import ODMTabs from "../../../layouts/Tab/odm";
import {Helmet} from "react-helmet-async";
import React from "react";
import EproContainer from "../../../container/odm/EproContainer";

const EproPage = () => {
    return (
        <ODMTabs menu={'e-pro'}>
            <Helmet title={`ODM Designer v2.0.0 :: ePRO`}/>
            <EproContainer />
        </ODMTabs>
    )
}
export default EproPage;