import {Col, Form, Input, InputNumber, Row} from "antd";
import React from "react";

const ExpressionComparisonResult = ({expressionComparisonTypeMap, itemExpression, itemIndex, validateFactor}) => {

    const expressionComparisonType = expressionComparisonTypeMap.get(itemExpression.expressionComparisonType);

    return (
        <div className={'m-0'}>
            {
                expressionComparisonType != null &&
                <Row>
                    {
                        expressionComparisonType.key === 'BETWEEN'?(
                            <Input.Group>
                                <Row>
                                    <Col span={14}>
                                        <Form.Item name={[itemIndex, "firstParam"]} className={'mb-0'} rules={[{
                                            required: true, message: 'Param 값을 입력해주세요.'
                                        }]}>
                                            <InputNumber addonAfter={'~'} onBlur={() => validateFactor()} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item name={[itemIndex, "secondParam"]} className={'mb-0'} rules={[{
                                            required: true, message: 'Param 값을 입력해주세요.'
                                        }]}>
                                            <InputNumber onBlur={() => validateFactor()} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Input.Group>
                        ):(
                            <>
                                    <Form.Item name={[itemIndex, "firstParam"]} className={'mb-0'} rules={[{
                                        required: true, message: 'Param 값을 입력해주세요.'
                                    }]}>
                                        {
                                            expressionComparisonType?.expressionType === 'NUMERIC'?(
                                                <InputNumber className={'width-100p'} onBlur={() => validateFactor()}
                                                       addonBefore={expressionComparisonType?.prefix}
                                                       addonAfter={expressionComparisonType?.postfix} />
                                            ):(
                                                <Input className={'width-100p'} onBlur={() => validateFactor()}
                                                       addonBefore={expressionComparisonType?.prefix}
                                                       addonAfter={expressionComparisonType?.postfix} />
                                            )
                                        }
                                    </Form.Item>
                            </>
                        )
                    }
                </Row>
            }
        </div>
    )
}

export default ExpressionComparisonResult;