import ODMTabs from "../../../layouts/Tab/odm";
import {Helmet} from "react-helmet-async";
import PublishContainer from "../../../container/odm/PublishContainer";
import React from "react";

const PublishPage = () => {

    return (
        <ODMTabs menu={'publish'}>
            <Helmet title={'ODM Designer v2.0.0 :: Publish'}/>
            <PublishContainer />
        </ODMTabs>
    )

}

export default PublishPage;