import {Draggable, Droppable} from "react-beautiful-dnd";
import React from "react";
import {
    Container, DescriptionWrapper,
    ItemGroupAddButtonBlock,
    ItemGroupDiv, ItemGroupDivider,
    ItemGroupTitle,
    ItemGroupTitleWrapper,
    Notice
} from "../StyleBox";
import Item from "./Item";
import DefinitionBtn from "./DefinitionBtn";
import {Button} from "antd";
import {isEmpty} from "../../../lib/StringUtils";
import ItemGroupOption from "./ItemGroupOption";
import {descriptionText, headerTitleText} from "./FormComponents";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../lib/ODMUtils";
import classNames from "classnames";
import style from './style.module.scss';

/**
 * ItemGroup들을 담은 컴포넌트
 * @param metaData
 * @param buildForm
 * @param setBuildForm
 * @param onAsideIn
 * @param asideData
 * @param setAsideData
 * @returns {JSX.Element}
 * @constructor
 */
const ItemGroup = ({metaData, selectedLanguage, designMode,
                       formDef, setFormDef, itemRefMap, setItemRefMap,
                       setDefVisible, setDefType, setDefItem, setDefOption,
                       onECSInfo, dropEnable}) => {

    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);

    const onCreateItemGroup = () => {
        setDefVisible(true);
        setDefType('itemGroup');
        setDefItem(null);
        setDefOption({
            option: 'itemGroupDef',
            formOID: formDef?.oid,
        })
    }

    const isEmptyHeaderTitle = (headerTitle) => {
        return headerTitle == null || headerTitle?.translatedText == null || headerTitle?.translatedText.length <= 0
    }

    return (
        <>
            <Droppable key={formDef?.oid} droppableId={formDef?.oid} type="itemGroup">
                {(provided, snapshot) => (
                    <Container
                        key={formDef?.oid}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        designMode={designMode}
                        isDraggingOver={snapshot.isDraggingOver}
                    >
                        {formDef?.itemGroupRef.length > 0 ?
                            formDef.itemGroupRef.map((itemGroup, index) => {
                                if(metaData.itemGroup.get(itemGroup.itemGroupOID) != null) {
                                    return (
                                        <Draggable
                                            key={`${itemGroup.itemGroupOID}`}
                                            draggableId={`${itemGroup.itemGroupOID}`}
                                            index={index} isDragDisabled={isProd}>
                                            { //Item 영역
                                                (provided, snapshot) => (
                                                    <ItemGroupDiv
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps} //Draggable 옵션을 가져올 수 있게 설정 (Source, destination 등)
                                                        isDragging={snapshot.isDragging}
                                                        isDraggingOver={snapshot.isDraggingOver}
                                                        style={provided.draggableProps.style}>
                                                        <ItemGroupTitleWrapper
                                                            key={`${itemGroup.itemGroupOID}`} {...provided.dragHandleProps}>
                                                            <ItemGroupTitle>
                                                                {
                                                                    designMode && (
                                                                        <DefinitionBtn
                                                                            data={metaData.itemGroup.get(itemGroup.itemGroupOID)}
                                                                            index={index} defType={"itemGroup"}
                                                                            setDefVisible={setDefVisible}
                                                                            setDefType={setDefType} setDefItem={setDefItem}/>
                                                                    )
                                                                }

                                                                <h4 style={{fontSize: '1rem', fontWeight: 400}}>
                                                                    {itemGroup?.mandatory === 'YES' && (
                                                                        <sup style={{color: 'red'}} className={'mandatory'}>*</sup>
                                                                    )}
                                                                    {
                                                                        !isEmptyHeaderTitle(metaData.itemGroup.get(itemGroup.itemGroupOID).headerTitle)?
                                                                            headerTitleText(metaData.itemGroup.get(itemGroup.itemGroupOID).headerTitle, selectedLanguage)
                                                                            : metaData.itemGroup.get(itemGroup.itemGroupOID).name
                                                                    }
                                                                </h4>
                                                            </ItemGroupTitle>
                                                            <ItemGroupOption metaData={metaData} itemGroup={itemGroup}
                                                                             formDef={formDef} setFormDef={setFormDef}
                                                                             itemRefMap={itemRefMap} setItemRefMap={setItemRefMap} index={index} />
                                                        </ItemGroupTitleWrapper>
                                                        <DescriptionWrapper>
                                                            {descriptionText({description:metaData.itemGroup.get(itemGroup.itemGroupOID).headerDescription, selectedLanguage:selectedLanguage})}
                                                        </DescriptionWrapper>
                                                        {(!isEmpty(formDef.itemGroupRef[index].collectionExceptionConditionOID) && designMode) &&
                                                            <div className={classNames('ml-3', {
                                                                'text-warning': metaData.editCheck.get(formDef.itemGroupRef[index].collectionExceptionConditionOID)?.activate
                                                            })}>
                                                                <span className={classNames(style.cursorPointer, 'badge', {
                                                                    'badge-danger': metaData.editCheck.get(formDef.itemGroupRef[index].collectionExceptionConditionOID)?.activate,
                                                                    'badge-secondary': !metaData.editCheck.get(formDef.itemGroupRef[index].collectionExceptionConditionOID)?.activate
                                                                })} onClick={() => onECSInfo(formDef.itemGroupRef[index].collectionExceptionConditionOID)}>
                                                                    <i className='fa fa-search-plus mr-1'></i>
                                                                    {formDef.itemGroupRef[index].collectionExceptionConditionOID}
                                                                </span>&nbsp;
                                                                <small>{metaData.editCheck.get(formDef.itemGroupRef[index].collectionExceptionConditionOID)?.name||'삭제된 Condition OID입니다.'}</small>
                                                            </div>
                                                        }
                                                        <ItemGroupDivider />
                                                        <Item metaData={metaData} selectedLanguage={selectedLanguage}
                                                              designMode={designMode} //Display Option
                                                              itemGroup={metaData.itemGroup.get(itemGroup.itemGroupOID)}
                                                              itemRefMap={itemRefMap}
                                                              setItemRefMap={setItemRefMap} //Reference Option
                                                              setDefVisible={setDefVisible} setDefType={setDefType}
                                                              setDefItem={setDefItem} setDefOption={setDefOption} //Definition Option
                                                              onECSInfo={onECSInfo} //ECS Option
                                                              dropEnable={dropEnable} //Drag & Drop Option
                                                        />
                                                        {metaData.itemGroup.get(itemGroup.itemGroupOID).footerDescription!= null && <hr className='my-0 mb-2' style={{width: '100%'}}/>}
                                                        <DescriptionWrapper>
                                                            {descriptionText({description:metaData.itemGroup.get(itemGroup.itemGroupOID).footerDescription, selectedLanguage:selectedLanguage})}
                                                        </DescriptionWrapper>
                                                    </ItemGroupDiv>
                                                )
                                            }
                                        </Draggable>
                                    )
                                }
                            })
                            : !snapshot.isUsingPlaceholder && (
                            <Notice> Drop itemGroup here </Notice>
                        )
                        }
                        {provided.placeholder}
                    </Container>
                )}
            </Droppable>
            {(!isProd && designMode) &&
            <ItemGroupAddButtonBlock>
                <Button type={"dashed"} className={'mr-2 mb-2'} onClick={onCreateItemGroup}>
                    <i className='fa fa-plus mr-2'/>Item Group
                </Button>
            </ItemGroupAddButtonBlock>
            }
        </>
    )
}

export default ItemGroup;
