import {Helmet} from "react-helmet-async";
import SponsorSubjectStatusContainer from "../../../../components/common/builder/sponsor/SponsorSubjectStatusContainer";

const SponsorSubjectStatusPage = () => {

    return (
        <>
            <Helmet title={'BUILDER :: Sponsor - Subject Status'} />
            <SponsorSubjectStatusContainer />
        </>
    )
}

export default SponsorSubjectStatusPage;