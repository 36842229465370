import {Button, Modal, Typography} from "antd";
import NormalLoading from "../../NormalLoading";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {useState} from "react";

const SAEModal = ({open, setOpen, json}) => {

    const [copy, setCopy] = useState(false);

    const handleCopyClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopy(true);
        } catch(e) {
            console.error(e);
            errorHandle(e);
        }
    }

    return (
        <Modal title={
            <>
                <span className={'mr-4'}>SAE Json Map</span>
                {
                    json != null &&
                    <Button className={'mr-2'} onClick={() => handleCopyClipboard(json)}>
                        <i className='fa fa-clipboard'></i>
                    </Button>
                }
                {
                    copy &&
                    <Typography.Text color={'#0000ff'}>JSON Copied.</Typography.Text>
                }
            </>
        }
               onCancel={() => setOpen(false)}
               width={500}
               open={open}
               okButtonProps={{style:{display:'none'}}}
               destroyOnClose={true}
        >
            <div className='container'>
                {
                    json !== null? (
                        <pre className={'font-size-14'}>{json}</pre>
                    ) : (
                        <NormalLoading />
                    )
                }
            </div>
        </Modal>
    )

}


export default SAEModal;