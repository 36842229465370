import useLibraryStore from "../../../../../store/libraryStore";
import {useEffect, useState} from "react";
import LibraryFormDef from "../modal/LibraryFormDef";
import {useMutation} from "react-query";
import {method, saveDefWithAxios} from "../../../../../lib/api/library";
import {message} from "antd";
import {errorHandle} from "../../../../../lib/BuilderUtils";
import {descriptionCheck} from "../../../../../lib/ODMUtils";

/**
 * Form Build 화면 상에서 Form을 추가하려고 할 때 동작하는 Container
 * @param defOpen
 * @param setDefOpen
 * @param defItem
 * @param setDefItem
 * @returns {false|JSX.Element}
 * @constructor
 */
const LibraryFormContainer = ({defOpen, setDefOpen, defItem, setDefItem}) => {

    const {library, formType, languages, yesOrNo, initialValue, updateLibrary} = useLibraryStore(state => (state));

    //컴포넌트에 따라 필요한 정보
    const propName = 'formDef';
    const [formDef, setFormDef] = useState({}); //현재 Form에 대한 정보
    const [create, setCreate] = useState(false);

    //UseMutation 사용
    const {mutate} = useMutation(saveDefWithAxios, {
        onSuccess:({library, resultMessage}) => {
            console.log(`Form ${create?'Create':'Update'} : `, library);
            message.success(resultMessage);
            updateLibrary(library);
            onCancel();
        },
        onError: (error) => {
            errorHandle(error);
        },
        onMutate: (variables) => {
            /**
             * Mutation 함수가 실행되기 전, 실행되고 mutation 함수가 받을 동일한 변수가 전달,
             */
        },
        onSettled: (data, error, variables, context) => {
            /**
             * mutation이 성공하여 성공한 데이터 또는 error가 전달될 때 실행,
             * 성공하거나 실패하거나 상관없이 결과가 무조건 전달
             */
        }
    });

    useEffect(() => {
        if (defItem !== null) { //Update
            setFormDef(defItem);
            setCreate(false);
        } else if (defItem === null) { //New
            setFormDef(initialValue?.form??{});
            setCreate(true);
        }

    }, [defItem]);

    const onCancel = () => {
        setFormDef({});
        setDefItem(null);
        setDefOpen(false);
        setCreate(false);
    }

    //데이터 등록/수정 함수
    const onFinish = (formDef) => {
        descriptionCheck(formDef); //description nullCheck 후, 없으면 삭제
        const {id} = library;
        formDef.id = id;
        formDef.attr = propName;
        formDef.method = create?method.post:method.put;
        mutate(formDef);

        onCancel(); //Drawer 화면 사라짐.
    };


    return (
        <LibraryFormDef open={defOpen} setOpen={setDefOpen}
                        formDef={formDef} yesOrNo={yesOrNo}
                        formType={formType} languages={languages}
                        onFinish={onFinish} onCancel={onCancel}/>
    )

}

export default LibraryFormContainer;