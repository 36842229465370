import React from 'react'
import {Helmet} from 'react-helmet-async'
import Error500 from '@vb/sscomponents/Errors/500'
import AuthLayout from "../../../layouts/Auth";

const System500 = () => {
    return (
        <AuthLayout authPagesColor={'image'}
                    descr={'BUILDER'}
                    isAuthTopbar={true}
                    isGrayTopbar={false}>
            <Helmet title="Page 500"/>
            <Error500/>
        </AuthLayout>
    )
}

export default System500
