import {Helmet} from "react-helmet-async";
import React from "react";
import BasicDefinition from "../../../../components/common/builder/studies/BasicDefinition";
import CRFVersionTabs from "../../../../layouts/Tab/crf-version";

const BasicDefinitionPage = () => {

    return (
        <CRFVersionTabs menu={'basic-definition'}>
            <Helmet title={'BUILDER :: Basic Definition'} />
            <BasicDefinition />
        </CRFVersionTabs>
    )
}

export default BasicDefinitionPage;