import {Button} from "antd";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {userActions} from "../../../../redux/module/user";
import {useDispatch, useSelector} from "react-redux";

const Error403 = ({t }) => {

    const user = useSelector(({user}) => (user));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect( () => {
        (async() => {
            if (!(user.user !== null || user.authorized)) {
                dispatch(userActions.loadCurrentAccount());
            }
        })()
    }, []);

    return (
        <div className="container pl-5 pr-5 pt-5 pb-5 mb-auto text-dark font-size-32">
            <div className="font-weight-bold mb-3">{t('error.title.403')}</div>
            <div className="text-gray-6 font-size-36">
                {t('error.message.403')}
            </div>
            <div className="font-weight-bold font-size-70 mb-1">{t('error.code.403')}</div>
            <Button onClick={() => {navigate('/')}} className="btn btn-outline-primary width-100">
                {t('btn.go-back')}
            </Button>
        </div>
    )
}

export default withTranslation()(Error403);