import {Col, Collapse, Input, Row} from "antd";
import React, {useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {errorHandle} from "../../lib/BuilderUtils";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {httpStatus} from "../../lib/createRequestSaga";
import {isEmpty} from "../../lib/StringUtils";
import {Notice} from "../../container/build-tool/StyleBox";
import NoDataBlock from "../common/NoDataBlock";
import cookie from 'react-cookies';

const LANGUAGE_COOKIE_NAME = 'org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE'
const EditCheckGuide = ({t}) => {

    const [editCheckGuide, setEditCheckGuide] = useState(null);
    const [filterEditCheckGuide, setFilterEditCheckGuide] = useState({});
    const [filter, setFilter] = useState({['GLOBAL_VARIABLE']:false,['OPERATOR']:false,['FUNCTION']:false,['PROPERTY']:false})

    const lang = isEmpty(cookie.load(LANGUAGE_COOKIE_NAME)) ? 'ko' : cookie.load(LANGUAGE_COOKIE_NAME);

    const [guideTypes, setGuideTypes] = useState([]);

    useEffect(() => {

        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/editcheckGuide`);
                if(status === httpStatus.ok) {
                    const {editCheckGuide, guideTypes} = data;
                    setEditCheckGuide(editCheckGuide);
                    setGuideTypes(guideTypes);

                    setFilterEditCheckGuide({
                        ['GLOBAL_VARIABLE']:editCheckGuide.filter(({guideType}) => guideType === 'GLOBAL_VARIABLE'),
                        ['OPERATOR']:editCheckGuide.filter(({guideType}) => guideType === 'OPERATOR')??[],
                        ['FUNCTION']:editCheckGuide.filter(({guideType}) => guideType === 'FUNCTION')??[],
                        ['PROPERTY']:editCheckGuide.filter(({guideType}) => guideType === 'PROPERTY')??[],
                    })
                }
            } catch(e) {
                errorHandle(e);
            }
        })()

    }, [])

    const onSearchGuide = (key, q) => {
        const filterData = editCheckGuide.filter(({guideType, keyword}) => {
            if(isEmpty(q)) {
                return guideType === key;
            }
            else {
                return guideType === key && keyword.toUpperCase().includes(q.toUpperCase());
            }
        });
        setFilterEditCheckGuide({
            ...filterEditCheckGuide,
            [key]:filterData
        });

        //Filter 여부 확인
        setFilter({
            ...filter,
            [key]: isEmpty(q) === false
        })
    }

    return (
        <Row>
            <Col offset={4} span={20}>
                <Collapse className={'site-collapse-custom-collapse mb-1'}>
                    {
                        guideTypes.map(({key, label}) => (
                            <Collapse.Panel header={label} key={key} className={'site-collapse-custom-panel'}
                                            extra={filter[key]&&<i className={'fa fa-filter'}></i>}>
                                <Input.Search className={'mb-3'} onSearch={(q) => onSearchGuide(key, q)} />
                                <PerfectScrollbar style={{maxHeight:'25rem'}}>
                                    <table className={'table table-bordered position-relative'} style={{backgroundColor: '#fff'}}>
                                        <thead style={{"margin-top":'-1px', background: '#f9f9f9', position:'sticky', top:'-1px'}}>
                                        <tr>
                                            <th>{label}</th>
                                            <th>Description</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            filterEditCheckGuide[key].length>0 ? filterEditCheckGuide[key].map(({caption, description, descriptionEn}, idx) => (
                                                <tr key={idx}>
                                                    <td style={{paddingTop:'1.5rem'}} dangerouslySetInnerHTML={{__html:caption}}></td>
                                                    <td style={{verticalAlign:'middle'}} dangerouslySetInnerHTML={{__html: lang === 'ko'? description : descriptionEn}}></td>
                                                </tr>
                                            )):(
                                                <tr>
                                                    <td colSpan={2}><Notice><NoDataBlock /></Notice></td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </PerfectScrollbar>
                            </Collapse.Panel>
                        ))
                    }
                </Collapse>
            </Col>
        </Row>
    )
}

export default withTranslation()(EditCheckGuide);
