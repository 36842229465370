import React from 'react'
import {Layout} from 'antd'
import classNames from 'classnames'
import style from './style.module.scss'
import UserMenu from "../../@vb/sscomponents/TopBar/UserMenu";
import LanguageSwitcher from "../../@vb/sscomponents/TopBar/LanguageSwitcher";


const AuthLayout = ({children, descr, user, isGrayTopbar, isAuthTopbar, authPagesColor}) => {
    return (
        <Layout className="vb__layout">
            <Layout.Content>
                <div
                    className={classNames(`${style.container}`, {
                        vb__layout__squaredBorders: false,
                        vb__layout__cardsShadow: false,
                        vb__layout__borderless: false,
                        [style.white]: authPagesColor === 'white',
                        [style.gray]: authPagesColor === 'gray',
                    })}
                    style={{
                        backgroundImage:
                            authPagesColor === 'image' ? 'url(static/images/content/photos/8.jpeg)' : '',
                    }}
                >
                    {isAuthTopbar && (
                        <div
                            className={classNames(`${style.topbar}`, {
                                [style.topbarGray]: isGrayTopbar,
                            })}
                        >
                            <div className={style.logoContainer}>
                                <div className={style.logo}>
                                    {/*<div className={style.name}>{logo}</div>*/}
                                    <div className={style.name}><img src="/static/images/redpine/logo-redpine-100.png"/>
                                    </div>
                                    <div className={style.descr}>{descr}</div>
                                </div>
                            </div>
                            <div className='d-flex flex-row align-items-center pull-right' style={{gap: '2rem'}}>
                                <div className="d-none d-sm-block">
                                    <LanguageSwitcher/>
                                </div>
                                <div className="d-none d-sm-block">
                                    {
                                        (user != null && user.authorized) &&
                                        <UserMenu user={user}/>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="mb-5">{children}</div>
                    {/* FOOTER */}
                    <div className="mt-auto pb-5 pt-5">
                        {/*<ul*/}
                        {/*  className={`${style.footerNav} list-unstyled d-flex mb-0 flex-wrap justify-content-center`}*/}
                        {/*>*/}
                        {/*  <li>*/}
                        {/*    <a href="#" onClick={(e) => e.preventDefault()}>*/}
                        {/*      Terms of Use*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <a href="#" onClick={(e) => e.preventDefault()}>*/}
                        {/*      Compliance*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <a href="#" onClick={(e) => e.preventDefault()}>*/}
                        {/*      Support*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <a href="#" onClick={(e) => e.preventDefault()}>*/}
                        {/*      Contacts*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <a href="#" onClick={(e) => e.preventDefault()}>*/}
                        {/*      About*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <a href="#" onClick={(e) => e.preventDefault()}>*/}
                        {/*      Advertising*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*</ul>*/}
                        <div className="text-center">
                            Copyright © {new Date().getFullYear()}{' '}
                            <a href="https://safesoft.co.kr" target="_blank" rel="noopener noreferrer">
                                Safesoft
                            </a>
                            {' | '}
                            <a href="https://dtnbiogroup.com" target="_blank" rel="noopener noreferrer">
                                DT&Bio Group
                            </a>
                        </div>
                    </div>
                </div>
            </Layout.Content>
        </Layout>
  )
}

export default AuthLayout;
