import {Helmet} from "react-helmet-async";
import Schedule from "../../../components/common/odm/Schedule";
import React from "react";
import ODMTabs from "../../../layouts/Tab/odm";
import {mdiCalendarMultiple} from "@mdi/js";
import Icon from "@mdi/react";

const StudyEventGroupsPage = () => {

    return (
        <ODMTabs menu={'study-event-groups'}>
            <Helmet title={'ODM Designer v2.0.0 :: Event Groups'}/>
            <Schedule defType={'studyEventGroupDef'} refType={'studyEventRef'} refTitle={'Event'}
                      defTitle={<span><Icon path={mdiCalendarMultiple} size={1} className={'mr-2'} />Event Group</span>}
            />
        </ODMTabs>
    )
}

export default StudyEventGroupsPage;
