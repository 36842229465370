import useLibraryStore from "../../../../../store/libraryStore";
import {useMutation} from "react-query";
import {method, saveDefWithAxios} from "../../../../../lib/api/library";
import {message} from "antd";
import {errorHandle} from "../../../../../lib/BuilderUtils";
import {codeListRefCheck, descriptionCheck, questionCheck, valueListRefCheck} from "../../../../../lib/ODMUtils";
import {useEffect, useState} from "react";
import {toLibraryMap} from "../../../../../lib/LibraryUtils";
import LibraryItemDef from "../modal/LibraryItemDef";

const LibraryItemContainer = ({defOpen, setDefOpen, defItem, setDefItem, defOption, setDefOption}) => {

    const {library, languages, initialValue, updateLibrary, loaded} = useLibraryStore(state => (state));

    //컴포넌트에 따라 필요한 정보
    const propName = 'itemDef';
    const [itemDef, setItemDef] = useState({}); //현재 ItemDef에 대한 정보
    const [create, setCreate] = useState(false);
    const [codeLists, setCodeLists] = useState([]);
    const [libraryMap, setLibraryMap] = useState(null);

    //UseMutation 사용
    const {mutate} = useMutation(saveDefWithAxios, {
        onSuccess:({library, resultMessage}) => {
            console.log(`ItemGroup ${create?'Create':'Update'} : `, library);
            message.success(resultMessage);
            updateLibrary(library);
            onCancel();
        },
        onError: (error) => {
            errorHandle(error);
        },
    });

    useEffect(() => {
        if(loaded) {
            const libMap = toLibraryMap(library);
            setLibraryMap(libMap);
            setCodeLists(Array.from(libMap.codeList.values()));
        }

        if (defItem !== null) { //Update
            setItemDef(defItem);
            setCreate(false);
        } else { //New
            setItemDef(initialValue?.item??{});
            setCreate(true);
        }

    }, [library, defItem]);

    const onCancel = () => {
        setItemDef({});
        setDefItem(null);
        setDefOpen(false);
        setCreate(false);
    }

    //데이터 등록/수정 함수
    const onFinish = (itemDef) => {
        descriptionCheck(itemDef); //description nullCheck 후, 없으면 삭제
        questionCheck(itemDef); // question undefined Check 후, 없으면 삭제
        codeListRefCheck(itemDef); // codeList undefined Check 후, 없으면 삭제
        valueListRefCheck(itemDef); // valueList undefined Check 후, 없으면 삭제

        const {id} = library;
        itemDef.id = id;
        itemDef.attr = propName;
        itemDef.method = create?method.post:method.put;
        mutate(itemDef);

        onCancel(); //Modal 화면 사라짐.
        setDefOption({
            ...defOption,
            itemOID: itemDef?.oid,
            done: true
        });
    };

    return (
        <LibraryItemDef library={libraryMap} languages={languages}
                        open={defOpen} itemDef={itemDef} codeLists={codeLists}
                        onFinish={onFinish} onCancel={onCancel} />
    )
}

export default LibraryItemContainer;