import React from "react";
import {Helmet} from "react-helmet-async";
import SponsorEdit from "../../../../components/common/builder/sponsor/SponsorEdit";


const SponsorEditPage = () => {

    return (
        <>
            <Helmet title={'BUILDER :: Sponsor Edit'} />
            <SponsorEdit />
        </>
    )
}

export default SponsorEditPage;