import React, {useEffect} from "react";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../redux/module/user";
import {DUPLICATED_SIGNOUT} from "../constant/responseType";


const PublicRoute = () => {
    const user = useSelector(({user}) => (user));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        console.log("%clocation : ", "color:red", location);
        if (!(user.user !== null || user.authorized) && location.pathname.match('/login$') && user?.responseStatus === '') {
            dispatch(userActions.loadCurrentAccount());
        } else if(user?.responseStatus === DUPLICATED_SIGNOUT) {
            navigate('/login?duplicated', {replace: true});
        }
    }, [location.pathname])

    return (user?.loaded && user?.authorized) ? (<Navigate to={'/'}/>) : (<Outlet />);
}

export default PublicRoute;