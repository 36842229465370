import {create} from "zustand";
import zukeeper from 'zukeeper';

//Zustand를 이용한 store 생성
const libraryStore = (set) => ({
    library: {},
    loaded: false,
    language: {ko:'korean'},
    selectLibrary: (data) => set(state => ({...state, ...data, loaded: true})),
    updateLibrary: (data) => set(state => ({...state, library:data}))
});

//운영환경인지에 따라 devtools 사용여부 선택
const useLibraryStore = create(process.env.NODE_ENV === 'production'?libraryStore:zukeeper(libraryStore));

window.store = useLibraryStore;
export default useLibraryStore;