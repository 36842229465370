import React, {useState} from "react";
import {ItemGroupExtra, ItemGroupOptionDiv} from "../../../../../container/build-tool/StyleBox";
import LibraryMandatoryBtn from "./LibraryMandatoryBtn";
import RemoveBtn from "../../../../../container/build-tool/content/build-form/RemoveBtn";

const LibraryItemGroupOption = ({library, itemGroup, formDef, setFormDef, itemRefMap, setItemRefMap, index}) => {

    const [removeOpen, setRemoveOpen] = useState(false);

    return (
        <>
            <ItemGroupOptionDiv className={'ml-3'} visible={removeOpen}>
                <LibraryMandatoryBtn library={library} data={itemGroup} formDef={formDef} setFormDef={setFormDef}
                                     index={index} defType={"itemGroup"} />
            </ItemGroupOptionDiv>
            <ItemGroupExtra visible={removeOpen}>
                <RemoveBtn data={itemGroup} formDef={formDef} setFormDef={setFormDef}
                           itemRefMap={itemRefMap} setItemRefMap={setItemRefMap}
                           index={index} defType={"itemGroup"}
                           visible={removeOpen} setVisible={setRemoveOpen} />
            </ItemGroupExtra>
        </>
    )

}

export default LibraryItemGroupOption;