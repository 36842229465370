import {useNavigate, useLocation, useParams} from "react-router-dom";
import queryString from "query-string";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Col, DatePicker, Form, Input, message, Row, Select} from "antd";
import {CardBody, CardFooter, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle, runtimeError} from "../../../../lib/BuilderUtils";
import DefaultLoading from "../../DefaultLoading";
import dayjs from 'dayjs';
import {Option} from "antd/es/mentions";
import {useTranslation} from "react-i18next";

const CRFVersionEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();

    //query-string이 존재하면 studyID에 해당하는 값 불러오기.
    const params = useParams();
    const query = queryString.parse(location.search);
    const [crfVersion, setCrfVersion] = useState(null);
    const [libraryStudies, setLibraryStudies] = useState([]);
    const [format, setFormat] = useState(null);
    const [dateFormatTypes, setDateFormatTypes] = useState([]);
    const [options, setOptions] = useState([]);
    const [form] = Form.useForm();

    //Button Loading
    const [loading, setLoading] = useState(false);

    useEffect( () => {

        (async () => {
            //GET Request
            try {
                //REST API Request
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/versions/edit`, {params: query});

                //State Setting
                if(status === httpStatus.ok) {
                    if(data?.designMetaDataVersion.env?.key != null) {
                        if(data.designMetaDataVersion.env?.key == 'PROD') {
                            throw runtimeError(t('studies.cannot.access.edit.prod.crf.version'));
                        }
                        data.designMetaDataVersion.env = data.designMetaDataVersion.env?.key; //Enum값을 key로 세팅
                    }

                    //DateFormat 양식 설정
                    const dateFormatType = data.designMetaDataVersion?.dateFormatType?.key??null;
                    const dateFormat = data.dateFormatTypes.find(s => s.key === dateFormatType)?.format?.toUpperCase();
                    setFormat(dateFormat);

                    setCrfVersion({...data.designMetaDataVersion,
                        effectiveDate:data.designMetaDataVersion?.effectiveDate!=null?dayjs(data.designMetaDataVersion?.effectiveDate):null,
                        dateFormatType:dateFormatType
                    });

                    setLibraryStudies(data.libraryStudies);
                    setOptions([{value:null, label:'...'}, ...data.dateFormatTypes.map((type) => ({value:type.key, label:type.format?.toUpperCase()}))]);
                    setDateFormatTypes(data.dateFormatTypes);
                }
            } catch (error) {
                errorHandle(error, navigate, `/studies`);
            }
        })();
    }, [])


    const onFinish = async (values) => {
        //Loading
        setLoading(true);

        //Create/Edit Study
        try {
            const body = {
                ...crfVersion,
                ...values,
            };

            //Effective Date값만 추려서 저장
            if(body?.effectiveDate!==null){
                body.effectiveDate = body.effectiveDate.format('YYYY-MM-DD');
            }

            const {data, status} = await axios({
                url: `/api/builder/studies/${params.sid}/versions/edit`,
                data: body, method: 'post',
            });

            if (status === httpStatus.ok) {
                message.success(data.message);
                navigate(`/studies/${params.sid}/versions`);
            }
        } catch (e) {
            errorHandle(e);
        }

        //Loading cancel
        setLoading(false);
    }

    const onDateFormatSelect = (value) => {
        const dateFormat = dateFormatTypes.find(s => s.key === value)?.format?.toUpperCase();
        setFormat(dateFormat);

        if(dateFormat == null) {
            form.setFieldsValue({effectiveDate:null});
        }
    }

    /**
     * 미래날짜는 선택하지 못하게 설정
     * @param currentdrf
     * @returns {boolean}
     */
    const disabledDate = (current) => {
        //Can not select days after today and today
        return current && current > dayjs().endOf('day');
    }

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    return (
        <>
            {
                crfVersion === null &&
                <DefaultLoading />
            }
            {
                crfVersion !== null &&
                <DefaultCard>
                    <CardHeader>
                        <h3>CRF Version</h3>
                    </CardHeader>
                    <Form layout="vertical"
                          form={form}
                          initialValues={crfVersion}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}>
                        <CardBody>
                            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} hidden={libraryStudies.length === 0}>
                                <Col span={'16'}>
                                    <Form.Item name={'includeStudyOID'} label={'Include Study'}
                                               rules={[{required: false}]}>
                                        <Select>
                                            <Select.Option value={""}>{t('studies.copy.assignment')}</Select.Option>
                                            {libraryStudies.map(s => (
                                                <Select.Option key={s.studyOID} value={s.studyOID}>
                                                    {s.protocolName||'NONE'} - {s.studyName||'NONE'}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                <Col span={'16'}>
                                    <Form.Item name={'name'} label={'Version'} required
                                               rules={[{
                                                   required: true,
                                                   message: t('studies.enter.version.name'),
                                               }]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={'3'}>
                                    <Form.Item name={'dateFormatType'} label={'Date Format'}>
                                        <Select onSelect={onDateFormatSelect} options={options}></Select>
                                    </Form.Item>
                                </Col>
                                <Col span={'5'}>
                                    <Form.Item name={'effectiveDate'} label={'Effective Date'} hidden={format == null}
                                               rules={[{required:(format!=null), message:t('studies.enter.effective.date')}]}>
                                        <DatePicker className='width-100p' disabledDate={disabledDate} format={format} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                <Col span={'24'}>
                                    <Form.Item name={'description'} label={'Description'} required
                                               rules={[{
                                                   required: true,
                                                   message: t('studies.enter.description'),
                                               }]}
                                    >
                                        <Input.TextArea style={{height: '120px'}}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className='text-right'>
                            <Button type={'default'} onClick={() => {
                                navigate(`/studies/${params.sid}/versions`);
                            }} className={'mr-3'}> Cancel </Button>
                            <Button loading={loading} type={'primary'} htmlType={'submit'}> Submit </Button>
                        </CardFooter>
                    </Form>
                </DefaultCard>
            }
        </>
    )

}

export default CRFVersionEdit;
