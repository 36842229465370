import {Button, Popconfirm} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import React, {useRef} from "react";
import _ from 'lodash';

const RemoveBtn = ({type, formDef, setFormDef, itemRefMap, setItemRefMap, index, defType, itemGroupOID, visible, setVisible}) => {

    //데이터 참조목록에서 삭제.
    const RemoveData = () => {
        const cloneItemRefMap = _.cloneDeep(itemRefMap);
        switch(defType) {
            case 'itemGroup':
                const cloneItemGroupRef = _.cloneDeep(formDef.itemGroupRef);
                const [removeItemGroup] = cloneItemGroupRef.splice(index, 1);
                setFormDef({...formDef, itemGroupRef:cloneItemGroupRef});

                //ItemRefMap에서 현재 지운 ItemGroup에 대한 키값을 갖고있으면,
                if(cloneItemRefMap.has(removeItemGroup.itemGroupOID)) {
                    cloneItemRefMap.delete(removeItemGroup.itemGroupOID);
                    setItemRefMap(cloneItemRefMap);
                }

                break;
            case 'item':
                if(cloneItemRefMap.has(itemGroupOID)) {
                    cloneItemRefMap.get(itemGroupOID).splice(index,1);
                    setItemRefMap(cloneItemRefMap);
                }
                break;
        }

        setVisible(false);
    }


    return (
        defType !== "codeList" && (
            <Popconfirm
                okType="danger"
                icon={<QuestionCircleOutlined style={{color: "red"}}/>}
                title={`Remove the ${defType}?`}
                open={visible}
                onOpenChange={() => setVisible(!visible)}
                onCancel={() => setVisible(false)}
                trigger={'click'}
                onConfirm={RemoveData}>
                <Button size={'small'} type={type} onClick={() => setVisible(true)}>
                    <i className="fa fa-trash-o"/>
                </Button>
            </Popconfirm>
        )
    )
}

export default RemoveBtn;
