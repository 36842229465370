import {Button, Col, Form, Input, Row, Select, Tag} from "antd";
import React from "react";
import {descOrQuestionAppend, descOrQuestionRemove} from "../../../../../lib/ODMUtils";
import {Notice} from "../../../../../container/build-tool/StyleBox";
import NoDataBlock from "../../../NoDataBlock";
import {useTranslation} from "react-i18next";

const {Item, List} = Form;

const LibraryDescriptionOrQuestion = ({label = null, name, languages, form}) => {

    const {t} = useTranslation();

    return (
        <Row gutter={[20, 0]} style={{alignItems: 'center'}}>
            {
                label != null &&
                <Col span={24}>
                    <h5>{label}</h5>
                    <hr className={'mar-no pad-btm'}/>
                </Col>
            }

            <List name={[name, 'translatedText']}>
                {
                    (fields) =>
                        <>
                            {
                                fields != null && fields.length > 0? (
                                    fields.map((field, index) => (
                                        <React.Fragment key={index}>
                                            <Col span={6}>
                                                <Item name={[index, 'lang']}
                                                      required={false}
                                                      label={<span className='text-primary'>Language</span>}
                                                      rules={[
                                                          {required: (index > 0), message: t('message.this.is.required.selection')},
                                                          {pattern: /^(([a-z]{2})([\\-][A-Z]{2})?)/, message: t('message.format.is.incorrect')},
                                                          {max: 5, message: t('message.you.can.enter.up.to.5.characters')}
                                                      ]}>
                                                    <Select>
                                                        <Select.Option value={""}>...</Select.Option>
                                                        {(languages !== null && languages !== undefined) && Object.keys(languages).map(key => (
                                                            <Select.Option value={key}>{languages[key]}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Item>
                                            </Col>
                                            <Col span={16}>
                                                <Item name={[index, 'value']}
                                                      required={false}
                                                      label={<span className='text-primary'>Translated Text</span>}
                                                      rules={[
                                                          {required: true, message: t('message.warning.required-entry')},
                                                          {max: 4000, message: t('message.you.can.enter.up.to.4000.characters')}
                                                      ]}>
                                                    <Input.TextArea autoSize={true} rows={1}/>
                                                </Item>
                                            </Col>
                                            <Col span={2}>
                                                <Button type={'default'} onClick={() => descOrQuestionRemove(name, form, index)}>
                                                    <i className={'fa fa-times text-danger'}></i>
                                                </Button>
                                            </Col>
                                        </React.Fragment>
                                    ))) : (
                                    <Notice>
                                        <NoDataBlock />
                                    </Notice>
                                )
                            }
                            {
                                (fields.length < (Object.keys(languages).length)) &&
                                <Col offset={4} span={18}>
                                    <Item>
                                        <Button type={'primary'} className={'btn-block'}
                                                disabled={fields.length === (Object.keys(languages).length)}
                                                onClick={() => descOrQuestionAppend(name, form)}>
                                            <i className={'fa fa-plus mr-2'}></i> Translated Text
                                        </Button>
                                    </Item>
                                </Col>
                            }
                        </>
                }
            </List>
        </Row>
    )
}

export default LibraryDescriptionOrQuestion;