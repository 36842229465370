import {Button, Tag} from "antd";
import React from "react";
import {ItemOptions, ToolbarItemTextWrapper, ToolbarItemWrapper} from "../StyleBox";
import style from './style.module.scss';
import {useSelector} from "react-redux";
import {codeListIcon, controlTypeIcon, isEnvProduction, itemGroupIcon} from "../../../lib/ODMUtils";
import {getStyle} from "../../../lib/ToolbarUtils";

const ToolbarItem = ({provided, snapshot, isDragging= false, enable= false, itemType, item, onEdit, onRemove, onSearchOID}) => {

    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);

    const itemEdit = () => {
        onEdit(itemType, item);
    }

    const itemRemove = () => {
        let propName = "";
        switch(itemType) {
            case 'itemGroup':
                propName = "itemGroupDef";
                break;
            case 'item':
                propName = "itemDef";
                break;
            case 'codeListItem':
            case 'externalCodeList':
                propName = "codeList";
                break;
            case 'valueList':
                propName = "valueListDef";
                break;
        }
        onRemove(itemType, propName, item);
    }

    return (
        <>
            <ToolbarItemWrapper
                ref={provided!=null?provided.innerRef : null}
                {...provided?.draggableProps}
                {...provided?.dragHandleProps}
                isDragging={isDragging}
                enable={enable}
                style={(provided!=null && snapshot!=null)?getStyle(provided.draggableProps.style, snapshot):null}
            >
                {itemType == "item" &&
                    <span className={'mr-1'}>{controlTypeIcon(item.controlType)}</span>
                }
                {itemType == "itemGroup" &&
                    <span className={'mr-1'}>{itemGroupIcon(item.repeating)}</span>
                }
                {(itemType == "codeListItem" || itemType == "externalCodeList") &&
                    <span className={'mr-1'}>{codeListIcon(item.dataType)}</span>
                }
                {(itemType != "item" && itemType != "itemGroup" && itemType != "codeListItem" && itemType != "externalCodeList") &&
                    <span><i className={'fa fa-bars mr-2'}></i></span>
                }
                <ToolbarItemTextWrapper>
                    <span className={style.itemName}>
                        <Tag color={'geekblue'} style={{minWidth:'75px'}} className={'font-size-10 font-weight-bolder mr-1 pl-1 pr-1'}>{item?.oid}</Tag>
                        <span className={'font-size-12'}>{item?.name}</span>
                    </span>
                </ToolbarItemTextWrapper>
                <ItemOptions className='toolbar-buttons text-right' style={{minWidth:'95px'}}>
                    <Button type={"primary"} onClick={() => itemEdit()} size={"small"}>
                        {isProd?(<i className={'fa fa-info'}></i>):(<i className={'fa fa-edit'}></i>)}
                    </Button>
                    <Button type={"info"} className={'ml-1'} onClick={() => onSearchOID(itemType, item?.oid)} size={"small"}><i className={'fa fa-search'}></i></Button>
                    <Button hidden={isProd} type={"danger"} className={'ml-1'} danger onClick={() => itemRemove()} size={"small"}><i className={'fa fa-trash-o text'}></i></Button>
                </ItemOptions>
            </ToolbarItemWrapper>
            {
                isDragging && (
                    <ToolbarItemWrapper style={{opacity:0.5,background:'#eeeeee'}}>
                        {itemType == "item" &&
                            <span className={'mr-1'}>{controlTypeIcon(item.controlType)}</span>
                        }
                        {itemType == "itemGroup" &&
                            <span className={'mr-1'}>{itemGroupIcon(item.repeating)}</span>
                        }
                        {(itemType == "codeListItem" || itemType == "externalCodeList") &&
                            <span className={'mr-1'}>{codeListIcon(item.dataType)}</span>
                        }
                        {(itemType != "item" && itemType != "itemGroup" && itemType != "codeListItem" && itemType != "externalCodeList") &&
                            <span><i className={'fa fa-bars mr-2'}></i></span>
                        }
                        <ToolbarItemTextWrapper>
                             <span className={style.itemName}>
                                 <Tag color={'geekblue'} style={{minWidth:'75px'}} className={'font-size-10 font-weight-bolder mr-1 pl-1 pr-1'}>{item?.oid}</Tag>
                                <span className={'font-size-12'}>{item?.name}</span>
                            </span>
                        </ToolbarItemTextWrapper>
                    </ToolbarItemWrapper>
                )
            }
        </>
    )
}




export default ToolbarItem;
