import React, {useEffect} from "react";
import {Card, Col, Form, Modal, Row} from "antd";
import FormInputOID from "../../../../components/common/odm/ant-form/FormInputOID";
import FormInputName from "../../../../components/common/odm/ant-form/FormInputName";
import FormSelectYesOrNo from "../../../../components/common/odm/ant-form/FormSelectYesOrNo";
import DescriptionOrQuestion from "../../../../components/common/odm/ant-form/DescriptionOrQuestion";
import ModalFooter from "../ModalFooter";
import ModalTitle from "../component/ModalTitle";
import FormInputNumber from "../../../../components/common/odm/ant-form/FormInputNumber";
import {useTranslation} from "react-i18next";

const StudyEventDef = ({ selectedOID, studyEventDef, visible, languages, yesOrNo, eventTypes, onFinish, onCancel}) => {

    const [form] = Form.useForm();
    const {t} = useTranslation();

    useEffect(() => {
        if(studyEventDef != null) {
            console.log(studyEventDef);
            form.setFieldsValue(studyEventDef);
        } else {
            form.resetFields();
        }
    }, [studyEventDef])

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    return (
        <Modal
            forceRender={true}
            wrapClassName={'odm-modal'}
            title={<ModalTitle iconType={'studyEventDef'}>Event</ModalTitle>}
            width={800}
            onCancel={onCancel}
            open={visible}
            destroyOnClose={true}
            styles={{body:{overflowY: 'auto', height: 'calc(100vh - 20rem)'}}}
            footer={<ModalFooter onCancel={onCancel} form={form}/>}
        >
            <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
                <Card title={'Attributes'} type={'inner'} className={'mb-2'}>
                    <Row gutter={[20, 0]}>
                        <Col span={8}>
                            <FormInputOID selectedOID={selectedOID} form={form} allowDotChar={false}/>
                        </Col>
                        <Col span={16}>
                            <FormInputName name={studyEventDef?.name} defType={'studyEventDef'} form={form} />
                        </Col>
                        <Col span={8}>
                            <FormSelectYesOrNo label={'Event Type'} name={'type'} required={true} yesOrNo={eventTypes} value={studyEventDef?.type} />
                        </Col>
                        <Col span={8}>
                            <FormSelectYesOrNo label={'Repeating'} name={'repeating'} required={true} yesOrNo={yesOrNo} value={studyEventDef?.repeating} />
                        </Col>
                        <Col span={8}>
                            <FormInputNumber name={'visitNum'} label={'Visit Number'} rules={[
                                {required: true, message:t('message.warning.required-entry')},
                                {min:0, type:'number', message:t('message.visit.number.can.be.entered.starting.from.minimum.of.0')},
                                {max:9999, type:'number', message: t('message.visit.number.can.be.entered.up.to.maximum.of.9999')}
                            ]} style={{width:'100%'}} />
                        </Col>
                    </Row>
                </Card>
                <Card title={'Description'} type={'inner'} className={'mb-2'}>
                    <DescriptionOrQuestion languages={languages} name={'description'} form={form} />
                </Card>
            </Form>
        </Modal>
    )
}

export default StudyEventDef;
