import {QuestionCircleOutlined} from "@ant-design/icons";
import {Button, Popconfirm} from "antd";
import React from "react";

const RemoveBtn = ({defOID, refInfo, refType, visible, setVisible, onDeleteRef}) => {

    return (
        <Popconfirm title={`Remove the ${refType}?`}
                    icon={<QuestionCircleOutlined style={{color: "red"}}/>}
                    open={visible} trigger={'click'}
                    onOpenChange={() => setVisible(!visible)}
                    onConfirm={() => onDeleteRef(refInfo.oid, defOID)}>
            <Button type={'default'} size={'small'}>
                <i className='fa fa-trash-o'/>
            </Button>
        </Popconfirm>
    )

}

export default RemoveBtn;