import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import React, {useEffect, useState} from "react";
import _ from "lodash";
import style from "./style.module.scss"
const Core = ({value}) => {
    return (
        <span className={`badge badge-${value === 'HR' ? 'danger' : (value === 'R/C' ? 'warning' : 'default')}`}>{value}</span>
    )
}

// const excludeFields = ['STUDYID', 'SITEID', 'SUBJID', 'VISIT', 'VISDAT'];//공통 변수는 share api 아이템 항목에서 제외하도록 한다.
// const excludeSuffixFields = ['DD', 'MO', 'YY', 'U'];

const FieldList = ({domain, selectedDomainFields, setSelectedDomainFields, fields}) => {
    // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");

    //추천 필드 자동 선택
    // useEffect(() => {
    //     if(selectedDomainFields.size === 0) {
    //         const fieldMap = new Map();
    //         fieldMap.set(domain, fields.filter(f => f.core === 'HR').map(f => f.name))
    //         setSelectedDomainFields(fieldMap);
    //     }
    // }, []);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input size={'small'}
                    // ref={node => {
                    //     // node.searchInput = node;
                    //     console.log(node);
                    // }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onKeyUp={() => handleSearch(selectedKeys, confirm, dataIndex, false)}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, true)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({closeDropdown: false});
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownOpenChange: (e) => {
            if (e) {
                // setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const handleSearch = (selectedKeys, confirm, dataIndex, closeDropdown) => {
        confirm({closeDropdown: closeDropdown});

        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Core',
            dataIndex: 'core',
            key: 'core',
            width: '20%',
            render:text => <Core value={text} />,
            // ...getColumnSearchProps('core'),
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
            width: '50%',
            ...getColumnSearchProps('label'),
            // ...this.getColumnSearchProps('address'),
            // sorter: (a, b) => a.address.length - b.address.length,
            // sortDirections: ['descend', 'ascend'],
        },
    ];

    const onSelectChange = selectedKeys => {
        // console.log(selectedKeys);
        const newData = _.cloneDeep(selectedDomainFields);
        if(selectedKeys.length === 0) {
            newData.delete(domain);
        } else {
            newData.set(domain, selectedKeys);
        }
        setSelectedDomainFields(newData);
    }

    // const suffixFilter = (fieldName) => {
    //     for(let index in excludeSuffixFields) {
    //         // console.log('suffix =', excludeSuffixFields[index]);
    //         if(fieldName.lastIndexOf(excludeSuffixFields[index]) !== -1) {
    //             return true;
    //         }
    //     }
    //
    //     return false;
    // }

    const rowSelection = {
        selectedRowKeys:selectedDomainFields.get(domain),
        onChange:onSelectChange,
        getCheckboxProps:(record) => ({
            // disabled:excludeFields.includes(record.name) || suffixFilter(record.name),
            checked:record.core === 'HR',
            name:record.name,
        }),
    }

    return (
        <Table className={style.wizardTable} rowKey={'name'} pagination={false} scroll={{y:440}}  rowSelection={rowSelection} size={'small'} columns={columns} dataSource={fields} />
    )
}

export default FieldList;
