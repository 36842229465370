import {Button, Col, Descriptions, Row, Space, Spin, Switch, Table, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import style from './style.module.scss'
import {errorHandle} from "../../../../lib/BuilderUtils";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {LoadingOutlined, RightOutlined} from "@ant-design/icons";
import {Notice} from "../../../../container/build-tool/StyleBox";
import {useTranslation} from "react-i18next";

const SponsorDashboard = () => {

    const params = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [sponsor, setSponsor] = useState(null);
    const [precondition, setPrecondition] = useState(null);
    const [subjectStatus, setSubjectStatus] = useState(null);
    const [roles, setRoles] = useState(null);
    const [rolesPrivileges, setRolesPrivileges] = useState(null);

    const [preconditionCnt, setPreconditionCnt] = useState(0);
    const [rolesPrivilegesCnt, setRolesPrivilegesCnt] = useState(0);


    useEffect( () => {
        (async () => {
            //GET Request
            try {
                //REST API Request
                const {data, status} = await axios.get(`/api/builder/sponsor/${params.sponsorId}/sponsor-dashboard`);

                if(status === httpStatus.ok) {
                    //State Setting
                    setSponsor(data?.sponsor);

                    setPrecondition(data?.preconditionList);
                    setPreconditionCnt(data?.preconditionCnt);

                    setSubjectStatus(data?.subjectStatusList);
                    setRoles(data?.roleList);

                    setRolesPrivileges(data?.rolePrivilegesList);
                    setRolesPrivilegesCnt(data?.rolePrivilegesListCnt);
                }
            } catch (e) {
                errorHandle(e, navigate, "/sponsors");
            }
        })()
    }, []);

    return (
        <>
            {
                !(sponsor !== null && precondition !== null && subjectStatus !== null && roles !== null && rolesPrivileges !== null) &&
                <>
                    <Row gutter={[12, 12]} className={'ml-4'}>
                        <Col span={24}>
                            <h3><RightOutlined className={'mr-2'}/>Dashboard</h3>
                        </Col>
                    </Row>
                    <div className={style.absoluteCenter}>
                        <div className='text-center'>
                            <Spin size="large" indicator={<LoadingOutlined style={{fontSize: 64}} spin/>} />
                        </div>
                    </div>
                </>
            }
            {
                (sponsor !== null && precondition !== null && subjectStatus !== null && roles !== null && rolesPrivileges !== null) &&
                <>
                    <Row gutter={[12, 12]} className={'ml-4'}>
                        <Col span={24}>
                            <h3><RightOutlined className={'mr-2'}/>Dashboard</h3>
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]} className={'m-4'}>
                        <Col span={'12'}>
                            <div className={style.infoBackground}>
                                <Descriptions layout={'vertical'} title={'Sponsor'} bordered>
                                    <Descriptions.Item label={'Sponsor Name'} style={{width: '30%'}}>
                                        <span className={'lead'}>{sponsor.name}</span>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={'Logo'} style={{width: '30%'}}>
                                        {
                                            sponsor?.logo != null?(
                                                <Space className='card text-left p-2' align={'start'} size='middle'>
                                                    <img src={sponsor.logo} height={'100%'} width={'auto'}/>
                                                </Space>
                                                ) : (
                                                    <Notice>No Logo</Notice>
                                            )
                                        }
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>
                        </Col>
                        {/* Precondition */}
                        <Col span={'12'}>
                            <div className={`${style.infoBackground} ${style.TBD}`}>
                                <Descriptions title={
                                    <>
                                        <span className={'mr-4'}>Subject Status</span>
                                        {
                                            subjectStatus?.length > 0 &&
                                            <Tag>Total Count : {subjectStatus?.length}</Tag>
                                        }
                                    </>
                                } />
                                {
                                    subjectStatus?.length > 0 ? (
                                        <Table size={'small'} bordered dataSource={subjectStatus} pagination={{pageSize:3}} showHeader={true}>
                                            <Table.Column title={'No'} align={'center'} render={(row, record, index) => {
                                                return subjectStatus.indexOf(row) + 1;
                                            }} sorter={(a,b) => (a.id - b.id)} />
                                            <Table.Column title={'OID'} align={'center'} dataIndex={'oid'} />
                                            <Table.Column title={'Display Order'} align={'center'} dataIndex={'displayOrder'} />
                                            <Table.Column title={'Status'} align={'center'} dataIndex={'status'} render={(text, record) => <Tag color={record.color}>{text}</Tag>} />
                                            <Table.Column title={'Disable the next visit.'} dataIndex={'disabledNextVisit'} render={text => <Switch checked={text} disabled={true}></Switch>}/>
                                            <Table.Column title={'Exclude Subject Count'} dataIndex={'excludeSubjectCount'} render={text => <Switch checked={text} disabled={true}></Switch>}/>
                                        </Table>
                                    ) : (
                                        <Notice style={{height:'12.25rem', flexDirection:'column'}}>
                                            <h3 className='text-warning'><i className='fa fa-exclamation-circle' />{t('sponsor.no.registered.subject.status.info')}</h3>
                                            <Button type={'link'} onClick={() => navigate(`/sponsors/${params.sponsorId}/sponsor-subject-status`)}>
                                                {t('btn.text.shortcut.to.subject.status')}<i className='ml-2 fa fa-external-link' />
                                            </Button>
                                        </Notice>
                                    )
                                }
                            </div>
                        </Col>
                        {/* Subject Status */}
                        <Col span={'8'}>
                            <div className={style.infoBackground}>
                                <Descriptions title={
                                    <>
                                        <span className={'mr-4'}>Precondition</span>
                                        {
                                            preconditionCnt > 0 &&
                                            <Tag>Total Count : {preconditionCnt}</Tag>
                                        }
                                    </>
                                } />
                                {
                                    precondition?.length > 0 ? (
                                        <Table size={'small'} bordered dataSource={precondition} pagination={false}>
                                            <Table.Column title={'Action'} align={'center'} dataIndex={'action'} />
                                            <Table.Column title={'Precondition'} align={'center'} dataIndex={'precondition'}
                                                          render={(value) => {
                                                              const values = value.split(',');
                                                              return (
                                                                  <>
                                                                      {
                                                                          values.map(value => (
                                                                              <Tag>{value}</Tag>
                                                                          ))
                                                                      }
                                                                  </>
                                                              )
                                                          }} />
                                        </Table>
                                    ) : (
                                        <Notice style={{height:'12.25rem', flexDirection:'column'}}>
                                            <h3 className='text-warning'><i className='fa fa-exclamation-circle' />{t('sponsor.no.registered.precondition.info')}</h3>
                                            <Button type={'link'} onClick={() => navigate(`/sponsors/${params.sponsorId}/sponsor-precondition`)}>
                                                {t('btn.text.shortcut.to.precondition')}<i className='ml-2 fa fa-external-link' />
                                            </Button>
                                        </Notice>
                                    )
                                }
                            </div>
                        </Col>
                        {/*Role*/}
                        <Col span={'8'}>
                            <div className={style.infoBackground}>
                                <Descriptions title={
                                    <>
                                        <span className={'mr-4'}>Role</span>
                                        {
                                            roles?.length > 0 &&
                                            <Tag>Total Count : {roles?.length}</Tag>
                                        }
                                    </>
                                } />
                                {
                                    roles?.length > 0 ? (
                                        <Table size={'small'} bordered dataSource={roles} pagination={false} showHeader={true}>
                                            <Table.Column title={'No'} align={'center'} render={(row, record, index) => {
                                                return roles.length - roles.indexOf(row);
                                            }} sorter={(a,b) => (a.id - b.id)} />
                                            <Table.Column title={'Abbreviation Name'} align={'center'} dataIndex={'shortName'} />
                                            <Table.Column title={'Name'} align={'center'} dataIndex={'displayName'} />
                                            <Table.Column title={'Created Date'} align={'center'} dataIndex={'createdDate'} />
                                        </Table>
                                    ) : (
                                        <Notice style={{height:'12.25rem', flexDirection:'column'}}>
                                            <h3 className='text-warning'><i className='fa fa-exclamation-circle' />{t('sponsor.no.registered.role.info')}</h3>
                                            <Button type={'link'} onClick={() => navigate(`/sponsors/${params.sponsorId}/sponsor-roles`)}>
                                                {t('btn.text.shortcut.to.role')}<i className='ml-2 fa fa-external-link' />
                                            </Button>
                                        </Notice>
                                    )
                                }
                            </div>
                        </Col>
                        {/* Role-Privileges */}
                        <Col span={'8'}>
                            <div className={style.infoBackground}>
                                <Descriptions title={
                                    <>
                                        <span className={'mr-4'}>Role & Privilege</span>
                                        {
                                            rolesPrivilegesCnt > 0 &&
                                            <Tag>Total Count : {rolesPrivilegesCnt}</Tag>
                                        }
                                    </>
                                } />
                                {
                                    rolesPrivileges?.length > 0 ? (
                                        <Table size={'small'} bordered dataSource={rolesPrivileges} pagination={false} showHeader={true}>
                                            <Table.Column title={'Role'} align={'center'} dataIndex={'role'} />
                                            <Table.Column title={'Privileges'} align={'center'} dataIndex={'privileges'}
                                                          render={(value) => {
                                                              const values = value.split(',');
                                                              return (
                                                                  <>
                                                                      {
                                                                          values.map(value => (
                                                                              <Tag>{value}</Tag>
                                                                          ))
                                                                      }
                                                                  </>
                                                              )
                                                          }} />
                                        </Table>
                                    ) : (
                                        <Notice style={{height:'12.25rem', flexDirection:'column'}}>
                                            <h3 className='text-warning'><i className='fa fa-exclamation-circle' />{t('sponsor.no.registered.role.privilege.info')}</h3>
                                            <Button type={'link'} onClick={() => navigate(`/sponsors/${params.sponsorId}/sponsor-roles-privileges`)}>
                                                {t('btn.text.shortcut.to.role.privilege')}<i className='ml-2 fa fa-external-link' />
                                            </Button>
                                        </Notice>
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}

export default SponsorDashboard;
