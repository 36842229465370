import {Form, Input} from 'antd';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {isEmpty} from "../../../../lib/StringUtils";
import {getAllOID, isEnvProduction} from "../../../../lib/ODMUtils";
import {useTranslation} from "react-i18next";
const {Item} = Form;


const FormInputOID = ({selectedOID, allowDotChar = true, requiredMark = false, form, onChange = null, ref = null, isLabel= true}) => {
    const metaDataStore = useSelector((store) => store.metaDataStore);
    const [oids, setOids] = useState([]);

    const {t} = useTranslation();

    // const [oid, setOid] = useState(selectedOID);
    const pattern = allowDotChar ? /^([A-Za-z]+)([0-9]+)?(([_.])?([A-Za-z0-9_]+))*$/i : /^([A-Za-z]+)([0-9]+)?(([_])?([A-Za-z0-9_]+))*$/i;

    useEffect(() => {
        const mdv = metaDataStore.study.metaDataVersion[0];
        // console.log(v);
        setOids(getAllOID(mdv));
        return () => {
            setOids([]);
        }
    }, [metaDataStore]);

    const contains = (val) => {
        // console.log(selectedOID);
        if(!isEmpty(selectedOID)) return true;
        if(val===undefined) return true;
        const checkOid = val.toUpperCase();
        const returnValue = !oids.includes(checkOid);
        // console.log(checkOid, returnValue);

        if(!returnValue) {
            return checkOid + t('sponsor.already.in.use');
        }
        return returnValue;
    }

    const handleChange = (e) => {
        form.setFieldValue('oid', e.target.value.toUpperCase());
        // setOid(e.target.value.toUpperCase());
        // console.log(e.target.value.toUpperCase());
       if(onChange !== null) {
           onChange(e.target.value.toUpperCase());
       }
    }

    return (
        <Item ref={ref} name={'oid'} label={isLabel ? <span className='text-primary'>OID</span> : null} rules={[
            {required: true, message: t('message.warning.required-entry')},
            {pattern: pattern, message: t('message.format.is.not.valid')},
            // {len: 64, message: 'OID는 64자 까지 입력 가능합니다.'},
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if(isEmpty(value)){return Promise.resolve();}
                    const result = contains(value);
                    if(result !== true) {return Promise.reject(new Error(result));}
                    else {return Promise.resolve();}
                }
            })]}
              required={requiredMark}>
            <Input maxLength={64} readOnly={selectedOID !== ""} onChange={handleChange} style={{imeMode: 'disabled'}} />
        </Item>
    )

}

export default FormInputOID;
