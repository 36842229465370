import {Menu, Skeleton, Space} from "antd";
import {Link, useLocation, useMatch, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {isBuilderAccessAuthorities} from "../../../../lib/AuthorityUtils";
import {mdiFileDocumentMultipleOutline, mdiTextBoxEditOutline} from "@mdi/js";
import Icon from "@mdi/react";

const SubMenu = ({edcStudyInfo}) => {

    const authorities = useSelector(({user}) => (user?.user.authorities));

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedMenu, setSelectedMenu] = useState('');

    //Studies Match
    const isDashboard = useMatch('/studies/:sid/dashboard');
    const isCRFVersion = useMatch('/studies/:sid/versions');
    const isCRFVersionInMenu = useMatch('/studies/:sid/v/:id');
    const isBasicDefinition = useMatch('/studies/:sid/basic-definition');
    const isDefaultECS = useMatch('/studies/:sid/default-ecs');
    const isPageSetting = useMatch('studies/:sid/page-setting');
    const isRole = useMatch('/studies/:sid/roles');
    const isRoleAndPrivilege = useMatch('/studies/:sid/roles-privileges');
    const isRoleAndPrivilegeIWRS = useMatch('/studies/:sid/roles-privileges-iwrs');
    const isRolesLearningCourse = useMatch('/studies/:sid/roles-learning-course');
    const isPrecondition = useMatch('studies/:sid/precondition');
    const isManageSites = useMatch('/studies/:sid/sites');
    const isManageUsers = useMatch('/studies/:sid/users');
    const isCrfSetting = useMatch('/studies/:sid/crf-setting');
    const isEmailNotice = useMatch('/studies/:sid/email-notice')

    //Sponsor Match
    const isSponsorDashboard = useMatch('/sponsors/:sponsorId/sponsor-dashboard');
    const isSponsorPrecondition = useMatch('/sponsors/:sponsorId/sponsor-precondition');
    const isSponsorSubjectStatus = useMatch('/sponsors/:sponsorId/sponsor-subject-status');
    const isSponsorRoles = useMatch('/sponsors/:sponsorId/sponsor-roles');
    const isSponsorRoleAndPrivilege = useMatch('/sponsors/:sponsorId/sponsor-roles-privileges');

    //Global Library Match
    const isLibraryForms = useMatch('/global-library/v/:libraryId/forms');

    useEffect(() => {
        //Menu Select 변경
        if(location.pathname != null) {

            //Studies
            if(isDashboard !== null) {setSelectedMenu('dashboard');}
            else if(isCRFVersion !== null) {setSelectedMenu('crf-version');}
            else if(isCRFVersionInMenu !== null) {setSelectedMenu('crf-version');}
            else if(isBasicDefinition !== null) {setSelectedMenu('basic-definition');}
            else if(isDefaultECS !== null) {setSelectedMenu('default-ecs');}
            else if(isPageSetting !== null) {setSelectedMenu('page-setting');}
            else if(isRole !== null) {setSelectedMenu('roles');}
            else if(isRoleAndPrivilege !== null) {setSelectedMenu('roles-privileges');}
            else if(isRoleAndPrivilegeIWRS !== null) {setSelectedMenu('roles-privileges-iwrs');}
            else if(isRolesLearningCourse !== null) {setSelectedMenu('roles-learning-course');}
            else if(isPrecondition !== null) {setSelectedMenu('precondition');}
            else if(isManageSites !== null) {setSelectedMenu('manage-sites');}
            else if(isManageUsers !== null) {setSelectedMenu('manage-users');}
            else if(isCrfSetting !== null) {setSelectedMenu('crfSetting');}
            else if(isEmailNotice !== null) {setSelectedMenu('email-notice');}

            //Sponsors
            else if(isSponsorDashboard !== null) {setSelectedMenu('sponsor-dashboard');}
            else if(isSponsorPrecondition !== null) {setSelectedMenu('sponsor-precondition');}
            else if(isSponsorSubjectStatus !== null) {setSelectedMenu('sponsor-subject-status');}
            else if(isSponsorRoles !== null) {setSelectedMenu('sponsor-roles');}
            else if(isSponsorRoleAndPrivilege !== null) {setSelectedMenu('sponsor-roles-privileges');}

            //Global Libraries
            else if(isLibraryForms !== null) {setSelectedMenu('global-library-forms');}
        }

    }, [location.pathname]);


    const studiesMenu = [
        // Study
        {
            key:'study',
            icon:<i className='fe fe-edit-3'/>,
            label:'Study',
            onTitleClick:() => navigate(`/studies/${params.sid}/dashboard`),
            children: [
                {
                    key: 'dashboard',
                    label: <Link to={`/studies/${params.sid}/dashboard`}>Dashboard</Link>,
                    icon: <i className='fe fe-home'/>
                }
            ]
        },

        //CRF Design
        {
            key:'crf-design',
            icon:<i className='fe fe-menu'/>,
            label:'CRF Design',
            onTitleClick:() => navigate(`/studies/${params.sid}/versions`),
            children: [
                {
                    key: 'crf-version',
                    label: <Link to={`/studies/${params.sid}/versions`}>CRF Version</Link>,
                    icon: <i className='fe fe-file-text'/>
                },
                {
                    type: 'group',
                    label: 'CRF Settings',
                    children: [
                        {
                            key: 'basic-definition',
                            label: <Link to={`/studies/${params.sid}/basic-definition`}>Basic Definition</Link>,
                            icon: <i className='fa fa-search'/>
                        },
                        {
                            key: 'default-ecs',
                            label: <Link to={`/studies/${params.sid}/default-ecs`}>Default ECS</Link>,
                            icon: <i className='fa fa-question-circle-o'/>
                        },
                        {
                            key: 'page-setting',
                            label: <Link to={`/studies/${params.sid}/page-setting`}>Page Setting</Link>,
                            icon: <i className='fa fa-th'/>
                        }
                    ]
                }
            ]
        },

        //Settings
        {
            key: 'settings',
            icon: <i className='fe fe-settings' />,
            label: 'Settings',
            onTitleClick: () => navigate(`/studies/${params.sid}/roles`),
            children: [
                {
                    type: 'group',
                    label: 'Role & Privilege',
                    children: [
                        {
                            key: 'roles',
                            label: <Link to={`/studies/${params.sid}/roles`}>Role</Link>,
                            icon: <i className='fe fe-user'/>,
                        },
                        {
                            key: 'roles-privileges',
                            label: <Link to={`/studies/${params.sid}/roles-privileges`}>Role & Privilege</Link>,
                            icon: <i className='fe fe-check-square'/>,
                        },
                        // edcStudyInfo?.useRandomization &&
                        // {
                        //     key: 'roles-privileges-iwrs',
                        //     label: <Link to={`/studies/${params.sid}/roles-privileges-iwrs`}>Role & Privilege IWRS</Link>,
                        //     icon: <i className='fe fe-check-square'/>
                        // },
                        {
                            key: 'roles-learning-course',
                            label: <Link to={`/studies/${params.sid}/roles-learning-course`}>Role & Learning Course</Link>,
                            icon: <i className='fe fe-book-open'/>,
                        }
                    ]
                },
                {
                    type: 'group',
                    label: 'CRF Settings',
                    children: [
                        {
                            key: 'precondition',
                            label: <Link to={`/studies/${params.sid}/precondition`}>Precondition</Link>,
                            icon: <i className='fa fa-list-ul'/>
                        },
                        {
                            key: 'crfSetting',
                            label: <Link to={`/studies/${params.sid}/crf-setting`}>CRF Setting</Link>,
                            icon: <i className='fe fe-book'/>
                        },
                        {
                            key: 'email-notice',
                            label: <Link to={`/studies/${params.sid}/email-notice`}>E-Mail Notice</Link>,
                            icon: <i className='fa fa-file-text-o'/>
                        }
                    ]
                }
            ]
        },

        //Management
        isBuilderAccessAuthorities(authorities) &&
        {
            key: 'management',
            icon: <i className='fe fe-layers'/>,
            label: 'Management',
            onTitleClick: () => navigate(`/studies/${params.sid}/sites`),
            children: [
                {
                    type: 'group',
                    label: 'Site & User',
                    children: [
                        {
                            key: 'manage-sites',
                            icon: <i className='fe fe-grid'/>,
                            label: <Link to={`/studies/${params.sid}/sites`}>Manage Sites</Link>
                        },
                        {
                            key: 'manage-users',
                            icon: <i className={'fe fe-users'}/>,
                            label: <Link to={`/studies/${params.sid}/users`}>Manage Users</Link>
                        }
                    ]
                }
            ]
        }
    ];

    const sponsorsMenu = [
        {
            key: 'sponsor-dashboard',
            icon: <i className='fe fe-info'/>,
            label: <Link to={`/sponsors/${params.sponsorId}/sponsor-dashboard`}>Dashboard</Link>,
        },
        {
            key:'sponsor-precondition',
            icon:<i className='fa fa-list-ul'/>,
            label:<Link to={`/sponsors/${params.sponsorId}/sponsor-precondition`}>Precondition</Link>,
        },
        {
            key:'sponsor-subject-status',
            icon:<i className='fa fa-list-alt' />,
            label:<Link to={`/sponsors/${params.sponsorId}/sponsor-subject-status`}>Subject Status</Link>,
        },
        {
            key:'sponsor-roles',
            icon:<i className='fa fa-user' />,
            label:<Link to={`/sponsors/${params.sponsorId}/sponsor-roles`}>Role</Link>,
        },
        {
            key: 'sponsor-roles-privileges',
            icon: <i className='fa fa-check-square-o'/>,
            label: <Link to={`/sponsors/${params.sponsorId}/sponsor-roles-privileges`}>Role & Privilege</Link>
        }
    ];

    const globalLibraryMenu = [
        {
            key: 'global-library-forms',
            icon: <Icon path={mdiFileDocumentMultipleOutline} size={1} className={'pt-2'} />,
            label: <Link to={`/global-library/v/${params.libraryId}/forms`}>Forms</Link>,
        },
    ]

    const menu = [
        ...(edcStudyInfo !== null && params.sid !== 'edit' && params.sid !== 'manage-access')?studiesMenu:[],
        ...(params.sponsorId !== undefined && params.sponsorId !== 'edit')?sponsorsMenu:[],
        ...(params.libraryId !== undefined && params.libraryId !== 'edit')?globalLibraryMenu:[],
    ]

    return (
        <>
            {
                (edcStudyInfo === null && params.sid != null && params.sid !== 'edit' && params.sid !== 'manage-access') && (
                    <Space className={'pl-4'}>
                        <Skeleton.Button className={'mt-2 mr-4'} shape={'square'} active={'true'} />
                        <Skeleton.Button className={'mt-2 mr-4'} shape={'square'} active={'true'} />
                        <Skeleton.Button className={'mt-2 mr-4'} shape={'square'} active={'true'} />
                        <Skeleton.Button className={'mt-2 mr-4'} shape={'square'} active={'true'} />
                    </Space>
                )
            }
            <Menu mode="horizontal" key={'builder-menu'} selectedKeys={selectedMenu}
                  items={menu}
                  style={{
                      lineHeight: '60px',
                      flex: ((params.sid !== undefined && params.sid !== 'edit')||
                          (params.sponsorId !== undefined && params.sponsorId !== 'edit')||
                          (params.libraryId !== undefined && params.libraryId !== 'edit'))?'1':false
                  }} />
        </>
    )
}

export default SubMenu;
