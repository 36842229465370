import React, {Fragment, useEffect, useState} from "react";
import ModalFooter from "../ModalFooter";
import {Card, Cascader, Col, Divider, Dropdown, Form, InputNumber, Modal, Row, Select, Switch, Tag} from "antd";
import FormInputOID from "../../../../components/common/odm/ant-form/FormInputOID";
import FormInputName from "../../../../components/common/odm/ant-form/FormInputName";
import FormInput from "../../../../components/common/odm/ant-form/FormInput";
import FormSelectDataTypes from "../../../../components/common/odm/ant-form/FormSelectDataTypes";
import DescriptionOrQuestion from "../../../../components/common/odm/ant-form/DescriptionOrQuestion";
import FormInputNumber from "../../../../components/common/odm/ant-form/FormInputNumber";
import {DownOutlined} from "@ant-design/icons";
import CodeListContainer from "../../container/CodeListContainer";
import {isEmpty} from "../../../../lib/StringUtils";
import ModalTitle from "../component/ModalTitle";
import {
    allowExtensionOptions,
    controlTypes,
    dataTypes,
    getCascadeLabel,
    getSetupDataLink,
    getSetupReferenceFile,
    isEnvProduction,
    unknownDateFormat
} from "../../../../lib/ODMUtils";
import {useSelector} from "react-redux";
import {WithContext as ReactTags} from 'react-tag-input';
import "reactTags.css";
import {useTranslation} from "react-i18next";

const KeyCodes = {
    comma: 188,
    enter: 13
};

const ItemDef = ({ metaData, selectedOID, itemDef, setItemDef, visible, languages, codeLists, valueLists = [], onFinish, onCancel }) => {
    const [form] = Form.useForm();

    const [isInit, setIsInit] = useState(true);
    const [mandatoryDigits, setMandatoryDigits] = useState(false);
    const [mandatoryLength, setMandatoryLength] = useState(false);
    const [mandatoryPrefix, setMandatoryPrefix] = useState(false);
    const [mandatoryPostfix, setMandatoryPostfix] = useState(false);
    const [showAllowFutureDate, setShowAllowFutureDate] = useState(false);
    const [showAllowOnlyAlphabet, setShowAllowOnlyAlphabet] = useState(false);
    const [showOnlyUpperCase, setShowOnlyUpperCase] = useState(false);
    const [showAllowSpace, setShowAllowSpace] = useState(false);
    const [showUnknownDateFormat, setShowUnknownDateFormat] = useState(false);
    const [showAllowWords, setShowAllowWords] = useState(false);
    const [showAllowExtensions, setShowAllowExtensions] = useState(false);
    const [showTextAlignJustify, setShowTextAlignJustify] = useState(false);
    const [showDataLink, setShowDataLink] = useState(false);
    const [showReferenceFile, setShowReferenceFile] = useState(false);
    const [showLabel, setShowLabel] = useState(false);

    const [dataLinkSources, setDataLinkSources] = useState([]);
    const [referenceFileSources, setReferenceFileSources] = useState([]);

    const [showCodeList, setShowCodeList] = useState(false);
    const [showValueList, setShowValueList] = useState(false);
    const [collapseIn, setCollapseIn] = useState(false);
    const [filteredCodelist, setFilteredCodelist] = useState([]);
    const [enabledDataTypes, setEnabledDataTypes] = useState([]);
    const [controlType, setControlType] = useState(null);
    const [allowWords, setAllowWords] = useState([]);

    const [switchLabelOIDs, setSwitchLabelOIDs] = useState([]);

    const [ukFormat, setUkFormat] = useState("ALL_UK");
    const [defaultMonth, setDefaultMonth] = useState(1);
    const [defaultDay, setDefaultDay] = useState(1);

    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);

    const {t} = useTranslation();

    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    //CodeList Item Setting
    const [defVisible, setDefVisible] = useState(null);
    const [defType, setDefType] = useState(null);
    const [defItem, setDefItem] = useState(null);
    // const [options, setOptions] = useState([]);
    const toggleCollapse = () => {
        setCollapseIn(!collapseIn);
    }

    const handleAllowWordDelete = i => {
        setAllowWords(allowWords.filter((tag, index) => index !== i));
    };

    const handleAllowWordAddition = tag => {
        tag.text = tag.text.toUpperCase();
        setAllowWords([...allowWords, tag]);
    };

    useEffect(() => {
        const dataSources = Array.from(metaData.studyEvent.values()).filter(e => e.type !== 'UNSCHEDULED').map(e => {
            const studyEvent = {value: e.oid, label: e.name};
            studyEvent.children = e.formRef.filter(f => metaData.form.get(f.formOID) != null && metaData.form.get(f.formOID).formType !== 'SAE_REPORT_FORM')
                .filter(f => metaData.form.get(f.formOID).itemGroupRef.filter(g => metaData.itemGroup.get(g.itemGroupOID).repeating === 'SIMPLE').length > 0)
                .map(f => {
                const formDef = metaData.form.get(f.formOID);
                const form = {value: formDef.oid, label: formDef.name};
                form.children = formDef.itemGroupRef.filter(g => metaData.itemGroup.get(g.itemGroupOID).repeating === 'SIMPLE').map(ig => {
                    const itemGroupDef = metaData.itemGroup.get(ig.itemGroupOID);
                    const itemGroup = {value: itemGroupDef.oid, label: itemGroupDef.name};
                    itemGroup.children = itemGroupDef.itemRef.map(i => {
                        const itemDef = metaData.item.get(i.itemOID);
                        return {value: itemDef.oid, label:`${itemDef.name} [${itemDef.oid}]`};
                    })
                    return itemGroup;
                })
                return form;
            });
            return studyEvent;
        }).filter(e => e.children.length > 0);

        setDataLinkSources(dataSources.filter(e => e.children.length > 0 || e.children?.filter(f => f.children.length > 0)));

        const currentFileDataSources = {
            value : '%CURRENT%',
            label: 'Current',
            children: Array.from(metaData.form.values())
                .filter(f => f.itemGroupRef.some(ig => metaData.itemGroup.get(ig.itemGroupOID)
                    .itemRef.some(i => metaData.item.get(i.itemOID)?.controlType === 'FILE_UPLOAD')))
                .map(formDef => {
                    const form = {value: formDef.oid, label: formDef.name};
                    form.children = formDef.itemGroupRef.filter(g => metaData.itemGroup.get(g.itemGroupOID).repeating === 'NO').map(ig => {
                        const itemGroupDef = metaData.itemGroup.get(ig.itemGroupOID);
                        const itemGroup = {value: itemGroupDef.oid, label: itemGroupDef.name};
                        itemGroup.children = itemGroupDef.itemRef
                            .filter(i => metaData.item.get(i.itemOID)?.controlType === 'FILE_UPLOAD')
                            .map(i => {
                                const itemDef = metaData.item.get(i.itemOID);
                                return {value: itemDef.oid, label: `${itemDef.name} [${itemDef.oid}]`};
                            })
                        return itemGroup;
                    })
                    return form;
                })
        }

        const referenceFileDataSources = Array.from(metaData.studyEvent.values())
            .filter(e => e.type !== 'UNSCHEDULED')
            .filter(e => e.formRef.some(f => metaData.form.get(f.formOID)
                .itemGroupRef.some(ig => metaData.itemGroup.get(ig.itemGroupOID)
                    .itemRef.some(i => metaData.item.get(i.itemOID)?.controlType === 'FILE_UPLOAD'))))
            .map(e => {
                const studyEvent = {value: e.oid, label: e.name};
                studyEvent.children = e.formRef
                    .filter(f => metaData.form.get(f.formOID) != null)
                    .filter(f => metaData.form.get(f.formOID)
                        .itemGroupRef.some(ig => metaData.itemGroup.get(ig.itemGroupOID)
                            .itemRef.some(i => metaData.item.get(i.itemOID)?.controlType === 'FILE_UPLOAD')))
                    .map(f => {
                        const formDef = metaData.form.get(f.formOID);
                        const form = {value: formDef.oid, label: formDef.name};
                        form.children = formDef.itemGroupRef.filter(g => metaData.itemGroup.get(g.itemGroupOID).repeating === 'NO').map(ig => {
                            const itemGroupDef = metaData.itemGroup.get(ig.itemGroupOID);
                            const itemGroup = {value: itemGroupDef.oid, label: itemGroupDef.name};
                            itemGroup.children = itemGroupDef.itemRef
                                .filter(i => metaData.item.get(i.itemOID)?.controlType === 'FILE_UPLOAD')
                                .map(i => {
                                    const itemDef = metaData.item.get(i.itemOID);
                                    return {value: itemDef.oid, label: `${itemDef.name} [${itemDef.oid}]`};
                                })
                            return itemGroup;
                        })
                        return form;
                    });
            return studyEvent;
        }).filter(e => e.children.length > 0);
        setReferenceFileSources([
            currentFileDataSources,
            ...referenceFileDataSources.filter(e => e.children.length > 0 || e.children?.filter(f => f.children.length > 0))
        ]);

    }, [metaData]);

    useEffect(() => {
        const editChecks = [...metaData.editCheck.values()];
        const switchLabelList = [];
        editChecks.filter(ecs => ecs.action.type === 'SWITCH_TEXT').forEach(ecs => {
            switchLabelList.push({value:ecs.oid, label:ecs.name});
        });

        setSwitchLabelOIDs(switchLabelList);
    }, []);

    useEffect(() => {

        if(itemDef?.controlType != null && itemDef?.dataType != null) {
             loadPropertiesInfo(itemDef);
        }

        setItemDef(itemDef);
        if(isInit) {
            let newItemDef = {...itemDef};
            const {dataLink, referenceFileSource} = newItemDef;

            //DataLink 설정
            newItemDef = getSetupDataLink(dataLink, newItemDef);
            //ReferenceFile 설정
            newItemDef = getSetupReferenceFile(referenceFileSource, newItemDef);

            // console.log(newItemDef);
            form.setFieldsValue(newItemDef);
            setIsInit(false);

            if(newItemDef?.allowWords ?? false) {
                const words = [];
                newItemDef.allowWords.forEach(w => words.push({id:w, text:w}));
                setAllowWords(words);
            }
        } else {
            const item = {...form.getFieldsValue()};
            item.dataLink = {};
            form.setFieldsValue(item);
        }
    }, [itemDef]);

    //CodeLists 변수 감지 확인
    useEffect(() => {
        if(codeLists) {
            const type = form.getFieldValue('dataType');
            //type이 null, undefined, ""이 아닌 경우 필터동작을 활성화 함.
            if(!isEmpty(type)) {
                const filtered = codeLists.filter(c => c.dataType === type);
                setFilteredCodelist(filtered);
            }
        }
    }, [codeLists]);


    const loadPropertiesInfo = (itemDef) => {
        const controlType = controlTypes.find(t => t.value === itemDef?.controlType);
        setControlType(controlType);
        const dataType = itemDef?.dataType;
        const dataTypeArr = controlType.dataTypes;
        setEnabledDataTypes(dataTypes.filter(t => dataTypeArr.findIndex(type => type === t.value) !== -1));

        setShowCodeList(controlType.codeListRef);
        setShowValueList(controlType.valueListRef);
        setShowAllowFutureDate(controlType.value === 'DATEPICKER');
        setFilteredCodelist([]);

        setMandatoryPrefix(controlType.value === "INPUT");
        setMandatoryPostfix(controlType.value === "INPUT");

        setMandatoryDigits((dataType === 'FLOAT' || dataType === 'DOUBLE'));
        setMandatoryLength((dataType === 'DATE' || dataType === 'TIME' || dataType === 'PARTIAL_DATE' || dataType === 'PARTIAL_TIME' || dataType === 'INTEGER' || dataType === 'FLOAT' || dataType === 'DOUBLE' || dataType === 'TEXT' || dataType === 'STRING'));
        setShowUnknownDateFormat(controlType.value === 'DATEPICKER' && (dataType === 'PARTIAL_DATE'));
        setShowAllowOnlyAlphabet(controlType.value === 'INPUT' && dataType === 'TEXT');
        setShowOnlyUpperCase(itemDef?.allowOnlyAlphabet && (controlType.value === 'INPUT' && dataType === 'TEXT'));
        setShowAllowSpace(itemDef?.allowOnlyAlphabet && (controlType.value === 'INPUT' && dataType === 'TEXT'));

        setShowAllowWords(controlType.value === "INPUT" && (dataType === 'INTEGER' || dataType === 'FLOAT'));
        setShowAllowExtensions(controlType.value === 'FILE_UPLOAD' && dataType === 'TEXT');
        setShowTextAlignJustify(controlType.value === "RADIOBUTTON_HORIZONTAL" && (dataType === 'INTEGER' || dataType === 'TEXT'));
        setShowDataLink(controlType.value === 'DROPDOWN_DATALINK');
        setShowReferenceFile(controlType.value === 'FILE_DOWNLOAD');
        setShowLabel(controlType.value === 'LABEL');

        setUkFormat(itemDef?.unknownDateFormat);
        setDefaultMonth((itemDef?.defaultMonth) !== null ? (itemDef?.defaultMonth) : 1);
        setDefaultDay((itemDef?.defaultDay) !== null ? (itemDef?.defaultDay) : 1);

        if (codeLists.length > 0) {
            const filtered = controlType.value === 'DICTIONARY' ? codeLists.filter(c => c.dataType === dataType && c.externalCodeList != null) : codeLists.filter(c => c.dataType === dataType && c.codeListItem != null);
            setFilteredCodelist(filtered);
        }
    }

    const onChangeControlType = (value) => {

        if(value != null) {
            const controlType = controlTypes.find(t => t.value === value);
            // console.log(controlType);
            setControlType(controlType);
            const dataTypeArr = controlType.dataTypes;
            setEnabledDataTypes(dataTypes.filter(t => dataTypeArr.findIndex(type => type === t.value) !== -1));
            setShowCodeList(controlType.codeListRef);
            setShowValueList(controlType.valueListRef);
            setShowAllowOnlyAlphabet(false);
            setShowOnlyUpperCase(false);
            setShowAllowSpace(false);
            setShowUnknownDateFormat(false);
            setFilteredCodelist([]);
            // setFiltered

            setShowTextAlignJustify(value === "RADIOBUTTON_HORIZONTAL");
            setShowAllowFutureDate(value === 'DATEPICKER');
            setMandatoryPrefix(value === "INPUT");
            setMandatoryPostfix(value === "INPUT");
            const type = form.getFieldValue('dataType');
            setShowAllowWords(value === "INPUT" && (type === 'INTEGER' || type === 'FLOAT'));
            setShowAllowExtensions(value === 'FILE_UPLOAD' && type === 'TEXT');
            setShowDataLink(value === 'DROPDOWN_DATALINK');
            setShowReferenceFile(value === 'FILE_DOWNLOAD');
            setShowLabel(value === 'LABEL');
        }

        // setEnabledDataTypes(option.dataTypes);
        //
        // const dataType = option.dataTypes[0];//첫번째 기본 선택
        form.setFieldsValue({
            dataType:null,
            codeListRef:null,
            valueListRef:null,
            allowFutureDate: null,
            allowOnlyAlphabet: null,
            onlyUpperCase: null,
            allowSpace: null,
        });
        // checkDataType(dataType);
    }

    const onChangeDataType = (type, option) => {
        checkDataType(type);
    }

    const onChangeCodeListOrValueList = (_, value) => {
        let codeListRef = form.getFieldValue(['codeListRef', 'codeListOID']);
        let valueListRef = form.getFieldValue(['valueListRef', 'valueListOID']);
        let isCodeListRef = isEmpty(codeListRef) == false;
        let isValueListRef = isEmpty(valueListRef) == false;

        if(showCodeList && showValueList) {
            if (isCodeListRef && isValueListRef) {
                return Promise.reject(new Error(t('message.only.select.either.code.list.or.value.list')));
            } else if (!isCodeListRef && !isValueListRef) {
                return Promise.reject(new Error(t('message.pls.select.either.code.list.or.value.list')));
            }
        } else if(showCodeList && !isCodeListRef) {
            return Promise.reject(new Error(t('message.this.is.required.selection')));
        } else if(showValueList && !isValueListRef) {
            return Promise.reject(new Error(t('message.this.is.required.selection')));
        }

        return Promise.resolve();
    }


    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    const onSubmit = (newItemDef) => {
        if(showAllowWords && allowWords.length > 0) {
            const words = allowWords.map(w => w.text.toUpperCase());
            newItemDef.allowWords = words;
        }

        // console.log('org ItemDef => ', newItemDef);
        if(newItemDef.dataLink?.dataLinkSources?.length === 4) {
            const [studyEventOID, formOID, itemGroupOID, itemOID] = newItemDef.dataLink.dataLinkSources;
            newItemDef.dataLink.source = {studyEventOID, formOID, itemGroupOID, itemOID};
            // delete newItemDef.dataLink.dataLinkSources;
        }

        if(newItemDef?.referenceFileSource?.length === 4) {
            const [studyEventOID, formOID, itemGroupOID, itemOID] = newItemDef.referenceFileSource;
            newItemDef.referenceFileSource = {studyEventOID, formOID, itemGroupOID, itemOID};
        }

        //
        onFinish(newItemDef);
    }

    const checkDataType = (type) => {
        // setShowUnits((type === 'INTEGER' || type === 'FLOAT' || type === 'DOUBLE'));
        setMandatoryDigits((type === 'FLOAT' || type === 'DOUBLE'));
        setMandatoryLength((type === 'DATE' || type === 'TIME' || type === 'PARTIAL_DATE' || type === 'PARTIAL_TIME' || type === 'INTEGER' || type === 'FLOAT' || type === 'DOUBLE' || type === 'TEXT' || type === 'STRING'));
        setShowUnknownDateFormat((type === 'PARTIAL_DATE'));

        if(controlType != null) {
            setShowValueList(controlType.valueListRef === true && type === 'TEXT');
            setShowAllowFutureDate(controlType.value === 'DATEPICKER');//date, partialDate 인 경우 보이도록 처리
            setShowAllowOnlyAlphabet(controlType.value === 'INPUT' && type === "TEXT");//input이면서 text일 때 보이도록 처리
            setShowAllowWords(controlType.value === 'INPUT' && (type === "INTEGER" || type === "FLOAT"));//input이면서 text일 때 보이도록 처리
            setShowAllowExtensions(controlType.value === 'FILE_UPLOAD' && type === 'TEXT');//File Upload이면서 Text일 때 보이도록 처리
        }

        let length = null;
        if(type === 'DATE' || type === 'PARTIAL_DATE') {
            length = 10;
        } else if(type === 'TIME' || type === 'PARTIAL_TIME') {
            length = 5;
        }

        form.setFieldsValue({
            length,
            fractionDigits:null,
            codeListRef:null,
        });

        if(controlType != null) {
            if (codeLists.length > 0) {
                const filtered = controlType.value === 'DICTIONARY' ? codeLists.filter(c => c.dataType === type && c.externalCodeList != null) : codeLists.filter(c => c.dataType === type && c.codeListItem != null);
                setFilteredCodelist(filtered);
            }
        }
    }

    const onOnlyAlphabetChange = (value) => {
        setShowOnlyUpperCase(value);
        setShowAllowSpace(value);
    }

    const menu = {
        items : [
            {
                key: 'externalCodeList',
                label: <><i className={'fa fa-plus mr-2'} />ExternalCodeList</>
            }
        ],
        onClick: ({key}) => {
            onCreate(key);
        }
    }

    /**
     * CodeList Create Method
     * @param type
     */
    const onCreate = (type) => {
        setDefVisible(true);
        setDefType(type);
        setDefItem(null);
    }

    const onCodeListSelect = (value) => {
        const codeList = metaData.codeList.get(value);
        if(codeList.codeListItem != null) {
            let max = -1;
            codeList.codeListItem.forEach(c => {
                if(c.codedValue.toString().length > max) {
                    max = c.codedValue.toString().length;
                }
            })
            if(max > -1) {form.setFieldsValue({length: max});}
        }
    }

    const ukChange = (selectedUk) => {
        setUkFormat(selectedUk);

        let length = 10;
        if(selectedUk === 'YEAR_UK') {
            length = 2;
        } else if(selectedUk === 'YEAR_SHORT') {
            length = 4;
        } else if(selectedUk === 'MONTH_SHORT' || selectedUk === 'MONTH_UK_SHORT') {
            length = 7;
        }

        form.setFieldsValue({
            length,
            fractionDigits:null
        });
    }

    return (
        <Modal
            forceRender={true}
            wrapClassName={'odm-modal'}
            title={<ModalTitle iconType={'itemDef'}>Item</ModalTitle>}
            width={800}
            onCancel={onCancel}
            destroyOnClose={true}
            open={visible}
            styles={{body:{overflowY: 'auto', height: 'calc(100vh - 20rem)'}}}
            footer={<ModalFooter onCancel={onCancel} form={form}/>}
        >
            <Form layout={'vertical'} onFinish={onSubmit} onFinishFailed={onFinishFailed} form={form}>
                <Card title={'Attributes'} type={'inner'} className={'mb-2'}>
                    {/*<Space hidden={!isEmpty(selectedOID)} className={'mb-3'}>*/}
                    {/*    <AutoComplete options={options} style={{width:695}} onSearch={debounce(handleSearch, 250)} onSelect={onSelect}>*/}
                    {/*        <Input.Search size="middle" placeholder="CDASH Model 1.2" enterButton/>*/}
                    {/*    </AutoComplete>*/}
                    {/*</Space>*/}
                    <Row gutter={[20, 0]}>
                        <Col span={6}>
                            <FormInputOID selectedOID={selectedOID} requiredMark={true} allowDotChar={false} form={form} />
                        </Col>
                        <Col span={18}>
                            <FormInputName name={itemDef?.name} defType={'itemDef'} form={form}/>
                        </Col>

                        {/*<Col span={12}>*/}
                        {/*    <FormInput onKeyup={changeSasFieldName} name={'sasfieldName'} label={'SAS Field Name'}*/}
                        {/*               rules={[*/}
                        {/*                   {required: true, message: "필수 항목 입니다."},*/}
                        {/*                   {pattern: /^([a-zA-Z_][a-zA-Z0-9_]+){1,8}$/i, message: "형식이 올바르지 않습니다."},*/}
                        {/*                   {max: 8, message: "최대 8자리까지 입력 가능 합니다."}*/}
                        {/*               ]}/>*/}
                        {/*</Col>*/}
                        <Col span={8}>
                            <Form.Item name={'controlType'} label={<span className='text-primary'>{'Control Type'}</span>}
                                       rules={[{required: true, message:t('message.this.is.required.selection')}]}
                                       required={true}>
                                {
                                    isProd?(
                                        <Tag className={'p-2'}>
                                            <span className={"font-size-16 font-weight-bold"}>
                                                {controlTypes.find(controlType => controlType.value === itemDef?.controlType)?.label||'N/A'}
                                            </span>
                                        </Tag>
                                    ):(
                                        <Select onChange={onChangeControlType} options={controlTypes} />
                                    )
                                }
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <FormSelectDataTypes dataTypes={enabledDataTypes} onChange={onChangeDataType} value={itemDef?.dataType}/>
                        </Col>
                        {
                            controlType?.value?.startsWith('CHECKBOXES_') &&
                            <Col span={8}>
                                <Form.Item label={<span className='text-primary'>Output Individual Values</span>} name={'outputIndividualValues'} valuePropName="checked" initialValue={'true'}>
                                    <Switch disabled={isProd} />
                                </Form.Item>
                            </Col>
                        }

                        {
                            (mandatoryLength || mandatoryDigits) &&
                            <Fragment>
                                {
                                    mandatoryLength &&
                                    <Col span={mandatoryDigits?4:8}>
                                        <FormInputNumber required={mandatoryLength} name={'length'} label={'Length'} style={{width: '100%'}}
                                                         min={1}
                                                         rules={[
                                                             {
                                                                 required: mandatoryLength,
                                                                 message: t('message.warning.required-entry')
                                                             },
                                                             {
                                                                 type: 'number',
                                                                 max: 4000,
                                                                 message: t('message.length.value.can.be.up.to.4000')
                                                             },
                                                             {
                                                                 validator: async (_, length) => {
                                                                     const fractionDigits = form.getFieldValue('fractionDigits');
                                                                     const isError = (length - fractionDigits) < 2;

                                                                     if(fractionDigits == null) {
                                                                         return Promise.resolve();
                                                                     } else if(isError) {
                                                                         return Promise.reject(new Error(t('message.length.value.must.be.least.2.greater.than.value.of.fraction.digits')));
                                                                     } else {
                                                                         return Promise.resolve();
                                                                     }
                                                                 }
                                                             }
                                                         ]}/>
                                    </Col>
                                }
                                {
                                    showUnknownDateFormat &&
                                    <Col span={13}>
                                        <Form.Item label={'Unknown Date Format'} name={'unknownDateFormat'} initialValue={'ALL_UK'}
                                                   rules={[{required: true, message: t('message.pls.specify.uk.date.format')}]}>
                                            <Select options={unknownDateFormat} onChange={ukChange}/>
                                        </Form.Item>
                                    </Col>
                                }

                                {
                                    (showUnknownDateFormat && ukFormat === "YEAR_SHORT") &&
                                    <Col span={4}>
                                        <Form.Item label={'Default Month'} name={'defaultMonth'} initialValue={1}
                                                   rules={[{required: true, message: t('message.pls.specify.reference.month')}]}>
                                            <Select options={[
                                                { value: 1, label: '1'}, { value: 2, label: '2'}, { value: 3, label: '3'},
                                                { value: 4, label: '4'}, { value: 5, label: '5'}, { value: 6, label: '6'},
                                                { value: 7, label: '7'}, { value: 8, label: '8'}, { value: 9, label: '9'},
                                                { value: 10, label: '10'}, { value: 11, label: '11'}, { value: 12, label: '12'},
                                            ]} value={defaultMonth} />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    (showUnknownDateFormat && (ukFormat === "YEAR_SHORT" || ukFormat === "MONTH_SHORT")) &&
                                    <Col span={4}>
                                        <Form.Item label={'Default Day'} name={'defaultDay'} initialValue={1}
                                                   rules={[{required: true, message: t('message.pls.specify.reference.date')}]}>
                                            <InputNumber style={{width: '100%'}} min={1} max={31} precision={0} stringMode={false} value={defaultDay} />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    (showAllowFutureDate && (showUnknownDateFormat ? ((ukFormat === "YEAR_SHORT" || ukFormat === "MONTH_SHORT") ? true : false) : true)) &&
                                    <Col span={5}>
                                        <Form.Item label={<span className='text-primary'>Allow Future Date</span>} valuePropName="checked" name={'allowFutureDate'}>
                                            <Switch disabled={isProd} />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    mandatoryDigits &&
                                    <Col span={5}>
                                        <FormInputNumber required={mandatoryDigits} name={'fractionDigits'} label={'Fraction Digits'}
                                                         style={{width: '100%'}} min={1}
                                                         rules={[
                                                             {
                                                                 required: mandatoryDigits,
                                                                 message: t('message.warning.required-entry')
                                                             },
                                                             {
                                                                 type: 'number',
                                                                 max: 50,
                                                                 message: t('message.value.for.fraction.digits.can.be.input.up.to.50')
                                                             }
                                                         ]}/>
                                    </Col>
                                }
                                {
                                    showAllowOnlyAlphabet &&
                                    <Col span={(showOnlyUpperCase&&showAllowSpace)?6:24}>
                                        <Form.Item label={'Allow Only Alphabet'} valuePropName="checked" className={'text-primary'} name={'allowOnlyAlphabet'}>
                                            <Switch disabled={isProd} onChange={onOnlyAlphabetChange} />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    showOnlyUpperCase &&
                                    <Col span={6}>
                                        <Form.Item label={'Only UpperCase'} valuePropName="checked" className={'text-primary'} name={'onlyUpperCase'}>
                                            <Switch disabled={isProd} />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    showAllowSpace &&
                                    <Col span={12}>
                                        <Form.Item label={'Allow Space'} valuePropName="checked" className={'text-primary'} name={'allowSpace'}>
                                            <Switch disabled={isProd} />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    mandatoryPrefix &&
                                    <Col span={12}>
                                        <FormInput required={false} name={'prefix'} label={'Left Text'}
                                                   rules={[
                                                       {
                                                           max: 20,
                                                           message: t('message.prefix.value.can.be.input.up.to.20.characters')
                                                       }
                                                   ]} />
                                    </Col>
                                }
                                {
                                    mandatoryPostfix &&
                                    <Col span={12}>
                                        <FormInput required={false} name={'postfix'} label={'Right Text'}
                                                   rules={[
                                                       {
                                                           max: 20,
                                                           message: t('message.postfix.value.can.be.input.up.to.20.characters')
                                                       }
                                                   ]} />
                                    </Col>
                                }
                                {
                                    showLabel &&
                                    <Col span={12}>
                                        <FormInput required={showLabel} name={'labelText'} label={'Label Text'}
                                                   rules={[
                                                       {
                                                           required: showLabel,
                                                           message: t('message.warning.required-entry')
                                                       },
                                                       {
                                                           min: 1,
                                                           message: t('message.pls.input.at.least.1.character.for.Label.Text')
                                                       },
                                                       {
                                                           validator: async (_, labelText) => {
                                                               const lengthValue = form.getFieldValue('length');

                                                               if(lengthValue == null) {
                                                                   return Promise.reject(new Error(t('message.pls.enter.length.value')));
                                                               } else if(labelText?.length > lengthValue) {
                                                                   return Promise.reject(new Error(t('message.enter.up.to.length.value.characters',{lengthValue: lengthValue})));
                                                               } else {
                                                                   return Promise.resolve();
                                                               }
                                                           }
                                                       }
                                                   ]} />
                                    </Col>
                                }

                                {
                                    showTextAlignJustify &&
                                    <Col span={6}>
                                        <Form.Item label={<span className='text-primary'>Align Text(Justify)</span>} valuePropName="checked" name={'alignJustify'}>
                                            <Switch disabled={isProd} />
                                        </Form.Item>
                                    </Col>
                                }

                                {
                                    showAllowWords &&
                                    <Col span={24}>
                                        <div style={{marginBottom:15, width:'100%'}}>
                                            <div className={'text-primary'}>Allow Words</div>
                                            <ReactTags
                                                tags={allowWords}
                                                // suggestions={suggestions}
                                                delimiters={delimiters}
                                                handleDelete={handleAllowWordDelete}
                                                handleAddition={handleAllowWordAddition}
                                                // handleDrag={handleAllowWordDrag}
                                                // handleTagClick={handleTagClick}
                                                autofocus={false}
                                                focus={false}
                                                inputFieldPosition="inline"
                                                readOnly={isProd}
                                                placeholder={'e.g. ND, UK,..'}
                                                allowDragDrop={false}
                                            />
                                        </div>
                                    </Col>
                                }
                                {
                                    showAllowExtensions &&
                                    <>
                                        <Col span={12}>
                                            <Form.Item label={<span className='text-primary'>Allow Extensions</span>}
                                                       name={'allowExtensions'} rules={[{required: true, message:'필수 선택 항목입니다.'}]}>
                                                <Select mode={'multiple'} options={allowExtensionOptions} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label={<span className='text-primary'>Limit File Size (MB)</span>}
                                                       name={'limitFileSize'} rules={[{required: true, message:'필수 입력 항목입니다.'}]}>
                                                <InputNumber min={0} max={1024} placeholder={'MB 단위로 입력'} style={{width:'100%'}} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                            </Fragment>
                        }



                        <Col span={24} hidden={!showCodeList}>
                            <Form.Item label={
                                <>
                                    <span style={{flex:'1 0 fit-content'}} className={'mr-2'}>CodeList Reference</span>
                                    {
                                        !isProd &&
                                        <Dropdown.Button size={'small'} menu={menu}
                                                         icon={<DownOutlined/>}
                                                         onClick={() => onCreate('codeListItem')}>
                                            <i className={'fa fa-plus mr-2'}/>CodeListItem
                                        </Dropdown.Button>
                                    }
                                </>
                            } name={['codeListRef', 'codeListOID']} rules={[{validator: onChangeCodeListOrValueList}]}>
                                {
                                    isProd?(
                                        <>
                                            {
                                                (itemDef?.codeListRef?.codeListOID != null) &&
                                                <Tag style={{minWidth:100}} color={"red"}>{itemDef?.codeListRef?.codeListOID}</Tag>
                                            }
                                            <Tag className={'p-2'}>
                                                <span className={'font-size-16 font-weight-bold'}>
                                                    {filteredCodelist.find(cd => cd.oid === itemDef?.codeListRef?.codeListOID)?.name||'N/A'}
                                                </span>
                                            </Tag>
                                        </>
                                    ):(
                                        <Select showSearch allowClear={true} onSelect={onCodeListSelect}
                                                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                                            {filteredCodelist.map(option => (
                                                <Select.Option key={option.oid} value={option.oid} >
                                                    <Tag style={{minWidth:100, lineHeight:'20px', verticalAlign: 'text-top'}} color={"red"}>{option.oid}</Tag>
                                                    &nbsp;{option.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={24} hidden={!showValueList}>
                            <Form.Item  name={['valueListRef', 'valueListOID']}
                                        required={false}
                                        rules={[
                                            {validator: onChangeCodeListOrValueList},
                                        ]}
                                        label={
                                            <>
                                                <span className={'mr-2'}>ValueList Reference</span>
                                            </>
                                        }>

                                {
                                    isProd?(
                                        <>
                                            {
                                                (itemDef?.valueListRef?.valueListOID != null) &&
                                                <Tag style={{minWidth:100}} color={"red"}>{itemDef?.valueListRef?.valueListOID}</Tag>
                                            }
                                            <Tag className={'p-2'}>
                                                <span className={'font-size-16 font-weight-bold'}>
                                                    {valueLists.find(cd => cd.oid === itemDef?.valueListRef?.valueListOID)?.name||'N/A'}
                                                </span>
                                            </Tag>
                                        </>
                                    ):(
                                        <Select showSearch allowClear={true} filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                                            {valueLists.map(option => (
                                                <Select.Option key={option.oid} value={option.oid} >
                                                    <Tag style={{minWidth:100}} color={"red"}>{option.oid}</Tag> {option.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        { //CodeListContainer
                            (defVisible && (defType === 'codeListItem' || defType === 'externalCodeList')) &&
                            <CodeListContainer defVisible={defVisible} setDefVisible={setDefVisible}
                                               defType={defType} defItem={defItem} setDefItem={setDefItem}/>
                        }

                    <Col span={24}>
                        <FormInput name={'sdsVarName'} label={'SDS Variable Name'}
                                   rules={[
                                       {
                                           pattern: /^([a-zA-Z_][a-zA-Z0-9_]+){1,8}$/i,
                                           message: t('message.format.is.not.valid')
                                       },
                                       {max: 8, message: t('message.you.can.enter.up.to.8.characters')}
                                   ]}/>
                    </Col>
                        {
                            showDataLink &&
                            <>
                                <Divider orientation="left">Data Link</Divider>
                                <Col span={4}>
                                    <FormInput name={['dataLink', 'delimiter']} label={'Delimiter'} required={true}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: t('message.warning.required-entry')
                                                   },
                                                   {
                                                       min: 1,
                                                       message: t('message.can.enter.at.least.1.character')
                                                   },
                                                   {max: 1, message: t('message.can.enter.up.to.maximum.of.1.character')}
                                               ]}/>
                                </Col>
                                <Col span={20}>
                                    <Form.Item name={["dataLink", "dataLinkSources"]} label={"Data Link(Source)"}
                                               rules={[{required: true}]}>
                                        {
                                            isProd ? (
                                                <Tag className={'p-2'}>
                                                    <span className={'font-size-16 font-weight-bold'}>
                                                        {getCascadeLabel(dataLinkSources, form.getFieldValue(["dataLink", "dataLinkSources"]))}
                                                    </span>
                                                </Tag>
                                            ) : (
                                                <Cascader style={{width: '100%'}} options={dataLinkSources} placeholder="Please select"/>
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </>
                        }
                        {
                            showReferenceFile &&
                            <Col span={24}>
                                <Form.Item name={["referenceFileSource"]} label={"Reference File"}
                                           rules={[{required: true}]}>
                                    {
                                        isProd ? (
                                            <Tag className={'p-2'}>
                                                    <span className={'font-size-16 font-weight-bold'}>
                                                        {getCascadeLabel(referenceFileSources, form.getFieldValue(["referenceFileSource"]))}
                                                    </span>
                                            </Tag>
                                        ) : (
                                            // Upload 파일이 없는 경우 비활성화
                                            <Cascader style={{width: '100%'}}
                                                      options={referenceFileSources}
                                                      placeholder={referenceFileSources[0].children.length > 0
                                                          ? t('message.pls.select.item')
                                                          : t('message.error.not-create-upload-file-item')}
                                                      disabled={referenceFileSources[0].children.length > 0 ? false : true}/>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        }
                        <Divider orientation="left">Switch Text(Optional)</Divider>
                        <Col span={12}>
                            <Form.Item name={'switchLabelOID'} label={<span className='text-primary'>{'Question'}</span>}
                                       required={false}>
                                {
                                    isProd?(
                                        <Tag className={'p-2'}>
                                            <span className={"font-size-16 font-weight-bold"}>
                                                {itemDef?.switchLabelOID ? metaData.editCheck.get(itemDef.switchLabelOID).name : 'N/A'}
                                            </span>
                                        </Tag>
                                    ):(
                                        <Select allowClear={true} options={switchLabelOIDs} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={'switchDescriptionOID'} label={<span className='text-primary'>{'Description'}</span>}
                                       required={false}>
                                {
                                    isProd?(
                                        <Tag className={'p-2'}>
                                            <span className={"font-size-16 font-weight-bold"}>
                                                {itemDef?.switchDescriptionOID ? metaData.editCheck.get(itemDef.switchDescriptionOID).name : 'N/A'}
                                            </span>
                                        </Tag>
                                    ):(
                                        <Select allowClear={true} options={switchLabelOIDs} />
                                    )
                                }
                            </Form.Item>
                        </Col>

                    </Row>
                </Card>
                <Card title={'Question'} type={'inner'} className={'mb-2'}>
                    <DescriptionOrQuestion languages={languages} name={'question'} form={form}/>
                </Card>
                <Card title={'Description'} type={'inner'} className={'mb-2'}>
                    <DescriptionOrQuestion languages={languages} name={'description'} form={form}/>
                </Card>
            </Form>
        </Modal>
    )
}

export default ItemDef;
