import {Button, Col, Divider, Form, Row, Select, Tag, Tooltip} from "antd";
import {Table} from "reactstrap";
import React from "react";
import _ from "lodash";
import ExpressionComparisonResult from "./ExpressionComparisonResult";
import {isEmpty} from "../../../lib/StringUtils";
import {useTranslation} from "react-i18next";


const StratificationFactors = ({form, metaData, isParsed, setParsed,
                                  studyStructure, setStudyStructure,
                                  expressionComparisonTypeOptions,
                                  expressionComparisonTypeMap, parsingFactorToInfo}) => {

    const {t} = useTranslation();

    //Stratification Methods ===============================================
    const validateFactor = (studyStructure = null) => {
        //현재 입력된 StudyStructure 가져오기
        const currentStudyStructure = studyStructure!==null?studyStructure:form.getFieldsValue();
        const factorInfos = currentStudyStructure?.stratification?.stratificationFactors;

        try {
            factorInfos.forEach(factor => {
                //CASE 1.Factor 항목 선택 여부 확인
                if(isEmpty(factor.itemOID)) {
                    throw new Error(t('message.factor.item.oid.does.not.exist'));
                }
                //CASE 2. ItemExpression 존재 여부 확인
                if(factor?.itemExpressions == null || (factor?.itemExpressions != null && factor?.itemExpressions.length <= 0)) {
                    throw new Error(t('message.factor.item.expression.does.not.exist'));
                } else {
                    //itemExpressions에 대한 반복
                    factor.itemExpressions.forEach(itemExp => {
                        //CASE 3. ItemExpression의 항목 누락 확인
                        if(isEmpty(itemExp?.expressionComparisonType)) {
                            throw new Error(t('message.comparison.settings.for.itemOID.factor.item.expression.are.missing',{itemOID : factor.itemOID}));
                        }
                        //CASE 4. ItemExpression의 설정갑 (firstParam, secondParam) 확인.
                        const ECT = expressionComparisonTypeMap.get(itemExp?.expressionComparisonType);
                        if(ECT.paramCount == 2 && (isEmpty(itemExp?.firstParam) || isEmpty(itemExp?.secondParam))) {
                            throw new Error(t('message.parameter.value.for.item.oid.factor.item.expression.is.missing',{itemOID : factor.itemOID}));
                        } else if(ECT.paramCount == 1 && isEmpty(itemExp?.firstParam)) {
                            throw new Error(t('message.parameter.value.for.item.oid.factor.item.expression.is.missing',{itemOID : factor.itemOID}));
                        }
                    });
                }
            })
            //Parsing 여부 설정
            setParsed(true);
            return true;
        } catch(e) {
            console.log(`%cERROR - ${e.message}`, 'color:red');
            //Parsing 여부 설정
            setParsed(false);
            return false;
        }
    }



    const stratificationFactorInitialValue = {itemOID : null, expressionType : null,  itemExpressions:[]};
    const stratificationFactorAdd = () => {
        const cloneStudyStructure = _.cloneDeep(form.getFieldsValue());
        const cloneStratificationFactor = cloneStudyStructure.stratification?.stratificationFactors;
        cloneStratificationFactor.push({...stratificationFactorInitialValue});
        form.setFieldsValue({stratification : {stratificationFactors:cloneStratificationFactor}});
        setStudyStructure(cloneStudyStructure);

        //Info항목으로 Parsing 가능한지 확인
        validateFactor(cloneStudyStructure);
    };

    const stratificationFactorRemove = (index) => {
        const cloneStudyStructure = _.cloneDeep(form.getFieldsValue());
        const cloneStratificationFactor = cloneStudyStructure.stratification?.stratificationFactors;

        //Stratification Item이 2개 이상이어야 삭제 동작.
        if(cloneStratificationFactor.length > 1) {
            cloneStratificationFactor.splice(index, 1);
            form.setFieldsValue({stratification:{
                    stratificationFactors : cloneStratificationFactor
                }});
            setStudyStructure(cloneStudyStructure);
            //Info항목으로 Parsing 가능한지 확인
            validateFactor(cloneStudyStructure);
        }
    }

    const itemExpressionInitialValue = {itemOID : null, expressionComparisonType : null,  firstParam:null, secondParam:null};
    const itemExpressionAdd = (factorIndex) => {
        const cloneStudyStructure = _.cloneDeep(form.getFieldsValue());
        const cloneStratificationFactor = cloneStudyStructure.stratification?.stratificationFactors;
        const cloneItemExpression = cloneStratificationFactor[factorIndex].itemExpressions;

        cloneItemExpression.push({...itemExpressionInitialValue, itemOID: cloneStratificationFactor[factorIndex].itemOID});
        form.setFieldsValue({stratification : {stratificationFactors:cloneStratificationFactor}});
        setStudyStructure(cloneStudyStructure);

        //Info항목으로 Parsing 가능한지 확인
        validateFactor(cloneStudyStructure);
    }

    const itemExpressionRemove = (factorIndex, index) => {
        const cloneStudyStructure = _.cloneDeep(form.getFieldsValue());
        const cloneStratificationFactor = cloneStudyStructure.stratification?.stratificationFactors;
        const cloneItemExpression = cloneStratificationFactor[factorIndex].itemExpressions;

        //ItemExpression이 2개 이상이어야 삭제 동작.
        if(cloneItemExpression.length > 1) {
            cloneItemExpression.splice(index, 1);
            form.setFieldsValue({stratification:{stratificationFactors : cloneStratificationFactor}});
            setStudyStructure(cloneStudyStructure);

            //Info항목으로 Parsing 가능한지 확인
            validateFactor(cloneStudyStructure);
        }
    }

    const onSelectItemExpression = (itemOID, index) => {
        //itemDef 정보 가져오기
        const itemDef = metaData.item.get(itemOID);
        const codeListOID = itemDef.codeListRef?.codeListOID; //CodeListOID가 있는지 여부 확인

        //studyStructure
        const cloneStudyStructure = _.cloneDeep(form.getFieldsValue());
        const cloneStratification = cloneStudyStructure.stratification;

        // CodeList 참조
        if(codeListOID != null){
            const codeListItem  = metaData.codeList.get(codeListOID)?.codeListItem;

            // stratificationFactors
            cloneStratification.stratificationFactors[index] = {
                itemOID : itemOID,
                expressionType :  'CODE_LIST',
                itemExpressions : codeListItem.map((codeItem => ({
                    itemOID: itemOID,
                    expressionComparisonType: 'CD_EQ',
                    firstParam: codeItem.codedValue,
                    secondParam: codeItem.decode.translatedText[0].value
                })))
            };

            // STRING, NUMERIC
        } else {
            const selectedDataType  = itemDef?.dataType === 'TEXT'? 'STRING' : 'NUMERIC';
            cloneStratification.stratificationFactors[index] = {
                itemOID : itemOID,
                expressionType :  selectedDataType,
                itemExpressions : [{
                    itemOID: itemOID,
                    expressionComparisonType:null,
                    firstParam:null,
                    secondParam:null
                }]
            }
        }
        form.setFieldsValue({stratification:cloneStratification});
        setStudyStructure(cloneStudyStructure);

        //Info항목으로 Parsing 가능한지 확인
        validateFactor(cloneStudyStructure);
    }

    const onSelectItemExpComp = () =>{
        //studyStructure
        const cloneStudyStructure = _.cloneDeep(form.getFieldsValue());
        const cloneStratificationFactor = cloneStudyStructure.stratification?.stratificationFactors;
        form.setFieldsValue({stratification : {stratificationFactors:cloneStratificationFactor}});
        setStudyStructure(cloneStudyStructure);

        //Info항목으로 Parsing 가능한지 확인
        validateFactor(cloneStudyStructure);
    }
    //Stratification Methods ===============================================

    //Stratification ItemDef Options
    const itemDefOptions = Array.from(metaData.item.values()).filter(itemDef => (itemDef.dataType === 'INTEGER' || itemDef.dataType === 'TEXT' || itemDef.dataType === 'FLOAT'))
        .map(itemDef => ({ value : itemDef?.oid, label : `${itemDef?.oid}-${itemDef?.name}(${itemDef.dataType }[${itemDef.length}${itemDef.dataType === 'FLOAT'?`.${itemDef.fractionDigits}`:''}])`}))


    /**
     * ItemExpression 영역 컴포넌트
     * @param factorIndex
     * @returns {JSX.Element}
     * @constructor
     */
    const ItemExpression = ({factorIndex}) => {
        const stratificationFactor = form.getFieldValue(["stratification", "stratificationFactors", factorIndex]);

        return (
            <>
                {
                    stratificationFactor.expressionType === 'CODE_LIST' ? (
                        <Table bordered>
                            <tbody>
                            {
                                stratificationFactor?.itemExpressions?.map((itemExp, i) => (
                                    <tr key={`codeList-${i}`}>
                                        <td>
                                            <Tag>{itemExp.firstParam}</Tag>
                                            <span>- {itemExp.secondParam}</span>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    ) : (
                        <div>
                            <Form.List name={[factorIndex, "itemExpressions"]}>
                                {(fields) => (
                                    <>
                                        {fields.map((field, itemIndex) => {
                                            const item = stratificationFactor?.itemExpressions[itemIndex];

                                            return (
                                                <Row key={`${factorIndex}-itemExpressions-${itemIndex}`}>
                                                    <Col span={20}>
                                                        <Form.Item name={[itemIndex, "expressionComparisonType"]} rules={[{
                                                            required: true, message: t('message.pls.select.expression.comparison.type')
                                                        }]}>
                                                            <Select style={{width: '15rem'}}
                                                                    options={expressionComparisonTypeOptions.filter((option) => (option.type === stratificationFactor.expressionType))}
                                                                    onSelect={onSelectItemExpComp}
                                                            />
                                                        </Form.Item>
                                                        <ExpressionComparisonResult expressionComparisonTypeMap={expressionComparisonTypeMap}
                                                                                    itemExpression={item} itemIndex={itemIndex} validateFactor={validateFactor} />
                                                    </Col>
                                                    <Col span={4}>
                                                        <Button style={{background:'#fafafa'}}
                                                                onClick={() => itemExpressionRemove(factorIndex, itemIndex)}
                                                                disabled={fields.length <= 1}>
                                                            <i className='fa fa-trash-o'/>
                                                        </Button>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Divider className={'mt-2 mb-2'} />
                                                    </Col>
                                                </Row>
                                            )

                                        })}
                                    </>
                                )}
                            </Form.List>
                        </div>
                    )
                }
            </>
        )
    }

    return (
        <>
            <Button type={'primary'} className={'mr-2'} onClick={stratificationFactorAdd}>
                <i className='fa fa-plus mr-2'/> Add </Button>
            <div className={'pull-right'}>
                <Tooltip title={t('message.convert.stratification.factor.info')}>
                    <Button type={'primary'} onClick={() => parsingFactorToInfo()} disabled={!isParsed} >
                        <i className='fa fa-arrow-right mr-2' /> Parsing
                    </Button>
                </Tooltip>
            </div>
            <Table bordered className={'width-100p mt-2'}>
                <thead>
                <tr>
                    <th>Form Item</th>
                    <th>Item Expression</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <Form.List name={["stratification", "stratificationFactors"]}>
                    {(fields, {}, {errors}) => {
                        return (
                            <>
                                {fields.map((field, index) => (
                                    <tr key={`stratificationFactor-${index}`}>
                                        <td className={'width-50p'}>
                                            <Form.Item name={[index, "itemOID"]} rules={[{
                                                required: true, message: t('message.pls.select.item')
                                            }]}>
                                                <Select showSearch
                                                        options={itemDefOptions}
                                                        style={{width: '20rem'}}
                                                        onSelect={(itemOID) => onSelectItemExpression(itemOID, index)}/>
                                            </Form.Item>
                                            {
                                                (form.getFieldValue(["stratification", "stratificationFactors", index, "expressionType"]) == "STRING" ||
                                                    form.getFieldValue(["stratification", "stratificationFactors", index, "expressionType"]) == "NUMERIC") &&
                                                <div className={'pull-right'}>
                                                    <Button
                                                        onClick={() => itemExpressionAdd(index)}><i
                                                        className='fa fa-plus'/></Button>
                                                </div>
                                            }
                                        </td>
                                        <td className={'width-50p'}>
                                            <ItemExpression factorIndex={index}/>
                                        </td>
                                        <td>
                                            <Button
                                                onClick={() => stratificationFactorRemove(index)}
                                                disabled={fields.length <= 1}><i
                                                className='fa fa-trash-o'/></Button>
                                        </td>
                                    </tr>
                                ))}
                                {
                                    //Error가 존재하면
                                    (errors.length > 0) &&
                                    <tr>
                                        <td colSpan={3}>
                                            <Form.ErrorList errors={errors} />
                                        </td>
                                    </tr>
                                }
                            </>
                        )
                    }}
                </Form.List>
                </tbody>
            </Table>
        </>
    )
}

export default StratificationFactors;