import {Helmet} from "react-helmet-async";
import SettingsTabs from "../../../../layouts/Tab/settings";
import DefaultECS from "../../../../components/common/builder/studies/DefaultECS";
import CRFVersionTabs from "../../../../layouts/Tab/crf-version";

const DefaultECSPage = () => {

    return (
        <CRFVersionTabs menu={'default-ecs'}>
            <Helmet title={'BUILDER :: Default ECS'} />
            <DefaultECS />
        </CRFVersionTabs>
    )

}

export default DefaultECSPage
