import {Form, Select, Tag} from "antd";
import {useTranslation} from "react-i18next";

const {Item} = Form;

const LibraryFormSelectDataTypes = ({required = true, name = "dataType", label = "Data Type", dataTypes, onChange, value}) => {

    const {t} = useTranslation();

    return (
        <Item name={name} label={<span className='text-primary'>{label}</span>}
              rules={[{required: required, message:t('message.this.is.required.selection')}]}
              required={required}>
            <Select onChange={onChange} options={dataTypes}></Select>
        </Item>
    )

}

export default LibraryFormSelectDataTypes;