import {Badge, Card, Col, Descriptions, Input, List, message, Row, Switch, Tag, Tooltip, Tree, Typography} from "antd";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, {useEffect} from "react";
import {DescriptionDiv, LibraryCard} from "../../../../../container/build-tool/StyleBox";
import {isEmpty} from "../../../../../lib/StringUtils";
import _ from 'lodash';
import {useTranslation} from "react-i18next";

const LibraryUpload = ({
                           library, libraries, metaData, overwrite, setOverwrite, studyInfo,
                           treeData, setTreeData, setCheckedTreeData, selectLibrary,
                           filteredSelectTreeData, setFilteredSelectTreeData,
                           forceCheckedDataKeys, checkedKeys, setCheckedKeys,
                           selectData, setSelectData, errorMessages,
                           typeColor, typeLabel, typeShortLabel, defTag
                       }) => {

    const {t} = useTranslation();

    const checkUseEPRO = (form) => {
        const {formType} = form;
        return studyInfo?.useEPRO?true:(formType !== 'EPRO'&&formType !== 'CRF_EPRO');
    }

    const checkUseSAE = (form) => {
        const {formType} = form;
        return studyInfo?.useSAEReport?true:(formType !== 'SAE_REPORT_FORM');
    }

    //Library가 변경되었을 때 동작.
    useEffect(() => {
        if(library != null) {
            //Tree 구조 설정
            //Form
            const dataTree = Array.from(metaData.form.values()).filter(checkUseEPRO).filter(checkUseSAE).map(form => {
                //Form Key
                return {
                    key: `form_${form.oid}`,
                    dataOID: `${form.oid}`,
                    type: 'formDef',
                    title: <><Tag color={typeColor['formDef']} className={'mr-2'}>{typeShortLabel['formDef']}</Tag>{form.oid}[{form.name}]</>,
                    data: form,

                    //ItemGroup
                    children: form.itemGroupRef.map(igRef => {
                        const itemGroup = metaData.itemGroup.get(igRef.itemGroupOID);
                        return {
                            key: `itemGroup_${form.oid}_${itemGroup.oid}`,
                            parentKey: `${form.oid}`,
                            dataOID: `${itemGroup.oid}`,
                            type: 'itemGroupDef',
                            title: <><Tag color={typeColor['itemGroupDef']} className={'mr-2'}>{typeShortLabel['itemGroupDef']}</Tag>{itemGroup.oid}[{itemGroup.name}]</>,
                            data: itemGroup,

                            //Item
                            children: itemGroup.itemRef.flatMap(iRef => {
                                const item = metaData.item.get(iRef.itemOID);
                                //ValueListOID를 참조하고있는 경우, ValueList 정보를 추가함
                                if(!isEmpty(iRef.valueListOID)) {
                                    const valueList = metaData.valueList.get(iRef.valueListOID);
                                    return [
                                        {
                                            key: `valueList_${form.oid}_${itemGroup.oid}_${valueList.oid}`,
                                            parentKey: `${form.oid}_${itemGroup.oid}`,
                                            dataOID: `${valueList.oid}`,
                                            type: 'valueList',
                                            reference: iRef.itemOID,
                                            title: <><Tag color={typeColor['valueList']} className={'mr-2'}>{typeShortLabel['valueList']}</Tag>{valueList.oid}[{valueList.name}]</>,
                                            data: valueList,
                                            //ValueList의 ItemRef 정보
                                            children: valueList.itemRef.map(vlIRef => {
                                                const valueListItem = metaData.item.get(vlIRef.itemOID);
                                                return {
                                                    key: `item_${form.oid}_${itemGroup.oid}_${valueList.oid}_${valueListItem.oid}`,
                                                    parentKey: `${form.oid}_${itemGroup.oid}_${valueList.oid}`,
                                                    dataOID: `${valueListItem.oid}`,
                                                    type: 'itemDef',
                                                    title: <><Tag color={typeColor['itemDef']} className={'mr-2'}>{typeShortLabel['itemDef']}</Tag>{valueListItem.oid}[{valueListItem.name}]</>,
                                                    data: valueListItem,
                                                    //Item의 Reference 정보 [codeList, ValueList]
                                                    children: getItemReference(form.oid, itemGroup.oid, valueListItem, `${valueList.oid}_`)
                                                }
                                            })
                                        },
                                        {
                                            key: `item_${form.oid}_${itemGroup.oid}_${item.oid}`,
                                            parentKey: `${form.oid}_${itemGroup.oid}`,
                                            dataOID: `${item.oid}`,
                                            type: 'itemDef',
                                            disableCheckbox: forceCheckedDataKeys.includes(`item_${form.oid}_${itemGroup.oid}_${item.oid}`),
                                            title: <><Tag color={typeColor['itemDef']} className={'mr-2'}>{typeShortLabel['itemDef']}</Tag>{item.oid}[{item.name}]</>,
                                            data: item,
                                            //Item의 Reference 정보 [codeList, ValueList]
                                            children: getItemReference(form.oid, itemGroup.oid, item)
                                        }
                                    ];
                                } else {
                                    return {
                                        key: `item_${form.oid}_${itemGroup.oid}_${item.oid}`,
                                        dataOID: `${item.oid}`,
                                        parentKey: `${form.oid}_${itemGroup.oid}`,
                                        type: 'itemDef',
                                        disableCheckbox: forceCheckedDataKeys.includes(`item_${form.oid}_${itemGroup.oid}_${item.oid}`),
                                        title: <><Tag color={typeColor['itemDef']} className={'mr-2'}>{typeShortLabel['itemDef']}</Tag>{item.oid}[{item.name}]</>,
                                        data: item,
                                        children: getItemReference(form.oid, itemGroup.oid, item)
                                    }
                                }
                            })
                        }
                    })
                }
            });
            console.log(dataTree);
            setTreeData(dataTree);
        }
    }, [library]);

    /**
     * @param formOID
     * @param itemGroupOID
     * @param itemDef
     * @param valueListOID
     * @returns {*[]}
     */
    const getItemReference = (formOID, itemGroupOID, itemDef, valueListOID = '') => {
        const {codeListRef, valueListRef} = itemDef;
        let result = []

        if(!isEmpty(codeListRef?.codeListOID)) {
            const codeList = metaData.codeList.get(codeListRef?.codeListOID);
            result.push({
                key: `codeList_${formOID}_${itemGroupOID}_${valueListOID}${itemDef.oid}_${codeList.oid}`,
                dataOID: `${codeList.oid}`,
                parentKey: `${formOID}_${itemGroupOID}_${itemDef.oid}`,
                type: 'codeList',
                title: <><Tag color={typeColor['codeList']} className={'mr-2'}>{typeShortLabel['codeList']}</Tag>{codeList.oid}[{codeList.name}]</>,
                data: codeList,
            })
        }

        //ItemDef에 참조된 ValueList는 무시함
        // if(!isEmpty(valueListRef?.valueListOID)) {
        //     const valueList = metaData.valueList.get(valueListRef?.valueListOID);
        //     result.push({
        //         key: `valueList_${formOID}_${itemGroupOID}_${valueListOID}${itemDef.oid}_${valueList.oid}`,
        //         dataOID: `${valueList.oid}`,
        //         parentKey: `${formOID}_${itemGroupOID}_${itemDef.oid}`,
        //         type: 'valueList',
        //         title: <><Tag color={typeColor['valueList']} className={'mr-2'}>{typeShortLabel['valueList']}</Tag>{valueList.oid}[{valueList.name}]</>,
        //         data: valueList,
        //     })
        // }

        return result;
    }

    /**
     * Tree항목을 Check했을 때 동작
     * @param checkedKeys
     * @param node
     */
    const onTreeCheck = (currentCheckedKeys, e) => {
        const {checked, node, halfCheckedKeys} = e;

        if(checked === true) {
            //강제로 체크되어야하는 항목에 대한 처리
            if(!forceCheckedDataKeys.includes(node.key)) {
                setCheckedKeys(currentCheckedKeys);
            } else {
                /* Check를 하는 시점에서, 해당 항목이 속한 selectedKey 정보를 체크 할 수 있게 해야 한다. */
                const forceCheckedKeys = forceCheckedDataKeys.filter(key => key.includes(node.parentKey));
                const applyCheckedKeys = [...checkedKeys, ...forceCheckedKeys];
                currentCheckedKeys = applyCheckedKeys;
                setCheckedKeys(applyCheckedKeys);
            }
        }
        //체크 해제하려고 했을 때,
        else if(checked === false) {
            //강제로 체크되어야하는 키가 아니면 설정 정보 적용
            if(!forceCheckedDataKeys.includes(node.key)) {
                setCheckedKeys(currentCheckedKeys);

            } else { //강제로 체크되어야 하는 경우
                message.warning(t('message.node.data.oid.item.cannot.be.unchecked', {nodeDataOid : node.data.oid}));
            }
        }

        const keys = [...currentCheckedKeys, ...halfCheckedKeys];
        checkedTreeDataSetting(keys);
    }

    /**
     * Check된 TreeData 구조에 맞춰 생성 되어야 할 데이터 구조를 형성한다.
     * @param checkedKeys
     */
    const checkedTreeDataSetting = (checkedKeys) => {
        const forms = checkedKeys.filter(key => key.startsWith('form_'));
        const itemGroups = checkedKeys.filter(key => key.startsWith('itemGroup_'));
        const items = checkedKeys.filter(key => key.startsWith('item_'));
        const codeLists = checkedKeys.filter(key => key.startsWith('codeList_'));
        const valueLists = checkedKeys.filter(key => key.startsWith('valueList_'));

        const checkedDataTreeList = [];

        //Form
        forms.forEach(formKey => {
            const formNode = _.cloneDeep(treeData.find(treeData => treeData.key === formKey));
            //ItemGroup
            const filterItemGroups = formNode.children.filter(itemGroup => itemGroups.includes(itemGroup.key));
            filterItemGroups.forEach(itemGroupNode => {
                const itemGroupData = itemGroupNode.data;

                //ValueList(itemRef 참조항목)
                const filterValueLists = itemGroupNode.children.filter(valueList => valueLists.includes(valueList.key));
                filterValueLists.forEach(valueListNode => {
                    //ValueList - ItemRef
                    const filterValueListItem = valueListNode.children.filter(valueListItem => items.includes(valueListItem.key));
                    valueListNode.children = filterValueListItem;
                    // console.log("ValueList : ", valueLikstData);
                })

                //Item
                const filterItems = itemGroupNode.children.filter(item => items.includes(item.key));
                filterItems.forEach(itemNode => {
                    // itemDef에 참조된 ValueList는 무시함.
                    // const filterItemValueLists = itemNode.children.filter(iVl => valueLists.includes(iVl.key));
                    // filterItemValueLists.forEach(itemValueListNode => {
                    //     //ValueList - ItemRef
                    //     const filterItemValueListItem = itemValueListNode.children.filter(itemValueListItem => items.includes(itemValueListItem.key))
                    //     itemValueListNode.children = filterItemValueListItem;
                    //     // console.log("Item에 참조중인 ValueList : ", itemValueListData);
                    // })

                    //CoeList는 무조건 Item에 참조중일때 포함되므로 별도 처리 하지 않음.
                    // const filterItemCodeList = itemData.children.filter(iCl => codeLists.includes(iCl.key));
                    // console.log("Item : ", itemData);
                })

                itemGroupNode.children = [...filterValueLists, ...filterItems];
                // console.log("ItemGroup : ", itemGroupData);
            })

            formNode.children = filterItemGroups;
            checkedDataTreeList.push(formNode);
        })

        console.log(checkedDataTreeList);
        setCheckedTreeData(checkedDataTreeList);
    }

    /**
     * Tree항목을 Select 했을 때 동작
     * @param selectedKeys
     * @param node
     */
    const onTreeSelect = (selectedKeys, {node}) => {
        // console.log(selectedKeys, node);
        const {data, type} = _.cloneDeep(node);
        data.type = type;
        setSelectData(data);
    }

    /**
     * TreeData에서 필요한 정보만 Filter하여 보기
     * Debounce 처리 하였음 (lodash - debounce 사용)
     * @param value
     */
    const onTreeSearch = _.debounce(({target}) => {
        const filterTree = treeData.filter(treeData => treeData.key.includes(target.value))
        const result = target?.value.length <= 0?null:filterTree;
        setFilteredSelectTreeData(result);
    }, 200);

    /**
     * Select 했을 때 데이터 정보 표시
     * @param selectData
     * @returns {JSX.Element}
     * @constructor
     */
    const SelectDataInfo = ({selectData}) => {
        const type = selectData?.type;

        //SelectData SignificantDigits 속성 안보이게 삭제
        if(!isEmpty(selectData.significantDigits)) {
            delete selectData.significantDigits;
        }

        return (
            <div style={{position:'sticky', top:'80px'}}>
                <Descriptions bordered column={1} layout={'horizontal'} size={'small'}
                              title={<><Tag color={typeColor[type]}>{typeLabel[type]??'-'}</Tag>&nbsp;&nbsp;{selectData?.name??'-'}</>}>
                    {
                        Object.keys(selectData).map((key, idx) => (
                            <React.Fragment key={idx}>
                                {
                                    (!isEmpty(selectData[key]) && key !== 'type' && (typeof selectData[key] === 'string' || typeof selectData[key] === 'number')) &&
                                    <Descriptions.Item label={<span style={{"text-transform":'uppercase'}}>{key}</span>}>
                                        {typeof selectData[key] === 'string' && selectData[key]}
                                        {typeof selectData[key] === 'number' && selectData[key]}
                                    </Descriptions.Item>
                                }
                            </React.Fragment>
                        ))
                    }
                </Descriptions>
                {
                    (selectData['codeListItem'] != null && selectData['codeListItem'].length > 0) &&
                    <Descriptions bordered column={1} layout={'vertical'} size={'small'} className={'mt-2'}>
                        <Descriptions.Item label={'CodeList Item [CodedValue : Decode]'}>
                            <List>
                                {
                                    selectData['codeListItem'].map((clItem, idx) => (
                                        <List.Item key={`${clItem.codedValue}_${idx}`}>
                                            <Tag>{clItem?.codedValue??'-'}</Tag>:&nbsp;&nbsp;<Tag>{clItem.decode.translatedText[0]?.value??'-'}</Tag>
                                        </List.Item>
                                    ))
                                }
                            </List>
                        </Descriptions.Item>
                    </Descriptions>
                }
                {
                    selectData['externalCodeList'] != null &&
                    <Descriptions bordered column={1} layout={'vertical'} size={'small'} className={'mt-2'}>
                        <Descriptions.Item label={'External CodeList'}>
                            {selectData['name']}
                        </Descriptions.Item>
                    </Descriptions>
                }
            </div>
        )
    }

    const onOverwriteSwitch = (checked) => {
        setOverwrite(checked);
    }

    return (
        <>
            {
                library === null ? (
                    <PerfectScrollbar>
                        <List grid={{gutter: 16, column: 4}} dataSource={libraries} style={{maxHeight: '30rem'}}
                              renderItem={(library) => (
                                  <List.Item style={{padding: '12px 0'}}>
                                      <LibraryCard onClick={() => selectLibrary(library.id)} title={library.name}
                                                   size={'small'} extra={<Badge color={'red'} text={'Form'} count={library?.formCnt}></Badge>}>
                                          <Descriptions column={1} layout={'vertical'}>
                                              <Descriptions.Item label={'Descriptions'} labelStyle={{paddingBottom: 0}}>
                                                  <Tooltip title={library?.description} placement={'bottomLeft'}>
                                                      <DescriptionDiv>{library?.description}</DescriptionDiv>
                                                  </Tooltip>
                                              </Descriptions.Item>
                                          </Descriptions>
                                      </LibraryCard>
                                  </List.Item>
                              )}
                        />
                    </PerfectScrollbar>
                ) : (
                    <Card>
                        <Row gutter={[20, 0]}>
                            <Col span={16} style={{minHeight:'30rem', maxHeight:'30rem', padding:'0px 24px 0px 24px'}}>
                                <PerfectScrollbar>
                                    <div style={{position:'sticky', top:0, background:'white', width:'100%', paddingTop:'24px', zIndex:'10'}}>
                                        <Input.Search className={'mb-2'} placeholder={t('column.enter.form.oid')} onChange={onTreeSearch} />
                                    </div>
                                    <Tree showIcon={true} showLine={true} checkable={true}
                                          onCheck={onTreeCheck}
                                          onSelect={onTreeSelect}
                                          checkedKeys={checkedKeys}
                                          treeData={filteredSelectTreeData!=null?filteredSelectTreeData:treeData} />
                                </PerfectScrollbar>
                            </Col>
                            <Col span={8} style={{borderLeft: '1px solid rgba(0,0,0,0.15)', minHeight:'30rem', maxHeight:'30rem'}}>
                                <PerfectScrollbar>
                                    <div className={'mb-2'} style={{position:'sticky', top:0, background:'white', width:'100%', paddingTop:'24px', zIndex:'10'}}>
                                        <Typography.Text className={'mr-2'}>Overwrite:</Typography.Text>
                                        <Switch checked={overwrite} onChange={onOverwriteSwitch}></Switch>
                                        <hr />
                                    </div>
                                    {
                                        selectData != null &&
                                        <SelectDataInfo selectData={selectData} />
                                    }
                                </PerfectScrollbar>
                            </Col>
                            {
                                errorMessages !== null &&
                                <Col span={24} style={{
                                    height:'7.5rem',
                                    overflowY:'auto',
                                    position:'sticky',
                                    background:'white',
                                    bottom:0,
                                    border: '1px solid rgba(0,0,0,0.15)',
                                    zIndex:1,
                                }}>
                                    <List size={'small'}>
                                        {
                                            errorMessages.map((message,idx) => {
                                                let tag;
                                                Object.keys(defTag).forEach(defTagKey => {
                                                    if(message.includes(defTagKey)) {
                                                        tag = defTag[defTagKey];
                                                        message = message.replace(defTagKey, '');
                                                    }
                                                })

                                                return (
                                                    <List.Item key={idx} style={{margin:0, padding:0}}>
                                                        <Typography.Text type={'danger'}>{tag}{message}</Typography.Text>
                                                    </List.Item>
                                                )
                                            })
                                        }
                                    </List>
                                </Col>
                            }
                        </Row>
                    </Card>
                )
            }
        </>
    )

}

export default LibraryUpload;