import React, {useEffect, useState} from "react";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {CardBody, CardFooter, CardHeader, PrimaryCard} from "../../../../pages/PageStyleBox";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Button, Form, Input, message, Switch} from "antd";
import NormalLoading from "../../NormalLoading";
import {withTranslation} from "react-i18next";
import {RightOutlined} from "@ant-design/icons";

const PageSetting = ({t}) => {
    const params = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        (async () => {
            try {
                const response = await axios.get(`/api/builder/studies/${params.sid}/basic-definition/page-setting`);
                if (response.status === httpStatus.ok) {
                    const {data} = response;

                    // console.log(data);
                    form.setFieldsValue(data);
                    setLoading(false);
                }
            } catch(e) {
                errorHandle(e, navigate);
            }
        })();
    }, []);

    const onFinish = async (values) => {

        console.log(values);

        try {
            const response = await axios.post(`/api/builder/studies/${params.sid}/basic-definition/page-setting`, values);

            if(response.status === httpStatus.ok) {
                const {data} = response;
                message.success(data.message);
            }

        } catch (e) {
            errorHandle(e);
        }
    };

    return (
        <>
            {
                loading?(
                    <NormalLoading />
                ):(
                    <PrimaryCard>
                        <CardHeader>
                            <div className={'row'}>
                                <div className={'col-8'}>
                                    <h3><RightOutlined className={'mr-2'} />Page Setting</h3>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" form={form}>
                                <table className={'table table-bordered table-'} style={{width: "50%", textAlign:"center"}}>
                                    <thead>
                                    <tr>
                                        <th>Page</th>
                                        <th>Column</th>
                                        <th>Visible</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <Form.List name="pageColumnDefs">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <tr>
                                                        <td style={{width:'35%'}}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'pageName']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Missing Page',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input style={{textAlign:"center", border:"none"}}
                                                                       placeholder="pageName" readOnly={true} />
                                                            </Form.Item>
                                                        </td>
                                                        <td style={{width:'35%'}}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'columnName']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Missing Column',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input style={{textAlign:"center", border:"none"}}
                                                                       placeholder="columnName" readOnly={true} />
                                                            </Form.Item>
                                                        </td>
                                                        <td>
                                                            <Form.Item valuePropName={"checked"}
                                                                       name={[name, 'visible']}
                                                                       rules={[{ required: false}]}
                                                                       initialValue={true} >
                                                                <Switch />
                                                            </Form.Item>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                    </Form.List>
                                    </tbody>
                                </table>
                                <Form.Item className={'text-right'}>
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                </Form.Item>
                            </Form>
                        </CardBody>
                    </PrimaryCard>
                )
            }
        </>
    )
}

export default withTranslation()(PageSetting);
