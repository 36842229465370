import {Button, Col, Form, Input, message, Row, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle} from "../../../../lib/BuilderUtils";
import DefaultLoading from "../../DefaultLoading";
import {CardBody, CardFooter, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    if (img == null) {
        callback("");
    } else {
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
}

const SponsorEdit = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();

    //query-string이 존재하면 parameter에 해당하는 값 불러오기.
    const params = queryString.parse(location.search);
    const [sponsor, setSponsor] = useState(null);

    const [form] = Form.useForm();

    //Button Loading
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        (async () => {
            //GET Request
            try {
                //REST API Request
                const {data, status} = await axios.get(`/api/builder/sponsors/edit`, {params: params});

                if(status === httpStatus.ok) {
                    //State Setting
                    setSponsor(data.sponsor);
                }
            } catch (error) {
                //Error Exception
                errorHandle(error, navigate, "/sponsors")
            }
        })()
    }, [])

    const onFinish = async (values) => {
        setLoading(true);

        try {
            const body = {
                ...sponsor,
                ...values,
            };

            const {data, status} = await axios({
                url: `/api/builder/sponsors/edit`,
                data: body, method: 'post',
            });

            if (status === httpStatus.ok) {
                message.success(data.message);
                navigate('/sponsors');
            }
        } catch (e) {
            errorHandle(e, navigate, '/sponsors');
        }

        setLoading(false)
    }

    const onFinishFailed = (error) => {
        console.log("%cError : ", "color:red", error);
    }

    const handleChange = (info) => {
        // Get this url from response in real world.
        getBase64(info.target.files[0], (imageUrl) => {

            form.setFieldsValue({
                logo: imageUrl
            });
        });
    };

    return (
        <>
            {
                sponsor === null &&
                <DefaultLoading/>
            }
            {
                sponsor !== null &&
                <DefaultCard>
                    <CardHeader>
                        <h3>Sponsor</h3>
                    </CardHeader>
                    <Form layout="vertical"
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          initialValues={sponsor}
                          form={form}
                    >
                        <CardBody>
                            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                <Col span={12}>
                                    <Form.Item name={'name'} label='Sponsor Name' required
                                               rules={[{
                                                   required: true,
                                                   message: t('message.pls.enter.sponsor.name')
                                               }]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label='Logo' required={false}>
                                        <Input type='file' accept={'.jpg,.jpeg,.png,.gif'} onChange={handleChange}/>
                                    </Form.Item>
                                    <Space>
                                        <Form.Item name={'logo'} required={false}
                                            /*rules={[{
                                                required: true,
                                                message: 'Logo File을 첨부해 주세요.',
                                            }]}*/
                                                   valuePropName={'src'}>
                                            <img src={sponsor.logo} width={'100%'} height={'auto'}/>
                                        </Form.Item>
                                    </Space>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className='text-right'r>
                            <Button type={'default'} onClick={() => {
                                navigate("/sponsors");
                            }} className={'mr-3'}> Cancel </Button>
                            <Button loading={loading} type={'primary'} htmlType={'submit'}> Submit </Button>
                        </CardFooter>
                    </Form>
                </DefaultCard>
            }
        </>
    )
}

export default SponsorEdit;