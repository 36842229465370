import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';

export const LoadingSpinnerComponent = (props) => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        <div>
            {(promiseInProgress === true) ?
                    <div style={{width: "100%", height: "90vh", display:"grid", position:"absolute", zIndex:"999", justifyContent: "center", alignItems: "center"}}>
                        <Loader type="ThreeDots" color="#814c97" height="100" width="100" />
                    </div>
                    :
                    null
            }
        </div>
    )
};
