import {Button} from "antd";

/**
 * ToolBar에서 직접 ItemGroup, Item, CodeList를 생성하는 버튼
 * @param setVisible
 * @param type
 * @param setDefinitionType
 * @returns {JSX.Element}
 * @constructor
 */
const ToolBtn = ({type, hidden, onCreate, btnType = "default"}) => {
    //Item의 생성

    return (
        <Button hidden={hidden} size={'small'} type={btnType}
                onClick={(e) => {
                    e.stopPropagation(); // Panel Toggle 동작으로 Event가 확장되지 않게 제어.
                    onCreate(type);
                }}
        >
            <i className='fa fa-plus'/>
            {type === 'externalCodeList' &&
                <span>&nbsp;ExternalCodeList</span>
            }
            {type === 'codeListItem' &&
                <span>&nbsp;CodeListItem</span>
            }
        </Button>
    )
}

export default ToolBtn;
