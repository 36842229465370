import {Helmet} from "react-helmet-async";
import RoleList from "../../../../components/common/builder/studies/RoleList";
import React from "react";
import SettingsTabs from "../../../../layouts/Tab/settings";

const RolesPage = () => {

    return (
        <SettingsTabs menu={'roles'}>
            <Helmet title={'BUILDER :: Role'}/>
            <RoleList/>
        </SettingsTabs>
    )
}

export default RolesPage;