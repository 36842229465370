import {Button, Card, Col, message, Modal, Row, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {LeftOutlined} from "@ant-design/icons";
import axios from "axios";
import {httpStatus} from "../../../../../lib/createRequestSaga";
import {errorHandle} from "../../../../../lib/BuilderUtils";
import {toLibraryMap} from "../../../../../lib/LibraryUtils";
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toMetaData} from "../../../../../lib/ODMUtils";
import {useMutation} from "react-query";
import {downloadLibraryWithAxios, uploadLibraryWithAxios} from "../../../../../lib/api/library";
import {metadataActions} from "../../../../../redux/module/metadata";
import {useParams} from "react-router-dom";
import LibraryDownload from "./LibraryDownload";
import LibraryUpload from "./LibraryUpload";
import {isEmpty} from "../../../../../lib/StringUtils";


const modalWidths = {
    init: 400,
    select: 800,
    steps: 1000
};

const typeColor = {
    formDef: 'gold',
    itemGroupDef: 'green',
    itemDef: 'geekblue',
    codeList: 'cyan',
    valueList: 'magenta'
};

const typeLabel = {
    formDef: 'FORM',
    itemGroupDef: 'ITEMGROUP',
    itemDef: 'ITEM',
    codeList: 'CODELIST',
    valueList: 'VALUELIST'
};

const typeShortLabel = {
    formDef: 'F',
    itemGroupDef: 'IG',
    itemDef: 'I',
    codeList: 'CL',
    valueList: 'VL'
};

const defVariable = {
    FORM: '%FORMDEF%',
    ITEMGROUP: '%ITEMGROUPDEF%',
    ITEM: '%ITEMDEF%',
    CODELIST: '%CODELIST%',
    VALUELIST: '%VALUELISTDEF%',
}

const defTag = {
    ['%FORMDEF%']: <Tag color={typeColor['formDef']} className={'mr-2'}>{typeLabel['formDef']}</Tag>,
    ['%ITEMGROUPDEF%']: <Tag color={typeColor['itemGroupDef']} className={'mr-2'}>{typeLabel['itemGroupDef']}</Tag>,
    ['%ITEMDEF%']: <Tag color={typeColor['itemDef']} className={'mr-2'}>{typeLabel['itemDef']}</Tag>,
    ['%CODELIST%']: <Tag color={typeColor['codeList']} className={'mr-2'}>{typeLabel['codeList']}</Tag>,
    ['%VALUELISTDEF%']: <Tag color={typeColor['valueList']} className={'mr-2'}>{typeLabel['valueList']}</Tag>,
}

const LibraryInterfaceDef = ({open, setOpen, t}) => {

    const [interfaceWidth, setInterfaceWidth] = useState(400);
    const [interfaceType, setInterfaceType] = useState('init');

    const metaDataStore = useSelector((store) => store.metaDataStore);
    const metaData = toMetaData(metaDataStore.study);

    //Library 정보
    const [libraries, setLibraries] = useState([]);
    const [library, setLibrary] = useState(null);
    const [libraryMap, setLibraryMap] = useState({});

    //Select 대상에 보여질 metadata list
    const [treeData, setTreeData] = useState([]);
    const [filteredSelectTreeData, setFilteredSelectTreeData] = useState(null);
    const [checkedTreeData, setCheckedTreeData] = useState([]);

    //download 대상이 되는 metadata
    const [forceCheckedDataKeys, setForceCheckedDataKeys] = useState([]); //처음부터 체크되어있어야 하는 데이터 키값
    const [checkedKeys, setCheckedKeys] = useState([]); //체크된 키값 정보
    const [selectData, setSelectData] = useState(null);
    const [overwrite, setOverwrite] = useState(false);
    const [errorMessages, setErrorMessages] = useState(null);

    //Message State
    const dispatch = useDispatch();
    const params = useParams();

    //Initial Setting
    const init = () => {
        setInterfaceType('init');
        setInterfaceWidth(modalWidths.init);
        setLibrary(null);
        setTreeData([]);
        setFilteredSelectTreeData(null);
        setCheckedKeys([]);
        setOverwrite(false);
        setErrorMessages(null);
    }

    const loadLibraries = async () => {
        try {
            const {status, data} = await axios.get('/api/builder/library-interface/global-libraries')
            if(status === httpStatus.ok) {
                setLibraries(data?.libraries??[]);
                // console.log("Library : ", data?.libraries);
            }
        } catch (e) {
            errorHandle(e);
        }
    }

    //Library가 변경되었을 때 동작.
    useEffect(() => {
        if (open) { //Modal이 열렸을 경우, init동작 및 library 리스트 조회함
            init();
            loadLibraries();
        }
    }, [open]);


    /**
     * 대분류 메뉴 선택 메서드
     */
    const onUpload = () => {
        setInterfaceType('upload');
        setInterfaceWidth(modalWidths.select);
    }
    const onDownload = () => {
        setInterfaceType('download');
        setInterfaceWidth(modalWidths.select);
    }
    const onCancel = () => {
        setOpen(false);
    }

    //Download
    const {mutate:downloadMutate} = useMutation(downloadLibraryWithAxios, {
        onSuccess:({resultMessage}) => {
            message.success(resultMessage);
            dispatch(metadataActions.fetchMetaData({...params}));
            onCancel();
        },
        onError: (error) => {
            errorHandle(error);
        },
    });

    //Download
    const {mutate:uploadMutate} = useMutation(uploadLibraryWithAxios, {
        onSuccess:({resultMessage}) => {
            message.success(resultMessage);
            loadLibraries();
            onCancel();
        },
        onError: (error) => {
            errorHandle(error);
        },
    });


    const getCheckedTreeToDefinitionData = () => {
        const errorMessages = new Set();
        const formDefMap = new Map();
        const itemGroupDefMap = new Map();
        const itemDefMap = new Map();
        const codeListMap = new Map();
        const valueListDefMap = new Map();

        /**
         * Download 진행 전 Validation 처리
         */
        if(checkedTreeData == null || checkedTreeData.length <= 0) {
            // message.warning('선택된 데이터가 없습니다.');
            errorMessages.add(t('message.no.data.selected'))
            return {errorMessages,formDefMap,itemGroupDefMap,itemDefMap,codeListMap,valueListDefMap};
        }

        //TODO Form Node에서 FormDef 정보 가져오기
        checkedTreeData.forEach(formNode => {
            const formDef = {...formNode.data};
            const itemGroupRefNodes = formNode.children;

            /**
             * Validation Form-1. OID Check
             * 덮어쓰기 하지 않는 경우, 이미 존재하는 metaData정보가 있으면 오류 표시
             * Download일 때, 현재 metaData 정보와 중첩되는 지 확인,
             * Upload일 때, 선택한 Library 정보가 중첩되는 지 확인
             */
            if((interfaceType === 'download' && overwrite === false && metaData.form.has(formDef.oid)) ||
                (interfaceType === 'upload' && overwrite === false && libraryMap.form.has(formDef.oid))) {
                errorMessages.add(t('message.form.oid.has.already.been.registered',{form : defVariable.FORM, oid : formDef.oid}))
            }

            //TODO ItemGroup Node에서 ItemGroupDef 정보 가져오기
            itemGroupRefNodes.forEach(itemGroupNode => {
                const itemGroupDef = {...itemGroupNode.data};
                const itemRefNodes = itemGroupNode.children.filter(iNode => iNode.type === 'itemDef');
                const itemRefValueListNode = itemGroupNode.children.find(iNode => iNode.type === 'valueList')??null;

                /**
                 * Validation ItemGroup-1. OID Check
                 * 덮어쓰기 하지 않는 경우, 이미 존재하는 metaData정보가 있으면 오류 표시
                 * Download일 때, 현재 metaData 정보와 중첩되는 지 확인,
                 * Upload일 때, 선택한 Library 정보가 중첩되는 지 확인
                 */
                if((interfaceType === 'download' && overwrite === false && metaData.itemGroup.has(itemGroupDef.oid)) ||
                    (interfaceType === 'upload' && overwrite === false && libraryMap.itemGroup.has(itemGroupDef.oid))) {
                    errorMessages.add(t('message.item.group.oid.has.already.been.registered',{itemGroup : defVariable.ITEMGROUP, oid : itemGroupDef.oid}))
                }

                //TODO Item Node에서 codeListRef/ValueListRef 정보 가져오기
                itemRefNodes.forEach(itemNode => {
                    const itemDef = {...itemNode.data};
                    const itemRefCodeListNode = itemNode.children.find(iNode => iNode.type === 'codeList')??null;
                    // const itemRefValueListNode = itemNode.children.find(iNode => iNode.type === 'valueList')??null;

                    /**
                     * Validation Item-1. OID Check
                     * 덮어쓰기 하지 않는 경우, 이미 존재하는 metaData정보가 있으면 오류 표시
                     * Download일 때, 현재 metaData 정보와 중첩되는 지 확인,
                     * Upload일 때, 선택한 Library 정보가 중첩되는 지 확인
                     */
                    if((interfaceType === 'download' && overwrite === false && metaData.item.has(itemDef.oid)) ||
                        (interfaceType === 'upload' && overwrite === false && libraryMap.item.has(itemDef.oid))) {
                        errorMessages.add(t('message.item.def.item.oid.has.already.been.registered',{item : defVariable.ITEM, oid : itemDef.oid}))
                    }

                    //CodeList가 있는 경우 CodeList 저장
                    if(itemRefCodeListNode !== null) {
                        const codeList =  {...itemRefCodeListNode.data};

                        /**
                         * Validation CodeList-1. OID Check
                         */
                        if((interfaceType === 'download' && overwrite === false && metaData.codeList.has(codeList.oid)) ||
                            (interfaceType === 'upload' && overwrite === false && libraryMap.codeList.has(codeList.oid))) {
                            errorMessages.add(t('message.code.list.oid.has.already.been.registered', {codeList : defVariable.CODELIST, oid : codeList.oid}))
                        }

                        if(!codeListMap.has(codeList.oid)) {
                            codeListMap.set(codeList.oid, codeList);
                        }
                    }

                    //ValueList가 있는 경우 ValueList 저장
                    // if(itemRefValueListNode !== null) {
                    //
                    // }

                    //ItemDef Setting
                    if(!itemDefMap.has(itemDef.oid)) {
                        itemDefMap.set(itemDef.oid, itemDef);
                    }
                });

                //ITEMGROUP - ITEM REF SETTING
                //======================================================================
                //ItemGroupDef 항목에 참조되는 itemRef 설정 변경
                const nodeItemOIDs = itemRefNodes.map(iNode => iNode.dataOID);
                const cloneItemRef = _.cloneDeep(itemGroupDef.itemRef);

                //ITEMGROUP - VALUELIST
                //ITEMGROUP이 STATIC이면서, ValueList가 존재하는 경우 처리
                if(itemGroupDef.repeating === 'STATIC' && itemRefValueListNode === null) { //itemGroup에서 valueList를 사용하지 않으면,
                    //valueListRefItem - valueListOID를 사용하는 참조 아이템에서 valueListOID의 참조 데이터를 삭제한다.
                    const valueListRefItem = cloneItemRef.find(iRef => iRef?.valueListOID != null);
                    if(valueListRefItem != null) { delete valueListRefItem.valueListOID; }
                } else if(itemRefValueListNode !== null) { //ValueList 데이터가 있는 경우,

                    const valueListDef = {...itemRefValueListNode.data};
                    const valueListItemRefNodes = itemRefValueListNode.children;

                    /**
                     * Validation ItemGroup - ValueList-1. OID Check
                     * 덮어쓰기 하지 않는 경우, 이미 존재하는 metaData정보가 있으면 오류 표시
                     * Download일 때, 현재 metaData 정보와 중첩되는 지 확인,
                     * Upload일 때, 선택한 Library 정보가 중첩되는 지 확인
                     */
                    if((interfaceType === 'download' && overwrite === false && metaData.valueList.has(valueListDef.oid)) ||
                        (interfaceType === 'upload' && overwrite === false && libraryMap.valueList.has(valueListDef.oid))) {
                        errorMessages.add(t('message.value.list.oid.has.already.been.registered', {valueList : defVariable.VALUELIST, oid : valueListDef.oid}))
                    }
                    else if (itemGroupDef.itemRef == null || itemGroupDef.itemRef.length > 0) {
                        errorMessages.add(t('message.value.list.oid.selected.item.def.entry.does.not.exist', {valueList : defVariable.VALUELIST, oid : valueListDef.oid}))
                    }

                    //itemRef 항목에서 참조하는 codeList ValueList 표시.
                    //TODO ValueList - Item Node에서 codeListRef/ValueListRef 정보 가져오기
                    valueListItemRefNodes.forEach(valueListItemNode => {
                        const valueListItemDef = {...valueListItemNode.data};
                        const valueListItemRefCodeListNode = valueListItemNode.children.find(iNode => iNode.type === 'codeList')??null;
                        // const itemRefValueListNode = valueListItemNode.children.find(iNode => iNode.type === 'valueList')??null;

                        /**
                         * Validation Item-1. OID Check
                         */
                        if((interfaceType === 'download' && overwrite === false && metaData.item.has(valueListItemDef.oid)) ||
                            (interfaceType === 'upload' && overwrite === false && libraryMap.item.has(valueListItemDef.oid))) {
                            errorMessages.add(t('message.item.value.list.item.oid.item.def.is.already.registered', {item : defVariable.ITEM, oid : valueListItemDef.oid}))
                        }

                        //CodeList가 있는 경우 CodeList 저장
                        if(valueListItemRefCodeListNode !== null) {
                            const codeList =  {...valueListItemRefCodeListNode.data};

                            /**
                             * Validation CodeList-1. OID Check
                             */
                            if((interfaceType === 'download' && overwrite === false && metaData.codeList.has(codeList.oid)) ||
                                (interfaceType === 'upload' && overwrite === false && libraryMap.codeList.has(codeList.oid))) {
                                errorMessages.add(t('message.code.list.oid.has.already.been.registered', {codeList : defVariable.CODELIST, oid : codeList.oid}))
                            }

                            if(!codeListMap.has(codeList.oid)) {
                                codeListMap.set(codeList.oid, codeList);
                            }
                        }

                        //ItemDef Setting
                        if(!itemDefMap.has(valueListItemDef.oid)) {
                            itemDefMap.set(valueListItemDef.oid, valueListItemDef);
                        }
                    });

                    //valueListDef 항목에 참조되는 itemRef 설정변경
                    const nodeValueListItemOIDs = valueListItemRefNodes.map(vliNode => vliNode.dataOID);
                    const cloneValueListItemRef = _.cloneDeep(valueListDef.itemRef);

                    //ValueListDef Setting
                    valueListDef.itemRef = cloneValueListItemRef.filter(vli => nodeValueListItemOIDs.includes(vli.itemOID));
                    if(!valueListDefMap.has(valueListDef.oid)) {
                        valueListDefMap.set(valueListDef.oid, valueListDef);
                    } else { //ValueListDef 항목이 이미 중복되는 경우,

                        /**
                         * Validation ItemGroup - ValueList-2. ItemRef Check
                         * ValueListDef에 대하여 이미 설정한 ValueListDef 정보가 존재하면 itemRef 정보가 동일한 지, 확인
                         */
                        const savedValueListDef = valueListDefMap.get(valueListDef.oid);
                        const isDifferentSize = savedValueListDef.itemRef.length !== valueListDef.itemRef.length //사이즈가 다른가?
                        const isItemRefEqual = _.isEqual(savedValueListDef.itemRef, valueListDef.itemRef); //참조항목 구성이 다른가?

                        if(isDifferentSize || !isItemRefEqual) {
                            errorMessages.add(t('message.value.list.def.oid.there.is.an.item.ref.that.has.been.selected.differently.in.value.list', {valueList : defVariable.VALUELIST, valueListDefOid : valueListDef.oid}));
                        }
                    }
                }

                //ItemGroupDef Setting
                itemGroupDef.itemRef = cloneItemRef.filter(i => nodeItemOIDs.includes(i.itemOID))
                if(!itemGroupDefMap.has(itemGroupDef.oid)) {
                    itemGroupDefMap.set(itemGroupDef.oid, itemGroupDef)
                } else { //itemGruopDefMap 항목이 이미 중복되는 경우,

                    /**
                     * Validation ItemGroup-2. ItemRef Check
                     * ItemGroupDef에 대하여 이미 설정한 ItemGroup 정보가 존재하면 itemRef 정보가 동일한 지, 확인
                     */
                    const savedItemGroupDef = itemGroupDefMap.get(itemGroupDef.oid);
                    const isDifferentSize = savedItemGroupDef.itemRef.length !== itemGroupDef.itemRef.length //사이즈가 다른가?
                    const isItemRefEqual = _.isEqual(savedItemGroupDef.itemRef, itemGroupDef.itemRef); //참조항목 구성이 다른가?

                    if(isDifferentSize || !isItemRefEqual) {
                        errorMessages.add(t('message.item.group.def.oid.there.is.an.item.ref.that.has.been.selected.differently.in.item.group', {itemGroup : defVariable.ITEMGROUP, itemGroupDefOid : itemGroupDef.oid}));
                    }
                }
                //======================================================================
            })


            //FORM - ITEMGROUP REF SETTING
            //=============================================================
            //FormDef항목에 참조되는 itemGroupRef 설정 변경
            const nodeItemGroupOIDs = itemGroupRefNodes.map(igNode => igNode.dataOID);
            const cloneItemGroupRef = _.cloneDeep(formDef.itemGroupRef);
            formDef.itemGroupRef = cloneItemGroupRef.filter(ig => nodeItemGroupOIDs.includes(ig.itemGroupOID));

            //LAB Form일 때, 필수 Item항목 체크 - Download일 때만 체크
            if(interfaceType === 'download' && formDef.formType === 'LABORATORY_TEST_FORM') {
                const itemOIDs = itemGroupRefNodes.flatMap(igNode => igNode.children.map(iNode => iNode.dataOID));

                //LabForm에서 필요로 하는 OID 항목
                const labRequiredItemOIDs = ['LBDAT','LBTESTCD','LBORRES'];
                //Central Processing with CS일 때,
                if(metaDataStore.studyInfo.labType.key === 'CENTRAL_CS') {
                    labRequiredItemOIDs.push('LBNRIND');
                    labRequiredItemOIDs.push('LBCLSIG');
                }
                //Local Processing일 때,
                else if(metaDataStore.studyInfo.labType.key === 'LOCAL') {
                    labRequiredItemOIDs.push('LBNRIND');
                }

                //현재 선택된 OID에서 누락된 OID 항목
                const requiredItemOIDs = labRequiredItemOIDs.filter(oid => !itemOIDs.includes(oid))??[];
                if(requiredItemOIDs.length > 0 ) {
                    errorMessages.add(t('message.def.variable.form.form.def.oid.form.is.missing.required.items.required.item.oids.Join', {defVariableForm: defVariable.FORM, formDefOid : formDef.oid, requiredItemOIDsJoin : requiredItemOIDs.join(',')}));
                }
            }

            //FormDef Setting
            if(!formDefMap.has(formDef.oid)) {
                formDefMap.set(formDef.oid, formDef);
            }
            //=============================================================
        })

        //Reference된 ECS OID항목에 대한 제거 (Library에서 ecs 미지원)
        Array.from(formDefMap.values()).forEach(formDef => referenceECSClear(formDef?.itemGroupRef??[]));
        Array.from(itemGroupDefMap.values()).forEach(itemGroupDef => referenceECSClear(itemGroupDef?.itemRef??[]));
        referenceECSClear(Array.from(itemDefMap.values()));

        return {
            errorMessages,
            formDefMap,
            itemGroupDefMap,
            itemDefMap,
            codeListMap,
            valueListDefMap
        }
    }

    //Library 항목 다운로드
    const onDownloadLibrary = () => {

        const {errorMessages,
            formDefMap,
            itemGroupDefMap,
            itemDefMap,
            codeListMap,
            valueListDefMap} = getCheckedTreeToDefinitionData();

        if(errorMessages.size > 0) {
            setErrorMessages(Array.from(errorMessages));
        } else {
            setErrorMessages(null);
            /**
             * Library Download 동작 처리
             */

            const data = {
                formDefs: Array.from(formDefMap.values()),
                itemGroupDefs: Array.from(itemGroupDefMap.values()),
                itemDefs: Array.from(itemDefMap.values()),
                codeLists: Array.from(codeListMap.values()),
                valueListDefs: Array.from(valueListDefMap.values())
            };
            const result = {data, params};
            downloadMutate(result);
        }
    }

    const onUploadLibrary = () => {

        const {errorMessages,
            formDefMap,
            itemGroupDefMap,
            itemDefMap,
            codeListMap,
            valueListDefMap} = getCheckedTreeToDefinitionData();

        if(errorMessages.size > 0) {
            setErrorMessages(Array.from(errorMessages));

        } else {
            setErrorMessages(null);
            /**
             * Library Download 동작 처리
             */

            const data = {
                formDefs: Array.from(formDefMap.values()),
                itemGroupDefs: Array.from(itemGroupDefMap.values()),
                itemDefs: Array.from(itemDefMap.values()),
                codeLists: Array.from(codeListMap.values()),
                valueListDefs: Array.from(valueListDefMap.values())
            };
            const result = {data, params, libraryId:library?.id};
            uploadMutate(result);
        }
    }

    /**
     * RefList에 설정된 MethodOID 및 CollectionExceptionOID 항목에 대해서 속성이 확인되면 삭제한다.
     * @param RefList
     */
    const referenceECSClear = (dataList) => {
        if(dataList != null && Array.isArray(dataList) && dataList.length > 0) {
            dataList.forEach((data, idx) => {
                if(data.hasOwnProperty('methodOID')) {
                    delete data['methodOID'];
                }

                if(data.hasOwnProperty('collectionExceptionConditionOID')) {
                    delete data['collectionExceptionConditionOID'];
                }

                if(data.hasOwnProperty('imputationMethodOID')) {
                    delete data['imputationMethodOID'];
                }

                if(data.hasOwnProperty('switchLabelOID')) {
                    delete data['switchLabelOID'];
                }

                if(data.hasOwnProperty('switchDescriptionOID')) {
                    delete data['switchDescriptionOID'];
                }
            })
        }
    }

    /**
     * interface 화면의 Title 옵션
     * @type {{init: string, download: JSX.Element, upload: JSX.Element}}
     */
    const interfaceTitle = {
        init: 'Library Interface',
        upload: <><Button type={'text'} onClick={init}><LeftOutlined /></Button>&nbsp;Library Upload</>,
        download: <><Button type={'text'} onClick={init}><LeftOutlined /></Button>&nbsp;Library Download</>
    }

    /**
     * Common Menu
     */
    const selectLibrary = async (id) => {

        const checkLabForm = (form) => {
            return form.formType === 'LABORATORY_TEST_FORM'
        }

        try {
            const {status, data} = await axios.post(`/api/builder/library-interface/library/${id}`)
            if(status === httpStatus.ok) {
                const libMap = toLibraryMap(data?.library);
                setLibrary(data?.library);
                setLibraryMap(libMap);
                setInterfaceWidth(modalWidths.steps);

                //미리 Checked 표시되어야 하는 항목 설정
                const currentSelectedKeys = [];
                Array.from(libMap.form.values()).filter(checkLabForm).forEach(form => {
                    form.itemGroupRef.forEach(igRef => {
                        const itemGroup = libMap.itemGroup.get(igRef.itemGroupOID);
                        itemGroup.itemRef.forEach(iRef => {
                            const item = libMap.item.get(iRef.itemOID);
                            //LabForm에서 필수로 선택되어있어야 하는 항목 선택
                            //LabType에 상관없이, 아래 항목은 무조건 필수
                            const labRequiredItemOIDs = ['LBDAT','LBTESTCD','LBORRES'];
                            //Central Processing with CS일 때,
                            if(metaDataStore.studyInfo.labType.key === 'CENTRAL_CS') {
                                labRequiredItemOIDs.push('LBNRIND');
                                labRequiredItemOIDs.push('LBCLSIG');
                            }
                            //Local Processing일 때,
                            else if(metaDataStore.studyInfo.labType.key === 'LOCAL') {
                                labRequiredItemOIDs.push('LBNRIND');
                            }

                            //SelectedKeys 설정
                            if(labRequiredItemOIDs.includes(item.oid)) {
                                currentSelectedKeys.push(`item_${form.oid}_${itemGroup.oid}_${item.oid}`);
                            }
                        })
                    })
                })

                setForceCheckedDataKeys(currentSelectedKeys);
                setCheckedKeys(currentSelectedKeys); //처음부터 체크가 되어있는 경우로 분류하는 것 방지
                setSelectData(null); //선택한 데이터 초기화
            }
        } catch(e) {
            errorHandle(e);
        }
    }

    return (
      <Modal
          open={open}
          forceRender={true}
          title={interfaceTitle[interfaceType]}
          onCancel={onCancel}
          width={interfaceWidth}
          destroyOnClose={true}
          maskClosable={false} //Backdrop 비활성화
          styles={{body:{maxHeight:'40rem'}}}
          footer={
              <div className={'pt-2'}>
                  {
                      (library != null && interfaceType === 'upload') &&
                      <Button type={'primary'} onClick={onUploadLibrary}><i className={'fa fa-upload mr-2'}></i>Upload</Button>
                  }
                  {
                      (library != null && interfaceType === 'download') &&
                      <Button type={'primary'} onClick={onDownloadLibrary}><i className={'fa fa-download mr-2'}></i>Download</Button>
                  }
                  <Button key={'Close'} onClick={() => onCancel()}>Close</Button>
              </div>
          }
      >
          {
              interfaceType === 'init' &&
              <Card>
                  <Row gutter={[10,40]} className={'text-center'}>
                      <Col span={24}>
                          <Button type={'primary'} onClick={onUpload}><i className='fa fa-upload mr-2'></i>Upload</Button>
                      </Col>
                      <Col span={24}>
                          <Button type={'default'} onClick={onDownload}><i className='fa fa-download mr-2'></i>Download</Button>
                      </Col>
                  </Row>
              </Card>
          }
          {
              interfaceType === 'upload' &&
              <LibraryUpload library={library} libraries={libraries} metaData={metaData} selectLibrary={selectLibrary}
                             overwrite={overwrite} setOverwrite={setOverwrite} studyInfo={metaDataStore.studyInfo}
                             treeData={treeData} setTreeData={setTreeData} setCheckedTreeData={setCheckedTreeData}
                             filteredSelectTreeData={filteredSelectTreeData} setFilteredSelectTreeData={setFilteredSelectTreeData}
                             forceCheckedDataKeys={forceCheckedDataKeys} checkedKeys={checkedKeys} setCheckedKeys={setCheckedKeys}
                             selectData={selectData} setSelectData={setSelectData} errorMessages={errorMessages}
                             typeColor={typeColor} typeLabel={typeColor} typeShortLabel={typeShortLabel} defTag={defTag}
              />
          }


          {
              interfaceType === 'download' &&
              <LibraryDownload library={library} libraries={libraries} libraryMap={libraryMap} selectLibrary={selectLibrary}
                               overwrite={overwrite} setOverwrite={setOverwrite} studyInfo={metaDataStore.studyInfo}
                               treeData={treeData} setTreeData={setTreeData} setCheckedTreeData={setCheckedTreeData}
                               filteredSelectTreeData={filteredSelectTreeData} setFilteredSelectTreeData={setFilteredSelectTreeData}
                               forceCheckedDataKeys={forceCheckedDataKeys} checkedKeys={checkedKeys} setCheckedKeys={setCheckedKeys}
                               selectData={selectData} setSelectData={setSelectData} errorMessages={errorMessages}
                               typeColor={typeColor} typeLabel={typeColor} typeShortLabel={typeShortLabel} defTag={defTag}
              />
          }
      </Modal>
    );

}

export default withTranslation()(LibraryInterfaceDef);