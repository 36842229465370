import {CardBody, CardFooter, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {Button, Card, Checkbox, Col, Form, Input, InputNumber, message, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import DefaultLoading from "../../DefaultLoading";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {useTranslation} from "react-i18next";

const StudyEdit = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();

    //query-string이 존재하면 studyID에 해당하는 값 불러오기.
    const params = queryString.parse(location.search);
    const [loaded, setLoaded] = useState(false);

    //Study 정보 변경 부분
    const [edcStudyInfo, setEdcStudyInfo] = useState(null);
    const [study, setStudy] = useState(null);

    //Types Info
    const [entryTypes, setEntryTypes] = useState(null);
    const [compareTypes, setCompareTypes] = useState(null);
    const [labTypes, setLabTypes] = useState(null);
    const [subjectLimitTypes, setSubjectLimitTypes] = useState(null);
    const [sponsors, setSponsors] = useState(null);

    //Country Map / Filter Map
    const [countryList, setCountryList] = useState(null);
    const [usedCountries, setUsedCountries] = useState(null);
    const [usedStcLink, setUsedStcLink] = useState(false);

    //Libraries
    const [sdtmct, setSdtmct] = useState(null);
    const [cdashct, setCdashct] = useState(null);
    const [cdashig, setCdashig] = useState(null);

    const [isDDE, setIsDDE] = useState(false);
    const [isStudyLimit, setIsStudyLimit] = useState(false);
    const [isPhaseOther, setIsPhaseOther] = useState(false);
    const [useRandomization, setUseRandomization] = useState(false);
    // const [useIPManagement, setUseIPManagement] = useState(false);

    //Button Loading
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //GET Request
        (async () => {
            try {
                //REST API Request
                const {status, data} = await axios.get(`/api/builder/studies/edit`, {params: params});

                if(status === httpStatus.ok) {
                    //State Setting
                    if(data?.edcStudyInfo?.acl != null) {
                        delete data.edcStudyInfo.acl; //Study Info에서 ACL 정보 Filter.
                    }

                    //env deserialize 시, key만 넘겨야 함.
                    if(data?.edcStudyInfo.env !== null && data?.edcStudyInfo.env !== undefined) {
                        data.edcStudyInfo.env = data.edcStudyInfo.env.key;
                    }

                    if(data?.edcStudyInfo.subjectLimitType !== null && data?.edcStudyInfo.subjectLimitType !== undefined) {
                        data.edcStudyInfo.subjectLimitType = data.edcStudyInfo.subjectLimitType.key;
                    }

                    setEdcStudyInfo(data.edcStudyInfo);
                    setStudy(data.edcStudyInfo.study);

                    setEntryTypes(data?.entryTypes);
                    setCompareTypes(data?.compareTypes);
                    setLabTypes(data?.labTypes);
                    setSubjectLimitTypes(data?.subjectLimitTypes);
                    setSponsors(data?.sponsors);
                    setCountryList(data?.countryList);
                    setUsedCountries(data?.usedCountries||[]);
                    setUsedStcLink(data?.usedStcLink);

                    if(data?.sdtmct != null) {
                        setSdtmct(data?.sdtmct.reverse());
                    }
                    if(data?.cdashct != null) {
                        setCdashct(data?.cdashct.reverse());
                    }
                    if(data?.cdashig != null) {
                        setCdashig(data?.cdashig.reverse());
                    }

                    setLoaded(true);
                    setIsDDE(data?.edcStudyInfo.entryType?.key === 'DDE');
                    setIsPhaseOther(data?.edcStudyInfo.phase === 'Other');
                    setIsStudyLimit(data?.edcStudyInfo.subjectLimitType === "EDC_STUDY_LIMIT");
                    setUseRandomization(data?.edcStudyInfo?.useRandomization);
                    // setUseIPManagement(data?.edcStudyInfo?.useIPManagement);

                    console.log(edcStudyInfo);
                }

            } catch (error) {
                errorHandle(error, navigate, '/studies');
            }
        })()
    }, []);

    const onFinish = (values) => {
        //Loading
        setLoading(true);

        (async () => {
            //Create/Edit Study
            try {
                const body = {...edcStudyInfo, ...values};
                const {data, status} = await axios({
                    url: `/api/builder/studies/edit`,
                    data: body, method: 'post',
                });

                if (status === httpStatus.ok) {
                    message.success(data.message);
                    navigate('/studies');
                }
            } catch (e) {
                errorHandle(e);
            }
        })()

        //Loading 해제
        setLoading(false);
    }

    const onFinishFailed = (error) => {
        console.log("%cError : ", "color:red", error);
    }

    const onSelectEntryType = (value) => {
        setIsDDE(value === 'DDE');
    }

    const onChangePhase = phase => {
        setIsPhaseOther(phase === 'Other');
        if(phase === 'Other') {
            form.setFieldsValue({phaseOther:""});
        }
    }

    const onChangeStudyLimit = limit => {
        setIsStudyLimit(limit === "EDC_STUDY_LIMIT");
        if(limit === "EDC_STUDY_LIMIT") {
            form.setFieldsValue({totalSubjectCount:""});
        }
    }

    return (
        <>
            {
                !loaded &&
                <DefaultLoading />
            }
            {
                loaded &&
                <DefaultCard>
                    <CardHeader>
                        <h3>Study</h3>
                    </CardHeader>
                    <Form layout="vertical"
                          form={form}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}>
                        <CardBody>
                            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                <Col span={12}>
                                    <Card title={t('study.info')} type={'inner'}>
                                        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                            <Col className='gutter-row' span={12}>
                                                <Form.Item name={['study', 'name']} label={t('study.name.label')} required
                                                           rules={[{
                                                               required: true,
                                                               message: t('study.name.required.message'),
                                                           }]}
                                                           initialValue={study?.name}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={12}>
                                                <Form.Item name={['study', 'protocolName']} label={t('study.protocol.name.label')}
                                                           required
                                                           rules={[{
                                                               required: true,
                                                               message: t('study.protocol.name.required.message'),
                                                           }]}
                                                           initialValue={study?.protocolName}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                            <Col className='gutter-row' span={12}>
                                                <Form.Item name={['phase']} label={t('study.phase.label')}
                                                           required
                                                           rules={[{
                                                               required: true,
                                                               message: t('study.phase.required.message'),
                                                           }]}
                                                           initialValue={edcStudyInfo?.phase}
                                                >
                                                    <Select onChange={onChangePhase}>
                                                        <Select.Option value={'Phase I'}>Phase I</Select.Option>
                                                        <Select.Option value={'Phase I & II'}>Phase I & II</Select.Option>
                                                        <Select.Option value={'Phase II/Phase IIa/Phase IIb'}>Phase II/Phase IIa/Phase IIb</Select.Option>
                                                        <Select.Option value={'Phase III'}>Phase III</Select.Option>
                                                        <Select.Option value={'Phase IV'}>Phase IV</Select.Option>
                                                        <Select.Option value={'IIT'}>IIT</Select.Option>
                                                        <Select.Option value={'PMS'}>PMS</Select.Option>
                                                        <Select.Option value={'Observational Study'}>Observational Study</Select.Option>
                                                        <Select.Option value={'BE'}>BE</Select.Option>
                                                        <Select.Option value={'Pilot'}>Pilot</Select.Option>
                                                        <Select.Option value={'Medical Device'}>Medical Device</Select.Option>
                                                        <Select.Option value={'Registry'}>Registry</Select.Option>
                                                        <Select.Option value={'Other'}>Other</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={12}>
                                                <Form.Item name={['phaseOther']} label={t('study.phase.other.label')}
                                                           required={isPhaseOther}
                                                           rules={[{
                                                               required: isPhaseOther,
                                                               message: t('study.phase.other.required.message'),
                                                           }]}
                                                           initialValue={edcStudyInfo?.phaseOther}
                                                           hidden={!isPhaseOther}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item name={['study', 'description']} label={t('study.description.label')} required
                                                   rules={[{
                                                       required: true,
                                                       message: t('study.description.required.message'),
                                                   }]}
                                                   initialValue={study?.description}
                                        >
                                            <Input.TextArea/>
                                        </Form.Item>
                                        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                                <Col className='gutter-row' span={12}>
                                                    <Form.Item name={['versionControlType']} label={t('study.version-control.label')}
                                                               required
                                                               rules={[{
                                                                   required: true,
                                                                   message: t('study.version-control.required.message'),
                                                               }]}
                                                               initialValue={edcStudyInfo?.versionControlType}
                                                    >
                                                        <Select>
                                                            <Select.Option value={'CONSENT_DATE'}>Consent Date</Select.Option>
                                                            <Select.Option disabled={true} value={'CONSENT_DATE_WITH_VISIT_DATE'}>Consent Date with Visit Date</Select.Option>
                                                            <Select.Option value={'DIRECT_SELECT'}>Direct Selection</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                            <Col className='gutter-row' span={isDDE ? 12 : 24}>
                                                <Form.Item name='entryType' label={t('study.entry-type.label')} required
                                                           rules={[{
                                                               required: true,
                                                               message: t('study.entry-type.required.message'),
                                                           }]}
                                                           initialValue={edcStudyInfo.entryType?.key || ""}
                                                >
                                                    <Select onSelect={onSelectEntryType}>
                                                        <Select.Option key={"entryType"} value={""}>...</Select.Option>
                                                        {
                                                            entryTypes.map((entryType, index) => (
                                                                <Select.Option key={entryType?.key} value={entryType?.key}
                                                                               ctRequired={entryType.enabled}>
                                                                    {entryType.description}
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={12} hidden={!isDDE}>
                                                {/* Double Data Entry 값일때만 required 활성화 */}
                                                <Form.Item name='compareType' label={t('study.compare-type.label')} required={isDDE}
                                                           rules={[{
                                                               required: isDDE,
                                                               message: t('study.compare-type.required.message'),
                                                           }]}
                                                           hidden={!isDDE}
                                                           initialValue={edcStudyInfo.compareType?.key || null}
                                                >
                                                    <Select disabled={!isDDE}>
                                                        <Select.Option value={""}>
                                                            ...
                                                        </Select.Option>
                                                        {
                                                            compareTypes.map((compareType, index) => (
                                                                <Select.Option key={index} value={compareType?.key}>
                                                                    {compareType.label}
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24} className='d-flex flex-row'>
                                                <Form.Item name='useCoding' valuePropName={'checked'} label={t('study.extension.options.label')}
                                                           initialValue={edcStudyInfo?.useCoding}>
                                                    <Checkbox className={'mr-2'}>{t('study.extension.options.medical.coding.label')}</Checkbox>
                                                </Form.Item>
                                                <Form.Item name='useSAEReport' valuePropName={'checked'} label={' '}
                                                           initialValue={edcStudyInfo?.useSAEReport}>
                                                    <Checkbox className={'mr-2'}>{t('study.extension.options.sae.report.label')}</Checkbox>
                                                </Form.Item>
                                                <Form.Item name='useRandomization' valuePropName={'checked'} label={' '}
                                                           initialValue={useRandomization}>
                                                    <Checkbox className={'mr-2'} onChange={(e) => {
                                                        setUseRandomization(e.target.checked);
                                                        // setUseIPManagement(false);
                                                        // console.log(form);
                                                        // if(e.target.checked === false){
                                                        //     form.setFieldValue('useIPManagement', false);
                                                        // }
                                                    }
                                                    }>{t('study.extension.options.randomization.label')}</Checkbox>
                                                </Form.Item>
                                                <Form.Item name='useIndependentEvaluation' valuePropName={'checked'} label={' '}
                                                           initialValue={edcStudyInfo?.useIndependentEvaluation}>
                                                    <Checkbox className={'mr-2'}>{t('study.extension.options.independent.evaluation.label')}</Checkbox>
                                                </Form.Item>
                                                <Form.Item name='useEPRO' valuePropName={'checked'} label={' '}
                                                           initialValue={edcStudyInfo?.useEPRO}>
                                                    <Checkbox className={'mr-2'}>ePRO</Checkbox>
                                                </Form.Item>
                                                {/*{*/}
                                                {/*    useRandomization &&*/}
                                                {/*    <Form.Item name='useIPManagement' valuePropName={'checked'} label={' '}*/}
                                                {/*                                   initialValue={useIPManagement}>*/}
                                                {/*        <Checkbox className={'mr-2'} onChange={(e) => setUseIPManagement(e.target.checked)}>{t('study.extension.options.ip.manage.label')}</Checkbox>*/}
                                                {/*    </Form.Item>*/}
                                                {/*}*/}
                                                {
                                                    usedStcLink &&
                                                    <Form.Item name='useStcLink' valuePropName={'checked'} label={' '}
                                                               initialValue={edcStudyInfo?.useStcLink}>
                                                        <Checkbox className={'mr-2'}>{t('study.extension.options.stc.link.label')}</Checkbox>
                                                    </Form.Item>
                                                }
                                            </Col>
                                        </Row>
                                        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                            <Col span={12}>
                                                <Form.Item name='labType' label={t('study.lab.type.label')}
                                                           rules={[{
                                                               required: true,
                                                               message: t('study.lab.type.required.message'),
                                                           }]}
                                                           initialValue={edcStudyInfo.labType?.key || ""}
                                                >
                                                    <Select>
                                                        <Select.Option value={""}>
                                                            ...
                                                        </Select.Option>
                                                        {
                                                            labTypes.map((labType, index) => (
                                                                <Select.Option key={index} value={labType.key}>
                                                                    {labType.title}
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name='sponsor' label={t('study.sponsor.label')}
                                                           rules={[{
                                                               required: true,
                                                               message: t('study.sponsor.required.message'),
                                                           }]}
                                                           initialValue={edcStudyInfo.sponsor?.id || ""}
                                                >
                                                    <Select>
                                                        <Select.Option value={""}>
                                                            ...
                                                        </Select.Option>
                                                        {
                                                            sponsors.map((sponsor, index) => (
                                                                <Select.Option key={sponsor.id} value={sponsor.id}>
                                                                    {sponsor.name}
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={isStudyLimit?12:24}>
                                                <Form.Item name='subjectLimitType' label={t('study.subject.limit.type.label')}
                                                           rules={[{
                                                               required: true,
                                                               message: t('study.subject.limit.type.required.message'),
                                                           }]}
                                                           initialValue={edcStudyInfo?.subjectLimitType || "NO_LIMIT"}
                                                >
                                                    <Select onChange={onChangeStudyLimit}>
                                                        {
                                                            subjectLimitTypes.map((type, index) => (
                                                                <Select.Option key={type.key} value={type.key}>
                                                                    {t(type.code)}
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} hidden={!isStudyLimit}>
                                                <Form.Item name='totalSubjectCount' label={t('study.total.subject.count.label')}
                                                           rules={[{
                                                               required: isStudyLimit,
                                                               message: t('study.total.subject.count.required.message')
                                                           }]}
                                                           initialValue={edcStudyInfo?.totalSubjectCount || ""}
                                                >
                                                    <InputNumber style={{width:'100%'}} min={1} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Form.Item label={t('study.countries.label')} required
                                                   key={'study-countries'}
                                                   name={'selectedCountry'}
                                                   rules={[{
                                                       required: true,
                                                       message: t('study.countries.required.message')
                                                   }]}
                                                   initialValue={edcStudyInfo?.selectedCountry || undefined}
                                        >
                                            <Select showSearch
                                                    mode={'multiple'}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                            >
                                                {
                                                    countryList.map((country, index) => (
                                                        <Select.Option key={`${country.id}_${index}`} value={country.id}
                                                                       disabled={usedCountries.includes(country.id)}
                                                            >
                                                            {country.value}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card title={'CDISC LIBRARY'} type={'inner'}>
                                        <Form.Item name='cdash' label='CDASHIG'
                                                   initialValue={edcStudyInfo?.cdash || ""}
                                                   /*rules={[{
                                                       required: true,
                                                       message: 'CDASHIG를 선택해 주세요.'
                                                   }]}*/>
                                            <Select>
                                                <Select.Option value={""}>
                                                    ...
                                                </Select.Option>
                                                {
                                                    cdashig.map((ig, index) => (
                                                        <Select.Option key={index} value={ig.href}>
                                                            {ig.title}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name='cdashct' label='CDASH CT'
                                                   initialValue={edcStudyInfo?.cdashct || ""}
                                                   /*rules={[{
                                                       required: true,
                                                       message: 'CDASH CT를 선택해 주세요.'
                                                   }]}*/>
                                            <Select>
                                                <Select.Option value={""}>
                                                    ...
                                                </Select.Option>
                                                {
                                                    cdashct.map((ct, index) => (
                                                        <Select.Option key={index} value={ct.href}>
                                                            {ct.title}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name='sdtmct' label='SDTM CT'
                                                   initialValue={edcStudyInfo?.sdtmct || ""}
                                                   /*rules={[{
                                                       required: true,
                                                       message: 'SDTM CT를 선택해 주세요.'
                                                   }]}*/>
                                            <Select>
                                                <Select.Option value={""}>
                                                    ...
                                                </Select.Option>
                                                {
                                                    sdtmct.map((ct, index) => (
                                                        <Select.Option key={index} value={ct.href}>
                                                            {ct.title}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className='text-right'>
                            <Button type={'default'} onClick={() => {navigate("/studies");}} className={'mr-3'}> Cancel </Button>
                            <Button loading={loading} type={'primary'} htmlType={'submit'}> Submit </Button>
                        </CardFooter>
                    </Form>
                </DefaultCard>
            }
        </>
    )

}

export default StudyEdit;
