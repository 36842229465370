import React from "react";
import {Helmet} from "react-helmet-async";
import Dashboard from "../../../../components/common/builder/studies/Dashboard";

const DashboardPage = () => {

    return (
        <>
            <Helmet title={'BUILDER :: Dashboard'} />
            <Dashboard />
        </>
    )
}

export default DashboardPage;