import {useNavigate, useParams} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {errorHandle} from "../../../../lib/BuilderUtils";
import axios from "axios";
import {Button, Col, DatePicker, Descriptions, Form, Input, message, Row} from "antd";
import DefaultLoading from "../../DefaultLoading";
import {CardBody, CardFooter, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {CheckOutlined, RightOutlined, RightSquareOutlined} from "@ant-design/icons";
import {Table} from "reactstrap";
import format from 'dayjs';
import {useTranslation} from "react-i18next";

const ManageSiteVersion = () => {

    const navigate = useNavigate();
    const params = useParams();
    const [form] = Form.useForm();
    const {t} = useTranslation();

    const [studySite, setStudySite] = useState(null);
    const [versionMap, setVersionMap] = useState(null);
    const [effectiveDateMap, setEffectiveDateMap] = useState(null);
    const [irbApprovalDateMap, setIrbApprovalDateMap] = useState(null);

    //Button Loading
    const [loading, setLoading] = useState(false);


    useEffect( () => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/sites/${params.siteId}/versions`)

                if (status === httpStatus.ok) {
                    setStudySite(data?.studySite);
                    setVersionMap(data?.versionMap);
                    setEffectiveDateMap(data?.effectiveDateMap);
                    setIrbApprovalDateMap(data?.irbApprovalDateMap);
                }
            } catch (e) {
                errorHandle(e, navigate, `/studies/${params.sid}/sites`);
            }
        })()
    }, []);


    useEffect(() => {
        if (versionMap !== null && effectiveDateMap !== null && irbApprovalDateMap !== null) {

            let fields = {};

            Object.entries(versionMap).map((version, index) => {
                const name = version[0];
                const versionList = version[1];
                const versions = [];

                versionList.map(verInfo => {
                    versions.push({
                        name: verInfo.name,
                        version: verInfo.version,
                        mdvId: verInfo.id,
                        effectiveDate: effectiveDateMap[verInfo.id]!=null?format(effectiveDateMap[verInfo.id]):null,
                        irbApprovalDate: irbApprovalDateMap[verInfo.id]!=null?format(irbApprovalDateMap[verInfo.id]):null,
                    });
                })

                fields = {
                    ...fields,
                    [name]: {
                        versionInfo: versions
                    }
                }
            });

            form.setFieldsValue(fields);
        }
    }, [versionMap, effectiveDateMap, irbApprovalDateMap]);


    const onFinish = async (values) => {
        setLoading(true);

        try {
            let body = [];
            //mdvId, effectiveDate 추출 - null값은 삭제처리
            Object.keys(versionMap).map((version) => {
                values[version].versionInfo.map((info, index, array) => {
                    if(info?.effectiveDate!==null && info?.irbApprovalDate!==null) {
                        info.effectiveDate = info.effectiveDate.format('YYYY-MM-DD');
                        info.irbApprovalDate = info.irbApprovalDate.format('YYYY-MM-DD');
                    }
                    else if(info?.effectiveDate!==null){
                        info.effectiveDate = info.effectiveDate.format('YYYY-MM-DD');
                    } else if(info?.irbApprovalDate!==null) {
                        info.irbApprovalDate = info.irbApprovalDate.format('YYYY-MM-DD');
                    }
                    else {
                        array.splice(index, 1);
                    }
                });
                body = [...body, ...values[version].versionInfo];
            });

            const {status, data} = await axios({
                url: `/api/builder/studies/${params.sid}/sites/${params.siteId}/versions`,
                data: body,
                method: 'post',
            })

            if(status === httpStatus.ok) {
                message.success(data.message);
                navigate(`/studies/${params.sid}/sites`);
            }
        } catch (e) {
            errorHandle(e);
        }

        setLoading(false);
    }

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    const onChange = (e) => {
        console.log(e);
    }

    const disabledDate = (current) => {
        // 오늘 이후 날짜 비활성화
        return current && current > format().endOf('day');
    };

    return (
        <>
            {
                (studySite === null || versionMap === null) &&
                <DefaultLoading/>
            }
            {
                studySite !== null && versionMap !== null &&
                <DefaultCard>
                    <CardHeader>
                        <h3><RightOutlined className={'mr-2'} />Site (CRF)</h3>
                    </CardHeader>
                    <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
                        <CardBody>
                            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                <Col span={'6'}>
                                    <Descriptions title={t('column.site.info')} layout={'vertical'} bordered>
                                        <Descriptions.Item label={t('column.site.name3')}>
                                            <h5 className='mt-2'>{studySite.name}</h5>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions bordered layout={'vertical'}>
                                        <Descriptions.Item label={t('column.site.code2')}>
                                            <h5 className='mt-2'>{studySite.oid}</h5>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col span={'18'}>
                                    {
                                        studySite.envs.map((env, index) => (
                                            <Row gutter={{xs: 6, sm: 12, md: 18, lg: 24}} key={index}>
                                                <Col span={'24'} className={'mb-3'}>
                                                    <h5>
                                                        <RightSquareOutlined className={'mr-2'}/>
                                                        <span>{env?.label}</span>
                                                    </h5>
                                                </Col>
                                                <Col span={'16'}>
                                                    <Table bordered>
                                                        <thead>
                                                        <tr>
                                                            <th className='bg-gray-1'>Version Name</th>
                                                            <th className='bg-gray-1'>{t('column.irb.approval.date')}</th>
                                                            <th className='bg-gray-1'>Effective Date</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <Form.List name={[`${env?.key}_versions`, 'versionInfo']}>
                                                            {
                                                                (fields, operation, meta) => (
                                                                    fields.map((field, index) => (
                                                                        <Fragment key={index}>
                                                                            {
                                                                                //가장 나중버전이거나, Effective Date가 있는 경우만 표시.
                                                                                (index <= 0 || (
                                                                                    form.getFieldValue([`${env?.key}_versions`, 'versionInfo', index, 'irbApprovalDate']) != null ||
                                                                                    form.getFieldValue([`${env?.key}_versions`, 'versionInfo', index, 'effectiveDate']) != null)
                                                                                ) &&
                                                                                <tr>
                                                                                    <td style={{width:'250px'}}>
                                                                                <span>
                                                                                    {form.getFieldValue([`${env?.key}_versions`, 'versionInfo', index, 'name'])}
                                                                                </span>
                                                                                        <small className='text-info text-semibold'>
                                                                                            ({form.getFieldValue([`${env?.key}_versions`, 'versionInfo', index, 'version'])})
                                                                                        </small>
                                                                                        <Form.Item name={[index, 'mdvId']} hidden={true}>
                                                                                            <Input />
                                                                                        </Form.Item>
                                                                                    </td>
                                                                                    <td>
                                                                                        {/* 가장 최상위 버전 말고는 DatePicker 지정 불가 */}
                                                                                        {
                                                                                            (index <= 0 || form.getFieldValue([`${env?.key}_versions`, 'versionInfo', index, 'irbApprovalDate']) != null) &&
                                                                                            <Form.Item name={[index, 'irbApprovalDate']} rules={[{
                                                                                            required: (index === 0),
                                                                                            message: t('studies.pls.enter.irb.approval.date')
                                                                                        }]}>
                                                                                            <DatePicker disabled={index > 0} disabledDate={disabledDate} />
                                                                                            </Form.Item>
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            (index <= 0 || form.getFieldValue([`${env?.key}_versions`, 'versionInfo', index, 'effectiveDate']) != null) &&
                                                                                            <Form.Item name={[index, 'effectiveDate']} rules={[{
                                                                                                required: (index === 0),
                                                                                                message: t('studies.pls.enter.effective.date')
                                                                                            }]}>
                                                                                                <DatePicker disabled={index > 0} />
                                                                                                {/*<DatePicker disabled={index > 0} disabledDate={disabledDate} />*/}
                                                                                            </Form.Item>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </Fragment>
                                                                    ))
                                                                )
                                                            }
                                                        </Form.List>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className={'text-right'}>
                            <Button className={'mr-3'} onClick={() => navigate(`/studies/${params.sid}/sites`)}>
                                Cancel
                            </Button>
                            <Button loading={loading} type={'primary'} htmlType={'submit'}>
                                <CheckOutlined /> Submit
                            </Button>
                        </CardFooter>
                    </Form>
                </DefaultCard>
            }
        </>
    )
}

export default ManageSiteVersion;
