import {Button, Card, Col, Dropdown, Form, Menu, Modal, Row, Select, Tag} from "antd";
import React, {useEffect, useState} from "react";
import ModalFooter from "../ModalFooter";
import DescriptionOrQuestion from "../../../../components/common/odm/ant-form/DescriptionOrQuestion";
import FormInputOID from "../../../../components/common/odm/ant-form/FormInputOID";
import {DownOutlined, MenuOutlined} from "@ant-design/icons";
import {Table} from "reactstrap";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import FormInputName from "../../../../components/common/odm/ant-form/FormInputName";
import {isEmpty} from "../../../../lib/StringUtils";
import NoDataBlock from "../../../../components/common/NoDataBlock";
import ItemContainer from "../../container/ItemContainer";
import style from '../style.module.scss'
import ControlledTerminology from "../ControlledTerminology";
import ModalTitle from "../component/ModalTitle";
import _ from 'lodash';
import {useTranslation} from "react-i18next";

const {Item, List} = Form;

const ValueListDef = ({ selectedOID, languages, valueListDef, setValueListDef, visible, onFinish, onCancel, metaData, isSdtmct, isProd }) => {

    const {t} = useTranslation();

    const [form] = Form.useForm();
    const [filteredItemDefs, setFilteredItemDefs] = useState(null);
    const [unitItemDefs, setUnitItemDefs] = useState(null);
    const itemDefs = Array.from(metaData.item.values());

    //Item Modal Setting
    const [isInit, setIsInit] = useState(true);
    const [defVisible, setDefVisible] = useState(false);
    const [defType, setDefType] = useState(null);
    const [defItem, setDefItem] = useState(null);
    const [defOption, setDefOption] = useState({}); //DefOption 사용
    const [skipConditions, setSkipConditions] = useState([]);

    const libraryMenu = {
        items: [
            {
                key: 'Laboratory',
                icon: <i className='fa fa-plus' />,
                label: 'Laboratory Test Code',
            },
            {
                key: 'VitalSign',
                icon: <i className='fa fa-plus' />,
                label: 'Vital Sign Test Code',
            }
        ],
        onClick: ({key}) => {
            onLibrary(key);
        }
    }

    const onLibrary = (field) => {
        if(field === 'Laboratory' || field === 'VitalSign') {
            setDefVisible(true);
            setDefType(field);
        }
    }

    const itemAppend = () => {
        const itemOID = form.getFieldValue('itemOID');
        const itemRef = form.getFieldValue('itemRef');

        if(isEmpty(itemOID)) {
            // message.warning("추가 할 Item 항목이 존재하지 않습니다.");
            return;
        }

        if (itemRef !== undefined) {
            const result = [...itemRef, {itemOID: itemOID, mandatory: false, orderNumber: itemRef?.length}];
            form.setFieldsValue({itemRef: result})
        } else {
            const result = [{itemOID: itemOID, mandatory: false, orderNumber: itemRef?.length??0}];
            form.setFieldsValue({itemRef: result});
        }

        if (filteredItemDefs !== null) {
            setFilteredItemDefs(filteredItemDefs.filter(i => i.oid !== itemOID && i.controlType !== 'LABEL'));
        } else {
            setFilteredItemDefs(itemDefs.filter(i => i.oid !== itemOID && i.controlType !== 'LABEL'))
        }

        // message.success(`[${itemOID}] Item을 추가하였습니다.`);

        //Select 값 초기화
        form.setFieldsValue({itemOID: null});
    }

    const itemRemove = (index) => {
        const itemRef = _.cloneDeep(form.getFieldValue('itemRef'));
        itemRef.splice(index, 1); //ItemRef에서 값 삭제
        setFilteredItemDefs(itemDefs.filter(i => !(itemRef.some(ir => ir.itemOID === i.oid)) && i.controlType !== 'LABEL'));
        form.setFieldValue("itemRef", itemRef); //field상에서 index에 해당하는 값 삭제.

        // message.success(`[${removed.itemOID}] Item을 참조항목에서 삭제하였습니다.`);
    }

    const onDragEnd = (result) => {
        const {source, destination} = result;
        // Drop한 영역이 없는 경우 아무 처리가 없이 종료
        if (!destination) {
            return;
        }
        // Drag를 시작한 영역과 Drop한 영역의 위치가 똑같으면 아무 처리 없이 종료.
        if (destination.index === source.index) { return; }

        const itemRef = form.getFieldValue('itemRef');
        const [item] = itemRef.splice(source.index, 1);
        itemRef.splice(destination.index, 0, item);

        //orderNumber 자동 설정.
        itemRef.map((item, index) => {
            item.orderNumber = index+1;
        })
        form.setFieldsValue({itemRef});
    }

    useEffect(() => {
        //YES or No로 되어있는 값을 true, false로 변경
        if (valueListDef?.itemRef != null && valueListDef.itemRef.length > 0) {
            valueListDef.itemRef.map((itemRef, index) => {
                valueListDef.itemRef[index].mandatory = valueListDef.itemRef[index].mandatory === 'YES' ? true : false;
            });
            //itemRef값이 존재하면 존재한 itemRef값을 filter처리한 값을 itemDefs로 설정.
            setFilteredItemDefs(itemDefs.filter(i => !valueListDef.itemRef.some(ir => ir.itemOID === i.oid) && i.controlType !== 'LABEL'));
        }

        //State & Form Value Setting
        setValueListDef(valueListDef);
        if(isInit) {
            form.setFieldsValue({...valueListDef});
            setIsInit(false);
        } else {
            form.setFieldsValue({...form.getFieldsValue()});
        }
    }, [valueListDef]);

    useEffect(() => {
        if(metaData) {
            //Create인 경우, itemRef에 추가하는 동작 실행.
            if(defOption?.done && defOption?.option === 'create') {
                const itemOID = defOption?.itemOID;
                itemRefAppend(itemOID);
                setDefOption({});
            } else if(defOption?.done && defOption?.option === 'controlledTerminology') {
                //ControlledTerminology 작업이 완료되고 나서, itemOIDs 항목에 값이 존재하면, 이 항목에 대하여 itemRef에 포함시킨다.
                if(defOption?.itemOIDs != null && defOption?.itemOIDs?.length > 0) {
                    defOption?.itemOIDs.forEach(itemOID => {
                        itemRefAppend(itemOID);
                    });
                    setDefOption({});
                }
            }

            setUnitItemDefs(Array.from(metaData.item.values()).filter(i => i.oid.match('U$')));

            const valueListItemConditions = [];
            const editChecks = [...metaData.editCheck.values()];
            const globalVariableSkipConditions = editChecks.filter(ecs => ecs.action.type === 'CONDITION' && ecs.useGlobalVariable);
            globalVariableSkipConditions.forEach(ecs => {
               valueListItemConditions.push({value:ecs.oid, label:ecs.name});
            });

            setSkipConditions(valueListItemConditions);
        }
    }, [metaData]);

    const itemRefAppend = (itemOID) => {
        const itemRef = form.getFieldValue('itemRef');
        if(!isEmpty(itemOID)) {
            if (itemRef !== undefined) {
                const result = [...itemRef, {itemOID: itemOID, mandatory: false, orderNumber: itemRef?.length}];
                form.setFieldsValue({itemRef: result})
            } else {
                const result = [{itemOID: itemOID, mandatory: false, orderNumber: itemRef?.length}];
                form.setFieldsValue({itemRef: result});
            }

            if (filteredItemDefs !== null) {
                setFilteredItemDefs(filteredItemDefs.filter(i => i.oid !== itemOID  && i.controlType !== 'LABEL'));
            } else {
                setFilteredItemDefs(itemDefs.filter(i => i.oid !== itemOID  && i.controlType !== 'LABEL'));
            }
        }
    }

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    const getDataType = (index) => {
        const itemData = metaData.item.get(form.getFieldValue(['itemRef', index]).itemOID);
        if(itemData !== undefined) {
            const {dataType, length, fractionDigits} = itemData;
            let res = dataType||'-';
            if (['TEXT', 'INTEGER', 'FLOAT'].includes(dataType)) {
                res += "(";
                if(length != null) {res +=`${length}`;}
                if(fractionDigits != null) {res +=`.${fractionDigits}`;}
                if(length == null && fractionDigits == null) {res +='-';}
                res += ")";
            }
            return res;
        }
        return '-';
    }

    const onCreate = (defType) => {
        setDefVisible(true);
        setDefItem(null);
        setDefType(defType);
        setDefOption({
            option: 'create'
        })
    }

    const onGetData = (defType, defItem) => {
        setDefVisible(true);
        setDefItem(defItem);
        setDefType(defType);
        setDefOption({
            option: 'modify'
        })
    }

    return (
        <Modal
            forceRender={true}
            wrapClassName={'odm-modal'}
            title={<ModalTitle iconType={'valueListDef'}>ValueList</ModalTitle>}
            width={800}
            onCancel={onCancel}
            open={visible}
            destroyOnClose={true}
            styles={{
                body: {
                    overflowY: 'auto',
                    height: 'calc(100vh - 20rem)',
                    zIndex: '100',
                }
            }}
            footer={<ModalFooter onCancel={onCancel} form={form}/>}
        >
            <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
                <Card title={'Attributes'} type={'inner'}>
                    <Row gutter={[30, 0]}>
                        <Col span={12}>
                            <FormInputOID selectedOID={selectedOID} form={form}/>
                        </Col>
                        <Col span={12}>
                            <FormInputName name={valueListDef?.name} defType={'valueListDef'} form={form} />
                        </Col>
                        <Col hidden={isProd} span={24} className={'mb-2'}>
                            <span className={'text-primary mr-3'}>Item Refs.</span>
                            <Button hidden={isProd} size={'small'} className={'mr-3'} icon={<i className={'fa fa-plus mr-2'} />} onClick={() => onCreate("itemDef")}>ItemDef</Button>
                        </Col>
                        <Col span={24}>
                            <Item hidden={isProd} name={'itemOID'}>
                                <Select showSearch onSelect={itemAppend}
                                        filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                    {
                                        (filteredItemDefs !== null ? filteredItemDefs : itemDefs).map((item, index) => (
                                            <Select.Option key={index} value={item.oid}>
                                                {`[${item.oid}] ${item.name}`}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Item>
                        </Col>
                        <Col span={4} className={'mb-3'}>
                            <Tag className={'font-size-14'} icon={<><i className='fa fa-exclamation-circle font-size-14'/> Total Count : </>}>{form.getFieldValue("itemRef")?.length||'0'}</Tag>
                        </Col>
                        <Col span={20} className={'text-right mb-3'}>
                            {
                                isSdtmct &&
                                <Dropdown size={'small'} menu={libraryMenu} trigger={'click'}>
                                    <Button hidden={isProd} icon={<i className='fa fa-plus mr-2' />}>
                                        Controlled Terminology <DownOutlined />
                                    </Button>
                                </Dropdown>
                            }
                        </Col>
                        { //ItemContainer
                            (defVisible && defType === 'itemDef') &&
                            <ItemContainer defVisible={defVisible} setDefVisible={setDefVisible}
                                           defItem={defItem} setDefItem={setDefItem}
                                           defOption={defOption} setDefOption={setDefOption}/>
                        }
                        { //Controlled Terminology
                            (defVisible && defType === 'Laboratory' || defType === 'VitalSign') &&
                            <ControlledTerminology defVisible={defVisible} setDefVisible={setDefVisible}
                                                   defType={defType} setDefType={setDefType}
                                                   defOption={defOption} setDefOption={setDefOption} />
                        }
                        <Col span={24}>
                            <DragDropContext onDragEnd={onDragEnd}>
                            <Table>
                                <thead>
                                <tr>
                                    <th className={'text-center'} style={{width:'50px'}}>Sort</th>
                                    <th className={'text-center'} style={{width: '50px'}}>No.</th>
                                    <th>Item</th>
                                    <th className={'text-center'} style={{width:'150px'}}>Data Type</th>
                                    <th className={'text-center'} style={{width:'250px'}}>Skip Condition</th>
                                    {/*<th className={'text-center'} style={{width:'100px'}}>Mandatory</th>*/}
                                    {/*<th className={'text-center'} style={{width:'200px'}}>UnitsItemOID</th>*/}
                                    <th className={'text-center'} style={{width:'50px'}}>#</th>
                                </tr>
                                </thead>
                                <Droppable droppableId={'itemRef'}>
                                    {
                                        (provided, snapshot) => (
                                            <tbody key={'tbody'}
                                                   ref={provided.innerRef}
                                                   {...provided.droppableProps}
                                                   isDraggingOver={snapshot.isDraggingOver}>
                                            <List name={'itemRef'}
                                                  rules={[
                                                      {
                                                          validator: async (_, names) => {
                                                              if(names == null) {
                                                                  return Promise.reject(new Error(t('message.there.is.no.item.reference.entry')));
                                                              }
                                                              else if(names.length <= 0) {
                                                                  return Promise.reject(new Error(t('message.warning.required-entry')));
                                                              } else {
                                                                  return Promise.resolve();
                                                              }
                                                          }
                                                      }
                                                  ]}>
                                                {
                                                    (fields, { add, remove }, {errors}) => (
                                                        fields.length > 0 ? (
                                                            fields.map((field, index) => (
                                                                <Draggable key={index} className={'code-list-table'}
                                                                           draggableId={`${index}`}
                                                                           index={index} isDragDisabled={isProd} >
                                                                    {
                                                                        (dragProvided, dragSnapshot) => (
                                                                            <>
                                                                                {
                                                                                    metaData.item.get(form.getFieldValue(['itemRef', index])?.itemOID) != null &&
                                                                                    <tr key={index}
                                                                                        ref={dragProvided.innerRef}
                                                                                        {...dragProvided.draggableProps}
                                                                                        isDragging={dragSnapshot.isDragging}>
                                                                                        <td className='text-center bg-white' style={{width:'50px', verticalAlign:'middle'}}
                                                                                            {...dragProvided.dragHandleProps}>
                                                                                            <MenuOutlined/>
                                                                                        </td>
                                                                                        <td className={'text-center bg-white'} style={{width:'50px', verticalAlign:'middle'}}>
                                                                                            <Tag color={'success'} className={'mr-0'}>{index+1}</Tag>
                                                                                        </td>
                                                                                        <td className={'bg-white'} style={{width:'310px', verticalAlign:'middle'}}>
                                                                                            <div>
                                                                                                <Tag color={'geekblue'}
                                                                                                     className={`mr-0 ${style.tagBtn}`}
                                                                                                     style={{cursor: 'pointer'}}
                                                                                                     icon={<i className='fa fa-search-plus mr-1'/>}
                                                                                                     onClick={() => onGetData("itemDef", metaData.item.get(form.getFieldValue(['itemRef', index])?.itemOID))}>
                                                                                                    {form.getFieldValue(['itemRef', index])?.itemOID}
                                                                                                </Tag>
                                                                                            </div>
                                                                                            {metaData.item.get(form.getFieldValue(['itemRef', index]).itemOID)?.name}
                                                                                        </td>
                                                                                        <td className={'text-center bg-white'} style={{width:'300px', verticalAlign:'middle'}}>
                                                                                            <Tag color={'processing'} className={'mr-0'}>{getDataType(index)}</Tag>
                                                                                        </td>
                                                                                        <td className={'text-center bg-white'} style={{width:'300px', verticalAlign:'middle'}}>
                                                                                            <Form.Item name={[index, 'collectionExceptionConditionOID']}
                                                                                                       required={false}>
                                                                                                {
                                                                                                    isProd?(
                                                                                                        <Tag className={'p-2'}>
                                                                                                        <span className={"font-size-16 font-weight-bold"}>
                                                                                                            {form.getFieldValue(['itemRef', index])?.collectionExceptionConditionOID ? metaData.editCheck.get(form.getFieldValue(['itemRef', index]).collectionExceptionConditionOID).name : 'N/A'}
                                                                                                        </span>
                                                                                                        </Tag>
                                                                                                    ):(
                                                                                                        <Select allowClear={true} options={skipConditions} />
                                                                                                    )
                                                                                                }
                                                                                            </Form.Item>
                                                                                        </td>
                                                                                        <td className={'text-center bg-white'} style={{width:'50px', verticalAlign:'middle'}}>
                                                                                            <Button hidden={isProd} size={'small'} onClick={() => itemRemove(index)}>
                                                                                                <i className={'fa fa-trash-o'} />
                                                                                            </Button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </Draggable>
                                                            ))
                                                        ) : (
                                                            <>
                                                                <tr>
                                                                    <td className='text-center' colSpan={6}>
                                                                        <NoDataBlock />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text-center' colSpan={6}>
                                                                        <Form.ErrorList errors={errors} />
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    )
                                                }
                                            </List>
                                            {provided.placeholder}
                                            </tbody>
                                        )
                                    }
                                </Droppable>
                            </Table>
                            </DragDropContext>
                        </Col>
                    </Row>
                </Card>
                <Card title={'Description'} type={'inner'}>
                    <DescriptionOrQuestion languages={languages} name={'description'} form={form}/>
                </Card>
            </Form>
        </Modal>
    );

}

export default ValueListDef;
