import {useNavigate, useParams} from "react-router-dom";
import React, {createRef, useEffect, useRef, useState} from "react";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {Button, Col, message, Row, Tooltip} from "antd";
import DefaultLoading from "../../DefaultLoading";
import {CardBody, CardFooter, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {Form, Table} from "reactstrap";
import style from "../sponsor/style.module.scss";
import {InfoCircleOutlined, RightOutlined, SaveOutlined, SyncOutlined} from "@ant-design/icons";
import _ from "lodash";
import {usePrompt} from "../../../../lib/Blocker";
import {useTranslation} from "react-i18next";

const StudyPrecondition = () => {

    const params = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [actionTypes, setActionTypes] = useState(null);
    const [preconditionTypes, setPreconditionTypes] = useState(null);
    const [savedPreconditions, setSavedPreconditions] = useState("init");
    const [loading, setLoading] = useState(false);
    const [change, setChange] = useState(false);

    //변경 감지 후 확인 창 띄우기
    usePrompt(change, t);

    const preconditionRef = useRef([]);

    const disabledCheck = (actionType, precondition) => {
        switch(actionType) {
            case 'DATA_REVIEW':
                return (precondition === 'SIGNATURE') || (precondition === 'DATA_REVIEW') || (precondition === 'FREEZING')
            case 'PARTIAL_FREEZING':
                return (precondition === 'SIGNATURE') || (precondition === 'DATA_REVIEW') || (precondition === 'FREEZING')
            case 'FORM_FREEZING':
                return (precondition === 'DATA_REVIEW') || (precondition === 'FREEZING')
            case 'PARTIAL_LOCKING':
                return (precondition === 'SIGNATURE') || (precondition === 'FREEZING')
        }
        return false;
    }

    useEffect( () => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/precondition`);

                if (status === httpStatus.ok) {
                    //set Data
                    setPreconditionTypes(data?.preconditionTypes);
                    setActionTypes(data?.actionTypes);
                    setSavedPreconditions(data?.savedPreconditions);
                }
            } catch (e) {
                errorHandle(e, navigate, `/studies/${params.sid}/study-info`);
            }
        })()
    }, [])


    //Ref 정보를 배열형태로 분산하여 지정함.
    useEffect(() => {
        if (actionTypes !== null && preconditionTypes !== null) {
            if (preconditionRef.current.length !== (actionTypes.length * preconditionTypes.length)) {
                // Add or Remove refs
                preconditionRef.current = Array(actionTypes.length * preconditionTypes.length).fill().map((_, i) => preconditionRef.current[i] || createRef());
            }
        }
    }, [actionTypes, preconditionTypes]);

    const onChangedCheck = () => {
        //변경 여부 확인.
        const precondition = preconditionRef.current.filter(rp => rp.current?.checked === true).map(rp => rp.current.defaultValue);
        setChange(!_.isEqual(savedPreconditions.sort(), precondition.sort()));
    };

    const onSync = async () => {
        setLoading(true);
        const originSavedPreconditions = _.cloneDeep(savedPreconditions);
        try{
            setSavedPreconditions('init');
            const {status, data} = await axios.post(`/api/builder/studies/${params.sid}/precondition/sync`);

            if (status === httpStatus.ok) {
                //Sponsor Data Sync
                setPreconditionTypes(data?.preconditionTypes);
                setActionTypes(data?.actionTypes);
                setSavedPreconditions(data?.savedPreconditions);
                message.success(data?.message);
            }
        } catch (e) {
            errorHandle(e);
            setSavedPreconditions(originSavedPreconditions);
        }
        setLoading(false);
    }

    const onSubmit = async (e) => {
        e.preventDefault(); // 기존 Submit Request 이벤트 제거.
        setLoading(true);

        try {
            //rolePrivileges의 ref값을 가져와서 checked된 값만 세팅함.
            const body = {
                oldPrecondition: savedPreconditions,
                precondition: preconditionRef.current.filter(rp => rp.current.checked === true).map(rp => rp.current.defaultValue),
            };

            const {status, data} = await axios({
                url: `/api/builder/studies/${params.sid}/precondition`,
                data: body,
                method: 'post'
            })

            if (status === httpStatus.ok) {
                //message
                message.success(data?.message);
                setSavedPreconditions(data?.savedPreconditions);
                setChange(false);
            }
        } catch (e) {
            errorHandle(e);
        }
        setLoading(false);
    }

    return (
        <>
            {
                (actionTypes === null || preconditionTypes === null || savedPreconditions === 'init') &&
                <DefaultLoading/>
            }
            {
                (actionTypes !== null && preconditionTypes !== null && savedPreconditions !== "init") &&
                <DefaultCard>
                    <CardHeader>
                        <Row>
                            <Col span={18}>
                                <h3><RightOutlined className={'mr-2'}/>Precondition</h3>
                            </Col>
                            <Col span={6} className={'text-right'}>
                                {
                                    (savedPreconditions instanceof Array && savedPreconditions?.length <= 0) &&
                                    <Button type={'default'} loading={loading} icon={<SyncOutlined/>} onClick={onSync}>
                                        Sync
                                        <Tooltip title={
                                            <>
                                                <div>{t('studies.retrieve.precondition.info1')}</div>
                                                <div>{t('studies.retrieve.precondition.info2')}</div>
                                            </>
                                        }>
                                            <InfoCircleOutlined/>
                                        </Tooltip>
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </CardHeader>
                    <Form onSubmit={onSubmit}>
                        <CardBody>
                            <Table bordered striped hover>
                                <thead>
                                <tr>
                                    <th className={`${style.backslash} stop-dragging`}>
                                        <div>Precondition</div>
                                        Action
                                    </th>
                                    {
                                        preconditionTypes.map((precondition, preconditionIdx) => (
                                            <th key={preconditionIdx} className='stop-dragging width-150'
                                                style={{textAlign: 'center', verticalAlign: 'middle'}} scope={'row'}>
                                                {precondition.displayName}
                                            </th>
                                        ))
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    actionTypes.map((actionType, actionTypeIdx) => (
                                        <tr key={actionTypeIdx} className={'stop-dragging'}>
                                            <th style={{verticalAlign: 'middle'}}>
                                                {actionType?.displayName}
                                            </th>
                                            {
                                                preconditionTypes.map((precondition, preconditionIdx) => (
                                                    <td key={preconditionIdx} className='text-center'>
                                                        <input type={'checkbox'} className={style.actionCheckbox}
                                                               onClick={onChangedCheck}
                                                               ref={preconditionRef.current[(actionTypeIdx * preconditionTypes.length) + preconditionIdx]}
                                                               disabled={disabledCheck(actionType.key, precondition.key)}
                                                               value={`${precondition.key},${actionType.key}`}
                                                               defaultChecked={savedPreconditions != null && savedPreconditions.includes(`${precondition.key},${actionType.key}`)}
                                                        />
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </CardBody>
                        <CardFooter className='text-right'>
                            {
                                change && (
                                    <Button loading={loading} icon={<SaveOutlined/>} type={'primary'} htmlType={'submit'}>Save</Button>
                                )
                            }
                        </CardFooter>
                    </Form>
                </DefaultCard>
            }
        </>
    )

}

export default StudyPrecondition