//MetaData options
export const META_LOADING_SUCCESS = 'META_LOADING_SUCCESS';
export const META_LOADING_FAILED = 'META_LOADING_FAILED';
export const SAVE_SUCCESS = 'SAVE_SUCCESS';
export const SAVE_FAILED = 'SAVE_FAILED';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAILED = 'DELETE_FAILED';

export const SYNC_SUCCESS = 'SYNC_SUCCESS';
export const SYNC_FAILED = 'SYNC_FAILED';

//User options
export const SIGNIN = 'SIGNIN';
export const SIGNOUT = 'SIGNOUT';
export const DUPLICATED_SIGNOUT = 'DUPLICATED_SIGNOUT';

export const PATH_PUSHED = 'PATH_PUSHED';
export const PATH_REPLACED = 'PATH_REPLACED';

export const LOAD_SUCCESS = 'LOAD_SUCCESS';
export const LOAD_FAILED = 'LOAD_FAILED';

export const REGPAGE_FETCH_SUCCESS = 'REGPAGE_FETCH_SUCCESS';
export const REGPAGE_FETCH_FAILED = 'REGPAGE_FETCH_FAILED';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';

export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILED = 'PROFILE_FAILED';
