import React, {useEffect, useState} from "react";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle, onSearch} from "../../../../lib/BuilderUtils";
import {Link, redirect, useNavigate, useParams} from "react-router-dom";
import {CardBody, CardFooter, CardHeader, PrimaryCard} from "../../../../pages/PageStyleBox";
import {Button,  Input,  message, Table} from "antd";
import {DeleteOutlined, EditOutlined, RightOutlined, CheckOutlined} from "@ant-design/icons";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";

const EmailNoticeList = () => {

    const navigate = useNavigate();
    const params = useParams();
    const [baseData, setBaseData] = useState(null);
    const [filterData, setFilterData] = useState(null);
    const {t} = useTranslation();

    const columns = [
        {
            title: 'No',
            dataIndex: 'id',
            key: 'id',
            align: "center",
            sorter: {compare: (a, b) => a.id>b.id?1:-1},
            width: '5%',
            render: (row, record, index) => {
                return (filterData !== null?filterData:baseData).length-(filterData !== null?filterData:baseData).indexOf(record);
            }
        },
        {
            title: 'title',
            dataIndex: 'title',
            key: 'title',
            width: '70%',
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            align: "center",
            width: '12%',
            sorter: {compare: (a, b) => new Date(a.createdDate)>new Date(b.createdDate)?1:-1},
        },
        {
            title: 'SAE Report',
            dataIndex: 'reportSAE',
            key: 'reportSAE',
            align: "center",
            width: '12%',
            render: (row, record) => {return (record.reportSAE ? <CheckOutlined /> : "")}
        },
        {
            title: 'Actions',
            align: "center",
            dataIndex: 'id',
            key: 'actions',
            width: '230px',
            render: (row, record, index) => {
                return (
                    <>
                        <Button className={'mr-3'}>
                            <Link to={`/studies/${params.sid}/email-notice/edit?id=${row}`}>
                                <EditOutlined className={'mr-2'}/>Edit
                            </Link>
                        </Button>
                        <Button icon={<DeleteOutlined />} onClick={() => onRemove(row)}>
                            Remove
                        </Button>
                        {/*{*/}
                        {/*    (studyRNPMap !== null && studyRNPMap[row] ) && (studyUserRoleMap !== null && studyUserRoleMap[row]) &&*/}
                        {/*    <Button icon={<DeleteOutlined />} onClick={() => onRemove(row)}>*/}
                        {/*        Remove*/}
                        {/*    </Button>*/}
                        {/*}*/}
                    </>
                )
            }
        }
    ]

    useEffect( () => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/email-notice`);
                if (status === httpStatus.ok) {
                    setBaseData(data.emailNoticeTemplateList);
                }
            } catch (e) {
                errorHandle(e, navigate);
            }
        })();

    }, []);

    const onRemove = async (id) => {
        try {
            const res = await Swal.fire({
                icon: 'question',
                iconColor: 'red',
                title: t('column.email.notice.template.delete'),
                confirmButtonColor: '#ec1818',
                confirmButtonText: t('btn.text.delete'),
                cancelButtonText: t('btn.text.cancel'),
                showCancelButton: true,
                html:t('studies.delete.this.template')
            });

            if(res.isConfirmed) {
                const {status, data} = await axios({
                    url:`/api/builder/studies/${params.sid}/email-notice/remove`,
                    method:'delete',
                    params:{id},
                })

                if(status === httpStatus.ok) {
                    setBaseData(data?.emailNoticeTemplateList??[]);
                    message.success(data.message);
                }
            }

        } catch (e) {
            // console.log(e.message);
            errorHandle(e);
        }
    }

    return (
        <PrimaryCard>
            <CardHeader>
                <div className={'row'}>
                    <div className={'col-8'}>
                        <h3><RightOutlined className={'mr-2'} />E-Mail Notice Template</h3>
                    </div>
                    <div className={'col-4'}>
                        <Input.Search
                            placeholder="Search by..."
                            onSearch={(value) => onSearch(baseData, setFilterData, value)}
                            enterButton
                        />
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                <Table columns={columns} // Columns Info
                       rowKey={row => row.id} // Row Key Value
                       dataSource={filterData === null ? baseData : filterData} // Data Source
                       pagination={{ pageSize: 25 }} // Pagination
                       loading={!baseData}  // Data Loading 처리
                       tableLayout={'fixed'}
                       bordered={true}
                />
            </CardBody>
            <CardFooter className='text-right'>
                <Button>
                    <Link to={`/studies/${params.sid}/email-notice/edit`} ><i className='fa fa-plus mr-2' /> Add a new template</Link>
                </Button>
            </CardFooter>
        </PrimaryCard>
    )
}

export default EmailNoticeList;
