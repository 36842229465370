import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {usePrompt} from "../../../../../lib/Blocker";
import useLibraryStore from "../../../../../store/libraryStore";
import {Button, Checkbox, Col, Drawer, Input, Layout, message, Row, Select, Tooltip} from "antd";
import {DragDropContext} from "react-beautiful-dnd";
import {useMutation} from "react-query";
import {saveDefWithAxios, saveFormRefWithAxios} from "../../../../../lib/api/library";
import {errorHandle, sweetAlert} from "../../../../../lib/BuilderUtils";
import {CardBody, CardFooter, CardHeader, NormalCard, PrimaryCard} from "../../../../../pages/PageStyleBox";
import {Paper} from "@material-ui/core";
import style from "../../../../../container/build-tool/container/style.module.scss";
import {ContentDiv, Notice} from "../../../../../container/build-tool/StyleBox";
import LibraryDefinitionSubmit from "../build-form/LibraryDefinitionSubmit";
import NormalLoading from "../../../NormalLoading";
import LibraryToolbar from "../build-form/LibraryToolbar";
import {FormThumb} from "../../../../../container/build-tool/content/FormComponents";
import {toLibraryMap} from "../../../../../lib/LibraryUtils";
import _ from "lodash";
import LibraryFormContainer from "./LibraryFormContainer";
import LibraryItemGroupContainer from "./LibraryItemGroupContainer";
import LibraryItemContainer from "./LibraryItemContainer";
import LibraryCodeListContainer from "./LibraryCodeListContainer";
import LibraryValueListContainer from "./LibraryValueListContainer";
import LibraryItemGroup from "../content/LibraryItemGroup";
import {
    copyItem,
    copyItemGroup,
    move,
    reorderItem,
    reorderItemGroup
} from "../../../../../container/build-tool/Methods";
import {useTranslation} from "react-i18next";


const ExtraFormList = ({library, filteredForms, onSearch, extraFormOpen, setExtraFormOpen, onCreateForm}) => {
    const {form, itemGroup, id} = toLibraryMap(library);

    return (
        <React.Fragment key={'FormThumbList'}>
            <Drawer
                title={
                    <Row>
                        <Col span={'4'}>Forms</Col>
                        <Col offset={'2'} span={'18'}><Input.Search onSearch={onSearch}/></Col>
                    </Row>
                }
                closable={false}
                placement={'left'}
                onClose={() => setExtraFormOpen(false)}
                open={extraFormOpen}
                style={{position: 'absolute'}}
                footer={
                    <Notice>
                        <Button style={{width:"100%"}} type={'ghost'} onClick={() => onCreateForm()}>
                            <i className={'fa fa-plus mr-2'}></i>Form
                        </Button>
                    </Notice>
                }
            >
                <div>
                    {
                        (filteredForms!==null?filteredForms:Array.from(form.values())).map(f => (
                            <FormThumb key={`form-thumb-${f.oid}`} formDef={f} itemGroups={itemGroup} toUrl={`/global-library/v/${id}/forms/${f.oid}`} />
                        ))
                    }
                </div>
            </Drawer>
            <div className={`pull-left ${style.stickyDiv}`}>
                <Button type={"default"} onClick={() => setExtraFormOpen(true)}>
                    <i className={'fa fa-files-o'}></i>
                </Button>
            </div>
        </React.Fragment>
    )
}

const DefinitionContainers = ({defOpen, setDefOpen, defType, defItem, setDefItem, defOption, setDefOption, disabledRepeatingType}) => {

    return (
        <>
            { //FormContainer
                (defOpen && defType === 'form') &&
                <LibraryFormContainer defOpen={defOpen} setDefOpen={setDefOpen} defItem={defItem} setDefItem={setDefItem}/>
            }
            { //ItemGroupContainer
                (defOpen && defType === 'itemGroup') &&
                <LibraryItemGroupContainer defOpen={defOpen} setDefOpen={setDefOpen}
                                           defItem={defItem} setDefItem={setDefItem}
                                           defOption={defOption} setDefOption={setDefOption}
                                           disabledRepeatingType={disabledRepeatingType} />
            }
            { //ItemContainer
                (defOpen && defType === 'item') &&
                <LibraryItemContainer defOpen={defOpen} setDefOpen={setDefOpen}
                                      defItem={defItem} setDefItem={setDefItem}
                                      defOption={defOption} setDefOption={setDefOption}/>
            }
            { //CodeListContainer
                (defOpen && (defType === 'codeListItem' || defType === 'externalCodeList')) &&
                <LibraryCodeListContainer defOpen={defOpen} setDefOpen={setDefOpen}
                                          defType={defType} defItem={defItem} setDefItem={setDefItem}/>
            }
            { //ValueList
                (defOpen && defType === 'valueList') &&
                <LibraryValueListContainer defOpen={defOpen} setDefOpen={setDefOpen}
                                           defItem={defItem} setDefItem={setDefItem}/>
            }
        </>
    )
}

const LibraryBuildFormContainer = () => {

    //Router Feature
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const {t} = useTranslation();

    const propName = 'formItemGroups';
    const [formDef, setFormDef] = useState(null); //현재 Form에 대한 정보
    const [itemRefMap, setItemRefMap] = useState(null); // key[itemGroupOID: itemRefs]

    //Def Control 옵션
    const [defOpen, setDefOpen] = useState(false);
    const [defType, setDefType] = useState(null);
    const [defItem, setDefItem] = useState(null);
    const [defOption, setDefOption] = useState(null);
    const [disabledRepeatingType, setDisabledRepeatingType] = useState(null);

    //Page Refresh 대책
    const [shouldConfirm, setShouldConfirm] = useState(false);
    //변경 감지 후 확인 창 표시.
    usePrompt(shouldConfirm, t);

    //컴포넌트에 따라 필요한 정보
    const [designMode, setDesignMode] = useState(true); //Annotated Option에 따라서 Form에 보여주는 정보를 달리할 때 쓰는 상태 정보
    const [selectedLanguage, setSelectedLanguage] = useState(null); //현재 Language값을 설정하는 상태 정보

    //Drag & Drop 동작 시, Drop 가능 여부에 대한 상태 정보.
    const [dropEnable, setDropEnable] = useState(null);

    //FormList 관련 상태값
    const [extraFormOpen, setExtraFormOpen] = useState(false);
    const [filteredForms, setFilteredForms] = useState(null);

    //Zustand Variables
    const {library, languages, updateLibrary} = useLibraryStore(state => state);
    const [libraryMap, setLibraryMap] = useState(toLibraryMap(library));

    //re-rendering이 일어나지 않으면서 pathname만 변경 되었을 때, 동작 처리
    useEffect(() => {
        setExtraFormOpen(false);
        setShouldConfirm(false);
        // disablePrevent();
        dataInit();
    }, [location.pathname])

    useEffect(() => {
        if(library !== null && library !== undefined) {
            setLibraryMap(toLibraryMap(library));
            if(formDef === null && itemRefMap === null) {
                dataInit();
            } else {
                const {oid} = params;
                const form = _.cloneDeep(libraryMap.form.get(oid));
                const itemGroupRef = _.cloneDeep(formDef.itemGroupRef);
                const cloneItemRefMap = _.cloneDeep(itemRefMap);

                //Data Option에 따른 Item / ItemGroup Append 설정
                if(defOption?.done) {
                    if(defOption?.option === 'itemGroupDef' && defOption?.itemGroupOID != null) {

                        //현재 itemGroupRef의 가장 하단에 itemGroup 추가.
                        itemGroupRef.splice(itemGroupRef?.length, 0, {
                            itemGroupOID: defOption.itemGroupOID, //required
                            orderNumber: itemGroupRef?.length,
                            mandatory: "NO", //required
                            collectionExceptionConditionOID: null,
                        });

                        //itemRefMap Setting
                        cloneItemRefMap.set(defOption.itemGroupOID, libraryMap.itemGroup.get(defOption.itemGroupOID)?.itemRef??[]);
                    } else if(defOption?.option === 'itemDef' && defOption?.itemOID != null) {
                        const itemRefs = cloneItemRefMap.get(defOption?.itemGroupOID);
                        //ItemRef 초기값을 최하단에 설정.
                        itemRefs.splice(itemRefs?.length, 0, {
                            itemOID: defOption?.itemOID, //required
                            mandatory: "NO", //required
                            orderNumber: itemRefs?.length, //index value
                            keySequence: null,
                            methodOID: null,
                            UnitsItemOID: null,
                            role: null,
                            roleCodeListOID: null,
                            collectionExceptionConditionOID: null,
                        });
                    }
                    setDefOption(null); //Def Option에 따른 설정 종료 후 초기화.
                }

                //FormDef, ItemRefMap에 대한 Data설정
                setFormDef({...form, itemGroupRef: itemGroupRef});
                setItemRefMap(cloneItemRefMap);
            }
        }
    }, [library]);

    useEffect(() => {
        //ItemGroup 생성/수정 화면을 띄웠을 경우,
        if(defOpen && defType === 'itemGroup') {
            //독립적 평가 폼 / 독립적 평가 Upload 폼이면,
            if(formDef.formType === 'INDEPENDENT_EVALUATION_FORM' ||
                formDef.formType === 'INDEPENDENT_EVALUATION_UPLOAD_FORM') {
                //SIMPLE, STATIC에 대한 설정을 비활성화 하게 처리
                setDisabledRepeatingType(['SIMPLE', 'STATIC'])
            }
            //disabledRepeatingType의 설정 정보가 있으면,
            else if(disabledRepeatingType != null) {
                //null로 설정
                setDisabledRepeatingType(null);
            }
        }
    }, [defOpen, defType]);

    /**
     * 현재 Library에 저장된 값으로 해당 Form 내용을 초기화 합니다.
     */
    const dataInit = () => {
        //URL에 등록된 Form OID 정보를 가지고 formDef Setting
        const form = _.cloneDeep(libraryMap.form.get(params.oid));
        setFormDef(form);

        const itemRefMap = new Map();
        form.itemGroupRef.map(ig => itemRefMap.set(ig.itemGroupOID, _.cloneDeep(libraryMap.itemGroup.get(ig.itemGroupOID).itemRef)));
        setItemRefMap(itemRefMap);
    }

    //Use Mutation
    //UseMutation 사용
    const {mutate, isLoading, isError, error, isSuccess} = useMutation(saveFormRefWithAxios, {
        onSuccess: ({library, resultMessage}) => {
            console.log(`FormDef Reference Save : `, library);
            message.success(resultMessage);
            updateLibrary(library);
        },
        onError: (error) => {
            errorHandle(error);
        },
        onMutate: (variables) => {
            /**
             * Mutation 함수가 실행되기 전, 실행되고 mutation 함수가 받을 동일한 변수가 전달,
             */
        },
        onSettled: (data, error, variables, context) => {
            /**
             * mutation이 성공하여 성공한 데이터 또는 error가 전달될 때 실행,
             * 성공하거나 실패하거나 상관없이 결과가 무조건 전달
             */
        }
    });

    const toggleDesignMode = (e) => {
        //Annotated가 선택된 값에 따라서 true, false 전환.
        setDesignMode(e.target.checked);
    }

    const selectLanguage = (e) => {
        setSelectedLanguage(e); //현재 Select된 값을 selectedLanguage에 저장함.
    }

    /**
     * FormBuild 화면 초기화
     */
    const formBuildReset = () => {
        dataInit()
        if(shouldConfirm) {
            setShouldConfirm(false);
            // disablePrevent();
        }
    }

    /**
     * FormBuild 저장
     */
    const formBuildSubmit = () => {
        //EXCEPTION CASE. No Reference 예외 처리.
        if(formDef?.itemGroupRef == null || formDef.itemGroupRef.length === 0) {
            sweetAlert("Form Reference Empty.", 0, 'error',
                <h5>{t('container.reference.info.form.empty')}</h5>);
            return;
        } else if(Array.from(itemRefMap.values()).some(itemRef => (itemRef.length === 0))) {
            sweetAlert("ItemGroup Reference Empty.", 0, 'error',
                <div>
                    <h5>{t('container.exist.item.group.empty.reference.info1')}</h5>
                    <h5>{t('container.exist.item.group.empty.reference.info2')}</h5>
                </div>);
            return;
        } else if(formDef.formType === 'LABORATORY_TEST_FORM') {
            /**
             * 실험실 검사 폼 인경우 필수 항목 체크
             */
            const labRequiredItemOIDs = new Map();
            labRequiredItemOIDs.set('LBDAT', t('lab.required.item.oid.lbdat'));
            labRequiredItemOIDs.set('LBTESTCD', t('lab.required.item.oid.lbtestcd'));
            labRequiredItemOIDs.set('LBORRES', t('lab.required.item.oid.lborres'));
            // if(metaDataStore.studyInfo.labType.key === 'CENTRAL_CS') {
            //     labRequiredItemOIDs.set('LBNRIND', '[LBNRIND] 정상여부');
            //     labRequiredItemOIDs.set('LBCLSIG', '[LBCLSIG] 임상적 유의성');
            // }
            // else if(metaDataStore.studyInfo.labType.key === 'LOCAL') {
            //     labRequiredItemOIDs.set('LBNRIND', '[LBNRIND] 정상여부');
            // }

            Array.from(itemRefMap.values()).forEach(itemRef => itemRef.forEach(item => labRequiredItemOIDs.delete(item.itemOID)));

            if(labRequiredItemOIDs.size > 0) {
                const itemStr = [...labRequiredItemOIDs.values()].join();
                sweetAlert(t('message.missing.lab.item'), 0, 'error',
                    <div>
                        <h6 className={'text-danger'}>{itemStr}</h6>
                        <h5>{t('message.item.missing')}</h5>
                    </div>);
                return;
            }
        }

        /* FormDef 정보 설정 및 저장할 정보 설정 */
        const result = {defList: [formDef]};
        //ItemGroupDefs 정보 및 Reference 정보 추가
        Array.from(itemRefMap.keys()).forEach((itemGroupOID, index) => {
            const itemGroupDef = libraryMap.itemGroup.get(itemGroupOID); //itemGroupOID으로 itemGroupDef 정보 받아오기.

            //itemGroup의 itemRef 값이 변경되었을 경우, 저장목록에 올림.
            if(!_.isEqual(itemGroupDef.itemRef, itemRefMap.get(itemGroupOID))) {
                itemGroupDef.itemRef = itemRefMap.get(itemGroupOID); //ItemGroup의 itemRef 정보 Setting
                result.defList.push(itemGroupDef);
            }
        });

        const {id} = library;
        result.id = id;
        result.attr = propName;

        mutate(result);

        setShouldConfirm(false);
    }

    /**
     * FormBuild 화면 취소 및 FormList로 돌아가기.
     */
    const formBuildCancel = () => {
        //Preview 화면 닫기
        navigate("../../forms");
    }

    /**
     * Drag 하는 도중에 발생하는 이벤트
     */
    const onDragUpdate = (update) => {
        //Update 진행 시, 현재 ItemGroup 형태 별로, Width / Height 제어
        const {source, destination, draggableId, type} = update;
        const itemGroups = Array.from(formDef.itemGroupRef);

        // Drop한 영역이 없는 경우 아무 처리가 없이 종료
        if (!destination) {
            //Destination이 null이 된 경우, enable값에 destination만 변경하여 설정함.
            setDropEnable({...dropEnable, destination,});
            return;
        }

        // Drag를 시작한 영역과 Drop한 영역이 완전하게 똑같으면 checked값이 무조건 true
        if (dropEnable !== null && destination.droppableId === source.droppableId) {
            setDropEnable({
                ...dropEnable,
                checked: true,
            });
            return;
        }

        //CASE 1. new Item's Add
        if (source.droppableId.includes("new") && type === 'item') {
            const items = itemRefMap.get(itemGroups.find(itemGroup => itemGroup.itemGroupOID === destination.droppableId).itemGroupOID);
            const checked = !items.some(item => item.itemOID === draggableId)//현재 draggableID와 일치하는 내용이 없으면,

            //현재 dropEnable에 대한 속성 정의
            setDropEnable({
                type: "copy",
                source,
                destination,
                draggableId,
                checked,
            });

            //CASE 2. ItemGroup's Item move
        } else if (draggableId != null && draggableId.includes("::")) {
            const dragItemOID = draggableId.split("::")[1]
            const destinationDroppableId = destination.droppableId;
            const sourceDroppableId = source.droppableId;

            if (sourceDroppableId !== destinationDroppableId) {

                //CASE 1. 옮기려는 ItemGroup에 현재 Item과 중복된 Item이 있는가?
                const items = itemRefMap.get(itemGroups.find(itemGroup => itemGroup.itemGroupOID === destinationDroppableId).itemGroupOID);;
                const isDuplicated = !items.some(item => item.itemOID === dragItemOID);

                //CASE 2. 이동하려는 Item이 ValueList를 참조하고있다면, Destination ItemGroupDef가 static 속성인가?
                const targetItemDef = libraryMap.item.get(dragItemOID);
                const destinationItemGroupDef = libraryMap.itemGroup.get(destinationDroppableId);
                const isMeaningValueList = targetItemDef?.valueListRef?.valueListOID == null?true:targetItemDef?.valueListRef?.valueListOID != null && destinationItemGroupDef?.repeating === 'STATIC';

                setDropEnable({
                    type: "move",
                    source,
                    destination,
                    draggableId,
                    checked:isDuplicated&&isMeaningValueList,
                })
            }
        }

    }

    /**
     * Drag 도중 Drop 했을 때 모션
     */
    const onDragEnd = (result) => {
        const {source, destination, draggableId, type} = result;

        //End 동작이 실행되면 dropEnable 초기화
        setDropEnable(null);

        //CASE Exception
        //No Destination - Droppable 위치가 없는 경우 바로 리턴
        if (!destination) { return; }
        // Process index total equals Exception처리
        if (destination.droppableId === source.droppableId && destination.index === source.index) { return; }

        if (source.droppableId.includes("new")) { //CASE 1. Toolbar Item => Form
            switch (type) {
                case 'itemGroup': copyItemGroup(libraryMap, formDef, setFormDef, itemRefMap, setItemRefMap, draggableId, destination); break;
                case 'item': copyItem(itemRefMap, setItemRefMap, draggableId, destination); break;
            }
        } else if(source.droppableId === destination.droppableId) { //CASE 2. 동일한 Parent 내에서의 이동
            switch (type) {
                case 'itemGroup':
                    reorderItemGroup(formDef, setFormDef, source.index, destination.index);
                    break;
                case 'item':
                    reorderItem(libraryMap, itemRefMap, setItemRefMap, source.droppableId, source.index, destination.index);
                    break;
            }
        } else {
            switch (type) {
                case 'item':
                    move(libraryMap, draggableId, itemRefMap, setItemRefMap, source, destination);
                    break;
            }
        }

        //Drag 동작 종료 후,
        if(!shouldConfirm) {
            // enablePrevent();
            setShouldConfirm(true);
        }
    }

    const onCreateForm = () => {
        setDefOpen(true);
        setDefType('form');
        setDefItem(null);
    }

    const onSearch = (value) => {
        const cloneForms = _.cloneDeep(Object.values(library?.formDef??[]));
        setFilteredForms(cloneForms.filter(s => s.name.toUpperCase().includes(value.toUpperCase())));
    }

    return (
        <PrimaryCard>
            <CardBody>
                {
                    (formDef !== null && itemRefMap !== null)? (
                        <DragDropContext
                            onDragUpdate={onDragUpdate} //OPTIONAL - Drag 진행 중 placeholder 정보 update 시 Event
                            onDragEnd={onDragEnd} //Required
                        >
                            <Row gutter={[20, 0]}>
                                <Col span={18}>
                                    <ExtraFormList library={library} filteredForms={filteredForms}
                                                   extraFormOpen={extraFormOpen} setExtraFormOpen={setExtraFormOpen}
                                                   onCreateForm={onCreateForm} onSearch={onSearch} />

                                    <DefinitionContainers defOpen={defOpen} setDefOpen={setDefOpen} defItem={defItem} setDefItem={setDefItem}
                                                          defType={defType} defOption={defOption} setDefOption={setDefOption}
                                                          disabledRepeatingType={disabledRepeatingType} />

                                    <Paper elevation={3} style={{marginTop: 0, marginBottom: 15}}>
                                        <NormalCard className={'mt-0'} style={{border: '1px solid #fff'}}>
                                            <Row className={style.stickyDiv}>
                                                <Col span={24}>
                                                    <CardHeader style={{border: '1px solid #fff', background: '#fff', zIndex: 999}}>
                                                        <Row className={'mt-3'}>
                                                            <Col span={12}>
                                                                <h3 className={"ml-2"} style={{fontSize: 18}}>
                                                                    {
                                                                        designMode ?
                                                                            (
                                                                                <>
                                                                                    <span className={'mr-2'}>{formDef.name}</span>
                                                                                    <span className="badge badge-primary">{formDef.oid}</span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {formDef.name}
                                                                                </>
                                                                            )
                                                                    }
                                                                </h3>
                                                            </Col>
                                                            <Col span={12} className={'text-right'}>
                                                                <Tooltip placement={'top'} title={'Language'}>
                                                                    <Select className={'mr-3'} defaultValue={null} onSelect={selectLanguage}>
                                                                        <Select.Option key={'0'}
                                                                                       value={null}> Default </Select.Option>
                                                                        {
                                                                            Object.keys(languages).map((language, index) => (
                                                                                <Select.Option key={`${language}${index}`}
                                                                                               value={language}>
                                                                                    {languages[language]}
                                                                                </Select.Option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Tooltip>
                                                                <Checkbox onClick={toggleDesignMode} defaultChecked={designMode}
                                                                          checked={designMode}>Design Mode</Checkbox>
                                                            </Col>
                                                        </Row>
                                                    </CardHeader>
                                                </Col>
                                            </Row>
                                            <CardBody>
                                                <ContentDiv>
                                                    <LibraryItemGroup library={libraryMap} designMode={designMode} selectedLanguage={selectedLanguage}
                                                                      formDef={formDef} setFormDef={setFormDef} itemRefMap={itemRefMap} setItemRefMap={setItemRefMap}
                                                                      setDefOpen={setDefOpen} setDefType={setDefType} setDefItem={setDefItem} setDefOption={setDefOption}
                                                                      dropEnable={dropEnable} />
                                                </ContentDiv>
                                            </CardBody>
                                            <CardFooter style={{position:'sticky', bottom:'0', border:'solid 0px'}}>
                                                <LibraryDefinitionSubmit onReset={formBuildReset} onCancel={formBuildCancel} onSubmit={formBuildSubmit} />
                                            </CardFooter>
                                        </NormalCard>
                                    </Paper>
                                </Col>
                                <Col span={6} style={{border: '1px solid rgba(0,0,0,0.1'}}>
                                    <div className={`mt-3 ${style.stickyDiv}`}>
                                        <LibraryToolbar Form={formDef} itemRefMap={itemRefMap} dropEnable={dropEnable} setDefOpen={setDefOpen} setDefItem={setDefItem} setDefType={setDefType} />
                                    </div>
                                </Col>
                            </Row>
                        </DragDropContext>
                    ):(
                        <NormalLoading />
                    )
                }
            </CardBody>
        </PrimaryCard>
    )
}

export default LibraryBuildFormContainer;