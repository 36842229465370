import {Button, Form, Input, Mentions, Modal, Select, Space, Spin, Switch, Typography} from 'antd';
import AceEditor from "react-ace";

import React, {useEffect, useState} from "react";
import {isEmpty} from "../../lib/StringUtils";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/snippets/javascript";
import langTools from "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/ext-searchbox";
import axios from 'axios';
import EditCheckSource from "./EditCheckSource";
import {useSelector} from "react-redux";
import {LoadingOutlined} from '@ant-design/icons';
import EditCheckGuide from "./EditCheckGuide";
import {useTranslation} from "react-i18next";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
// import {DownOutlined, UserOutlined} from "@ant-design/icons";
// import { mdiFunction } from '@mdi/js';
// import Icon from '@mdi/react';
const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
const { Title } = Typography;
const functions = [
    {value:'VISITNUM',caption:'VISITNUM - Visit Number',meta:'Global Variable'},
    {value:'prev',caption:'caption.study.event.oid.access.previous.visit',meta:'Global Variable'},
    {value:'FORM',caption:'FORM - Form OID',meta:'Global Variable'},
    {value:'SITEID',caption:'SITEID - Site ID',meta:'Global Variable'},
    {value:'IRBDATE',caption:'IRBDATE - IRB Approval Date',meta:'Global Variable'},
    {value:'max',caption:'caption.maximum.value',meta:'function'},
    {value:'min',caption:'caption.minimum.value',meta:'function'},
    {value:'first',caption:'caption.first.value',meta:'function'},
    {value:'last',caption:'caption.last.value',meta:'function'},
    {value:'prev',caption:'caption.previous.row',meta:'function'},
    {value:'next',caption:'caption.next.row',meta:'function'},
    {value:'age',caption:'caption.age.in.years',desc:'function'},
    {value:'ageMonth',caption:'caption.age.in.months',desc:'function'},
    {value:'datediff',caption:'caption.datediff',meta:'function'},
    {value:'round',caption:'caption.round.specified.number.digits',meta:'function'},
    {value:'ceil',caption:'caption.round.up.specified.number.digits',meta:'function'},
    {value:'floor',caption:'caption.round.down.specified.number.digits',meta:'function'},
    {value:'countif',caption:'caption.count.occurrences.where.value.matches',meta:'function'},
    {value:'toDatetime',caption:'caption.convert.date.and.time.info.datetime',meta:'function'},
    {value:'toMinute',caption:'caption.convert.time.string.to.datetime.and.return.minutes',meta:'function'},
    {value:'isSaved',caption:'caption.return.whether.visit.form.is.saved',meta:'function'},
    {value:'substr',caption:'caption.return.substring.from.specified.index',meta:'function'},
    {value:'parseInt',caption:'caption.parse.string.to.integer',meta:'function'},
    {value:'isInteger',caption:'caption.check.if.value.an.integer',meta:'function'},
    {value:'dateToTimeMils',caption:'caption.convert.date.to.mill.second.time',meta:'function'},
    {value:'timeMilsToDate',caption:'caption.convert.mill.second.to.date',meta:'function'},
    {value:'timeMilsToStrDate',caption:'caption.convert.mill.second.to.date.format',meta:'function'},
    {value:'dayToTimeMils',caption:'caption.convert.day.to.mill.second.time',meta:'function'},
    {value:'hourToTimeMils',caption:'caption.convert.hour.to.mill.second.time',meta:'function'},
    {value:'minuteToTimeMils',caption:'caption.convert.minute.to.mill.second.time',meta:'function'},
    {value:'timeMilsToDay',caption:'caption.convert.mill.second.to.day.time',meta:'function'},
    {value:'timeMilsToHour',caption:'caption.convert.mill.second.to.hour.time',meta:'function'},
    {value:'timeMilsToMinute',caption:'caption.convert.mill.second.to.minute.time',meta:'function'},
    {value:'addDays',caption:'caption.add.specified.number.of.days.to.date',meta:'function'},
    {value:'sum',caption:'caption.return.sum.of.values.in.specified.item.for.all.rows',meta:'function'},
    {value:'pow',caption:'caption.return.number.raised.to.power.of.pow',meta:'function'},
    {value:'strDate',caption:'caption.return.date.string.in.format',meta:'function'},
    {value:'fmt:formatNumber', caption:'caption.number.format.return.formatted.number', meta:'function'},
    {value:'fmt:currentDate', caption:'caption.current.date.format.return.current.date.in.format', meta:'function'},
    {value:'fmt:currentDate', caption:'caption.current.date.format.return.current.date.plus.specified.hours', meta:'function'},
    {value:'fmt:currentDateTime', caption:'caption.current.date.time.format.return.current.date.time', meta:'function'},
    {value:'fmt:currentDateTime', caption:'caption.current.date.time.format.return.current.date.time.plus.specified.hours', meta:'function'},
    {value:'fmt:currentDateTime', caption:'caption.current.date.time.format.return.current.date.time.specified.pattern', meta:'function'},
    {value:'fmt:currentDateTime', caption:'caption.current.date.time.format.return.current.date.time.specified.pattern.plus.specified.hours', meta:'function'},
];

const targetUnusableFunctions = new Map();
targetUnusableFunctions.set('MAX', 'MAX');
targetUnusableFunctions.set('MIN', 'MIN');
targetUnusableFunctions.set('FIRST', 'FIRST');
targetUnusableFunctions.set('LAST', 'LAST');
targetUnusableFunctions.set('PREV', 'PREV');
targetUnusableFunctions.set('NEXT', 'NEXT');
targetUnusableFunctions.set('COUNTIF', 'COUNTIF');
targetUnusableFunctions.set('PREVISITDATE', 'PREVISITDATE');

const EditCheckDef = ({metaData, oid, isCopy = false, emailNoticeTemplates, onFinish, showModal, closeModal, isProd}) => {
    const [form] = Form.useForm();
    const initialValue = {
        useGlobalVariable: false,
        check:{
            formalExpression:{},
            source:[],
        },
        action:{target:{varOID:null}}
    };
    const [editCheckDef, setEditCheckDef] = useState(isEmpty(oid) ? initialValue : metaData.editCheck.get(oid));
    const [visitIfStatements, setVisitIfStatements] = useState(isEmpty(oid) ? [] : metaData.editCheck.get(oid)?.check?.visitIfStatements);

    const {t} = useTranslation();

    const [annotations, setAnnotations] = useState([]);
    const [markers, setMarkers] = useState([]);
    const itemDefs = [...metaData.item.values()];
    const itemGroupDefs = [...metaData.itemGroup.values()];
    const formDefs = [...metaData.form.values()];
    const studyEventDefs = [...metaData.studyEvent.values()];
    const subjectStatusDefs = [...metaData.subjectStatus.values()];
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validate, setValidate] = useState(false);
    const metaDataStore = useSelector((store) => store.metaDataStore);
    const pattern = /^([A-Za-z]+)([0-9]+)?(([_.])?([A-Za-z0-9_]+))*$/i;
    const [oids, setOids] = useState([]);
    const [actionType, setActionType] = useState(editCheckDef?.action?.type != null ? editCheckDef.action.type : null);

    const [expr, setExpr] = useState("");
    const [showParseBtn, setShowParseBtn] = useState(false);
    const [queryMessageOptions, setQueryMessageOptions] = useState([]);

    useEffect(() => {
        const mdv = metaDataStore.study.metaDataVersion[0];
        // console.log(v);
        const allOIDs = [];
        allOIDs.push(mdv.oid.toUpperCase());//mdv oid
        mdv.codeList.forEach(cl => {
            allOIDs.push(cl.oid.toUpperCase());//codelist oid
        });
        mdv.itemDef.forEach(i => {
            allOIDs.push(i.oid.toUpperCase());
        });
        mdv.itemGroupDef.forEach(g => {
            allOIDs.push(g.oid.toUpperCase());
        });
        mdv.formDef.forEach(f => {
            allOIDs.push(f.oid.toUpperCase());
        });
        mdv.studyEventDef.forEach(se => {
            allOIDs.push(se.oid.toUpperCase());
        });
        mdv.studyEventGroupDef.forEach(seg => {
            allOIDs.push(seg.oid.toUpperCase());
        });
        mdv.editCheckDef.forEach(ec => {
            allOIDs.push(ec.oid.toUpperCase());
        });
        mdv.subjectStatusDef.forEach(ec => {
            allOIDs.push(ec.oid.toUpperCase());
        });

        setOids(allOIDs);

        return () => {
            setOids([]);
        }
    }, [metaDataStore]);

    const contains = (val) => {
        // console.log(selectedOID);
        if(!isEmpty(oid) && isCopy == false) return true;
        const checkOid = val.toUpperCase();
        const returnValue = oids.includes(checkOid);
        // console.log(checkOid, returnValue);

        // if(!returnValue) {
        //     return checkOid + '(은)는 이미 사용중입니다.';
        // }
        return returnValue;
    }

    // setEditMode(false);

    useEffect(() => {
        form.resetFields();
        if(!isEmpty(oid) && !isCopy) {
            setLoading(true);
            setEditMode(false);
            const editCheck = metaData.editCheck.get(oid);
            setEditCheckDef(editCheck);
            setActionType(editCheck.action?.type);
        } else if(!isEmpty(oid) && isCopy) {
            setEditMode(true);
            const editCheck = metaData.editCheck.get(oid);
            setEditCheckDef({...editCheck});
            setActionType(editCheck.action?.type);
        } else {
            setEditMode(true);
            setEditCheckDef(initialValue);
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [oid]);

    useEffect(() => {
        form.setFieldsValue(editCheckDef);
        if(!isEmpty(oid) && isCopy) {
            form.validateFields(['oid']);
        }

        const res = [
            ...editCheckDef?.check?.source?.flatMap(({sourceItems}, i) => {
                return sourceItems.filter(item => isEmpty(item.itemOID) === false && isEmpty(item.func))
                    .flatMap((item, itemIndex) =>({
                        key: `${item.varOID}-${itemIndex}`,
                        value: `{${item.varOID}}`,
                        label: `${item.varOID}`
                    }))
            })??[],

            ...editCheckDef?.check?.localVariables?.map((v, i) => (
                {
                    key: `${v.varOID}-${i}`,
                    value: `{${v.varOID}}`,
                    label: `${v.varOID}`
                }
            ))??[]
        ];
        setQueryMessageOptions(res);
    }, [editCheckDef]);

    const parse = () => {
        setShowParseBtn(false);

        (async () => {
            setValidate(true);
            const res = await axios.post(`/api/studies/${metaDataStore.versionInfo.studyOID}/mdv/${metaDataStore.versionInfo.oid}/expr/parse`, {expr: expr});
            const {annotations, executeExpr, ecsSources, localVariables, visitIfStatements, useGlobalVariable} = res.data;

            setAnnotations(annotations);
            setVisitIfStatements(visitIfStatements);
            // console.log(visitIfStatements);

            const editCheck = {
                useGlobalVariable,
                check:{
                    executeExpression:executeExpr,
                    visitIfStatements:visitIfStatements,
                    source:ecsSources,
                    localVariables,
                },
                action:{target:{varOID:null}}
            };
            form.setFieldsValue(editCheck);
            setEditCheckDef(editCheck);

            if (annotations.length > 0) {
                const $markers = [];
                annotations.map(annotation => {
                    $markers.push({
                        startRow: annotation.row,
                        startCol: annotation.startCol - 1,
                        endRow: annotation.row,
                        endCol: annotation.endCol,
                        className: 'ecs_error',
                        // type: 'background'
                        type: 'line'
                    });
                });
                setMarkers($markers);
            } else {
                setMarkers([]);
            }

            setTimeout(() => {
                setValidate(false);
            }, 500);

        })();
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values) => {
                // Submit values

                const check = {...values.check, visitIfStatements};
                // console.log(check);

                const saveValues = {...values, check};
                // console.log('saveValues =>', saveValues);
                onFinish(saveValues);
            })
            .catch((errorInfo) => {});
    };

    const onChange = (newValue, action) => {
        // console.log('change expr =', newValue, 'action=', action);
        if(editMode) {
            // setExpr(newValue);
            if (isEmpty(newValue)) {
                setAnnotations([]);
                setShowParseBtn(false);
            } else {
                setExpr(newValue);
                setShowParseBtn(true);
            }
        }
    }

    const onPaste = (t) => {
        console.log('onPaste = ', t);
    }

    const onChangeActionType = actionType => {
        setActionType(actionType);
    }

    const staticWordCompleter = {
        getCompletions: function(editor, session, pos, prefix, callback) {

            // const items = [...metaData.item.values()];
            // callback(null, items.map(function(word) {
            //     return {
            //         caption: word.sasFieldName,
            //         value: word.sasfieldName,
            //         meta: word.name
            //     };
            // }));
            const studyEvents = [...metaData.studyEvent.values()];
            // const forms = [...metaData.form.values()];
            // const itemGroups = [...metaData.itemGroup.values()];
            const data = [];
            studyEvents.map(function(studyEvent) {
                studyEvent.formRef.map(({formOID}) => {
                    let form = metaData.form.get(formOID);
                    form.itemGroupRef.map(({itemGroupOID}) => {
                        let itemGroup = metaData.itemGroup.get(itemGroupOID);
                        itemGroup.itemRef.map(({itemOID}, index) => {
                            //empty size 함수 사용가능한 아이템 그룹 추가
                            if(index === 0 && itemGroup.repeating !== 'NO') {
                                data.push({
                                    caption: 'size(' + studyEvent.oid + '.' + form.oid + '.' + itemGroup.oid + ')',
                                    value: 'size(' + studyEvent.oid + '.' + form.oid + '.' + itemGroup.oid + ')',
                                    meta: studyEvent.name + '>' + form.name + '>' + itemGroup.name + t('caption.number.of.data')
                                });

                                data.push({
                                    caption: 'size(' + form.oid + '.' + itemGroup.oid + ')',
                                    value: 'size(' + form.oid + '.' + itemGroup.oid + ')',
                                    meta: form.name + '>' + itemGroup.name + t('caption.number.of.data')
                                });

                                data.push({
                                    caption: 'empty(' + studyEvent.oid + '.' + form.oid + '.' + itemGroup.oid + ')',
                                    value: 'empty(' + studyEvent.oid + '.' + form.oid + '.' + itemGroup.oid + ')',
                                    meta: studyEvent.name + '>' + form.name + '>' + itemGroup.name + t('caption.data.availability')
                                });

                                data.push({
                                    caption: 'empty(' + form.oid + '.' + itemGroup.oid + ')',
                                    value: 'empty(' + form.oid + '.' + itemGroup.oid + ')',
                                    meta: form.name + '>' + itemGroup.name + t('caption.data.availability')
                                });
                            }


                            //StudyEvent...Item 정보 추가
                            data.push({
                                caption: studyEvent.oid + '.' + form.oid + '.' + itemGroup.oid + '.' + itemOID,
                                value: studyEvent.oid + '.' + form.oid + '.' + itemGroup.oid + '.' + itemOID,
                                meta: studyEvent.name + '>' + form.name + '>' + itemGroup.name + '>' + metaData.item.get(itemOID).name
                            });

                            //Form....Item 정보 추가
                            data.push({
                                caption: form.oid + '.' + itemGroup.oid + '.' + itemOID,
                                value: form.oid + '.' + itemGroup.oid + '.' + itemOID,
                                meta: form.name + '>' + itemGroup.name + '>' + metaData.item.get(itemOID).name
                            });
                        });
                    });
                });
            });
            callback(null, data);
            // callback(null, itemGroups.map(function(itemGroup) {
            //     // console.log(itemGroup.itemRef);
            //     itemGroup.itemRef.map(({itemOID}) => {
            //         // console.log(itemOID);
            //         return {
            //             caption: itemOID,
            //             value: itemOID,
            //             meta: itemOID
            //         };
            //     });
            // }));
            // callback(null, items.map(function(word) {
            //     return {
            //         caption: name,
            //         value: word.sasfieldName,
            //         meta: word.sasfieldName
            //     };
            // }));

            callback(null, functions.map(function(f) {
                return {
                    caption: t(f.caption),
                    value: f.value,
                    meta: f.meta
                };
            }));
        }
    }

    langTools.setCompleters([staticWordCompleter]);

    // const menu = (
    //     <Menu>
    //         <Menu.Item key="1">
    //             1st menu item
    //         </Menu.Item>
    //     </Menu>
    // );

    return (

        <Modal
            title="ECS Editor"
            centered
            maskClosable={false}
            keyboard={false}
            open={showModal}
            okText={'Save'}
            destroyOnClose={true}
            // okButtonProps={{disabled:annotations.length > 0 || !editMode}}
            // onOk={() => handleFormSubmit()}
            onCancel={() => closeModal()}
            width={1000}
            footer={[
                (!isProd && !editMode) && <Button type={'link'} onClick={() => setEditMode(true)}><i className={'fa fa-pencil'}></i>&nbsp;&nbsp;Edit</Button>,
                (!isProd && editMode) && <Button type={"primary"} disabled={annotations.length > 0 || !editMode || showParseBtn} onClick={() => handleFormSubmit()}><i className={'fa fa-check'}></i>&nbsp;&nbsp;Save</Button>,
                <Button type={'link'} onClick={() => closeModal()}><i className={'fa fa-times'}></i>&nbsp;&nbsp;Close</Button>,
            ]}
            afterClose={() => {setEditMode(false);setValidate(false);closeModal();}}
        >
            <Spin spinning={loading} tip={'Loading...'} size={'large'}>
                <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} size={'middle'}>
                    <EditCheckGuide />
                    <Form.Item className={'mb-0'} hidden={true} name="useGlobalVariable" label="Use Global Variable">
                        <Input readOnly={true}/>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name={["check", "executeExpression"]} label="ExecuteExpr" rules={[{ required: false}]} hidden={true}>
                        <Input readOnly={true}/>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name="oid" label="OID" rules={[
                        { required: true, message:t('message.required.field')},
                        { pattern:pattern, message:t('message.format.is.not.valid')},
                        {
                            validator: async (_, newOid) => {
                                if(isEmpty(oid) || isCopy) {
                                    if (!isEmpty(newOid)) {
                                        const exists = contains(newOid);
                                        if (exists) {
                                            return Promise.reject(new Error(t('message.new.oid.is.already.in.use', {newOid: newOid})));
                                        }
                                    }
                                }
                            },
                        },
                    ]}>
                        <Input readOnly={!isEmpty(oid)&&!isCopy} disabled={!editMode}/>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name="name" label="Name" rules={[{ required: true}]}>
                        <Input disabled={!editMode}/>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name="description" label="Description" rules={[{ required: false}]}>
                        <TextArea disabled={!editMode}/>
                    </Form.Item>
                    <Form.Item valuePropName={"checked"} className={'mb-0'}
                               name="activate" label="Activate" rules={[{ required: false}]}
                               initialValue={true} >
                        <Switch disabled={!editMode} />
                    </Form.Item>

                    <Title level={5}>Check</Title>

                    <Spin indicator={antIcon} spinning={validate} tip={'Parsing expression...'} size={'large'}>
                        <Form.Item className={'mb-0'} name={["check", "formalExpression", "value"]} label="Expression" rules={[{ required: true, message:t('message.pls.enter.the.expression') }]}>
                            <AceEditor
                                readOnly={!editMode}
                                focus={false}
                                width={'100%'}
                                mode={"javascript"}
                                theme={"xcode"}
                                // value={expr}
                                // height={'100px'}
                                minLines={10}
                                maxLines={Infinity}
                                // onSelectionChange={(e) => console.log(e)}
                                // debounceChangePeriod={1000}
                                onChange={onChange}
                                onPaste={onPaste}
                                // onValid={() => console.log('onValid')}
                                // enableSnippets={true}
                                showPrintMargin={false}
                                showGutter={true}
                                wrapEnabled={editMode}
                                highlightActiveLine={editMode}
                                fontSize={15}
                                style={{fontFamily:'consolas,monaco'}}
                                // borderRadius:3
                                name="ECS_EDITOR"
                                // editorProps={{ $blockScrolling: Infinity }}
                                annotations={annotations}
                                // commands={[{   // commands is array of key bindings.
                                //     name: 'Parse', //name for the key binding.
                                //     bindKey: {win: 'Ctrl-h', mac: 'Command-h'}, //key combination used for the command.
                                //     exec: () => validate  //function to execute when keys are pressed.
                                // }]}
                                markers={markers}
                                setOptions={{
                                    enableBasicAutocompletion: false,
                                    enableLiveAutocompletion: editMode,
                                    // enableSnippets: true,
                                    showLineNumbers: true,
                                    useWorker:false,
                                    tabSize: 4,
                                    dragEnabled: editMode,
                                    placeholder:'/**\n*'+t('odm.pls.enter.it.here')+'\n*/',
                                    // autoScrollEditorIntoView: true,
                                }}
                                commands={[{
                                    name: 'save',
                                    bindKey: { win: 'Ctrl-enter', mac: 'Cmd-enter' },
                                    exec: editor => onPaste(editor.getValue()),
                                }]}
                            />

                        </Form.Item>
                    </Spin>
                    <div style={{textAlign:'right',marginTop:'5px'}}>
                        <Button type={"primary"} danger={true} onClick={parse} hidden={!showParseBtn} size={"small"}>Parsing</Button>
                    </div>
                    {editCheckDef.check.source.length > 0 &&
                        <>
                            <Form.List name={["check", "source"]}>
                                {(fields) => {
                                    return (
                                        <div className={'mt-2'}>
                                            <Title level={5}>Data Source</Title>
                                            {fields.map((field, index) => (
                                                <EditCheckSource key={`${field.key}-source-${index}`} metaData={metaData} form={form} field={field} index={index} editMode={editMode}
                                                                 studyEventDefs={studyEventDefs} formDefs={formDefs} itemGroupDefs={itemGroupDefs} itemDefs={itemDefs} source={editCheckDef.check.source[index]}/>
                                            ))}
                                        </div>
                                    )
                                }}
                            </Form.List>
                            <Space hidden={true}>
                                <Form.List name={["check", "localVariables"]}>
                                    {(fields) => (
                                        fields.map((field, varIndex) => (
                                            <Form.Item  key={`localVariables${varIndex}`} name={[varIndex, "varOID"]}>
                                                <Input readOnly={true}/>
                                            </Form.Item>
                                        ))
                                    )}
                                </Form.List>
                            </Space>
                        </>
                    }

                    <Title level={5}>Action</Title>
                    <Form.Item className={'mb-0'} name={["action", "type"]} label="Type" rules={[{required: true}]} readOnly={!editMode}>
                        <Select disabled={!editMode} onChange={onChangeActionType}>
                            <Option disabled={editCheckDef.check.source.length === 0} value={'SYSTEM_QUERY'}>System Query</Option>
                            <Option disabled={editCheckDef.check.source.length === 0} value={'METHOD'}>Method</Option>
                            <Option disabled={editCheckDef.check.source.length === 0 && !editCheckDef.useGlobalVariable} value={'CONDITION'}>Condition</Option>
                            <Option disabled={editCheckDef.check.source.length > 0} value={'SWITCH_TEXT'}>Switch Text</Option>
                            <Option disabled={editCheckDef.check.source.length === 0} value={'SUBJECT_STATUS'}>Subject Status</Option>
                            <Option disabled={editCheckDef.check.source.length === 0} value={'EMAIL_NOTICE'}>Email Notice</Option>
                        </Select>
                    </Form.Item>
                    {/*{Object.keys(form.getFieldValue(["check", "source"])).map()}*/}
                    <Form.Item className={'mb-0'} name={["action", "target", "varOID"]} label="Query Target"
                               rules={[{required: (actionType === 'SYSTEM_QUERY')}]} hidden={actionType !== 'SYSTEM_QUERY'}>
                        <Select disabled={!editMode}>
                            {editCheckDef.check.source.map((k, index) => {
                                return (
                                    k.sourceItems.map((item, itemIndex) => isEmpty(item.itemOID) === false && (isEmpty(item.func) || !targetUnusableFunctions.has(item.func))  ? (
                                            <Option key={`V_${k.prevStudyEventIndex ? '.' + 'PREV' : ''}${k.studyEventIndex > -1 ? '.' + k.studyEventIndex : ''}_${k.itemGroupOID}${k.itemGroupIndex > -1 ? '.' + k.itemGroupIndex : ''}.${item.itemOID}.${itemIndex}`} value={item.varOID}>{`${k.studyEventOID.length === 0 ? '' : k.studyEventOID[0] + (k.prevStudyEventIndex ? '[PREV]' : '') + (k.studyEventIndex > -1 ? '[' +k.studyEventIndex+ ']' : '') + '.'}${k.formOID}.${k.itemGroupOID}${k.itemGroupIndex > -1 ? '[' +k.itemGroupIndex+ ']' : ''}.${item.itemOID} - ${k.studyEventOID.length === 0 ? '' : metaData.studyEvent.get(k.studyEventOID[0])?.name||'[REMOVED STUDY]' + ' > '}${metaData.form.get(k.formOID)?.name} > ${metaData.itemGroup.get(k.itemGroupOID)?.name}${k.itemGroupIndex > -1 ? '[' +k.itemGroupIndex+ ']' : ''} > ${metaData.item.get(item.itemOID)?.name} ${isEmpty(item.func) ? '' : ` - ${item.func}`}`}</Option>
                                        ) : <></>
                                        // <Option key={k.itemGroupOID} value={item.varOID}>{`${k.itemGroupOID} - ${metaData.form.get(k.formOID)?.name} > ${metaData.itemGroup.get(k.itemGroupOID)?.name} ${isEmpty(item.func) ? '' : ` - ${item.func}`}`}</Option>
                                    ))
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name={["action", "editableNoValuePresent"]} label={<div><div>Editable when</div><div>no value is present?</div></div>} valuePropName={"checked"} hidden={actionType !== 'METHOD'}>
                        <Switch disabled={!editMode}/>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name={["action", "target", "message"]} label="Query Message" hidden={actionType !== 'SYSTEM_QUERY'}
                               rules={[{required: (actionType === 'SYSTEM_QUERY')}]}>
                        <Mentions disabled={!editMode} rows={2} prefix="$" options={queryMessageOptions}></Mentions>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name={["action", "subjectStatusOID"]} label="Subject Status" hidden={actionType !== 'SUBJECT_STATUS'}
                               rules={[{required: (actionType === 'SUBJECT_STATUS')}]}>
                        <Select disabled={!editMode}>
                            {subjectStatusDefs.map((v, i) => (
                                <Option key={`${v.oid}${i}`} value={v.oid}>{v.status}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name={["action", "emailNoticeTemplateId"]} label="Email Notice Template" hidden={actionType !== 'EMAIL_NOTICE'}
                               rules={[{required: (actionType === 'EMAIL_NOTICE')}]}>
                        <Select disabled={!editMode}>
                            {emailNoticeTemplates.map((template, i) => (
                                <Option key={`${template.id}${i}`} value={template.id}>{template.title}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name={["action", "noticeTriggerType"]} label="Notice Trigger" hidden={actionType !== 'EMAIL_NOTICE'}
                               rules={[{required: (actionType === 'EMAIL_NOTICE')}]}>
                        <Select disabled={!editMode}>
                            <Option disabled={editCheckDef.check.source.length === 0} value={'CASE_REPORT_FORM_SAVE'}>CaseReportForm Save</Option>
                            <Option disabled={editCheckDef.check.source.length === 0} value={'CASE_REPORT_FORM_UPDATE'}>CaseReportForm Update</Option>
                            <Option disabled={editCheckDef.check.source.length === 0} value={'CASE_REPORT_FORM_SAVE_UPDATE'}>CaseReportForm Save & Update</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>

    )
}

export default EditCheckDef;
