import React, {useEffect, useState} from "react";
import {Button, Card, Popconfirm, Space, Switch, Tag, Typography} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {isEnvProduction, toMetaData} from "../../lib/ODMUtils";
import EditCheckDef from "../../components/odm/EditCheckDef";
import {metadataActions} from "../../redux/module/metadata";
import {isEmpty} from "../../lib/StringUtils";
import DataGrid, {
    Column, ColumnChooser, Export,
    FilterRow, Grouping, GroupPanel,
    HeaderFilter, LoadPanel, OperationDescriptions,
    Pager, Paging, Scrolling, StateStoring, Toolbar, Item
} from 'devextreme-react/data-grid';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {onExporting} from "../../lib/BuilderUtils";
import axios from "axios";
import {httpStatus} from "../../lib/createRequestSaga";
import {useTranslation} from "react-i18next";


const {Text} = Typography;

const EditCheckContainer = () => {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const metaData = toMetaData(metaDataStore.study);
    // const [items, setItems] = useState([]);
    // const [showModal, setShowModal] = useState(false);
    const [editChecks, setEditChecks] = useState(metaDataStore.study.metaDataVersion[0]?.editCheckDef);
    const [filtered, setFiltered] = useState(false);
    const [dataGrid, setDataGrid] = useState(null);

    const [emailNoticeTemplates, setEmailNoticeTemplates] = useState([]);

    const [ecsOIDs, setEcsOIDs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [oid, setOid] = useState(null);
    const [isCopy, setIsCopy] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const attr = 'editCheckDef';


    //Initial Settings
    useEffect(() => {
        (async () => {
            const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/email-notice?isECSTemplate=true`)

            if(status === httpStatus.ok) {
                setEmailNoticeTemplates(data.emailNoticeTemplateList);
            }
        })();
    }, []);

    useEffect(() => {
        const editChecksData = metaDataStore.study.metaDataVersion[0]?.editCheckDef;
        const ecsOIDs = [...editChecksData].map(ecs => ({text: ecs.oid, value: ecs.oid}))??[];
        setEditChecks(metaDataStore.study.metaDataVersion[0]?.editCheckDef);
        setEcsOIDs(ecsOIDs);
        setLoading(false);
    }, [metaDataStore]);

    useEffect(() => {
        if(dataGrid != null) {
            const hashOID = location.hash.substring(1, location.hash.length);

            setTimeout(() => {
                if(isEmpty(location.hash) === false) {
                    const dataGridInstance = dataGrid.instance;
                    dataGridInstance.filter(['oid', '=', hashOID]); //Filter 입력정보 초기화
                    dataGridInstance.clearFilter('row'); //FilterRow 해제
                    dataGridInstance.clearFilter('header'); //HeaderFilter 해제
                    setFiltered(true);
                }
            }, 100);
        }
    }, [dataGrid])

    const fnEdit = ({oid}) => {
        setOid(oid);
        setIsCopy(false);
        setShowModal(true);
    }

    const fnCopy = ({oid}) => {
        setOid(oid);
        setIsCopy(true);
        setShowModal(true);
    }

    const onFinish = (editCheckDef) => {
        setLoading(true);
        editCheckDef.attr = attr;
        editCheckDef.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
        //console.log(editCheckDef);

        if(ecsOIDs.filter(ecsOID => ecsOID.value === editCheckDef.oid))
            console.log(ecsOIDs.filter(ecsOID => ecsOID.value === editCheckDef.oid));
        if(isEmpty(oid)) {
            if((ecsOIDs.filter(ecsOID => ecsOID.value === editCheckDef.oid)).length > 0) {
                console.log(ecsOIDs.filter(ecsOID => ecsOID.value === editCheckDef.oid));
                alert(t('message.same.oid.already.exist'));
                return;
            }
            dispatch(metadataActions.create(editCheckDef));
        } else {
            dispatch(metadataActions.update(editCheckDef));
        }

        setOid(null);
        setShowModal(false);

        //저장동작이 발생하면, 필터적용된 ECS항목 초기화 함
        if(filtered) {
            const dataGridInstance = dataGrid.instance;
            dataGridInstance.clearFilter();
            setFiltered(false);
            navigate(location.pathname);
        }
    };

    const deleteECS = (oid) => {
        setLoading(true);
        const editCheckDef = {...metaData.editCheck.get(oid)};
        editCheckDef.attr = attr;
        editCheckDef.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
        dispatch(metadataActions.delete(editCheckDef));
    }

    const closeModal = () => {
        setShowModal(false);
        setOid(null);
    }

    const getTypeText = type => {
        if(type === 'SYSTEM_QUERY') {
            return <Tag color={'warning'}>System Query</Tag>;
        } else if(type === 'METHOD') {
            return <Tag color={'processing'}>Method</Tag>;
        } else if(type === 'CONDITION') {
            return <Tag color={'orange'}>Condition</Tag>;
        } else if(type === 'SUBJECT_STATUS') {
            return <Tag color={'success'}>Subject Status</Tag>;
        } else if(type === 'SWITCH_TEXT') {
            return <Tag color={'blue'}>Switch Text</Tag>;
        } else {
            return <Tag color={'default'}>{type}</Tag>;
        }
    }

    return (
        <Card title={'Edit Checks'}>
            <DataGrid dataSource={editChecks}
                      id={'gridECS'}
                      ref={(ref) => {setDataGrid(ref);}}
                      allowedPageSizes={true}
                      allowColumnResizing={false}
                      columnMinWidth={120}
                      columnAutoWidth={true}
                      showColumnLines={true}
                      showRowLines={true}
                      showBorders={true}
                      hoverStateEnabled={true}
                      allowColumnReordering={true}
                      keyExpr={'oid'}
                      onExporting={(e) => onExporting(e, 'EditChecks',
                          ({gridCell, excelCell}) => {
                            if(gridCell.column.caption === 'Action') {
                                excelCell.value = '';
                            }
                          }
                      )}>
                {/*<GroupPanel visible={true} />*/}
                {/*<Grouping autoExpandAll={true} />*/}
                <Column dataField={'action.type'} caption={'Type'} fixed={true} fixedPosition={'left'}
                        cellRender={({data}) => <>{getTypeText(data.action?.type)}</>}/>
                <Column dataField={'oid'} caption={'OID'} dataType={'string'} fixed={true} fixedPosition={'left'}
                        cellRender={({data}) => <Text strong={filtered}>{data?.oid}</Text>} />
                <Column dataField={'name'} caption={'Name'} dataType={'string'}/>
                <Column dataField={'description'} caption={'Description'} dataType={'string'}/>
                <Column dataField={'activate'} caption={'Activate'} dataType={'boolean'} fixed={true} fixedPosition={'right'}
                        cellRender={({data}) => <Switch checked={data.activate} disabled={true}></Switch>}/>
                <Column caption={'Action'} fixed={true} fixedPosition={'right'}
                        cellRender={({data}) => (
                            <Space size="middle">
                                <a onClick={() => fnEdit({oid: data.oid})}><i className={'fa fa-search'}></i></a>
                                {
                                    !isProd &&
                                    <>
                                        <a onClick={() => fnCopy({oid: data.oid})}><i
                                            className={'fa fa-copy'}></i></a>
                                        <Popconfirm
                                            title="Are you sure to delete this task?"
                                            onConfirm={() => deleteECS(data.oid)}
                                            // onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <a href="#"><i className={'fa fa-trash-o'}></i></a>
                                        </Popconfirm>
                                    </>
                                }
                            </Space>
                        )}/>
                {/* Option Component */}
                <Toolbar>
                    <Item render={() => (<Button hidden={isProd} type={"primary"} onClick={() => fnEdit({oid:""})}>
                        <i className={'fa fa-plus mr-2'}></i>New
                    </Button>)} />
                    <Item name={'columnChooserButton'} />
                    <Item name={'exportButton'} />
                </Toolbar>
                <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} />
                <LoadPanel enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} />
                <FilterRow visible={true} applyFilter={'auto'} />
                {/* 해당 컴포넌트 내 storageKey 변경으로 지정상태 정보 저장 */}
                <StateStoring enabled={true} type={'localStorage'} storageKey={`storage-${params?.sid}-ecs`}/>
                <Scrolling rowRenderingMode={'virtual'} />
                <Paging defaultPageSize={20}/>
                <Pager
                    visible={true}
                    displayMode={'compact'}
                    allowedPageSizes={[10, 20, 30, 50, 'all']}
                    showPageSizeSelector={true}
                    showInfo={true}
                />
            </DataGrid>
            <EditCheckDef metaData={metaData} oid={oid} isCopy={isCopy} onFinish={onFinish}
                          emailNoticeTemplates={emailNoticeTemplates}
                          showModal={showModal} closeModal={closeModal} isProd={isProd}/>
        </Card>
    )
}

export default EditCheckContainer;
