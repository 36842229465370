import React from 'react'
import {Helmet} from 'react-helmet-async'
import Profile from "../../../@vb/sscomponents/Auth/Profile";
import AuthLayout from "../../../layouts/Auth";

const SystemProfile = ({user}) => {
    return (
        <AuthLayout authPagesColor={'image'}
                    descr={'BUILDER'}
                    isAuthTopbar={true}
                    isGrayTopbar={false}
        >
            <Helmet title={`Profile`} />
            <Profile user={user.user}/>
        </AuthLayout>
    )
}

export default SystemProfile
