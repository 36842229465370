import {DefaultCard, CardHeader, CardBody, CardFooter} from "../../../../pages/PageStyleBox";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle} from "../../../../lib/BuilderUtils";
import queryString from "query-string";
import {useEffect, useState} from "react";
import DefaultLoading from "../../DefaultLoading";
import {Button, Col, Form, Input, message, Row, Space} from "antd";
import {useTranslation} from "react-i18next";

const GlobalLibraryEdit = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();

    //query-string이 존재하면 parameter에 해당하는 값 불러오기.
    const params = queryString.parse(location.search);
    const [library, setLibrary] = useState(null);
    const [descLength, setDescLength] = useState(0);
    const [nameLength, setNameLength] = useState(0);

    const [form] = Form.useForm();

    //Button Loading
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        (async () => {
            //GET Request
            try {
                //REST API Request
                const {data, status} = await axios.get(`/api/builder/global-library/edit`, {params: params});

                if(status === httpStatus.ok) {
                    //State Setting
                    setLibrary(data.library);
                    setNameLength(data?.library?.name?.length??0);
                    setDescLength(data?.library?.description?.length??0);
                }
            } catch (error) {
                //Error Exception
                errorHandle(error, navigate, "/global-library")
            }
        })()
    }, [])


    const onFinish = async (values) => {
        setLoading(true);

        try {
            const body = {
                ...library,
                ...values,
            };

            const {data, status} = await axios({
                url: `/api/builder/global-library/edit`,
                data: body, method: 'post',
            });

            if (status === httpStatus.ok) {
                message.success(data.message);
                navigate('/global-library');
            }
        } catch (e) {
            errorHandle(e, navigate, '/global-library');
        }

        setLoading(false)
    }

    const onFinishFailed = (error) => {
        console.log("%cError : ", "color:red", error);
    }

    const onInputDescription = ({target}) => {
        setDescLength(target.value.length);
    }

    const onInputName = ({target}) => {
        setNameLength(target.value.length);
    }

    return (
        <>
            {
                library === null &&
                <DefaultLoading/>
            }
            {
                library !== null &&
                <DefaultCard>
                    <CardHeader>
                        <h3>Global Library</h3>
                    </CardHeader>
                    <Form layout="vertical"
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          initialValues={library}
                          form={form}
                    >
                        <CardBody>
                            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                <Col span={24}>
                                    <Form.Item name={'name'} label='Library Name' extra={<span>{nameLength}/120</span>} required
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: t('message.pls.enter.lib.name'),
                                                   },
                                                   {
                                                       max: 120,
                                                       type: 'string',
                                                       message: t('message.lib.name.cannot.exceed.hundred.twenty.char')
                                                   }
                                               ]}
                                    >
                                        <Input onInput={onInputName} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name={'description'} label='Library Description' extra={<span>{descLength}/500</span>} required
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: t('message.pls.enter.lib.description'),
                                                   },
                                                   {
                                                       max: 500,
                                                       type: 'string',
                                                       message: t('message.lib.description.cannot.exceed.five.hundred.char')
                                                   }
                                               ]}
                                    >
                                        <Input.TextArea onInput={onInputDescription} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className='text-right'r>
                            <Button type={'default'} onClick={() => {
                                navigate("/global-library");
                            }} className={'mr-3'}> Cancel </Button>
                            <Button loading={loading} type={'primary'} htmlType={'submit'}> Submit </Button>
                        </CardFooter>
                    </Form>
                </DefaultCard>
            }
        </>
    )
}

export default GlobalLibraryEdit;