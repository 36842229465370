import React from "react";
import {CardFormWrapper, CodeListTextWrapper, FormDiv} from "../StyleBox";
import {Link, useParams} from "react-router-dom";
import {Paper} from "@material-ui/core";
import {isEmpty} from "../../../lib/StringUtils";
import style from './style.module.scss'
import {Typography} from "antd";

const {Text} = Typography;

const getFormTypeLabel = (formType) => {
    if(formType === 'CRF') {
        return <span className={'badge badge-default font-size-10'}>CRF</span>;
    } else if(formType === 'REGISTRATION_FORM') {
        return <span className={'badge badge-info font-size-10'}>REG</span>;
    } else if(formType === 'LABORATORY_TEST_FORM') {
        return <span className={'badge badge-success font-size-10'}>LAB</span>;
    } else if(formType === 'SAE_REPORT_FORM') {
        return <span className={'badge badge-warning font-size-10'}>SAE</span>
    } else if(formType === 'EPRO') {
        return <span className={'badge badge-info font-size-10'}>ePRO</span>
    } else if(formType === 'CRF_EPRO') {
        return <span className={'badge badge-info font-size-10'}>CRF&nbsp;/&nbsp;ePRO</span>
    } else if(formType === 'INDEPENDENT_EVALUATION_FORM') {
        return <span className={'badge badge-secondary font-size-10'}>IE</span>
    } else if(formType === 'INDEPENDENT_EVALUATION_UPLOAD_FORM') {
        return <span className={'badge badge-secondary font-size-10'}>IE&nbsp;/&nbsp;<i className='fa fa-upload'></i></span>
    } else {
        return '-';
    }
}

export const translatedText = (translatedTexts, lang) => {
    if (translatedTexts == null || translatedTexts[0].value == null) {
        return null;
    }

    const langIndex = translatedTexts.findIndex(txt => txt.lang === lang);
    return translatedTexts[langIndex === -1 ? 0 : langIndex].value;
}

export const questionTranslatedText = (itemDef, lang) => {
    if (itemDef?.question == null || itemDef?.question?.translatedText == null || isEmpty(itemDef?.question?.translatedText[0]?.value)) {
        return itemDef?.name;
    }

    return translatedText(itemDef.question?.translatedText, lang);
}

export const QuestionText = ({mandatory, keySequence = null, itemDef, selectedLanguage, designMode}) => {
    return (
        <>
            {
                designMode ? (
                    <span className={'font-size-12'}>
                        {(keySequence != null) && (
                            <i className={'fa fa-key mr-1 p-1 text-white bg-primary'} />
                        )}
                        {mandatory === 'YES' && (
                            <sup style={{color: 'red'}} className={'mandatory'}>*</sup>
                        )}
                        <div className={style.checkboxContent}>
                            <pre className={'font-size-12'} style={{
                                whiteSpace: 'pre-wrap'
                            }}>{questionTranslatedText(itemDef, selectedLanguage)}</pre>
                        </div>
                    </span>
                ) : (
                    <div className={style.checkboxContent}>
                        <pre className={'font-size-12'} style={{
                            whiteSpace:'pre-wrap'
                        }}>{questionTranslatedText(itemDef, selectedLanguage)}</pre>
                    </div>
                )
            }
        </>
    )
};


const outTranslatedText = (text, selectedLanguage) => {
    if (text == null || text.translatedText == null || isEmpty(text.translatedText[0]?.value)) {
        return null;
    }

    const langIndex = text.translatedText.findIndex(txt => txt.lang === selectedLanguage);
    return text.translatedText[langIndex === -1 ? 0 : langIndex].value;
}

export const descriptionText = ({description, selectedLanguage, isWrap = true}) => {
    const text = outTranslatedText(description, selectedLanguage);
    return (
        text !== null ?
            <small className={'text-muted'}><i className={'fa fa-info-circle'}></i> <pre style={{display:'inline', whiteSpace:isWrap?'pre-wrap':'nowrap'}}>{text}</pre></small> : <></>
    )
};

export const headerTitleText = (headerTitle, selectedLanguage) => {
    const text = outTranslatedText(headerTitle, selectedLanguage);
    return (text !== null && <>{text}</>);
}

export const CodeList = ({itemDef, codeList, selectedLanguage, designMode}) => {
    const controlType = itemDef?.controlType;
    const isHorizontal = controlType.endsWith("HORIZONTAL");
    const isRadioButtons = controlType.startsWith("RADIO");
    const isCheckboxes = controlType.startsWith("CHECKBOX");
    const isDictionary = controlType.startsWith("DICTIONARY");
    const translatedText = (decode) => {
        if (decode.translatedText == null) {
            return 'N/A';
        }
        const langIndex = decode.translatedText.findIndex(txt => txt.lang === selectedLanguage);
        return decode.translatedText[langIndex === -1 ? 0 : langIndex].value;
    }

    return (
        <>
            {descriptionText({description:codeList.description, selectedLanguage:selectedLanguage})}
            {(codeList.externalCodeList != null && isDictionary) && (
                <div className="input-group mt-2">
                    <div className="input-group-prepend">
                        <span className="input-group-text"><i className={'fa fa-search'}></i></span>
                    </div>
                    <input type="text" style={{fontSize: '0.8rem'}} className="form-control"
                           placeholder={`${codeList.name}`}/>
                </div>
            )}
            {/*Radio Buttons */}
            {(codeList.codeListItem != null && (isRadioButtons || isCheckboxes) && !isDictionary) && (
                <div className={'mt-2'}>
                    {codeList.codeListItem.map((codeListItem, index) =>
                        <div key={`${itemDef?.oid}-codelist-${index}`}
                             style={{display: (isHorizontal ? "inline-block" : "block")}}
                             className={isHorizontal ? 'mr-2' : ''}>
                            <label className={isRadioButtons ? "vb__utils__control vb__utils__control__radio" : "vb__utils__control vb__utils__control__check"}>
                                <input type={'radio'} disabled/>
                                <span className="vb__utils__control__indicator"></span>
                                <CodeListTextWrapper>
                                    {designMode === true ?
                                        <sup style={{color: 'red'}}>{codeListItem.codedValue}</sup> : ''}
                                    <span className={'font-size-12'}> {translatedText(codeListItem.decode)}</span>
                                </CodeListTextWrapper>
                            </label>
                        </div>
                    )}
                </div>
            )}
            {/*Dropdowns */}
            {(codeList.codeListItem != null && !isRadioButtons && !isCheckboxes && !isDictionary) && (
                <div className={'mt-2'}>
                    <select className={'form-control font-size-12'}>
                    {codeList.codeListItem.map((codeListItem, index) =>
                        <option key={`${itemDef?.oid}-codelist-${index}`}>
                                    {designMode === true ?
                                        `${codeListItem.codedValue} : ` : ''}
                                    {translatedText(codeListItem.decode)}
                        </option>
                    )}
                    </select>
                </div>
            )}
        </>
    )
}

export const FormThumb = ({itemGroups, formDef, toUrl = null}) => {
    const params = useParams();
    const currentFormOID = params.oid;

    // console.log(formDef);

    return (
        <CardFormWrapper>
            <FormDiv>
                <Paper elevation={3}>
                    <div className={'card'}>
                        <div className={'card-body'} style={currentFormOID === formDef.oid ? {background: '#eee'} : {}}>
                            {
                                (currentFormOID !== formDef?.oid) ? (
                                    <Link to={toUrl!==null?toUrl:`/studies/${params.sid}/v/${params.id}/forms/${formDef.oid}`}>
                                        <FormThumbDetail currentFormOID={currentFormOID} formDef={formDef} itemGroups={itemGroups} />
                                    </Link>
                                ) : (
                                    <FormThumbDetail currentFormOID={currentFormOID} formDef={formDef} itemGroups={itemGroups} />
                                )
                            }
                        </div>
                    </div>
                </Paper>
            </FormDiv>
        </CardFormWrapper>
    )
}

const FormThumbDetail = ({currentFormOID, formDef, itemGroups}) => {
    return (
        <>
            <h6 className="header-title">
                <div className={'pull-left'}><Text style={{fontSize:8,color:'#4b7cf3'}} strong>{formDef?.oid}</Text></div>
                <div className={'text-right mb-2'}>{getFormTypeLabel(formDef.formType)}</div>
                {
                    currentFormOID === formDef.oid &&
                    <span><i className={'fa fa-check text-primary'}></i>&nbsp;</span>
                }
                {formDef.name}
            </h6>
            {(formDef.itemGroupRef == null || formDef?.itemGroupRef.length === 0) && (
                <div className={'text-center'}>
                    <br/>
                    <small className={'text-danger text-center'}>Please fill out the form.</small>
                    <br/>
                    <br/>
                </div>
            )}
            {formDef.itemGroupRef != null &&
                formDef.itemGroupRef.map((g, index) => {
                    const itemGroupDef = itemGroups.get(g.itemGroupOID);
                    return (
                        index < 2 &&
                        <div key={`form-thumb-group-${g.itemGroupOID}-${index}`}>
                            <small>{itemGroupDef.name}</small>
                            <hr/>
                            <hr/>
                            <hr/>
                        </div>
                    )
                })
            }
        </>
    );
}
