import {Button, Col, Descriptions, Divider, Input, Modal, Row, Typography} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

const LibraryActiveModal = ({activeOpen, setActiveOpen, active, onActiveChange, isNameMatch, onActiveLibrary, library}) => {

    const {t} = useTranslation();

    return (
        <Modal
            title={`Library ${active?'Active':'Inactive'}`}
            onCancel={() => setActiveOpen(false)}
            width={500}
            open={activeOpen}
            destroyOnClose={true}
            footer={
                <>
                    <Button key={'Close'} onClick={() => setActiveOpen(false)}>Cancel</Button>
                    <Button key={'Active'} disabled={!isNameMatch} type={'primary'}
                            onClick={() => onActiveLibrary(library?.id)}>{active?'Active':'Inactive'}</Button>
                </>
            }>
            <Row gutter={[10, 20]}>
                <Col span={24}>
                    <h3>{t('lib.really.active.inactive.library',{active : active?'Active':'Inactive'})}</h3>
                </Col>
                <Col span={24}>
                    <Descriptions column={1}>
                        <Descriptions.Item label={'Name'}>
                            <Typography.Text>
                                {library?.name??'-'}
                            </Typography.Text>
                        </Descriptions.Item>
                        <Descriptions.Item label={'Description'} contentStyle={{maxHeight:'15rem', overflow:"auto"}}>
                            <Typography.Text>
                                {library?.description??'-'}
                            </Typography.Text>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={24}>
                    <Descriptions column={1} bordered style={{}}>
                        <Descriptions.Item label={'FormDef'} contentStyle={{width:'75%'}}>
                            {library?.formDef?.length??0}
                        </Descriptions.Item>
                        <Descriptions.Item label={'ItemGroupDef'} contentStyle={{width:'75%'}}>
                            {library?.itemGroupDef?.length??0}
                        </Descriptions.Item>
                        <Descriptions.Item label={'ItemDef'} contentStyle={{width:'75%'}}>
                            {library?.itemDef?.length??0}
                        </Descriptions.Item>
                        <Descriptions.Item label={'CodeList'} contentStyle={{width:'75%'}}>
                            {library?.codeList?.length??0}
                        </Descriptions.Item>
                        <Descriptions.Item label={'ValueListDef'} contentStyle={{width:'75%'}}>
                            {library?.valueListDef?.length??0}
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={24}>
                    <Typography.Text>
                        {t('lib.want.continue.with.deletion.pls.enter.name.lib1')} <Typography.Text code>Name</Typography.Text>{t('lib.want.continue.with.deletion.pls.enter.name.lib2')}
                    </Typography.Text>
                    <Divider />
                    <Input onChange={onActiveChange} placeholder={t('lib.pls.enter.name', {name: library?.name})} />
                </Col>
            </Row>
        </Modal>
    )
}

export default LibraryActiveModal;