import React, {useEffect} from "react";
import {Card, Col, Row, Tag} from "antd";
import {useSelector} from "react-redux";
import {toMetaData} from "../../lib/ODMUtils";
import 'react-querybuilder/dist/query-builder.css';
import {useTranslation} from "react-i18next";

const DatasetContainer = () => {
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const metaData = toMetaData(metaDataStore.study);
    const staticItemGroupMap = new Map();
    const itemGroups = [...metaData.itemGroup.values()];
    const domainJoinType = new Map();
    const {t} = useTranslation();

    const domainNames = [... new Set(itemGroups.map(itemGroup => itemGroup?.domain))];

    useEffect(() => {
    }, []);


    return (
        <Card title={t('odm.check.dataset.based.on.domain', {length:domainNames.length})} className={'mb-3'}>
            <Row gutter={3}>
                <Col span={20}>
                    {
                        domainNames.map((domain, index) => {
                            const domainItemGroups = itemGroups.filter(g => g.domain === domain);
                            const nonRepeatItemGroups = domainItemGroups.filter(g => g.repeating === 'NO');
                            const simpleItemGroups = domainItemGroups.filter(g => g.repeating === 'SIMPLE');
                            const staticItemGroups = domainItemGroups.filter(g => g.repeating === 'STATIC');

                            let errorMessage = undefined;
                            if(staticItemGroups.length > 0 && (nonRepeatItemGroups.length > 0 || simpleItemGroups.length > 0)) {
                                errorMessage = t('message.static.item.group.only.domain.config.static.item.group');
                            }
                            if(nonRepeatItemGroups.length > 0 && simpleItemGroups.length > 1) {
                                errorMessage = t('message.only.non.repeating.group.and.one.repeat.config.simultaneously');
                            }
                            // if(nonRepeatItemGroups.length > 1 && simpleItemGroups.length === 1) {
                            //     errorMessage = '반복되지 않는 그룹과 설정시 반복되지 않는 그룹은 하나만 존재할수 있습니다.';
                            // }
                            if(nonRepeatItemGroups.length > 0 && simpleItemGroups.length > 0) {
                                domainJoinType.set(domain, 'O2M');
                            } else if(staticItemGroups.length > 0) {
                                domainJoinType.set(domain, 'M2M');

                                console.log(staticItemGroups);
                                const gg = staticItemGroups.reduce((prevValue, currentValue) => prevValue.itemRef.length > currentValue.itemRef.length ? prevValue : currentValue);
                                staticItemGroups.map(g => staticItemGroupMap.set(g.oid, gg));
                            }
                            return (
                    <Card title={<Tag color={"purple"}>{domain}</Tag>} size={"small"} style={{marginTop:(index > 0 ? 15 : 0)}}>
                        {
                            errorMessage !== undefined &&
                            <div className={'text-danger'}><i className={'fa fa-exclamation-circle'}></i> {errorMessage}</div>
                        }
                        {
                            domainItemGroups.map((g, index) => {

                                return (
                                    <>
                                        {
                                            index > 0 &&
                                            <div style={{textAlign:"center", fontSize:15,fontWeight:'bold',color:'#666'}}><i className={'fa fa-plus'}></i></div>
                                        }
                                    <table className={'table table-bordered'}>
                                        <tr>
                                            <td style={{backgroundColor:'#fafafa',fontSize:'12px'}} colSpan={g.itemRef.length}>{g.name} ({g.oid}) / Repeating={g.repeating}</td>
                                        </tr>
                                        <tr>
                                            {g.itemRef.map(itemRef => <td style={{padding:3,textAlign:'center'}}><span style={{fontSize:'11px',fontWeight:'bold'}}>{itemRef.itemOID}</span><br/><span style={{fontSize:'11px'}}>({metaData.item.get(itemRef.itemOID).name})</span></td>)}
                                        </tr>
                                    </table>
                                    </>
                                )
                            })
                        }
                    </Card>)
                        })
                    }
                </Col>
            </Row>

        </Card>
    )
}

export default DatasetContainer;
