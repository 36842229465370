import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import {Button, Col, Input, Row, Space, message, Form, Card, Switch, Tag, InputNumber} from "antd";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {useNavigate, useParams} from "react-router-dom";
import {errorHandle} from "../../../../lib/BuilderUtils";
import DefaultLoading from "../../DefaultLoading";
import {DefaultCard} from "../../../../pages/PageStyleBox";
import HtmlEditor, {TableContextMenu, TableResizing, Toolbar, Item} from "devextreme-react/html-editor";
import "devextreme/ui/html_editor/converters/markdown";

const { TextArea } = Input;

const CoverContent = styled.div`
    
  width: 21cm;
  height: 29.7cm;
  padding: 3.5cm 1.5cm 2cm 1.5cm;
  background-color: white;
  box-shadow: 5px 5px 5px 5px gray;
  zoom: 0.6;
  overflow-y: hidden;
  
`;

const HeaderContent = styled.div`
    
  width: 21cm;
  height: 5cm;
  padding: 1.5cm 1.5cm 2cm;
  background-color: white;
  box-shadow: 5px 5px 5px 5px gray;
  zoom: 0.6;
  overflow-y: hidden;
`;

const headerValues = [false, 1, 2, 3, 4, 5];

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];

const fontValues = ["Arial", "Courier New", "Georgia", "Impact", "Lucida Console",
                    "Tahoma", "Times New Roman", "Verdana"];

const CRFSetting = () => {

    const params = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    // mention
    let mentionList = new Array();
    const [isMention, setIsMention] = useState(false);
    const [coverMentionCfg, setCoverMentionCfg] = useState([{
        dataSource: mentionList,
        searchExpr: 'text',
        displayExpr: 'text',
        valueExpr: 'value',
        marker: '$',
    }]);
    const [headerMentionCfg, setHeaderMentionCfg] = useState([{
        dataSource: mentionList,
        searchExpr: 'text',
        displayExpr: 'text',
        valueExpr: 'value',
        marker: '$',
    }]);

    // html
    const coverStyle =
        `<style>
            #cover table {
                width: 100%;
                max-width: 100%;
                border-spacing: 0;
            }
    
            #cover table, td, th {
                border : 1px solid black;
                border-collapse: collapse;
            }
        </style>`;

    const headerStyle =
        `<style>
            .header table {
                width: 100%;
                max-width: 100%;
                border-spacing: 0;
                margin-top: 1.5cm;
            }
    
            .header table, td, th {
                border : 1px solid black;
                border-collapse: collapse;
                padding: 0px;
                margin:0px;
                height: 10px;
            }
        </style>`;

    const [crfCover, setCrfCover] = useState(coverStyle);
    const [crfHeader, setCrfHeader] = useState(headerStyle);

    const [switchCover, setSwitchCover] = useState(true);
    const [switchHeader, setSwitchHeader] = useState(true);

    // debounce
    const [coverIntervalId, setCoverIntervalId] = useState("");
    const [headerIntervalId, setHeaderIntervalId] = useState("");

    useEffect( () => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/crf-setting`);

                if (status === httpStatus.ok) {
                    if(data?.crfSetting) {
                        form.setFieldsValue(data?.crfSetting);
                        setCrfCover(data.crfSetting?.crfCover === null ? coverStyle : coverStyle + data.crfSetting?.crfCover);
                        setCrfHeader(data.crfSetting?.crfHeader === null ? headerStyle : headerStyle + data.crfSetting?.crfHeader);
                        setSwitchCover(data.crfSetting?.useCover);
                        setSwitchHeader(data.crfSetting?.useHeader);
                    }

                    mentionList = data?.crfMentionList;
                    if(mentionList.length > 0) {
                        let newCoverMentionCfg = [...coverMentionCfg];
                        newCoverMentionCfg[0].dataSource = mentionList.filter(m => m.type !== 'header');
                        setCoverMentionCfg(newCoverMentionCfg);

                        let newHeaderMentionCfg = [...headerMentionCfg];
                        newHeaderMentionCfg[0].dataSource = mentionList.filter(m => m.type !== 'cover');
                        setHeaderMentionCfg(newHeaderMentionCfg);

                        setIsMention(true);
                    }
                }
            } catch (e) {
                errorHandle(e, navigate);
            }
        })();

    }, []);

    const onFinish = async (values) => {
        try {
            const {status, data} = await axios({
                url: `/api/builder/studies/${params.sid}/crf-setting`,
                data: values,
                method: 'post'
            });

            if(status === httpStatus.ok) {
                message.success(data?.message);
            }
        } catch(e) {
            errorHandle(e, navigate);
        }

        const onResize = () => {
            const zoom = Math.min()
        }
    };

    const crfCoverChanged = (e) => {
        // event가 200ms 내에 반복 발생 : clearTimeout -> intervalId 생성 -> setInterval 설정
        // event 발생 후 200ms 지남 : (clearTimeout -> intervalId 생성 -> setInterval 설정 ->) setCrfCover 설정

        clearTimeout(coverIntervalId);
        const intervalId = setTimeout(() => {
            const coverHtml = coverStyle + e.value;
            setCrfCover(coverHtml);
            form.setFieldsValue({crfCover:coverHtml});
        }, 200);
        setCoverIntervalId(intervalId);
    }

    const crfHeaderChanged = (e) => {
        clearTimeout(headerIntervalId);
        const intervalId = setTimeout(() => {
            const headerHtml = headerStyle + e.value;
            setCrfHeader(headerHtml);
            form.setFieldsValue({crfHeader:headerHtml});
        }, 200);
        setHeaderIntervalId(intervalId);
    }

    const onSwitchCover = (e) => {
        setSwitchCover(e);
    }

    const onSwitchHeader = (e) => {
        setSwitchHeader(e);
    }

    return (
        <>
            {
                (isMention === false) ? (
                    <DefaultLoading />
                ) : (
                    <DefaultCard>
                        <Space direction="vertical">
                            <Form name="dynamic_form_nest_item" form={form} onFinish={onFinish} autoComplete="off">
                                <Row style={{marginTop: "20px"}}>
                                    <Card title={<h5>Use CRF Setting</h5>} style={{margin: "20px", width: "100%"}}>
                                        <div style={{display: "flex", transform: "translate(0, 20%)"}}>
                                            <h3 style={{marginRight: "100px", display: "flex", alignItems: "baseline"}}>
                                                <Tag style={{height:"auto"}} bordered={false} color="geekblue">Cover</Tag>
                                                <Form.Item
                                                    name={'useCover'}
                                                    valuePropName="checked"
                                                    rules={[{required: true, message: 'check using cover'}]}
                                                    style={{height:"auto"}}>
                                                    <Switch title={"useCover"} onChange={onSwitchCover} />
                                                </Form.Item>
                                            </h3>
                                            <h3 style={{display: "flex", alignItems: "baseline"}}>
                                                <Tag bordered={false} color="geekblue">Header</Tag>
                                                <Form.Item
                                                    name={'useHeader'}
                                                    valuePropName="checked"
                                                    rules={[{required: true, message: 'check using header'}]}>
                                                    <Switch title={"useHeader"} onChange={onSwitchHeader} />
                                                </Form.Item>
                                            </h3>
                                        </div>
                                    </Card>
                                </Row>
                                <Row>
                                    {/*CRF Cover*/}
                                    <Card title={<h5>CRF Cover</h5>} style={{margin: "20px"}} hidden={!switchCover}>
                                        <Form.Item
                                            name={'crfCover'}
                                            fieldKey={'crfCover'}
                                            rules={[{required: switchCover, message: 'Missing content'}]}
                                            hidden={true}>
                                            <TextArea/>
                                        </Form.Item>
                                        <Row gutter={[20, 10]}>
                                            <Col span={13} className="widget-container">
                                                <HtmlEditor
                                                    height="725px"
                                                    valueType={'html'}
                                                    mentions={coverMentionCfg}
                                                    defaultValue={crfCover}
                                                    onValueChanged={crfCoverChanged}
                                                >
                                                    {/*<MediaResizing enabled={true} />*/}
                                                    {/*<ImageUpload fileUploadMode="base64" />*/}
                                                    <TableContextMenu enabled={true} />
                                                    <TableResizing enabled={true} />
                                                    <Toolbar>
                                                        <Item name="undo" />
                                                        <Item name="redo" />
                                                        <Item name="separator" />
                                                        <Item name="size" acceptedValues={sizeValues} />
                                                        <Item name="font" acceptedValues={fontValues} />
                                                        <Item name="separator" />
                                                        <Item name="bold" />
                                                        <Item name="italic" />
                                                        <Item name="strike" />
                                                        <Item name="underline" />
                                                        <Item name="separator" />
                                                        <Item name="alignLeft" />
                                                        <Item name="alignCenter" />
                                                        <Item name="alignRight" />
                                                        <Item name="alignJustify" />
                                                        <Item name="separator" />
                                                        <Item name="orderedList" />
                                                        <Item name="bulletList" />
                                                        <Item name="separator" />
                                                        <Item name="header" acceptedValues={headerValues} />
                                                        <Item name="separator" />
                                                        <Item name="color" />
                                                        <Item name="background" />
                                                        <Item name="separator" />
                                                        {/* <Item name="link" />
                                                            <Item name="image" />
                                                            <Item name="separator" /> */}
                                                        <Item name="clear" />
                                                        <Item name="codeBlock" />
                                                        <Item name="blockquote" />
                                                        <Item name="separator" />
                                                        <Item name="insertTable" />
                                                        <Item name="insertHeaderRow" />
                                                        <Item name="insertRowAbove" />
                                                        <Item name="insertRowBelow" />
                                                        <Item name="separator" />
                                                        <Item name="insertColumnLeft" />
                                                        <Item name="insertColumnRight" />
                                                        <Item name="separator" />
                                                        <Item name="deleteColumn" />
                                                        <Item name="deleteRow" />
                                                        <Item name="deleteTable" />
                                                        <Item name="separator" />
                                                        <Item name="cellProperties" />
                                                        <Item name="tableProperties" />
                                                    </Toolbar>
                                                </HtmlEditor>
                                            </Col>
                                            <Col span={11}>
                                                <Card style={{height: "725px", background: "#999999"}}>
                                                    <div style={{display: "flex", alignContent: "center", justifyContent: "center"}}>
                                                        <CoverContent dangerouslySetInnerHTML={{__html: crfCover}}/>
                                                    </div>
                                                </Card>
                                                <div style={{margin: "1px", font:"10px", fontWeight:"bold"}}> ※ The screen above may differ from the actual CRF file.</div>
                                            </Col>

                                        </Row>
                                    </Card>
                                    {/*CRF Header*/}
                                    <Card title={<h5>CRF Header</h5>} style={{margin: "20px"}} hidden={!switchHeader}>
                                        <Form.Item
                                            name={'crfHeader'}
                                            fieldKey={'crfHeader'}
                                            rules={[{required: switchHeader, message: 'Missing content'}]}
                                            hidden={true}>
                                            <TextArea/>
                                        </Form.Item>
                                        <Row gutter={[20, 10]}>
                                            <Col span={13} className="widget-container">
                                                <HtmlEditor
                                                    height="400px"
                                                    valueType={'html'}
                                                    mentions={headerMentionCfg}
                                                    defaultValue={crfHeader}
                                                    onValueChanged={crfHeaderChanged}
                                                >
                                                    {/*<MediaResizing enabled={true} />*/}
                                                    {/*<ImageUpload fileUploadMode="base64" />*/}
                                                    <TableContextMenu enabled={true} />
                                                    <TableResizing enabled={true} />
                                                    <Toolbar>
                                                        <Item name="undo" />
                                                        <Item name="redo" />
                                                        <Item name="separator" />
                                                        <Item name="size" acceptedValues={sizeValues} />
                                                        <Item name="font" acceptedValues={fontValues} />
                                                        <Item name="separator" />
                                                        <Item name="bold" />
                                                        <Item name="italic" />
                                                        <Item name="strike" />
                                                        <Item name="underline" />
                                                        <Item name="separator" />
                                                        <Item name="alignLeft" />
                                                        <Item name="alignCenter" />
                                                        <Item name="alignRight" />
                                                        <Item name="alignJustify" />
                                                        <Item name="separator" />
                                                        <Item name="orderedList" />
                                                        <Item name="bulletList" />
                                                        <Item name="separator" />
                                                        <Item name="header" acceptedValues={headerValues} />
                                                        <Item name="separator" />
                                                        <Item name="color" />
                                                        <Item name="background" />
                                                        <Item name="separator" />
                                                        {/* <Item name="link" />
                                                            <Item name="image" />
                                                            <Item name="separator" /> */}
                                                        <Item name="clear" />
                                                        <Item name="codeBlock" />
                                                        <Item name="blockquote" />
                                                        <Item name="separator" />
                                                        <Item name="insertTable" />
                                                        <Item name="insertHeaderRow" />
                                                        <Item name="insertRowAbove" />
                                                        <Item name="insertRowBelow" />
                                                        <Item name="separator" />
                                                        <Item name="insertColumnLeft" />
                                                        <Item name="insertColumnRight" />
                                                        <Item name="separator" />
                                                        <Item name="deleteColumn" />
                                                        <Item name="deleteRow" />
                                                        <Item name="deleteTable" />
                                                        <Item name="separator" />
                                                        <Item name="cellProperties" />
                                                        <Item name="tableProperties" />
                                                    </Toolbar>
                                                </HtmlEditor>
                                            </Col>
                                            <Col span={11}>
                                                <Card style={{height: "400px", background: "#999999"}}>
                                                    <div style={{display: "flex", alignContent: "center", justifyContent: "center"}}>
                                                        <HeaderContent dangerouslySetInnerHTML={{__html: crfHeader}}/>
                                                    </div>
                                                </Card>
                                                <div style={{margin: "1px", font:"10px", fontWeight:"bold"}}> ※ The screen above may differ from the actual CRF file.</div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Row>
                                <Row style={{marginRight: "20px"}}>
                                    <Col span={24} style={{textAlign: 'right'}}>
                                        <Form.Item
                                            name={'saveCnt'}
                                            fieldKey={'saveCnt'}
                                            hidden={true}>
                                            <InputNumber />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Space>
                    </DefaultCard>
                )
            }
        </>
    );
}

export default CRFSetting;
