import {Checkbox, Radio, Space} from "antd";
import React from "react";
import style from "./style.module.scss"
const Domain = ({labType, scenarioDomains, selectedDomains, domain, delimiter, domainChangeListener}) => {
    return (
        <>
            {/*{domain['_links']['scenarios'] === undefined &&*/}
            {!scenarioDomains.includes(domain.name) &&
                <div key={domain.name}>
                    <Checkbox checked={selectedDomains.has(domain.name)} value={domain.name}
                              onClick={domainChangeListener}>
                        <span className={`text-xs font-size-14`}>
                            <span className={'font-weight-bold text-blue'}>{domain.name}</span>&nbsp;{domain.label}
                        </span>
                    </Checkbox>
                </div>
            }
            {(scenarioDomains.includes(domain.name) &&
                domain['_links']['scenarios'] !== undefined) &&
                domain['_links']['scenarios'].map((scenario) => {
                    // const val = domain.name + delimiter + scenario.title;
                    const val = scenario.href.substring(scenario.href.lastIndexOf("/") + 1);
                    if(domain.name !== 'LB' || val === labType) {
                        return (
                            <div key={val}>
                                <Checkbox onClick={domainChangeListener} value={val}
                                          checked={selectedDomains.get(domain.name) === val}>
                                <span className={`text-xs font-size-14`}>
                                    <span
                                        className={'font-weight-bold text-blue'}>{domain.name}</span>&nbsp;{domain.label} - {scenario.title}
                                </span>
                                </Checkbox>
                            </div>
                        )
                    }
                })
            }
        </>
    )
}

export default Domain;
