import React from "react";
import {Button} from "antd";


/**
 * Definition Button 동작
 * @param data itemGroup || item || codeList
 * @param buildForm buildForm
 * @param setBuildForm setBuildForm
 * @param parentItemGroup
 * @param index
 * @param defType
 * @param onAside
 * @param setAsideData
 * @returns {JSX.Element}
 * @constructor
 */

const DefinitionBtn = ({itemOID, data, defType, setDefVisible, setDefType, setDefItem}) => {

    //Edit Definition
    const onEdit = () => {
        setDefVisible(true);
        setDefType(defType);
        setDefItem(data);
    }

    return (
        <button className={'btn btn-outline-primary mr-2'}
                style={{fontWeight: 400, fontSize: '0.7rem', padding: '0px 3px', minWidth:'fit-content'}}
                onClick={() => onEdit()}>
            <i className={'fa fa-search-plus mr-1'}></i>{data?.oid??itemOID}
        </button>
    )
}

export default DefinitionBtn;
