import {Helmet} from "react-helmet-async";
import CRFSetting from "../../../../components/common/builder/studies/CRFSetting";
import SettingsTabs from "../../../../layouts/Tab/settings";

const CrfCoverPage = () => {

    return (
        <SettingsTabs menu={'crf-setting'}>
            <Helmet title={'BUILDER :: CRF Setting'} />
            <CRFSetting/>
        </SettingsTabs>
    )

}

export default CrfCoverPage