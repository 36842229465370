import React, {useEffect, useState} from "react";
import useLibraryStore from "../../../../../store/libraryStore";
import {errorHandle} from "../../../../../lib/BuilderUtils";
import {message} from "antd";
import {useMutation} from "react-query";
import {method, saveDefWithAxios} from "../../../../../lib/api/library";
import {descriptionCheck} from "../../../../../lib/ODMUtils";
import LibraryCodeList from "../modal/LibraryCodeList";
import Icon from "@mdi/react";
import {mdiOrderAlphabeticalAscending, mdiOrderNumericAscending} from "@mdi/js";

const LibraryCodeListContainer = ({defOpen, setDefOpen, defType, defItem, setDefItem}) => {

    const {
        library,
        dictionaryType,
        dictionaryMap,
        languages,
        initialValue,
        updateLibrary,
        clDataType
    } = useLibraryStore(state => (state));

    //컴포넌트에 따라 필요한 정보
    const propName = 'codeList';
    const [codeList, setCodeList] = useState({}); //현재 CodeList에 대한 정보
    const [create, setCreate] = useState(false);
    const dataTypeIcons = {
        'INTEGER': <Icon path={mdiOrderNumericAscending} color={'#1d39c4'} size={0.75} />,
        'TEXT': <Icon path={mdiOrderAlphabeticalAscending} color={'#1d39c4'} size={0.75} />
    };

    //UseMutation 사용
    const {mutate} = useMutation(saveDefWithAxios, {
        onSuccess:({library, resultMessage}) => {
            console.log(`ItemGroup ${create?'Create':'Update'} : `, library);
            message.success(resultMessage);
            updateLibrary(library);
            onCancel();
        },
        onError: (error) => {
            errorHandle(error);
        },
    });

    useEffect(() => {
        if (defItem !== null) { //Update
            setCodeList(defItem);
            setCreate(false);
        } else { //New
            setCodeList(initialValue?.codeList??{});
            setCreate(true);
        }
    }, [defItem]);

    const onCancel = () => {
        setCodeList({});
        setDefItem(null);
        setDefOpen(false);
        setCreate(false);
    }

    const onFinish = (itemDef) => {
        descriptionCheck(itemDef); //description nullCheck 후, 없으면 삭제

        const {id} = library;
        itemDef.id = id;
        itemDef.attr = propName;
        itemDef.method = create?method.post:method.put;
        mutate(itemDef);

        onCancel(); //Modal 화면 사라짐.
    };

    return (
        <LibraryCodeList open={defOpen} codeList={codeList} setCodeList={setCodeList}
                         dataTypeIcons={dataTypeIcons} dataTypes={clDataType} itemType={defType}
                         dictionaryType={dictionaryType} dictionaryMap={dictionaryMap}
                         languages={languages} onFinish={onFinish} onCancel={onCancel}  />
    )
}

export default LibraryCodeListContainer