import React from "react";
import _ from "lodash";
import {Badge, Card, Col, Collapse, Input, List, Row, Switch, Tag, Typography} from "antd";
import {Notice} from "../build-tool/StyleBox";
import classNames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import {controlTypes} from "../../lib/ODMUtils";

export const objectDiff = (a, b)  => _.fromPairs(_.differenceWith(_.toPairs(a), _.toPairs(b), _.isEqual));



export const StudyEventGroupDef = ({studyEventGroupDef, studyEventRefs}) => {
    return (
        <>
            {studyEventGroupDef != null &&
            <div style={{fontSize:'12px'}}>
                <div>Name : {studyEventGroupDef?.name}</div>
                <div>Repeating : {studyEventGroupDef.repeating}</div>
                <Collapse className={'mt-3'} title={'Item References'}>
                    {
                        studyEventRefs?.length > 0? (studyEventRefs.map((studyEventRef, index) => (
                            <Collapse.Panel key={index} header={
                                <>
                                    {
                                        studyEventRef?.mandatory === 'YES' &&
                                        <span className={'text-danger'}>*</span>
                                    }
                                    <span>{studyEventRef.studyEventOID}</span>
                                </>
                            }>
                                <div><Tag color={"default"}>Mandatory : {studyEventRef?.mandatory||'NO'}</Tag></div>
                                <div><Tag color={"orange"}>Condition OID : {studyEventRef?.collectionExceptionConditionOID||'NONE'}</Tag></div>
                            </Collapse.Panel>
                        ))
                        ) : (
                            <Notice>
                                No StudyEventRef.
                            </Notice>
                        )
                    }
                </Collapse>
            </div>
            }
        </>
    )
}

export const StudyEventGroupDefDiff = ({oldStudyEventGroupDef, newStudyEventGroupDef}) => {
    return (
        <div style={{fontSize:'12px'}}>
            {oldStudyEventGroupDef == null && <Tag color={"green"}>NEW</Tag>}
            <div>
                Name&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldStudyEventGroupDef?.name !== newStudyEventGroupDef?.name
                })}><span className={'new'}>{newStudyEventGroupDef?.name}</span>
                </span>
            </div>
            <div>
                Repeating&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldStudyEventGroupDef?.repeating !== newStudyEventGroupDef?.repeating
                })}><span className={'new'}>{newStudyEventGroupDef?.repeating}</span>
                </span>
            </div>
            <Collapse className={'mt-3'} title={'Item References'}>
                {
                    newStudyEventGroupDef?.studyEventRef?.length > 0? (
                        newStudyEventGroupDef?.studyEventRef.map((seRef, index) => {
                            const oldSeRef = oldStudyEventGroupDef?.studyEventRef.find(se => se.studyEventOID == seRef.studyEventOID)||{};
                            return (
                                <Collapse.Panel key={index} header={
                                    <>
                                        {
                                            seRef?.mandatory === 'YES' &&
                                            <span className={'text-danger'}>*</span>
                                        }
                                        <span>{seRef.studyEventOID}</span>
                                    </>
                                }>
                                    <div>
                                        <Tag color={"default"}>Mandatory&nbsp;:&nbsp;
                                            <span className={classNames('', {
                                               ['compare-changed font-weight-bold']: seRef?.mandatory !== oldSeRef.mandatory
                                            })}>{seRef?.mandatory}</span>
                                        </Tag>
                                    </div>
                                    <div>
                                        <Tag color={"orange"}>Condition OID&nbsp;:&nbsp;
                                            <span className={classNames('', {
                                                ['compare-changed font-weight-bold']: seRef?.collectionExceptionConditionOID !== oldSeRef.collectionExceptionConditionOID
                                            })}>{seRef?.collectionExceptionConditionOID || 'NONE'}</span>
                                        </Tag>
                                    </div>
                                </Collapse.Panel>
                            )
                        })
                    ) : (
                        <Notice>
                            No StudyEventRef.
                        </Notice>
                    )
                }
            </Collapse>
        </div>
    )
}

export const StudyEventDef = ({studyEventDef, formRefs}) => {
    return (
        <>
            {studyEventDef != null &&
            <div style={{fontSize:'12px'}}>
                <div>Name : {studyEventDef?.name}</div>
                <div>Repeating : {studyEventDef.repeating}</div>
                <div>Type : {studyEventDef.type}</div>
                <Collapse className={'mt-3'} title={'Item References'}>
                    {
                        formRefs?.length > 0 ? (
                            formRefs.map((formRef, index) => (
                                <Collapse.Panel key={index} header={
                                    <>
                                        {
                                            formRef?.mandatory === 'YES' &&
                                            <span className={'text-danger'}>*</span>
                                        }
                                        <span>{formRef.formOID}</span>
                                    </>
                                }>
                                    <div><Tag color={"default"}>Mandatory : {formRefs?.mandatory || 'NO'}</Tag></div>
                                    <div><Tag color={"orange"}>Condition OID
                                        : {formRefs?.collectionExceptionConditionOID || 'NONE'}</Tag></div>
                                </Collapse.Panel>
                            ))
                        ) : (
                            <Notice>
                                No FormRefs.
                            </Notice>
                        )
                    }
                </Collapse>
            </div>
            }
        </>
    )
}

export const StudyEventDefDiff = ({oldStudyEventDef, newStudyEventDef}) => {
    return (
        <div style={{fontSize:'12px'}}>
            {oldStudyEventDef == null && <Tag color={"green"}>NEW</Tag>}
            <div>
                Name&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldStudyEventDef?.name !== newStudyEventDef?.name
                })}><span className={'new'}>{newStudyEventDef?.name}</span>
                </span>
            </div>
            <div>
                Repeating&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldStudyEventDef?.repeating !== newStudyEventDef?.repeating
                })}><span className={'new'}>{newStudyEventDef?.repeating}</span>
                </span>
            </div>
            <div>
                Type&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldStudyEventDef?.type !== newStudyEventDef?.type
                })}><span className={'new'}>{newStudyEventDef?.type}</span>
                </span>
            </div>
            <Collapse className={'mt-3'} title={'Item References'}>
                {
                    newStudyEventDef?.formRef?.length > 0? (
                        newStudyEventDef?.formRef.map((formRef, index) => {
                            const oldFormRef = oldStudyEventDef?.formRef.find(f => f.formOID == formRef.formOID)||{};
                            return (
                                <Collapse.Panel key={index} header={
                                    <>
                                        {
                                            formRef?.mandatory === 'YES' &&
                                            <span className={'text-danger'}>*</span>
                                        }
                                        <span>{formRef.formOID}</span>
                                    </>
                                }>
                                    <div>
                                        <Tag color={"default"}>Mandatory&nbsp;:&nbsp;
                                            <span className={classNames('', {
                                                ['compare-changed font-weight-bold']: formRef?.mandatory!==oldFormRef?.mandatory
                                            })}>{formRef?.mandatory}</span>
                                        </Tag>
                                    </div>
                                    <div>
                                        <Tag color={"orange"}>Condition OID&nbsp;:&nbsp;
                                            <span className={classNames('', {
                                                ['compare-changed font-weight-bold'] : formRef?.collectionExceptionConditionOID!==oldFormRef?.collectionExceptionConditionOID
                                            })}>{formRef?.collectionExceptionConditionOID||'NONE'}</span>
                                        </Tag>
                                    </div>
                                </Collapse.Panel>
                            )
                        })
                    ) : (
                        <Notice>
                            No ItemGroupRefs.
                        </Notice>
                    )
                }
            </Collapse>
        </div>
    )
}


export const FormDef = ({formDef, itemGroupRefs}) => {
    return (
        <>
            {formDef != null &&
            <div style={{fontSize:'12px'}}>
                <div>Name : {formDef?.name}</div>
                <div>Repeating : {formDef.repeating}</div>
                <div>Form Type : {formDef.formType}</div>
                <div>Sibling Form OID : {formDef?.siblingFormOID??'-'}</div>
                <Collapse className={'mt-3'} title={'Item References'}>
                    {
                        itemGroupRefs?.length > 0 ? (
                            itemGroupRefs.map((itemGroupRef, index) => (
                                <Collapse.Panel key={index} header={
                                    <>
                                        {
                                            itemGroupRef?.mandatory === 'YES' &&
                                            <span className={'text-danger'}>*</span>
                                        }
                                        <span>{itemGroupRef.itemGroupOID}</span>
                                    </>
                                }>
                                    <div><Tag color={"default"}>Mandatory : {itemGroupRef?.mandatory || 'NO'}</Tag>
                                    </div>
                                    <div><Tag color={"orange"}>Condition OID
                                        : {itemGroupRef?.collectionExceptionConditionOID || 'NONE'}</Tag></div>
                                </Collapse.Panel>
                            ))
                        ) : (
                            <Notice>
                                No itemGroupRefs.
                            </Notice>
                        )
                    }
                </Collapse>
            </div>
            }
        </>
    )
}

export const FormDefDiff = ({oldFormDef, newFormDef}) => {
    return (
        <div style={{fontSize:'12px'}}>
            {oldFormDef == null &&
            <Tag color={"green"}>NEW</Tag>
            }
            <div>
                Name&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldFormDef?.name !== newFormDef?.name
                })}><span className={'new'}>{newFormDef?.name}</span>
                </span>
            </div>
            <div>
                Repeating&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldFormDef?.repeating !== newFormDef?.repeating
                })}><span className={'new'}>{newFormDef?.repeating}</span>
                </span>
            </div>
            <div>
                Form Type&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldFormDef?.formType !== newFormDef?.formType
                })}><span className={'new'}>{newFormDef?.formType}</span>
                </span>
            </div>
            <div>
                Sibling Form&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldFormDef?.siblingFormOID !== newFormDef?.siblingFormOID
                })}><span className={'new'}>{newFormDef?.siblingFormOID??'-'}</span>
                </span>
            </div>
            <Collapse className={'mt-3'} title={'Item References'}>
                {
                    newFormDef?.itemGroupRef?.length > 0? (
                        newFormDef?.itemGroupRef.map((itemGroupRef, index) => {
                            const oldItemGroupRef = oldFormDef?.itemGroupRef.find(ig => ig.itemGroupOID == itemGroupRef.itemGroupOID)||{};
                            return (
                                <Collapse.Panel key={index} header={
                                    <>
                                        {
                                            itemGroupRef?.mandatory === 'YES' &&
                                            <span className={'text-danger'}>*</span>
                                        }
                                        <span>{itemGroupRef.itemGroupOID}</span>
                                    </>
                                }>
                                    <div>
                                        <Tag color={"default"}>Mandatory&nbsp;:&nbsp;
                                            <span className={classNames('', {
                                                ['compare-changed font-weight-bold']: itemGroupRef?.mandatory!==oldItemGroupRef?.mandatory
                                            })}>{itemGroupRef?.mandatory}</span>
                                        </Tag>
                                    </div>
                                    <div>
                                        <Tag color={"orange"}>Condition OID&nbsp;:&nbsp;
                                            <span className={classNames('', {
                                                ['compare-changed font-weight-bold'] : itemGroupRef?.collectionExceptionConditionOID!==oldItemGroupRef?.collectionExceptionConditionOID
                                            })}>{itemGroupRef?.collectionExceptionConditionOID||'NONE'}</span>
                                        </Tag>
                                    </div>
                                </Collapse.Panel>
                            )
                        })
                    ) : (
                        <Notice>
                            No ItemRefs.
                        </Notice>
                    )
                }
            </Collapse>
        </div>
    )
}

export const ItemGroupDef = ({itemGroupDef, itemRefs, metaData}) => {
    return (
        <>
            {itemGroupDef != null &&
            <div style={{fontSize:'12px'}}>
                <div>Name : {itemGroupDef.name}</div>
                <div>Domain : {itemGroupDef?.domain||'N/A'}</div>
                <div>Repeating : {itemGroupDef.repeating}</div>
                <div>
                    Header Title&nbsp;:&nbsp;
                    {
                        (itemGroupDef?.headerTitle != null && itemGroupDef?.headerTitle.translatedText != null)?(
                            <div style={{border:'solid 1px #ced4da', padding:'0.5rem'}}>
                                {
                                    itemGroupDef?.headerTitle.translatedText.map((text, index) => (
                                        <Row key={`headerTitle-${index}`}>
                                            <Col span={6}>
                                                <Tag>{text?.lang||'Default'}</Tag>
                                            </Col>
                                            <Col span={18}>
                                                <pre>{text.value}</pre>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </div>
                        ) : (
                            <span>N/A</span>
                        )
                    }
                </div>
                <div>
                    Header Description&nbsp;:&nbsp;
                    {
                        (itemGroupDef?.headerDescription != null && itemGroupDef?.headerDescription.translatedText != null)?(
                            <div style={{border:'solid 1px #ced4da', padding:'0.5rem'}}>
                                {
                                    itemGroupDef?.headerDescription.translatedText.map((text, index) => (
                                        <Row key={`headerDescription-${index}`}>
                                            <Col span={6}><Tag>{text?.lang||'Default'}</Tag></Col>
                                            <Col span={18}><pre style={{whiteSpace:'pre-wrap'}}>{text.value}</pre></Col>
                                        </Row>
                                    ))
                                }
                            </div>
                        ) : (
                            <span>N/A</span>
                        )
                    }
                </div>
                <div>
                    Footer Description&nbsp;:&nbsp;
                    {
                        (itemGroupDef?.footerDescription != null && itemGroupDef?.footerDescription.translatedText != null)?(
                            <div style={{border:'solid 1px #ced4da', padding:'0.5rem'}}>
                                {
                                    itemGroupDef?.footerDescription.translatedText.map((text, index) => (
                                        <Row key={`footerDescription-${index}`}>
                                            <Col span={6}><Tag>{text?.lang||'Default'}</Tag></Col>
                                            <Col span={18}><pre style={{whiteSpace:'pre-wrap'}}>{text.value}</pre></Col>
                                        </Row>
                                    ))
                                }
                            </div>
                        ) : (
                            <span>N/A</span>
                        )
                    }
                </div>
                <Collapse className={'mt-3'} title={'Item References'}>
                    {
                        itemRefs.map((itemRef, index) => (
                            <Collapse.Panel key={index} header={
                                <>
                                    {
                                        itemRef?.mandatory === 'YES' &&
                                        <span className={'text-danger'}>*</span>
                                    }
                                    <span>{itemRef.itemOID}</span>
                                </>
                            }>
                                <div className={'mb-1'}><Tag color={"default"}>Mandatory : {itemRef?.mandatory||'NO'}</Tag></div>
                                <div className={'mb-1'}><Tag color={"orange"}>Condition OID : {itemRef?.collectionExceptionConditionOID||'NONE'}</Tag></div>
                                <div className={'mb-1'}><Tag color={'geekblue'}>Method OID : {itemRef?.methodOID||'NONE'}</Tag></div>
                                <div className={'mb-1'}><Tag color={'green'}>UnitsItem OID : {itemRef?.unitsItemOID||'NONE'}</Tag></div>
                                <div className={'mb-1'}><Tag color={'magenta'}>ValueList OID : {itemRef?.valueListOID||'NONE'}</Tag></div>
                            </Collapse.Panel>
                        ))
                    }
                </Collapse>
            </div>
            }
        </>
    )
}

export const ItemGroupDefDiff = ({oldItemGroupDef, newItemGroupDef}) => {
    return (
        <div style={{fontSize:'12px'}}>
            {oldItemGroupDef == null && <Tag color={"green"}>NEW</Tag>}
            <div>
                Name&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldItemGroupDef?.name !== newItemGroupDef?.name
                })}><span className={'new'}>{newItemGroupDef?.name}</span>
                </span>
            </div>
            <div>
                Domain&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldItemGroupDef?.domain !== newItemGroupDef?.domain
                })}><span className={'new'}>{newItemGroupDef?.domain||'N/A'}</span>
                </span>
            </div>
            <div>
                Repeating&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldItemGroupDef?.repeating !== newItemGroupDef?.repeating
                })}><span className={'new'}>{newItemGroupDef?.repeating}</span>
                </span>
            </div>

            <div>
                Header Title&nbsp;:&nbsp;
                {
                    (newItemGroupDef?.headerTitle != null && newItemGroupDef?.headerTitle.translatedText != null)?(
                        <div style={{border:'solid 1px #ced4da', padding:'0.5rem'}}>
                            {
                                newItemGroupDef?.headerTitle.translatedText.map((text, index) => (
                                    <Row key={`headerTitle-${index}`}>
                                        <Col span={6}>
                                            <Tag style={{
                                                color: (text?.lang !== oldItemGroupDef?.headerTitle[index]?.lang)?'red':null,
                                                fontWeight: (text?.lang !== oldItemGroupDef?.headerTitle[index]?.lang)?'bold':null
                                            }}>
                                                {text?.lang||'Default'}
                                            </Tag>
                                        </Col>
                                        <Col span={18}>
                                            <pre style={{
                                                whiteSpace:'pre-wrap',
                                                color: (text?.value !== oldItemGroupDef?.headerTitle[index]?.value)?'red':null,
                                                fontWeight: (text?.value !== oldItemGroupDef?.headerTitle[index]?.value)?'bold':null
                                            }}>
                                                {text.value}
                                            </pre>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </div>
                    ) : (
                        <span className={classNames('', {
                            ['compare-changed font-weight-bold']: !_.isEqual(oldItemGroupDef?.headerTitle, newItemGroupDef?.headerTitle)
                        })}>N/A</span>
                    )
                }
            </div>
            <div>
                Header Description&nbsp;:&nbsp;
                {
                    (newItemGroupDef?.headerDescription != null && newItemGroupDef?.headerDescription.translatedText != null)?(
                        <div style={{border:'solid 1px #ced4da', padding:'0.5rem'}}>
                            {
                                newItemGroupDef?.headerDescription.translatedText.map((text, index) => (
                                    <Row key={`headerDescription-${index}`}>
                                        <Col span={6}>
                                            <Tag style={{
                                                color: (text?.lang !== oldItemGroupDef?.headerDescription[index]?.lang)?'red':null,
                                                fontWeight: (text?.lang !== oldItemGroupDef?.headerDescription[index]?.lang)?'bold':null
                                            }}>
                                                {text?.lang||'Default'}
                                            </Tag>
                                        </Col>
                                        <Col span={18}>
                                            <pre style={{
                                                whiteSpace:'pre-wrap',
                                                color: (text?.value !== oldItemGroupDef?.headerDescription[index]?.value)?'red':null,
                                                fontWeight: (text?.value !== oldItemGroupDef?.headerDescription[index]?.value)?'bold':null
                                            }}>
                                                {text.value}
                                            </pre>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </div>
                    ) : (
                        <span className={classNames('', {
                            ['compare-changed font-weight-bold']: !_.isEqual(oldItemGroupDef?.headerDescription, newItemGroupDef?.headerDescription)
                        })}>N/A</span>
                    )
                }
            </div>
            <div>
                Footer Description&nbsp;:&nbsp;
                {
                    (newItemGroupDef?.footerDescription != null && newItemGroupDef?.footerDescription.translatedText != null)?(
                        <div style={{border:'solid 1px #ced4da', padding:'0.5rem'}}>
                            {
                                newItemGroupDef?.footerDescription.translatedText.map((text, index) => (
                                    <Row key={`footerDescription-${index}`}>
                                        <Col span={6}>
                                            <Tag style={{
                                                color: (text?.lang !== oldItemGroupDef?.footerDescription[index]?.lang)?'red':null,
                                                fontWeight: (text?.lang !== oldItemGroupDef?.footerDescription[index]?.lang)?'bold':null
                                            }}>
                                                {text?.lang||'Default'}
                                            </Tag>
                                        </Col>
                                        <Col span={18}>
                                            <pre style={{
                                                whiteSpace:'pre-wrap',
                                                color: (text?.value !== oldItemGroupDef?.footerDescription[index]?.value)?'red':null,
                                                fontWeight: (text?.value !== oldItemGroupDef?.footerDescription[index]?.value)?'bold':null
                                            }}>
                                                {text.value}
                                            </pre>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </div>
                    ) : (
                        <span className={classNames('', {
                            ['compare-changed font-weight-bold']: !_.isEqual(oldItemGroupDef?.footerDescription, newItemGroupDef?.footerDescription)
                        })}>N/A</span>
                    )
                }
            </div>

            <Collapse className={'mt-3'} title={'Item References'}>
                {
                    newItemGroupDef?.itemRef?.length > 0? (
                        newItemGroupDef?.itemRef.map((itemRef, index) => {
                            const oldItemRef = oldItemGroupDef?.itemRef.find(i => i?.itemOID == itemRef?.itemOID)||{};
                            return (
                                <Collapse.Panel key={index} header={
                                    <>
                                        {
                                            itemRef?.mandatory === 'YES' &&
                                            <span className={'text-danger'}>*</span>
                                        }
                                        <span>{itemRef.itemOID}</span>
                                    </>
                                }>
                                    <div className={'mb-1'}>
                                        <Tag color={"default"}>Mandatory &nbsp;:&nbsp;
                                            <span className={classNames('', {
                                                ['compare-changed font-weight-bold'] : itemRef?.mandatory!==oldItemRef?.mandatory
                                            })}>{itemRef?.mandatory||'NO'}</span>
                                        </Tag>
                                    </div>
                                    <div className={'mb-1'}>
                                        <Tag color={"orange"}>Condition OID&nbsp;:&nbsp;
                                            <span className={classNames('', {
                                                ['compare-changed font-weight-bold'] : itemRef?.collectionExceptionConditionOID!==oldItemRef?.collectionExceptionConditionOID
                                            })}>{itemRef?.collectionExceptionConditionOID||'NONE'}</span>
                                        </Tag>
                                    </div>
                                    <div className={'mb-1'}>
                                        <Tag color={"geekblue"}>Method OID&nbsp;:&nbsp;
                                            <span className={classNames('', {
                                                ['compare-changed font-weight-bold'] : itemRef?.methodOID!==oldItemRef?.methodOID
                                            })}>{itemRef?.methodOID||'NONE'}</span>
                                        </Tag>
                                    </div>
                                    <div className={'mb-1'}>
                                        <Tag color={"green"}>UnitsItem OID&nbsp;:&nbsp;
                                            <span className={classNames('', {
                                                ['compare-changed font-weight-bold'] : itemRef?.unitsItemOID!==oldItemRef?.unitsItemOID
                                            })}>{itemRef?.unitsItemOID||'NONE'}</span>
                                        </Tag>
                                    </div>
                                    <div className={'mb-1'}>
                                        <Tag color={"magenta"}>ValueList OID&nbsp;:&nbsp;
                                            <span className={classNames('', {
                                                ['compare-changed font-weight-bold'] : itemRef?.valueListOID!==oldItemRef?.valueListOID
                                            })}>{itemRef?.valueListOID||'NONE'}</span>
                                        </Tag>
                                    </div>
                                </Collapse.Panel>
                            )
                        })
                    ) : (
                        <Notice>
                            No ItemRefs.
                        </Notice>
                    )
                }
            </Collapse>
        </div>
    )
}

const getLabelControlType = (value) => {
    return controlTypes.find(ct => ct.value === value)?.label||'NONE';
}

export const ItemDef = ({itemDef, metaData}) => {
    return (
        <>
            {itemDef != null &&
            <div style={{fontSize:'12px'}}>
                <div>Name : {itemDef?.name}</div>
                <div>ControlType : {getLabelControlType(itemDef?.controlType)}</div>
                <div>DataType : {itemDef?.dataType}</div>
                <div>Length : {itemDef?.length}</div>
                <div>Fraction Digits : {itemDef?.fractionDigits||'N/A'}</div>
                <div>Left Text : {itemDef?.prefix||'N/A'}</div>
                <div>Right Text : {itemDef?.postfix||'N/A'}</div>
                <div>
                    Question&nbsp;:&nbsp;
                    {
                        (itemDef?.question != null && itemDef?.question.translatedText != null)?(
                            <div style={{border:'solid 1px #ced4da', padding:'0.5rem'}}>
                                {
                                    itemDef?.question.translatedText.map((text, index) => (
                                        <Row key={`headerTitle-${index}`}>
                                            <Col span={6}><Tag>{text?.lang||'Default'}</Tag></Col>
                                            <Col span={18}><pre style={{whiteSpace:'pre-wrap'}}>{text.value}</pre></Col>
                                        </Row>
                                    ))
                                }
                            </div>
                        ) : (
                            <span>N/A</span>
                        )
                    }
                </div>
                <div>
                    Description&nbsp;:&nbsp;
                    {
                        (itemDef?.description != null && itemDef?.description.translatedText != null)?(
                            <div style={{border:'solid 1px #ced4da', padding:'0.5rem'}}>
                                {
                                    itemDef?.description.translatedText.map((text, index) => (
                                        <Row key={`headerTitle-${index}`}>
                                            <Col span={6}><Tag>{text?.lang||'Default'}</Tag></Col>
                                            <Col span={18}><pre style={{whiteSpace:'pre-wrap'}}>{text.value}</pre></Col>
                                        </Row>
                                    ))
                                }
                            </div>
                        ) : (
                            <span>N/A</span>
                        )
                    }
                </div>

                {
                    itemDef?.valueListRef?.valueListOID != null && (
                        <>
                            <div className={'mb-1'}>
                                <Tag color={"gold"}>ValueListRef OID&nbsp;:&nbsp;
                                    <span className={'font-weight-bold'}>{itemDef?.valueListRef?.valueListOID}</span>
                                </Tag>
                            </div>
                            <ValueListDiff showDefaultProps={false} valueList={metaData.valueList.get(itemDef.valueListRef.valueListOID)} />
                        </>
                    )
                }
                {
                    itemDef?.codeListRef?.codeListOID != null && (
                        <>
                            <div className={'mb-1'}>
                                <Tag color={"gold"}>CodeListRef OID&nbsp;:&nbsp;
                                    <span className={'font-weight-bold'}>{itemDef?.codeListRef?.codeListOID}</span>
                                </Tag>
                            </div>
                            <CodeList showDefaultProps={false} codeList={metaData.codeList.get(itemDef.codeListRef.codeListOID)}/>
                        </>
                    )
                }
                {(itemDef?.valueListRef?.valueListOID == null && itemDef?.codeListRef?.codeListOID == null) && (
                    <Card>
                        <Input readOnly size={'small'} placeholder={itemDef.dataType + (itemDef.length == null ? '' : '(' + itemDef.length + ')')}/>
                    </Card>
                )}
            </div>
            }
        </>
    )
}

export const ItemDefDiff = ({oldItemDef, newItemDef, metaData}) => {
    return (
        <div style={{fontSize:'12px'}}>
            {oldItemDef == null && <Tag color={"green"}>NEW</Tag>}
            <div>
                Name&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldItemDef?.name !== newItemDef?.name
                })}><span className={'new'}>{newItemDef?.name}</span>
                </span>
            </div>
            <div>
                ControlType&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldItemDef?.controlType !== newItemDef?.controlType
                })}><span className={'new'}>{getLabelControlType(newItemDef.controlType)}</span>
                </span>
            </div>
            <div>
                DataType&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldItemDef?.dataType !== newItemDef?.dataType
                })}><span className={'new'}>{newItemDef?.dataType}</span>
                </span>
            </div>
            <div>
                Length&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldItemDef?.length !== newItemDef?.length
                })}><span className={'new'}>{newItemDef.length}</span>
                </span>
            </div>
            <div>
                Fraction Digits&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldItemDef?.fractionDigits !== newItemDef?.fractionDigits
                })}><span className={'new'}>{newItemDef.fractionDigits||'N/A'}</span>
                </span>
            </div>
            <div>
                Left Text&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldItemDef?.prefix !== newItemDef?.prefix
                })}><span className={'new'}>{newItemDef.prefix||'N/A'}</span>
                </span>
            </div>
            <div>
                Right Text&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: oldItemDef?.postfix !== newItemDef?.postfix
                })}><span className={'new'}>{newItemDef?.postfix||'N/A'}</span>
                </span>
            </div>
            <div>
                Question&nbsp;:&nbsp;
                {
                    (newItemDef?.question != null && newItemDef?.question.translatedText != null)?(
                        <div style={{border:'solid 1px #ced4da', padding:'0.5rem'}}>
                            {
                                newItemDef?.question.translatedText.map((text, index) => (
                                    <Row key={`headerTitle-${index}`}>
                                        <Col span={6}>
                                            <Tag style={{
                                                color: (text?.lang !== oldItemDef?.question[index]?.lang)?'red':null,
                                                fontWeight: (text?.lang !== oldItemDef?.question[index]?.lang)?'bold':null
                                            }}>
                                                {text?.lang||'Default'}
                                            </Tag>
                                        </Col>
                                        <Col span={18}>
                                            <pre style={{
                                                whiteSpace:'pre-wrap',
                                                color: (text?.value !== oldItemDef?.question[index]?.value)?'red':null,
                                                fontWeight: (text?.value !== oldItemDef?.question[index]?.value)?'bold':null
                                            }}>
                                                {text.value}
                                            </pre>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </div>
                    ) : (
                        <span className={classNames('', {
                            ['compare-changed font-weight-bold']: !_.isEqual(oldItemDef?.question, newItemDef?.question)
                        })}>N/A</span>
                    )
                }
            </div>
            <div>
                Description&nbsp;:&nbsp;
                {
                    (newItemDef?.description != null && newItemDef?.description.translatedText != null)?(
                        <div style={{border:'solid 1px #ced4da', padding:'0.5rem'}}>
                            {
                                newItemDef?.description.translatedText.map((text, index) => (
                                    <Row key={`headerTitle-${index}`}>
                                        <Col span={6}>
                                            <Tag style={{
                                                color: (text?.lang !== oldItemDef?.description[index]?.lang)?'red':null,
                                                fontWeight: (text?.lang !== oldItemDef?.description[index]?.lang)?'bold':null
                                            }}>
                                                {text?.lang||'Default'}
                                            </Tag>
                                        </Col>
                                        <Col span={18}>
                                            <pre style={{
                                                whiteSpace:'pre-wrap',
                                                color: (text?.value !== oldItemDef?.description[index]?.value)?'red':null,
                                                fontWeight: (text?.value !== oldItemDef?.description[index]?.value)?'bold':null
                                            }}>
                                                {text.value}
                                            </pre>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </div>
                    ) : (
                        <span className={classNames('', {
                            ['compare-changed font-weight-bold']: !_.isEqual(oldItemDef?.description, newItemDef?.description)
                        })}>N/A</span>
                    )
                }
            </div>

            {
                newItemDef?.valueListRef?.valueListOID != null && (
                    <>
                        <div className={'mb-1'}>
                            <Tag color={"gold"}>ValueListRef OID&nbsp;:&nbsp;
                                <span className={'font-weight-bold'}>{newItemDef?.valueListRef?.valueListOID}</span>
                            </Tag>
                        </div>
                        <ValueListDiff showDefaultProps={false} valueList={metaData.valueList.get(newItemDef.valueListRef.valueListOID)} />
                    </>
                )
            }
            {
                newItemDef?.codeListRef?.codeListOID != null && (
                    <>
                        <div className={'mb-1'}>
                            <Tag color={"gold"}>CodeListRef OID&nbsp;:&nbsp;
                                <span className={'font-weight-bold'}>{newItemDef?.codeListRef?.codeListOID}</span>
                            </Tag>
                        </div>
                        <CodeListDiff showDefaultProps={false} codeList={metaData.codeList.get(newItemDef.codeListRef.codeListOID)}/>
                    </>
                )
            }
            {(newItemDef?.valueListRef?.valueListOID == null && newItemDef?.codeListRef?.codeListOID == null) && (
                <Card>
                    <Input readOnly size={'small'} placeholder={newItemDef.dataType + (newItemDef.length == null ? '' : '(' + newItemDef.length + ')')}/>
                </Card>
            )}

        </div>
    )
}

export const CodeList = ({codeList, showDefaultProps = true}) => {
    return (
        <>
            {codeList != null &&
            <div className='font-size-12'>
                {
                    showDefaultProps &&
                    <>
                        <div>Name : {codeList.name}</div>
                        <div>DataType : {codeList.dataType}</div>
                        <div>SASFormatName : {codeList?.sasformatName}</div>
                    </>
                }
                <Card>
                    <CodeListItem codeList={codeList} designMode={true}/>
                </Card>
            </div>
            }
        </>
    )
}

export const CodeListDiff = ({oldCodeList, codeList, showDefaultProps = true}) => {
    return (
        <>
            {codeList != null &&
            <div className='font-size-12'>
                {
                    showDefaultProps &&
                    <>
                        {oldCodeList == null && <Tag color={"green"}>NEW</Tag>}
                        <div>Name : {codeList.name}</div>
                        <div>DataType : {codeList.dataType}</div>
                        <div>SASFormatName : {codeList?.sasformatName}</div>
                    </>
                }
                <Card>
                    <CodeListItem codeList={codeList} designMode={true}/>
                </Card>
            </div>
            }
        </>
    )
}

export const CodeListItem = ({codeList, selectedLanguage, designMode}) => {
    const translatedText = (decode) => {
        if(decode.translatedText == null) {
            return 'N/A';
        }

        const langIndex = decode.translatedText.findIndex(txt => txt.lang === selectedLanguage);
        return decode.translatedText[langIndex === -1 ? 0 : langIndex].value;
    }

    return (
        <div style={{fontSize:'12px !important'}}>
            {codeList.externalCodeList != null && (
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text"><i className={'fa fa-search'}></i></span>
                    </div>
                    <input type="text" style={{fontSize:'0.8rem'}} className="form-control" placeholder={`${codeList.name}`}/>
                </div>
            )}
            {codeList.codeListItem != null && (
                <div style={{fontSize:'12px !important'}}>
                    {codeList.codeListItem.map((codeListItem, index) =>
                        <div key={`chlog-codelist-${codeList.oid}-${index}`}>
                            <label className="vb__utils__control vb__utils__control__checkbox">
                                <input type={'checkbox'} disabled/>
                                <span className="vb__utils__control__indicator"></span>
                                <span>
                                    {designMode === true ? <sup style={{color:'red'}}>{codeListItem.codedValue}</sup> : ''}
                                    {translatedText(codeListItem.decode)}
                                </span>
                            </label>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export const ValueList = ({valueList, metaData, showDefaultProps = true}) => {
    return (
        <>
            {valueList != null &&
            <div style={{fontSize:'12px'}}>
                {
                    showDefaultProps &&
                    <div>
                        Name&nbsp;:&nbsp;
                        <span>
                            <span className={'new'}>{valueList?.name}</span>
                        </span>
                    </div>
                }
                <Card>
                    <ValueListItem valueList={valueList} metaData={metaData} designMode={true}/>
                </Card>
            </div>
            }
        </>
    )
}

export const ValueListDiff = ({before, valueList, metaData, showDefaultProps = true}) => {
    return (
        <>
            {valueList != null &&
            <div style={{fontSize:'12px'}}>
                {
                    showDefaultProps &&
                    <div>
                        Name&nbsp;:&nbsp;
                        <span className={classNames('', {
                            ['compare-changed font-weight-bold']: before?.name !== valueList?.name
                        })}><span className={'new'}>{valueList?.name}</span>
                        </span>
                    </div>
                }
                <Card>
                    <ValueListItem valueList={valueList} metaData={metaData} designMode={true}/>
                </Card>
            </div>
            }
        </>
    )
}

export const ValueListItem = ({valueList, metaData, designMode}) => {
    return (
        <div className='font-size-12'>
            {
                valueList?.itemRef != null && (
                    <div className='font-size-12'>
                        {valueList?.itemRef.map((itemRef, index) =>
                            <div key={`chlog-itemRef-${itemRef.oid}-${index}`}>
                                <Input readOnly placeholder={`${itemRef?.itemOID} / Mandatory : [${itemRef?.mandatory || 'NO'}]`}/>
                            </div>
                        )}
                    </div>
                )
            }
        </div>
    )
}

export const EditCheck = ({editCheck, referenceOIDs}) => {
    return (
        <>
            {editCheck != null &&
            <div className='font-size-12'>
                <div className={'mt-1 mb-1'}>
                    OID : <span>{editCheck.oid}</span>
                </div>
                <div className={'mt-1 mb-1'}>
                    Name : <span>{editCheck.name}</span>
                </div>
                <div className={'mt-1 mb-1'}>
                    Activate&nbsp;:&nbsp;
                    <Tag>{editCheck.activate ? 'ON' : 'OFF'}</Tag>
                </div>
                <div className={'mt-1 mb-1 font-size-12'}>
                    <div>Description :</div>
                    <Input.TextArea value={editCheck?.description} rows={3} readOnly={true}/>
                </div>
                <div className={'mt-1 mb-1 font-size-12'}>
                    <div>Expression :</div>
                    <Input.TextArea value={editCheck?.check?.formalExpression?.value} rows={5} readOnly={true} />
                </div>
                <div className={'mt-1 mb-1'}>
                    Type&nbsp;:&nbsp;
                    <Tag>{editCheck?.action?.type}</Tag>
                </div>
                {
                    //Condition - Reference OID 정보 출력
                    editCheck?.action?.type == 'CONDITION' &&
                    <div className={'mt-1 mb-1'}>
                        <div> Condition Reference OID :</div>
                        <PerfectScrollbar style={{border:'solid 1px #ced4da'}}>
                            <List size={'small'}
                                  rowKey={'referenceOID'}
                                  style={{maxHeight:'11rem'}}
                                  dataSource={referenceOIDs}
                                  renderItem={item => (
                                      <List.Item style={{borderBottom: 'solid 1px #ced4da'}}>
                                          <Typography.Text type={'success'}>{item}</Typography.Text>
                                      </List.Item>
                                  )}
                            />
                        </PerfectScrollbar>
                    </div>
                }
                {
                    //Method - Reference OID 정보 출력
                    editCheck?.action?.type == 'METHOD' &&
                    <div className={'mt-1 mb-1'}>
                        <div> Method Reference OID :</div>
                        <PerfectScrollbar style={{border:'solid 1px #ced4da'}}>
                            <List size={'small'}
                                  rowKey={'referenceOID'}
                                  style={{maxHeight:'11rem'}}
                                  dataSource={referenceOIDs}
                                  renderItem={item => (
                                      <List.Item style={{borderBottom: 'solid 1px #ced4da'}}>
                                          <Typography.Text type={'success'}>{item}</Typography.Text>
                                      </List.Item>
                                  )}
                            />
                        </PerfectScrollbar>
                    </div>
                }
                {
                    //System Query
                    editCheck?.action?.type == 'SYSTEM_QUERY' &&
                    <div className={'mt-1 mb-1'}>
                        <div> Query Target OID :</div>
                        <PerfectScrollbar style={{border:'solid 1px #ced4da'}}>
                            <List size={'small'}
                                  rowKey={'referenceOID'}
                                  style={{maxHeight:'11rem'}}
                                  dataSource={referenceOIDs}
                                  renderItem={item => (
                                      <List.Item style={{borderBottom: 'solid 1px #ced4da'}}>
                                          <Typography.Text type={'success'}>{item}</Typography.Text>
                                      </List.Item>
                                  )}
                            />
                        </PerfectScrollbar>
                    </div>
                }
                {
                    //Subject Status
                    editCheck?.action?.type == 'SUBJECT_STATUS' &&
                    <div className={'mt-1 mb-1'}>
                        <div> Subject Status OID :</div>
                        <PerfectScrollbar style={{border:'solid 1px #ced4da'}}>
                            <List size={'small'}
                                  rowKey={'referenceOID'}
                                  style={{maxHeight:'11rem'}}
                                  dataSource={referenceOIDs}
                                  renderItem={item => (
                                      <List.Item style={{borderBottom: 'solid 1px #ced4da'}}>
                                          <Typography.Text type={'success'}>{item}</Typography.Text>
                                      </List.Item>
                                  )}
                            />
                        </PerfectScrollbar>
                    </div>
                }
            </div>
            }
        </>
    );
}

export const EditCheckDiff = ({before, editCheck, beforeRefOIDs, afterRefOIDs}) => {
    return (
        <div className='font-size-12'>
            {before == null && <Tag color={"green"}>NEW</Tag>}
            <div className={'mt-1 mb-1'}>
                Name&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['compare-changed font-weight-bold']: before?.name !== editCheck?.name
                })}><span className={'new'}>{editCheck?.name}</span></span>
            </div>
            <div className={'mt-1 mb-1'}>
                Activate&nbsp;:&nbsp;
                <Tag>
                    <span className={classNames('', {
                        ['compare-changed font-weight-bold']: before?.activate !== editCheck?.activate
                    })}>{editCheck?.activate ? 'ON' : 'OFF'}</span>
                </Tag>
            </div>
            <div className={'mt-1 mb-1'}>
                <div>Description :</div>
                <Input.TextArea className={classNames('font-size-12', {
                    ['text-danger font-weight-bold']: before?.description !== editCheck?.description
                })} value={editCheck?.description} rows={3} readOnly={true}/>
            </div>
            <div className={'mt-1 mb-1'}>
                <div>Expression :</div>
                <Input.TextArea className={classNames('font-size-12', {
                    ['text-danger font-weight-bold']: before?.check?.formalExpression?.value !== editCheck?.check?.formalExpression?.value
                })} value={editCheck?.check?.formalExpression?.value} rows={5} readOnly={true}/>
            </div>
            <div className={'mt-1 mb-1'}>
                Type&nbsp;:&nbsp;
                <Tag>
                    <span className={classNames('', {
                        ['compare-changed font-weight-bold']: before?.action?.type !== editCheck?.action?.type
                    })}>{editCheck?.action?.type}</span>
                </Tag>
            </div>
            {
                //Condition - Reference OID 정보 출력
                editCheck?.action?.type == 'CONDITION' &&
                <div className={'mt-1 mb-1'}>
                    <div> Condition Reference OID :</div>
                    <PerfectScrollbar style={{border: 'solid 1px #ced4da'}}>
                        <List size={'small'}
                              rowKey={'referenceOID'}
                              style={{maxHeight: '11rem'}}
                              dataSource={afterRefOIDs}
                              renderItem={item => (
                                  <List.Item style={{borderBottom: 'solid 1px #ced4da'}}>
                                      <Typography.Text type={beforeRefOIDs.includes(item)?'success':'danger'}>{item}</Typography.Text>
                                  </List.Item>
                              )}
                        />
                    </PerfectScrollbar>
                </div>
            }
            {
                //Method - Reference OID 정보 출력
                editCheck?.action?.type == 'METHOD' &&
                <div className={'mt-1 mb-1'}>
                    <div> Method Reference OID :</div>
                    <PerfectScrollbar style={{border: 'solid 1px #ced4da'}}>
                        <List size={'small'}
                              rowKey={'referenceOID'}
                              style={{maxHeight: '11rem'}}
                              dataSource={afterRefOIDs}
                              renderItem={item => (
                                  <List.Item style={{borderBottom: 'solid 1px #ced4da'}}>
                                      <Typography.Text type={beforeRefOIDs.includes(item)?'success':'danger'}>{item}</Typography.Text>
                                  </List.Item>
                              )}
                        />
                    </PerfectScrollbar>
                </div>
            }
            {
                //System Query
                editCheck?.action?.type == 'SYSTEM_QUERY' &&
                <div className={'mt-1 mb-1'}>
                    <div> Query Target OID :</div>
                    <PerfectScrollbar style={{border: 'solid 1px #ced4da'}}>
                        <List size={'small'}
                              rowKey={'referenceOID'}
                              style={{maxHeight: '11rem'}}
                              dataSource={afterRefOIDs}
                              renderItem={item => (
                                  <List.Item style={{borderBottom: 'solid 1px #ced4da'}}>
                                      <Typography.Text type={beforeRefOIDs.includes(item)?'success':'danger'}>{item}</Typography.Text>
                                  </List.Item>
                              )}
                        />
                    </PerfectScrollbar>
                </div>
            }
            {
                //Subject Status
                editCheck?.action?.type == 'SUBJECT_STATUS' &&
                <div className={'mt-1 mb-1'}>
                    <div> Subject Status OID :</div>
                    <PerfectScrollbar style={{border: 'solid 1px #ced4da'}}>
                        <List size={'small'}
                              rowKey={'referenceOID'}
                              style={{maxHeight: '11rem'}}
                              dataSource={afterRefOIDs}
                              renderItem={item => (
                                  <List.Item style={{borderBottom: 'solid 1px #ced4da'}}>
                                      <Typography.Text type={beforeRefOIDs.includes(item)?'success':'danger'}>{item}</Typography.Text>
                                  </List.Item>
                              )}
                        />
                    </PerfectScrollbar>
                </div>
            }
        </div>
    );
}

export const SubjectStatus = ({subjectStatus}) => {
    return (
        <>
            {
                subjectStatus != null &&
                <div className='font-size-12'>
                    <div className={'mb-1'}>
                        Status&nbsp;:&nbsp;
                        <span><span className={'new'}>{subjectStatus?.status}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Display Order&nbsp;:&nbsp;
                        <span><span className={'new'}>{subjectStatus?.priority}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Color&nbsp;:&nbsp;
                        <span><span className={'new'}>{subjectStatus?.color}</span>
                        <div className={'ml-2'} style={{display:'inline-block', verticalAlign:'middle', width:'15px', height: '10px', backgroundColor:subjectStatus?.color}} />
                    </span>
                    </div>
                    <div className={'mb-1'}>
                        Disable the next visit&nbsp;:&nbsp;
                        <Tag><span>{subjectStatus?.disabledNextVisit ? 'ON' : 'OFF'}</span></Tag>
                    </div>
                    <div className={'mb-1'}>
                        Exclude Subject Count&nbsp;:&nbsp;
                        <Tag><span>{subjectStatus?.excludeSubjectCount ? 'ON' : 'OFF'}</span></Tag>
                    </div>
                </div>
            }
        </>
    );
}

export const SubjectStatusDiff = ({oldSubjectStatus, subjectStatus}) => {
    return (
        <div className='font-size-12'>
            {oldSubjectStatus == null && <Tag color={"green"}>NEW</Tag>}
            <div className='font-size-12'>
                <div className={'mb-1'}>
                    Status&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldSubjectStatus?.status !== subjectStatus?.status
                    })}><span className={'new'}>{subjectStatus?.status}</span></span>
                </div>
                <div className={'mb-1'}>
                    Display Order&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldSubjectStatus?.priority !== subjectStatus?.priority
                    })}><span className={'new'}>{subjectStatus?.priority}</span></span>
                </div>
                <div className={'mb-1'}>
                    Color&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldSubjectStatus?.color !== subjectStatus?.color
                    })}><span className={'new'}>{subjectStatus?.color}</span>
                        <div className={'ml-2'} style={{display:'inline-block', verticalAlign:'middle', width:'15px', height: '10px', backgroundColor:subjectStatus?.color}} />
                    </span>
                </div>
                <div className={'mb-1'}>
                    Disable the next visit&nbsp;:&nbsp;
                    <Tag>
                        <span className={classNames('', {
                            ['font-weight-bold compare-changed']: oldSubjectStatus?.disabledNextVisit !== subjectStatus?.disabledNextVisit
                        })}>{subjectStatus?.disabledNextVisit ? 'ON' : 'OFF'}</span>
                    </Tag>
                </div>
                <div className={'mb-1'}>
                    Exclude Subject Count&nbsp;:&nbsp;
                    <Tag>
                        <span className={classNames('', {
                            ['font-weight-bold compare-changed']: oldSubjectStatus?.excludeSubjectCount !== subjectStatus?.excludeSubjectCount
                        })}>{subjectStatus?.excludeSubjectCount ? 'ON' : 'OFF'}</span>
                    </Tag>
                </div>
            </div>
        </div>
    );
}

export const SAEMapDef = ({saeItemGroupDefs}) => {
    return (
        <>
            {
                saeItemGroupDefs != null &&
                <div className='font-size-12'>
                    <span>TEST</span>
                </div>
            }
        </>
    );
}

export const SAEMapDefDiff = ({oldSaeItemGroupDefs, saeItemGroupDefs}) => {
    return (
        <div className='font-size-12'>
            {oldSaeItemGroupDefs == null && <Tag color={"green"}>NEW</Tag>}
            <div className='font-size-12'>
                <Collapse>
                    {
                        saeItemGroupDefs.map(({itemGroupOID, sql, editable, dataSourceDefs}) => (
                          <Collapse.Panel key={itemGroupOID} header={itemGroupOID}>
                              <Row gutter={[10, 0]}>
                                  <Col span={24}>
                                      <div className={'d-flex'}>
                                          <h5 style={{flex:1}} >SQL</h5>
                                          <div>
                                              <span className={'mr-1'}>Editable:</span>
                                              <Switch checkedChildren={'Yes'} unCheckedChildren={'No'}
                                                      value={editable} disabled />
                                          </div>
                                      </div>
                                  </Col>
                                  <Col title={'SQL'} span={24} className='border border-info border-box p-2' style={{whiteSpace:'pre-line'}}>
                                      {sql}
                                  </Col>
                                  <Col className={'mt-2'} span={24}><h5>Data Sources</h5></Col>
                                  <Col title={'Data Sources'} span={24}>
                                      <List dataSource={dataSourceDefs} itemLayout="vertical"
                                            renderItem={({tableName, sql, nativeQuery, columnDefs}, index) => (
                                                <List.Item key={index}>
                                                    <Tag className={'font-size-21'} color={tableName === '_SYSTEM' ? 'geekblue' : 'default'}>
                                                        {tableName === '_SYSTEM'?'SYSTEM':tableName}
                                                    </Tag>
                                                    <List.Item.Meta title={'Columns'} description={
                                                        <div className={'border p-1'}>
                                                            {
                                                                columnDefs.map(({crfItemOID, type, saeItemOID, editable}) => (
                                                                    <div className={'font-weight-bold'}>
                                                                        <div>
                                                                            <Tag className={'mr-2'} color={type==='CONSTANT'?'geekblue':'default'} >{type}</Tag>
                                                                            <span className={'mr-1'}>Editable:</span>
                                                                            <Switch checkedChildren={'Yes'}
                                                                                    unCheckedChildren={'No'}
                                                                                    value={editable} disabled />
                                                                        </div>
                                                                        <span className={'mr-2'}>{crfItemOID}</span>
                                                                        <span className={'mr-2'}><i className='fa fa-arrow-right'></i></span>
                                                                        <span className={'mr-2'}>{saeItemOID}</span>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    }>
                                                    </List.Item.Meta>
                                                </List.Item>
                                            )}
                                      />
                                  </Col>
                              </Row>
                          </Collapse.Panel>
                        ))
                    }
                </Collapse>
            </div>
        </div>
    );
}

const generateNumber = ({length, leftPadWithZero, initialNumber, autoGenerated}) => {
    let strNumber = '';
    if(autoGenerated) {
        strNumber = `${initialNumber}`||'1';
        if(leftPadWithZero && length > strNumber?.length) {
            const repeat = length - strNumber?.length;
            for(let i=0; i<repeat; i++) { strNumber = '0' + strNumber; }
        }
    } else {
        if (length == 2) {
            strNumber = (leftPadWithZero ? '0' : '') + '1';
        } else if (length == 3) {
            strNumber = (leftPadWithZero ? '00' : '') +'1';
        } else if (length == 4) {
            strNumber = (leftPadWithZero ? '000' : '') + '1';
        } else if (length == 5) {
            strNumber = (leftPadWithZero ? '0000' : '') + '1';
        }
    }
    return strNumber;
};

export const ScreeningNumber = ({screeningNumber}) => {

    return (
        <>
            {
                screeningNumber != null &&
                <div className='font-size-12'>
                    <div className={'mt-1 mb-1'}>
                        Prefix&nbsp;:&nbsp;
                        <span><span className={'new'}>{screeningNumber?.prefix}</span></span>
                    </div>
                    <div className={'mt-1 mb-1'}>
                        Site Code&nbsp;:&nbsp;
                        <span><span className={'new'}>{screeningNumber?.siteCode?'USE':'NOT USE'}</span></span>
                    </div>
                    <div className={'mt-1 mb-1'}>
                        Suffix&nbsp;:&nbsp;
                        <span><span className={'new'}>{screeningNumber?.suffix}</span></span>
                    </div>
                    <div className={'mt-1 mb-1'}>
                        Number Length&nbsp;:&nbsp;
                        <span><span className={'new'}>{screeningNumber?.length}</span></span>
                    </div>
                    <div className={'mt-1 mb-1'}>
                        Auto generated&nbsp;:&nbsp;
                        <span><span className={'new'}>{screeningNumber?.autoGenerated?'ON':'OFF'}</span></span>
                    </div>
                    <div className={'mt-1 mb-1'}>
                        Left pad string with zeros&nbsp;:&nbsp;
                        <span><span className={'new'}>{screeningNumber?.leftPadWithZero?'ON':'OFF'}</span></span>
                    </div>
                    <Notice>
                        <h3>
                            <span className={'badge badge-info mr-1'}>
                                {screeningNumber?.prefix?.length > 5 ?
                                screeningNumber.prefix.substring(0, 5) : screeningNumber.prefix}
                            </span>
                            <span className={'badge badge-default mr-1'}>
                                  {screeningNumber?.siteCode === true ? '999' : ''}
                            </span>
                            <span className={'badge badge-info mr-1'}>
                                  {screeningNumber?.suffix?.length > 5 ?
                                  screeningNumber.suffix.substring(0, 5) : screeningNumber.suffix}
                            </span>
                            <span className={'badge badge-default mr-1'}>
                                  {
                                      generateNumber(screeningNumber)
                                  }
                            </span>
                        </h3>
                    </Notice>
                </div>
            }
        </>
    );
}

export const ScreeningNumberDiff = ({oldScreeningNumber, screeningNumber}) => {
    return (
        <div className='font-size-12'>
            {oldScreeningNumber == null && <Tag color={"green"}>NEW</Tag>}
            <div className='font-size-12'>
                <div className={'mt-1 mb-1'}>
                    Prefix&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldScreeningNumber?.prefix !== screeningNumber?.prefix
                    })}><span className={'new'}>{screeningNumber?.prefix}</span></span>
                </div>
                <div className={'mt-1 mb-1'}>
                    Site Code&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldScreeningNumber?.siteCode !== screeningNumber?.siteCode
                    })}><span className={'new'}>{screeningNumber?.siteCode?'USE':'NOT USE'}</span></span>
                </div>
                <div className={'mt-1 mb-1'}>
                    Suffix&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldScreeningNumber?.suffix !== screeningNumber?.suffix
                    })}><span className={'new'}>{screeningNumber?.suffix}</span></span>
                </div>
                <div className={'mt-1 mb-1'}>
                    Number Length&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldScreeningNumber?.length !== screeningNumber?.length
                    })}><span className={'new'}>{screeningNumber?.length}</span></span>
                </div>
                <div className={'mt-1 mb-1'}>
                    Auto generated&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldScreeningNumber?.autoGenerated !== screeningNumber?.autoGenerated
                    })}><span className={'new'}>{screeningNumber?.autoGenerated?'ON':'OFF'}</span></span>
                </div>
                <div className={'mt-1 mb-1'}>
                    Initial Number&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldScreeningNumber?.initialNumber !== screeningNumber?.initialNumber
                    })}><span className={'new'}>{screeningNumber?.initialNumber}</span></span>
                </div>
                <div className={'mt-1 mb-1'}>
                    Left pad string with zeros&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldScreeningNumber?.leftPadWithZero !== screeningNumber?.leftPadWithZero
                    })}><span className={'new'}>{screeningNumber?.leftPadWithZero?'ON':'OFF'}</span></span>
                </div>
                <Notice>
                    <h3>
                        <span className={'badge badge-info mr-1'}>
                            {screeningNumber?.prefix?.length > 5 ?
                            screeningNumber.prefix.substring(0, 5) : screeningNumber.prefix}
                        </span>
                        <span className={'badge badge-default mr-1'}>
                            {screeningNumber?.siteCode === true ? '999' : ''}
                        </span>
                        <span className={'badge badge-info mr-1'}>
                            {screeningNumber?.suffix?.length > 5 ?
                            screeningNumber.suffix.substring(0, 5) : screeningNumber.suffix}
                        </span>
                        <span className={'badge badge-default mr-1'}>
                        {
                            generateNumber(screeningNumber)
                        }
                        </span>
                    </h3>
                </Notice>
            </div>
        </div>
    );
}

export const RandomNumber = ({randomNumber}) => {

    return (
        <>
            {
                randomNumber != null &&
                <div className='font-size-12'>
                    <div className={'mt-1 mb-1'}>
                        Number(Seq) Length&nbsp;:&nbsp;
                        <span><span className={'new'}>{randomNumber?.length}</span></span>
                    </div>
                    <div className={'mt-1 mb-1'}>
                        Format&nbsp;:&nbsp;
                        <span><span className={'new'}>{randomNumber?.format}</span></span>
                    </div>
                    <div className={'mt-1 mb-1'}>
                        Left pad string with zeros&nbsp;:&nbsp;
                        <span><span className={'new'}>{randomNumber?.leftPadWithZero?'ON':'OFF'}</span></span>
                    </div>
                    <Notice>
                        <h3>
                            <span className={'badge badge-default mr-1'}>
                              {
                                  randomNumber?.format?.replace('${Seq}', generateNumber(randomNumber))
                              }
                            </span>
                        </h3>
                    </Notice>
                </div>
            }
        </>
    );
}

export const RandomNumberDiff = ({oldRandomNumber, randomNumber}) => {
    return (
        <div className='font-size-12'>
            {oldRandomNumber == null && <Tag color={"green"}>NEW</Tag>}
            <div className='font-size-12'>
                <div className={'mt-1 mb-1'}>
                    Number(Seq) Length&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldRandomNumber?.length !== randomNumber?.length
                    })}><span className={'new'}>{randomNumber?.length}</span></span>
                </div>
                <div className={'mt-1 mb-1'}>
                    Format&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldRandomNumber?.format !== randomNumber?.format
                    })}><span className={'new'}>{randomNumber?.format}</span></span>
                </div>
                <div className={'mt-1 mb-1'}>
                    Left pad string with zeros&nbsp;:&nbsp;
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldRandomNumber?.leftPadWithZero !== randomNumber?.leftPadWithZero
                    })}><span className={'new'}>{randomNumber?.leftPadWithZero?'ON':'OFF'}</span></span>
                </div>
                <Notice>
                    <h3>
                        <span className={'badge badge-success mr-1'}>
                        {
                            randomNumber?.format?.replace('${Seq}', generateNumber(randomNumber))
                        }
                        </span>
                    </h3>
                </Notice>
            </div>
        </div>
    );
}

const getFieldLabel = (fieldOID) => {
    if(fieldOID == null) {return 'N/A';}
    return `[${fieldOID?.studyEventOID}] > [${fieldOID?.formOID}] > [${fieldOID?.itemGroupOID}] > [${fieldOID?.itemOID}]`;
}

export const SubjectField = ({subjectField}) => {
    return (
        <>
            {
                subjectField != null &&
                <div className='font-size-12'>
                    <div className={'mb-1'}>
                        Subject Identifier&nbsp;:&nbsp;
                        <span><span className={'new'}>{getFieldLabel(subjectField?.subjectId)}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Consent Date&nbsp;:&nbsp;
                        <span><span className={'new'}>{getFieldLabel(subjectField?.consentDate)}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Initial&nbsp;:&nbsp;
                        <span><span className={'new'}>{getFieldLabel(subjectField?.initial)}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Birth Date&nbsp;:&nbsp;
                        <span><span className={'new'}>{getFieldLabel(subjectField?.birthDate)}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Sex&nbsp;:&nbsp;
                        <span><span className={'new'}>{getFieldLabel(subjectField?.sex)}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Age&nbsp;:&nbsp;
                        <span><span className={'new'}>{subjectField?.ageOID}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Visit Date&nbsp;:&nbsp;
                        <span><span className={'new'}>{getFieldLabel(subjectField?.visitDateOID)}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Random No&nbsp;:&nbsp;
                        <span><span className={'new'}>{subjectField?.randomNoOID}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        IP Code&nbsp;:&nbsp;
                        <span><span className={'new'}>{subjectField?.ipCodeOID}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Evaluations&nbsp;:&nbsp;
                        <span><span className={'new'}>{subjectField?.evaluationOIDs.join(", ")}</span></span>
                    </div>
                </div>
            }
        </>
    );
}

export const SubjectFieldDiff = ({oldSubjectField, subjectField, metaData}) => {
    return (
        <div className='font-size-12'>
            {oldSubjectField == null && <Tag color={"green"}>NEW</Tag>}
            <div className={'mb-1'}>
                Subject Identifier&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldSubjectField?.subjectId !== subjectField?.subjectId
                })}><span className={'new'}>{getFieldLabel(subjectField?.subjectId)}</span></span>
            </div>
            <div className={'mb-1'}>
                Consent Date&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldSubjectField?.consentDate !== subjectField?.consentDate
                })}><span className={'new'}>{getFieldLabel(subjectField?.consentDate)}</span></span>
            </div>
            <div className={'mb-1'}>
                Initial&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldSubjectField?.initial !== subjectField?.initial
                })}><span className={'new'}>{getFieldLabel(subjectField?.initial)}</span></span>
            </div>
            <div className={'mb-1'}>
                Birth Date&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldSubjectField?.birthDate !== subjectField?.birthDate
                })}><span className={'new'}>{getFieldLabel(subjectField?.birthDate)}</span></span>
            </div>
            <div className={'mb-1'}>
                Sex&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldSubjectField?.sex !== subjectField?.sex
                })}><span className={'new'}>{getFieldLabel(subjectField?.sex)}</span></span>
            </div>
            <div className={'mb-1'}>
                Age&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldSubjectField?.ageOID !== subjectField?.ageOID
                })}><span className={'new'}>{subjectField?.ageOID}</span></span>
            </div>
            <div className={'mb-1'}>
                Visit Date&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldSubjectField?.visitDateOID !== subjectField?.visitDateOID
                })}><span className={'new'}>{subjectField?.visitDateOID}</span></span>
            </div>
            <div className={'mb-1'}>
                Random No&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldSubjectField?.randomNoOID !== subjectField?.randomNoOID
                })}><span className={'new'}>{subjectField?.randomNoOID}</span></span>
            </div>
            <div className={'mb-1'}>
                IP Code&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldSubjectField?.ipCodeOID !== subjectField?.ipCodeOID
                })}><span className={'new'}>{subjectField?.ipCodeOID}</span></span>
            </div>
            <div className={'mb-1'}>
                Evaluations&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldSubjectField?.evaluationOIDs !== subjectField?.evaluationOIDs
                })}><span className={'new'}>{subjectField?.evaluationOIDs.join(", ")}</span></span>
            </div>
        </div>
    );
}

export const AdverseEvent = ({adverseEvent}) => {
    return (
        <>
            {
                adverseEvent != null &&
                <div className='font-size-12'>
                    <div className={'mb-1'}>
                        Adverse Event OID&nbsp;:&nbsp;
                        <span><span className={'new'}>{adverseEvent?.itemGroupOID}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Adverse Event Term&nbsp;:&nbsp;
                        <span><span className={'new'}>{adverseEvent?.aeTermItemOID}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Adverse Event Start Date&nbsp;:&nbsp;
                        <span><span className={'new'}>{adverseEvent?.aeStartDateOID}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        Adverse Event End Date&nbsp;:&nbsp;
                        <span><span className={'new'}>{adverseEvent?.aeEndDateOID}</span></span>
                    </div>
                    <div className={'mb-1'}>
                        SAE Condition&nbsp;:&nbsp;
                        <span><span className={'new'}>{adverseEvent?.saeCondition}</span></span>
                    </div>
                </div>
            }
        </>
    );
}

export const AdverseEventDiff = ({oldAdverseEvent, adverseEvent}) => {
    return (
        <div className='font-size-12'>
            {oldAdverseEvent == null && <Tag color={"green"}>NEW</Tag>}
            <div className={'mb-1'}>
                Adverse Event OID&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldAdverseEvent?.itemGroupOID !== adverseEvent?.itemGroupOID
                })}><span className={'new'}>{adverseEvent?.itemGroupOID}</span></span>
            </div>
            <div className={'mb-1'}>
                Adverse Event Term&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldAdverseEvent?.aeTermItemOID !== adverseEvent?.aeTermItemOID
                })}><span className={'new'}>{adverseEvent?.aeTermItemOID}</span></span>
            </div>
            <div className={'mb-1'}>
                Adverse Event Start Date&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldAdverseEvent?.aeStartDateOID !== adverseEvent?.aeStartDateOID
                })}><span className={'new'}>{adverseEvent?.aeStartDateOID}</span></span>
            </div>
            <div className={'mb-1'}>
                Adverse Event End Date&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldAdverseEvent?.aeEndDateOID !== adverseEvent?.aeEndDateOID
                })}><span className={'new'}>{adverseEvent?.aeEndDateOID}</span></span>
            </div>
            <div className={'mb-1'}>
                SAE Condition&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldAdverseEvent?.saeCondition !== adverseEvent?.saeCondition
                })}><span className={'new'}>{adverseEvent?.saeCondition}</span></span>
            </div>
        </div>
    );
}

export const ProSchedule = ({proSchedule}) => {
    return (
        <>
            {
                proSchedule != null &&
                <div className='font-size-12'>
                    ePRO Schedule&nbsp;:&nbsp;
                    <PerfectScrollbar style={{border: 'solid 1px #ced4da'}}>
                        {
                            proSchedule.length > 0?(
                                <List size={'small'}
                                      rowKey={'oid'}
                                      style={{maxHeight: '11rem'}}
                                      dataSource={proSchedule}
                                      renderItem={schedule => (
                                          <List.Item style={{borderBottom: 'solid 1px #ced4da'}}>
                                              <div>
                                                  OID&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.oid}</span></Tag>
                                              </div>
                                              <div>
                                                  StudyEventOID&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.studyEventOID}</span></Tag>
                                              </div>
                                              <div>
                                                  formOID&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.formOID}</span></Tag>
                                              </div>
                                              <div>
                                                  Baseline StudyEventOID&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.sourceStudyEventOID}</span></Tag>
                                              </div>
                                              <div>
                                                  time&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.time}</span></Tag>
                                              </div>
                                              <div>
                                                  delayDays&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.delayDays}</span></Tag>
                                              </div>
                                              <div>
                                                  interval&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.interval}</span></Tag>
                                              </div>
                                              <div>
                                                  numberOfTimes&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.numberOfTimes}</span></Tag>
                                              </div>
                                              <div>
                                                  preWindow&nbsp;:&nbsp;
                                                  <Tag><span>D[{schedule?.preWindow}], H:[{schedule?.preWindowTime}]</span></Tag>
                                              </div>
                                              <div>
                                                  usePreWindowAlarm&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.usePreWindowAlarm ? 'YES' : 'NO'}</span></Tag>
                                              </div>
                                              <div>
                                                  postWindow&nbsp;:&nbsp;
                                                  <Tag><span>D[{schedule?.postWindow}], H:[{schedule?.postWindowTime}]</span></Tag>
                                              </div>
                                              <div>
                                                  beforeEndTimeAlarms&nbsp;:&nbsp;
                                                  <Tag><span>[{(schedule?.endTimeAlarms??[]).join(",")}]</span></Tag>
                                              </div>
                                          </List.Item>
                                      )}
                                />
                            ) : (
                                <Notice>N/A</Notice>
                            )
                        }
                    </PerfectScrollbar>
                </div>
            }
        </>
    )
};

export const ProScheduleDiff = ({oldProSchedule, proSchedule}) => {

    const scheduleEquals = (old, current, index = null) => {
        if(old?.length === current?.length) {
            if(index != null) {
                return _.isEqual(old[index], current[index]);
            } else {
                let result = true;
                for(let i=0; i<old?.length; i++) {
                    result = result && _.isEqual(old[i], current[i]);
                }
                return result;
            }
        }
        return false;
    }

    return (
        <>
            <div className='font-size-12'>
                {oldProSchedule == null && <Tag color={"green"}>NEW</Tag>}
                ePRO Schedule&nbsp;:&nbsp;
                <PerfectScrollbar style={{border: 'solid 1px #ced4da'}}>
                    {
                        proSchedule.length > 0?(
                            <List size={'small'}
                                  rowKey={'oid'}
                                  style={{maxHeight: '11rem'}}
                                  dataSource={proSchedule}
                                  renderItem={(schedule, index) => (
                                      <List.Item extra={<span className='badge badge-dark font-size-16'>#{index+1}</span>}
                                                 style={{borderBottom: 'solid 1px #ced4da'}}>
                                          <div className={classNames('font-size-12', {
                                              ['font-weight-bold compare-changed']: !scheduleEquals(oldProSchedule, proSchedule, index)
                                          })}>
                                              <div>
                                                  OID&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.oid}</span></Tag>
                                              </div>
                                              <div>
                                                  StudyEventOID&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.studyEventOID}</span></Tag>
                                              </div>
                                              <div>
                                                  formOID&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.formOID}</span></Tag>
                                              </div>
                                              <div>
                                                  Baseline StudyEventOID&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.sourceStudyEventOID}</span></Tag>
                                              </div>
                                              <div>
                                                  time&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.time}</span></Tag>
                                              </div>
                                              <div>
                                                  delayDays&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.delayDays}</span></Tag>
                                              </div>
                                              <div>
                                                  interval&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.interval}</span></Tag>
                                              </div>
                                              <div>
                                                  numberOfTimes&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.numberOfTimes}</span></Tag>
                                              </div>
                                              <div>
                                                  preWindow&nbsp;:&nbsp;
                                                  <Tag><span>D[{schedule?.preWindow}], H:[{schedule?.preWindowTime}]</span></Tag>
                                              </div>
                                              <div>
                                                  usePreWindowAlarm&nbsp;:&nbsp;
                                                  <Tag><span>{schedule?.usePreWindowAlarm ? 'YES' : 'NO'}</span></Tag>
                                              </div>
                                              <div>
                                                  postWindow&nbsp;:&nbsp;
                                                  <Tag><span>D[{schedule?.postWindow}], H:[{schedule?.postWindowTime}]</span></Tag>
                                              </div>
                                              <div>
                                                  beforeEndTimeAlarms&nbsp;:&nbsp;
                                                  <Tag><span>[{(schedule?.endTimeAlarms??[]).join(",")}]</span></Tag>
                                              </div>
                                          </div>
                                      </List.Item>
                                  )}
                            />
                        ) : (
                            <Notice>N/A</Notice>
                        )
                    }
                </PerfectScrollbar>
            </div>
        </>
    )
};

export const VisitWindow = ({visitWindow}) => {
    return (
        <>
            {
                visitWindow != null &&
                <div className='font-size-12'>
                    <div className={'mt-1 mb-1'}>
                        OID&nbsp;:&nbsp;
                        <span><span className={'new'}>{visitWindow?.oid}</span></span>
                    </div>
                    <div className={'mt-1 mb-1'}>
                        Name&nbsp;:&nbsp;
                        <span><span className={'new'}>{visitWindow?.name}</span></span>
                    </div>
                    <div className={'mt-1 mb-1'}>
                        System Query&nbsp;:&nbsp;
                        <Tag>
                            <span>{visitWindow?.systemQuery?'ON':'OFF'}</span>
                        </Tag>
                    </div>
                    <div className={'mt-1 mb-1'}>
                        Entry Lock&nbsp;:&nbsp;
                        <Tag>
                            <span>{visitWindow?.entryLock?'ON':'OFF'}</span>
                        </Tag>
                    </div>
                    <div className={'mt-1 mb-1'}>
                        Baseline Visit OID&nbsp;:&nbsp;
                        <span><span className={'new'}>{visitWindow?.baselineVisitOID}</span></span>
                    </div>
                    <div className={'mt-1 mb-1'}>
                        <div> Duration Timing :</div>
                        <PerfectScrollbar style={{border: 'solid 1px #ced4da'}}>
                            {
                                visitWindow?.durationTimingConstraint?.length > 0?(
                                    <List size={'small'}
                                          rowKey={'duration'}
                                          style={{maxHeight: '11rem'}}
                                          dataSource={visitWindow?.durationTimingConstraint}
                                          renderItem={dtc => (
                                              <List.Item style={{borderBottom: 'solid 1px #ced4da'}}>
                                                  <div className={'font-size-12'}>
                                                      <div>
                                                          OID&nbsp;:&nbsp;
                                                          <Tag><span>{dtc?.oid}</span></Tag>
                                                      </div>
                                                      <div>
                                                          Name&nbsp;:&nbsp;
                                                          <Tag><span>{dtc?.name}</span></Tag>
                                                      </div>
                                                      <div>
                                                          Pre Window&nbsp;:&nbsp;
                                                          <Tag><span>{dtc?.preWindow}[{dtc?.preWindowUnit}]</span></Tag>
                                                      </div>
                                                      <div>
                                                          Target&nbsp;:&nbsp;
                                                          <Tag><span>{dtc?.target}[{dtc?.targetUnit}]</span></Tag>
                                                      </div>
                                                      <div>
                                                          Post Window&nbsp;:&nbsp;
                                                          <Tag><span>{dtc?.postWindow}[{dtc?.postWindowUnit}]</span></Tag>
                                                      </div>
                                                  </div>
                                              </List.Item>
                                          )}
                                    />
                                ):(
                                    <Notice>N/A</Notice>
                                )
                            }
                        </PerfectScrollbar>
                    </div>
                </div>
            }
        </>
    );
}

export const VisitWindowDiff = ({oldVisitWindow, visitWindow}) => {

    const durationTimingEquals = (old, current, index = null) => {

        if(old?.length === current?.length) {
            if(index != null) {
                return _.isEqual(old[index], current[index]);
            } else {
                let result = true;
                for(let i=0; i<old?.length; i++) {
                    result = result && _.isEqual(old[i], current[i]);
                }
                return result;
            }
        }
        return false;
    }

    return (
        <div className='font-size-12'>
            {oldVisitWindow == null && <Tag color={"green"}>NEW</Tag>}
            <div className={'mt-1 mb-1'}>
                OID&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldVisitWindow?.oid !== visitWindow?.oid
                })}><span className={'new'}>{visitWindow?.oid}</span></span>
            </div>
            <div className={'mt-1 mb-1'}>
                Name&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldVisitWindow?.name !== visitWindow?.name
                })}><span className={'new'}>{visitWindow?.name}</span></span>
            </div>
            <div className={'mt-1 mb-1'}>
                System Query&nbsp;:&nbsp;
                <Tag>
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldVisitWindow?.systemQuery !== visitWindow?.systemQuery
                    })}>{visitWindow?.systemQuery?'ON':'OFF'}</span>
                </Tag>
            </div>
            <div className={'mt-1 mb-1'}>
                Entry Lock&nbsp;:&nbsp;
                <Tag>
                    <span className={classNames('', {
                        ['font-weight-bold compare-changed']: oldVisitWindow?.entryLock !== visitWindow?.entryLock
                    })}>{visitWindow?.entryLock?'ON':'OFF'}</span>
                </Tag>
            </div>
            <div className={'mt-1 mb-1'}>
                Baseline Visit OID&nbsp;:&nbsp;
                <span className={classNames('', {
                    ['font-weight-bold compare-changed']: oldVisitWindow?.baselineVisitOID !== visitWindow?.baselineVisitOID
                })}><span className={'new'}>{visitWindow?.baselineVisitOID}</span></span>
            </div>
            <div className={'mt-1 mb-1'}>
                <div> Duration Timing :</div>
                <PerfectScrollbar style={{border: 'solid 1px #ced4da'}}>
                {
                    visitWindow?.durationTimingConstraint?.length > 0?(
                        <List size={'small'}
                              rowKey={'duration'}
                              style={{maxHeight: '11rem'}}
                              dataSource={visitWindow?.durationTimingConstraint}
                              renderItem={(dtc, index) => (
                                  <List.Item style={{borderBottom: 'solid 1px #ced4da'}}>
                                      <div className={classNames('font-size-12', {
                                          ['font-weight-bold compare-changed']: !durationTimingEquals(oldVisitWindow?.durationTimingConstraint, visitWindow?.durationTimingConstraint, index)
                                      })}>
                                          <div>
                                              OID&nbsp;:&nbsp;
                                              <Tag><span>{dtc?.oid}</span></Tag>
                                          </div>
                                          <div>
                                              Name&nbsp;:&nbsp;
                                              <Tag><span>{dtc?.name}</span></Tag>
                                          </div>
                                          <div>
                                              Pre Window&nbsp;:&nbsp;
                                              <Tag><span>{dtc?.preWindow}[{dtc?.preWindowUnit}]</span></Tag>
                                          </div>
                                          <div>
                                              Target&nbsp;:&nbsp;
                                              <Tag><span>{dtc?.target}[{dtc?.targetUnit}]</span></Tag>
                                          </div>
                                          <div>
                                              Post Window&nbsp;:&nbsp;
                                              <Tag><span>{dtc?.postWindow}[{dtc?.postWindowUnit}]</span></Tag>
                                          </div>
                                      </div>
                                  </List.Item>
                              )}
                        />
                    ):(
                        <Notice>N/A</Notice>
                    )
                }
                </PerfectScrollbar>
            </div>
        </div>
    );
}