import {Draggable, Droppable} from "react-beautiful-dnd";
import {
    Container, DescriptionWrapper,
    ItemGroupAddButtonBlock,
    ItemGroupDiv, ItemGroupDivider, ItemGroupTitle,
    ItemGroupTitleWrapper,
    Notice
} from "../../../../../container/build-tool/StyleBox";
import React from "react";
import {Button} from "antd";
import {descriptionText, headerTitleText} from "../../../../../container/build-tool/content/FormComponents";
import DefinitionBtn from "../../../../../container/build-tool/content/DefinitionBtn";
import LibraryItemGroupOption from "./LibraryItemGroupOption";
import LibraryItem from "./LibraryItem";
import {isEmpty} from "../../../../../lib/StringUtils";

const LibraryItemGroup = ({library, selectedLanguage, designMode,
                              formDef, setFormDef, itemRefMap, setItemRefMap,
                              setDefOpen, setDefType, setDefItem, setDefOption, dropEnable}) => {


    const onCreateItemGroup = () => {
        setDefOpen(true);
        setDefType('itemGroup');
        setDefItem(null);
        setDefOption({
            option: 'itemGroupDef',
            formOID: formDef?.oid,
        })
    }

    const isEmptyHeaderTitle = (headerTitle) => {
        return headerTitle == null || headerTitle?.translatedText == null || headerTitle?.translatedText.length <= 0
    }

    return (
        <>
            <Droppable key={formDef?.oid} droppableId={formDef?.oid} type="itemGroup">
                {
                    (provided, snapshot) => (
                        <Container key={formDef?.oid}
                                   {...provided.droppableProps}
                                   ref={provided.innerRef}
                                   designMode={designMode}
                                   isDraggingOver={snapshot.isDraggingOver}>
                            {
                                formDef?.itemGroupRef.length > 0 ?
                                    formDef.itemGroupRef.map((itemGroup, index) => {
                                        if(library.itemGroup.get(itemGroup.itemGroupOID) != null) {
                                            return (
                                                <Draggable
                                                    key={`${itemGroup.itemGroupOID}`}
                                                    draggableId={`${itemGroup.itemGroupOID}`}
                                                    index={index}>
                                                    { // Item 영역
                                                        (provided, snapshot) => (
                                                            <ItemGroupDiv
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps} //Draggable 옵션을 가져올 수 있게 설정 (Source, destination 등)
                                                                isDragging={snapshot.isDragging}
                                                                isDraggingOver={snapshot.isDraggingOver}
                                                                style={provided.draggableProps.style}>
                                                                <ItemGroupTitleWrapper key={`${itemGroup.itemGroupOID}`} {...provided.dragHandleProps}>
                                                                    <ItemGroupTitle>
                                                                        {
                                                                            designMode && (
                                                                                <DefinitionBtn
                                                                                    data={library.itemGroup.get(itemGroup.itemGroupOID)}
                                                                                    index={index} defType={"itemGroup"}
                                                                                    setDefVisible={setDefOpen}
                                                                                    setDefType={setDefType} setDefItem={setDefItem} />
                                                                            )
                                                                        }
                                                                        <h4 style={{fontSize: '1rem', fontWeight: 400}}>
                                                                            {itemGroup?.mandatory === 'YES' && (
                                                                                <sup style={{color: 'red'}} className={'mandatory'}>*</sup>
                                                                            )}
                                                                            {
                                                                                !isEmptyHeaderTitle(library.itemGroup.get(itemGroup.itemGroupOID).headerTitle)?
                                                                                    headerTitleText(library.itemGroup.get(itemGroup.itemGroupOID).headerTitle, selectedLanguage)
                                                                                    : library.itemGroup.get(itemGroup.itemGroupOID).name
                                                                            }
                                                                        </h4>
                                                                    </ItemGroupTitle>
                                                                    <LibraryItemGroupOption library={library} itemGroup={itemGroup}
                                                                                            formDef={formDef} setFormDef={setFormDef}
                                                                                            itemRefMap={itemRefMap} setItemRefMap={setItemRefMap} index={index} />
                                                                </ItemGroupTitleWrapper>
                                                                <DescriptionWrapper>
                                                                    {descriptionText({description:library.itemGroup.get(itemGroup.itemGroupOID).headerDescription, selectedLanguage:selectedLanguage})}
                                                                </DescriptionWrapper>
                                                                <ItemGroupDivider />
                                                                {/* ITEM 작업 필요  */}
                                                                <LibraryItem library={library} selectedLanguage={selectedLanguage}
                                                                             designMode={designMode} //Display Option
                                                                             itemGroup={library.itemGroup.get(itemGroup.itemGroupOID)}
                                                                             itemRefMap={itemRefMap} setItemRefMap={setItemRefMap}
                                                                             setDefOpen={setDefOpen} setDefType={setDefType}
                                                                             setDefItem={setDefItem} setDefOption={setDefOption}
                                                                             dropEnable={dropEnable} />
                                                                <DescriptionWrapper>
                                                                    {descriptionText({description:library.itemGroup.get(itemGroup.itemGroupOID).footerDescription, selectedLanguage:selectedLanguage})}
                                                                </DescriptionWrapper>
                                                            </ItemGroupDiv>
                                                        )
                                                    }
                                                </Draggable>
                                            )
                                        }
                                    }
                                ) : !snapshot.isUsingPlaceholder && (
                                    <Notice> Drop itemGroup here </Notice>
                                )
                            }
                            {provided.placeholder}
                        </Container>
                    )
                }
            </Droppable>
            {
                designMode &&
                <ItemGroupAddButtonBlock>
                    <Button type={"dashed"} className={'mr-2 mb-2'} onClick={onCreateItemGroup}>
                        <i className='fa fa-plus mr-2'/>Item Group
                    </Button>
                </ItemGroupAddButtonBlock>
            }
        </>
    )

}

export default LibraryItemGroup;