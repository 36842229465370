import styled from "styled-components";

export const CardContent = styled.div`
    min-height: 35rem;
    margin: 2rem;
`;

export const PrimaryCard = styled(CardContent).attrs({
    className:'card card-top card-top-primary'
})``;

export const PrimaryCenterCard =styled(PrimaryCard)`
    justify-content: center;
`;

export const NormalCard = styled(CardContent).attrs({
    className:'card'
})``;

export const NormalCenterCard = styled(NormalCard)`
    justify-content: center;
`

export const DefaultCard = styled(CardContent).attrs({
    className:'card card-top card-top-default'
})``;

export const DefaultCenterCard = styled(DefaultCard)`
    justify-content: center;
`;

export const CardHeader = styled.div.attrs({
    className:'card-header'
})``;

export const CardBody = styled.div.attrs({
    className:'card-body'
})``;

export const CardFooter = styled.div.attrs({
    className:'card-footer text-right'
})``;