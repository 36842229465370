import {Helmet} from "react-helmet-async";
import SponsorRoleList from "../../../../components/common/builder/sponsor/SponsorRoleList";

const SponsorRoleListPage = () => {

    return  (
        <>
            <Helmet title={"BUILDER :: Roles"} />
            <SponsorRoleList />
        </>
    )

}

export default SponsorRoleListPage;