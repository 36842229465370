import React, {useEffect, useRef, useState} from "react";
import {CardBody, CardHeader} from "../../../pages/PageStyleBox";
import {Button, Card, Checkbox, Col, Form, Input, Layout, Mentions, message, Radio, Row, Select, Space} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Table} from 'reactstrap';
import {getAllOID, listToMap, toMetaData} from "../../../lib/ODMUtils";
import axios from "axios";
import {httpStatus} from "../../../lib/createRequestSaga";
import {isEmpty} from "../../../lib/StringUtils";
import {downloadFile} from "../../../lib/FileUtils";
import _ from 'lodash';
import StratificationFactors from "./StratificationFactors";
import StratificationInfos from "./StratificationInfos";
import {metadataActions} from "../../../redux/module/metadata";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {errorHandle} from "../../../lib/BuilderUtils";
import classNames from "classnames";
import styles from "./style.module.scss";
import NormalLoading from "../NormalLoading";
import StudyStructureFooter from "./StudyStructureFooter";
import {useTranslation} from "react-i18next";

const {Footer} = Layout;

const StudyStructure = () => {

    const metaDataStore = useSelector(({metaDataStore}) => (metaDataStore));
    const metaData = toMetaData(metaDataStore.study);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const {t} = useTranslation();

    //Initial State
    const [loading, setLoading] = useState(true);
    const [studyStructure, setStudyStructure] = useState(null);
    const [randomizationType, setRandomizationType] = useState([]);
    const [randomizationRange, setRandomizationRange] = useState([]);
    const [codeBreakingMethod, setCodeBreakingMethod] = useState([]);
    const [blindType, setBlindType] = useState([]);
    const [useStratification, setUseStratification] = useState(false);
    const [expressionComparisonTypeOptions, setExpressionComparisonTypeOptions] = useState([]);
    const [expressionComparisonTypeMap, setExpressionComparisonTypeMap] = useState(new Map());
    const [oids, setOids] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [isParsed, setParsed] = useState(false);
    const [codeBreakOptions, setCodeBreakOptions] = useState([]);
    const [disableView, setDisableView] = useState(false);
    const [useIPManagement, setUseIPManagement] = useState(false);
    const [useVisit, setUseVisit] = useState(false);
    const [studyEvents, setStudyEvents] = useState([]);
    const [randomGroup, setRandomGroup] = useState([]);
    const [lpadWithZero, setLPadWithZero] = useState(false);
    const [reload, setReload] = useState(0);
    const [studySites, setStudySites] = useState(null);
    const [studySiteOptions, setStudySiteOptions] = useState(null);
    const [studySiteEnvOptions, setStudySiteEnvOptions] = useState(null);

    const pattern = /^([A-Za-z]+)([0-9]+)?(([_])?([A-Za-z0-9_]+))*$/i;
    const navigate = useNavigate();
    const stratificationRef = useRef(null);

    useEffect(() => {
        if(metaDataStore.loaded === true) {
            setRandomizationType(metaDataStore.randomizationType);
            setRandomizationRange(metaDataStore.randomizationRange);
            setCodeBreakingMethod(metaDataStore.codeBreakingMethod);
            setBlindType(metaDataStore?.blindType);
            setExpressionComparisonTypeOptions(metaDataStore.expressionComparisonType.map(ect => ({value: ect.key, label:ect.label, type:ect.expressionType})));
            const result = listToMap(metaDataStore?.expressionComparisonType, 'key');
            setExpressionComparisonTypeMap(result);
        }

        const mdv = metaDataStore.study.metaDataVersion[0];
        const studyStructure = mdv.protocol?.studyStructure;
        setUseIPManagement(metaDataStore.studyInfo?.useIPManagement);

        const studyEventOIDs = getStudyEventOIDs(false);

        const studyEventRef= studyEventOIDs.map(oid => {return {value:oid,label:metaData.studyEvent.get(oid).name}});
        const emptyRef = [{value: '', label: 'None'}];
        setStudyEvents([...emptyRef, ...studyEventRef]);

        setRandomGroup(studyStructure?.arms?.map(arm => {return {value:arm?.name, label:arm?.name}}));
        setUseVisit(studyStructure?.useVisit??false);

        //itemExpression 항목을 key값으로 전환
        const stratificationFactors = studyStructure?.stratification?.stratificationFactors??[];
        stratificationFactors.forEach(factor => {
            (factor?.itemExpressions??[]).forEach(itemExp => {
                //초기에 JSONShape.object 형태로 가지고 온 형태인 경우 key값으로 변환, 이후 변환동작 수행 하지 않음.
                if(typeof itemExp.expressionComparisonType !== 'string') {
                    itemExp.expressionComparisonType = itemExp.expressionComparisonType?.key;
                }
            });
        });
        const stratificationInfos = studyStructure?.stratification?.stratificationInfos??[];
        stratificationInfos.forEach(info => {
            (info?.itemExpressions??[]).forEach(itemExp => {
                //초기에 JSONShape.object 형태로 가지고 온 형태인 경우 key값으로 변환, 이후 변환동작 수행 하지 않음.
                if(typeof itemExp.expressionComparisonType !== 'string') {
                    itemExp.expressionComparisonType = itemExp.expressionComparisonType?.key;
                }
            });
        });

        const ipCodeDef = studyStructure?.ipCodeDef;
        setLPadWithZero(ipCodeDef?.leftPadWithZero??false);

        const studyStructureInit = {
            type: 'UPLOAD',
            range: 'CENTRAL',
            codeBreakingMethod: 'PI',
            blindType:'SINGLE',
            useStratification:true,
            arms: [{oid : '', name : '' }],
            codeBreakingList: [{
                codeBreakSite : '',
                codeBreakEnvironment : '',
                codeBreakEmail: []
            }],
            stratification: {
                stratificationFactors: [{itemOID : '', expressionType : '', itemExpressions:[]}],
                stratificationInfos: [],
            },
            ipList: [{visit : '', randomGroup : '', drugName : '', drugOid : '' }],
            ipCodeDef: {format : ipCodeDef?.format??'',
                length : ipCodeDef?.length??2,
                leftPadWithZero:ipCodeDef?.leftPadWithZero??false},
            ipName:''
        };

        let formStudyStructure;
        if(studyStructure != null){
            setLoaded(true);
            formStudyStructure = {
                ...studyStructure??{},
                arms: studyStructure?.arms??studyStructureInit.arms,
                codeBreakingList: studyStructure?.codeBreakingList??studyStructureInit.codeBreakingList,
                stratification: studyStructure?.stratification??(studyStructureInit.stratification),
                blindType:studyStructure?.blindType??studyStructureInit.blindType,
                useStratification: studyStructure?.useStratification??studyStructureInit.useStratification,
                ipList: studyStructure?.ipList??studyStructureInit.ipList,
                ipCodeDef: studyStructure?.ipCodeDef??studyStructureInit.ipCodeDef,
                ipName: studyStructure?.ipName??studyStructureInit.ipName
            }
        } else {
            formStudyStructure = {
                ...studyStructureInit
            }
        }

        setUseStratification( studyStructure?.useStratification??studyStructureInit.useStratification);

        //OID Check를 위한 값
        setOids(getAllOID(mdv));
        form.setFieldsValue(formStudyStructure);
        setStudyStructure(formStudyStructure);

        // study User option
        (async () => {
            try {
                const studyInfo = await axios.get(`/api/builder/studies/${params?.sid}/v/${params?.id}/protocol/study-structure`);
                if(studyInfo?.status === 200){
                    const viewOption = studyInfo?.data?.disableView;
                    setDisableView(viewOption);

                    const studySiteList = studyInfo?.data?.studySites;
                    setStudySites(studySiteList);

                    const siteOptions = studySiteList?.map(site => {
                        return {value: site?.oid, label: site?.name};
                    })

                    setStudySiteOptions(siteOptions);
                }

            } catch (e) {
                errorHandle(e, navigate, `/studies/${params.sid}/dashboard`);
            }
        })();

        setLoading(false);

    }, [reload]);

    const onChangeIPSetting = (changeValue) => {
        const cloneStudyStructure = {...studyStructure};
        const value = form.getFieldValue(changeValue);;

        let ipCodeDefValue = form.getFieldValue(["ipCodeDef", changeValue]);
        if(changeValue === 'format') {
            cloneStudyStructure.ipCodeDef.format = ipCodeDefValue;
        } else if(changeValue === 'length') {
            cloneStudyStructure.ipCodeDef.length = ipCodeDefValue;
        } else if(changeValue === 'leftPadWithZero') {
            cloneStudyStructure.ipCodeDef.leftPadWithZero = ipCodeDefValue;
            setLPadWithZero(ipCodeDefValue);
        }
        setStudyStructure(cloneStudyStructure);
    }
    const checkMention = async (_, value) => {
        if(isEmpty(value)){
            return Promise.reject(new Error(t('message.format.required.field')));
        }

        if(!isEmpty(value)){
            const seq = RegExp(/\${Seq}/g);
            const test = /[${} ]/g;

            if(!seq.test(value)){
                return Promise.reject(new Error(t('message.seq.required.field')));
            }
            const resultText = value.replaceAll(seq, '');

            if(test.test(resultText)){
                return Promise.reject(new Error(t('message.cannot.input.outside.specified.format')));
            }
        }

    };

    const generateNumber = ({length, leftPadWithZero, initialNumber, autoGenerated}) => {
        let strNumber = '';

        if(autoGenerated) {
            strNumber = `${initialNumber}`;
            if(leftPadWithZero && length > strNumber?.length) {
                const repeat = length - strNumber?.length;
                for(let i=0; i<repeat; i++) { strNumber = '0' + strNumber; }
            }
        } else {
            if (length == 2) {
                strNumber = (leftPadWithZero ? '0' : '') + '1';
            } else if (length == 3) {
                strNumber = (leftPadWithZero ? '00' : '') +'1';
            } else if (length == 4) {
                strNumber = (leftPadWithZero ? '000' : '') + '1';
            } else if (length == 5) {
                strNumber = (leftPadWithZero ? '0000' : '') + '1';
            }
        }
        return strNumber;
    };

    const IDCodePreview = () => {

        const ipCodeDef = studyStructure?.ipCodeDef;
        const seq = RegExp(/\${Seq}/g);
        let format = '';
        if(seq.test(ipCodeDef?.format)){
            format = ipCodeDef?.format.replace(seq, generateNumber(ipCodeDef));
        }
        return(
            <>
                <h3>
                    <span className={'badge badge-success mr-1'}>{format}</span>
                </h3>
            </>
        )
    };

    const getStudyEventOIDs = (appendPrefix = true) => {
        let studyEventOIDs = [];
        const prefix = 'VW.';
        metaDataStore.study.metaDataVersion[0].protocol.studyEventGroupRef.forEach(g => {
            let oids = metaData.studyEventGroup.get(g.studyEventGroupOID).studyEventRef.filter(s => metaData.studyEvent.get(s.studyEventOID)?.type === 'SCHEDULED').map(s => appendPrefix ? `${prefix}${s.studyEventOID}` : s.studyEventOID);
            if(oids.length > 0) {
                studyEventOIDs = studyEventOIDs.concat(oids);
            }
        });

        return studyEventOIDs;
    }

    const onFinish = (values) =>{
        setLoaded(true);

        if(isParsed) {
            message.warning(t('message.pls.proceed.parsing.factor.item'));
            return;
        }

        values.params = params;

        const metaDataStudyStructure = metaData?.studyStructure;

        const dataStudyStructure = {
            ...metaDataStudyStructure,
            stratification: values?.useStratification === false ? null : metaDataStudyStructure?.stratification
        }

        const data = {
            ...dataStudyStructure,
            ...values
        }

        dispatch(metadataActions.updateStudyStructure(data));
    }

    const onFinishFailed = (e) =>{
        console.log(e);
    }

    /**
     * Download Method
     * @returns {Promise<void>}
     */
    const onDownload = async() => {
        const studyOID = metaData?.studyOID;

        // 마지막 저장 상태의 range를 가지고 와야 함.
        const range = metaDataStore.study.metaDataVersion[0]?.protocol?.studyStructure?.range;
        const useStratification = metaDataStore.study.metaDataVersion[0]?.protocol?.studyStructure?.useStratification;

        let codes = form.getFieldValue(["stratification", "stratificationInfos"])?.map(info=>info?.code)?.join(",");
        let labels = form.getFieldValue(["stratification", "stratificationInfos"])?.map(info=>info?.label)?.join(",");
        let arms = form.getFieldValue(["arms"])?.map(arm=>arm?.name)?.join(",");


        try{
            const {headers, status, data} = await axios({
                url: `/api/builder/studies/${studyOID}/versions/download-stratifications`,
                responseType: 'blob',
                params : {
                    randomizationRange : range,
                    codes : JSON.parse(JSON.stringify(codes)),
                    labels : JSON.parse(JSON.stringify(labels)),
                    arms :  JSON.parse(JSON.stringify(arms)),
                    useStratification : useStratification
                },
                method:'get'
            });

            if(status === httpStatus.ok) {
                downloadFile(headers, data);
                navigate(location.pathname);
            }

        } catch(e) {
            if (e?.response?.data?.message != null) {
                message.error(e?.response?.data.message);
            } else {
                message.error(e?.message);
            }
        }
    }

    //ARM Methods ==========================================================
    const contains = (val) => {
        if(val===undefined) return true;
        const checkOid = val.toUpperCase();
        const returnValue = !oids.includes(checkOid);

        if(!returnValue) {
            //해당 값이 현재, arms에 이미 저장된 값인지 확인 - 맞으면 통과 처리
            if(metaData.arms.has(checkOid)) {
                return true;
            }
            return checkOid + t('container.is.already.in.use');
        }
        return returnValue;
    }

    const armInitialValue = {oid : null, name : null };
    const armListItemAppend = () => {
        const cloneStudyStructure = _.cloneDeep(form.getFieldsValue());
        const cloneArmListItem = cloneStudyStructure.arms;
        cloneArmListItem.push({...armInitialValue});

        form.setFieldsValue({arms : cloneArmListItem});
        setStudyStructure(cloneStudyStructure);
    }

    const armListItemRemove = (index) => {
        const cloneStudyStructure = _.cloneDeep(form.getFieldsValue());
        const cloneArmListItem = cloneStudyStructure.arms;

        if(cloneArmListItem.length > 1) {
            cloneArmListItem.splice(index, 1);
            form.setFieldsValue({arms:cloneArmListItem});
            setStudyStructure(cloneStudyStructure);
        }
    }
    //ARM Methods ==========================================================

    //Cartesian Product
    const f = (a, b) => [].concat(...a.map(d => b.map(e => [].concat(d, e))));
    const cartesian = (a, b, ...c) => (b ? cartesian(f(a, b), ...c) : a);
    const parsingInfo = (list) => {
        if(list.length === 0) {return null; }
        if(list.length === 1) {return [].concat(...list);}
        else { return cartesian(...list); }
    }

    //Factor to Info Parsing
    const parsingFactorToInfo = () => {
        /**
         * click 하면 Factor 정보를 Info로 parsing해주는 동작 추가
         */

            //Validate Factor
        const currentStudyStructure = _.cloneDeep(form.getFieldsValue());
        const factorInfos = currentStudyStructure?.stratification?.stratificationFactors;

        const exps = parsingInfo(factorInfos.map((factor) => factor.itemExpressions));
        const stratificationInfos = exps.map((exp, index) => {
            //exp 항목이 배열이 아닌, 객체인 경우 배열화 시킴.
            let itemExps;
            if(typeof exp === 'object') { itemExps = [].concat(exp); }
            else { itemExps = exp; }

            // 자릿수가 2자리 이상일 경우 left pad With zero 적용
            const codeLength = exps?.length?.toString()?.length;
            const stratificationCode = index+1;
            const stratificationCodeZeroFill = stratificationCode?.toString()?.padStart(codeLength, '0');

            return {itemExpressions: itemExps, label:null, code: stratificationCodeZeroFill}
        })

        //currentStudyStructure에 현재 설정된 Infos 값 설정
        currentStudyStructure.stratification.stratificationInfos = stratificationInfos;

        //form 항목 및 studyStructure State 업데이트
        form.setFieldsValue({stratification:{stratificationInfos}});
        setStudyStructure(currentStudyStructure);

        //Parsed 설정 false로 변경
        setParsed(false);
    }

    // Code Break 권한 설정
    const onChangeCodeBreak = (e) => {
        const cloneStudyStructure = {...studyStructure};
        cloneStudyStructure.codeBreakingMethod = form.getFieldValue('codeBreakingMethod');
        setStudyStructure(cloneStudyStructure);
    }

    // Blind 방식 설정
    const onChangeBlindType = (e) => {
        const cloneStudyStructure = {...studyStructure};
        const value = form.getFieldValue('blindType');
        cloneStudyStructure.blindType = value;
        if('OPENLABEL' === value){
            cloneStudyStructure.codeBreakingMethod = null;
        }
        setStudyStructure(cloneStudyStructure);
    }

    const onChangeUseStratification = (e) => {
        const checked = e.target.checked;
        setUseStratification(checked);

        const studyStructureInit = {
            stratification: {
                stratificationFactors: [{itemOID : '', expressionType : '', itemExpressions:[]}],
                stratificationInfos: [],
            }
        };

        if(checked){
            stratificationRef?.current?.scrollIntoView({behavior: "smooth"});
        }

        const formStudyStructure = {
            ...studyStructure??{},
            stratification: !checked ? null : studyStructure?.stratification??(studyStructureInit.stratification),
            useStratification: checked
        }
        form.setFieldsValue(formStudyStructure);
        setStudyStructure(formStudyStructure);
    };

    // Code Break List Set
    const codeBreakInitialValue = {codeBreakEnvironment : null, codeBreakEmail : [] };
    const codeBreakListAppend = () => {
        const cloneStudyStructure = _.cloneDeep(form.getFieldsValue());
        const cloneCodeBreakingList= cloneStudyStructure.codeBreakingList;
        cloneCodeBreakingList.push({...codeBreakInitialValue});

        form.setFieldsValue({codeBreakingList : cloneCodeBreakingList});
        setStudyStructure(cloneStudyStructure);
    }

    const codeBreakListRemove = (index) => {
        const cloneStudyStructure = _.cloneDeep(form.getFieldsValue());
        const cloneCodeBreakingList= cloneStudyStructure.codeBreakingList;

        if(cloneCodeBreakingList.length > 1) {
            cloneCodeBreakingList.splice(index, 1);
            form.setFieldsValue({codeBreakingList : cloneCodeBreakingList});
            setStudyStructure(cloneStudyStructure);
        }
    }

    const onChangeCodeBreakList = (value, index, name) => {

        if(name === 'codeBreakSite' ){
            onChangeCodeBreakSite(value);
        }

        const cloneStudyStructure = _.cloneDeep(form.getFieldsValue());
        const cloneCodeBreakList = cloneStudyStructure.codeBreakingList;
        form.setFieldsValue({codeBreakList:cloneCodeBreakList});
        setStudyStructure(cloneStudyStructure);

        const codeBreakData = form.getFieldValue('codeBreakList')[index];
        const env = codeBreakData?.codeBreakEnvironment;
        const siteOid = codeBreakData?.codeBreakSite;

        if(name !== 'codeBreakEmail' && env !== null && env !== "" &&  siteOid !== null && siteOid !== ""  ) {
            (async () => {
                try {
                    const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/stratification/studyUser`, {params:{env:env, siteOid:siteOid}});
                    if(status === httpStatus.ok) {
                        //set Data
                        const studyUsers = data?.studyUsers?.map(studyUser => {
                            return {value : studyUser?.user?.username, label: studyUser?.user?.username};
                        });

                        setCodeBreakOptions(studyUsers);
                        const idx = index;
                        const cloneStudyStructure = _.cloneDeep(form.getFieldsValue());
                        const cloneCodeBreakingList = cloneStudyStructure.codeBreakingList[idx];
                        const newCodeBreakingList = {
                            codeBreakSite : cloneCodeBreakingList?.codeBreakSite,
                            codeBreakEnvironment :  cloneCodeBreakingList?.codeBreakEnvironment
                        }
                        cloneStudyStructure.codeBreakingList[idx] = newCodeBreakingList;

                        form.setFieldsValue(cloneStudyStructure);
                        setStudyStructure(cloneStudyStructure);
                    }
                } catch (e) {
                    errorHandle(e, navigate, `/studies/${params.sid}/dashboard`);
                }
            })();
        }
    }

    const onClickRandomGroup = () => {
        const arms = form.getFieldValue('arms');
        setRandomGroup(arms?.map(arm => {return {value:arm?.name, label:arm?.name}}));
    }

    const onChangeCodeBreakSite = (value) => {
        const selectedSite = studySites?.find(site => site?.oid === value);
        const envs = selectedSite?.envs?.map(env => {return {value: env?.key, label:env?.key}})
        setStudySiteEnvOptions(envs);
    }

    return (
        <Layout className={classNames('card', {[`${styles.blockingArea}`]: disableView})}>
            <CardHeader>
                <h3>Study Structure</h3>
            </CardHeader>
            <CardBody>
                {
                    loading?(
                        <NormalLoading />
                    ):(
                        <Form
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            form={form}
                            style={{position:"relative"}}
                        >
                            <Row gutter={[20, 0]}>
                                <Col span={8}>
                                    <Card title={'Random'} className={'mt-3 mb-3'}>
                                        <Row gutter={[20, 0]}>
                                            <Col span={12}>
                                                <Form.Item name={'type'} label={t('column.randomization.method')} required
                                                           rules={[{
                                                               required: true,
                                                               message: t('message.pls.select.randomization.method')
                                                           }]}>
                                                    <Radio.Group>
                                                        <Space direction="horizontal">
                                                            {
                                                                Object.keys(randomizationType).map((type) => (
                                                                    <Radio key={type} value={type}
                                                                           disabled={type === 'GENERATION'}>{randomizationType[type]}</Radio>
                                                                ))
                                                            }
                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name={'range'} label={t('column.randomization.range')} required
                                                           rules={[{
                                                               required: true,
                                                               message: t('message.pls.select.randomization.range')
                                                           }]}>
                                                    <Radio.Group>
                                                        <Space direction="horizontal">
                                                            {
                                                                Object.keys(randomizationRange).map((range) => (
                                                                    <Radio key={range} value={range}>{randomizationRange[range]}</Radio>
                                                                ))
                                                            }
                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[20, 0]}>
                                            <Col span={12}>
                                                {
                                                     (studyStructure?.blindType === 'DOUBLE') && (
                                                        <Form.Item name={'codeBreakingMethod'} label={t('column.code.breaking')}
                                                                   rules={[{
                                                                       message: t('message.pls.select.code.breaking')
                                                                   }]}>
                                                            <Radio.Group onChange={onChangeCodeBreak}>
                                                                {
                                                                    Object.keys(codeBreakingMethod).map((method) => (
                                                                        <Radio key={method} value={method}>{codeBreakingMethod[method]}</Radio>
                                                                    ))
                                                                }
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    )
                                                }
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name={'blindType'} label={t('column.blinding.method')} required
                                                           rules={[{
                                                               required: true,
                                                               message: t('message.pls.select.blind.method')
                                                           }]}>
                                                    <Radio.Group onChange={onChangeBlindType} >
                                                        <Space direction="vertical">
                                                            {
                                                                Object.keys(blindType).map((type) => (
                                                                    <Radio key={type} value={type}>{blindType[type]}</Radio>
                                                                ))
                                                            }
                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {(studyStructure?.codeBreakingMethod !== 'PI' && studyStructure?.blindType === 'DOUBLE' ) && (
                                                    <Card title ={<h6>{t('column.code.breaking.email.recipient.setting')}</h6>} className={'mt-3 mb-3'}
                                                          extra={<Button type={'primary'} className='width-100p' onClick={codeBreakListAppend}>
                                                              <i className='fa fa-plus mr-2'/> Add </Button>}>
                                                        <Form.List name={["codeBreakingList"]}>
                                                            {(fields) => (
                                                                <>
                                                                    {fields.map((field, index) => (
                                                                        <Row key={`codeBreakingList-${index}`}
                                                                             gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                                                            <Col>
                                                                                <Form.Item name={[index, "codeBreakSite"]} label={'Site'}
                                                                                           rules={[{
                                                                                                required: studyStructure?.codeBreakingMethod !== 'PI',
                                                                                                message: t('message.pls.select.code.breaking.site')
                                                                                            }]}>
                                                                                    <Select
                                                                                        style={{minWidth: 120}}
                                                                                        options={studySiteOptions}
                                                                                        onChange={(value) => onChangeCodeBreakList(value, index,'codeBreakSite')} />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Item name={[index, "codeBreakEnvironment"]}
                                                                                           label={'Environment'}
                                                                                           rules={[{
                                                                                               required: studyStructure?.codeBreakingMethod !== 'PI',
                                                                                               message: t('message.pls.select.code.breaking.env')
                                                                                           }]}>
                                                                                    <Select placeholder={"Environment"}
                                                                                            style={{minWidth: 100}}
                                                                                            options={studySiteEnvOptions}
                                                                                            onChange={(value)=>onChangeCodeBreakList(value, index,'codeBreakEnvironment')}>
                                                                                    </Select>
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col span={11}>
                                                                                <Form.Item name={[index, "codeBreakEmail"]}
                                                                                           label={t('column.email.recipient')}
                                                                                           rules={[{
                                                                                               required: studyStructure?.codeBreakingMethod !== 'PI',
                                                                                               message: t('message.pls.select.email.recipient')
                                                                                           }]}>
                                                                                    <Select mode="multiple"
                                                                                            placeholder={"Code Break Email"}
                                                                                            onChange={(value)=>onChangeCodeBreakList(value, index,'codeBreakEmail')}
                                                                                            options={codeBreakOptions}/>
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Item label={' '}>
                                                                                    <Button onClick={() => codeBreakListRemove(index)}
                                                                                            disabled={fields.length <= 1}>
                                                                                        <i className='fa fa-trash-o'/></Button>
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>

                                                                    ))}
                                                                </>
                                                            )}
                                                        </Form.List>
                                                    </Card>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Item name={'useStratification'}>
                                                    <Checkbox onChange={onChangeUseStratification} checked={useStratification}>Use Stratification</Checkbox>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={16}>
                                    <Card title={'ARM'}
                                          className={'mt-3 mb-3'}
                                          extra={<Button type={'primary'} className='width-100p'
                                                         onClick={armListItemAppend}>
                                              <i className='fa fa-plus mr-2'/> Add </Button>}>
                                        <Table bordered style={{align: 'center'}}>
                                            <thead>
                                            <tr>
                                                <th>ARM OID</th>
                                                <th>Label</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <Form.List name={["arms"]}>
                                                {(fields) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <tr key={`arm-${index}`}>
                                                                <td>
                                                                    <Form.Item
                                                                        name={[index, "oid"]}
                                                                        rules={[
                                                                            {required: true, message: t('message.pls.enter.oid')},
                                                                            {pattern: pattern, message: t('message.format.is.not.valid')},
                                                                            ({getFieldValue}) => ({
                                                                                validator(_, value) {
                                                                                    //Arms의 입력값 확인
                                                                                    const arms = form.getFieldValue("arms");

                                                                                    //CASE 1.Value가 빈칸이면
                                                                                    if (isEmpty(value)) {
                                                                                        return Promise.resolve();
                                                                                    }
                                                                                    //CASE 2.OID 중복 체크 / 현재 ARM에 저장된 OID는 예외처리함
                                                                                    const result = contains(value);
                                                                                    if (result !== true) {
                                                                                        return Promise.reject(new Error(result));
                                                                                    }
                                                                                    //arm내에서 동일한 oid를 2개 이상 사용한 경우
                                                                                    else if (arms.filter(arm => (arm?.oid||"").toUpperCase() === value).length >= 2) {
                                                                                        return Promise.reject(new Error(t('message.same.oid.entered.within.arm.item')));
                                                                                    } else {
                                                                                        return Promise.resolve();
                                                                                    }
                                                                                }
                                                                            })
                                                                        ]}
                                                                    >
                                                                        <Input onInput={e => e.target.value = e.target.value.toUpperCase()}/>
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item
                                                                        name={[index, "name"]}
                                                                        rules={[{
                                                                            required: true,
                                                                            message: t('message.pls.enter.label')
                                                                        }]}
                                                                    >
                                                                        <Input/>
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Button onClick={() => armListItemRemove(index)}
                                                                            disabled={fields.length <= 1}><i
                                                                        className='fa fa-trash-o'/></Button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}
                                            </Form.List>
                                            </tbody>
                                        </Table>
                                    </Card>
                                </Col>
                            </Row>
                            <div ref={stratificationRef}>
                                {
                                    useStratification && (
                                        <Row>
                                            <Col span={24}>
                                                <Card title={'Stratification'}
                                                      className={'mt-3 mb-3'}>
                                                    <Row gutter={[20, 0]}>
                                                        <Col span={12}>
                                                            {/* StratificationFactor */}
                                                            <StratificationFactors form={form} metaData={metaData}
                                                                                   isParsed={isParsed} setParsed={setParsed}
                                                                                   studyStructure={studyStructure}
                                                                                   setStudyStructure={setStudyStructure}
                                                                                   expressionComparisonTypeOptions={expressionComparisonTypeOptions}
                                                                                   expressionComparisonTypeMap={expressionComparisonTypeMap}
                                                                                   parsingFactorToInfo={parsingFactorToInfo}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <StratificationInfos form={form} studyStructure={studyStructure}
                                                                                 metaData={metaData}
                                                                                 expressionComparisonTypeMap={expressionComparisonTypeMap}/>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </div>
                            {
                                useIPManagement &&
                                <Row gutter={[20, 0]}>
                                    <Col>
                                        <Card title={'IP Code Format'}
                                              className={'mt-3 mb-3'}>
                                            <Row>
                                                <Col>
                                                    <Form.Item name={'ipName'}
                                                               label={'IP Name'}
                                                               rules={[{ required:useIPManagement, message: t('message.required.item')}]}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[20, 0]}>
                                                <Col>
                                                    <Form.Item name={["ipCodeDef", "format"]}
                                                               label={'IP Code Format'}
                                                               required={useIPManagement}
                                                               rules={[{validator: checkMention,},]}
                                                    >
                                                        <Mentions
                                                            placeholder="IP Code Format"
                                                            prefix={'$'}
                                                            split={''}
                                                            onChange={() => onChangeIPSetting('format')}
                                                            options={[
                                                                {
                                                                    value: '{Seq}',
                                                                    label: '{Seq}',
                                                                }
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item name={['ipCodeDef', 'length']} label={'Sequence Length'}
                                                               rules={[{ required:useIPManagement && lpadWithZero, message: t('message.required.item')}]}>
                                                        <Select onChange={() => onChangeIPSetting('length')}>
                                                            <Select.Option key={0} value={""} >...</Select.Option>
                                                            <Select.Option key={2} value={2}>2</Select.Option>
                                                            <Select.Option key={3} value={3}>3</Select.Option>
                                                            <Select.Option key={4} value={4}>4</Select.Option>
                                                            <Select.Option key={5} value={5}>5</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item className='ml-3' name={["ipCodeDef", "leftPadWithZero"]} valuePropName={'checked'} label={' '}>
                                                        <Checkbox onChange={() => onChangeIPSetting('leftPadWithZero')}>Left pad string with zeros</Checkbox>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <IDCodePreview />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    {/* 240513_CYJ :: IP Table 삭제 - DM팀 요청 */}
                                    {/*<Col span={15}>*/}
                                    {/*    <Card title={'IP'}*/}
                                    {/*          className={'mt-3 mb-3'}*/}
                                    {/*    >*/}
                                    {/*        <Table bordered style={{align: 'center'}}>*/}
                                    {/*            <thead>*/}
                                    {/*            <tr>*/}
                                    {/*                <th>Visit</th>*/}
                                    {/*                <th>Random Group</th>*/}
                                    {/*                <th>Drug Name</th>*/}
                                    {/*                <th>Drug OID</th>*/}
                                    {/*                <th>Actions</th>*/}
                                    {/*            </tr>*/}
                                    {/*            </thead>*/}
                                    {/*            <tbody>*/}
                                    {/*            <Form.List name={["ipList"]}>*/}
                                    {/*                {(fields, {add, remove}) => (*/}
                                    {/*                    <>*/}
                                    {/*                        {fields.map((field, index) => (*/}
                                    {/*                            <tr key={`ip-${index}`}>*/}
                                    {/*                                <td>*/}
                                    {/*                                    <Form.Item*/}
                                    {/*                                        name={[index, "visit"]}*/}
                                    {/*                                        rules={[*/}

                                    {/*                                            ({getFieldValue}) => ({*/}
                                    {/*                                                validator(_, value) {*/}

                                    {/*                                                    const visitList = form.getFieldValue('ipList')?.map(ip => ip?.visit);*/}

                                    {/*                                                    if(visitList?.length > 0 && !visitList?.includes('')){*/}

                                    {/*                                                        if (visitList.filter(visit => visit.toUpperCase() === value).length >= 2 ) {*/}

                                    {/*                                                            const ipList = form.getFieldValue('ipList');*/}
                                    {/*                                                            if(new Set(ipList)?.size !== ipList?.length){*/}
                                    {/*                                                                return Promise.reject(new Error(t('message.same.value.entered.within.ip.list.item')));*/}
                                    {/*                                                            }else {*/}
                                    {/*                                                                return Promise.resolve();*/}
                                    {/*                                                            }*/}

                                    {/*                                                        } else {*/}
                                    {/*                                                            return Promise.resolve();*/}
                                    {/*                                                        }*/}
                                    {/*                                                    }*/}
                                    {/*                                                    return Promise.resolve();*/}
                                    {/*                                                }*/}
                                    {/*                                            })*/}
                                    {/*                                        ]}*/}
                                    {/*                                    >*/}
                                    {/*                                        <Select options={studyEvents} style={{width:'90px'}}/>*/}
                                    {/*                                    </Form.Item>*/}
                                    {/*                                </td>*/}
                                    {/*                                <td>*/}
                                    {/*                                    <Form.Item*/}
                                    {/*                                        name={[index, "randomGroup"]}*/}
                                    {/*                                        rules={[{*/}
                                    {/*                                            required: true,*/}
                                    {/*                                            message: t('message.pls.enter.random.group')*/}
                                    {/*                                        }]}*/}
                                    {/*                                    >*/}
                                    {/*                                        <Select options={randomGroup} onClick={onClickRandomGroup}/>*/}
                                    {/*                                    </Form.Item>*/}
                                    {/*                                </td>*/}
                                    {/*                                <td>*/}
                                    {/*                                    <Form.Item*/}
                                    {/*                                        name={[index, "drugName"]}*/}
                                    {/*                                        rules={[*/}
                                    {/*                                            {required: true, message: t('message.pls.enter.drug.name')},*/}
                                    {/*                                        ]}*/}
                                    {/*                                    >*/}
                                    {/*                                        <Input />*/}
                                    {/*                                    </Form.Item>*/}
                                    {/*                                </td>*/}
                                    {/*                                <td>*/}
                                    {/*                                    <Form.Item*/}
                                    {/*                                        name={[index, "drugOid"]}*/}
                                    {/*                                        rules={[*/}
                                    {/*                                            {required: true, message: t('message.pls.enter.drug.oid')},*/}
                                    {/*                                            {pattern: pattern, message: t('message.format.is.not.valid')},*/}
                                    {/*                                            ({getFieldValue}) => ({*/}
                                    {/*                                                validator(_, value) {*/}
                                    {/*                                                    //drugs 입력값 확인*/}

                                    {/*                                                    const ipList = form.getFieldValue('ipList');*/}
                                    {/*                                                    //CASE 1.Value가 빈칸이면*/}
                                    {/*                                                    if (isEmpty(value)) {*/}
                                    {/*                                                        return Promise.resolve();*/}
                                    {/*                                                    }*/}

                                    {/*                                                    //CASE 2.OID 중복 체크 / 현재 ARM에 저장된 OID는 예외처리함*/}
                                    {/*                                                    const result = contains(value);*/}
                                    {/*                                                    if (result !== true) {*/}
                                    {/*                                                        return Promise.reject(new Error(result));*/}
                                    {/*                                                    }*/}
                                    {/*                                                    // ipList 내에서 동일한 oid를 2개 이상 사용한 경우*/}
                                    {/*                                                    else if (ipList.filter(ip => (ip?.drugOid||"").toUpperCase() === value).length >= 2) {*/}
                                    {/*                                                        return Promise.reject(new Error(t('message.same.oid.entered.within.ip.list.item')));*/}
                                    {/*                                                    } else {*/}
                                    {/*                                                        return Promise.resolve();*/}
                                    {/*                                                    }*/}
                                    {/*                                                }*/}
                                    {/*                                            })*/}
                                    {/*                                        ]}*/}
                                    {/*                                    >*/}
                                    {/*                                        <Input onInput={e => e.target.value = e.target.value.toUpperCase()}/>*/}
                                    {/*                                    </Form.Item>*/}
                                    {/*                                </td>*/}
                                    {/*                                <td>*/}
                                    {/*                                    {fields.length > 1 &&*/}
                                    {/*                                        <Button onClick={() => remove(field.name)}*/}
                                    {/*                                                disabled={fields.length <= 1}>*/}
                                    {/*                                            <i className='fa fa-trash-o'/></Button>*/}
                                    {/*                                    }*/}
                                    {/*                                </td>*/}
                                    {/*                            </tr>*/}
                                    {/*                        ))}*/}
                                    {/*                        <tr>*/}
                                    {/*                            <td colSpan = {5}>*/}
                                    {/*                                <Form.Item>*/}
                                    {/*                                    <Button*/}
                                    {/*                                        type={'primary'}*/}
                                    {/*                                        className='width-100p'*/}
                                    {/*                                        onClick={() => add()}*/}
                                    {/*                                    >*/}
                                    {/*                                        <i className='fa fa-plus mr-2'/> Add*/}
                                    {/*                                    </Button>*/}
                                    {/*                                </Form.Item>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}
                                    {/*                    </>*/}
                                    {/*                )}*/}
                                    {/*            </Form.List>*/}
                                    {/*            </tbody>*/}
                                    {/*        </Table>*/}
                                    {/*    </Card>*/}
                                    {/*</Col>*/}
                                </Row>
                            }
                            <Footer className={'card-footer text-right'} style={{position:'sticky', background:'white', bottom:'0px', paddingBottom:'1rem !important', zIndex:10}}>
                                <StudyStructureFooter studyStructure={studyStructure} loaded={loaded} onDownload={onDownload} metaDataStore={metaDataStore} form={form} disableView={disableView} useIPManagement={useIPManagement} reload={reload} setReload={setReload}/>
                            </Footer>
                        </Form>
                    )
                }
            </CardBody>
        </Layout>
    )

}

export default StudyStructure;