import style from "../../../../../container/build-tool/tools/style.module.scss";
import {Button, Collapse, Dropdown, Input, message, Tag} from "antd";
import React, {useEffect, useState} from "react";
import ToolBtn from "../../../../../container/build-tool/tools/ToolBtn";
import {onSearch} from "../../../../../lib/ToolbarUtils";
import {Notice, ToolbarItemList} from "../../../../../container/build-tool/StyleBox";
import {Draggable, Droppable} from "react-beautiful-dnd";
import useLibraryStore from "../../../../../store/libraryStore";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useMutation} from "react-query";
import {method, saveDefWithAxios} from "../../../../../lib/api/library";
import {errorHandle} from "../../../../../lib/BuilderUtils";
import LibraryToolbarItem from "../tools/LibraryToolbarItem";
import {toLibraryMap} from "../../../../../lib/LibraryUtils";
import {DownOutlined} from "@ant-design/icons";
import _ from "lodash";
import {checkDelete, mandatorySetting} from "../../../../../lib/ODMUtils";
import {useTranslation} from "react-i18next";
import {isEmpty} from "../../../../../lib/StringUtils";

const {Panel} = Collapse;

const LibraryToolbar = ({Form, itemRefMap, dropEnable, setDefOpen, setDefType, setDefItem}) => {

    const {library, updateLibrary} = useLibraryStore(state => state);
    const {itemGroup, item, codeList, valueList} = toLibraryMap(library);
    const {t} = useTranslation();

    //UseMutation 사용
    const {mutate, isLoading, isError, error, isSuccess} = useMutation(saveDefWithAxios, {
        onSuccess: ({library, resultMessage}) => {
            console.log(`Toolbar Definition Save : `, library);
            message.success(resultMessage);
            updateLibrary(library);
        },
        onError: (error) => {
            errorHandle(error);
        },
        onMutate: (variables) => {
            /**
             * Mutation 함수가 실행되기 전, 실행되고 mutation 함수가 받을 동일한 변수가 전달,
             */
        },
        onSettled: (data, error, variables, context) => {
            /**
             * mutation이 성공하여 성공한 데이터 또는 error가 전달될 때 실행,
             * 성공하거나 실패하거나 상관없이 결과가 무조건 전달
             */
        }
    });

    const [itemGroupFilterKey, setItemGroupFilterKey] = useState(null);
    const [itemFilterKey, setItemFilterKey] = useState(null);
    const [codeListFilterKey, setCodeListFilterKey] = useState(null);
    const [valueListFilterKey, setValueListFilterKey] = useState(null);

    const [filteredItemGroups, setFilteredItemGroups] = useState(null);
    const [filteredItems, setFilteredItems] = useState(null);
    const [filteredCodeLists, setFilteredCodeLists] = useState(null);
    const [filteredValueLists, setFilteredValueLists] = useState(null);

    /**
     * Definition 정보 수정으로 인한 Library Reload 시, Filter 내용 Refresh
     */
    useEffect(() => {

        //ITEM GROUP
        if(itemGroupFilterKey != null) {
            setFilteredItemGroups(Array.from(itemGroup.values()).filter(ig => {
                let result = false;
                if(!isEmpty(ig?.domain)){result |= ig?.domain.toUpperCase().includes(itemGroupFilterKey.toUpperCase()||'');}
                if(!isEmpty(ig?.oid))   {result |= ig?.oid.toUpperCase().includes(itemGroupFilterKey.toUpperCase()||'');}
                if(!isEmpty(ig?.name))  {result |= ig?.name.toUpperCase().includes(itemGroupFilterKey.toUpperCase()||'');}
                return result;
            }));
        }

        //ITEM
        if(itemFilterKey != null) {
            setFilteredItems(Array.from(item.values()).filter(v => {
                let result = false;
                if(!isEmpty(v?.oid))   {result |= v?.oid.toUpperCase().includes(itemFilterKey.toUpperCase());}
                if(!isEmpty(v?.name))   {result |= v?.name.toUpperCase().includes(itemFilterKey.toUpperCase());}
                return result;
            }));
        }

        //CODELIST
        if(codeListFilterKey != null) {
            setFilteredCodeLists(Array.from(codeList.values()).filter(v => {
                let result = false;
                if(!isEmpty(v?.oid))   {result |= v?.oid.toUpperCase().includes(codeListFilterKey.toUpperCase());}
                if(!isEmpty(v?.name))   {result |= v?.name.toUpperCase().includes(codeListFilterKey.toUpperCase());}
                return result;
            }));
        }

        //VALUELIST
        if(valueListFilterKey != null) {
            setFilteredValueLists(Array.from(valueList.values()).filter(v => {
                let result = false;
                if(!isEmpty(v?.oid))   {result |= v?.oid.toUpperCase().includes(valueListFilterKey.toUpperCase());}
                if(!isEmpty(v?.name))   {result |= v?.name.toUpperCase().includes(valueListFilterKey.toUpperCase());}
                return result;
            }));
        }

    }, [library]);

    const onCreate = (type) => {
        setDefOpen(true);
        setDefType(type);
        setDefItem(null);
    }

    const onEdit = (type, item) => {
        setDefOpen(true);
        setDefType(type);
        setDefItem(item);
    }

    const onRemove = async (itemType, propName, definition) => {
        try {
            const res = await withReactContent(Swal).fire({
                title: `[${definition.oid}] ${definition.name}`,
                icon: 'question',
                iconColor: 'red',
                html: `
                    <div class="alert alert-danger">
                        <span>정말로 삭제하시겠습니까?</span>
                    </div>
                `,
                showCancelButton: true,
                cancelButtonText: '아니오',
                showConfirmButton: '예'
            });

            //ValueList를 삭제할 시, itemRef항목에 대한 mandatory 설정 변경
            //true or false를 Yes or No로 변경
            if(itemType === 'valueList') {
                mandatorySetting(definition.itemRef);
            }

            //예 눌렀을 때,
            if(res.isConfirmed) {
                //현재 페이지에 참조되어있는 ItemGroup이 존재하는 경우
                const existItemGroupOIDs = checkDelete(itemRefMap, propName, definition);

                //현재 itemRefList에 지우려는 item의 Reference 정보가 있으면,
                if(existItemGroupOIDs != null) {
                    message.warning(t('message.error.exist.item-ref', {itemGroupOIDs:existItemGroupOIDs}))
                } else {
                    const {id} = library;
                    definition.id = id;
                    definition.attr = propName;
                    definition.method = method.delete;
                    mutate(definition);
                }
            }
        } catch(e) {
            Swal.showValidationMessage(e.message);
        }
    }

    const isEnable = (enable, draggableId) => {
        //move Type에, draggableId가 일치한 경우만
        if (enable !== null && enable.destination !== null) {
            if (enable.type === 'copy' && enable.draggableId === draggableId) {
                return enable.checked;
            }
        }
        return null;
    }

    /**
     * 드래그 비활성화 처리할 아이템
     * @param oid
     * @returns {boolean|*}
     */
    const isDragDisabledItem = oid => {
        if(oid == null) return false;
        // return oid === "ICDAT" || oid === "SUBJID" || (oid.endsWith("U"))
        return (oid.endsWith("U"))
    }

    const menu = {
        items: [{label: <ToolBtn type={'externalCodeList'} onCreate={onCreate} btnType={'text'} />}]
    }

    //onSearch Debounce 적용
    const onDebounceSearch = _.debounce(onSearch, 200);

    return (
        <Collapse accordion bordered={false} className={style.menu}  defaultActiveKey={["item"]} expandIconPosition={'start'}>
            <Panel key={'item-group'} header={<>
                Item Groups
                {
                    filteredItemGroups !== null &&
                    <Tag className={'ml-2'}><i className='fa fa-filter'/></Tag>
                }
            </>} extra={<ToolBtn type="itemGroup" onCreate={onCreate} />}>
                <Input.Search allowClear className={style.search} onChange={(e) => onDebounceSearch(e?.target?.value, 'itemGroup', Array.from(itemGroup.values()), setFilteredItemGroups, setItemGroupFilterKey)} />
                <Droppable droppableId="newItemGroup" direction="vertical" isDropDisabled={true} type="itemGroup">
                    {(provided, snapshot) => (
                        <ToolbarItemList ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver}>
                            {
                                (Form.itemGroupRef??[]).filter(fig => (filteredItemGroups!==null?filteredItemGroups:Array.from(itemGroup.values())).some(ig => ig.oid === fig.itemGroupOID)).length < (filteredItemGroups!==null?filteredItemGroups:Array.from(itemGroup.values())).length ?
                                    (filteredItemGroups!==null?filteredItemGroups:Array.from(itemGroup.values())).map((ig, index) => (
                                        (Form.itemGroupRef??[]).every(fig => fig.itemGroupOID !== ig.oid) &&
                                        <Draggable key={ig.oid} draggableId={ig.oid} index={index} disableInteractiveElementBlocking={true}>
                                            {(provided, snapshot) => (
                                                <LibraryToolbarItem key={`toolbar-item-group-${ig.oid}`} itemType="itemGroup"
                                                                    provided={provided} snapshot={snapshot} isDragging={snapshot.isDragging}
                                                                    enable={isEnable(dropEnable, ig.oid)} item={ig} onEdit={onEdit} onRemove={onRemove} />
                                            )}
                                        </Draggable>
                                    ))
                                    : (
                                        <Notice key={'notice'}>
                                            No ItemGroup.
                                        </Notice>
                                    )
                            }
                            {/* placeholder가 없으면 warning 발생으로 인해 강제로 집어넣음. */}
                            <div style={{display: "none"}}>{provided.placeholder}</div>
                        </ToolbarItemList>
                    )}
                </Droppable>
            </Panel>
            <Panel key={'item'} header={<>
                Items
                {
                    filteredItems !== null &&
                    <Tag className={'ml-2'}><i className='fa fa-filter'/></Tag>
                }
            </>} extra={<ToolBtn type="item" onCreate={onCreate} />}>
                <Input.Search allowClear className={style.search} onChange={(e) => onDebounceSearch(e?.target?.value, 'item', Array.from(item.values()), setFilteredItems, setItemFilterKey)} />
                <Droppable droppableId="newItem" direction="vertical" isDropDisabled={true} type="item">
                    {(provided, snapshot) => (
                        <ToolbarItemList ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver}>
                            {
                                (filteredItems!==null?filteredItems:Array.from(item.values())).length > 0 ?
                                    (filteredItems!==null?filteredItems:Array.from(item.values())).map((i, index) => (
                                        <Draggable key={i?.oid} draggableId={i?.oid} index={index} isDragDisabled={isDragDisabledItem(i?.oid)} disableInteractiveElementBlocking={true}>
                                            {(provided, snapshot) => (
                                                <LibraryToolbarItem key={`toolbar-item-${i?.oid}`} itemType="item"
                                                             provided={provided} snapshot={snapshot} isDragging={snapshot.isDragging}
                                                             enable={isEnable(dropEnable, i?.oid)} item={i} onEdit={onEdit} onRemove={onRemove} />
                                            )}
                                        </Draggable>
                                    )) : (
                                        <Notice key={'notice'}>
                                            No Items.
                                        </Notice>
                                    )
                            }
                        </ToolbarItemList>
                    )}
                </Droppable>
            </Panel>
            <Panel key={'codelist'} header={<>
                CodeLists
                {
                    filteredCodeLists !== null &&
                    <Tag className={'ml-2'}><i className='fa fa-filter'/></Tag>
                }
            </>} extra={<>
                <Button size={'small'}
                        onClick={(e) => {
                                e.stopPropagation(); // Panel Toggle 동작으로 Event가 확장되지 않게 제어.
                                onCreate("codeListItem");
                            }
                        }>
                    <i className='fa fa-plus mr-2'/>codeListItem
                </Button>
                <Dropdown menu={menu} trigger={"click"} onClick={(e) => {
                    e.stopPropagation();
                }}>
                    <Button size={'small'}><DownOutlined /></Button>
                </Dropdown>
            </>}>
                <Input.Search allowClear className={style.search} onChange={(e) => onDebounceSearch(e?.target?.value, 'codeList', Array.from(codeList.values()), setFilteredCodeLists, setCodeListFilterKey)} />
                <ToolbarItemList>
                    {
                        (filteredCodeLists!==null?filteredCodeLists:Array.from(codeList.values())).length > 0 ?
                            (filteredCodeLists!==null?filteredCodeLists:Array.from(codeList.values())).map((cl, index) => (
                                // CodeListItem Field가 있으면, codeList Item 아니면, externalCodeList
                                <LibraryToolbarItem key={`toolbar-codelist-${cl?.oid}`} enable={true}
                                             itemType={cl?.externalCodeList===null?'codeListItem':'externalCodeList'}
                                             item={cl} onEdit={onEdit} onRemove={onRemove} />
                            )) : (
                                <Notice key={'notice'}>
                                    No CodeLists.
                                </Notice>
                            )
                    }
                </ToolbarItemList>
            </Panel>
            <Panel key={'valueList'} header={
                <>
                    ValueLists
                    {
                        filteredValueLists !== null &&
                        <Tag className={'ml-2'}><i className='fa fa-filter'/></Tag>
                    }
                </>
            } extra={<ToolBtn type="valueList" onCreate={onCreate} />}>
                <Input.Search allowClear className={style.search} onChange={(e) => onDebounceSearch(e?.target?.value, 'valueList', Array.from(valueList.values()), setFilteredValueLists, setValueListFilterKey)} />
                <ToolbarItemList>
                    {
                        (filteredValueLists !== null ? filteredValueLists : Array.from(valueList.values())).length > 0 ?
                            (filteredValueLists !== null ? filteredValueLists : Array.from(valueList.values())).map((vl, index) => (
                                    <LibraryToolbarItem key={`toolbar-valuelist-${vl?.oid}`} enable={true} itemType="valueList" item={vl} onEdit={onEdit} onRemove={onRemove} />
                                )
                            ) : (
                                <Notice key={'notice'}>
                                    No ValueList.
                                </Notice>
                            )
                    }
                </ToolbarItemList>
            </Panel>
        </Collapse>
    )
}

export default LibraryToolbar;