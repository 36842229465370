import _ from "lodash";
import Icon from "@mdi/react";
import {
    mdiAlphabeticalVariant, mdiApplicationOutline,
    mdiBookSearchOutline,
    mdiCalendar,
    mdiCheckboxOutline,
    mdiCheckCircleOutline,
    mdiClockOutline,
    mdiDownloadOutline,
    mdiFormDropdown,
    mdiFormTextarea,
    mdiFormTextbox, mdiOrderAlphabeticalAscending, mdiOrderNumericAscending,
    mdiRelationOneToOne, mdiTableLarge, mdiTableLargePlus,
    mdiUploadOutline
} from "@mdi/js";
import React from "react";


export const controlTypeLabel = (controlType, label) => {
    if(controlType === 'INPUT') {
        return <><Icon path={mdiFormTextbox} color={'#1d39c4'} size={0.75}/>&nbsp;{label}</>
    } else if(controlType === 'DROPDOWN') {
        return <><Icon path={mdiFormDropdown} color={'#1d39c4'} size={0.75}/>&nbsp;{label}</>
    } else if(controlType === 'DATEPICKER') {
        return <><Icon path={mdiCalendar} color={'#1d39c4'} size={0.75}/>&nbsp;{label}</>
    } else if(controlType === 'TIMEPICKER') {
        return <><Icon path={mdiClockOutline} color={'#1d39c4'} size={0.75}/>&nbsp;{label}</>
    } else if(controlType.startsWith('CHECK')) {
        return <><Icon path={mdiCheckboxOutline} color={'#1d39c4'} size={0.75}/>&nbsp;{label}</>
    } else if(controlType.startsWith('RADIO')) {
        return <><Icon path={mdiCheckCircleOutline} color={'#1d39c4'} size={0.75}/>&nbsp;{label}</>
    } else if(controlType === 'TEXTAREA') {
        return <><Icon path={mdiFormTextarea} color={'#1d39c4'} size={0.75}/>&nbsp;{label}</>
    } else if(controlType === 'DICTIONARY') {
        return <><Icon path={mdiBookSearchOutline} color={'#1d39c4'} size={0.75}/>&nbsp;{label}</>
    } else if(controlType === 'LABEL') {
        return <><Icon path={mdiAlphabeticalVariant} color={'#1d39c4'} size={0.75}/>&nbsp;{label}</>
    } else if(controlType === 'DROPDOWN_DATALINK') {
        return <><Icon path={mdiRelationOneToOne} color={'#1d39c4'} size={0.75}/>&nbsp;{label}</>
    } else if(controlType === 'FILE_UPLOAD') {
        return <><Icon path={mdiUploadOutline} color={'#1d39c4'} size={0.75}/>&nbsp;{label}</>
    } else if(controlType === 'FILE_DOWNLOAD') {
        return <><Icon path={mdiDownloadOutline} color={'#1d39c4'} size={0.75}/>&nbsp;{label}</>
    } else {
        return "";
    }
}

export const controlTypeIcon = (controlType) => {
    if(controlType === 'INPUT') {
        return <Icon path={mdiFormTextbox} color={'#1d39c4'} size={1}/>
    } else if(controlType === 'DROPDOWN') {
        return <Icon path={mdiFormDropdown} color={'#1d39c4'} size={1}/>
    } else if(controlType === 'DATEPICKER') {
        return <Icon path={mdiCalendar} color={'#1d39c4'} size={1}/>
    } else if(controlType === 'TIMEPICKER') {
        return <Icon path={mdiClockOutline} color={'#1d39c4'} size={1}/>
    } else if(controlType.startsWith('CHECK')) {
        return <Icon path={mdiCheckboxOutline} color={'#1d39c4'} size={1}/>
    } else if(controlType.startsWith('RADIO')) {
        return <Icon path={mdiCheckCircleOutline} color={'#1d39c4'} size={1}/>
    } else if(controlType === 'TEXTAREA') {
        return <Icon path={mdiFormTextarea} color={'#1d39c4'} size={1}/>
    } else if(controlType === 'DICTIONARY') {
        return <Icon path={mdiBookSearchOutline} color={'#1d39c4'} size={1}/>
    } else if(controlType === 'FILE_UPLOAD') {
        return <Icon path={mdiUploadOutline} color={'#1d39c4'} size={1}/>
    } else if(controlType === 'FILE_DOWNLOAD') {
        return <Icon path={mdiDownloadOutline} color={'#1d39c4'} size={1}/>
    } else {
        return "";
    }
}

export const itemGroupIcon = (repeating) => {
    if(repeating === 'SIMPLE') {
        return <Icon path={mdiTableLargePlus} color={'#1d39c4'} size={1}/>
    } else if(repeating === 'STATIC') {
        return <Icon path={mdiTableLarge} color={'#1d39c4'} size={1}/>
    } else {
        return <Icon path={mdiApplicationOutline} color={'#1d39c4'} size={1}/>
    }
}
export const codeListIcon = (dataType) => {
    if(dataType === 'TEXT') {
        return <Icon path={mdiOrderAlphabeticalAscending } color={'#1d39c4'} size={1}/>
    } else {
        return <Icon path={mdiOrderNumericAscending } color={'#1d39c4'} size={1}/>
    }
}

export const dataTypes = [
    {value:'TEXT', label:'Text', controlTypes: ['INPUT', 'TEXTAREA', 'RADIOBUTTON_HORIZONTAL','RADIOBUTTON_VERTICAL', 'CHECKBOXES_HORIZONTAL', 'CHECKBOXES_VERTICAL', 'DROPDOWN', 'DICTIONARY']},
    {value:'INTEGER', label:'Integer', controlTypes: ['INPUT', 'RADIOBUTTON_HORIZONTAL', 'RADIOBUTTON_VERTICAL', 'CHECKBOXES_HORIZONTAL', 'CHECKBOXES_VERTICAL', 'DROPDOWN', 'DICTIONARY']},
    {value:'FLOAT', label:'Float', controlTypes: ['INPUT', 'RADIOBUTTON_HORIZONTAL', 'RADIOBUTTON_VERTICAL', 'CHECKBOXES_HORIZONTAL', 'CHECKBOXES_VERTICAL', 'DROPDOWN', 'DICTIONARY']},
    {value:'DATE', label:'Date', controlTypes: ['DATEPICKER']},
    {value:'PARTIAL_DATE', label:'Partial Date', controlTypes: ['DATEPICKER']},
    {value:'TIME', label:'Time', controlTypes: ['TIMEPICKER']},
    {value:'PARTIAL_TIME', label:'Partial Time', controlTypes: ['TIMEPICKER']},
    // {value:'DATETIME', label:'Datetime', controlTypes: ['INPUT']},
    {value:'BOOLEAN', label:'Boolean', controlTypes: ['CHECKBOX', 'CHECKBOXES_HORIZONTAL', 'CHECKBOXES_VERTICAL']},
];

export const controlTypes = [
    // {value:'TEXT', label:'Text', codeListRef: false, codeListItem:false, valueListRef: true},
    {value:'INPUT', label:controlTypeLabel('INPUT', 'Input Box'), dataTypes:['TEXT', 'INTEGER', 'FLOAT'], codeListRef: false, codeListItem:false, externalCodeList:false, valueListRef: false},
    {value:'TEXTAREA', label:controlTypeLabel('TEXTAREA', 'Textarea'), dataTypes:['TEXT'], codeListRef: false, codeListItem:false, externalCodeList:false, valueListRef: false},
    {value:'LABEL', label:controlTypeLabel('LABEL', 'Label'), dataTypes:['TEXT'], codeListRef: false, codeListItem:false, externalCodeList:false, valueListRef: false},
    // {value:'NUMBER', label:controlTypeLabel('NUMBER', 'Number'), codeListRef: false, codeListItem:false, valueListRef: false},
    {value:'DATEPICKER', label:controlTypeLabel('DATEPICKER', 'Datepicker'), dataTypes:['DATE', 'PARTIAL_DATE'], codeListRef: false, codeListItem:false, externalCodeList:false, valueListRef: false},
    {value:'TIMEPICKER', label:controlTypeLabel('TIMEPICKER', 'Timepicker'), dataTypes:['TIME', 'PARTIAL_TIME'], codeListRef: false, codeListItem:false, externalCodeList:false, valueListRef: false},
    // {value:'DATETIME', label:'Datetime',  codeListRef: false, codeListItem:false, valueListRef: false},

    {value:'DROPDOWN', label:controlTypeLabel('DROPDOWN', 'Dropdown'), dataTypes:['TEXT', 'INTEGER'], codeListRef: true, codeListItem:true, externalCodeList:false, valueListRef: false},
    {value:'DROPDOWN_DATALINK', label:controlTypeLabel('DROPDOWN_DATALINK', 'Dropdown(Data Link)'), dataTypes:['TEXT'], codeListRef: false, codeListItem:false, externalCodeList:false, valueListRef: false, dataLinkSource:true},
    {value:'RADIOBUTTON_VERTICAL', label:controlTypeLabel('RADIOBUTTON_VERTICAL', 'Radio Buttons(Vertical)'), dataTypes:['TEXT', 'INTEGER'], codeListRef: true, codeListItem:true, externalCodeList:false, valueListRef: false},
    {value:'RADIOBUTTON_HORIZONTAL', label:controlTypeLabel('RADIOBUTTON_HORIZONTAL', 'Radio Buttons(Horizontal)'), dataTypes:['TEXT', 'INTEGER'], codeListRef: true, codeListItem:true, externalCodeList:false, valueListRef: false},

    {value:'CHECKBOX', label:controlTypeLabel('CHECKBOX', 'Checkbox'), dataTypes:['BOOLEAN'], codeListRef: false, codeListItem:false, externalCodeList:false, valueListRef: false},
    {value:'CHECKBOXES_VERTICAL', label:controlTypeLabel('CHECKBOXES_VERTICAL', 'Checkboxes(Vertical)'), dataTypes:['TEXT', 'INTEGER'], codeListRef: true, codeListItem:true, externalCodeList:false, valueListRef: true},
    {value:'CHECKBOXES_HORIZONTAL', label:controlTypeLabel('CHECKBOXES_HORIZONTAL', 'Checkboxes(Horizontal)'), dataTypes:['TEXT', 'INTEGER'], codeListRef: true, codeListItem:true, externalCodeList:false, valueListRef: true},

    {value:'DICTIONARY', label:controlTypeLabel('DICTIONARY', 'Dictionary'), dataTypes:['TEXT', 'INTEGER'], codeListRef: true, codeListItem:false, externalCodeList:true, valueListRef: false},
    // {value:'MULTIPLE_CHECKBOXES_VERTICAL', label:'Checkboxes-Multiple(Vertical)', codeListRef: true, codeListItem:true, valueListRef: false},
    // {value:'MULTIPLE_CHECKBOXES_HORIZONTAL', label:'Checkboxes-Multiple(Horizontal)', codeListRef: true, codeListItem:true, valueListRef: false},
    {value:'FILE_UPLOAD', label:controlTypeLabel('FILE_UPLOAD', 'File Upload'), dataTypes:['TEXT'], codeListRef: false, codeListItem:false, externalCodeList:true, valueListRef: false},
    {value:'FILE_DOWNLOAD', label:controlTypeLabel('FILE_DOWNLOAD', 'File Download'), dataTypes:['TEXT'], codeListRef: false, codeListItem:false, externalCodeList:true, valueListRef: false},
];

export const unknownDateFormat = [
    {value:'ALL_UK', label:<span><strong>All UK</strong> (UKUK-UK-UK or YYYY-UK-UK or YYYY-MM-UK)</span>, format: 'DATE (All UK)', regExp: /([uU][kK]|^\d{4}-(0[1-9]|1[012]|[uU][kK])-(0[1-9]|[12][0-9]|3[01]|[uU][kK]))$/},
    {value:'MONTH_UK', label:<span><strong>Month UK</strong> (YYYY-UK-UK or YYYY-MM-UK)</span>, format: 'DATE (Month UK)', regExp: /([uU][kK]|^\d{4}-(0[1-9]|1[012]|[uU][kK])-(0[1-9]|[12][0-9]|3[01]|[uU][kK]))$/},
    {value:'DAY_UK', label:<span><strong>Day UK</strong> (YYYY-MM-UK)</span>, format: 'DATE (Day UK)', regExp: /([uU][kK]|^\d{4}-(0[1-9]|1[012]|[uU][kK])-(0[1-9]|[12][0-9]|3[01]|[uU][kK]))$/},
    {value:'YEAR_SHORT', label:<span><strong>Year</strong> (YYYY)</span>, format: 'DATE (YEAR)', regExp: /(^\d{4})$/},
    {value:'MONTH_SHORT', label:<span><strong>Month</strong> (YYYY-MM)</span>, format: 'DATE (MONTH)', regExp: /(^\d{4}-(0[1-9]|1[012]))$/},
    {value:'YEAR_UK', label:<span><strong>Year UK</strong> (UK)</span>, format: 'DATE (YEAR UK)', regExp: /([uU][kK]|^\d{4})$/},
    {value:'MONTH_UK_SHORT', label:<span><strong>Month UK</strong> (YYYY-UK)</span>, format: 'DATE (MONTH UK SHORT)', regExp: /(^\d{4}-(0[1-9]|1[012]|[uU][kK]))$/}
];

export const allowExtensionOptions = [
    {value: 'BMP', label:'.bmp'},
    {value: 'JPEG', label:'.jpeg/.jpg'},
    {value: 'PNG', label:'.png'},
    {value: 'MP4', label:'.mp4'},
    {value: 'ZIP', label:'.zip'},
]

export const toMetaData = (study) => {
    const metaData = {};
    const metaDataVersion = study.metaDataVersion[0];


    // const v = (mdv.protocol !== null && mdv.protocol != undefined && (Array.isArray(mdv.protocol.studyEventRef) || Array.isArray(mdv.protocol.studyEventGroupRef))) ? mdv : null;
    // metaData.protocol = ((v === null || v === undefined) ? null : v.protocol);

    if(metaDataVersion !== undefined && metaDataVersion !== null) {
        const studyEventGroupMap = new Map();
        const studyEventMap = new Map();
        const formMap = new Map();
        const itemGroupMap = new Map();
        const itemMap = new Map();
        const codeListMap = new Map();
        const valueListMap = new Map();
        const editCheckMap = new Map();
        const subjectStatusMap = new Map();
        const armsMap = new Map();
        const saeReportFormMap = new Map();
        const eproScheduleMap = new Map();
        const currentProScheduleOIDs = [];

        const formScheduleList = [];
        const studyEventScheduleList = [];
        const studyEventScheduleMap = new Map();
        const duplicateSiblingFormStudyEvents = [];

        const ieItemDefs = [];

        metaDataVersion.studyEventGroupDef !== undefined && metaDataVersion.studyEventGroupDef.forEach(seg => {
            studyEventGroupMap.set(seg.oid, seg);
        });

        metaDataVersion.studyEventDef !== undefined && metaDataVersion.studyEventDef.forEach(se => {
            studyEventMap.set(se.oid, se);
        });
        metaDataVersion.formDef !== undefined && metaDataVersion.formDef.forEach(form => {
            formMap.set(form.oid, form);
        });
        metaDataVersion.itemGroupDef !== undefined && metaDataVersion.itemGroupDef.forEach(ig => {
            itemGroupMap.set(ig.oid, ig);
        });
        metaDataVersion.itemDef !== undefined && metaDataVersion.itemDef.forEach(i => {
            itemMap.set(i.oid, i);
        });
        metaDataVersion.codeList !== undefined && metaDataVersion.codeList.forEach(cl => {
            codeListMap.set(cl.oid, cl);
        });
        metaDataVersion.valueListDef !== undefined && metaDataVersion.valueListDef.forEach(vl => {
            valueListMap.set(vl.oid, vl);
        });
        metaDataVersion.editCheckDef !== undefined && metaDataVersion.editCheckDef.forEach(ec => {
            editCheckMap.set(ec.oid, ec);
        });
        metaDataVersion.subjectStatusDef !== undefined && metaDataVersion.subjectStatusDef.forEach(ec => {
            subjectStatusMap.set(ec.oid, ec);
        });
        metaDataVersion.saeReportFormDefs !== undefined && metaDataVersion.saeReportFormDefs.forEach(saeForm => {
            saeReportFormMap.set(saeForm.formOID, saeForm.itemGroupDefs);
        });

        metaDataVersion.protocol.studyStructure?.arms != null && metaDataVersion.protocol.studyStructure?.arms.forEach(arm => {
            armsMap.set(arm.oid, arm);
        });

        metaDataVersion.protocol.proScheduleDef != null && metaDataVersion.protocol.proScheduleDef.forEach(pro => {
            // console.log('pro =>', pro);
            eproScheduleMap.set(pro.oid, pro);
        });


        //formScheduleList 생성
        (metaDataVersion.protocol?.studyEventGroupRef??[])?.forEach(seg => {
            const studyEventGroupDef = studyEventGroupMap.get(seg.studyEventGroupOID);
            studyEventGroupDef.studyEventRef.forEach(se => {
                const studyEventDef = studyEventMap.get(se.studyEventOID);
                //StudyEventList에서 참조하는 Form정보를 토대로 사용되는 FormList 저장
                studyEventDef.formRef.forEach(f => {
                    const formDef = formMap.get(f.formOID);
                    if(!formScheduleList.some(f => f.oid === formDef.oid) && formDef?.siblingFormOID === null) { //FormList에 없는 Form만 저장
                        formScheduleList.push(formDef);
                    }
                })
            })
        });

        //StudyEvent 별 Form 정보 정리
        (metaDataVersion.protocol?.studyEventGroupRef??[])?.forEach(seg => {
            const studyEventGroupDef = studyEventGroupMap.get(seg.studyEventGroupOID);
            studyEventGroupDef.studyEventRef.forEach(se => {
                const studyEventDef = studyEventMap.get(se.studyEventOID);
                const scheduleMap = new Map();
                //StudyEventList에서 참조하는 Form정보를 토대로 사용되는 FormList 저장
                studyEventDef.formRef.forEach(f => {
                    const formDef = formMap.get(f.formOID);
                    let key = formDef?.siblingFormOID !== null?formDef.siblingFormOID:formDef.oid;

                    //ScheduleMap에 FormOID가 중복되어있지 않으면,
                    if(!scheduleMap.has(key)) {
                        scheduleMap.set(key, formDef);
                    }
                    /* 이미 ScheduleMap에서 FormOID정보를 가지고있으면 ->
                     * SiblingFormOID로 지정한 Form으로 인해 2개이상 중복되는 경우,
                     * 또는 SiblingFormOID로 선택한Form과 설정한 폼이 같이 있는 경우
                     */
                    else if(!duplicateSiblingFormStudyEvents.includes(se.studyEventOID)) {
                        duplicateSiblingFormStudyEvents.push(se.studyEventOID);
                    }
                })
                studyEventScheduleMap.set(studyEventDef.oid, scheduleMap);
                studyEventScheduleList.push(studyEventDef);
            })
        });

        [...formMap.values()]
            .filter(f => f.formType === 'INDEPENDENT_EVALUATION_FORM')
            .forEach((form) => {
                // console.log(form);
                form.itemGroupRef
                    .forEach(({itemGroupOID}) => {
                        // console.log(itemGroupOID);
                        const itemGroupDef = itemGroupMap.get(itemGroupOID);
                        // console.log(itemGroupDef);
                        itemGroupDef.itemRef
                            .forEach(({itemOID}) => {
                                // console.log(itemOID);
                                ieItemDefs.push(itemMap.get(itemOID));
                                // console.log(itemMap.get(itemOID));
                        });
                    })
            })

        //현재 Schedule 기준, ePROScheduleOID 리스트
        Array.from(formMap.values())
            .filter(f => f.formType === 'EPRO' || f.formType === 'CRF_EPRO')
            .map(f => f.oid)
            .forEach(oid => {
                let proEvents = Array.from(studyEventMap.values())
                    .filter(se => se.formRef.some(({formOID}) => formOID === oid))
                    .map(se => se.oid);

                if(proEvents) {
                    proEvents.forEach(seOID => {
                        currentProScheduleOIDs.push(`PRO.${seOID}.${oid}`);
                    })
                }
            });

        metaData.studyEventGroup = studyEventGroupMap;
        metaData.studyEvent = studyEventMap;
        metaData.form = formMap;
        metaData.itemGroup = itemGroupMap;
        metaData.item = itemMap;
        metaData.codeList = codeListMap;
        metaData.valueList = valueListMap;
        metaData.editCheck = editCheckMap;
        metaData.subjectStatus = subjectStatusMap;
        metaData.arms = armsMap;
        metaData.oid = metaDataVersion.oid;
        metaData.studyOID = study.oid;

        //siblingForm정보가 반영된 FormList 정보
        metaData.formSchedule = formScheduleList;
        //SiblingForm에 대한 StudyEvent 데이터 정보
        metaData.studyEventSchedule = studyEventScheduleList;
        metaData.studyEventScheduleMap = studyEventScheduleMap;
        metaData.duplicateSiblingFormStudyEvents = duplicateSiblingFormStudyEvents;

        metaData.adverseEvent = metaDataVersion.adverseEventDef;
        metaData.subjectField = metaDataVersion.subjectFieldDef;
        metaData.saeReportFormDefs = metaDataVersion.saeReportFormDefs;
        metaData.saeReportFormMap = saeReportFormMap;
        metaData.studyStructure = metaDataVersion.protocol.studyStructure;
        metaData.eproScheduleMap = eproScheduleMap;
        metaData.currentProScheduleOIDs = currentProScheduleOIDs;
        metaData.independentEvaluationDef = metaDataVersion.protocol.independentEvaluationDef;

        metaData.ieItemDefs = ieItemDefs;
    }

    return metaData;
}


export const descriptionCheck = (definition) => {
    //description 정보가 존재하지 않으면, description 정보 삭제
    if(definition?.description?.translatedText == null) {
        delete definition.description;
    }

    if(definition?.headerDescription?.translatedText == null ||
        (definition?.headerDescription?.translatedText != null && definition?.headerDescription?.translatedText.length === 0)) {
        delete definition.headerDescription;
    }

    if(definition?.footerDescription?.translatedText == null ||
        (definition?.footerDescription?.translatedText != null && definition?.footerDescription?.translatedText.length === 0)) {
        delete definition.footerDescription;
    }
}

export const headerTitleCheck = (definition) => {
    //description 정보가 존재하지 않으면, description 정보 삭제
    if(definition?.headerTitle?.translatedText == null ||
        (definition?.headerTitle?.translatedText != null && definition?.headerTitle?.translatedText.length === 0)) {
        delete definition.headerTitle;
    }
}

export const questionCheck = (definition) => {
    //description 정보가 존재하지 않으면, description 정보 삭제
    if(definition?.question?.translatedText == null) {
        delete definition.question;
    }
}

export const codeListRefCheck = (definition) => {
    if(definition?.codeListRef?.codeListOID == null) {
        delete definition.codeListRef;
    }
}

export const valueListRefCheck = (definition) => {
    if(definition.valueListRef?.valueListOID == null) {
        delete definition.valueListRef;
    }
}

/* Ant-form Description Or Question Edit */
const initialValue = {lang: null, value: ''};
export const descOrQuestionAppend = (name, form) => {
    const translatedText = form.getFieldValue([name, 'translatedText']);
    //항목이 1개도 없을 때,
    if(translatedText === undefined) {
        form.setFieldsValue({[name]:{translatedText:[_.cloneDeep(initialValue)]}});
    } else {
        form.setFieldsValue({[name]:{translatedText:[...translatedText, _.cloneDeep(initialValue)]}});
    }
}
export const descOrQuestionRemove = (name, form, index) => {
    const translatedText = form.getFieldValue([name, 'translatedText']);

    //1개일 때 삭제하면, 해당 속성값 제거
    if(translatedText?.length === 1) {
        form.setFieldsValue({[name]:{translatedText:undefined}});
    } else {
        translatedText.splice(index, 1);
        form.setFieldsValue({[name]:{translatedText:[...translatedText]}});
    }
}

/* Ant-form CodeList Decode Function */
export const decodeAppend = (form, itemIndex) => {
    const codeListItem = form.getFieldValue('codeListItem');
    const translatedText = codeListItem[itemIndex].decode.translatedText;
    translatedText.splice((translatedText.length), 0, _.cloneDeep(initialValue));
    form.setFieldsValue({codeListItem});
}
export const decodeRemove = (form, itemIndex, decodeIndex) => {
    const codeListItem = form.getFieldValue('codeListItem');
    const translatedText = codeListItem[itemIndex].decode.translatedText;
    translatedText.splice(decodeIndex, 1,);
    form.setFieldsValue({codeListItem});
}

/* CodeList Function */
const codeListItemInitialValue = {codedValue: null, orderNumber:null, decode:{translatedText:[_.cloneDeep(initialValue)]}}
export const codeListItemAppend = (form, setCodeListDef) => {
    const codeListItem = form.getFieldValue('codeListItem');
    const newCodeListItems = [...codeListItem, _.cloneDeep({...codeListItemInitialValue, orderNumber:codeListItem.length})];
    form.setFieldsValue({codeListItem:newCodeListItems});
    setCodeListDef({...form.getFieldsValue(), codeListItem:newCodeListItems});
}
export const codeListItemRemove = (form, setCodeListDef, index) => {
    const codeListItem = form.getFieldValue('codeListItem');
    codeListItem.splice(index, 1);
    form.setFieldsValue({codeListItem});
    setCodeListDef({...form.getFieldsValue(), codeListItem});
}

/**
 * targetOID가 excludeOID에 포함이 되는가?
 * @param targetOID
 * @returns {boolean}
 */
export const isExcludeOID = (targetOID) => {
    //Remove Exclude OID Set
    // const excludeDefOIDs = ["SEG.EN", "SE.EN", "F.EN", "EN", "ICDAT", "SUBJID"];
    const excludeDefOIDs = [];
    return excludeDefOIDs.some(oid => oid.toLowerCase() === targetOID.toLowerCase());
}


export const isEnvProduction = (metaDataStore) => {
    if(metaDataStore == null) {return false;}
    return metaDataStore?.versionInfo?.env?.key === 'PROD';
}

export const getCascadeLabel = (sources, fields) => {
    if(fields == null || (fields?.length||0) <= 0) {return "N/A";}
    const studyEvent = sources.find(se => se.value === fields[0])
    const form = studyEvent?.children.find(f => f.value === fields[1]);
    const itemGroup = form?.children.find(f => f.value === fields[2]);
    const item = itemGroup?.children.find(f => f.value === fields[3]);

    return [`${studyEvent?.label}`, " / ", `${form?.label}`, " / ", `${itemGroup?.label}`, " / ", `${item?.label}`].join('');
}

export const getExtensionsLabel = (exts) => {
    if(exts == null || (exts?.length||0) <= 0) {return "";}
    return exts.map(ext => allowExtensionOptions.find(({value}) => value === ext).label).join(",");
}

export const getReferenceFileLabel = (referenceFileSource, metaData) => {
    if(referenceFileSource == null) {return "";}
    const {oid, name, allowExtensions} = metaData.item.get(referenceFileSource?.itemOID);
    return name + "[" + oid + "]" + " (" + getExtensionsLabel(allowExtensions) + ")"
}

export const getAllOID = (mdv) => {
    const allOIDs = [];
    allOIDs.push(mdv.oid.toUpperCase());//mdv oid

    mdv.codeList.forEach(cl => {
        allOIDs.push(cl.oid.toUpperCase());//codelist oid
    });
    mdv.valueListDef.forEach(vl => {
        allOIDs.push(vl.oid.toUpperCase());
    })
    mdv.itemDef.forEach(i => {
        allOIDs.push(i.oid.toUpperCase());
    });
    mdv.itemGroupDef.forEach(g => {
        allOIDs.push(g.oid.toUpperCase());
    });
    mdv.formDef.forEach(f => {
        allOIDs.push(f.oid.toUpperCase());
    });
    mdv.studyEventDef.forEach(se => {
        allOIDs.push(se.oid.toUpperCase());
    });
    mdv.studyEventGroupDef.forEach(seg => {
        allOIDs.push(seg.oid.toUpperCase());
    });
    mdv.editCheckDef.forEach(ec => {
        allOIDs.push(ec.oid.toUpperCase());
    });
    mdv.subjectStatusDef.forEach(ec => {
        allOIDs.push(ec.oid.toUpperCase());
    });
    (mdv.protocol.studyStructure?.arms??[]).forEach(arm => {
        allOIDs.push(arm.oid.toUpperCase());
    });

    return allOIDs;

}

/**
 * list 항목을 keyName에 해당하는 항목을 key로 두는 Map으로 변환한다.
 * @param list
 * @constructor
 */
export const listToMap = (list, keyName) => {
    const resultMap = new Map();
    list.forEach((item) => {
        resultMap.set(item[keyName], item);
    });

    return resultMap;
}

export const mandatorySetting = (RefList) => {
    if(RefList != null && Array.isArray(RefList) && RefList.length > 0) {
        RefList.forEach((ref, idx) => {
            ref['mandatory'] = ref['mandatory']?'YES':'NO';
        })
    }
}

export const orderNumberSetting = (RefList) => {
    if(RefList != null && Array.isArray(RefList) && RefList.length > 0) {
        RefList.forEach((ref, idx) => {
            ref['orderNumber'] = idx;
        })
    }
}


export const getSetupDataLink = (dataLink, itemDef) => {
    if(dataLink?.source != null) {
        const {studyEventOID, formOID, itemGroupOID, itemOID} = dataLink?.source;
        itemDef = {
            ...itemDef,
            dataLink:{
                delimiter:itemDef?.dataLink?.delimiter,
                dataLinkSources: [studyEventOID, formOID, itemGroupOID, itemOID]
            }
        };
    }

    return itemDef;
}

export const getSetupReferenceFile = (referenceFile, itemDef) => {
    if(referenceFile != null) {
        const {studyEventOID, formOID, itemGroupOID, itemOID} = referenceFile;
        itemDef = {
            ...itemDef,
            referenceFileSource: [studyEventOID, formOID, itemGroupOID, itemOID]
        };
    }

    return itemDef;
}

export const getScheduleMapByProtocol = (protocol, studyEventGroupMap, studyEventMap, formMap) => {

    const formSchedule = [];
    const studyEventSchedule = [];
    const studyEventScheduleMap = new Map();
    const duplicateSiblingFormStudyEvents = [];

    //formScheduleList 생성
    (protocol?.studyEventGroupRef??[])?.forEach(seg => {
        const studyEventGroupDef = studyEventGroupMap.get(seg.studyEventGroupOID);
        studyEventGroupDef.studyEventRef.forEach(se => {
            const studyEventDef = studyEventMap.get(se.studyEventOID);
            //StudyEventList에서 참조하는 Form정보를 토대로 사용되는 FormList 저장
            studyEventDef.formRef.forEach(f => {
                const formDef = formMap.get(f.formOID);
                if(!formSchedule.some(f => f.oid === formDef.oid) && formDef?.siblingFormOID === null) { //FormList에 없는 Form만 저장
                    formSchedule.push(formDef);
                }
            })
        })
    });

    //StudyEvent 별 Form 정보 정리
    (protocol?.studyEventGroupRef??[])?.forEach(seg => {
        const studyEventGroupDef = studyEventGroupMap.get(seg.studyEventGroupOID);
        studyEventGroupDef.studyEventRef.forEach(se => {
            const studyEventDef = studyEventMap.get(se.studyEventOID);
            const scheduleMap = new Map();
            //StudyEventList에서 참조하는 Form정보를 토대로 사용되는 FormList 저장
            studyEventDef.formRef.forEach(f => {
                const formDef = formMap.get(f.formOID);
                let key = formDef?.siblingFormOID !== null?formDef.siblingFormOID:formDef.oid;

                //ScheduleMap에 FormOID가 중복되어있지 않으면,
                if(!scheduleMap.has(key)) {
                    scheduleMap.set(key, formDef);
                }
                /* 이미 ScheduleMap에서 FormOID정보를 가지고있으면 ->
                 * SiblingFormOID로 지정한 Form으로 인해 2개이상 중복되는 경우,
                 * 또는 SiblingFormOID로 선택한Form과 설정한 폼이 같이 있는 경우
                 */
                else if(!duplicateSiblingFormStudyEvents.includes(se.studyEventOID)) {
                    duplicateSiblingFormStudyEvents.push(se.studyEventOID);
                }
            })
            studyEventScheduleMap.set(studyEventDef.oid, scheduleMap);
            studyEventSchedule.push(studyEventDef);
        })
    });

    return {formSchedule, studyEventSchedule, studyEventScheduleMap};
}

export const checkDelete = (itemRefMap, propName, itemDef) => {

    if(propName === 'itemDef') {
        const existItemGroupOIDs = [];
        itemRefMap.keys().forEach(itemGroupOID => {
            const itemRefOIDs = itemRefMap.get(itemGroupOID).map(({itemOID}) => itemOID);
            if(itemRefOIDs.includes(itemDef?.oid)) {
                existItemGroupOIDs.push(itemGroupOID);
            }
        });

        return existItemGroupOIDs.length > 0 ? existItemGroupOIDs.join(",") : null;
    }

    return null;
}