import {Helmet} from "react-helmet-async";
import BuildFormContainer from "../../../container/build-tool/container/BuildFormContainer";
import React from "react";
import ODMTabs from "../../../layouts/Tab/odm";
import {useParams} from "react-router-dom";

const FormsPage = () => {

    const {oid} = useParams();

    return (
        <ODMTabs menu={'forms'}>
            <Helmet title={`ODM Designer v2.0.0 :: Forms[${oid}]`}/>
            <BuildFormContainer/>
        </ODMTabs>
    )

}

export default FormsPage;