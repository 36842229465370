import {Button, message, Popover, Select, Space, Tag} from "antd";
import _ from "lodash";
import {isEmpty} from "../../../../lib/StringUtils";
import {useRef} from "react";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../../lib/ODMUtils";

const UnitsBtn = ({metaData, data, itemRefMap, setItemRefMap, index, itemGroupOID, visible, setVisible}) => {
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const unitsItems = ([...metaData.item.values()].filter(i => i?.codeListRef?.codeListOID != null && i.oid === (data.itemOID + "U")));
    const displayDef = unitsItems.find(def => def?.oid === data?.unitsItemOID);

    const setUnitsItemOID = (value) => {
        const itemOID = value;
        const cloneItemRefMap = _.cloneDeep(itemRefMap);
        if(isEmpty(itemOID)) {
            delete cloneItemRefMap.get(itemGroupOID)[index].unitsItemOID;
        } else {
            cloneItemRefMap.get(itemGroupOID)[index].unitsItemOID = itemOID;
        }
        setItemRefMap(cloneItemRefMap);
    }

    const unitOptions = [
        {value: '', label: '...'},
        ...unitsItems.map(item => ({
            key: `UNIT_${itemGroupOID}_${index}_${item.oid}`,
            value: item.oid,
            label: item.name
        }))
    ];

    return (
        <Popover placement={'top'}
                 title={'Unit'}
                 trigger={'click'}
                 open={visible}
                 onOpenChange={() => setVisible(!visible)}
                 content={(
                     <Space>
                         {
                             isProd?(
                                 <Tag>
                                     <span className={'font-weight-bold text-danger'}>
                                         {displayDef!=null?`${displayDef?.oid} - ${displayDef?.name}`:`NONE`}
                                     </span>
                                 </Tag>
                             ) : (
                                 <Select placeholder={'Units'} defaultValue={data?.unitsItemOID}
                                         showSearch={true} options={unitOptions} style={{width: 250}}
                                         onChange={setUnitsItemOID} onBlur={() => setVisible(false)} />
                             )
                         }
                     </Space>
                 )}>
            <Button size={'small'} onClick={() => setVisible(true)}>
                <i className='fa fa-flask'></i>
            </Button>
        </Popover>
    )

}

export default UnitsBtn;
