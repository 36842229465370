import {combineReducers} from "redux";
import metaDataStore, {metadataSaga} from "./metadata";
import user, {userSaga} from "./user";
import {all} from "redux-saga/effects";

const rootReducer = combineReducers({
    metaDataStore,
    user,
});

export function* rootSaga() {
    yield all([
        metadataSaga(),
        userSaga(),
    ]);
}

export default rootReducer;



