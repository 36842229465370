import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {isEmpty} from "../../../lib/StringUtils";
import {Popconfirm, Typography} from 'antd';
import {useSelector} from "react-redux";
import {isEnvProduction, isExcludeOID, toMetaData} from "../../../lib/ODMUtils";
import {CardFormWrapper} from "../../../container/build-tool/StyleBox";
import {Paper} from "@material-ui/core";
import classNames from "classnames";
import SAEModal from "./modal/SAEModal";
import axios from "axios";
import {httpStatus} from "../../../lib/createRequestSaga";
import {errorHandle} from "../../../lib/BuilderUtils";

const {Text} = Typography;

const CardForm = ({oid, name, disabled, onSelect, onCopy, onDelete, selectedOID}) => {
    const metaDataStore = useSelector((store) => store.metaDataStore);
    const [editVisible, setEditVisible] = useState(false);
    const [jsonVisible, setJsonVisible] = useState(false);
    const [json, setJson] = useState(null);

    const metaData = toMetaData(metaDataStore.study);
    const navigate = useNavigate();
    const params = useParams();

    const isProd = isEnvProduction(metaDataStore);
    const formDef = metaData.form.get(oid);

    const getFormTypeLabel = (formType) => {
        if(formType === 'CRF') {
            return <span className={'badge badge-default font-size-10'}>CRF</span>;
        } else if(formType === 'REGISTRATION_FORM') {
            return <span className={'badge badge-info font-size-10'}>REG</span>;
        } else if(formType === 'LABORATORY_TEST_FORM') {
            return <span className={'badge badge-success font-size-10'}>LAB</span>;
        } else if(formType === 'SAE_REPORT_FORM') {
            return <span className={'badge badge-warning font-size-10'}>SAE</span>
        } else if(formType === 'EPRO') {
            return <span className={'badge badge-info font-size-10'}>ePRO</span>
        } else if(formType === 'CRF_EPRO') {
            return <span className={'badge badge-info font-size-10'}>CRF&nbsp;/&nbsp;ePRO</span>
        } else if(formType === 'INDEPENDENT_EVALUATION_FORM') {
            return <span className={'badge badge-secondary font-size-10'}>IE</span>
        } else if(formType === 'INDEPENDENT_EVALUATION_UPLOAD_FORM') {
            return <span className={'badge badge-secondary font-size-10'}>IE&nbsp;/&nbsp;<i className='fa fa-upload'></i></span>
        } else {
            return '-';
        }
    }

    const onJSONDataModal = async () => {
        setJsonVisible(true);

        try {
            const {sid, id} = params;
            const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
            const formOID = formDef.oid;

            const {data, status} = await axios.get(`/api/studies/${sid}/mdv/${mdvOID}/form/${formOID}/json-map`);

            if(status === httpStatus.ok) {
                setJson(JSON.stringify(data, null, 2));
            }
        } catch (e) {
            errorHandle(e);
        }
    }

    return (
        <CardFormWrapper key={`form-wrapper-${oid}`}>
            {
                jsonVisible &&
                <SAEModal open={jsonVisible} setOpen={setJsonVisible} json={json} ></SAEModal>
            }
            <Paper elevation={3}>
                <div className={"card"} style={{minWidth: 160, minHeight: 200, maxWidth:200}} key={oid}>
                    <div className={'card-body'}>
                        <div className={'form-menu'} style={{display:'none',position:'absolute',top:5, right:5}}>
                            <div className={'btn-group btn-group-sm'}>
                                <React.Fragment>
                                    { onSelect !== undefined && (
                                        //preview가 true인 상태에서, 이전 OID와 새로 선택한 OID가 서로 다른경우에만 팝업표시.
                                        <button className={'btn btn-default'} data-tip={'Edit'}
                                                disabled={disabled}
                                                onClick={() => onSelect(oid)}>
                                            <i className={classNames('fa', {
                                                ['fa-pencil']: !isProd,
                                                ['fa-info']: isProd,
                                            })}></i>
                                        </button>
                                    )}
                                    {
                                        //Version 정보가 PROD가 아닌경우에만 보이게 설정
                                        (isProd == false && isEmpty(selectedOID) && !isExcludeOID(oid)) &&
                                        <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" className={'btn btn-default'}
                                                    onConfirm={() => onDelete(oid)}
                                                    okButtonProps={{loading: metaDataStore.loading}}>
                                            <a href="#"><i className={'fa fa-trash-o'}></i></a>
                                        </Popconfirm>
                                    }
                                    {
                                        (isProd == false && formDef.formType === 'SAE_REPORT_FORM') &&
                                        <button className={'btn btn-default'} onClick={() => onJSONDataModal()}>
                                            <i className='fa fa-sitemap'></i>
                                        </button>
                                    }
                                </React.Fragment>
                                {onCopy !== undefined &&
                                <button className={'btn btn-default'} data-tip={'Copy'} disabled={disabled}
                                        onClick={() => onCopy(oid)}>
                                    <i className={'fa fa-clone'}></i>
                                </button>
                                }
                            </div>
                        </div>
                        <div onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/forms/${oid}`)}>
                            <div className={'pull-left'}><Text style={{fontSize:8,color:'#4b7cf3'}} strong>{oid}</Text></div>
                            <div className={'text-right mb-2'}>{getFormTypeLabel(formDef.formType)}</div>
                            <h6 className="header-title text-truncate">{name}</h6>

                            {formDef.itemGroupRef == null || formDef.itemGroupRef.length === 0 && (
                                <div className={'text-center'}>
                                    <br/>
                                    <small className={'text-danger text-center'}>Please fill out the form.</small>
                                </div>
                            )}
                        {formDef.itemGroupRef != null && formDef.itemGroupRef.map((g, index) => {
                            const itemGroupDef = metaData.itemGroup.get(g.itemGroupOID);
                            return (
                                index < 2 &&
                                <div key={`form-thumb-group-${g.itemGroupOID}-${index}`}>
                                    <small className={'d-inline-block text-truncate width-100p'}>{itemGroupDef?.name||`[${g.itemGroupOID}]?`}</small>
                                    <hr/>
                                    <hr/>
                                    <hr/>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
            </Paper>
        </CardFormWrapper>
    )
};

export default CardForm;
