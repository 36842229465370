import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useNavigate, useParams} from "react-router-dom";
import {errorHandle, getStorageStudy, headerHighlight, onExported, onExporting} from "../../../../lib/BuilderUtils";
import {CardBody, CardFooter, CardHeader, PrimaryCard} from "../../../../pages/PageStyleBox";
import {Button, Tag} from "antd";
import {EditOutlined, FormOutlined, RightOutlined} from "@ant-design/icons";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {withTranslation} from "react-i18next";
import DataGrid, {
    Column,
    ColumnChooser,
    Export,
    FilterRow,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    Scrolling,
    StateStoring,
    Toolbar
} from "devextreme-react/data-grid";

const ManageSites = ({t}) => {

    const params = useParams();
    const navigate = useNavigate();
    const [dataGrid, setDataGrid] = useState(null);
    const [siteList, setSiteList] = useState(null);

    //StudyInfo 설정에 따른 Setting 설정
    const studyInfo = getStorageStudy(params.sid);
    //현재 과제의 설정이 기관 례수 제한 설정인가.
    const isSiteStudyLimit = (studyInfo?.subjectLimitType?.key === 'SITE_STUDY_LIMIT');

    useEffect( () => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/sites`);

                if(status === httpStatus.ok) {
                    //set Data
                    setSiteList(data?.studySites);
                }
            } catch (e) {
                errorHandle(e, navigate, '');
            }
        })()
    }, [])

    return (
        <PrimaryCard>
            <CardHeader>
                <div className={'row'}>
                    <div className={'col-8'}>
                        <h3><RightOutlined className={'mr-2'} />Site</h3>
                    </div>
                    {/*<div className={'col-4'}>*/}
                    {/*    <Input.Search*/}
                    {/*        placeholder="Search by..."*/}
                    {/*        onSearch={(value) => onSearch(baseData, setFilterData, value)}*/}
                    {/*        enterButton*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            </CardHeader>
            <CardBody>
                <DataGrid dataSource={siteList}
                          height={'600'}
                          id={'gridStudySites'}
                          ref={(ref) => {setDataGrid(ref);}}
                          allowedPageSizes={true}
                          allowColumnResizing={false}
                          columnMinWidth={50}
                          columnAutoWidth={true}
                          showColumnLines={true}
                          showRowLines={true}
                          showBorders={true}
                          hoverStateEnabled={true}
                          allowColumnReordering={true}
                          keyExpr={'id'}
                          onExporting={(e) => onExporting(e, 'StudySites',
                              ({gridCell, excelCell}) => {
                                  //Header Background Color Setting
                                  headerHighlight(gridCell, excelCell);

                                  //ENVS
                                  if (gridCell.rowType === 'data' && gridCell.column.dataField === 'envs') {
                                      excelCell.value = (gridCell.data?.envs?.map(env => env?.key)).join(',');
                                  }
                                  //번호
                                  if (gridCell.rowType === 'data' && gridCell.column.dataField === 'id') {
                                      excelCell.value = siteList?.length - siteList.indexOf(gridCell.data);
                                  }
                              })}
                          onExported={(e) => onExported(e)}>
                    <Column dataField={'id'} cssClass={'v-middle'} caption={t('column.no')} fixed={true} fixedPosition={'left'} width={'60px'}
                            cellRender={({row}) => (
                                <span className={'v-middle'} key={row?.loadIndex}>{siteList?.length - row?.loadIndex}</span>
                            )}
                    />
                    <Column dataField={'oid'} cssClass={'v-middle'} caption={t('column.site.oid')} dataType={'string'} alignment={'center'} />
                    <Column dataField={'name'} cssClass={'v-middle'} caption={t('column.site.name')} dataType={'string'} alignment={'center'} />
                    <Column dataField={'principalInvestigator'} cssClass={'v-middle'} caption={t('column.site.principal.investigator')} dataType={'string'} alignment={'center'} />
                    {
                        isSiteStudyLimit && //사이트 별 계약 례수 설정 시, 보이는 칼럼 항목
                        <Column dataField={'contractNumber'} cssClass={'v-middle'} caption={t('column.contract.count')} dataType={'string'} alignment={'center'} />
                    }
                    <Column dataField={'phoneNumber'} cssClass={'v-middle'} caption={t('column.phone.number')} dataType={'string'} alignment={'center'} />
                    <Column dataField={'faxNumber'} cssClass={'v-middle'} caption={t('column.fax.number')} dataType={'string'} alignment={'center'} />
                    <Column dataField={'envs'} cssClass={'v-middle'} caption={t('column.env')} dataType={'string'} alignment={'center'}
                            cellRender={({data, row}) => (
                                <>{(data?.envs??[]).map(env => ( <Tag key={env?.key}>{env?.key}</Tag>))}</>
                            )}/>
                    <Column dataField={'action'} cssClass={'v-middle'} caption={t('column.action')} fixed={true} fixedPosition={'right'}
                            cellRender={({data, row}) => (
                                <>
                                    <Button className={'mr-3'}><Link to={`/studies/${params.sid}/sites/edit?id=${data?.id}`}><EditOutlined className={'mr-2'} />Edit</Link></Button>
                                    <Button><Link to={`/studies/${params.sid}/sites/${data?.id}/versions`}><FormOutlined className={'mr-2'} />CRF</Link></Button>
                                </>
                            )}
                    />

                    <Toolbar>
                        <Item name={'columnChooserButton'} />
                        <Item name={'exportButton'} />
                    </Toolbar>
                    <ColumnChooser enabled={true} />
                    <Export enabled={true} allowExportSelectedData={false} />
                    <LoadPanel enabled={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <FilterRow visible={true} applyFilter={'auto'} />
                    <StateStoring enabled={true} type={'sessionStorage'} storageKey={`storage-studySites`}/>
                    <Scrolling rowRenderingMode={'virtual'} />
                    <Paging defaultPageSize={20}/>
                    <Pager
                        visible={true}
                        displayMode={'compact'}
                        allowedPageSizes={[10, 20, 30, 50]}
                        showPageSizeSelector={true}
                        showInfo={true}
                    />
                </DataGrid>
            </CardBody>
            <CardFooter className='text-right v-middle'>
                <Button>
                    <Link to={`/studies/${params.sid}/sites/edit`} ><i className='fa fa-plus mr-2' /> Add a new site</Link>
                </Button>
            </CardFooter>
        </PrimaryCard>
    )
}

export default withTranslation()(ManageSites);