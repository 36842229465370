import {Helmet} from "react-helmet-async";
import PageSetting from "../../../../components/common/builder/studies/PageSetting";
import CRFVersionTabs from "../../../../layouts/Tab/crf-version";


const PageSettingPage = () => {

    return (
        <CRFVersionTabs menu={'page-setting'}>
            <Helmet title={'BUILDER :: Page Setting'} />
            <PageSetting />
        </CRFVersionTabs>
    )

}

export default PageSettingPage
