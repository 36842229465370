import _ from "lodash";
import {isEmpty} from "../../../../../lib/StringUtils";
import {Button, Popover, Select, Space, Tag} from "antd";

const LibraryUnitsBtn = ({library, data, itemRefMap, setItemRefMap, index, itemGroupOID, open, setOpen}) => {

    const unitsItems = ([...library.item.values()].filter(i => i?.codeListRef?.codeListOID != null && i.oid === (data.itemOID + "U")));
    const displayDef = unitsItems.find(def => def?.oid === data?.unitsItemOID);

    const setUnitsItemOID = (value) => {
        const itemOID = value;
        const cloneItemRefMap = _.cloneDeep(itemRefMap);
        if(isEmpty(itemOID)) {
            delete cloneItemRefMap.get(itemGroupOID)[index].unitsItemOID;
        } else {
            cloneItemRefMap.get(itemGroupOID)[index].unitsItemOID = itemOID;
        }
        setItemRefMap(cloneItemRefMap);
    }

    const unitOptions = [
        {value: '', label: '...'},
        ...unitsItems.map(item => ({
            key: `UNIT_${itemGroupOID}_${index}_${item.oid}`,
            value: item.oid,
            label: item.name
        }))
    ];

    return (
        <Popover placement={'top'}
                 title={'Unit'}
                 trigger={'click'}
                 open={open}
                 onOpenChange={() => setOpen(!open)}
                 content={(
                     <Space>
                         <Select placeholder={'Units'} defaultValue={data?.unitsItemOID}
                                 showSearch={true} options={unitOptions} style={{width: 450}}
                                 onChange={setUnitsItemOID} onBlur={() => setOpen(false)} />
                     </Space>
                 )}>
            <Button size={'small'} onClick={() => setOpen(true)}>
                <i className='fa fa-flask'></i>
            </Button>
        </Popover>
    )

}

export default LibraryUnitsBtn;