import {Helmet} from "react-helmet-async";
import React from "react";
import ManageSiteEdit from "../../../../../components/common/builder/studies/ManageSiteEdit";

const ManageSiteEditPage = () => {

    return (
        <>
            <Helmet title={'BUILDER :: Manage Sites'}/>
            <ManageSiteEdit />
        </>
    )
}

export default ManageSiteEditPage;