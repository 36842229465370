import {Button, Col, Descriptions, Modal, Row, Switch, Typography} from "antd";
import AceEditor from "react-ace";
import React from "react";
import Icon from "@mdi/react";
import {mdiCalculatorVariantOutline} from "@mdi/js";
import {Link} from "react-router-dom";
import './modalStyle.scss';
import {useTranslation} from "react-i18next";

const {Text, Title, Paragraph} = Typography;

const EditCheckInfo = ({editCheck, visible, onClose}) => {

    const {t} = useTranslation();

    return (
        <Modal
            forceRender={true}
            wrapClassName={'odm-modal'}
            title={<Row>
                <Icon path={mdiCalculatorVariantOutline} size={2} />
                <span className={'ml-3'}>
                <strong>Edit Check</strong> Info
            </span>
            </Row>}
            open={editCheck != null && visible}
            width={800}
            onCancel={onClose}
            cancelText={'Close'}
            okButtonProps={{hidden:true}}
            destroyOnClose={true}
        >
            <Row gutter={[20,0]} style={{margin:'5px'}}>
                <Col span={24}>
                    <Title level={4}>Info</Title>
                    <Descriptions layout={'horizontal'} bordered={true}>
                        <Descriptions.Item labelStyle={{width:'20%'}} label={'OID'}>
                            <Text code>{editCheck?.oid}</Text>
                            <Link to={`../../ecs#${editCheck?.oid}`}>
                                <Button type={'link'} size={'small'}>
                                    <i className='fa fa-external-link'></i>
                                </Button>
                            </Link>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout={'horizontal'} bordered={true}>
                        <Descriptions.Item labelStyle={{width:'20%'}} label={'Name'}>
                            <Text>{editCheck?.name}</Text>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout={'horizontal'} bordered={true}>
                        <Descriptions.Item labelStyle={{width:'20%'}} label={'Description'}>
                            <Paragraph>
                                {editCheck?.description}
                            </Paragraph>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout={'horizontal'} bordered={true}>
                        <Descriptions.Item labelStyle={{width:'20%'}} label={'Activate'}>
                            <Switch checked={editCheck?.activate} unCheckedChildren={'Inactive'} checkedChildren={'Active'}/>
                        </Descriptions.Item>
                    </Descriptions>
                    <Title className={'mt-3'} level={4}>Check</Title>
                    <Descriptions layout={'horizontal'} bordered={true}>
                        <Descriptions.Item labelStyle={{width:'20%'}} label={'Expression'}>
                            <AceEditor
                                readOnly={true}
                                focus={false}
                                width={'100%'}
                                mode={"javascript"}
                                theme={"xcode"}
                                minLines={10}
                                maxLines={Infinity}
                                showPrintMargin={false}
                                showGutter={true}
                                wrapEnabled={false}
                                highlightActiveLine={false}
                                fontSize={18}
                                style={{fontFamily:'consolas,monaco'}}
                                name="ECS_EDITOR"
                                setOptions={{
                                    enableBasicAutocompletion: false,
                                    enableLiveAutocompletion: false,
                                    showLineNumbers: true,
                                    useWorker:false,
                                    tabSize: 4,
                                    dragEnabled: false,
                                    placeholder:'/**\n* '+t('odm.pls.enter.it.here')+'\n*/',
                                }}
                                value={editCheck.check.formalExpression?.value}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout={'horizontal'} bordered={true}>
                        <Descriptions.Item labelStyle={{width:'20%'}} label={'Source Data'}>
                            {
                                editCheck.check?.source.map((ecs, index) => (
                                    <Text code key={index} strong={true} style={{fontSize:24}} >
                                        {`[${ecs?.sourceItems.map((s) => s.itemOID).join()}] < [${ecs?.itemGroupOID}] < [${ecs?.formOID}] < [${ecs?.studyEventOID.join()}]`}
                                    </Text>
                                ))
                            }
                        </Descriptions.Item>
                    </Descriptions>
                    <Title className={'mt-3'} level={4}>Action</Title>
                    <Descriptions layout={'horizontal'} bordered={true}>
                        <Descriptions.Item labelStyle={{width:'20%'}} label={'Type'}>
                            <Text code>{editCheck.action?.type}</Text>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        </Modal>
    )
}

export default EditCheckInfo;