import FormInputOID from "../../../../components/common/odm/ant-form/FormInputOID";
import FormInputName from "../../../../components/common/odm/ant-form/FormInputName";
import FormSelectYesOrNo from "../../../../components/common/odm/ant-form/FormSelectYesOrNo";
import FormInput from "../../../../components/common/odm/ant-form/FormInput";
import DescriptionOrQuestion from "../../../../components/common/odm/ant-form/DescriptionOrQuestion";
import React, {useEffect, useState} from "react";
import ModalFooter from "../ModalFooter";
import {Card, Col, Form, Modal, Row, Select} from "antd";
import ModalTitle from "../component/ModalTitle";
import {useTranslation} from "react-i18next";

const ItemGroupDef = ({selectedOID, languages, itemGroupDef, visible, repeatTypeIcons, itemGroupRepeatType, onFinish, onCancel , metaData, disabledRepeatingType}) => {

    const [form] = Form.useForm();
    const [collapseIn, setCollapseIn] = useState(false);
    const [switchLabelOIDs, setSwitchLabelOIDs] = useState([]);
    const {t} = useTranslation();
    const toggleCollapse = () => {
        setCollapseIn(!collapseIn);
    }

    useEffect(() => {
        if(metaData != null) {
            const editChecks = [...metaData.editCheck.values()];
            const switchLabelList = [];
            editChecks.filter(ecs => ecs.action.type === 'SWITCH_TEXT').forEach(ecs => {
                switchLabelList.push({value: ecs.oid, label: ecs.name});
            });

            setSwitchLabelOIDs(switchLabelList);
        }
    }, [metaData]);

    useEffect(() => {
        if(itemGroupDef !== null) {
            form.setFieldsValue(itemGroupDef);
        } else {
            form.resetFields();
        }
    }, [itemGroupDef]);

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    return (
        <Modal
            forceRender={true}
            wrapClassName={'odm-modal'}
            title={<ModalTitle iconType={'itemGroupDef'}>ItemGroup</ModalTitle>}
            width={800}
            onCancel={onCancel}
            open={visible}
            destroyOnClose={true}
            styles={{body:{overflowY: 'auto', height: 'calc(100vh - 20rem)'}}}
            footer={<ModalFooter onCancel={onCancel} form={form}/>}
        >
            <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
                <Card title={'Attributes'} type={'inner'}>
                    {/* REQUIRED */}
                    <Row gutter={[20, 0]}>
                        <Col span={12}>
                            <FormInputOID selectedOID={selectedOID} allowDotChar={false} form={form}/>
                        </Col>
                        <Col span={12}>
                            <FormInput name={'domain'} label={'Domain'}
                                       rules={[
                                           {required:true, message:t('message.warning.required-entry')},
                                           {pattern: /^([a-zA-Z]+[0-9]*){1,8}$/i, message: t('message.format.is.not.valid')},
                                           {max: 100, message: t('message.can.enter.up.to.maximum.of.100.character')}
                                       ]}/>
                        </Col>
                        <Col span={12}>
                            <FormInputName name={itemGroupDef?.name} defType={'itemGroupDef'} form={form}/>
                        </Col>
                        <Col span={12}>
                            <FormSelectYesOrNo label={'Repeating'} name={'repeating'} required={true}
                                               icons={repeatTypeIcons} yesOrNo={itemGroupRepeatType}
                                               value={itemGroupDef?.repeating} disabledOption={disabledRepeatingType}
                            />
                        </Col>
                    </Row>
                    {/*{collapseIn === false &&*/}
                    {/*<div onClick={toggleCollapse}*/}
                    {/*     style={{cursor: "Pointer", borderColor: "#cccccc", backgroundColor: "#f8f6f8"}}*/}
                    {/*     className={'panel'}>*/}
                    {/*    <div className={'panel-body p-0'}>*/}
                    {/*        <h5 className={'pl-3 pr-3 text-right'}>...more</h5>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*}*/}

                    {/*<div className={`panel-collapse collapse${collapseIn ? ' show' : ''}`}>*/}
                    {/*    <Row gutter={[20, 0]}>*/}
                    {/*        <Col span={12}>*/}
                    {/*            <FormInput name={'sasdatasetName'} label={'Dataset Name'}*/}
                    {/*                       rules={[*/}
                    {/*                           {pattern: /^([a-zA-Z_][a-zA-Z0-9_]+){1,8}$/i, message: '형식이 올바르지 않습니다.'},*/}
                    {/*                           {max: 8, message: '최대 8자리까지 입력 가능합니다.'}*/}
                    {/*                       ]}/>*/}
                    {/*        </Col>*/}
                    {/*        <Col span={12}>*/}
                    {/*            <FormInput name={'origin'} label={'Origin'}*/}
                    {/*                       rules={[{max: 100, message: '최대 100자리까지 입력 가능 합니다.'}]}/>*/}
                    {/*        </Col>*/}
                    {/*        <Col span={12}>*/}
                    {/*            <FormTextArea name={'purpose'} label={'Purpose'}*/}
                    {/*                          rules={[{max: 4000, message: '최대 4000자리까지 입력 가능 합니다.'}]}/>*/}
                    {/*        </Col>*/}
                    {/*        <Col span={12}>*/}
                    {/*            <FormTextArea name={'comment'} label={'Comment'}*/}
                    {/*                          rules={[{max: 4000, message: '최대 4000자리까지 입력 가능 합니다.'}]}/>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</div>*/}
                </Card>

                <Card title={'Switch Text(Optional)'} type={'inner'}>
                    <Row gutter={[20, 0]}>
                        <Col span={24}>
                            <Form.Item name={'titleSwitchTextOID'} label={<span className='text-primary'>{'Header Title'}</span>}
                                       required={false}>
                                {
                                    <Select allowClear={true} options={switchLabelOIDs} />
                                }
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={'headerSwitchTextOID'} label={<span className='text-primary'>{'Header Description'}</span>}
                                       required={false}>
                                {
                                    <Select allowClear={true} options={switchLabelOIDs} />
                                }
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={'footerSwitchTextOID'} label={<span className='text-primary'>{'Footer Description'}</span>}
                                       required={false}>
                                {
                                    <Select allowClear={true} options={switchLabelOIDs} />
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                {/*<Card title={'Description'} type={'inner'}>*/}
                {/*    <DescriptionOrQuestion languages={languages} name={'description'} form={form}/>*/}
                {/*</Card>*/}
                <Card title={'Header Title'} type={'inner'}>
                    <DescriptionOrQuestion languages={languages} name={'headerTitle'} form={form}/>
                </Card>
                <Card title={'Header Description'} type={'inner'}>
                    <DescriptionOrQuestion languages={languages} name={'headerDescription'} form={form}/>
                </Card>
                <Card title={'Footer Description'} type={'inner'}>
                    <DescriptionOrQuestion languages={languages} name={'footerDescription'} form={form}/>
                </Card>
            </Form>
        </Modal>
    )

}

export default ItemGroupDef;
