import ScheduleRefCard from "./ScheduleRefCard";
import {Draggable} from "react-beautiful-dnd";
import React from "react";
import {StudyGroupRefItem} from "../../../container/build-tool/StyleBox";
import {Badge} from "antd";
import classNames from "classnames";
import ProtocolRefControlOption from "../../../container/build-tool/content/ProtocolRefControlOption";
import styles from './style.module.scss';

const ScheduleDefDetail = ({defType, metaData, def, onDeleteRef, toggleMandatory, onChangeCondition, isProd}) => {

    return (
        <>
            {
                def !== undefined && metaData !== null && defType === 'protocol' &&
                def.studyEventGroupRef.map((refItem, index) => (
                    <div key={index}>
                        {
                            <Draggable key={`protocol::${refItem.studyEventGroupOID}`}
                                       draggableId={`protocol::${refItem.studyEventGroupOID}`}
                                       index={index} isDragDisabled={isProd}>
                                {(provided, snapshot) => (
                                    <div key={index}
                                         ref={provided.innerRef}
                                         {...provided.draggableProps}
                                         {...provided.dragHandleProps}
                                    >
                                        <StudyGroupRefItem isDragging={snapshot.isDragging}>
                                            <span >
                                                <Badge count={metaData.studyEventGroup.get(refItem.studyEventGroupOID).studyEventRef.length}
                                                       offset={[-20,-2]} size={'small'} style={{backgroundColor: '#57b3ee'}}>
                                                    <i className={classNames('ml-2 fa', {
                                                        ['fa-folder-open-o']: metaData.studyEventGroup.get(refItem.studyEventGroupOID).studyEventRef.length > 0,
                                                        ['fa-folder-o']: metaData.studyEventGroup.get(refItem.studyEventGroupOID).studyEventRef.length <= 0,
                                                    })} />
                                                </Badge>
                                                &nbsp;{metaData.studyEventGroup.get(refItem.studyEventGroupOID).name}&nbsp;
                                            </span>
                                            <ProtocolRefControlOption metaData={metaData} defOID={'protocol'} isProd={isProd}
                                                                      refInfo={metaData.studyEventGroup.get(refItem.studyEventGroupOID)}
                                                                      refItem={refItem} refType={'studyEventGroupRef'}
                                                                      onDeleteRef={onDeleteRef} toggleMandatory={toggleMandatory}
                                                                      onChangeCondition={onChangeCondition} />

                                        </StudyGroupRefItem>
                                    </div>
                                )}
                            </Draggable>
                        }
                    </div>
                ))
            }
            {
                def !== undefined && metaData !== null && defType === 'studyEventDef' &&
                def.formRef.map((refItem, index) => (
                    <Draggable key={`${def.oid}::${refItem.formOID}`}
                               draggableId={`${def.oid}::${refItem.formOID}`}
                               index={index} isDragDisabled={isProd}>
                        {(provided, snapshot) => (
                            <div key={index}
                                 ref={provided.innerRef}
                                 {...provided.draggableProps}
                                 {...provided.dragHandleProps}
                            >

                                    {/* ref정보를 토대로 def를 넘겨야 이름을 알 수 있기 때문에 def로 넘김 */}
                                    <ScheduleRefCard refType={'formRef'}
                                                     refItem={refItem} refInfo={metaData.form.get(refItem.formOID)}
                                                     dragging={snapshot.isDragging} defOID={def.oid}
                                                     onDeleteRef={onDeleteRef} toggleMandatory={toggleMandatory}
                                                     onChangeCondition={onChangeCondition}
                                                     metaData={metaData}/>
                            </div>
                        )}
                    </Draggable>
                ))
            }
            {
                def !== undefined && metaData !== null && defType === 'studyEventGroupDef' &&
                def?.studyEventRef.map((refItem, index) => (
                    <div key={index}>
                        {
                            /*refItem.type === 'StudyEventRef' &&*/
                            <Draggable key={`${def.oid}::${refItem.studyEventOID}`}
                                       draggableId={`${def.oid}::${refItem.studyEventOID}`}
                                       index={index} isDragDisabled={isProd}>
                                {(provided, snapshot) => (
                                    <div key={index}
                                         ref={provided.innerRef}
                                         {...provided.draggableProps}
                                         {...provided.dragHandleProps}
                                    >
                                        <ScheduleRefCard refType={'studyEventRef'}
                                                         refItem={refItem} refInfo={metaData.studyEvent.get(refItem.studyEventOID)}
                                                         dragging={snapshot.isDragging} defOID={def.oid}
                                                         onDeleteRef={onDeleteRef} toggleMandatory={toggleMandatory}
                                                         onChangeCondition={onChangeCondition}
                                                         metaData={metaData}/>
                                    </div>
                                )}
                            </Draggable>
                        }
                    </div>
                ))
            }
        </>
    )
}

export default ScheduleDefDetail;
