import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import {message} from "antd";
import {
    DELETE_FAILED,
    DELETE_SUCCESS,
    META_LOADING_FAILED,
    PROFILE_FAILED,
    PROFILE_SUCCESS,
    REGPAGE_FETCH_FAILED,
    REGPAGE_FETCH_SUCCESS,
    SAVE_FAILED,
    SAVE_SUCCESS,
    SIGNUP_FAILED,
    SIGNUP_SUCCESS, SYNC_FAILED, SYNC_SUCCESS
} from "../components/constant/responseType";
import rootReducer, {rootSaga} from "../redux/module";
import i18next from "i18next";

const sagaMiddleware = createSagaMiddleware();
// const middleware = [sagaMiddleware];

// const enhancer = process.env.NODE_ENV === 'production' ? compose(applyMiddleware(...middleware)) : composeWithDevTools(applyMiddleware(...middleware));
console.log('%cprocess.env.NODE_ENV => ' + process.env.NODE_ENV, "color:red;");

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), sagaMiddleware, logger],
    devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

console.log('store started..');

store.subscribe(() => {
    const {metaDataStore, user} = store.getState();
    const t = i18next.t.bind(i18next);

    if(metaDataStore.responseStatus !== '') {
        switch (metaDataStore.responseStatus) {
            case META_LOADING_FAILED:
                message.error(t('message.failed.retrieve.metadata.info'));
                break;
            // case META_LOADING_SUCCESS:
            //     message.info('메타데이터 정보를 로드 완료 했습니다.');
            //     break;
            case SAVE_SUCCESS:
                message.success(t('message.has.been.saved'));
                break
            case DELETE_SUCCESS:
                message.success(t('message.has.been.deleted'));
                break
            case SYNC_SUCCESS:
                message.success(t('message.sync.has.been.completed'));
                break;
            case SAVE_FAILED:
                message.warning(metaDataStore.message);
                break;
            case DELETE_FAILED:
                message.warning(metaDataStore.message);
                break;
            case SYNC_FAILED:
                message.warning(metaDataStore.message);
                break;
            default:
                break;
        }
        metaDataStore.responseStatus = '';
    }

    if(user.responseStatus !== '') {
        switch(user.responseStatus) {

            case SIGNUP_SUCCESS:
                message.success(t('message.membership.registration.complete'));
                break;
            case SIGNUP_FAILED:
                message.warning(t('message.membership.registration.failed'));
                break;

            case PROFILE_SUCCESS:
                message.success(t('message.profile.update.successful'));
                break;
            case PROFILE_FAILED:
                message.warning(t('message.profile.update.failed'));
                break;

            case REGPAGE_FETCH_SUCCESS:
                // message.success("");
                break;
            case REGPAGE_FETCH_FAILED:
                message.warning(t('message.registration.page.error'));
                break;
            default:
                break;
        }
    }

});

export default store;
