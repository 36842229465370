import React from 'react'
import {UserOutlined} from '@ant-design/icons'
import {Avatar, Dropdown, Menu} from 'antd'
import styles from './style.module.scss'
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {withTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {userActions} from "../../../../redux/module/user";

const UserMenu = ({t, user}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = async () => {
        await axios.post("/api/logout");
        dispatch(userActions.logout());
        navigate('/login?logout', {replace:true});
    }

    const menu = {
        selectable: false,
        items: [
            {
                key: 1,
                label: <><strong><span>{user.user?.name || 'Anonymous'}</span></strong>{t('index.name.label')}<div><span className='badge badge-dark p-1'>Role</span> : {user.user?.roles || '-'}</div></>
            },
            {type: 'divider'},
            {
                key: 2,
                icon: <i className='fe fe-user-check' />,
                label: <Link to={"/profile"}>{t('user.menu.profile')}</Link>
            },
            {
                key:3,
                icon: <i className='fa fa-external-link' />,
                label: 'CDMS',
                children: [
                    {
                        key: 4,
                        icon: <i className='fa fa-code' />,
                        label: <a href={process.env.REACT_APP_CDMS_DEV_URL}>DEV CDMS</a>
                    },
                    {
                        key: 5,
                        icon: <i className='fa fa-search' />,
                        label: <a href={process.env.REACT_APP_CDMS_UAT_URL}>UAT CDMS</a>
                    },
                    {
                        key: 6,
                        icon: <i className='fa fa-desktop' />,
                        label: <a href={process.env.REACT_APP_CDMS_PROD_URL}>PROD CDMS</a>
                    },
                ]
            },
            {
                key: 7,
                icon: <i className="fe fe-log-out"></i>,
                label: <Link to='/logout' onClick={() => logout()} >{t('user.menu.logout')}</Link>
            }
        ]
    }

    return (
        <>
            <Dropdown menu={menu} trigger={['click']}>
                <div className={styles.dropdown}>
                    <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined/>}/>
                </div>
            </Dropdown>
        </>
    )
}

export default withTranslation()(UserMenu);
