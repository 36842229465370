import React from 'react'
import style from './style.module.scss'

/**
 * YSH Customize - 2021-07-19
 * @param logo
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = ({ logo }) => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        {/*<img width="100" src="/static/images/redpine/logo-redpine.png" />*/}
        {/*<br />*/}
        <p className="mb-0">
          Copyright © {new Date().getFullYear()}{' '}
          <a href="http://www.safesoft.co.kr" target="_blank" rel="noopener noreferrer">
            SAFESOFT
          </a>
          {' | '}
          <a href="#">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer;
