import {Button, Descriptions, Modal, Spin} from "antd";
import {useTranslation} from "react-i18next";

const ReferenceSearchModal = ({searchOID, onSearchOID, isModalVisible, setIsModalVisible, isSearchOID, searchType, searchResult}) => {

    const {t} = useTranslation();

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <Modal width={900} title={`Search : ${searchOID}`} open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Spin size="large" tip={t('message.searching')} spinning={isSearchOID}>
                <Descriptions
                    column={1}
                    bordered
                    title={t('column.search.results')}
                    size={'small'}
                >
                    {isSearchOID === false &&
                        <>
                            {searchType === 'item' &&
                                <>
                                    <Descriptions.Item label="Item Group">

                                        {searchResult.itemGroups.length === 0 ? t('message.no.search.results.found') : t('message.search.result.item.groups.length.search.result', {length : searchResult.itemGroups.length})}
                                        <Descriptions
                                            column={1}
                                            bordered
                                            size={'small'}
                                        >
                                            {
                                                searchResult.itemGroups.map(itemGroup => (

                                                    <Descriptions.Item label={itemGroup.name}>
                                                        <Button type={"link"}
                                                                onClick={() => onSearchOID('itemGroup', itemGroup.oid)}>{itemGroup.oid}&nbsp;
                                                            <i className={'fa fa-search'}></i></Button>
                                                    </Descriptions.Item>
                                                ))

                                            }
                                        </Descriptions>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="ValueList">
                                        {searchResult.valueList.length === 0 ? t('message.no.search.results.found') : t('message.search.result.item.groups.length.search.result', {length : searchResult.valueList.length})}
                                        <Descriptions
                                            column={1}
                                            bordered
                                            size={'small'}
                                        >
                                            {

                                                searchResult.valueList.map(data => (
                                                    <>
                                                        <Descriptions.Item label="OID">
                                                            {data.oid}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Name">
                                                            {data.name}
                                                        </Descriptions.Item>
                                                    </>
                                                ))
                                            }
                                        </Descriptions>
                                    </Descriptions.Item>
                                </>
                            }
                            {/*itemGroup 검색 결과*/}
                            {searchType === 'itemGroup' &&
                                <>
                                    <Descriptions.Item label="Form">
                                        {searchResult.forms.length === 0 ? t('message.no.search.results.found') : t('message.search.result.item.groups.length.search.result', {length : searchResult.forms.length})}
                                        <Descriptions
                                            column={1}
                                            bordered
                                            size={'small'}
                                        >
                                            {
                                                searchResult.forms.map(form => (
                                                    <Descriptions.Item label={form.name}>
                                                        <Button type={"link"}
                                                                onClick={() => onSearchOID('form', form.oid)}>{form.oid}&nbsp;
                                                            <i className={'fa fa-search'}></i></Button>
                                                    </Descriptions.Item>
                                                ))
                                            }
                                        </Descriptions>
                                    </Descriptions.Item>
                                </>
                            }
                            {/*form 검색 결과*/}
                            {searchType === 'form' &&
                                <>
                                    <Descriptions.Item label="Study Event">
                                        {searchResult.studyEvents.length === 0 ? t('message.no.search.results.found') : t('message.search.result.item.groups.length.search.result', {length : searchResult.studyEvents.length})}
                                        <Descriptions
                                            column={1}
                                            bordered
                                            size={'small'}
                                        >
                                            {
                                                searchResult.studyEvents.map(data => (
                                                    <Descriptions.Item label={data.name}>

                                                        <Button type={"link"}
                                                                onClick={() => onSearchOID('studyEvent', data.oid)}>{data.oid}&nbsp;
                                                            <i className={'fa fa-search'}></i></Button>
                                                    </Descriptions.Item>
                                                ))
                                            }
                                        </Descriptions>
                                    </Descriptions.Item>
                                </>
                            }
                            {/*studyEvent 검색 결과*/}
                            {searchType === 'studyEvent' &&
                                <>
                                    <Descriptions.Item label="Study Event Group">
                                        {searchResult.studyEventGroups.length === 0 ? t('message.no.search.results.found') : t('message.search.result.item.groups.length.search.result', {length : searchResult.studyEventGroups.length})}
                                        <Descriptions
                                            column={1}
                                            bordered
                                            size={'small'}
                                        >
                                            {
                                                searchResult.studyEventGroups.map(data => (
                                                    <Descriptions.Item label={data.name}>
                                                        {data.oid}
                                                    </Descriptions.Item>
                                                ))
                                            }
                                        </Descriptions>
                                    </Descriptions.Item>
                                </>
                            }
                            {/*codeList 검색 결과*/}
                            {(searchType === 'valueList' || searchType === 'codeListItem' || searchType === 'externalCodeList') &&
                                <>
                                    <Descriptions.Item label="Item">
                                        {searchResult.items.length === 0 ? t('message.no.search.results.found') : t('message.search.result.item.groups.length.search.result', {length : searchResult.items.length})}
                                        <Descriptions
                                            column={1}
                                            bordered
                                            size={'small'}
                                        >
                                            {
                                                searchResult.items.map(data => (
                                                    <Descriptions.Item label={data.name}>
                                                        <Button type={"link"}
                                                                onClick={() => onSearchOID('item', data.oid)}>{data.oid}&nbsp;
                                                            <i className={'fa fa-search'}></i></Button>
                                                    </Descriptions.Item>
                                                ))
                                            }
                                        </Descriptions>
                                    </Descriptions.Item>
                                </>
                            }
                        </>
                    }
                </Descriptions>
            </Spin>
        </Modal>
    )
}

export default ReferenceSearchModal;