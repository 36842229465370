import {Helmet} from "react-helmet-async";
import SponsorRolesPrivileges from "../../../../components/common/builder/sponsor/SponsorRolesPrivileges";

const SponsorRolesPrivilegesPage = () => {

    return (
        <>
            <Helmet title={'BUILDER :: Sponsor - Role & Privilege'} />
            <SponsorRolesPrivileges />
        </>
    )

}

export default SponsorRolesPrivilegesPage;