import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {DefaultCenterCard} from "../../pages/PageStyleBox";
import React from "react";

const DefaultLoading = () => {

    return (
        <DefaultCenterCard>
            <Spin size="large" indicator={<LoadingOutlined style={{fontSize: 64}} spin/>} />
        </DefaultCenterCard>
    )
}

export default DefaultLoading;