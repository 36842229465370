import LibraryFormList from "../LibraryFormList";
import useLibraryStore from "../../../../../store/libraryStore";
import {useEffect, useState} from "react";
import LibraryFormDef from "../modal/LibraryFormDef";
import {useMutation} from "react-query";
import {method, saveDefWithAxios} from "../../../../../lib/api/library";
import {errorHandle} from "../../../../../lib/BuilderUtils";
import {message} from "antd";

const LibraryFormListContainer = () => {

    const [initialForm, setInitialForm] = useState({});
    const {library, formType, languages, yesOrNo, initialValue, reportDesignType, updateLibrary} = useLibraryStore(state => (state));

    //컴포넌트에 따라 필요한 정보
    const propName = 'formDef';
    const [formDef, setFormDef] = useState(initialForm); //현재 Form에 대한 정보
    const [create, setCreate] = useState(false);
    const [formOpen, setFormOpen] = useState(false); //Form 정보를 직접 입력 할 때의 visible 여부.

    //UseMutation 사용
    const {mutate, isLoading, isError, error, isSuccess} = useMutation(saveDefWithAxios, {
        onSuccess:({library, resultMessage}) => {
            console.log(`Form ${create?'Create':'Update'} : `, library);
            message.success(resultMessage);
            updateLibrary(library);
            onCancel();
        },
        onError: (error) => {
            errorHandle(error);
        },
        onMutate: (variables) => {
            /**
             * Mutation 함수가 실행되기 전, 실행되고 mutation 함수가 받을 동일한 변수가 전달,
             */
        },
        onSettled: (data, error, variables, context) => {
            /**
             * mutation이 성공하여 성공한 데이터 또는 error가 전달될 때 실행,
             * 성공하거나 실패하거나 상관없이 결과가 무조건 전달
             */
        }
    });

    useEffect(() => {
        if(library !== null) {
            console.log("Library Settings")
            setInitialForm(initialValue?.form??{});
        }
    }, [library]);

    const onCancel = () => {
        setFormDef({});
        setFormOpen(false);
        setCreate(false);
    }

    const onFormAdd = () => {
        setFormDef(initialForm);
        setFormOpen(true);
        setCreate(true);
    }

    const onFormEdit = (formDef) => {
        setFormDef(formDef);
        setFormOpen(true);
        setCreate(false);
    }

    const onFinish = (values) => {
        const {id} = library;
        const update = {...formDef, ...values};
        update.id = id;
        update.attr = propName;
        update.method = create?method.post:method.put;
        mutate(update);
    }

    const onFormDelete = (formDef) => {
        const {id} = library;
        formDef.id = id;
        formDef.attr = propName;
        formDef.method = method.delete;
        mutate(formDef);
    }

    return (
        <>
            <LibraryFormList library={library} onFormAdd={onFormAdd} onFormEdit={onFormEdit} onFormDelete={onFormDelete} />
            <LibraryFormDef open={formOpen} setOpen={setFormOpen}
                            formDef={formDef} yesOrNo={yesOrNo}
                            formType={formType} languages={languages}
                            reportDesignType={reportDesignType}
                            onFinish={onFinish} onCancel={onCancel} />
        </>
    )
}

export default LibraryFormListContainer;