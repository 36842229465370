import React, {useEffect, useState} from "react";
import QueryBuilder, { formatQuery } from 'react-querybuilder';
import {Alert, Button, Card, Col, DatePicker, Form, Input, Row, Select, Tag} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {isEnvProduction, toMetaData} from "../../lib/ODMUtils";
import 'react-querybuilder/dist/query-builder.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import sql from 'react-syntax-highlighter/dist/esm/languages/hljs/sql';
import {metadataActions} from "../../redux/module/metadata";
import styles from "./style.module.scss";
import classNames from "classnames";
import {Notice} from "../build-tool/StyleBox";
import {useParams} from "react-router-dom";
import {isEmpty} from "../../lib/StringUtils";
import {useTranslation} from "react-i18next";
const initialState = {
    id: 'root',
    combinator: 'and',
    not: false,
    rules: []
};

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
};
const SAEConfigContainer = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const metaData = toMetaData(metaDataStore.study);
    const itemGroups = [...metaData.itemGroup.values()].filter(g => g.repeating !== 'NO')
    const {t} = useTranslation();

    const [form] = Form.useForm();

    const [fields, setFields] = useState([]);
    const [query, setQuery] = useState(initialState);
    const [viewSql, setViewSql] = useState("");
    const [empty, setEmpty] = useState(false);

    const [adverseEvent, setAdverseEvent] = useState(null);
    const [isAdverseEventSelect, setIsAdverseEventSelect] = useState(false);

    useEffect(() => {
        const adverseEvent = metaData.adverseEvent;
        // console.log(adverseEvent);
        if(adverseEvent != null && adverseEvent?.itemGroupOID != null) {
            form.setFieldsValue({
                itemGroupOID:adverseEvent.itemGroupOID,
                aeTermItemOID:adverseEvent.aeTermItemOID,
                aeStartDateOID:adverseEvent.aeStartDateOID,
                aeEndDateOID:adverseEvent.aeEndDateOID,
                aeOngoingOID:adverseEvent.aeOngoingOID,
                saeCondition:adverseEvent.saeCondition
            });

            setAdverseEvent({
                itemGroupOID:adverseEvent.itemGroupOID,
                aeTermItemOID:adverseEvent.aeTermItemOID,
                aeStartDateOID:adverseEvent.aeStartDateOID,
                aeEndDateOID:adverseEvent.aeEndDateOID,
                aeOngoingOID:adverseEvent.aeOngoingOID,
                saeCondition:adverseEvent.saeCondition
            })

            setIsAdverseEventSelect(adverseEvent.itemGroupOID != null);

            // setViewSql(adverseEvent.sql);
            setEmpty(metaData.itemGroup.get(adverseEvent.itemGroupOID) == null); //itemGroup 정보는 있으나 해당 metadata가 존재하지 않으면,
            setFields(metaData.itemGroup.get(adverseEvent.itemGroupOID)?.itemRef.map(i => metaData.item.get(i.itemOID)).map(i => ({name:i.oid,label:i.name}))||[]);
            onQueryChange(JSON.parse(adverseEvent.saeQueryJson));
        }
    }, []);

    const onChange = (itemGroupOID) => {

        if(!isEmpty(itemGroupOID)) {
            setFields(metaData.itemGroup.get(itemGroupOID)?.itemRef.map(i => metaData.item.get(i.itemOID)).map(i => ({name:i.oid,label:i.name}))||[]);
            setIsAdverseEventSelect(true);
        } else {
            setFields([]);
            form.setFieldsValue({
                itemGroupOID:null, aeTermItemOID:null, aeStartDateOID:null,
                aeEndDateOID:null, aeOngoingOID:null, saeCondition:null
            });

            setAdverseEvent(null);
            setIsAdverseEventSelect(false);
        }

        setQuery(initialState);
        setViewSql("");
    }

    const onQueryChange = value => {
        setQuery(value);
        console.log('queryChange ::', value);
        const sql = formatQuery(value, 'sql');
        setViewSql(sql); // '(firstName = "Steve" and lastName = "Vai")'

        form.setFieldsValue({saeCondition:sql,saeQueryJson:JSON.stringify(value)});
    }

    const getLabelField = (field) => {
        if(field == null) {return 'N/A';}
        const label = fields.find(f => f.name === field)?.label;
        return [field, ' - ', label].join('');
    }

    const onFinish = values => {
        // console.log(values);
        values.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
        dispatch(metadataActions.updateAdverseEvent(values));
    }

    return (
        <Form name={"sae-config-form"} form={form} onFinish={onFinish} {...layout}>
            <Card title={t('column.ae.sae.config')} className={'mb-3'}>
                <Row>
                    <Col span={24}>
                        {
                            empty &&
                            <Notice>
                                <Alert className={'width-100p'} type={'warning'} message={<span><i className='fa fa-exclamation-circle mr-2' />{t('message.item.group.info.correspond.ae.not.exist')}</span>} />
                            </Notice>
                        }
                    </Col>
                    <Col span={6}>
                        <Form.Item {...tailLayout} name={"itemGroupOID"} label={"Adverse Event"} rules={[{required: isAdverseEventSelect, message:t('message.pls.select.ae')}]} requiredMark={true}>
                            {
                                isProd?(
                                    <Tag calssName={'p-2'}>
                                        <span className={'font-size-16 font-weight-bold'}>
                                            {itemGroups.find(ig => ig.oid === adverseEvent?.itemGroupOID)?.name||'N/A'}
                                        </span>
                                    </Tag>
                                ):(
                                    <Select placeholder={'select adverse event'} onChange={onChange}>
                                        <Select.Option value={""}>...</Select.Option>
                                        {itemGroups.map(g => (
                                            <Select.Option value={g.oid}>{g.name}</Select.Option>
                                        ))}
                                    </Select>
                                )
                            }
                        </Form.Item>
                        <Form.Item {...tailLayout} name={"aeTermItemOID"} label={"Adverse Event Term"} rules={[{required: isAdverseEventSelect, message:t('message.pls.select.ae.term.item')}]} requiredMark={true}>
                            {
                                isProd?(
                                    <Tag calssName={'p-2'}>
                                        <span className={'font-size-16 font-weight-bold'}>
                                            {getLabelField(adverseEvent?.aeTermItemOID)}
                                        </span>
                                    </Tag>
                                ):(
                                    <Select placeholder={'select adverse event term'}>
                                        <Select.Option value={""}>...</Select.Option>
                                        {fields.map(i => (
                                            <Select.Option value={i.name}>{i.name} - {i.label}</Select.Option>
                                        ))}
                                    </Select>
                                )
                            }
                        </Form.Item>
                        <Form.Item {...tailLayout} name={"aeStartDateOID"} label={"Adverse Event Start Date"} rules={[{required: isAdverseEventSelect, message:t('message.pls.select.ae.start.date')}]} requiredMark={true}>
                            {
                                isProd?(
                                    <Tag calssName={'p-2'}>
                                        <span className={'font-size-16 font-weight-bold'}>
                                            {getLabelField(adverseEvent?.aeStartDateOID)}
                                        </span>
                                    </Tag>
                                ):(
                                    <Select placeholder={'select adverse event start date'}>
                                        <Select.Option value={""}>...</Select.Option>
                                        {fields.map(i => (
                                            <Select.Option value={i.name}>{i.name} - {i.label}</Select.Option>
                                        ))}
                                    </Select>
                                )
                            }
                        </Form.Item>
                        <Form.Item {...tailLayout} name={"aeEndDateOID"} label={"Adverse Event End Date"} rules={[{required: isAdverseEventSelect, message:t('message.pls.select.ae.end.date')}]} requiredMark={true}>
                            {
                                isProd?(
                                    <Tag calssName={'p-2'}>
                                        <span className={'font-size-16 font-weight-bold'}>
                                            {getLabelField(adverseEvent?.aeEndDateOID)}
                                        </span>
                                    </Tag>
                                ):(
                                    <Select placeholder={'select adverse end date'}>
                                        <Select.Option value={""}>...</Select.Option>
                                        {fields.map(i => (
                                            <Select.Option value={i.name}>{i.name} - {i.label}</Select.Option>
                                        ))}
                                    </Select>
                                )
                            }
                        </Form.Item>
                        {/*<Form.Item {...tailLayout} name={"aeOngoingOID"} label={"Adverse Event Ongoing"} rules={[{required: true, message:'AE Ongoing 항목을 선택해 주세요.'}]} requiredMark={true}>*/}
                        {/*    <Select placeholder={'select adverse ongoing'}>*/}
                        {/*        <Select.Option>...</Select.Option>*/}
                        {/*        {fields.map(i => (*/}
                        {/*            <Select.Option value={i.name}>{i.name} - {i.label}</Select.Option>*/}
                        {/*        ))}*/}
                        {/*    </Select>*/}
                        {/*</Form.Item>*/}
                        <Form.Item {...tailLayout} name={"saeCondition"} rules={[{required: isAdverseEventSelect}]} hidden={true}>
                            <Input/>
                        </Form.Item>
                        <Form.Item {...tailLayout} name={"saeQueryJson"} rules={[{required: isAdverseEventSelect}]} hidden={true}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col offset={1} span={17} hidden={fields.length === 0}>
                        <Card title={<h6>SAE Condition</h6>} className={classNames('mb-3', {
                          [`${styles.blockingArea}`]: isProd
                        })}>
                            <QueryBuilder controlClassnames={{
                                addGroup: 'btn btn-squared btn-secondary btn-sm',
                                addRule: 'btn btn-squared btn-primary btn-sm',
                                cloneGroup: 'btn btn-squared btn-secondary btn-sm',
                                cloneRule: 'btn btn-squared btn-secondary btn-sm',
                                removeGroup: 'btn btn-squared btn-outline-danger btn-sm',
                                removeRule: 'btn btn-squared btn-outline-danger btn-sm',
                                combinators: 'form-control form-control-sm d-inline sae-querybuilder-combinators',
                                fields: 'form-control form-control-sm d-inline sae-querybuilder-field',
                                operators: 'form-control form-control-sm d-inline sae-querybuilder-control',
                                value: 'form-control form-control-sm d-inline sae-querybuilder-value'
                            }} fields={fields} query={query} onQueryChange={onQueryChange} />
                        </Card>
                        <Card title={<h6>PREVIEW :: Structured Query Language</h6>}>
                            <SyntaxHighlighter language="javascript" style={vs}>
                                {viewSql}
                            </SyntaxHighlighter>
                        </Card>
                    </Col>
                </Row>
                <Form.Item {...tailLayout} className={"text-right mt-2"}>
                    <Button hidden={isProd} type={"primary"} htmlType={"submit"}>Submit</Button>
                </Form.Item>
            </Card>
        </Form>
    )
}

export default SAEConfigContainer;
