import {Helmet} from "react-helmet-async";
import Schedule from "../../../components/common/odm/Schedule";
import React from "react";
import ODMTabs from "../../../layouts/Tab/odm";

const ProtocolPage = () => {

    return (
        <ODMTabs menu={'protocol'}>
            <Helmet title={'ODM Designer v2.0.0 :: Protocol (SoA)'}/>
            <Schedule defType={'protocol'} refType={'studyEventGroupRef'} refTitle={'Event Group'}
                      defTitle={<span><i className='icmn-calendar mr-2'/>SoA</span>}
            />
        </ODMTabs>
    )

}

export default ProtocolPage;
