import React, {useEffect} from 'react'
import {Outlet, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {metadataActions} from "../../redux/module/metadata";

const ODMPage = () => {

    const params = useParams();
    const dispatch = useDispatch();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);

    useEffect(() => {
        console.log('metaData 정보 조회 요청...', params);
        dispatch(metadataActions.fetchMetaData({sid: params.sid, id: params.id}));
    }, []);

    useEffect(() => {
        if (metaDataStore.loaded === true) {
            const {sid, id} = params;
            sessionStorage.setItem(`${sid}_${id}_studyOID`, metaDataStore.versionInfo.studyOID);
            sessionStorage.setItem(`${sid}_${id}_mdvOID`, metaDataStore.versionInfo.oid);
            sessionStorage.setItem(`${sid}_${id}_patch`, metaDataStore.versionInfo.patch);
        }
    }, [metaDataStore]);

    return (
        <>
            {
                metaDataStore.loaded === true &&
                <Outlet/>
            }
        </>
    )
};

export default ODMPage;
