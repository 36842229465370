import {Nav, NavItem, NavLink, TabContent} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import React from "react";
import classNames from "classnames";
import '../style.scss';

const ManagementTabs = ({children, menu}) => {

    const params = useParams();
    const navigate = useNavigate();

    return (
        <>
            <Nav tabs className={`management-tabs`}>

                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/sites`)}
                             className={classNames({active: menu === 'sites'})}>
                        <i className='fe fe-grid mr-2'/>Manage Sites
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/users`)}
                             className={classNames({active: menu === 'users'})}>
                        <i className={'fe fe-users mr-2'}/>Manage Users
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className={`p-3 content`}>
                {children}
            </TabContent>
        </>
    )
}

export default ManagementTabs;