import {useLocation, useNavigate, useParams} from "react-router-dom";
import queryString from "query-string";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Card, Col, DatePicker, Form, Input, Row, Tag} from "antd";
import {CardBody, CardFooter, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle} from "../../../../lib/BuilderUtils";
import DefaultLoading from "../../DefaultLoading";
import dayjs from 'dayjs';

const CRFVersionEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();

    //query-string이 존재하면 studyID에 해당하는 값 불러오기.
    const params = useParams();
    const query = queryString.parse(location.search);
    const [crfVersion, setCrfVersion] = useState(null);
    const [format, setFormat] = useState(null);
    const [form] = Form.useForm();

    useEffect( () => {

        (async () => {
            //GET Request
            try {
                //REST API Request
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/versions/edit`, {params: query});

                //State Setting
                if(status === httpStatus.ok) {

                    //DateFormat 양식 설정
                    const dateFormatType = data.designMetaDataVersion?.dateFormatType?.key??'NONE';
                    const dateFormat = data.dateFormatTypes.find(s => s.key === dateFormatType)?.format?.toUpperCase();
                    setFormat(dateFormat);
                    const effectiveDate = (data.designMetaDataVersion?.effectiveDate!=null)?dayjs(data.designMetaDataVersion?.effectiveDate).format(dateFormat):'NONE';

                    setCrfVersion({...data.designMetaDataVersion,
                        effectiveDate, dateFormatType
                    });
                }
            } catch (error) {
                errorHandle(error, navigate, `/studies`);
            }
        })();
    }, [])

    return (
        <>
            {
                crfVersion === null &&
                <DefaultLoading />
            }
            {
                crfVersion !== null &&
                <DefaultCard>
                    <CardHeader>
                        <h3>CRF Version</h3>
                    </CardHeader>
                    <Form layout="vertical" form={form} initialValues={crfVersion}>
                        <CardBody>
                            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                <Col span={'16'}>
                                    <Form.Item name={'name'} label={'Version'} valuePropName={'children'}>
                                        <Card styles={{body: {padding:'0.25rem 0.5rem 0.25rem 0.5rem'}}}></Card>
                                    </Form.Item>
                                </Col>
                                <Col span={'3'}>
                                    <Form.Item name={'dateFormatType'} label={'Date Format'} hidden={format == null} valuePropName={'children'}>
                                        <Tag className={'width-100p p-1'}></Tag>
                                    </Form.Item>
                                </Col>
                                <Col span={'5'}>
                                    <Form.Item name={'effectiveDate'} label={'Effective Date'} hidden={format == null} valuePropName={'children'}>
                                        <Tag className='width-100p p-1'></Tag>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                <Col span={'24'}>
                                    <Form.Item name={'description'} label={'Description'}
                                               valuePropName={'children'}>
                                        <Card styles={{body:{whiteSpace:'pre-line', padding:'1rem'}}}></Card>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className='text-right'>
                            <Button type={'default'} onClick={() => { navigate(`/studies/${params.sid}/versions`); }}
                                    className={'mr-3'} icon={<i className='fa fa-list mr-2'></i>}>to List</Button>
                        </CardFooter>
                    </Form>
                </DefaultCard>
            }
        </>
    )

}

export default CRFVersionEdit;
