import {PrimaryCenterCard} from "../../pages/PageStyleBox";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import React from "react";


const PrimaryLoading = () => {

    return (
        <PrimaryCenterCard>
            <Spin size="large" indicator={<LoadingOutlined style={{fontSize: 64}} spin/>} />
        </PrimaryCenterCard>
    )
}

export default PrimaryLoading;