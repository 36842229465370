import ODMTabs from "../../../layouts/Tab/odm";
import {Helmet} from "react-helmet-async";
import React from "react";
import DatasetContainer from "../../../container/odm/DatasetContainer";

const DataSetPage = () => {
    return (
        <ODMTabs menu={'dataset'}>
            <Helmet title={`ODM Designer v2.0.0 :: DATA SET`}/>
            <DatasetContainer />
        </ODMTabs>
    )
}
export default DataSetPage;
