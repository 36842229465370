import {Helmet} from "react-helmet-async";
import ManageAccess from "../../../../components/common/builder/studies/ManageAccess";
import React from "react";

const ManageAccessPage = () => {

    return (
        <>
            <Helmet title={'BUILDER :: Manage Access'}/>
            <ManageAccess/>
        </>
    )
}

export default ManageAccessPage;