import React, {useState} from 'react'
import {Drawer} from 'antd'
import MenuLeft from './MenuLeft'
import MenuTop from './MenuTop'
import style from './style.module.scss'


const Menu = ({ isMobileMenuOpen, isMobileView, menuLayoutType, leftMenuWidth, edcStudyInfo}) => {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(isMobileMenuOpen);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  const GetMenu = () => {
    if (isMobileView) {
      return (
        <div>
          <div
            className={style.handler}
            onClick={toggleMobileMenu}
            onFocus={(e) => {
              e.preventDefault()
            }}
            onKeyPress={toggleMobileMenu}
            role="button"
            tabIndex="0"
          >
            <div className={style.handlerIcon} />
          </div>
          <Drawer
            closable={false}
            visible={isMobileMenuOpen}
            placement="left"
            className={style.mobileMenu}
            onClose={toggleMobileMenu}
            maskClosable
            getContainer={null}
            width={leftMenuWidth}
          >
            <MenuLeft />
          </Drawer>
        </div>
      )
    }
    if (menuLayoutType === 'top') {
        return <MenuTop edcStudyInfo={edcStudyInfo} menuColor={'white'} version={'BUILDER'} />
    }
    if (menuLayoutType === 'nomenu') {
      return null
    }
    return <MenuLeft />
  }

  return GetMenu()
}

export default Menu;
