import {Form, Input} from 'antd';
import {useEffect, useState} from "react";
import {isEmpty} from "../../../../lib/StringUtils";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../../lib/ODMUtils";
import {useTranslation} from "react-i18next";
const {Item} = Form;

const FormInputName = ({name, defType, form}) => {

    const metaDataStore = useSelector((store) => store.metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const [names, setNames] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        const mdv = metaDataStore.study.metaDataVersion[0];
        const defNames = [];

        //현재 수정중인 데이터면,
        mdv[defType].forEach(def => {
            if (name !== def.name) {
                defNames.push(def.name);
            }
        })
        setNames(defNames);
        return () => {
            setNames([]);
        }
    }, [metaDataStore, name]);

    const handleChange = (e) => {
        form.setFieldValue('name', e.target.value);
    }

    return (
        <Item name={'name'} label={<span className='text-primary'>Name</span>}
              required={true}
              rules={[
                  {required: true, message: t('message.warning.required-entry')},
                  // {max:50, message: '50자 까지 입력 가능 합니다.'},
                  ({ getFieldValue }) => ({
                      validator(_, value) {
                          if(isEmpty(value)){return Promise.resolve();}
                          else if(isEmpty(value.trim())){return Promise.reject(new Error(t('message.blank.values.not.allowed')))}
                          else if(value.length > 255) {return Promise.reject(new Error(t('message.name.can.be.up.to.255.characters.long')))}

                          // const result = contains(value);
                          // if(result !== true) {return Promise.reject(new Error(result));}
                          else {return Promise.resolve();}
                      }
                  })
              ]}>
            <Input.TextArea readOnly={isProd} rows={1} autoSize={true} onChange={handleChange} />
        </Item>
    );
}

export default FormInputName;
