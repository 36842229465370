import React, {useState} from "react";
import {useSelector} from "react-redux";
import CardForm from "./CardForm";
import CDISCWizard from "../../wizard/CDISCWizard";
import {mdiFileDocumentMultipleOutline} from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import {isEnvProduction} from "../../../lib/ODMUtils";
import LibraryInterfaceDef from "../builder/global-library/interface/LibraryInterfaceDef";

const FormList = ({title, propName, onCopy, onSelect, onDelete, onClickAddEvent, selectedOID}) => {
    const [keyword, setKeyword] = useState("");
    const [visibleWizard, setVisibleWizard] = useState(false);
    //Library Interface에 대한 정보
    const [libraryOpen, setLibraryOpen] = useState(false);

    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const metaDataVersion = metaDataStore.study.metaDataVersion[0];

    const addNew = (itemType) => {
        setKeyword("");
        if(itemType === undefined) {
            onClickAddEvent();
        } else {
            onClickAddEvent(itemType);
        }
    }

    return (
        <>
            <CDISCWizard visibleWizard={visibleWizard} setVisibleWizard={setVisibleWizard}/>
            <LibraryInterfaceDef open={libraryOpen} setOpen={setLibraryOpen} />
            <div className={'card'}>
                <div className={'card-header card-header-flex'}>
                    <div className="d-flex flex-column justify-content-center">
                        <h4 className={'header-title text-truncate'}><Icon path={mdiFileDocumentMultipleOutline} size={1} className={'mr-2'} />Forms({metaDataVersion.formDef.length})</h4>
                    </div>
                    <div className="ml-auto d-flex flex-column justify-content-center">
                        <div className={classNames('', {
                            ['d-none']:isProd
                        })}>
                            <button className={'btn btn-secondary btn-squared mr-2'} onClick={() => setLibraryOpen(true)}>
                                <i className={'fa fa-database'}></i>&nbsp;Library
                            </button>
                            <button className={'btn btn-info btn-squared mr-2'} onClick={() => setVisibleWizard(true)}>
                                <i className={'fa fa-magic'}></i>&nbsp;Wizard
                            </button>
                            <button disabled={selectedOID !== ""} className={'btn btn-primary btn-squared'} onClick={() => addNew("")}>
                                <i className={'fa fa-plus'}></i> New
                            </button>
                        </div>
                    </div>
                </div>
                <div className={'card-body definitions'}>
                    <div className={'row mb-3'}>
                        <div className={'col-12'}>
                            <div className={'input-group'}>
                                <div className={'input-group-prepend'}>
                                    <span className={'input-group-text input-lg'} ><i className={'fa fa-search icon-lg'}></i></span>
                                </div>
                                <input className={'form-control'} placeholder={'...'} onChange={e => setKeyword(e.target.value)}></input>
                            </div>
                        </div>
                    </div>
                    <div className={'row pl-0 ml-0'}>
                        {metaDataVersion[propName].filter(i => keyword === "" || (i.oid.toUpperCase().indexOf(keyword.toUpperCase()) !== -1 || i.name.toUpperCase().indexOf(keyword.toUpperCase()) !== -1))
                            .map((data, index) =>
                                <CardForm key={`form-thumb-${data.oid}`} oid={data.oid} name={data.name} disabled={selectedOID === data.oid}
                                            verName={metaDataVersion.name} onCopy={onCopy} onSelect={onSelect} selectedOID={selectedOID}
                                            onDelete={onDelete} />
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}


export default FormList;
