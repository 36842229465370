import {Helmet} from "react-helmet-async";
import GlobalLibraryEdit from "../../../../components/common/builder/global-library/GlobalLibraryEdit";

const GlobalLibraryEditPage = () => {

    return (
        <>
            <Helmet title={"BUILDER :: Global Library"} />
            <GlobalLibraryEdit />
        </>

    )

}

export default GlobalLibraryEditPage;