import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {metadataActions} from "../../../redux/module/metadata";
import {useLocation, useParams} from "react-router-dom";
import queryString from "query-string";
import {descriptionCheck, toMetaData} from "../../../lib/ODMUtils";
import FormDef from '../modal/odm/FormDef';
import FormList from "../../../components/common/odm/FormList";


/**
 * 처음 Forms 화면에서 List일 때, 동작하는 Container
 * @returns {JSX.Element}
 * @constructor
 */
const FormListContainer = () => {

    const location = useLocation();
    const {oid} = queryString.parse(location.search);
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const [metaData, setMetaData] = useState(toMetaData(metaDataStore.study));

    //공통
    const propName = 'formDef';
    const [initialValue, setInitialValue] = useState({});
    const [selectedOID, setSelectedOID] = useState("");

    const [metaDataVersion, setMetaDataVersion] = useState([]);
    const [create, setCreate] = useState(false);
    const [preview, setPreview] = useState(false);
    // const [actionType, setActionType] = useState(CREATE);
    const [languages, setLanguages] = useState({});

    //컴포넌트에 따라 필요한 정보
    const [formVisible, setFormVisible] = useState(false); //Form 정보를 직접 입력 할 때의 visible 여부.
    const [formType, setFormType] = useState([]);
    const [reportDesignType, setReportDesignType] = useState([]);

    const [formDef, setFormDef] = useState(initialValue); //현재 Form에 대한 정보
    const [yesOrNo, setYesOrNo] = useState([]); //현재 Form에서 Yes/No 선택하는 정보


    useEffect(() => {
        if (metaDataStore) {
            setInitialValue(metaDataStore.initialValue.form);
            setMetaDataVersion(metaDataStore.study.metaDataVersion[0]);
            setYesOrNo(metaDataStore.yesOrNo);
            setLanguages(metaDataStore.languages);
            setFormType(metaDataStore.formType);
            setReportDesignType(metaDataStore.reportDesignType);

            //MetaData Map 정보 변경
            setMetaData(toMetaData(metaDataStore.study));
        }

        if (metaDataVersion) {
            if (oid !== undefined) {
                onGetData(oid);
            }
        }

    }, [metaDataStore, metaDataVersion]);


    const onGetData = (oid) => {
        setSelectedOID(oid);
        setFormDef(metaDataVersion.formDef.find(f => f.oid === oid));
        setCreate(false);
        setFormVisible(true);
    };


    //개정 된 버전은 삭제(O)
    //신규 버전은 사용되지 않는 경우 삭제 가능(O)
    const onDelete = (oid) => {
        const deleteFormDef = {...metaDataVersion.formDef.find(f => f.oid === oid)};
        deleteFormDef.attr = propName;
        deleteFormDef.params = params; //SessionStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
        dispatch(metadataActions.delete(deleteFormDef));
    }

    //FormDef 화면의 닫기 동작
    const onCancel = () => {
        if (!preview) {
            setFormDef({});
            setSelectedOID("");
        }
        setFormVisible(false);
    }

    //FormDef의 등록/수정 기능
    const onFinish = (formDef) => {
        //기존 form의 itemGroupRef 정보 손실을 막기 위함.
        const originFormDef = metaData.form.get(formDef.oid);
        formDef = {...originFormDef, ...formDef};
        descriptionCheck(formDef);

        formDef.attr = propName;
        formDef.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
        if (create) {
            dispatch(metadataActions.create(formDef));
        } else {
            //const idx = metaDataVersions[selectedVal.verIndex][propName].findIndex(o => o.oid === formDef.oid);
            dispatch(metadataActions.update(formDef));
        }

        setFormDef(formDef);
        onCancel();
    };

    //신규 버튼 이벤트
    const onClickAddEvent = () => {
        //Drawer 형태로 Form 기본정보만 가지고 옴
        setFormDef(initialValue);
        setSelectedOID("");
        setCreate(true);
        setPreview(false);
        setFormVisible(true);
    };

    return (
        <>
            <FormList title={'Form'} propName={propName} onSelect={onGetData} onDelete={onDelete}
                      create={create} onClickAddEvent={onClickAddEvent} selectedOID={selectedOID}>
            </FormList>
            <FormDef languages={languages} selectedOID={selectedOID}
                     formDef={formDef} visible={formVisible}
                     setVisible={setFormVisible} formType={formType}
                     reportDesignType={reportDesignType}
                     setFormDef={setFormDef} onFinish={onFinish}
                     yesOrNo={yesOrNo} onCancel={onCancel}/>
        </>
    )
}

export default FormListContainer;
