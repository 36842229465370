import {Helmet} from "react-helmet-async";
import ODMTabs from "../../../../layouts/Tab/odm";
import React from "react";
import StudyStructure from "../../../../components/common/odm/StudyStructure";

const StudyStructurePage = () => {

    return (
        <ODMTabs menu={'protocol'}>
            <Helmet title={'ODM Designer v2.0.0 :: Protocol (SoA)'}/>
            <StudyStructure />
        </ODMTabs>
    )
}

export default StudyStructurePage;