import React, {useEffect, useState} from 'react'
import {Menu} from 'antd'
import {Link, useLocation, useMatch} from 'react-router-dom'
import classNames from 'classnames'
import style from './style.module.scss'
import {withTranslation} from "react-i18next";

const MenuTop = ({t, menuColor = 'white', version, edcStudyInfo}) => {

    const location = useLocation();
    const matchStudy = useMatch('/studies/*')
    const matchSponsor = useMatch('/sponsors/*')
    const matchGlobalLibrary = useMatch('/global-library/*');

    const [selectKey, setSelectKey] = useState('');

    //현재 도메인 주소 확인 후 특정 키워드가 포함된지 확인
    useEffect(() => {
        if(matchStudy) {
            setSelectKey('studies');
        } else if(matchSponsor) {
            setSelectKey('sponsor');
        } else if(matchGlobalLibrary) {
            setSelectKey('global-library');
        }

    }, [location.pathname])

    const menuItems = [
            {
                key: 'studies',
                icon: <i className='fe fe-edit'/>,
                label: <Link to={`/studies`}>Studies</Link>,

            },
            {
                key: 'sponsor',
                icon: <i className='fa fa-handshake-o'/>,
                label: <Link to={`/sponsors`}>Sponsors</Link>,

            },
            {
                key: 'global-library',
                icon: <i className='fe fe-book-open'/>,
                label: <Link to={`/global-library`}>Global Library</Link>,
            }
    ]

    return (
        <div
            className={classNames(`${style.menu}`, {
                [style.white]: menuColor === 'white',
                [style.gray]: menuColor === 'gray',
                [style.dark]: menuColor === 'dark',
            })}
        >
            <div className={style.logoContainer}>
                <div className={style.logo}>
                    {/*<div className={style.name}>{logo}</div>*/}
                    <div className={style.name}>
                        <img src="/static/images/redpine/logo-redpine-100.png"/>
                    </div>
                    <div className={`${style.descr} text-capitalize`}>
                        {version}
                    </div>
                </div>
            </div>
            <div className={style.navigation}>
                {/*<Menu*/}
                {/*    theme={'light'}*/}
                {/*    mode="horizontal"*/}
                {/*    key={'builder-menu'}*/}
                {/*    selectedKeys={[match.pathnameBase]}*/}
                {/*    items={mainMenuItems} />*/}
                <Menu mode="horizontal" key={'builder-menu'} items={menuItems} selectedKeys={selectKey} />
            </div>
        </div>
    )
}
export default withTranslation()(MenuTop);
