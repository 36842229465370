import {Badge, Card, Collapse, Input, Tooltip} from "antd";
import {Draggable, Droppable} from "react-beautiful-dnd";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RefItemList, StudyGroupRefItem} from "../../../container/build-tool/StyleBox";
import {isEnvProduction, toMetaData} from "../../../lib/ODMUtils";
import ScheduleRefCard from "./ScheduleRefCard";
import PerfectScrollbar from "react-perfect-scrollbar";
import classNames from "classnames";

const {Panel} = Collapse;

function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
        return style;
    }
    return {
        ...style,
        // cannot be 0, but make it super tiny
        transitionDuration: `0.0001s`,
    };
}

const ScheduleRefList = ({refType, refTitle, refList, setRefList, isInclude}) => {

    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const [metaDataVersion, setMetaDataVersion] = useState(metaDataStore.study.metaDataVersion[0]);
    const [metaData, setMetaData] = useState(toMetaData(metaDataStore.study));

    const [filterList, setFilterList] = useState(null);

    useEffect(() => {
        if (metaDataStore.loaded) {
            //When loaded, action
            setMetaDataVersion(metaDataStore.study.metaDataVersion[0]);
            setMetaData(toMetaData(metaDataStore.study));
        }

    }, [metaDataStore]);

    useEffect(() => {

        if (metaDataVersion) {
            switch (refType) {
                case 'formRef':
                    const formRef = Array.from(metaDataVersion.formDef);
                    setRefList(formRef);
                    break;
                case 'studyEventRef':
                    setRefList(Array.from(metaDataVersion.studyEventDef));
                    break;
                case 'studyEventGroupRef':
                    setRefList(Array.from(metaDataVersion.studyEventGroupDef));
                    break;
            }
        }

    }, [metaDataVersion, metaData]);

    const onSearch = (keyword) => {

        if (keyword === '') {
            setFilterList(null);
        } else {
            setFilterList(refList.filter(refItem => refItem.name.includes(keyword)))
        }
    }

    return (
        <>
            {
                refList !== null &&
                <>
                    <Card key={refType} bordered={false} title={(
                        <>
                            <span style={{fontSize:'14px'}}>{refTitle}</span>
                            <span className='ml-2 badge badge-pill badge-danger'>{filterList !== null ? filterList?.length : refList?.length}</span>
                            <Input.Search style={{width: '45%', float: 'right'}} onSearch={onSearch}/>
                            <hr className={'my-3'}/>
                        </>
                    )}
                          styles={{body:{padding: 0, height:'40rem'}}}>
                        <PerfectScrollbar>
                            <Droppable droppableId={`new${refType}`}
                                       direction="vertical"
                                       isDropDisabled={true}
                                       type={refType}
                            >
                                {
                                    (provided, snapshot) => (
                                        <RefItemList
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            isDraggingOver={snapshot.isDraggingOver}
                                            protocol={refType==='studyEventGroupRef'?1:0}
                                        >
                                            {
                                                (filterList !== null ? filterList : refList).map((refItem, index) => (
                                                        <Draggable
                                                            key={refItem.oid}
                                                            draggableId={refItem.oid}
                                                            index={index} isDragDisabled={isProd}
                                                            disableInteractiveElementBlocking={true}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <Tooltip title={
                                                                    (refType === 'studyEventGroupRef'&&refItem.studyEventRef?.length<=0)?
                                                                    `[${refItem.name}]에 참조된 Event가 존재하지 않습니다.`:refItem.name
                                                                }>
                                                                    <div key={index}
                                                                         ref={provided.innerRef}
                                                                         {...provided.draggableProps}
                                                                         {...provided.dragHandleProps}
                                                                         data-rbd-drag-handle-draggable-id={
                                                                             isProd?null:((refType === 'studyEventGroupRef'&&refItem.studyEventRef?.length<=0)?null:refItem.oid)
                                                                         }
                                                                         data-rbd-drag-handle-context-id={
                                                                             isProd?null:((refType === 'studyEventGroupRef'&&refItem.studyEventRef?.length<=0)?null:provided.draggableProps['data-rbd-draggable-context-id'])
                                                                         }
                                                                         style={getStyle(provided.draggableProps.style, snapshot)}
                                                                    >
                                                                        {
                                                                            //Form
                                                                            refType === 'formRef' &&
                                                                            <ScheduleRefCard
                                                                                dragging={snapshot.isDragging}
                                                                                refType={refType} refInfo={refItem}
                                                                                metaData={metaData}/>
                                                                        }

                                                                        {
                                                                            isInclude(refType, refItem.oid) &&
                                                                            refType === 'studyEventRef' &&
                                                                            <ScheduleRefCard
                                                                                dragging={snapshot.isDragging}
                                                                                refType={refType} refInfo={refItem}
                                                                                metaData={metaData}/>
                                                                        }

                                                                        {
                                                                            isInclude(refType, refItem.oid) &&
                                                                            refType === 'studyEventGroupRef' &&
                                                                            <StudyGroupRefItem isDragging={snapshot.isDragging}
                                                                                               style={{
                                                                                                   background: refItem.studyEventRef?.length>0?false:'rgba(255,81,81,0.3)'
                                                                                               }}>

                                                                                <i className={classNames('mr-2 fa', {
                                                                                    ['fa-folder-open-o']: refItem.studyEventRef.length > 0,
                                                                                    ['fa-folder-o']: refItem.studyEventRef.length <= 0,
                                                                                })}/>
                                                                                <Badge count={refItem.studyEventRef.length} offset={[10,0]} size={'small'} style={{backgroundColor: '#57b3ee'}}>
                                                                                    {refItem.name}
                                                                                </Badge>
                                                                            </StudyGroupRefItem>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        snapshot.isDragging &&
                                                                        <>
                                                                            {
                                                                                refType === 'formRef' &&
                                                                                <ScheduleRefCard refType={refType} dragging={false} refInfo={refItem} metaData={metaData}/>
                                                                            }
                                                                            {
                                                                                (isInclude(refType, refItem.oid) && refType === 'studyEventRef') &&
                                                                                <ScheduleRefCard refType={refType} dragging={false} refInfo={refItem} metaData={metaData}/>
                                                                            }
                                                                            {
                                                                                isInclude(refType, refItem.oid) &&
                                                                                refType === 'studyEventGroupRef' &&
                                                                                <StudyGroupRefItem isDragging={snapshot.isDragging}
                                                                                                   style={{
                                                                                                       background: refItem.studyEventRef?.length>0?false:'rgba(255,81,81,0.3)'
                                                                                                   }}>
                                                                                    <i className={classNames('mr-2 fa', {
                                                                                        ['fa-folder-open-o']: refItem.studyEventRef.length > 0,
                                                                                        ['fa-folder-o']: refItem.studyEventRef.length <= 0,
                                                                                    })}/>
                                                                                    <Badge count={refItem.studyEventRef.length} offset={[10,0]} size={'small'} style={{backgroundColor: '#57b3ee'}}>
                                                                                        {refItem.name}
                                                                                    </Badge>
                                                                                </StudyGroupRefItem>
                                                                            }
                                                                        </>
                                                                    }
                                                                </Tooltip>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )
                                            }
                                        </RefItemList>
                                    )}
                            </Droppable>
                        </PerfectScrollbar>
                    </Card>
                </>
            }
        </>
    )
}

export default ScheduleRefList;