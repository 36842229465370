import {Helmet} from "react-helmet-async";
import React from "react";
import ManageUsersSearch from "../../../../../components/common/builder/studies/ManageUsersSearch";
import ManagementTabs from "../../../../../layouts/Tab/management";

const ManageUsersSearchPage = () => {


    return (
        <ManagementTabs menu={'users'}>
            <Helmet title={'BUILDER :: Manage Users'}/>
            <ManageUsersSearch />
        </ManagementTabs>
    )
}

export default ManageUsersSearchPage;