import {isEmpty} from "../../lib/StringUtils";
import {Col, Form, Input, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import Text from "antd/lib/typography/Text";

const { Option } = Select;

const EditCheckSource = ({form, field, index, editMode, studyEventDefs, formDefs, itemGroupDefs, itemDefs, metaData, source}) => {

    const [sourceFunc, setSourceFunc] = useState("");

    const getFunctionName = (func) => {
        return func.split("_")[1];
    }

    useEffect(() => {

        if(source != null) {
            setSourceFunc(source?.func);
        }
    }, [source]);

    return (
        <>
            {/*<Divider orientation="left"></Divider>*/}
            <Row key={field.key + index}>
                <Col span={4}>
                    {!isEmpty(sourceFunc) &&
                        <div style={{textAlign: 'right', paddingRight: 10}}>
                            <Text italic type={"danger"}>{sourceFunc}</Text>
                        </div>
                    }
                </Col>
                <Col span={4} hidden={form.getFieldValue(["check", "source", index, "studyEventOID"])?.length == 0}>
                    <div className={'pull-left'} style={{marginRight:3,marginTop:5}}>
                        <i className="fa fa-folder-open-o text-gray-5" aria-hidden="true"></i>
                    </div>
                    <div className={'pull-right'} style={{marginRight:15,marginTop:5,color:'blue'}} hidden={isEmpty(form.getFieldValue(["check", "source", index, "studyEventOID"]))}>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </div>
                    {
                        form.getFieldValue(["check", "source", index, "studyEventIndex"]) > -1 &&
                        <div className={'pull-right mr-2 ml-1'} style={{marginRight: 0, marginTop: 5}}>
                            <span className={'badge badge-info'}>{form.getFieldValue(["check", "source", index, "studyEventIndex"])}</span>
                        </div>
                    }
                    {
                        form.getFieldValue(["check", "source", index, "prevStudyEventIndex"]) &&
                        <div className={'pull-right'} style={{marginRight: 0, marginTop: 5}}>
                            <span className={'badge badge-info'}>prev</span>
                        </div>
                    }
                    <Form.Item  name={[index, "studyEventOID"]}>
                        <Input readOnly={true} size={"small"}/>
                    </Form.Item>
                </Col>

                <Col span={4} hidden={isEmpty(form.getFieldValue(["check", "source", index, "formOID"]))}>
                    <div className={'pull-left'} style={{marginRight:3,marginTop:5}}>
                        <i className="fa fa-file-o text-gray-5" aria-hidden="true"></i>
                    </div>
                    <div className={'pull-right'} style={{marginRight:15,marginTop:5}} hidden={isEmpty(form.getFieldValue(["check", "source", index, "itemGroupOID"]))}>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </div>
                    <Form.Item  name={[index, "formOID"]}>
                        <Input readOnly={true} size={"small"}/>
                    </Form.Item>
                </Col>

                <Col span={4} hidden={isEmpty(form.getFieldValue(["check", "source", index, "itemGroupOID"]))}>
                    <div className={'pull-left'} style={{marginRight:3,marginTop:5}}>
                        <i className="fa fa-database text-gray-5" aria-hidden="true"></i>
                    </div>
                    {
                        form.getFieldValue(["check", "source", index, "itemGroupIndex"]) > -1 &&
                        <div className={'pull-right mr-3'} style={{marginRight: 0, marginTop: 5}}>
                            <span className={'badge badge-info'}>{form.getFieldValue(["check", "source", index, "itemGroupIndex"])}</span>
                        </div>
                    }
                    <Form.Item  name={[index, "itemGroupOID"]} style={{marginBottom:5,paddingBottom:0}}>
                        <Input readOnly={true} size={"small"}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.List name={[index, "sourceItems"]}>
                        {fields => (
                            <>
                                {fields.map((field, itemIndex) => {
                                    const func = form.getFieldValue(["check", "source", index, "sourceItems", itemIndex, "func"]);
                                    return (
                                        <Row key={`${field.key}_items${index}_${itemIndex}`} className={'mb-0 pb-0'}>
                                            <div className={'pull-left'} style={{marginTop:3,marginRight:5}}>
                                                {!isEmpty(func) &&
                                                    <>
                                                        <Text italic type={"danger"}>{getFunctionName(func)}</Text>
                                                        <Text type={"secondary"}> #{form.getFieldValue(["check", "source", index, "sourceItems", itemIndex, "index"])}</Text>
                                                    </>
                                                }
                                            </div>
                                            <Col span={12}>
                                                <Form.Item  name={[itemIndex, "itemOID"]}>
                                                    <Input readOnly={true} size={"small"}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
        </>
    )
}

export default EditCheckSource;
