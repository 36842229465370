import IndependentEvaluationDef from "../../components/odm/IndependentEvaluationDef";

const IndependentEvaluationContainer = () => {

    return (
        <IndependentEvaluationDef />
    )

}


export default IndependentEvaluationContainer;