import {NormalCenterCard} from "../../pages/PageStyleBox";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import React from "react";

const NormalLoading = () => {

    return (
        <NormalCenterCard>
            <Spin size="large" indicator={<LoadingOutlined style={{fontSize: 64}} spin/>} />
        </NormalCenterCard>
    )
}

export default NormalLoading;