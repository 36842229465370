import styled from "styled-components";
import {Button} from "antd";
import {useState} from "react";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../lib/ODMUtils";

const FooterWrapper = styled.div`
        padding: 0px 2rem 1rem 2rem;
        text-align: right;
    `;

const ModalFooter = ({onCancel, onSave = null, form = null, isRevision = false}) => {

    const metaDataStore = useSelector((store) => store.metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const [loading, setLoading] = useState(false);

    //onSave CALLBACK
    const handleSave = (onSave) => {
        setLoading(loading);
        onSave();
    }

    return (
        <FooterWrapper>
            <Button className={'mr-2'} onClick={onCancel}>Cancel</Button>
            {
                (!isProd && form !== null) &&
                <Button type={'primary'} onClick={() => form.submit()} >
                    {/* isRevision이 True이면, Save -> Revision으로 표시. */}
                    { isRevision ? (<>Revision</>) : (<>Save</>) }
                </Button>
            }
            {
                (!isProd && onSave !== null) &&
                <Button type={'primary'} onClick={() => handleSave(onSave)}
                        loading={loading}>
                    Save
                </Button>
            }
        </FooterWrapper>
    )
}

export default ModalFooter;