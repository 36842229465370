import {Helmet} from "react-helmet-async";
import React from "react";
import ManageUsersEdit from "../../../../../components/common/builder/studies/ManageUsersEdit";
import ManagementTabs from "../../../../../layouts/Tab/management";

const ManageUsersEditPage = () => {

    return (
        <ManagementTabs menu={'users'}>
            <Helmet title={'BUILDER :: Manage Users'}/>
            <ManageUsersEdit/>
        </ManagementTabs>
    )
}

export default ManageUsersEditPage;