import React from "react";
import {Button} from "antd";
import style from "./style.module.scss";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../lib/ODMUtils";

const DefinitionSubmit = ({onReset, onCancel, onSubmit}) => {

    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);

    return (
        <div className="text-right pad-all p-3">
            <Button hidden={isProd} className={`mr-2 ${style.btnShadow}`} onClick={() => onReset()} >
                <i className='fa fa-refresh mr-2'/>Reset
            </Button>
            <Button className={`mr-2 ${style.btnShadow}`} onClick={() => onCancel()}>
                <i className='fa fa-list-ul mr-2' />List
            </Button>
            <Button hidden={isProd} className={style.btnShadow} type={'primary'} onClick={() => onSubmit()}>
                <i className='fa fa-check mr-2' />Save
            </Button>
        </div>
    )
}

export default DefinitionSubmit;
