import {useEffect, useState} from "react";
import {Button, message, Upload, Form, Dropdown, Menu, Space} from "antd";
import {
    CalendarOutlined,
    DownloadOutlined,
    ReloadOutlined,
    HistoryOutlined,
    InfoCircleOutlined,
    DownOutlined,
    FileExcelOutlined,
    OrderedListOutlined, SettingFilled
} from "@ant-design/icons";
import React from "react";
import {isEmpty} from "../../../lib/StringUtils";
import {Link, useNavigate, useParams, useLocation} from "react-router-dom";
import axios from "axios";
import {httpStatus} from "../../../lib/createRequestSaga";
import {errorHandle} from "../../../lib/BuilderUtils";
import {toMetaData} from "../../../lib/ODMUtils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import './style.scss';
import StudyStructureUploadHistory from "./StudyStructureUploadHistory";
import StudyStructureResetHistory from "./StudyStructureResetHistory";
import StudyStructureCurrentRandomNo from "./StudyStructureCurrentRandomNo";
import classNames from "classnames";
import styles from "./style.module.scss";
import {useTranslation} from "react-i18next";

const StudyStructureFooter = ({studyStructure, loaded, onDownload, metaDataStore, form, disableView, useIPManagement, reload, setReload}) => {

    const [siteCodes, setSiteCodes] = useState(null);
    const [uploadHistoryVisible, setUploadHistoryVisible] = useState(false);
    const [currentRandomNoInfo, setCurrentRandomNoInfo] = useState(false);
    const [resetHistoryVisible, setResetHistoryVisible] = useState(false);

    const metaData = toMetaData(metaDataStore.study);
    const studyOID = metaData?.studyOID;

    const randomNumberDef = metaDataStore?.study?.basicDefinitions?.randomNumberDef;
    const randomNumberFormat = randomNumberDef?.format;
    const seqLength = randomNumberDef?.leftPadWithZero === true ? randomNumberDef?.length : 0;
    const stratificationCodes = studyStructure?.stratification?.stratificationInfos?.map(info => info?.code);
    const stratificationLabels = studyStructure?.stratification?.stratificationInfos?.map(info => info?.label);
    const useStratification = studyStructure?.useStratification;
    const range = studyStructure?.range;

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const {t} = useTranslation();

    useEffect(() => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params?.sid}/sites`);
                if(status === httpStatus.ok) {
                    const result = data?.studySites?.map(site =>site?.oid)
                    setSiteCodes(result.join('|'));
                }
            } catch (e) {
                errorHandle(e, navigate, '');
            }
        })()
    }, [])

    const onReset = (studyOID) => {
        if(disableView){
            message.error(t('message.cannot.reset'));
        } else {
            const resetModal = withReactContent(Swal);
            resetModal.fire({
                title: <strong>Randomization Info Reset</strong>,
                icon: 'info',
                iconColor: 'skyblue',
                html: `<Space>
                        <h6 class="alert alert-info">
                          ${t('message.pls.enter.your.password.to.reset.uploaded.randomization.info1')} </br> ${t('message.pls.enter.your.password.to.reset.uploaded.randomization.info2')}
                        </h6>
                   </Space>`,
                input: "password",
                inputPlaceholder: ('password'),
                inputAttributes: {
                    autocomplete: 'off',
                },
                showCancelButton: true,
                cancelButtonText: `<i class="fa fa-close" /> cancel`,
                confirmButtonText: `<i class="fa fa-check" /> submit`,
                showLoaderOnConfirm: true,
                preConfirm: (password) => {
                    if (password === '') {
                        resetModal.showValidationMessage('password');
                        return;
                    }
                    return axios({
                        url: `/api/builder/studies/stratification/reset`,
                        data: {
                            password: password,
                            oid: studyOID,
                        },
                        method: 'put'
                    }).then(response => {
                        message.success(response?.data?.message);
                        setReload(Math.random());
                        navigate(location.pathname);
                    }).catch(({response: {data}}) => {
                        resetModal.showValidationMessage(data.message);
                    })
                },
            })
        }
    }

    const uploadProps = {
        name: "file",
        headers: {authorization: "authorization-text", contentType: 'application/json'},
        method: "POST",
        data : {
            siteCodes : siteCodes?.toString(),
            stratificationCodes : stratificationCodes?.join(",").toString(),
            stratificationLabels : stratificationLabels?.join(",").toString(),
            randomizationRange  : studyStructure?.range,
            labels  : studyStructure?.arms?.map(arm => arm?.name),
            randomNumberFormat : randomNumberDef?.format,
            seqLength : seqLength,
            useStratification : useStratification
        },
        action : `/api/builder/studies/${studyOID}/uploadStratification`,
        customRequest : async({action, data, file, headers, filename, onSuccess, onError, showUploadList}) => {

            const formData = new FormData();
            if(data){
                Object.keys(data).forEach(key => {
                    formData.append(key, data[key]);
                });
            }
            formData.append(filename, file);
            const controller = new AbortController();
            const signal = controller.signal;

            await axios.post(action, formData, headers)
                .then((response) => {
                    onSuccess(response, file);
                })
                .catch((e)=> onError(e))

        },
        beforeUpload : (file, onSuccess, onError, showUploadList) => {
            let fileType = file.name.split('.').pop().toLowerCase();
            const isXlsx = fileType === 'xlsx'

            if(!isXlsx){
                message.error(`${file.name} is not a xlsx file`);
                return  Upload.LIST_IGNORE
            }
            // validateFile(file,onSuccess, onError);
        },
        onChange: (info) => {
            // console.log(info.fileList);
        },
        onSuccess:(response, file, showUploadList) => {
            console.log(response);
            if(response?.status===200){
                const result = response?.data?.result;
                if('error' === result){
                    message.error(t('message.unable.to.upload.the.file'));
                } else if('noSave' === result) {
                    message.success(t('message.this.data.has.already.been.saved'));
                } else {
                    message.success(t('message.file.name.upload.has.been.completed',{name : file.name}));
                }
                setReload(Math.random());
                navigate(location.pathname);
            }

        },
        onError: (error)=>{
            errorHandle(error);
        },
        showUploadList : false
    }

    const showHistoryDrawer = (target) => {
        switch(target) {
            case 'upload':
                setUploadHistoryVisible(true);
                break;
            case 'reset':
                setResetHistoryVisible(true);
                break;
            case 'current' :
                setCurrentRandomNoInfo(true);
                break;
        }
    };

    const onHistoryCloseDrawer = (target) => {
        switch(target) {
            case 'upload':
                setUploadHistoryVisible(false);
                break;
            case 'reset':
                setResetHistoryVisible(false);
                break;
            case 'current' :
                setCurrentRandomNoInfo(false);
                break;
        }
    }

    const infoMenu = {
        items: [
            {
                key: 'upload',
                icon: <HistoryOutlined />,
                label: 'Upload History',
            },
            {
                key: 'reset',
                icon: <HistoryOutlined />,
                label: 'Reset History',
            },
            {
                key: 'current',
                icon: <OrderedListOutlined />,
                label: 'Current Random Number',
            }
        ],
        onClick: ({key}) => {
            showHistoryDrawer(key);
        }
    }


    return (
        <div style={{zIndex:10}}
             className={classNames({[`${styles.blockingAreaFooter}`]: disableView})}>
            <Space wrap style={{marginTop:15}}>
                {
                    loaded &&
                    <>
                        <Button className={'mr-2'} icon={<ReloadOutlined />} onClick={()=>onReset(studyOID)}>Reset</Button>
                        <Button className={'mr-2'} style={{zIndex:15}} icon={<DownloadOutlined/>}  onClick={() => onDownload()}>Download</Button>
                        <Upload style={{zIndex:15}} className={'mr-2'} {...uploadProps} maxCount={1} disabled={isEmpty(randomNumberFormat)}>
                            <Button style={{zIndex:15}} icon={<FileExcelOutlined />}>Upload</Button>
                        </Upload>
                        <Dropdown menu={infoMenu} style={{zIndex:15}}>
                            <Button style={{zIndex:15}}>
                                <Space>
                                    <InfoCircleOutlined /> Info <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </>

                }
                <Button style={{zIndex:15}}
                        type={'default'}
                        icon={<CalendarOutlined/>}
                        onClick={() =>{
                            navigate(`/studies/${params?.sid}/v/${params?.id}/protocol`)
                        }}>Protocol</Button>
                <Button type={'primary'} htmlType={'submit'}> Save</Button>
            </Space>
            <StudyStructureUploadHistory showUploadHistory={uploadHistoryVisible}
                                         onHistoryCloseDrawer={onHistoryCloseDrawer}
                                         range={range}
                                         reload ={reload} />
            <StudyStructureResetHistory showResetHistory={resetHistoryVisible}
                                        onHistoryCloseDrawer={onHistoryCloseDrawer}
                                        reload ={reload}/>
            <StudyStructureCurrentRandomNo showCurrentRandomNo={currentRandomNoInfo}
                                           onHistoryCloseDrawer={onHistoryCloseDrawer}
                                           useStratification={useStratification}
                                           range={range}
                                           reload={reload}/>
        </div>
    )
}

export default StudyStructureFooter;