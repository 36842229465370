import {Helmet} from "react-helmet-async";
import CRFVersionControl from "../../../../../components/common/builder/studies/CRFVersionControl";

const CRFVersionControlPage = () => {

    return(
        <>
            <Helmet title={'BUILDER :: CRF Version Control'} />
            <CRFVersionControl />
        </>
    )

}

export default CRFVersionControlPage;