import {Button, Tooltip} from "antd";
import React from "react";
import _ from "lodash";

const LibraryMandatoryBtn = ({data, formDef, setFormDef, itemRefMap, setItemRefMap, index, defType, itemGroupOID}) => {

    const toggleMandatory = () => {
        switch(defType) {
            case 'itemGroup':
                const cloneItemGroupRef = _.cloneDeep(formDef.itemGroupRef);
                cloneItemGroupRef[index].mandatory = cloneItemGroupRef[index].mandatory === 'YES'?'NO':'YES';
                //Mandatory가 NO일 때, collectionExceptionConditionOID 속성 삭제.
                if(cloneItemGroupRef[index].mandatory === 'NO') {
                    delete cloneItemGroupRef[index].collectionExceptionConditionOID;
                    delete cloneItemGroupRef[index].keySequence;
                }
                setFormDef({...formDef, itemGroupRef:cloneItemGroupRef})
                break;
            case 'item':
                const cloneItemRefMap = _.cloneDeep(itemRefMap);
                cloneItemRefMap.get(itemGroupOID)[index].mandatory = cloneItemRefMap.get(itemGroupOID)[index].mandatory === 'YES'?'NO':'YES';
                //Mandatory가 NO일 때, collectionExceptionConditionOID 속성 삭제.
                if(cloneItemRefMap.get(itemGroupOID)[index].mandatory === 'NO') {
                    delete cloneItemRefMap.get(itemGroupOID)[index].collectionExceptionConditionOID;
                    delete cloneItemRefMap.get(itemGroupOID)[index].keySequence;
                }
                setItemRefMap(cloneItemRefMap);
                break;
        }
    }

    return (
        <>
            { // Mandatory 표시
                defType !== 'codeList' && (
                    <>
                        <Tooltip placement="top" title={`Mandatory : ${data.mandatory}`}>
                            <Button size={'small'} tooltip={'Mandatory'}
                                    onClick={() => {toggleMandatory()}}>
                                <i className={`fa ${data.mandatory === 'YES' ? 'fa-check-square-o':'fa-square-o'}`}></i>
                            </Button>
                        </Tooltip>
                    </>
                )
            }
        </>
    )

}

export default LibraryMandatoryBtn;