import {useEffect, useState} from "react";
import useLibraryStore from "../../../../../store/libraryStore";
import {useMutation} from "react-query";
import {method, saveDefWithAxios} from "../../../../../lib/api/library";
import {message} from "antd";
import {errorHandle} from "../../../../../lib/BuilderUtils";
import {descriptionCheck} from "../../../../../lib/ODMUtils";
import LibraryItemGroupDef from "../modal/LibraryItemGroupDef";
import Icon from "@mdi/react";
import {mdiApplicationOutline, mdiTableLarge, mdiTableLargePlus} from "@mdi/js";

const LibraryItemGroupContainer = ({defOpen, setDefOpen, defItem, setDefItem, defOption, setDefOption, disabledRepeatingType}) => {

    const {library, itemGroupRepeatType, languages, yesOrNo, initialValue, updateLibrary, loaded} = useLibraryStore(state => (state));

    //컴포넌트에 따라 필요한 정보
    const propName = 'itemGroupDef';
    const [itemGroupDef, setItemGroupDef] = useState({}); //현재 Form에 대한 정보
    const [repeatTypeIcons, setRepeatTypeIcons] = useState({});
    const [create, setCreate] = useState(false);

    //UseMutation 사용
    const {mutate} = useMutation(saveDefWithAxios, {
        onSuccess:({library, resultMessage}) => {
            console.log(`ItemGroup ${create?'Create':'Update'} : `, library);
            message.success(resultMessage);
            updateLibrary(library);
            onCancel();
        },
        onError: (error) => {
            errorHandle(error);
        },
    });

    useEffect(() => {
        if(loaded) {
            setRepeatTypeIcons({
                'SIMPLE':<Icon path={mdiTableLargePlus} color={'#1d39c4'} size={0.75}/>,
                'STATIC':<Icon path={mdiTableLarge} color={'#1d39c4'} size={0.75}/>,
                'NO': <Icon path={mdiApplicationOutline} color={'#1d39c4'} size={0.75}/>
            });
        }

        if (defItem !== null) { //Update
            setItemGroupDef(defItem);
            setCreate(false);
        } else if (defItem === null) { //New
            setItemGroupDef(initialValue?.itemGroup??{});
            setCreate(true);
        }

    }, [defItem]);

    const onCancel = () => {
        setItemGroupDef({});
        setDefItem(null);
        setDefOpen(false);
        setCreate(false);
    }

    //데이터 등록/수정 함수
    const onFinish = (itemGroupDef) => {
        descriptionCheck(itemGroupDef); //description nullCheck 후, 없으면 삭제
        const {id} = library;
        itemGroupDef.id = id;
        itemGroupDef.attr = propName;
        itemGroupDef.method = create?method.post:method.put;
        mutate(itemGroupDef);

        onCancel(); //Modal 화면 사라짐.
        //현재 저장된 ItemGroupDef의 oid값 가져오기.
        setDefOption({
            ...defOption,
            itemGroupOID: itemGroupDef?.oid,
            done: true
        })
    };

    return (
        <LibraryItemGroupDef open={defOpen} setOpen={setDefOpen} languages={languages}
                             itemGroupDef={itemGroupDef} yesOrNo={yesOrNo}
                             itemGroupRepeatType={itemGroupRepeatType} repeatTypeIcons={repeatTypeIcons}
                             onFinish={onFinish} onCancel={onCancel}
                             disabledRepeatingType={disabledRepeatingType}/>
    )

}

export default LibraryItemGroupContainer;