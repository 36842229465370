import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {userActions} from "../../../../redux/module/user";
import {Navigate} from "react-router-dom";
import axios from "axios";

const Logout = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            await axios({
                url: "/logout",
                method: 'get',
            });
        })();
        dispatch(userActions.logout());
    }, []);

    return (
        <></>
    )
}

export default Logout;