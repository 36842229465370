import ODMTabs from "../../../layouts/Tab/odm";
import {Helmet} from "react-helmet-async";
import React from "react";
import SubjectStatusContainer from "../../../container/odm/SubjectStatusContainer";

const SubjectStatusPage = () => {
    return (
        <ODMTabs menu={'subject-status'}>
            <Helmet title={`ODM Designer v2.0.0 :: Subject Status`}/>
            <SubjectStatusContainer />
        </ODMTabs>
    )
}
export default SubjectStatusPage;
