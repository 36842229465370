export const getAllOID = (library) => {
    const allOIDs = [];

    (library?.codeList??[]).forEach(cl => {
        allOIDs.push(cl.oid.toUpperCase());//codelist oid
    });
    (library?.valueListDef??[]).forEach(vl => {
        allOIDs.push(vl.oid.toUpperCase());
    });
    (library?.itemDef??[]).forEach(i => {
        allOIDs.push(i.oid.toUpperCase());
    });
    (library?.itemGroupDef??[]).forEach(g => {
        allOIDs.push(g.oid.toUpperCase());
    });
    (library?.formDef??[]).forEach(f => {
        allOIDs.push(f.oid.toUpperCase());
    });

    return allOIDs;
}

/**
 * library의 definition Data 목록을 Map으로 만들어서 리턴한다.
 * @param library 접속한 Library의 정보
 * @returns {{form, itemGroup, item, codeList, valueList}}
 */
export const toLibraryMap = (library) => {
    const result = {};

    if(library != null) {
        const formMap = new Map();
        const itemGroupMap = new Map();
        const itemMap = new Map();
        const codeListMap = new Map();
        const valueListMap = new Map();

        library.formDef != null && library.formDef.forEach(form => {
            formMap.set(form.oid, form);
        });
        library.itemGroupDef != null && library.itemGroupDef.forEach(ig => {
            itemGroupMap.set(ig.oid, ig);
        });
        library.itemDef != null && library.itemDef.forEach(i => {
            itemMap.set(i.oid, i);
        });
        library.codeList != null && library.codeList.forEach(cl => {
            codeListMap.set(cl.oid, cl);
        });
        library.valueListDef != null && library.valueListDef.forEach(vl => {
            valueListMap.set(vl.oid, vl);
        });

        result.id = library.id;
        result.form = formMap;
        result.itemGroup = itemGroupMap;
        result.item = itemMap;
        result.codeList = codeListMap;
        result.valueList = valueListMap;

        return result
    }
}