import {Row} from "antd";
import React from "react";
import ModalIcons from "./ModalIcons";

const ModalTitle = ({iconType, children}) => {
    return (
        <Row>
            <ModalIcons type={iconType} />
            <span className={'ml-3'}>
                <strong>{children}</strong> Properties
            </span>
        </Row>
    )
}

export default ModalTitle;