import { Table, Input, Button, Space, Card, Skeleton, List } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import React, {useState, useEffect} from "react";
import api from "../../lib/api/metadata";
import _ from "lodash";
import {useSelector} from "react-redux";
import style from "./style.module.scss"
// const Core = ({value}) => {
//     return (
//         <span className={`label label-${value === 'HR' ? 'danger' : (value === 'R/C' ? 'warning' : 'default')}`}>{value}</span>
//     )
// }

const CodeList = ({href, selectedCodeItems, setSelectedCodeItems, items, stickyAttr}) => {
    // console.log('CodeList => ', href, items);
    // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const metaDataStore = useSelector((store) => store.metaDataStore);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [ct, setCt] = useState([]);
    const [ctUrl, setCtUrl] = useState("");

    useEffect(() => {
        (async () => {

            const response = await api.getShareAPI(href);//codelist url 조회
                   // console.log('data => ', response.data);
                   const versions = response.data['_links']['versions'];
                   if(versions !== undefined) {
                       const cdashct = versions.find(v => {
                           return v.href.indexOf(metaDataStore.studyInfo.cdashct) === 0;
                       });
                       let terminology = null;
                       if(cdashct) {
                           terminology = cdashct;
                       } else {
                           const sdtmct = versions.find(v => {
                               return v.href.indexOf(metaDataStore.studyInfo.sdtmct) === 0;
                           });

                           if(sdtmct) {
                               terminology = sdtmct;
                           }
                       }

                       // console.log(terminologyUrl);
                       if(terminology) {
                           const responseCT = api.getShareAPI(terminology.href);
                           setCtUrl(terminology.href);
                           // console.log(responseCT);
                           responseCT.then((res) => {
                               // console.log(res.data);
                               setCt(res.data);
                           });
                           // setCt(ctdata.data);
                       }
                   }
            //
            // const response = await api.getShareAPI(href);
            // console.log('CodeList = ', response.data);
            // setCt(response.data);
        })()
    }, []);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input size={'small'}
                    // ref={node => {
                    //     // node.searchInput = node;
                    //     console.log(node);
                    // }}
                       placeholder={`Search ${dataIndex}`}
                       value={selectedKeys[0]}
                       onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       // onPree={() => handleSearch(selectedKeys, confirm, dataIndex, false)}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, true)}
                       style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({closeDropdown: false});
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownOpenChange: (e) => {
            // console.log(e);
            if (e) {
                // setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const handleSearch = (selectedKeys, confirm, dataIndex, closeDropdown) => {
        confirm({closeDropdown: closeDropdown});

        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };

    const columns = [
        {
            title: 'Preferred Term ',
            dataIndex: 'preferredTerm',
            key: 'preferredTerm',
            width: '45%',
            ...getColumnSearchProps('preferredTerm'),
        },
        {
            title: 'Submission Value',
            dataIndex: 'submissionValue',
            key: 'submissionValue',
            width: '35%',
        },
        {
            title: 'NCICode',
            dataIndex: 'conceptId',
            key: 'conceptId',
            width: '20%',
        },
    ];

    const onSelectChange = selectedKeys => {
        console.log('CT ctUrl = ', ctUrl);
        const newData = _.cloneDeep(selectedCodeItems);
        if(selectedKeys.length === 0) {
            newData.delete(ctUrl);
        } else {
            //현재 선택한 Controlled Terminolgoy를 사용하는 아이템 정보ex) ['AEYN', 'DMYN'] 도 추가한다.
            newData.set(ctUrl, selectedKeys);
            // newData.set(ct.conceptId + '_ITEMS', items);
        }
        setSelectedCodeItems(newData);
        // onChangeCodeListItem(newData, items);
    }

    // const rowSelection =

    return (
        <Skeleton loading={ct.terms === undefined} active>
            <Card key={ct.conceptId} size={'small'} title={`${ct.preferredTerm}`} headStyle={stickyAttr}>
                <Table className={style.wizardTable} rowKey={'conceptId'} pagination={false} scroll={{y: 440}}
                       rowSelection={{
                    selectedRowKeys: selectedCodeItems.get(ct.submissionValue),
                    onChange: onSelectChange,
                }} size={'small'} columns={columns} dataSource={ct.terms}/>
            </Card>
        </Skeleton>
    )
}

export default CodeList;
