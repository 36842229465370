import {Button, message, Popover, Select, Space, Tag} from "antd";
import _ from "lodash";
import {isEmpty} from "../../../../lib/StringUtils";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../../lib/ODMUtils";

const ConditionBtn = ({metaData, data, itemGroupOID, formDef, setFormDef, itemRefMap, setItemRefMap, index, defType, visible, setVisible}) => {

    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);

    // console.log([...metaData.editCheck.values()]);
    const conditionDefs = [...metaData.editCheck.values()].filter(ecs => ecs.action.type === 'CONDITION');
    const displayDef = conditionDefs.find(def => def?.oid === data?.collectionExceptionConditionOID);

    const onChangeCondition = (value) => {
        const conditionOID = value;

        switch(defType) {
            case 'itemGroup':
                const cloneItemGroupRef = _.cloneDeep(formDef.itemGroupRef);
                if(isEmpty(conditionOID)) {
                    delete cloneItemGroupRef[index].collectionExceptionConditionOID;
                } else {
                    cloneItemGroupRef[index].collectionExceptionConditionOID = conditionOID;
                }
                setFormDef({...formDef, itemGroupRef:cloneItemGroupRef})
                break;
            case 'item':
                const cloneItemRefMap = _.cloneDeep(itemRefMap);
                if(isEmpty(conditionOID)) {
                    delete cloneItemRefMap.get(itemGroupOID)[index].collectionExceptionConditionOID;
                } else {
                    cloneItemRefMap.get(itemGroupOID)[index].collectionExceptionConditionOID = conditionOID;
                }
                setItemRefMap(cloneItemRefMap);
                break;
        }

    }


    const conditionOptions = [
        {value: '', label: '...'},
        ...conditionDefs.map(ecs => ({
                key: `CON_${itemGroupOID}_${index}_${ecs.oid}`,
                value: ecs.oid,
                label: `${ecs.oid} - ${ecs.name}`
        }))
    ];

    return (
        <>
            <Popover placement={'top'}
                     title={'Condition'}
                     trigger={'click'}
                     open={visible}
                     onOpenChange={() => setVisible(!visible)}
                     content={(
                         <Space>
                             {
                                 isProd?(
                                     <Tag>
                                         <span className={'font-weight-bold text-danger'}>
                                             {displayDef!=null?`${displayDef?.oid} - ${displayDef?.name}`:`NONE`}
                                         </span>
                                     </Tag>
                                 ) : (
                                     <Select showSearch={true} placeholder={'Condition'} style={{width: 450}}
                                             defaultValue={data?.collectionExceptionConditionOID} onChange={onChangeCondition}
                                             onBlur={() => setVisible(false)} options={conditionOptions} />
                                 )
                             }
                         </Space>
                     )}>
                <Button size={'small'} onClick={() => setVisible(true)}>
                    <i className='fa fa-code'></i>
                </Button>
            </Popover>
        </>
    )
}

export default ConditionBtn;
