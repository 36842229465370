import {isEmpty} from "../../../../../lib/StringUtils";
import {Form, Input} from "antd";
import {useEffect, useState} from "react";
import useLibraryStore from "../../../../../store/libraryStore";
import {useTranslation} from "react-i18next";
const {Item} = Form;

const LibraryFormInputName = ({name, defType, form}) => {

    const {t} = useTranslation();
    const {library} = useLibraryStore(state => (state));
    const [names, setNames] = useState([]);

    useEffect(() => {
        const defNames = [];

        //현재 수정중인 데이터면,
        (library[defType]??[]).forEach(def => {
            if (name !== def.name) {
                defNames.push(def.name);
            }
        })
        setNames(defNames);
        return () => {
            setNames([]);
        }
    }, [library, name]);

    const handleChange = (e) => {
        form.setFieldValue('name', e.target.value);
    }

    return (
        <Item name={'name'} label={<span className='text-primary'>Name</span>}
              required={true}
              rules={[
                  {required: true, message: t('message.warning.required-entry')},
                  // {max:50, message: '50자 까지 입력 가능 합니다.'},
                  ({ getFieldValue }) => ({
                      validator(_, value) {
                          if(isEmpty(value)){return Promise.resolve();}
                          else if(isEmpty(value.trim())){return Promise.reject(new Error(t('message.blank.values.not.allowed')))}
                          else if(value.length > 255) {return Promise.reject(new Error(t('message.name.can.be.up.to.255.characters.long')))}

                              // const result = contains(value);
                          // if(result !== true) {return Promise.reject(new Error(result));}
                          else {return Promise.resolve();}
                      }
                  })
              ]}>
            <Input.TextArea rows={1} autoSize={true} onChange={handleChange} />
        </Item>
    );
}

export default LibraryFormInputName;