import React, {useEffect, useRef, useState} from "react";
import {isEmpty} from "../../../../lib/StringUtils";
import ModalFooter from "../ModalFooter";
import {Button, Card, Col, Divider, Form, Input, Modal, Row, Select, Tag} from "antd";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Table} from 'reactstrap';
import {MenuOutlined} from "@ant-design/icons";
import '../style.scss';
import FormInputOID from "../../../../components/common/odm/ant-form/FormInputOID";
import FormInputName from "../../../../components/common/odm/ant-form/FormInputName";
import FormSelectDataType from "../../../../components/common/odm/ant-form/FormSelectDataType";
import FormInput from "../../../../components/common/odm/ant-form/FormInput";
import DescriptionOrQuestion from "../../../../components/common/odm/ant-form/DescriptionOrQuestion";
import Decode from "../../../../components/common/odm/ant-form/Decode";
import {codeListItemAppend, codeListItemRemove, isEnvProduction} from "../../../../lib/ODMUtils";
import ModalTitle from "../component/ModalTitle";
import {useTranslation} from "react-i18next";

const {Item, List} = Form;

const CodeListDef = ({ selectedOID, metaDataStore, languages, codeListDef, setCodeListDef, itemType, visible, dataTypeIcons, dataTypes, onFinish, onCancel }) => {

    const isProd = isEnvProduction(metaDataStore);

    const [form] = Form.useForm();
    const [dicVersions, setDicVersions] = useState(null);

    const [dataType, setDataType] = useState(null); //현재 선택된 Data Type

    const addItemRef = useRef();
    const oidRef = useRef();
    const {t} = useTranslation();

    const getCodedPattern = (dataType) => {
        if(!isEmpty(dataType)) {
            switch(dataType) {
                case "FLOAT":
                    return /[-+]?(([0-9]*[.]?[0-9]+([ed][-+]?[0-9]+)?)|(inf)|(nan))+/;
                case "TEXT":
                    // return /^((?!,)(.|\n))*$/;
                    return /^((?!,).)*$/;
                case "INTEGER":
                    return /^[-+]?[0-9]\d*$/i;
            }
        }

        return null;
    }

    const getCodedMessage = (dataType) => {
        if(!isEmpty(dataType)) {
            switch(dataType) {
                case "FLOAT":
                case "INTEGER":
                    return t('message.must.enter.data.in.correct.format', {dataType: dataType});
                case "TEXT":
                    return t('message.cannot.enter.values.that.contain.comma');
            }
        }
    }

    const onChangeDataType = (value) => {
        setDataType(value);
    }

    const changeDictionary = (key) => {
        if (!isEmpty(key)) {
            const dictionary = metaDataStore.dictionaryMap[key];
            setDicVersions(dictionary);

            if (dictionary?.length > 0) {
                form.setFieldsValue({externalCodeList: {version: `${dictionary[0].id}`}});
            } else {
                form.setFieldsValue({externalCodeList: {version: null}});
            }
        }
    }

    const onDragEnd = (result) => {
        const {source, destination} = result;
        // Drop한 영역이 없는 경우 아무 처리가 없이 종료
        if (!destination) {
            return;
        }
        // Drag를 시작한 영역과 Drop한 영역의 위치가 똑같으면 아무 처리 없이 종료.
        if (destination.index === source.index) {
            return;
        }

        const codeListItem = form.getFieldValue('codeListItem');
        const [items] = codeListItem.splice(source.index, 1);
        codeListItem.splice(destination.index, 0, items);

        //orderNumber 자동 설정.
        codeListItem.map((codeListItem, index) => {
            codeListItem.orderNumber = index;
        })
        form.setFieldsValue({...codeListItem, codeListItem});
    }

    useEffect(() => {
        if (itemType === 'externalCodeList') {
            if(codeListDef?.externalCodeList?.dictionary != null) {
                const dictionary = metaDataStore.dictionaryMap[codeListDef.externalCodeList.dictionary];
                setDicVersions(dictionary);
            }

            //State & Form Value Setting
            setCodeListDef(codeListDef);
            form.setFieldsValue(codeListDef);
        } else if (itemType === 'codeListItem') {
            if (codeListDef?.codeListItem == null) {
                codeListDef.codeListItem = [{
                    codedValue: null,
                    rank: null,
                    orderNumber: 0,
                    decode: {translatedText: [{lang: null, value: ''}]}
                }];
            }

            //State & Form Value Setting
            setCodeListDef(codeListDef);
            setDataType(codeListDef?.dataType);
            form.setFieldsValue(codeListDef);
        }
    }, [codeListDef]);


    const changeVersion = (dictionary) => {
        changeDictionary(dictionary);
    }

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    const getDictionaryVersion = (id) => {
        const dictionary = dicVersions.find(ver => ver?.id === Number(id));
        if(dictionary == null){return "N/A";}
        return [`${dictionary?.version} `, `${dictionary?.lang?.key != null? `(${dictionary.lang.key})` : ''}`].join('');
    }

    return (
        <Modal
            forceRender={true}
            wrapClassName={'odm-modal'}
            title={<ModalTitle iconType={itemType}>CodeList</ModalTitle>}
            width={800}
            onCancel={onCancel}
            open={visible}
            styles={{
                body:{
                    overflowY: 'auto',
                    height: 'calc(100vh - 20rem)',
                    zIndex: '100',
                }
            }}
            footer={<ModalFooter onCancel={onCancel} form={form}/>}
        >
            <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
                <Card title={'Attributes'} type={'inner'}>
                    <Row gutter={[30, 10]}>
                        <Col span={12}>
                            <FormInputOID ref={oidRef} selectedOID={selectedOID} form={form}/>
                        </Col>
                        <Col span={12}>
                            <FormInputName name={codeListDef?.name} defType={'codeList'} form={form}/>
                        </Col>
                        <Col span={12}>
                            <FormSelectDataType icons={dataTypeIcons} dataTypes={dataTypes} onChange={onChangeDataType} value={codeListDef?.dataType}/>
                        </Col>
                        <Col span={12}>
                            <FormInput name={'sasformatName'} label={'SAS Format Name'}
                                       rules={[
                                           {
                                               pattern: /^([a-zA-Z_$][a-zA-Z0-9_.]+){1,8}$/i,
                                               message: t('message.format.is.not.valid')
                                           },
                                           {max: 8, message: t('message.you.can.enter.up.to.8.characters')}
                                       ]}/>
                        </Col>
                    </Row>
                    <div>
                        <h6>{itemType === 'externalCodeList' ? 'External CodeList' : 'CodeList Items'}</h6>
                    </div>
                    <Divider />
                    {
                        itemType === 'externalCodeList' && (
                            <Row gutter={[30, 10]}>
                                <Col span={12}>
                                    <Item name={['externalCodeList', 'dictionary']}
                                          required={false}
                                          label={<span className={'text-primary'}>Dictionary</span>}
                                          rules={[{required: true, message: t('message.pls.select.dictionary')}]}>
                                        {
                                            isProd?(
                                                <Tag className={'p-2'}>
                                                    <span className={'font-size-16 font-weight-bold'}>
                                                        {metaDataStore.dictionaryType[codeListDef?.externalCodeList?.dictionary]}
                                                    </span>
                                                </Tag>
                                            ):(
                                                <Select onSelect={changeVersion}>
                                                    {(metaDataStore.dictionaryType !== null && metaDataStore.dictionaryType !== undefined) &&
                                                    Object.keys(metaDataStore.dictionaryType).map(key => (
                                                        <Select.Option value={key} key={key}>
                                                            {metaDataStore.dictionaryType[key]}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )
                                        }
                                    </Item>
                                </Col>
                                <Col span={12}>
                                    {
                                        (dicVersions !== null && dicVersions !== undefined) &&
                                        <Item name={['externalCodeList', 'version']}
                                              required={false}
                                              label={<span className={'text-primary'}>Version</span>}
                                              rules={[{required: true, message: t('message.this.is.required.selection')}]}>
                                            {
                                                isProd?(
                                                    <Tag className={'p-2'}>
                                                        <span className={'font-size-16 font-weight-bold'}>
                                                            {getDictionaryVersion(codeListDef?.externalCodeList?.version)}
                                                        </span>
                                                    </Tag>
                                                ):(
                                                    <Select>
                                                        {
                                                            dicVersions.map((v,index) => (
                                                                <Select.Option value={`${v.id}`} key={`${v.id}_${index}`}>
                                                                    {v.version} {v.lang != null ? '(' + v.lang.key + ')' : ''}
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </Item>
                                    }
                                </Col>
                            </Row>
                        )
                    }
                    {
                        itemType === 'codeListItem' && (
                            <Row>
                                <Col span={24}>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Table style={{background: 'white'}}>
                                            <thead>
                                            <tr>
                                                <th width={50} style={{verticalAlign: 'middle'}}>Sort</th>
                                                <th width={200} style={{verticalAlign: 'middle'}}>Coded</th>
                                                <th style={{verticalAlign: 'middle'}}>Decode</th>
                                            </tr>
                                            </thead>
                                            <Droppable droppableId={'codeListItem'}>
                                                {
                                                    (provided, snapshot) => (
                                                        <tbody key={'tbody'}
                                                               ref={provided.innerRef}
                                                               {...provided.droppableProps}
                                                        >
                                                        <List name={'codeListItem'}
                                                              rules={[
                                                                  {
                                                                      //Validate :: CodeListItem 간에 CodedValue는 중복값을 허용하지 않음
                                                                      validator: async (_, codeListItem) => {
                                                                          const itemCodedValues = [];
                                                                          let isError = false;
                                                                          codeListItem.forEach(item => {
                                                                              if(!isEmpty(item?.codedValue)) {
                                                                                  const value = item?.codedValue.toUpperCase(); //대문자로 변환 (대소문자 구분삭제를 위함)
                                                                                  if(itemCodedValues.includes(value)) { isError = true; }
                                                                                  itemCodedValues.push(value);
                                                                              }
                                                                          });

                                                                          if(isError) {
                                                                              return Promise.reject(new Error(t('message.duplicate.values.not.allowed.in.coded.value.case.insensitive')));
                                                                          } else {
                                                                              return Promise.resolve();
                                                                          }
                                                                      }
                                                                  }
                                                              ]}>
                                                            {(fields, { add, remove }, { errors }) => (
                                                                    <>
                                                                        {
                                                                            fields.length > 0 ? (
                                                                                fields.map((field, index) => (
                                                                                    <Draggable key={index} className={'code-list-table'}
                                                                                               draggableId={`${index}`} index={index}
                                                                                               isDragDisabled={isProd}>
                                                                                        {
                                                                                            (dragProvided, dragSnapshot) => (
                                                                                                <tr ref={dragProvided.innerRef}
                                                                                                    {...dragProvided.draggableProps}
                                                                                                    className={'code-list-item'}
                                                                                                >
                                                                                                    <td className={'text-center bg-white'}
                                                                                                        style={{
                                                                                                            verticalAlign: 'middle',
                                                                                                            width: '50px'
                                                                                                        }}
                                                                                                        {...dragProvided.dragHandleProps}
                                                                                                    >
                                                                                                        <MenuOutlined/>
                                                                                                    </td>
                                                                                                    <td className='bg-white'
                                                                                                        style={{width: '200px'}}>
                                                                                                        <Row key={index}>
                                                                                                            <Col span={24}>
                                                                                                                <Item
                                                                                                                    name={[index, 'codedValue']}
                                                                                                                    required={false}
                                                                                                                    rules={[
                                                                                                                        {
                                                                                                                            required: true,
                                                                                                                            message: t('message.warning.required-entry')
                                                                                                                        },
                                                                                                                        {
                                                                                                                            min: 1,
                                                                                                                            message: t('message.must.enter.least.one.character')
                                                                                                                        },
                                                                                                                        {
                                                                                                                            pattern: getCodedPattern(dataType),
                                                                                                                            message: getCodedMessage(dataType)
                                                                                                                        }
                                                                                                                    ]}>
                                                                                                                    <Input tabIndex={index+1} readOnly={isProd} placeholder={'Value'}/>
                                                                                                                </Item>
                                                                                                            </Col>
                                                                                                            <Item name={['codeListItem', index, 'orderNumber']} hidden={true}>
                                                                                                                <Input/>
                                                                                                            </Item>
                                                                                                        </Row>
                                                                                                    </td>
                                                                                                    <td className='bg-white'
                                                                                                        style={{width: '500px'}}>
                                                                                                        <Row gutter={[10, 0]}>
                                                                                                            <Col span={22}>
                                                                                                                <Decode
                                                                                                                    tabIndex={fields.length + index + 1}
                                                                                                                    name={`decode`}
                                                                                                                    languages={languages}
                                                                                                                    form={form}
                                                                                                                    codeListItemIndex={index}/>
                                                                                                            </Col>
                                                                                                            <Col span={2} className={'code-list-item-option'}>
                                                                                                                {
                                                                                                                    !isProd &&
                                                                                                                    <button
                                                                                                                        style={{
                                                                                                                            border: '0px',
                                                                                                                            float: 'right',
                                                                                                                            borderRadius: '25px'
                                                                                                                        }}
                                                                                                                        disabled={fields.length <= 1}
                                                                                                                        type={"button"}
                                                                                                                        onClick={() => codeListItemRemove(form, setCodeListDef, index)}>
                                                                                                                        <i className='fa fa-trash-o'></i>
                                                                                                                    </button>
                                                                                                                }
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                    </Draggable>
                                                                                ))
                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan={4}>
                                                                                        No Content.
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        <tr>
                                                                            <td colSpan={4}>
                                                                                <Form.ErrorList errors={errors} />
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            }
                                                        </List>
                                                        {provided.placeholder}
                                                        </tbody>
                                                    )
                                                }
                                            </Droppable>
                                        </Table>
                                    </DragDropContext>
                                </Col>
                                <Col span={24}>
                                    <Button hidden={isProd} type={'primary'} ref={addItemRef} className='width-100p' onClick={() => codeListItemAppend(form, setCodeListDef)}>
                                        <i className='fa fa-plus mr-2' /> CodeList Item
                                    </Button>
                                </Col>
                            </Row>
                        )}
                </Card>
                <Card title={'Description'} type={'inner'}>
                    <DescriptionOrQuestion languages={languages} name={'description'} form={form}/>
                </Card>
            </Form>
        </Modal>
    )
}

export default CodeListDef;
