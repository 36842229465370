import {Form, Input, InputNumber, Tag, Typography} from "antd";
import {Table} from 'reactstrap';
import React from "react";
import {Notice} from "../../../container/build-tool/StyleBox";
import {isEmpty} from "../../../lib/StringUtils";
import {useTranslation} from "react-i18next";

const StratificationInfos = ({form, metaData, studyStructure, expressionComparisonTypeMap}) => {

    const {t} = useTranslation();

    const stratificationInfos = studyStructure?.stratification?.stratificationInfos??[];
    // console.log(stratificationInfos);

    const ItemExpression = ({infoIndex}) => {
        const itemExpressions = form.getFieldValue(["stratification", "stratificationInfos", infoIndex, "itemExpressions"]);

        const expressionFormat = (string, itemOID, firstParam, secondParam) => {
            let formatted = string;
            formatted = formatted.replaceAll('itemOID', itemOID);
            formatted = formatted.replaceAll('firstParam', firstParam);
            if(!isEmpty(secondParam)) {
                formatted = formatted.replaceAll('secondParam', secondParam);
            }
            return formatted;
        }

        const expressionValue = (itemExpression) => {
            const ECT = expressionComparisonTypeMap.get(itemExpression.expressionComparisonType);
            return (
                <Tag>{expressionFormat(ECT?.expression||"", itemExpression?.itemOID||"", itemExpression?.firstParam||"", itemExpression?.secondParam||"")}</Tag>
            )
        }

        return (
            <Table>
                <tbody className={'p-1'}>
                {
                    itemExpressions.map((itemExp, index) => (
                        <tr key={`itemExp-${index}`}>
                            <td className={'font-size-12'} style={{width:'40%'}}>
                                <span className='text-truncate'>{metaData.item.get(itemExp.itemOID)?.name||''}</span>
                            </td>
                            <td className={'font-size-12'} style={{width:'60%'}}>
                                {expressionValue(itemExp)}
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        )
    }

    return (
        <>
            <Table bordered className={'width-100p height-100p mt-2'}>
                <thead>
                <tr>
                    <th>Item Expression</th>
                    <th>Code</th>
                    <th>Label</th>
                </tr>
                </thead>
                <tbody>
                <Form.List name={["stratification", "stratificationInfos"]} rules={[{
                    validator: (_, stratificationInfos) => {
                        if(stratificationInfos == null || (stratificationInfos != null && stratificationInfos.length <= 0)) {
                            return Promise.reject(new Error(t('odm.stratification.info.has.not.been.set')));
                        }
                        return Promise.resolve();
                    }
                }]}>
                    {(fields, {}, {errors}) => (
                        <>
                            {(stratificationInfos != null && stratificationInfos.length > 0)?(
                                fields.map((field, index) => (
                                <tr key={`stratificationInfo-${index}`}>
                                    <td>
                                        <ItemExpression infoIndex={index} />
                                    </td>
                                    <td>
                                        <Form.Item name={[index, "code"]}
                                                   rules={[{required: true, message: t('message.pls.enter.code.value')}]}>
                                            <InputNumber readOnly bordered={false} />
                                        </Form.Item>
                                    </td>
                                    <td>
                                        <Form.Item name={[index, "label"]}
                                                   rules={[{required: true, message: t('message.pls.enter.label.value')}]}>
                                            <Input />
                                        </Form.Item>
                                    </td>
                                </tr>
                            ))):(
                                <tr>
                                    <td colSpan={3}>
                                        <Notice style={{height:'100%'}}>
                                            <Typography.Text strong className={'font-size-16'}>
                                                <i className='fa fa-exclamation-circle mr-2'/>{t('odm.stratification.info.has.not.been.entered')}
                                                <br /> {t('odm.click.parsing.convert.stratification.factors.into.stratification.info')}
                                            </Typography.Text>
                                        </Notice>
                                    </td>
                                </tr>
                            )}
                            {
                                //Error가 존재하면
                                (errors.length > 0) &&
                                <tr>
                                    <td colSpan={3}>
                                        <Form.ErrorList errors={errors} />
                                    </td>
                                </tr>
                            }
                        </>
                    )}
                </Form.List>
                </tbody>
            </Table>
        </>
    )

}

export default StratificationInfos;
