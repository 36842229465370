import {useNavigate, useLocation, useParams} from "react-router-dom";
import queryString from "query-string";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {errorHandle} from "../../../../lib/BuilderUtils";
import DefaultLoading from "../../DefaultLoading";
import {CardBody, CardFooter, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {Button, Col, Form, Input, InputNumber, message, Row, Select} from "antd";
import format from 'dayjs';
import {RightOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const ManageSiteEdit = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();

    //query-string이 존재하면 studyID에 해당하는 값 불러오기.
    const params = useParams();
    const query = queryString.parse(location.search);

    const [studyCountryMap, setStudyCountryMap] = useState(null);
    const [timeZoneMap, setTimeZoneMap] = useState(null);
    const [siteGroups, setSiteGroups] = useState(null);
    const [envList, setEnvList] = useState(null);
    const [locationList, setLocationList] = useState(null);
    const [studySite, setStudySite] = useState(null);
    const [isSiteLimit, setIsSiteLimit] = useState(null);
    const [useRandomization, setUseRandomization] = useState(false);

    const [form] = Form.useForm();

    //Button Loading
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        (async () => {
            //GET Request
            try {
                //REST API Request
                const {data} = await axios.get(`/api/builder/studies/${params.sid}/sites/edit`, {params: query});
                //State Setting
                setStudyCountryMap(data?.studyCountryMap);
                setTimeZoneMap(data?.timeZoneMap);
                setSiteGroups(data?.siteGroups);
                setEnvList(data?.envList);
                setLocationList(data?.locationList);
                setStudySite(data?.studySite);
                setIsSiteLimit(data?.isSiteLimit);
                setUseRandomization(data?.useRandomization);
            } catch (error) {
                errorHandle(error, navigate, `/studies/${params.sid}/dashboard`);
            }
        })();
    }, [])

    //StudySite 값이 Setting 되면 enumType 값만 따로 처리
    useEffect(() => {
        if(studySite !== null) {
            form.setFieldsValue({
                ...studySite,
                locationType: 'SITE'/*studySite?.locationType?.key||null*/,
                selectedEnvs: studySite?.selectedEnvs?.map(env => env?.key)||[],
                contractDate: studySite?.contractDate!=null?format(studySite?.contractDate):null,
                irbApprovalDate: studySite?.irbApprovalDate!=null?format(studySite?.irbApprovalDate):null,
                //ant Design - DatePicker 에서 value를 dayjs로 받아서 처리함
            })
        }
    }, [studySite])


    const onFinish = async (values) => {
        setLoading(true);

        //Create/Edit Study
        try {
            const body = {
                ...studySite,
                ...values,
                studySiteEnvs:null //env Json 변환오류로 인해 null처리 / 실제 저장값에 영향을 주는 값이 아님.
            };

            const {data, status} = await axios({
                url: `/api/builder/studies/${params.sid}/sites/edit`,
                data: body, method: 'post',
            });

            if (status === httpStatus.ok) {
                message.success(data.message);
                navigate(`/studies/${params.sid}/sites`);
            }
        } catch (e) {
            errorHandle(e);
        }

        setLoading(false);
    }

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    //Form Item의 Config 설정.
    const config = (message, type = null, min = null, max = null, required = true) => {
        return {rules: [{ type, required: required, message, min, max}]};
    }

    return (
        <>
            {
                studySite === null &&
                    <DefaultLoading />
            }
            {
                studySite !== null &&
                <DefaultCard>
                    <CardHeader>
                        <h3><RightOutlined className={'mr-2'} />Site</h3>
                    </CardHeader>
                    <Form layout="vertical"
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          form={form}
                    >
                        <CardBody>
                            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                <Col span={'12'}>
                                    <Form.Item name={'locationType'} label={t('column.type')}
                                               required {...config(t('studies.pls.select.type'))}>
                                        <Select>
                                            <Select.Option value={""}>...</Select.Option>
                                            {
                                                locationList.map((location, index) => (
                                                    <Select.Option key={index} value={location?.key}
                                                                   disabled={location?.key !== 'SITE'}>
                                                        {location?.value}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={'12'}>
                                    <Form.Item name={'selectedEnvs'} label={t('column.environment')} required
                                               {...config(t('studies.pls.select.environment'), 'array')}>
                                        <Select mode={'multiple'}>
                                            {
                                                envList.map((env, index) => (
                                                    <Select.Option key={index} value={env.key}>
                                                        {`[${env.key}] ${env.label}`}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={'12'}>
                                    <Form.Item name={'oid'} label={t('column.site.code')} required
                                        {...config(t('studies.pls.enter.site.code'))}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={'12'} hidden={!isSiteLimit}>
                                    <Form.Item name={'contractNumber'} label={t('column.contract.count')} hidden={!isSiteLimit}
                                               {...config(t('studies.pls.enter.contract.count.gt.zero'), 'number', 0, null, isSiteLimit)}>
                                        <InputNumber style={{width: '100%'}} min={1} />
                                    </Form.Item>
                                </Col>
                                <Col span={'12'}>
                                    <Form.Item name={'name'} label={t('column.site.name2')} required
                                               {...config(t('studies.pls.enter.site.name'))}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'targetSubjectNum'} label={t('column.target.subject')}>
                                        <InputNumber style={{width: '100%'}} min={1} />
                                    </Form.Item>
                                </Col>
                                <Col span={'12'}>
                                    <Form.Item name={'studySiteGroupId'} label={t('column.country')} required
                                               {...config(t('studies.pls.select.country'), 'number')}>
                                        <Select>
                                            <Select.Option value={""}>...</Select.Option>
                                            {
                                                siteGroups.map((siteGroup, index) => (
                                                    <Select.Option key={index} value={siteGroup.id}>
                                                        {studyCountryMap[siteGroup?.countryCode]}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={'12'}>
                                    <Form.Item name={'deptName'} label={t('column.medical.department')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={'12'}>
                                    <Form.Item name={'timeZone'} label={t('column.time.zone')} required
                                               {...config(t('studies.pls.select.time.zone'))}>
                                        <Select showSearch filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                            <Select.Option value={""}>...</Select.Option>
                                            {
                                                Object.keys(timeZoneMap).map((timeZone, index) => (
                                                    <Select.Option key={index} value={timeZone}>
                                                        {timeZoneMap[timeZone]}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={'12'}>
                                    <Form.Item name={'phoneNumber'} label={t('column.phone.number')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'email'} label={'Email'} rules={[{required: useRandomization, message: 'Please Input Email Address'}]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={'12'}>
                                    <Form.Item name={'faxNumber'} label={t('column.fax.number')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'address'} label={t('column.address')}>
                                        <Input.TextArea />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'principalInvestigator'} label={t('column.principal.investigator')}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                            </Row>

                        </CardBody>
                        <CardFooter className='text-right'>
                            <Button type={'default'} onClick={() => {
                                navigate(`/studies/${params.sid}/sites`);
                            }} className={'mr-3'}> Cancel </Button>
                            <Button loading={loading} type={'primary'} htmlType={'submit'}> Submit </Button>
                        </CardFooter>
                    </Form>
                </DefaultCard>
            }
        </>
    )
}

export default ManageSiteEdit;