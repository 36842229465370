import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CardBody, CardFooter, CardHeader, PrimaryCard} from "../../../../pages/PageStyleBox";
import {Button, Dropdown, Input, Menu, message, Spin, Table, Tag, Upload} from "antd";
import {errorHandle, getIndexOfTableCell, onSearch} from "../../../../lib/BuilderUtils";
import axios from "axios";
import {DownOutlined, RightOutlined, UploadOutlined} from "@ant-design/icons";
import Swal from "sweetalert2";
import {httpStatus} from "../../../../lib/createRequestSaga";
import style from './style.module.scss'
import dayjs from 'dayjs';
import {downloadFile} from "../../../../lib/FileUtils";
import {withTranslation} from "react-i18next";

const CRFVersionList = ({t}) => {

    const navigate = useNavigate();
    const params = useParams();
    const [baseData, setBaseData] = useState(null);
    const [filterData, setFilterData] = useState(null);
    const [reload, setReload] = useState(0);

    const [exporting, setExporting] = useState(false);

    const columns = [
        {
            title: 'No',
            dataIndex: 'id',
            key: 'id',
            align: "center",
            sorter: {compare: (a, b) => a.id>b.id?1:-1},
            render: (row, record, index) => {
                return (filterData !== null?filterData:baseData).length-(filterData !== null?filterData:baseData).indexOf(record);
            }
        },
        {
            title: t('column.version'),
            dataIndex: 'name',
            key: 'name',
            render: (row, record, index) => {
                return <>{row}&nbsp;{record.patch > 0 ? <Tag color={'cyan'}>P#{record.patch}</Tag> : ''}</>;
            }
        },
        {
            title: t('column.effective-date'),
            key: 'effectiveDate',
            render: (data) => {
                return (
                    <span> {data?.effectiveDate!=null?dayjs(data?.effectiveDate).format(data?.dateFormatType?.format?.toUpperCase()):'-'}</span>
                )
            }
        },
        {
            title: t('column.description'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('column.env'),
            dataIndex: 'env',
            key: 'env',
            render: row => {
                let label = 'default';
                switch(row?.key) {
                    case 'PROD':    label='processing'; break;
                    case 'UAT':     label='warning'; break;
                }
                return (<Tag color={label}>{row?.key}</Tag>)
            }
        },
        {
            title: t('column.created-by'),
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (data) => {
                return `${data.firstName} ${data.middleName} ${data.lastName}`;
            }
        },
        {
            title: t('column.created-date'),
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: {compare: (a, b) => new Date(a.createdDate)>new Date(b.createdDate)?1:-1},
        },
        {
            title: t('column.action'),
            align: "center",
            key: 'actions',
            className:style.actionCell,
            render: (row, record, index) => {
                return (
                    <Dropdown type={'ghost'} trigger={'click'} placement={'bottomLeft'} menu={crfVersionMenu(row,record, index)}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                )
            }
        }
    ]

    const crfVersionMenu = (row, record, index) => {
        return {
            items: [
                {
                    type: 'group',
                    label: 'Design',
                    key: 'design',
                    children: [
                        (row?.env?.key != null && row.env.key !== 'PROD') &&
                        {
                            label: 'Edit',
                            icon: <i className='fa fa-edit'/>,
                            key: 'edit',
                            onClick: () => navigate(`/studies/${row.studyOID}/versions/edit?id=${row.id}`),
                        },
                        (row?.env?.key != null && row.env.key === 'PROD') &&
                        {
                            label: 'Info',
                            icon: <i className='fa fa-info'/>,
                            key: 'edit',
                            onClick: () => navigate(`/studies/${row.studyOID}/versions/info?id=${row.id}`),
                        },
                        row?.env?.key == null &&
                        {
                            label: 'Delete',
                            icon: <i className='fa fa-trash-o'/>,
                            key: 'delete',
                            onClick: () => onDelete(row),
                        },
                    ]
                },
                (row?.env?.key != null && row.env.key === 'PROD' && index === 0) &&
                {
                    type: 'group',
                    label: 'Version Control',
                    key: 'versionControl',
                    children: [
                        {
                            label: 'Patch Version',
                            icon: <i className='fa fa-repeat'/>,
                            key: 'patch',
                            onClick: () => navigate(`/studies/${row.studyOID}/versions/${row.id}/version-control?versionControlType=PATCH`),
                        },
                        {
                            label: 'New Version',
                            icon: <i className='fa fa-upload'/>,
                            key: 'new',
                            onClick: () => navigate(`/studies/${row.studyOID}/versions/${row.id}/version-control`),
                        },
                    ]
                },
                row?.env?.key != null &&
                {
                    type: 'group',
                    label: 'EXPORT(ODM-XML)',
                    key: 'export-odm-xml',
                    children: [
                        {
                            label: 'Snapshot(Vendor)',
                            icon: <i className='fa fa-file-code-o'/>,
                            key: 'snapshot',
                            onClick: () => onExportXML(row.studyOID, row.id, 'SNAPSHOT'),
                        },
                        {
                            label: 'Transactional(Vendor)',
                            icon: <i className='fa fa-file-code-o'/>,
                            key: 'transactional',
                            onClick: () => onExportXML(row.studyOID, row.id, 'TRANSACTIONAL'),
                        },
                        // {
                        //     label: 'Snapshot',
                        //     icon: <i className='fa fa-file-code-o'/>,
                        //     key: 'snapshot',
                        //     onClick: () => onExportXML(row.studyOID, row.id, 'SNAPSHOT', false),
                        // },
                        // {
                        //     label: 'Transactional',
                        //     icon: <i className='fa fa-file-code-o'/>,
                        //     key: 'transactional',
                        //     onClick: () => onExportXML(row.studyOID, row.id, 'TRANSACTIONAL', false),
                        // },
                    ],
                },
                row?.env?.key != null &&
                {
                    type: 'group',
                    label: 'EXPORT',
                    key: 'export-crf',
                    children: [
                        {
                            label: 'Blank CRF',
                            icon: <i className='fa fa-file-pdf-o'/>,
                            key: 'blank-crf',
                            onClick: () => onExportPDF(row.studyOID, row.id, false, row.env.key),
                        },
                        {
                            label: 'Annotated CRF',
                            icon: <i className='fa fa-file-pdf-o'/>,
                            key: 'annotated-crf',
                            onClick: () => onExportPDF(row.studyOID, row.id, true, row.env.key),
                        },
                    ]
                },
                row?.env?.key != null &&
                {
                    type: 'group',
                    label: 'EXPORT(DB Spec)',
                    key: 'export-spec',
                    children: [
                        {
                            label: 'Snapshot',
                            icon: <i className='fa fa-file-excel-o'/>,
                            key: 'db-spec-snapshot',
                            onClick: () => onDBSpec(row.studyOID, row.id, true, row.env.key, 'SNAPSHOT'),
                        },
                        {
                            label: 'Transactional',
                            icon: <i className='fa fa-file-excel-o'/>,
                            key: 'db-spec-transactional',
                            onClick: () => onDBSpec(row.studyOID, row.id, true, row.env.key, 'TRANSACTIONAL'),
                        },
                    ]
                }
            ]
        };
    }


    //Event Methods.
    const onClickRow = (row) => {
        return {
            onClick: (e) => {
                const cellIndex = getIndexOfTableCell(e);
                //Actions 영역이 아닌 경우에만 동작.
                if(cellIndex != 7) {
                    navigate(`/studies/${row.studyOID}/v/${row.id}/forms`)
                }
            }
        }
    }

    //Menu Settings
    const onDelete = async (row) => {

        try{
            const res = await Swal.fire({
                icon: 'question',
                iconColor: 'red',
                title: t('studies.crf.version.delete'),
                confirmButtonColor: '#ec1818',
                confirmButtonText: t('btn.text.delete'),
                cancelButtonText: t('btn.text.cancel'),
                showCancelButton: true,
                html:`
                <table class="table table-bordered">
                    <thead>
                       <tr>
                            <th>Version</th>
                            <th>Description</th>
                       </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="badge badge-light">${row.name}</span></td>
                            <td style="text-center">
                                <div style="overflow-x: hidden; text-overflow: ellipsis; white-space: nowrap; width: 15rem">
                                    ${row.description}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span>`+ t('studies.check.delete.crf.version') + `</span>`
            })

            if(res.isConfirmed) {
                //DateFormatType key 변경
                const params = {...row, dateFormatType: (row?.dateFormatType?.key??null)};
                const {status, data} = await axios.delete(`/api/builder/studies/${row.studyOID}/versions/edit`, {data: params});

                if(status === httpStatus.ok) {
                    //Message 출력 후, 현재 baseData에서 삭제한 CRF Version을 제외하여 Setting
                    message.success(data.message);
                    setBaseData(baseData.filter(version => (version.id !== row.id)));
                }
            }
        } catch(e) {
            if(e.status === httpStatus.badRequest) {
                if (e?.response?.data?.message != null) {
                    message.error(e?.response?.data.message);
                } else {
                    message.error(e?.message);
                }
            } else {
                if (e?.response?.data?.message != null) {
                    Swal.showValidationMessage(e?.response?.data.message);
                } else {
                    Swal.showValidationMessage(e?.message);
                }
            }
        }
    }

    const onExportXML = async (studyOID, id, fileType, vendorExtension = true) => {
        setExporting(true);

        //Export XML
        try{
            const params = {id, fileType, vendorExtension};
            const {headers, status, data} = await axios({
                url: `/api/builder/studies/${studyOID}/versions/export-odmxml`,
                responseType: 'blob',
                params: params,
                method:'get',
            });

            //XML File Download
            if(status === httpStatus.ok) {
                downloadFile(headers, data);
            }

        } catch(e) {
            if (e?.response?.data?.message != null) {
                message.error(e?.response?.data.message);
            } else {
                message.error(e?.message);
            }
        }

        setExporting(false);
    }

    const onExportPDF = async (studyOID, id, annotation, env) => {
        setExporting(true);

        //Export PDF
        try{
            const params = {id, annotation, env};
            const {headers, status, data} = await axios({
                url: `/api/builder/studies/${studyOID}/versions/export-pdf`,
                responseType: 'blob',
                params: params,
                method:'get',
            });

            //XML File Download
            if(status === httpStatus.ok) {
                downloadFile(headers, data);
            }

        } catch(e) {
            if (e?.response?.data?.message != null) {
                message.error(e?.response?.data.message);
            } else {
                message.error(e?.message);
            }
        }

        setExporting(false);
    }
    const onDBSpec = async (studyOID, id, annotation, env, fileType) => {
        setExporting(true);

        //Export PDF
        try{
            const params = {id, annotation, env};
            const {headers, status, data} = await axios({
                url: `/api/builder/studies/${studyOID}/versions/download-dbspec?fileType=${fileType}`,
                responseType: 'blob',
                params: params,
                method:'get',
            });

            //XML File Download
            if(status === httpStatus.ok) {
                downloadFile(headers, data);
            }

        } catch(e) {
            errorHandle(e);
            // if (e?.response?.data?.message != null) {
            //     message.error(e?.response?.data.message);
            // } else {
            //     message.error(e?.message);
            // }
        }

        setExporting(false);
    }

    const props = {
        name: 'file',
        action: `/api/${params.sid}/odm-xml/import`,
        // headers: {
        //     authorization: 'authorization-text',
        // },
        beforeUpload: file => {
            if (file.type !== 'text/xml') {
                message.error(`${file.name} is not a XML file`);
            }
            return file.type === 'text/xml' ? true : Upload.LIST_IGNORE;
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            // if (info.file.status === 'success') {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                setReload(Math.random());
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    //Init
    useEffect( () => {
        (async () => {
            //GET Request
            //비동기를 동기식으로 응답 받을 때 까지 대기.
            try {
                //REST API Request
                const {data} = await axios.get(`/api/builder/studies/${params.sid}/versions`);
                //State Setting
                setBaseData(data);
            } catch (e) {
                //Error Exception
                errorHandle(e, navigate);
            }
        })();
    }, [reload]);


    return (
        <>
            <PrimaryCard>
                <CardHeader>
                    <div className={'row'}>
                        <div className={'col-8'}>
                            <h3><RightOutlined className={'mr-2'} />CRF Versions</h3>
                        </div>
                        <div className={'col-4'}>
                            <Input.Search
                                placeholder="Search by..."
                                onSearch={(value) => onSearch(baseData, setFilterData, value)}
                                enterButton
                            />
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <Spin tip={"Exporting File..."} spinning={exporting}>
                        <Table columns={columns} // Columns Info
                               rowKey={row => row.id} // Row Key Value
                               dataSource={filterData === null ? baseData : filterData} // Data Source
                               pagination={{
                                   pageSize: 25
                               }}    // Pagination
                               loading={!baseData}  // Data Loading 처리
                               bordered={true}
                               rowClassName={style.selectRow}
                               onRow={onClickRow}
                        />
                    </Spin>
                </CardBody>
                <CardFooter className='text-right'>
                    {
                        (baseData !== null && baseData?.length) <= 0 &&
                        <div className='d-inline-flex'>
                            <Upload {...props} className={'mr-2'}>
                                <Button icon={<UploadOutlined/>}>Import ODM-XML</Button>
                            </Upload>
                            <Button className={'d-inline'} onClick={() => {
                                navigate(`/studies/${params.sid}/versions/edit`)
                            }}>
                                <i className='fa fa-plus mr-2'/>
                                Add a new Version
                            </Button>
                        </div>
                    }
                </CardFooter>
            </PrimaryCard>
        </>
    )
}

export default withTranslation()(CRFVersionList);
