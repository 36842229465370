import React from "react";
import {Paper} from "@material-ui/core";
import {Popconfirm} from "antd";
import {CardFormWrapper} from "../../../../../container/build-tool/StyleBox";
import {useNavigate, useParams} from "react-router-dom";
import Text from "antd/lib/typography/Text";
import useLibraryStore from "../../../../../store/libraryStore";
import {toLibraryMap} from "../../../../../lib/LibraryUtils";

const getFormTypeLabel = (formType) => {
    if(formType === 'CRF') {
        return <span className={'badge badge-default font-size-10'}>CRF</span>;
    } else if(formType === 'REGISTRATION_FORM') {
        return <span className={'badge badge-info font-size-10'}>REG</span>;
    } else if(formType === 'LABORATORY_TEST_FORM') {
        return <span className={'badge badge-success font-size-10'}>LAB</span>;
    } else if(formType === 'SAE_REPORT_FORM') {
        return <span className={'badge badge-warning font-size-10'}>SAE</span>
    } else if(formType === 'EPRO') {
        return <span className={'badge badge-info font-size-10'}>ePRO</span>
    } else if(formType === 'CRF_EPRO') {
        return <span className={'badge badge-info font-size-10'}>CRF/ePRO</span>
    } else {
        return '-';
    }
}

const LibraryCardForm = ({formDef, disabled, onEdit, onDelete}) => {

    const navigate = useNavigate();
    const params = useParams();

    const {library} = useLibraryStore(state => state);
    const libraryMap = toLibraryMap(library);

    return (
        <CardFormWrapper key={`form-wrapper-${formDef?.oid}`}>
            <Paper elevation={3}>
                <div className={"card"} style={{minWidth: 160, minHeight: 200, maxWidth:200}} key={formDef?.oid}>
                    <div className={'card-body'}>
                        <div className={'form-menu'} style={{display:'none',position:'absolute',top:5, right:5}}>
                            <div className={'btn-group btn-group-sm'}>
                                <React.Fragment>
                                    <button className={'btn btn-default'} data-tip={'Edit'}
                                            disabled={disabled}
                                            onClick={() => onEdit(formDef)}>
                                        <i className={'fa fa-pencil'}></i>
                                    </button>
                                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" className={'btn btn-default'}
                                                onConfirm={() => onDelete(formDef)}>
                                        <i className={'fa fa-trash-o'}></i>
                                    </Popconfirm>
                                </React.Fragment>
                            </div>
                        </div>
                        <div onClick={() => navigate(`/global-library/v/${params.libraryId}/forms/${formDef?.oid}`)}>
                            <div className={'pull-left'}><Text style={{fontSize:8,color:'#4b7cf3'}} strong>{formDef?.oid}</Text></div>
                            <div className={'text-right mb-2'}>{getFormTypeLabel(formDef.formType)}</div>
                            <h6 className="header-title text-truncate">{formDef?.name}</h6>

                            {formDef.itemGroupRef == null || formDef.itemGroupRef.length === 0 && (
                                <div className={'text-center'}>
                                    <br/>
                                    <small className={'text-danger text-center'}>Please fill out the form.</small>
                                </div>
                            )}
                            {formDef.itemGroupRef != null && formDef.itemGroupRef.map((g, index) => {
                                const itemGroupDef = libraryMap.itemGroup.get(g.itemGroupOID);
                                return (
                                    index < 2 &&
                                    <div key={`form-thumb-group-${g.itemGroupOID}-${index}`}>
                                        <small className={'d-inline-block text-truncate width-100p'}>{itemGroupDef?.name||`[${g.itemGroupOID}]?`}</small>
                                        <hr/>
                                        <hr/>
                                        <hr/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Paper>
        </CardFormWrapper>
    )

}

export default LibraryCardForm;