import {Button, Col, Form, Input, Row, Select, Tag} from "antd";
import React from "react";
import {decodeAppend, decodeRemove} from "../../../../../lib/ODMUtils";
import {useTranslation} from "react-i18next";

const {Item, List} = Form;

const LibraryDecode = ({label = null, name, languages, form, codeListItemIndex, tabIndex = 0}) => {

    const {t} = useTranslation();

    return (
        <Row gutter={[20, 0]} className={'mr-2'}>
            {
                label !== null &&
                <Col span={24}>
                    <h6>Decode</h6>
                    <hr className={'m-0 pb-3'} />
                </Col>
            }
            <List name={[codeListItemIndex, name, 'translatedText']}>
                {
                    (fields) =>
                        <>
                            {
                                fields.length > 0 &&
                                fields.map((field, index) => (
                                    <React.Fragment key={index}>
                                        <Col span={6}>
                                            <Item name={[index, 'lang']}
                                                  required={false}
                                                  rules={[
                                                      {required: (index > 0), message: t('message.this.is.required.selection')},
                                                      {pattern: /^(([a-z]{2})([\\-][A-Z]{2})?)/, message: t('message.format.is.incorrect')},
                                                      {max: 5, message: t('message.you.can.enter.up.to.5.characters')}
                                                  ]}>
                                                {
                                                    <Select placeholder={'Language'}>
                                                        <Select.Option value={""}>...</Select.Option>
                                                        {(languages !== null && languages !== undefined) && Object.keys(languages).map(key => (
                                                            <Select.Option value={key}>{languages[key]}</Select.Option>
                                                        ))}
                                                    </Select>
                                                }
                                            </Item>
                                        </Col>
                                        <Col span={16}>
                                            <Item name={[index, 'value']}
                                                  required={false}
                                                  rules={[
                                                      {required: true, message: t('message.warning.required-entry')},
                                                      {max: 4000, message: t('message.you.can.enter.up.to.4000.characters')}
                                                  ]}>
                                                <Input.TextArea tabIndex={tabIndex} autoSize={true} rows={1} placeholder={'Translated Text'}/>
                                            </Item>
                                        </Col>
                                        <Col span={2}>
                                            {
                                                (fields.length > 1) &&
                                                <Button type={'default'} onClick={() => decodeRemove(form, codeListItemIndex, index)}>
                                                    <i className={'fa fa-times text-danger'}></i>
                                                </Button>
                                            }
                                        </Col>
                                    </React.Fragment>
                                ))
                            }
                            {
                                fields.length < (Object.keys(languages).length) &&
                                <Col offset={4} span={18}>
                                    <Item>
                                        <Button type={'primary'} className={'btn-block'}
                                                disabled={fields.length === (Object.keys(languages).length)}
                                                onClick={() => decodeAppend(form, codeListItemIndex)}>
                                            <i className={'fa fa-plus'}></i> Translated Text
                                        </Button>
                                    </Item>
                                </Col>
                            }
                        </>
                }
            </List>
        </Row>
    )

}

export default LibraryDecode;