import {Card, Col, Form, Modal, Row} from "antd";
import ModalTitle from "../../../../../container/build-tool/modal/component/ModalTitle";
import ModalFooter from "../../../../../container/build-tool/modal/ModalFooter";
import LibraryFormInputOID from "../ant-form/LibraryFormInputOID";
import LibraryFormInputName from "../ant-form/LibraryFormInputName";
import LibraryFormSelectYesOrNo from "../ant-form/LibraryFormSelectYesOrNo";
import LibraryDescriptionOrQuestion from "../ant-form/LibraryDescriptionOrQuestion";
import {useEffect} from "react";
import LibraryFormInput from "../ant-form/LibraryFormInput";
import {useTranslation} from "react-i18next";

const LibraryItemGroupDef = ({open, setOpen, itemGroupDef, repeatTypeIcons, itemGroupRepeatType, yesOrNo, languages, onFinish, onCancel, disabledRepeatingType}) => {

    const {t} = useTranslation();

    const [form] = Form.useForm();

    useEffect(() => {
        console.log(itemGroupDef);
        form.setFieldsValue(itemGroupDef);
    }, [itemGroupDef]);

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    return (
        <Modal
            forceRender={true}
            wrapClassName={'odm-modal'}
            title={<ModalTitle iconType={'itemGroupDef'}>ItemGroup</ModalTitle>}
            width={800}
            onCancel={onCancel}
            open={open}
            destroyOnClose={true}
            styles={{body:{overflowY: 'auto', height: 'calc(100vh - 20rem)'}}}
            footer={<ModalFooter onCancel={onCancel} form={form} />}
        >
            <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
                <Card title={'Attributes'} type={'inner'} className={'mb-2'}>
                    {/* REQUIRED */}
                    <Row gutter={[20, 0]}>
                        <Col span={12}>
                            <LibraryFormInputOID selectedOID={itemGroupDef?.oid??""} allowDotChar={false} form={form} />
                        </Col>
                        <Col span={12}>
                            <LibraryFormInput name={'domain'} label={'Domain'}
                                              rules={[
                                                  {required:true, message:t('message.warning.required-entry')},
                                                  {pattern: /^([a-zA-Z]+[0-9]*){1,8}$/i, message: t('message.format.is.not.valid')},
                                                  {max: 100, message: t('message.can.enter.up.to.maximum.of.100.character')}
                                              ]} />
                        </Col>
                        <Col span={12}>
                            <LibraryFormInputName name={itemGroupDef?.name} defType={'itemGroupType'} form={form}/>
                        </Col>
                        <Col span={12}>
                            <LibraryFormSelectYesOrNo label={'Repeating'} name={'repeating'} required={true}
                                                      icons={repeatTypeIcons} yesOrNo={itemGroupRepeatType} value={itemGroupDef?.repeating}
                                                      disabledOption={disabledRepeatingType} />
                        </Col>
                    </Row>
                    {/*<div className={`panel-collapse collapse${collapseIn ? ' show' : ''}`}>*/}
                    {/*    <Row gutter={[20,0]}>*/}
                    {/*        <Col span={12}>*/}
                    {/*            <LibraryFormInput name={'sasdatasetName'} label={'Dataset Name'}*/}
                    {/*                              rules={[*/}
                    {/*                                  {pattern: /^([a-zA-Z_][a-zA-Z0-9_]+){1,8}$/i, message: '형식이 올바르지 않습니다.'},*/}
                    {/*                                  {max: 8, message: '최대 8자리까지 입력 가능합니다.'}*/}
                    {/*                              ]} />*/}
                    {/*        </Col>*/}
                    {/*        <Col span={12}>*/}
                    {/*            <LibraryFormInput name={'origin'} label={'Origin'}*/}
                    {/*                              rules={[{max: 100, message: '최대 100자리까지 입력 가능 합니다.'}]} />*/}
                    {/*        </Col>*/}
                    {/*        <Col span={12}>*/}
                    {/*            <FormTextArea name={'purpose'} label={'Purpose'}*/}
                    {/*                          rules={[{max: 4000, message: '최대 4000자리까지 입력 가능 합니다.'}]}/>*/}
                    {/*        </Col>*/}
                    {/*        <Col span={12}>*/}
                    {/*            <FormTextArea name={'comment'} label={'Comment'}*/}
                    {/*                          rules={[{max: 4000, message: '최대 4000자리까지 입력 가능 합니다.'}]}/>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</div>*/}
                </Card>
                <Card title={'Header Title'} type={'inner'}>
                    <LibraryDescriptionOrQuestion languages={languages} name={'headerTitle'} form={form}/>
                </Card>
                <Card title={'Header Description'} type={'inner'}>
                    <LibraryDescriptionOrQuestion languages={languages} name={'headerDescription'} form={form}/>
                </Card>
                <Card title={'Footer Description'} type={'inner'}>
                    <LibraryDescriptionOrQuestion languages={languages} name={'footerDescription'} form={form}/>
                </Card>
            </Form>
        </Modal>
    )

}

export default LibraryItemGroupDef;
