import {Card, Col, Form, Input, Modal, Row, Select} from "antd";
import ModalTitle from "../../../../../container/build-tool/modal/component/ModalTitle";
import ModalFooter from "../../../../../container/build-tool/modal/ModalFooter";
import React, {useEffect, useState} from "react";
import LibraryFormInputOID from "../ant-form/LibraryFormInputOID";
import LibraryFormInputName from "../ant-form/LibraryFormInputName";
import LibraryDescriptionOrQuestion from "../ant-form/LibraryDescriptionOrQuestion";
import LibraryFormSelectYesOrNo from "../ant-form/LibraryFormSelectYesOrNo";
import LibraryFormSelectDataType from "../ant-form/LibraryFormSelectDataType";
import {Option} from "antd/es/mentions";

const LibraryFormDef = ({open, setOpen, formDef, formType, reportDesignType, yesOrNo, languages, onFinish, onCancel}) => {

    const [form] = Form.useForm();
    const [type, setType] = useState();
    const [custom, setCustom] = useState(false);

    useEffect(() => {
        form.setFieldsValue(formDef);
        setType(formDef.formType);

    }, [formDef]);

    //visible이 false처리되어 닫힐 때, form 항목 리셋
    useEffect(() => {
        if(!open) { form.resetFields(); }
    }, [open])

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    const onChangeFormType = (_type) => {
        setType(_type);

        if(_type === 'EPRO' || _type === 'CRF_EPRO' || _type === 'INDEPENDENT_EVALUATION_FORM') {
            form.setFieldValue('repeating', 'YES');
        }
        else if(_type === 'INDEPENDENT_EVALUATION_UPLOAD_FORM') {
            form.setFieldValue('repeating', 'NO');
        }
    }

    const onChangeDesignType = (_type) => {
        setCustom(_type === 'CUSTOM');
    }

    const getFormRepeatingDisabledOption = (_type) => {
        switch (_type) {
            case 'EPRO':
            case 'CRF_EPRO':
            case 'INDEPENDENT_EVALUATION_FORM':
                return 'NO';
            case 'INDEPENDENT_EVALUATION_UPLOAD_FORM':
                return 'YES';
            default:
                return '';
        }
    }

    return (
        <Modal
            forceRender={true}
            wrapClassName={'odm-modal'}
            title={<ModalTitle iconType={'formDef'}>Form</ModalTitle>}
            width={800}
            onCancel={onCancel}
            open={open}
            styles={{body:{overflowY: 'auto', height: 'calc(100vh - 20rem)'}}}
            destroyOnClose={true}
            footer={<ModalFooter onCancel={onCancel} form={form} />}
        >
            <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
                <Card title={'Attributes'} type={'inner'} className={'mb-2'}>
                    <Row gutter={[20, 0]}>
                        <Col span={12}>
                            <LibraryFormInputOID selectedOID={formDef?.oid??""} form={form} allowDotChar={false} />
                        </Col>
                        <Col span={12}>
                            <LibraryFormInputName name={formDef?.name} defType={'formDef'} form={form}/>
                        </Col>
                        <Col span={12}>
                            <LibraryFormSelectYesOrNo label={'Repeating'} name={'repeating'} required={true} yesOrNo={yesOrNo} value={formDef?.repeating}
                                                      disabledOption={getFormRepeatingDisabledOption(type)} />
                        </Col>
                        <Col span={12}>
                            <LibraryFormSelectDataType label={'Form Type'} name={'formType'} required={true} dataTypes={formType} value={formDef?.formType}
                                                       onChange={onChangeFormType} />
                        </Col>
                        {
                            type === 'SAE_REPORT_FORM' &&
                            <Col span={custom?12:24}>
                                <Form.Item label={'Report Design Type'} name={'reportDesignType'}>
                                    <Select onChange={onChangeDesignType}>
                                        {
                                            Object.keys(reportDesignType).map(key => (
                                                <Option key={key} value={key}>
                                                    {reportDesignType[key]}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        {
                            type === 'SAE_REPORT_FORM' && custom &&
                            <Col span={12}>
                                <Form.Item label={'Report API Key'} name={'reportApiKey'}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                </Card>
                <Card title={'Description'} type={'inner'} className={'mb-2'}>
                    <LibraryDescriptionOrQuestion languages={languages} name={'description'} form={form}/>
                </Card>
            </Form>
        </Modal>
    )
}

export default LibraryFormDef;