import {useEffect, useState} from "react";
import {Drawer, Pagination, Button} from "antd";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {httpStatus} from "../../../lib/createRequestSaga";
import {errorHandle, onExporting} from "../../../lib/BuilderUtils";
import {Table} from "reactstrap";
import './style.scss';
import NormalLoading from "../NormalLoading";
import DataGrid, {
    Column,
    ColumnChooser,
    Export, FilterRow,
    HeaderFilter,
    Item,
    LoadPanel, Pager, Paging,
    Toolbar
} from "devextreme-react/data-grid";
const StudyStructureUploadHistory = ({showUploadHistory, onHistoryCloseDrawer, range, reload}) => {

    const [uploadHistoryList, setUploadHistoryList] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [detailInfo, setDetailInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dataGrid, setDataGrid] = useState(null);
    const [dataGridDetail, setDataGridDetail] = useState(null);

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params?.sid}/stratification/audit/upload`);

                if(status === httpStatus.ok) {
                    setUploadHistoryList(data?.uploadHistoryList);
                }

            } catch (e) {
                errorHandle(e, navigate, '');
            }
            setLoading(false);
        })()

    }, [reload])

    const onSelectDetails = (historyId) => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params?.sid}/stratification/audit/upload/details/${historyId}`);

                if(status === httpStatus.ok) {
                    setDetailInfo(data?.details);
                }

            } catch (e) {
                errorHandle(e, navigate, '');
            }
        })()

        setShowDetails(true);
    }


    return (
        <>
            <Drawer
                title={"Upload History"}
                placement="right"
                size={'large'}
                onClose={() => onHistoryCloseDrawer('upload')}
                open={showUploadHistory}
            >
                {
                    loading?(
                        <NormalLoading />
                    ):(
                        <>
                            <DataGrid dataSource={uploadHistoryList}
                                      height={'600'}
                                      id={'girdUploadHistory'}
                                      ref={(ref) => {
                                          setDataGrid(ref);
                                      }}
                                      allowedPageSizes={true}
                                      allowColumnResizing={false}
                                      columnMinWidth={50}
                                      columnAutoWidth={true}
                                      showColumnLines={true}
                                      showRowLines={true}
                                      showBorders={true}
                                      hoverStateEnabled={true}
                                      allowColumnReordering={true}
                                      keyExpr={'id'}
                                      onExporting={(e) => onExporting(e, 'Studies',
                                          ({gridCell, excelCell}) => {
                                              if (gridCell.column.dataField === 'action') {
                                                  excelCell.value = '';
                                              }
                                          }
                                      )}>
                                <Column dataField={'num'} caption={('No')} fixed={true} fixedPosition={'left'}
                                        width={'60px'}
                                        cellTemplate={(cellElement, cellInfo) => {
                                            const dataGrid = cellInfo.component;
                                            const num = dataGrid.pageIndex() * dataGrid.pageSize() + cellInfo.rowIndex;
                                            cellElement.innerText= num + 1;
                                        }}
                                />
                                <Column dataField={'user.username'} caption={('User')} dataType={'string'}/>
                                <Column dataField={'uploadCount'} dataType={'number'}/>
                                <Column dataField={'createdDate'} dataType={'datetime'} format={'yyyy-MM-dd HH:mm:ss'}/>
                                <Column dataField={'detail'}
                                        caption={('Detail')}
                                        fixed={true}
                                        fixedPosition={'right'}
                                        allowEditing={false}
                                        cellRender={({data}) =>
                                            <>
                                                <Button onClick={() => onSelectDetails(data?.id)}>
                                                    Detail
                                                </Button>
                                            </>
                                        }
                                />
                                <Toolbar>
                                    <Item name={'columnChooserButton'}/>
                                    <Item name={'exportButton'}/>
                                </Toolbar>
                                <ColumnChooser enabled={true}/>
                                <Export enabled={true} allowExportSelectedData={false}/>
                                <LoadPanel enabled={true}/>
                                <HeaderFilter visible={true} allowSearch={true}/>
                                <FilterRow visible={true} applyFilter={'auto'}/>
                                <Paging defaultPageSize={10}/>
                                <Pager
                                    visible={true}
                                    displayMode={'compact'}
                                    allowedPageSizes={[10, 20, 30, 50]}
                                    showPageSizeSelector={true}
                                    showInfo={true}
                                />
                            </DataGrid>
                            <Drawer
                                title={"Upload History > Detail"}
                                placement="right"
                                size={'large'}
                                onClose={()=>setShowDetails(false)}
                                open={showDetails}
                            >
                                <DataGrid dataSource={detailInfo}
                                          height={'600'}
                                          id={'girdUploadHistoryDetail'}
                                          ref={(ref) => {
                                              setDataGridDetail(ref);
                                          }}
                                          allowedPageSizes={true}
                                          allowColumnResizing={false}
                                          columnMinWidth={50}
                                          columnAutoWidth={true}
                                          showColumnLines={true}
                                          showRowLines={true}
                                          showBorders={true}
                                          hoverStateEnabled={true}
                                          allowColumnReordering={true}
                                          keyExpr={'id'}
                                          onExporting={(e) => onExporting(e, 'Studies',
                                              ({gridCell, excelCell}) => {
                                                  if (gridCell.column.dataField === 'action') {
                                                      excelCell.value = '';
                                                  }
                                              }
                                          )}>
                                    <Column dataField={'num'} caption={('No')} fixed={true} fixedPosition={'left'}
                                            width={'60px'}
                                            cellTemplate={(cellElement, cellInfo) => {
                                                const dataGrid = cellInfo.component;
                                                const num = dataGrid.pageIndex() * dataGrid.pageSize() + cellInfo.rowIndex;
                                                cellElement.innerText= num + 1;
                                            }}
                                    />
                                    <Column dataField={'env.key'} dataType={'string'}/>
                                    {
                                        range != 'CENTRAL' &&
                                        <Column dataField={'siteOid'} dataType={'string'}/>
                                    }
                                    <Column dataField={'stratification'}
                                            caption={('stratification')}
                                            cellRender={({data}) => {
                                                const stratification = data?.stratification === 0 ? '-' : data?.stratification;
                                                return (
                                                    <>
                                                        <span>{stratification}</span>
                                                    </>
                                                )
                                            }}
                                    />
                                    <Column dataField={'sequenceNo'} dataType={'string'}/>
                                    <Column dataField={'randomNo'} dataType={'string'}/>
                                    <Toolbar>
                                        <Item name={'columnChooserButton'}/>
                                        <Item name={'exportButton'}/>
                                    </Toolbar>
                                    <ColumnChooser enabled={true}/>
                                    <Export enabled={true} allowExportSelectedData={false}/>
                                    <LoadPanel enabled={true}/>
                                    <HeaderFilter visible={true} allowSearch={true}/>
                                    <FilterRow visible={true} applyFilter={'auto'}/>
                                    <Paging defaultPageSize={10}/>
                                    <Pager
                                        visible={true}
                                        displayMode={'compact'}
                                        allowedPageSizes={[10, 20, 30, 50]}
                                        showPageSizeSelector={true}
                                        showInfo={true}
                                    />
                                </DataGrid>
                            </Drawer>
                        </>
                    )
                }
            </Drawer>
        </>
    )
}

export default StudyStructureUploadHistory;