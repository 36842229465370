import {Helmet} from "react-helmet-async";
import LibraryBuildFormContainer from "../../../../../components/common/builder/global-library/container/LibraryBuildFormContainer";
import {useParams} from "react-router-dom";

const LibraryFormPage = () => {

    const {oid} = useParams();

    return (
        <>
            <Helmet title={`BUILDER :: Form[${oid}]`} />
            <LibraryBuildFormContainer />
        </>
    )
}

export default LibraryFormPage;