import {Button, Tooltip} from "antd";
import React from "react";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../../lib/ODMUtils";

const MandatoryBtn = ({refItem, refInfo, defOID, toggleMandatory, isProd}) => {

    return(
        <Tooltip placement="top" title={`Mandatory : ${refItem.mandatory}`}>
            <Button type={'default'} size={'small'} className={'mr-1'} style={{cursor:isProd?'default':null}}
                    onClick={() => toggleMandatory(refInfo.oid, defOID)}
            >
                {
                    refItem?.mandatory === 'YES' ? (
                        <i className='fa fa-check-square-o'/>
                    ) : (
                        <i className='fa fa-square-o'/>
                    )
                }
            </Button>
        </Tooltip>
    )
}

export default MandatoryBtn;