import React from 'react'
import {Helmet,} from 'react-helmet-async'
import LoginV2 from '@vb/sscomponents/Auth/LoginV2';
import AuthLayout from "../../../layouts/Auth";

const SystemLogin = () => {

    return (
        <AuthLayout authPagesColor={'image'}
                    descr={'BUILDER'}
                    isAuthTopbar={true}
                    isGrayTopbar={false}>
            <Helmet title={'Login'} />
            <LoginV2 logo={<span className={'mr-2'}><span style={{color:'red'}}>RED</span>PINE BUILDER</span>} />
        </AuthLayout>
    )
}

export default SystemLogin
