import {useEffect, useState} from "react";
import LibraryValueListDef from "../modal/LibraryValueListDef";
import useLibraryStore from "../../../../../store/libraryStore";
import {useMutation} from "react-query";
import {method, saveDefWithAxios} from "../../../../../lib/api/library";
import {message} from "antd";
import {errorHandle} from "../../../../../lib/BuilderUtils";
import {descriptionCheck} from "../../../../../lib/ODMUtils";
import {toLibraryMap} from "../../../../../lib/LibraryUtils";

const LibraryValueListContainer = ({defOpen, setDefOpen, defItem, setDefItem}) => {

    const {
        library,
        languages,
        initialValue,
        updateLibrary,
    } = useLibraryStore(state => (state));
    const libraryMap = toLibraryMap(library);

    //컴포넌트에 따라 필요한 정보
    const propName = 'valueListDef';
    const [valueListDef, setValueListDef] = useState({}); //현재 CodeList에 대한 정보
    const [create, setCreate] = useState(false);

    //UseMutation 사용
    const {mutate} = useMutation(saveDefWithAxios, {
        onSuccess:({library, resultMessage}) => {
            console.log(`ItemGroup ${create?'Create':'Update'} : `, library);
            message.success(resultMessage);
            updateLibrary(library);
            onCancel();
        },
        onError: (error) => {
            errorHandle(error);
        },
    });

    useEffect(() => {
        if (defItem !== null) { //Update
            setValueListDef(defItem);
            setCreate(false);
        } else { //New
            setValueListDef(initialValue?.valueList??{});
            setCreate(true);
        }
    }, [defItem]);

    const onCancel = () => {
        setValueListDef({});
        setDefItem(null);
        setDefOpen(false);
        setCreate(false);
    }

    const onFinish = (valueListDef) => {
        //true or false를 Yes or No로 변경
        if (valueListDef?.itemRef != null) {
            valueListDef.itemRef.map((itemRef, index) => {
                valueListDef.itemRef[index].mandatory = valueListDef.itemRef[index].mandatory?'YES':'NO';
                valueListDef.itemRef[index].orderNumber = (index+1);
            });
        }
        descriptionCheck(valueListDef); //description nullCheck 후, 없으면 삭제

        const {id} = library;
        valueListDef.id = id;
        valueListDef.attr = propName;
        valueListDef.method = create?method.post:method.put;
        mutate(valueListDef);

        onCancel(); //Modal 화면 사라짐.
    };

    return (
        <LibraryValueListDef library={libraryMap} open={defOpen} valueListDef={valueListDef}
                             languages={languages} onFinish={onFinish} onCancel={onCancel} />
    )

}

export default LibraryValueListContainer;