// a little function to help us with reordering the result
import {Dispatch, SetStateAction} from "react";
import _ from 'lodash';

interface Map<K, V> {
    clear(): void;
    delete(key: K): boolean;
    entries(): IterableIterator<[K, V]>;
    forEach(callbackfn: (value: V, index: K, map: Map<K, V>) => void, thisArg?: any): void;
    get(key: K): V;
    has(key: K): boolean;
    keys(): IterableIterator<K>;
    set(key: K, value?: V): Map<K, V>;
    size: number;
    values(): IterableIterator<V>;
    [Symbol.iterator]():IterableIterator<[K,V]>;
    [Symbol.toStringTag]: string;
}

interface IMetaData {
    studyEventGroup: Map<string, any>;
    studyEvent: Map<string, any>;
    form: Map<string, any>;
    itemGroup: Map<string, any>;
    item: Map<string, any>;
}

interface IFormDef {
    description: object;
    itemGroupRef: object[];
    archiveLayout: object[];
    alias: object[];
    oid: string;
    name: string;
    repeating: string;
    formType: string;
}


interface IDroppable {
    index: number;
    droppableId: string;
}



const itemGroupRefData = {
    itemGroupOID: "", //required
    orderNumber: null,
    mandatory: "NO", //required
    collectionExceptionConditionOID: null,
};

const itemRefData = {
    itemOID: "", //required
    mandatory: "NO", //required
    orderNumber: null, //index value
    keySequence: null,
    methodOID: "",
    UnitsItemOID: null,
    role: null,
    roleCodeListOID: null,
    collectionExceptionConditionOID: null,
};

const codeListRefData = {
    codeListOID: null
}

/**
 * ItemGroup Copy
 * @Author sh.yang
 * @param formDef
 * @param itemRefMap
 * @param setItemRefMap
 * @param draggableId
 * @param destination
 * @returns {void}
 */
export const copyItemGroup = (metaData:IMetaData, formDef:IFormDef, setFormDef:Dispatch<SetStateAction<IFormDef>>,
                              itemRefMap:Map<string, any>, setItemRefMap:Dispatch<SetStateAction<Map<string, any>>>,
                              draggableId:string, destination:IDroppable):void => {
    //itemGroupRef Copy
    const cloneItemGroupRef = _.cloneDeep(formDef.itemGroupRef);
    cloneItemGroupRef.splice(destination.index, 0, {...itemGroupRefData, itemGroupOID: draggableId});
    cloneItemGroupRef.map((itemGroupRef:any, index:number) => itemGroupRef.orderNumber = index);
    setFormDef({...formDef, itemGroupRef:cloneItemGroupRef});

    //ItemGroup's ItemRef Copy
    const cloneItemRefMap = _.cloneDeep(itemRefMap);
    const cloneItemRef = _.cloneDeep(metaData.itemGroup.get(draggableId).itemRef);
    if(!cloneItemRefMap.has(draggableId)) { //현재 itemRefMap에서 복사하려는 ItemGroup의 Key값을 갖고있지 않으면,
        cloneItemRefMap.set(draggableId, cloneItemRef);
        setItemRefMap(cloneItemRefMap);
    }
};

/**
 * Item Copy
 * @param metaData - MetaData 정보
 * @param itemRefMap
 * @param setItemRefMap
 * @param draggableId
 * @param destination
 */
export const copyItem = (itemRefMap:Map<string, any>, setItemRefMap:Dispatch<SetStateAction<Map<string,any>>>,
                         draggableId:string, destination:IDroppable):void => {

    //ItemGroup's ItemRef Copy
    const cloneItemRefMap = _.cloneDeep(itemRefMap);
    const cloneItemRef = cloneItemRefMap.get(destination.droppableId);

    if(cloneItemRefMap.has(destination.droppableId)) {
        //현재 copy하려는 Item이 해당 ItemGroup에 존재하지 않는 경우에만 복사함.
        if(!cloneItemRef.some((itemRef:any) => itemRef.itemOID === draggableId)) {
            cloneItemRef.splice(destination.index, 0, {...itemRefData, itemOID:draggableId});
            cloneItemRef.map((itemRef:any, index:number) => itemRef.orderNumber = index);
            cloneItemRefMap.set(destination.droppableId, cloneItemRef);
            setItemRefMap(cloneItemRefMap);
        }
    }
};

/**
 * ItemGroup Reorder
 * @param formDef
 * @param setFormDef
 * @param startIndex
 * @param endIndex
 */
export const reorderItemGroup = (formDef:IFormDef, setFormDef:Dispatch<SetStateAction<IFormDef>>,
                                 startIndex:number, endIndex:number):void => {
    const cloneFormDef = _.cloneDeep(formDef);
    const [removed] = cloneFormDef.itemGroupRef.splice(startIndex, 1);
    cloneFormDef.itemGroupRef.splice(endIndex, 0, removed);
    cloneFormDef.itemGroupRef.map((itemGroupRef:any, index:number) => itemGroupRef.orderNumber=index);
    // console.log(cloneFormDef.itemGroupRef);
    setFormDef(cloneFormDef);
};

/**
 *
 * @param itemGroupMap
 * @param setItemGroupMap
 * @param sourceDroppableId
 * @param startIndex
 * @param endIndex
 */
export const reorderItem = (metaData:IMetaData, itemRefMap:Map<string, any>, setItemRefMap:Dispatch<SetStateAction<Map<string, any>>>,
                            sourceDroppableId:string, startIndex:number, endIndex:number):void => {

    const cloneItemRefMap = _.cloneDeep(itemRefMap);
    const [removed] = cloneItemRefMap.get(sourceDroppableId).splice(startIndex, 1);
    cloneItemRefMap.get(sourceDroppableId).splice(endIndex, 0, removed);

    //orderNumber setting
    cloneItemRefMap.get(sourceDroppableId).map((itemRef:any, index:number) => itemRef.orderNumber=index);

    // console.log(cloneItemRefMap.get(sourceDroppableId));
    setItemRefMap(getKeySeqItemMap(metaData, cloneItemRefMap, [sourceDroppableId], null));
};

/**
 *
 * @param formDef
 * @param setFormDef
 * @param itemRefMap
 * @param setItemRefMap
 * @param source - from ItemGroup [droppableId : ItemGroup OID / index : Item's index]
 * @param destination - to ITemGroup [droppableId : ItemGroup OID / index : Item's index]
 * @param draggableId - ${itemGroup OID}::${item OID}
 */
export const move = (metaData:IMetaData, draggableId:string, itemRefMap:Map<string, any>, setItemRefMap:Dispatch<SetStateAction<Map<string, any>>>,
                     source:IDroppable, destination:IDroppable):void => {
    //Init Setting
    const dragItemOID = draggableId.split("::")[1]
    const cloneItemRefMap = _.cloneDeep(itemRefMap);
    const sourceItemRef = cloneItemRefMap.get(source.droppableId);
    const destinationItemRef = cloneItemRefMap.get(destination.droppableId);

    //Destination 대상이 되는 ItemGroup의 Item 중에서, Source의 Item 항목과 중복되는 경우 동작을 취소
    if(destinationItemRef.some((itemRef:any) => itemRef.itemOID === sourceItemRef[source.index].itemOID)) {return;}

    //CASE 2. 이동하려는 Item이 ValueList를 참조하고있다면, Destination ItemGroupDef의 repeating 속성이 static이 아니면 동작을 취소
    const targetItemDef = metaData.item.get(dragItemOID);
    const destinationItemGroupDef = metaData.itemGroup.get(destination.droppableId);
    if(targetItemDef?.valueListRef?.valueListOID != null && destinationItemGroupDef?.repeating !== 'STATIC'){return;};

    //source, destination 간의 index 위치 변경
    const [removedItem] = sourceItemRef.splice(source.index, 1);
    destinationItemRef.splice(destination.index, 0, removedItem);

    //order Number 재 입력.
    sourceItemRef.map((itemRef:any, index:number) => itemRef.orderNumber = index);
    destinationItemRef.map((itemRef:any, index:number) => itemRef.orderNumber = index);
    setItemRefMap(getKeySeqItemMap(metaData, cloneItemRefMap, [source.droppableId, destination.droppableId], null));

    // console.log(sourceItemRef);
    // console.log(destinationItemRef);
};

/**
 * ItemRefMap에 대하여 keySequence를 설정하는 동작
 * @param itemRefMap
 * @param itemGroupOID
 * @param itemOID
 */
export const getKeySeqItemMap = (metadata:IMetaData, itemRefMap:Map<string, any>, itemGroupOIDs:string[], itemOID:string|null):Map<string, any> => {
    const cItemRefMap = _.cloneDeep(itemRefMap);

    itemGroupOIDs.forEach((itemGroupOID:string) => {
        const itemRefs = Array.from(cItemRefMap.get(itemGroupOID));
        let keySequence = 0;

        //ItemRef 항목에 대하여 반복작업
        itemRefs.forEach((itemRef:any) => {
            //SIMPLE일 때만 사용
            if(metadata.itemGroup.get(itemGroupOID)?.repeating === 'SIMPLE') {
                if(itemRef?.itemOID === itemOID) {
                    //keySequence값 설정 후 +1, 단 클릭 대상인경우 재 클릭 시 null
                    itemRef.keySequence = (itemRef.keySequence == null?(keySequence++):null);
                } else if(itemRef.keySequence != null) {
                    //keySequence값 설정 후 +1
                    itemRef.keySequence = keySequence++;
                }
            }
            //SIMPLE이 아닌 경우, 사용하면 안되므로 NULL처리
            else {
                itemRef.keySequence = null;
            }
        });

        cItemRefMap.set(itemGroupOID, itemRefs);
    })

    return cItemRefMap;
}
