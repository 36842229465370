import {useLocation, useNavigate, useParams} from "react-router-dom";
import queryString from "query-string";
import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, InputNumber, message, Row, Select} from "antd";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle} from "../../../../lib/BuilderUtils";
import DefaultLoading from "../../DefaultLoading";
import {CardBody, CardFooter, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {RightOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const SponsorRoleEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();

    //query-string이 존재하면 studyID에 해당하는 값 불러오기.
    const params = useParams();
    const query = queryString.parse(location.search);
    const [role, setRole] = useState(null);
    const [form] = Form.useForm();

    //Button Loading
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        (async () => {
            //GET Request
            try {
                //REST API Request
                const {data, status} = await axios.get(`/api/builder/sponsors/${params.sponsorId}/sponsor-roles/edit`, {params: query});

                if(status === httpStatus.ok) {
                    //State Setting
                    setRole(data.sponsorRole);
                    form.setFieldsValue({
                        shortName: data?.sponsorRole?.shortName,
                        displayName: data?.sponsorRole?.displayName,
                        displayOrder: data?.sponsorRole?.displayOrder,
                    })
                }
            } catch (e) {
                errorHandle(e, navigate, `/sponsors/${params.sponsorId}/sponsor-roles`);
            }
        })()
    }, [])


    const onFinish = async (values) => {
        setLoading(true);

        try {
            const body = {
                ...role,
                ...values,
            }

            const {status, data} = await axios({
                url: `/api/builder/sponsors/${params.sponsorId}/sponsor-roles/edit`,
                data: body,
                method: 'post'
            })

            if(status === httpStatus.ok) {
                message.success(data.message);
                navigate(`/sponsors/${params.sponsorId}/sponsor-roles`);
            }
        } catch(e) {
            errorHandle(e);
        }

        setLoading(false);
    }

    const onFinishFailed = (error) => {
        console.log(error);
    }

    return (
        <>
            {
                role === null &&
                <DefaultLoading />
            }
            {
                role !== null &&
                <DefaultCard>
                    <CardHeader>
                        <h3><RightOutlined className={'mr-2'} />Sponsor Role</h3>
                    </CardHeader>
                    <Form layout="vertical"
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          form={form}
                    >
                        <CardBody>
                            <Row gutter={[20, 0]}>
                                <Col span={'16'}>
                                    <Form.Item name={'shortName'} label={'Abbreviation Name'} required
                                               rules={[{
                                                   required: true,
                                                   message: t('message.pls.enter.abbreviation.name')
                                               }]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={'8'}>
                                    <Form.Item name={'displayOrder'} label={'Order'}>
                                        <InputNumber style={{width:'100%'}} min={0} />
                                    </Form.Item>
                                </Col>
                                <Col span={'24'}>
                                    <Form.Item name={'displayName'} label={'Name'} required
                                               rules={[{
                                                   required: true,
                                                   message: t('message.enter.name')
                                               }]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className='text-right'>
                            <Button type={'default'} onClick={() => {
                                navigate(`/sponsors/${params.sponsorId}/sponsor-roles`);
                            }} className={'mr-3'}> Cancel </Button>
                            <Button loading={loading} type={'primary'} htmlType={'submit'}> Submit </Button>
                        </CardFooter>
                    </Form>
                </DefaultCard>
            }
        </>
    )
}

export default SponsorRoleEdit