import {Helmet} from "react-helmet-async";
import LibraryFormListContainer
    from "../../../../../components/common/builder/global-library/container/LibraryFormListContainer";

const LibraryFormListPage = () => {

    return (
        <>
            <Helmet title={"BUILDER :: Form"} />
            <LibraryFormListContainer />
        </>
    )
}

export default LibraryFormListPage;