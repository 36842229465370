import {Helmet} from "react-helmet-async";
import React from "react";
import EmailNotice from "../../../../../components/common/builder/studies/EmailNotice";

const EmailNoticeEditPage = () => {

    return (
        <>
            <Helmet title={'BUILDER :: SAE Report'} />
            <EmailNotice />
        </>
    )

}

export default EmailNoticeEditPage
