import {Helmet} from "react-helmet-async";
import GlobalLibraryList from "../../../components/common/builder/global-library/GlobalLibraryList";

const GlobalLibraryPage = () => {


    return (
        <>
            <Helmet title={"BUILDER :: Global Libraries"} />
            <GlobalLibraryList />
        </>
    )
}

export default GlobalLibraryPage;