import React, {useEffect} from "react";
import {Navigate, Outlet, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../redux/module/user";
import {isBuilderAccessAuthorities} from "../../lib/AuthorityUtils";
import MainLayout from "../../layouts/Main";
import {SIGNOUT} from "../constant/responseType";
import {useTranslation} from "react-i18next";

const PrivateRoute = ({isLayout = true}) => {
    const {user} = useSelector(({user}) => ({user}));
    const dispatch = useDispatch();
    const location = useLocation();
    const {t} = useTranslation();

    useEffect( () => {
        if (!(user.user !== null || user.authorized) && user.responseStatus !== SIGNOUT) {
            dispatch(userActions.loadCurrentAccount());
        }
    }, [location.pathname]);

    return (user.loaded && (user?.loaded && user?.authorized) ? (
        (isBuilderAccessAuthorities(user?.user?.authorities) || location.pathname === '/logout') ? (
            <>
                {
                    isLayout? (<MainLayout user={user}><Outlet /></MainLayout>):(<Outlet />)
                }
            </>
        ) : (
            <Navigate state={{from: location.pathname, explain: t('explain.user.permission.denied')}} to={"/403"}/>
        )) : (
        <Navigate state={{from: location.pathname, explain: t('explain.no.auth.info')}} to={"/login"}/>
    ));

}

export default PrivateRoute;