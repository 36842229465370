import {
    ItemAddButtonBlock,
    ItemDiv,
    ItemDivBlock,
    Notice, RepeatingHeader, RepeatingItemDiv,
    RepeatingItemDivBlock, RepeatingItemFieldWrapper, RepeatingItemTitleArea
} from "../../../../../container/build-tool/StyleBox";
import {Draggable, Droppable} from "react-beautiful-dnd";
import React from "react";
import {Button, Col, Row} from "antd";
import style from "../../../../../container/build-tool/content/style.module.scss";
import DefinitionBtn from "../../../../../container/build-tool/content/DefinitionBtn";
import {QuestionText} from "../../../../../container/build-tool/content/FormComponents";
import LibraryItemOption from "./LibraryItemOption";
import LibraryItemField from "./LibraryItemField";

const LibraryItem = ({ library, designMode, selectedLanguage, itemGroup, itemRefMap, setItemRefMap,
                         setDefOpen, setDefType, setDefItem, setDefOption, dropEnable }) => {

    const isEnable = (enable, draggableId) => {
        //move Type에, draggableId가 일치한 경우만
        if (enable !== null && enable.destination !== null) {
            if (enable.type === 'move' && enable.draggableId === draggableId) {
                return enable.checked;
            }
        }
        return null;
    }

    const onCreateItem = () => {
        setDefOpen(true);
        setDefType('item');
        setDefItem(null);
        setDefOption({
            option: 'itemDef',
            itemGroupOID: itemGroup?.oid,
        });
    }

    const isEnableUnit = (itemDef) => {
        return itemDef?.codeListRef?.codeListOID == null && (itemDef?.dataType === 'INTEGER' || itemDef?.dataType === 'FLOAT' || itemDef?.dataType === 'DOUBLE');
    }

    const isIgnoreMarginBottom = (itemDef) => {
        return itemDef?.codeListRef?.codeListOID != null || itemDef?.controlType === "TEXTAREA";
    }

    const getValueListOID = (valueListOID, itemGroup, itemOID) => {
        //ORRES인 경우, 앞의 글자를 제한 내용 + TESTCD에 valueList가 있으면 넘김.
        if(itemOID.endsWith('ORRES')) {
            const test = itemOID.split('ORRES')[0];
            const testCD = `${test}TESTCD`;
            return itemRefMap.get(itemGroup.oid).find(i => i.itemOID === testCD)?.valueListOID;
        }
        return valueListOID;
    }

    return (
        <>
            {
                itemGroup?.repeating === "NO" && (
                    <Droppable key={itemGroup.oid} droppableId={itemGroup.oid} type="item">
                        {
                            (provided, snapshot) => (
                                <ItemDivBlock key={itemGroup.oid}
                                              {...provided.droppableProps}
                                              ref={provided.innerRef} skyblue
                                              isDraggingOver={snapshot.isDraggingOver}>
                                    {
                                        (itemRefMap.get(itemGroup.oid) != null && itemRefMap.get(itemGroup.oid).length) > 0 ?
                                            itemRefMap.get(itemGroup.oid).map((itemRef, index) => (
                                            <>
                                                {
                                                    (library.item.get(itemRef.itemOID) != null) &&
                                                    <Draggable key={`${itemGroup.oid}::${itemRef.itemOID}`}
                                                               draggableId={`${itemGroup.oid}::${itemRef.itemOID}`}
                                                               index={index}>
                                                        {
                                                            (provided, snapshot) => (
                                                                <ItemDiv
                                                                    key={`${itemGroup.oid}::${itemRef.itemOID}`}
                                                                    ref={provided.innerRef}
                                                                    isIgnoreMarginBottom={isIgnoreMarginBottom(library.item.get(itemRef.itemOID))}
                                                                    {...provided.draggableProps} //Draggable 옵션을 가져올 수 있게 설정 (Source, destination 등)
                                                                    isDragging={snapshot.isDragging}
                                                                    enable={isEnable(dropEnable, `${itemGroup.oid}::${itemRef.itemOID}`)}>
                                                                    <Row gutter={[20, 0]} className={style.itemWrapper}
                                                                         id={`${itemGroup.oid}::${itemRef.itemOID}`} {...provided.dragHandleProps}>
                                                                        <Col span={10}>
                                                                            {
                                                                                designMode &&
                                                                                <div className={style.itemTitleBlock}>
                                                                                    <DefinitionBtn
                                                                                        data={library.item.get(itemRef.itemOID)}
                                                                                        defType={'item'}
                                                                                        setDefVisible={setDefOpen}
                                                                                        setDefType={setDefType}
                                                                                        setDefItem={setDefItem}/>
                                                                                </div>
                                                                            }
                                                                            <div className={style.questionBlock}>
                                                                                <QuestionText
                                                                                    mandatory={itemRef?.mandatory}
                                                                                    itemDef={library.item.get(itemRef.itemOID)}
                                                                                    selectedLanguage={selectedLanguage}
                                                                                    designMode={designMode}/>
                                                                            </div>
                                                                            <LibraryItemOption isRepeating={false} designMode={designMode} library={library}
                                                                                               itemGroup={itemGroup} itemRef={itemRef}
                                                                                               itemRefMap={itemRefMap} setItemRefMap={setItemRefMap}
                                                                                               index={index} isEnableUnit={isEnableUnit} />
                                                                        </Col>
                                                                        <Col span={14}>
                                                                            <LibraryItemField library={library}
                                                                                              designMode={designMode}
                                                                                              selectedLanguage={selectedLanguage}
                                                                                              setDefOpen={setDefOpen}
                                                                                              setDefType={setDefType}
                                                                                              setDefItem={setDefItem}
                                                                                              item={library.item.get(itemRef.itemOID)}
                                                                                              repeating={itemGroup.repeating}
                                                                                              itemGroupOID={itemGroup.oid}
                                                                                              unitsItemOID={itemRef.unitsItemOID}
                                                                                              valueListOID={getValueListOID(itemRef.valueListOID, itemGroup, itemRef.itemOID)}
                                                                                // onECSInfo={onECSInfo}
                                                                                // methodOID={itemRef.methodOID}
                                                                                // collectionExceptionConditionOID={itemRef.collectionExceptionConditionOID}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </ItemDiv>
                                                            )
                                                        }
                                                    </Draggable>
                                                }
                                            </>
                                        ))
                                        : !provided.placeholder.props.on &&
                                        (
                                            <Notice> Drop item here </Notice>
                                        )
                                    }
                                    {
                                        (designMode && !snapshot.isDraggingOver) &&
                                        <ItemAddButtonBlock>
                                            <Button type={"dashed"} className={'mr-2'} onClick={onCreateItem}>
                                                <i className='fa fa-plus mr-2'/>Item
                                            </Button>
                                        </ItemAddButtonBlock>
                                    }
                                    {provided.placeholder}
                                </ItemDivBlock>
                            )
                        }
                    </Droppable>
                )
            }
            {
                itemGroup?.repeating !== "NO" && (
                    <Droppable key={itemGroup.oid} droppableId={itemGroup.oid} type="item" direction='horizontal'
                               repeating={itemGroup.repeating !== 'NO'}>
                        {
                            (provided, snapshot) => (
                                <ItemDivBlock key={itemGroup.oid}>
                                    <RepeatingItemDivBlock {...provided.droppableProps}
                                                           ref={provided.innerRef}
                                                           enable={dropEnable} skyblue
                                                           isDraggingOver={snapshot.isDraggingOver}>
                                        {
                                            (itemRefMap.get(itemGroup.oid) != null && itemRefMap.get(itemGroup.oid).length) > 0 ?
                                                itemRefMap.get(itemGroup.oid).map((itemRef, index) => (
                                                    <Draggable key={`${itemGroup.oid}::${itemRef.itemOID}`}
                                                               draggableId={`${itemGroup.oid}::${itemRef.itemOID}`}
                                                               index={index}>
                                                        {
                                                            (provided, snapshot) => (
                                                                <RepeatingItemDiv
                                                                    key={`${itemGroup.oid}::${itemRef.itemOID}`}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps} //Draggable 옵션을 가져올 수 있게 설정 (Source, destination 등)
                                                                    isDragging={snapshot.isDragging}
                                                                    enable={isEnable(dropEnable, `${itemGroup.oid}::${itemRef.itemOID}`)}>
                                                                    <RepeatingHeader
                                                                        id={`${itemGroup.oid}::${itemRef.itemOID}`}
                                                                        {...provided.dragHandleProps}>
                                                                        {
                                                                            designMode &&
                                                                            <div
                                                                                className={style.repeatingItemTitleWrapper}>
                                                                                <RepeatingItemTitleArea>
                                                                                    <DefinitionBtn
                                                                                        data={library.item.get(itemRef.itemOID)}
                                                                                        defType={'item'}
                                                                                        setDefVisible={setDefOpen}
                                                                                        setDefType={setDefType}
                                                                                        setDefItem={setDefItem}/>
                                                                                </RepeatingItemTitleArea>
                                                                            </div>
                                                                        }
                                                                        <div className={style.questionBlock}>
                                                                            <QuestionText
                                                                                mandatory={itemRef?.mandatory}
                                                                                keySequence={itemRef?.keySequence}
                                                                                itemDef={library.item.get(itemRef.itemOID)}
                                                                                selectedLanguage={selectedLanguage}
                                                                                designMode={designMode}/>
                                                                        </div>
                                                                    </RepeatingHeader>
                                                                    <RepeatingItemFieldWrapper>
                                                                        <LibraryItemField library={library}
                                                                                          designMode={designMode}
                                                                                          selectedLanguage={selectedLanguage}
                                                                                          setDefOpen={setDefOpen}
                                                                                          setDefType={setDefType}
                                                                                          setDefItem={setDefItem}
                                                                                          item={library.item.get(itemRef.itemOID)}
                                                                                          repeating={itemGroup.repeating}
                                                                                          itemGroupOID={itemGroup.oid}
                                                                                          unitsItemOID={itemRef.unitsItemOID}
                                                                                          valueListOID={getValueListOID(itemRef.valueListOID, itemGroup, itemRef.itemOID)}
                                                                                          // onECSInfo={onECSInfo}
                                                                                          // methodOID={itemRef.methodOID}
                                                                                          // collectionExceptionConditionOID={itemRef.collectionExceptionConditionOID}
                                                                        />
                                                                        <LibraryItemOption isRepeating={true} designMode={designMode} library={library}
                                                                                           itemGroup={itemGroup} itemRef={itemRef}
                                                                                           itemRefMap={itemRefMap} setItemRefMap={setItemRefMap}
                                                                                           index={index} isEnableUnit={isEnableUnit} />
                                                                    </RepeatingItemFieldWrapper>
                                                                </RepeatingItemDiv>
                                                            )
                                                        }
                                                    </Draggable>
                                            )) :!provided.placeholder.props.on && (
                                                <Notice> Drop item here </Notice>
                                            )
                                        }
                                        {provided.placeholder}
                                    </RepeatingItemDivBlock>
                                    {
                                        (designMode) &&
                                        <ItemAddButtonBlock>
                                            <Button type={"dashed"} className={'mr-2'} onClick={onCreateItem}>
                                                <i className='fa fa-plus mr-2'/>Item
                                            </Button>
                                        </ItemAddButtonBlock>
                                    }
                                </ItemDivBlock>
                            )
                        }
                    </Droppable>
                )
            }
        </>
    )
}

export default LibraryItem;