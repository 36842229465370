import {Helmet} from "react-helmet-async";
import AuthLayout from "../../../layouts/Auth";
import React from "react";
import Error403 from "../../../@vb/sscomponents/Errors/403";
import {useSelector} from "react-redux";

const System403 = () => {
    const user = useSelector(({user}) => user);

    return (
        <AuthLayout authPagesColor={'image'}
                    descr={'BUILDER'}
                    user={user}
                    isAuthTopbar={true}
                    isGrayTopbar={false}>
            <Helmet title={'Page 403'} />
            <Error403/>
        </AuthLayout>
    )
}

export default System403;