import React from "react";
import {Anchor, Card, Col, Row, Table, Tabs, Tag} from "antd";
import {useSelector} from "react-redux";
import {isEmpty} from "../../../lib/StringUtils";


/**
 * 처음 Forms 화면에서 List일 때, 동작하는 Container
 * @returns {JSX.Element}
 * @constructor
 */
const { TabPane } = Tabs;

const studyEventGroupColumns = [
    {
        title: 'OID',
        dataIndex: 'oid',
        key: 'oid',
        render:(text, record) => (
            <Tag id={`seg-${record.oid}`} color={'geekblue'}>{record.oid}</Tag>
        )
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Repeating',
        dataIndex: 'repeating',
        key: 'repeating',
    },
    {
        title: 'StudyEvent Reference',
        dataIndex: 'studyEventRef',
        key: 'studyEventRef',
        render:(text, record) => (
            <table className={'table'}>
                <thead>
                <tr>
                    <th>StudyEventOID</th>
                    <th>Mandatory</th>
                    <th>Skip Condition</th>
                </tr>
                </thead>
                <tbody>
                {record?.studyEventRef.map(studyEventRef => (
                        <tr key={`${record.oid}_${studyEventRef.studyEventOID}`}>
                            <td>
                                {/**/}
                                <Anchor affix={false} showInkInFixed={false}><Link href={`#se-${studyEventRef.studyEventOID}`} title={<Tag color={'success'}>{studyEventRef.studyEventOID}</Tag>}/></Anchor>
                            </td>
                            <td>{studyEventRef.mandatory}</td>
                            <td>{studyEventRef.collectionExceptionConditionOID}</td>
                        </tr>
                ))}
                </tbody>
            </table>
        )
    },
];

const studyEventColumns = [
    {
        title: 'OID',
        dataIndex: 'oid',
        key: 'oid',
        render:(text, record) => (
            <Tag id={`se-${record.oid}`} color={'geekblue'}>{record.oid}</Tag>
        )
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Repeating',
        dataIndex: 'repeating',
        key: 'repeating',
    },
    {
        title: 'Form Reference',
        dataIndex: 'formRef',
        key: 'formRef',
        render:(text, record) => (
            <table className={'table'}>
                <thead>
                <tr>
                    <th>Form OID</th>
                    <th>Mandatory</th>
                    <th>Skip Condition</th>
                </tr>
                </thead>
                <tbody>
                {record?.formRef.map(formRef => (
                    <tr key={`${record.oid}_${formRef.formOID}`}>
                        <td>
                            {/**/}
                            <Anchor affix={false} showInkInFixed={false}><Link href={`#form-${formRef.formOID}`} title={<Tag color={'success'}>{formRef.formOID}</Tag>}/></Anchor>
                        </td>
                        <td>{formRef.mandatory}</td>
                        <td>{formRef.collectionExceptionConditionOID}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        )
    },
];

const formColumns = [
    {
        title: 'OID',
        dataIndex: 'oid',
        key: 'oid',
        render:(text, record) => (
            <Tag id={`form-${record.oid}`} color={'geekblue'}>{record.oid}</Tag>
        )
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Repeating',
        dataIndex: 'repeating',
        key: 'repeating',
    },
    {
        title: 'ItemGroup Reference',
        dataIndex: 'itemGroupRef',
        key: 'itemGroupRef',
        render:(text, record) => (
            <table className={'table'}>
                <thead>
                <tr>
                    <th>ItemGroup OID</th>
                    <th>Mandatory</th>
                    <th>Skip Condition</th>
                </tr>
                </thead>
                <tbody>
                {record?.itemGroupRef.map(itemGroupRef => (
                    <tr key={`${record.oid}_${itemGroupRef.itemGroupOID}`}>
                        <td>
                            {/**/}
                            <Anchor affix={false} showInkInFixed={false}><Link href={`#ig-${itemGroupRef.itemGroupOID}`} title={<Tag color={'success'}>{itemGroupRef.itemGroupOID}</Tag>}/></Anchor>
                        </td>
                        <td>{itemGroupRef.mandatory}</td>
                        <td>{itemGroupRef.collectionExceptionConditionOID}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        )
    },
];

const itemGroupColumns = [
    {
        title: 'OID',
        dataIndex: 'oid',
        key: 'oid',
        render:(text, record) => (
            <Tag id={`ig-${record.oid}`} color={'geekblue'}>{record.oid}</Tag>
        )
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Domain',
        dataIndex: 'domain',
        key: 'domain',
    },
    {
        title: 'Repeating',
        dataIndex: 'repeating',
        key: 'repeating',
    },
    {
        title: 'Item Reference',
        dataIndex: 'itemRef',
        key: 'itemRef',
        render:(text, record) => (
            <table className={'table'}>
                <thead>
                <tr>
                    <th>Item OID</th>
                    <th>Mandatory</th>
                    <th>Skip Condition</th>
                </tr>
                </thead>
                <tbody>
                {record?.itemRef.map(itemRef => (
                    <tr key={`${record.oid}_${itemRef.itemOID}`}>
                        <td>
                            {/**/}
                            <Anchor affix={false} showInkInFixed={false}><Link href={`#i-${itemRef.itemOID}`} title={<Tag color={'success'}>{itemRef.itemOID}</Tag>}/></Anchor>
                        </td>
                        <td>{itemRef.mandatory}</td>
                        <td>{itemRef.collectionExceptionConditionOID}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        )
    },

];

const itemColumns = [
    {
        title: 'OID',
        dataIndex: 'oid',
        key: 'oid',
        render:(text, record) => (
            <Tag id={`i-${record.oid}`} color={'geekblue'}>{record.oid}</Tag>
        )
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'DataType',
        dataIndex: 'dataType',
        key: 'dataType',
    },
    {
        title: 'ControlType',
        dataIndex: 'controlType',
        key: 'controlType',
    },
    {
        title: 'Length',
        dataIndex: 'length',
        key: 'length',
    },
    {
        title: 'Fraction Digits',
        dataIndex: 'fractionDigits',
        key: 'fractionDigits',
    },
    {
        title: 'CodeListRef',
        dataIndex: ['codeListRef', 'codeListOID'],
        key: 'codeListRef',
        render:(text, record) => (
            isEmpty(record?.codeListRef?.codeListOID) === false &&
            <Anchor affix={false} showInkInFixed={false}><Link href={`#cl-${record?.codeListRef?.codeListOID}`} title={<Tag color={'success'}>{record?.codeListRef?.codeListOID}</Tag>}/></Anchor>
        )
    },
    {
        title: 'ValueListRef',
        dataIndex: ['valueListRef', 'valueListOID'],
        key: 'valueListRef',
        render:(text, record) => (
            isEmpty(record?.valueListRef?.valueListOID) === false &&
            <Anchor affix={false} showInkInFixed={false}><Link href={`#cl-${record?.valueListRef?.valueListOID}`} title={<Tag color={'success'}>{record?.valueListRef?.valueListOID}</Tag>}/></Anchor>
        )
    },
];

const valueListColumns = [
    {
        title: 'OID',
        dataIndex: 'oid',
        key: 'oid',
        render:(text, record) => (
            <Tag id={`vl-${record.oid}`} color={'geekblue'}>{record.oid}</Tag>
        )
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Item Reference',
        dataIndex: 'itemRef',
        key: 'itemRef',
        render:(text, record) => (
            <table className={'table'}>
                <thead>
                <tr>
                    <th>Item OID</th>
                    <th>Mandatory</th>
                    <th>Skip Condition</th>
                </tr>
                </thead>
                <tbody>
                {record?.itemRef.map(itemRef => (
                    <tr key={`${record.oid}_}${itemRef.itemOID}`}>
                        <td><Anchor affix={false} showInkInFixed={false}><Link href={`#i-${itemRef.itemOID}`} title={<Tag color={'success'}>{itemRef.itemOID}</Tag>}/></Anchor></td>
                        <td>{itemRef.mandatory}</td>
                        <td>{itemRef.collectionExceptionConditionOID}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        )
    },
    // {
    //     title: 'DataType',
    //     dataIndex: 'dataType',
    //     key: 'dataType',
    // }
];

const { Link } = Anchor;
const DBSpecContainer = () => {
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);

    const codeListColumns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            render:(text, record) => (
                <Tag id={`cl-${record.oid}`} color={'geekblue'}>{record.oid}</Tag>
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'DataType',
            dataIndex: 'dataType',
            key: 'dataType',
        },
        {
            title: 'CodeListItem',
            dataIndex: 'codeListItem',
            key:'codeListItem',
            render:(text, record) => (
                <>

                    {
                        (record?.externalCodeList != null)?(
                            <table className={'table'}>
                                <thead>
                                <tr>
                                    <th>Dictionary</th>
                                    <th>Versions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        {record.externalCodeList?.dictionary}
                                    </td>
                                    <td>
                                        {metaDataStore.dictionaryMap[record.externalCodeList?.dictionary].find(e => e.id === parseInt(record.externalCodeList?.version))?.version||'None'}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        ):(
                            <table className={'table'}>
                                <thead>
                                <tr>
                                    <th>Coded Value</th>
                                    <th>Label</th>
                                </tr>
                                </thead>
                                <tbody>
                                {record?.codeListItem.map(item => (
                                    <tr key={`${record.oid}_${item.codedValue}`}>
                                        <td><Tag color={'success'}>{item.codedValue}</Tag></td>
                                        <td>{item.decode.translatedText[0].value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )
                    }
                </>
            )
        }
    ];

    return (
        <Row justify={'start'} algin={'top'} >
            <Col flex={'200px'}>
                <Anchor
                    // getContainer={() => document.getElementById("my-scroll-layout")}
                    showInkInFixed={true}
                    affix={true}
                >
                    <Link href="#event-groups" title={`Event Groups(${metaDataStore.study.metaDataVersion[0]?.studyEventGroupDef?.length})`} />
                    <Link href="#study-events" title={`Study Events(${metaDataStore.study.metaDataVersion[0]?.studyEventDef?.length})`} />

                    <Link href="#forms" title={`Forms(${metaDataStore.study.metaDataVersion[0]?.formDef?.length})`} />
                    <Link href="#item-groups" title={`Item Groups(${metaDataStore.study.metaDataVersion[0]?.itemGroupDef?.length})`} />
                    <Link href="#items" title={`Items(${metaDataStore.study.metaDataVersion[0]?.itemDef?.length})`} />
                    <Link href="#codelists" title={`CodeLists(${metaDataStore.study.metaDataVersion[0]?.codeList?.length})`} />
                    <Link href="#valuelists" title={`ValueLists(${metaDataStore.study.metaDataVersion[0]?.valueListDef?.length})`}/>
                </Anchor>
            </Col>
            <Col flex={'1'}>
                <Card>
                    <h5 id={'event-groups'}>Event Groups({metaDataStore.study.metaDataVersion[0]?.studyEventGroupDef?.length})</h5>
                        <Table dataSource={metaDataStore.study.metaDataVersion[0]?.studyEventGroupDef} pagination={false} bordered={true} size={'small'} rowKey={'oid'} columns={studyEventGroupColumns}/>
                </Card>
                <Card>
                    <h5 id={'study-events'}>Study Events({metaDataStore.study.metaDataVersion[0]?.studyEventDef?.length})</h5>
                        <Table dataSource={metaDataStore.study.metaDataVersion[0]?.studyEventDef} pagination={false} bordered={true} size={'small'} rowKey={'oid'} columns={studyEventColumns}/>
                </Card>

                <Card>
                    <h5 id={'forms'}>Forms({metaDataStore.study.metaDataVersion[0]?.formDef?.length})</h5>
                        <Table dataSource={metaDataStore.study.metaDataVersion[0]?.formDef} pagination={false} bordered={true} size={'small'} rowKey={'oid'} columns={formColumns}/>
                </Card>

                <Card>
                    <h5 id={'item-groups'}>Item Groups({metaDataStore.study.metaDataVersion[0]?.itemGroupDef?.length})</h5>
                        <Table dataSource={metaDataStore.study.metaDataVersion[0]?.itemGroupDef} pagination={false} bordered={true} size={'small'} rowKey={'oid'} columns={itemGroupColumns}/>
                </Card>

                <Card>
                    <h5 id={'items'}>Items({metaDataStore.study.metaDataVersion[0]?.itemDef?.length})</h5>
                        <Table dataSource={metaDataStore.study.metaDataVersion[0]?.itemDef} pagination={false} bordered={true} size={'small'} rowKey={'oid'} columns={itemColumns}/>
                </Card>

                <Card>
                    <h5 id={'codelists'}>Code Lists({metaDataStore.study.metaDataVersion[0]?.codeList?.length})</h5>
                        <Table dataSource={metaDataStore.study.metaDataVersion[0]?.codeList}  pagination={false} bordered={true} size={'small'} rowKey={'oid'} columns={codeListColumns}/>
                </Card>

                <Card>
                    <h5 id={'valuelists'}>Value Lists({metaDataStore.study.metaDataVersion[0]?.valueListDef?.length})</h5>
                        <Table dataSource={metaDataStore.study.metaDataVersion[0]?.valueListDef} pagination={false} bordered={true} size={'small'} rowKey={'oid'} columns={valueListColumns}/>
                </Card>
            </Col>

        </Row>
    )
}

export default DBSpecContainer;
