import {Helmet} from "react-helmet-async";
import React from "react";
import SponsorRoleEdit from "../../../../../components/common/builder/sponsor/SponsorRoleEdit";

const SponsorRoleEditPage = () => {

    return (
        <>
            <Helmet title={'BUILDER :: Role Edit'}/>
            <SponsorRoleEdit />
        </>
    )
}

export default SponsorRoleEditPage;