import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Card, Form, Input, Mentions} from 'antd'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import style from '../style.module.scss'
import queryString from "query-string";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {userActions} from "../../../../redux/module/user";
import {isEmpty} from "../../../../lib/StringUtils";
import {withTranslation} from "react-i18next";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {httpStatus} from "../../../../lib/createRequestSaga";

const Login = ({t, logo, version}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const query = queryString.parse(location.search);
    const [loading, setLoading] = useState(false);
    const [domains, setDomains] = useState(null);
    const user = useSelector(({user}) => user);

    const [form] = Form.useForm();
    const passwordInput = useRef(null);

    const onFinish = async (values) => {
        setLoading(true);

        try{
            const {data} = await axios({
                url: '/login',
                params: values,
                method: 'post',
            })

            if (data?.code === 500) { //로그인 실패 시 발생 코드
                navigate(`/login?error${!isEmpty(data.message)?`=${data.message}`:''}`, {replace:true});
            } else if (data?.code === 300) { //로그인 중복 시
                const result = await withReactContent(Swal).fire({
                    title: t('login.proceed.title'),
                    text: t('login.terminate.existing-connection.text'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn-danger',
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    closeOnConfirm: false,
                    closeOnCancel: true,
                })

                if (result.isConfirmed) {
                    const {data} = await axios({
                        url: '/login',
                        params: {...values, forceLogin: '1'},
                        method: 'post',
                    });

                    if (data.code === httpStatus.ok) { // 로그인 성공 시
                        dispatch(userActions.loadCurrentAccount());
                        navigate('/studies', {replace:true});
                    }
                }

            } else if(data.code === httpStatus.ok) { //로그인 성공 시
                dispatch(userActions.loadCurrentAccount());
                navigate('/studies', {replace:true});
            }
        } catch(e) {
            navigate('/500');
        }

        setLoading(false);
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed : ', errorInfo);
    }

    const errorTranslate = (error) => {
        if(error === 'Bad credentials') {
            return t('login.message.bad.credentials');
        } else if(error === 'CREDENTIAL_ERROR') {
            return t('login.message.credentials.expired')
        } else {
            return error
        }
    }

    const onChangeUsername = (value) => {
        if(!isEmpty(value) && value.endsWith('@')) {
            setDomains(null);
            (async () => {
                try {
                    const {status, data} = await axios.get(`/api/domains?username=${value}`);

                    if(status === httpStatus.ok) {
                        setDomains(data?.domains);
                    }
                } catch (e) {
                    errorHandle(e);
                }
            })()
        } else {
            value.replace(' ', '');
        }
    }

    const onEnterkeyIgnore = ({value}) => {
        const username = form.getFieldValue("username");
        form.setFieldsValue({username:username.replace('\n', '').trim()});
    }

    const domainOptions = (domains??[]).map(domain => ({
        value: domain,
        label: domain
    }));

    const onMentions = ({value}) => {
        const username = form.getFieldValue("username");
        if(value === "ANTD_SEARCHING") {
            form.setFieldsValue({username:username.replace(' ', '').trim().split('@')[0].concat('@')});
        } else if(!isEmpty(value)) {
            form.setFieldsValue({username:username.replace(' ', '').trim()});
            if(passwordInput.current) { //Mention Select 시, Password로 화면 전환
                setTimeout(() => {
                    passwordInput.current.focus()
                }, 10);
            }
        }
    }

    return (
        <div>
            <Card
                loading={user.loading}
                className={style.container}>
                <div className={'mb-3'}>
                {/*    <img src="/static/images/redpine/logo-redpine-240.png" />*/}
                </div>
                <div className="text-dark font-size-28 mb-2">{t('login.header.title')}</div>
                <div key={"alert-area"} className="mb-2">
                    {
                        Object.keys(query).map((q, index) => (
                            <div key={`${q}-${index}`}>
                                {
                                    (q === 'sessionExpired' || q === 'invalidSession') && (
                                        <div className='alert alert-danger'>
                                            {t('login.message.session.expired')}
                                        </div>
                                    )
                                }
                                {
                                    q === 'error' && (
                                        <div className='alert alert-danger'>
                                            {query.error !== null ? errorTranslate(query.error) : t('login.message.invalid.username.password')}
                                            {query.error === 'CREDENTIAL_ERROR' && <Link to={'/forgot-password'}>[<i className='fa fa-external-link'></i>{t('btn.reset.password')}]</Link>}
                                        </div>
                                    )
                                }
                                {
                                    q === 'logout' && (
                                        <div className='alert alert-success'>
                                            {t('login.message.logout')}
                                        </div>
                                    )
                                }
                                {
                                    q === 'duplicated' && (
                                        <div className='alert alert-success'>
                                            {t('login.message.logout.duplicated')}
                                        </div>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
                <Form form={form}
                      requiredMark={false}
                      layout="vertical"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      className="mb-4"
                >
                    <div className='form-group'>
                        <Form.Item key={"username"}
                                   name={"username"}
                                   label={t('login.username')}
                                   rules={[{required: true, message: t('input.required.message.username.email')}]}
                                   style={{marginBottom: "1rem"}}
                        >
                            <Mentions placeholder={t('input.placeholder.username.email')} loading={(domains===null)}
                                      autoFocus autoSize={{minRows:1, maxRows:1}} style={{whiteSpace:"nowrap"}}
                                      notFoundContent={<span><i className='fa fa-exclamation-circle'></i> {t('login.mention.not.found.username')}.</span>}
                                      onChange={(value) => {
                                          onEnterkeyIgnore(value);
                                          onChangeUsername(value);}}
                                      onSelect={onMentions}
                                      options={domainOptions} />
                            {/*<Input name='username' placeholder={t('input.placeholder.username.email')} />*/}
                        </Form.Item>
                    </div>
                    <div className='form-group'>
                        <Form.Item key={"passwrod"}
                                   name={"password"}
                                   label={t('login.password')}
                                   rules={[{required: true, message: t('input.required.message.password'),
                                   }]}
                        >
                            <Input.Password name='password' placeholder={t('input.placeholder.password')} ref={passwordInput} />
                        </Form.Item>
                    </div>
                    <Button
                        type="primary"
                        className="text-center w-100 btn btn-success"
                        htmlType="submit"
                        loading={loading}
                    >
                        <strong>{t('login.sign-in')}</strong>
                    </Button>
                </Form>
                <Link to="/forgot-password" className="vb__utils__link font-size-12">
                    {t('btn.find.password')}
                </Link>
            </Card>
        </div>
    )
}

export default withTranslation()(Login);