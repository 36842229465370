import {Helmet} from "react-helmet-async";
import React from "react";
import RoleEdit from "../../../../../components/common/builder/studies/RoleEdit";

const RoleEditPage = () => {

    return (
        <>
            <Helmet title={'BUILDER :: Role Edit'}/>
            <RoleEdit />
        </>
    )
}

export default RoleEditPage;