import {Helmet} from "react-helmet-async";
import FormListContainer from "../../../container/build-tool/container/FormListContainer";
import React from "react";
import ODMTabs from "../../../layouts/Tab/odm";

const FormListPage = () => {

    return (
        <ODMTabs menu={'forms'}>
            <Helmet title={'ODM Designer v2.0.0 :: Forms List'}/>
            <FormListContainer/>
        </ODMTabs>
    )
}

export default FormListPage;