import {isEmpty} from "../../../../../lib/StringUtils";
import {CodeListRefArea, Notice} from "../../../../../container/build-tool/StyleBox";
import DefinitionBtn from "../../../../../container/build-tool/content/DefinitionBtn";
import React from "react";
import {
    CodeList,
    descriptionText,
    QuestionText,
    translatedText
} from "../../../../../container/build-tool/content/FormComponents";
import classNames from "classnames";
import style from "../../../../../container/build-tool/content/style.module.scss";
import {getExtensionsLabel, unknownDateFormat} from "../../../../../lib/ODMUtils";
import {useTranslation} from "react-i18next";

const LibraryItemField = ({library, designMode, selectedLanguage, setDefOpen, setDefType,
                              setDefItem, item, repeating, itemGroupOID,
                              unitsItemOID = null, valueListOID = null}) => {

    const {t} = useTranslation();
    const isCodeList = item?.codeListRef != null && (!isEmpty(item.codeListRef?.codeListOID));
    const isValueList = !isCodeList && valueListOID != null;

    //ValueList일 때, RESULT 값인지 확인
    const isValueListResultItem = valueListOID != null && item.oid.endsWith("ORRES");

    const codeList = isCodeList ? library.codeList.get(item.codeListRef.codeListOID) : [];
    const valueList = isValueList ? library.valueList.get(valueListOID)||null : [];
    const controlType = item?.controlType;

    return (
        <>
            {
                ((isCodeList && (controlType === 'DROPDOWN' || controlType.startsWith("RADIO") || controlType.startsWith("CHECKBOXES") || controlType === "DICTIONARY")) &&
                    <>
                        {
                            designMode && (
                                <CodeListRefArea>
                                    <DefinitionBtn data={codeList}
                                                   defType={codeList.externalCodeList != null ? "externalCodeList" : "codeListItem"}
                                                   setDefVisible={setDefOpen} setDefType={setDefType}
                                                   setDefItem={setDefItem}/>
                                </CodeListRefArea>
                            )
                        }
                        {
                            <>
                                {/*CodeList Update 진행 후 decode의 translateText가 공백으로 받아져서 처리.*/}
                                <CodeList itemDef={item} codeList={codeList} selectedLanguage={selectedLanguage} designMode={designMode}/>
                                <div className={classNames('', {
                                    [style.descriptionRepeating]: repeating != 'NO'
                                })}>
                                    {descriptionText({description: item.description, selectedLanguage: selectedLanguage})}
                                </div>
                            </>
                        }
                    </>
                )
            }
            {
                //repeating STATIC인 itemGroup에서 ORRES형식의 값을 사용할 때,
                (repeating == 'STATIC' && isValueListResultItem) &&
                <>
                    {
                        valueList !== null? (
                            <>
                                {
                                    designMode && (
                                        <DefinitionBtn data={valueList} defType={"valueList"}
                                                       setDefVisible={setDefOpen} setDefType={setDefType}
                                                       setDefItem={setDefItem}/>
                                    )
                                }
                                {
                                    valueList.itemRef.map((i, index) => {
                                        const valueListItem = library.item.get(i?.itemOID);
                                        const isVLItemCodeList = valueListItem.codeListRef != null && (!isEmpty(valueListItem.codeListRef.codeListOID));;
                                        const VLItemCodeList = isVLItemCodeList ? library.codeList.get(valueListItem.codeListRef.codeListOID) : [];
                                        return (
                                            <div key={i?.itemOID}
                                                 style={{
                                                     minHeight: '45px',
                                                     borderBottom: '1px solid #eeeeee',
                                                     padding: '9px 0px 5px 0',
                                                     width: '100%'
                                                 }}>
                                                {
                                                    ((isVLItemCodeList && (valueListItem.controlType === 'DROPDOWN' ||
                                                            valueListItem.controlType.startsWith("RADIO") ||
                                                            valueListItem.controlType.startsWith("CHECKBOXES") ||
                                                            valueListItem.controlType === "DICTIONARY")) &&
                                                        <>
                                                            {
                                                                designMode && (
                                                                    <CodeListRefArea>
                                                                        <DefinitionBtn data={VLItemCodeList}
                                                                                       defType={VLItemCodeList.externalCodeList != null ? "externalCodeList" : "codeListItem"}
                                                                                       setDefVisible={setDefOpen} setDefType={setDefType}
                                                                                       setDefItem={setDefItem}/>
                                                                    </CodeListRefArea>
                                                                )
                                                            }
                                                            {
                                                                <>
                                                                    {/*CodeList Update 진행 후 decode의 translateText가 공백으로 받아져서 처리.*/}
                                                                    <CodeList itemDef={valueListItem} codeList={VLItemCodeList} selectedLanguage={selectedLanguage} designMode={designMode}/>
                                                                    <div className={classNames('', {
                                                                        [style.descriptionRepeating]: repeating != 'NO'
                                                                    })}>
                                                                        {descriptionText({description: item.description, selectedLanguage: selectedLanguage})}
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    ((valueListItem.controlType === 'INPUT' || valueListItem.controlType === 'TEXT' ||
                                                        valueListItem.controlType === 'DATEPICKER' || valueListItem.controlType === 'TIMEPICKER' ||
                                                        valueListItem.controlType === 'DATETIME' || valueListItem.controlType === 'CHECKBOX' ||
                                                        valueListItem.controlType === 'NUMBER'))
                                                    && (
                                                        <>
                                                            <div className={'input-group'}>
                                                                {(!isEmpty(valueListItem.prefix)) &&
                                                                    <span className={'text-muted mt-1 mr-2'}>{valueListItem.prefix}</span>
                                                                }
                                                                {(controlType === "FILE_UPLOAD") &&
                                                                    <div className={'input-group-prepend'}>
                                                                        <span className={'input-group-text'}><i className='fa fa-upload'></i></span>
                                                                    </div>
                                                                }
                                                                {(!isEmpty(i.methodOID) && designMode) &&
                                                                    <div className={'input-group-prepend'}>
                                                                        <span className={'input-group-text'}><i className={'fa fa-calculator'}></i></span>
                                                                    </div>
                                                                }
                                                                {library.item.get(i.itemOID).dataType !== 'BOOLEAN' &&
                                                                    <input type="text" readOnly={true} className="form-control" style={{fontSize: '0.8rem'}}
                                                                           placeholder={getDataType(valueListItem.dataType, isVLItemCodeList, valueListItem)}/>
                                                                }
                                                                {library.item.get(i.itemOID).dataType === 'BOOLEAN' &&
                                                                    <label className="vb__utils__control vb__utils__control__checkbox">
                                                                        <input type={'checkbox'} disabled={true}/>
                                                                        <span className="vb__utils__control__indicator"></span>
                                                                        {designMode === true ? <sup style={{color: 'red'}}>1</sup> : ''}
                                                                        {QuestionText({itemDef:valueListItem, selectedLanguage:selectedLanguage, designMode:true})}{/*Checkbox Label 출력을 위해서 designMode 항시 true 로 설정*/}
                                                                    </label>
                                                                }
                                                                {
                                                                    !isEmpty(i.unitsItemOID) &&
                                                                    <div className={'input-group-append'}>
                                                                        <select className={'form-control'} style={{fontSize: '0.8rem',width:'90px'}}>
                                                                            {designMode ?
                                                                                <>
                                                                                    <option>{i.unitsItemOID}</option>
                                                                                    {library.codeList.get(library.item.get(i.unitsItemOID).codeListRef.codeListOID).codeListItem.map((code, index) => (
                                                                                        <option key={`${i.unitsItemOID}-${index}`}>{code.codedValue} : {translatedText(code.decode.translatedText, selectedLanguage)}</option>
                                                                                    ))}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {library.codeList.get(library.item.get(i.unitsItemOID).codeListRef.codeListOID).codeListItem.map((code, index) => (
                                                                                        <option key={`${i.unitsItemOID}-${index}`}>{translatedText(code.decode.translatedText, selectedLanguage)}</option>
                                                                                    ))}
                                                                                </>
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                }
                                                                {(!isEmpty(valueListItem.postfix) && isEmpty(i.unitsItemOID)) &&
                                                                    <span className={'text-muted mt-1 ml-2'}>{valueListItem.postfix}</span>
                                                                }
                                                            </div>
                                                            <div>
                                                                {descriptionText({description:valueListItem.description, selectedLanguage:selectedLanguage, isWrap: false})}
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </>
                        ) : (
                            <Notice>
                                {t('lib.value.list.has.been.deleted')}
                            </Notice>
                        )
                    }
                </>
            }
            {
                (((controlType === 'INPUT' || controlType === 'TEXT' ||
                        controlType === 'DATEPICKER' || controlType === 'TIMEPICKER' ||
                        controlType === "FILE_UPLOAD" || controlType === "FILE_DOWNLOAD" ||
                        controlType === 'DATETIME' || controlType === 'CHECKBOX' ||
                        controlType === 'NUMBER') && isValueList === false)
                    &&
                    <>
                        <div className={'input-group'}>
                            {(!isEmpty(item.prefix)) &&
                                <span className={'text-muted mt-1 mr-2'}>{item.prefix}</span>
                            }
                            {item.dataType !== 'BOOLEAN' &&
                                <input type="text" readOnly={true} className="form-control" style={{fontSize: '0.8rem'}}
                                       placeholder={getDataType(item.dataType, isCodeList, item)}/>
                            }
                            {item.dataType === 'BOOLEAN' &&
                                <label className="vb__utils__control vb__utils__control__checkbox">
                                    <input type={'checkbox'} disabled={true}/>
                                    <span className="vb__utils__control__indicator"></span>
                                    {designMode === true ?
                                        <sup style={{color: 'red'}}>1</sup> : ''}
                                    {QuestionText({itemDef:item, selectedLanguage:selectedLanguage, designMode:true})}{/*Checkbox Label 출력을 위해서 designMode 항시 true 로 설정*/}
                                </label>
                            }
                            {
                                !isEmpty(unitsItemOID) &&
                                <div className={'input-group-append'}>
                                    <select className={'form-control'} style={{fontSize: '0.8rem',width:'90px'}}>
                                        {designMode ?
                                            <>
                                                <option>{unitsItemOID}</option>
                                                {library.codeList.get(library.item.get(unitsItemOID).codeListRef.codeListOID).codeListItem.map((code, index) => (
                                                    <option key={`${unitsItemOID}-${index}`}>{code.codedValue} : {translatedText(code.decode.translatedText, selectedLanguage)}</option>
                                                ))}
                                            </>
                                            :
                                            <>
                                                {library.codeList.get(library.item.get(unitsItemOID).codeListRef.codeListOID).codeListItem.map((code, index) => (
                                                    <option key={`${unitsItemOID}-${index}`}>{translatedText(code.decode.translatedText, selectedLanguage)}</option>
                                                ))}
                                            </>

                                        }
                                    </select>
                                </div>
                            }
                            {(!isEmpty(item.postfix) && isEmpty(unitsItemOID)) &&
                                <span className={'text-muted mt-1 ml-2'}>{item.postfix}</span>
                            }
                        </div>
                        <div className={classNames('', {
                            [style.descriptionRepeating]: repeating != 'NO'
                        })}>
                            {descriptionText({description: item.description, selectedLanguage: selectedLanguage})}
                        </div>
                    </>
                )
            }
            {
                (controlType === "TEXTAREA" &&
                    <>
                        <textarea className={'form-control'} rows={3} readOnly={true} style={{fontSize: '0.8rem', resize:'none'}}
                                  placeholder={getDataType(item.dataType, isCodeList, item)}></textarea>
                        <div>
                            {descriptionText({description: item.description, selectedLanguage: selectedLanguage})}
                        </div>
                    </>
                )
            }
            {
                (controlType === "LABEL" &&
                    <>
                        <span style={{fontSize: '0.8rem', resize:'none'}}>{item.labelText}</span>
                    </>
                )
            }
            {
                ((item?.valueListRef != null && item.valueListRef?.valueListOID != null && controlType.startsWith("CHECKBOXES")) &&
                    <>
                        {
                            designMode && (
                                <div className={'mb-2'}>
                                    <DefinitionBtn data={library.valueList.get(item.valueListRef.valueListOID)} defType={"valueList"}
                                                   setDefVisible={setDefOpen} setDefType={setDefType}
                                                   setDefItem={setDefItem}/>
                                </div>
                            )
                        }
                        {library.valueList.get(item.valueListRef.valueListOID).itemRef.map((i, index) => {
                            const valueListItem = library.item.get(i?.itemOID);
                            return (
                                <label key={index} className="vb__utils__control vb__utils__control__checkbox">
                                    <input type={'checkbox'} disabled={true}/>
                                    <span className="vb__utils__control__indicator"></span>
                                    {
                                        designMode === true &&
                                        <span>
                                            <DefinitionBtn data={valueListItem} index={index} defType={"item"}
                                                           setDefVisible={setDefOpen} setDefType={setDefType}
                                                           setDefItem={setDefItem}/>
                                        </span>
                                    }
                                    {QuestionText({itemDef:valueListItem, selectedLanguage:selectedLanguage, designMode:true})}{/*Checkbox Label 출력을 위해서 designMode 항시 true 로 설정*/}
                                </label>
                            )
                        })}
                    </>
                )
            }
            {
                //실험항목을 참조한 ValueList Field를 그릴 때 사용
                (isValueListResultItem === false && isValueList) && (
                    <>
                        {
                            valueList !== null? (
                                <>
                                    {
                                        designMode && (
                                            <DefinitionBtn data={valueList} defType={"valueList"}
                                                           setDefVisible={setDefOpen} setDefType={setDefType}
                                                           setDefItem={setDefItem}/>
                                        )
                                    }
                                    {
                                        valueList.itemRef.map((i, index) => {
                                            const valueListItem = library.item.get(i?.itemOID);
                                            return (
                                                <div key={index}
                                                     style={{
                                                         minHeight: valueListItem.description != null?'66px':'45px',
                                                         borderBottom: '1px solid #eeeeee',
                                                         padding: '9px 0px 5px 0',
                                                         width: '100%'
                                                     }}>
                                                    {
                                                        designMode &&
                                                        <DefinitionBtn data={valueListItem} index={index} defType={"item"}
                                                                       setDefVisible={setDefOpen} setDefType={setDefType}
                                                                       setDefItem={setDefItem}/>
                                                    }
                                                    <div className={style.valueListTextBlock}>{valueListItem.name}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            ) : (
                                <Notice>
                                    {t('lib.value.list.has.been.deleted')}
                                </Notice>
                            )
                        }
                    </>
                )
            }
        </>
    )

}

const getDataType = (dataType, isCodeList, item) => {

    if (["TEXT", "INTEGER", "FLOAT"].includes(dataType)) {
        let str = isCodeList ? `Length : ${item.dataType}` : `${item.dataType}`;
        str += `(`;

        if(item?.allowOnlyAlphabet && item?.onlyUpperCase && item?.allowSpace) {
            str += 'ONLY ALPHABET (WITH SPACE)';
            str += ' / ';
        } else if(item?.allowOnlyAlphabet && item?.allowSpace) {
            str += 'Only Alphabet (With Space)';
            str += ' / ';
        } else if(item?.allowOnlyAlphabet && item?.onlyUpperCase) {
            str += 'ONLY ALPHABET';
            str += ' / ';
        } else if(item?.allowOnlyAlphabet) {
            str += 'Only Alphabet';
            str += ' / ';
        }

        if (item.length != null) { // null, undefined Check
            str += `${item.length}`;
        }
        if (item.fractionDigits != null) { // null, undefined Check
            str += `.${item.fractionDigits}`;
        }

        str += ')';

        if(item.allowExtensions != null && item?.allowExtensions.length > 0) {
            str += " - " + getExtensionsLabel(item.allowExtensions);
        }

        if(item?.limitFileSize != null) {
            str += " [Limit : " + item.limitFileSize  + "MB]"
        }

        return str.endsWith("()") ? dataType : str;
    } else if(dataType === 'DATE') {
        return 'DATE (YYYY-MM-DD)';
    } else if(dataType === 'TIME') {
        return 'TIME (HH:MM)';
    } else if(dataType === 'PARTIAL_DATE') {
        return unknownDateFormat.find(df => df.value === item?.unknownDateFormat??'ALL_UK')?.format;
    } else if(dataType === 'PARTIAL_TIME') {
        return 'TIME (UK or HH:UK)';
    }

    return dataType;
}

export default LibraryItemField;
