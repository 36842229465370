import {Helmet} from "react-helmet-async";
import React from "react";
import SponsorPrecondition from "../../../../components/common/builder/sponsor/SponsorPrecondition";

const SponsorPreconditionPage = () => {

    return (
        <>
            <Helmet title={"BUILDER :: Precondition"} />
            <SponsorPrecondition />
        </>
    )
}

export default SponsorPreconditionPage;