import React, {useEffect, useState} from 'react';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import {useDispatch, useSelector} from "react-redux";
import {metadataActions} from "../../../redux/module/metadata";
import CodeListDef from "../modal/odm/CodeListDef";
import {descriptionCheck, orderNumberSetting} from "../../../lib/ODMUtils";
import Icon from "@mdi/react";
import {mdiOrderAlphabeticalAscending, mdiOrderNumericAscending} from "@mdi/js";
import {useParams} from "react-router-dom";

const CodeListContainer = ({defVisible, setDefVisible, defType, defItem, setDefItem}) => {

    //Redux
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);

    //공통
    const propName = 'codeList';
    const [initialValue, setInitialValue] = useState({});
    const [create, setCreate] = useState(true);
    const [selectedOID, setSelectedOID] = useState("");
    const [languages, setLanguages] = useState({});

    //컴포넌트에 따라 필요한 정보
    const [codeListDef, setCodeListDef] = useState(initialValue);
    const [dataTypes, setDataTypes] = useState([]);
    const [dataTypeIcons, setDataTypeIcons] = useState({});

    useEffect(() => {
        if (metaDataStore.loaded === true) {
            setInitialValue(metaDataStore.initialValue.codeList);
            setDataTypes(metaDataStore.clDataType);
            setDataTypeIcons({
                'INTEGER': <Icon path={mdiOrderNumericAscending} color={'#1d39c4'} size={0.75} />,
                'TEXT': <Icon path={mdiOrderAlphabeticalAscending} color={'#1d39c4'} size={0.75} />
            });
            setLanguages(metaDataStore.languages);
        }

        if(defItem !== null) { // UPDATE
            setCodeListDef(defItem);
            setSelectedOID(defItem.oid);
            setCreate(false);
        } else if(defItem === null) { // NEW

            //ExternalCodeList 인 경우, externalCodeList의 값을 설정함.
            if(defType === 'externalCodeList') {
                initialValue.externalCodeList = {};
            }

            setCodeListDef(initialValue);
            setSelectedOID("");
            setCreate(true);
        }

    }, [metaDataStore, defItem]);

    //데이터 등록/수정 함수
    const onFinish = (codeListDef) => {
        codeListDef.attr = propName;
        codeListDef.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
        orderNumberSetting(codeListDef?.codeListItem) //CodeListItem OrderNumber Setting - null이면 미처리
        descriptionCheck(codeListDef);

        if (create) {
            dispatch(metadataActions.create(codeListDef));
        } else {
            dispatch(metadataActions.update(codeListDef));
        }

        onCancel(); //Modal 화면 닫기.
    };

    const onCancel = () => {
        setDefItem(null);
        setDefVisible(false);
    }

    return (
        //selectedOID, metaDataStore, languages, codeListDef, itemType, setCodeListDef, onSubmitData, dataTypes, onCancel
        (selectedOID !== "" || create == true)  && (
            <CodeListDef selectedOID={selectedOID} metaDataStore={metaDataStore} codeListDef={codeListDef}
                         setCodeListDef={setCodeListDef} visible={defVisible} itemType={defType} dataTypeIcons={dataTypeIcons}
                         dataTypes={dataTypes} languages={languages} onFinish={onFinish} onCancel={onCancel}/>
        )
    );
}

export default CodeListContainer;
