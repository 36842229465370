import {useCallback, useContext, useEffect} from 'react';
import {UNSAFE_NavigationContext as NavigationContext, useNavigate} from 'react-router-dom';
import swal from 'sweetalert2';

function useConfirmExit(navigator, confirmExit, when = true) {
    useEffect(() => {
        if (!when) {
            return;
        }

        const push = navigator.push;

        navigator.push = (...args) => {
            const result = confirmExit(...args);
            if (result !== false) {
                push(...args);
            }
        };

        return () => {
            navigator.push = push;
        };
    }, [navigator, confirmExit, when]);
}

export function usePrompt( when = true,t, html = null) {
    html = html ?? `<h4>${t('message.are.you.sure.leave.page')}</h4>`
    const { navigator } = useContext(NavigationContext);
    const navigate = useNavigate();
    let result = false;

    useEffect(() => {
        if (when) {
            window.onbeforeunload = function () {
                return html;
            };
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [html, when]);


    const confirmExit = useCallback(({pathname}) => {

        if(!result) {
            swal.fire({
                title: '<h4>'+ t('message.there.are.unsaved.changes') +'</h4>',
                html: html,
                icon: 'question',
                showCancelButton:true,
            }).then(res => {
                if(res.isConfirmed) {
                    result = true;
                    navigate(pathname);
                }
            })
        }
        return result;
    }, [html]);


    useConfirmExit(navigator, confirmExit, when);
}


// import {useContext, useEffect, useCallback} from 'react';
// import { UNSAFE_NavigationContext as NavigationContext} from 'react-router-dom';
// import swal from 'sweetalert2';
//
// export function useBlocker(blocker, when = true) {
//     const {navigator} = useContext(NavigationContext);
//
//     useEffect(() => {
//         if(!when) return;
//
//         const unblock = navigator.block((tx) => {
//             const autoUnblockingTx = {
//                 ...tx,
//                 retry() {
//                     unblock();
//                     tx.retry();
//                 },
//             };
//             blocker(autoUnblockingTx);
//         });
//         return unblock;
//     }, [navigator, blocker, when]);
// }
//
// export function usePrompt(when = true, html = '<h4>이 페이지에서 나가시겠습니까?</h4>') {
//     const blocker = useCallback((tx) => {
//         swal.fire({
//             title: '<h4>저장되지 않은 변경 사항이 있습니다.</h4>',
//             html: html,
//             icon: 'question',
//             showCancelButton:true,
//         }).then(res => {
//             if(res.isConfirmed) tx.retry();
//         })
//     }, [html]);
//
//     useBlocker(blocker, when);
// }