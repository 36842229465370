import React, {useEffect, useState} from 'react';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import {useDispatch, useSelector} from "react-redux";
import ItemGroupDef from "../modal/odm/ItemGroupDef";
import {metadataActions} from "../../../redux/module/metadata";
import {descriptionCheck, headerTitleCheck, toMetaData} from "../../../lib/ODMUtils";
import {message} from "antd";
import {sweetAlert} from "../../../lib/BuilderUtils";
import {isEmpty} from "../../../lib/StringUtils";
import Icon from "@mdi/react";
import {mdiApplicationOutline, mdiTableLarge, mdiTableLargePlus} from "@mdi/js";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ItemGroupContainer = ({defVisible, setDefVisible, defItem, setDefItem, defOption, setDefOption, disabledRepeatingType = null}) => {

    //Redux
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const {t} = useTranslation();

    //공통
    const propName = 'itemGroupDef';
    const [initialValue, setInitialValue] = useState({});
    const [selectedOID, setSelectedOID] = useState("");

    const [create, setCreate] = useState(true);
    const [languages, setLanguages] = useState({});

    //컴포넌트에 따라 필요한 정보
    const [itemGroupDef, setItemGroupDef] = useState(initialValue);
    const [yesOrNo, setYesOrNo] = useState([]);
    const [itemGroupRepeatType, setItemGroupRepeatType] = useState([]);
    const [repeatTypeIcons, setRepeatTypeIcons] = useState({});
    const [metaData, setMetaData] = useState(null);

    useEffect(() => {
        if (metaDataStore.loaded === true) {
            setInitialValue(metaDataStore.initialValue.itemGroup);
            setYesOrNo(metaDataStore.yesOrNo);
            setItemGroupRepeatType(metaDataStore.itemGroupRepeatType);
            setRepeatTypeIcons({
                'SIMPLE':<Icon path={mdiTableLargePlus} color={'#1d39c4'} size={0.75}/>,
                'STATIC':<Icon path={mdiTableLarge} color={'#1d39c4'} size={0.75}/>,
                'NO': <Icon path={mdiApplicationOutline} color={'#1d39c4'} size={0.75}/>
            });
            setLanguages(metaDataStore.languages);

            const meta = toMetaData(metaDataStore.study);
            setMetaData(meta);
        }

        //Data 수정 시 작업
        if (defItem !== null) { //Update
            setSelectedOID(defItem.oid);
            setItemGroupDef(defItem);
            setCreate(false);
        } else if (defItem === null) { //NEW
            setSelectedOID("");
            setItemGroupDef(initialValue);
            setCreate(true);
        }

    }, [metaDataStore, defItem]);

    //데이터 등록/수정 함수
    const onFinish = (newItemGroupDef) => {
        descriptionCheck(newItemGroupDef);
        headerTitleCheck(newItemGroupDef);

        if(itemGroupDef?.itemRef != null) {
            //기존에 갖고있는 itemRef 정보를 저장함.
            newItemGroupDef = {...newItemGroupDef, itemRef:itemGroupDef.itemRef};

            if(newItemGroupDef.repeating !== 'STATIC') {
                if(itemGroupDef.itemRef.some(item => (item.valueListOID != null))) {
                    sweetAlert("ItemGroup Save ERROR.", 0, 'error',
                        <h5>{t('message.currently.referenced.item.referring.valueList')}</h5>);
                    return;
                }
            }
        }

        //description 정보가 존재하지 않으면, description 정보 삭제
        descriptionCheck(newItemGroupDef);

        newItemGroupDef.attr = propName;
        newItemGroupDef.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
        if (create) {
            dispatch(metadataActions.create(newItemGroupDef));
        } else {
            //const idx = metaDataVersions[selectedVal.verIndex][propName].findIndex(o => o.oid === itemGroupDef.oid);
            dispatch(metadataActions.update(newItemGroupDef));
        }


        onCancel(); //Drawer 화면 사라짐.
        //현재 저장된 ItemGroupDef의 oid값 가져오기.
        setDefOption({
            ...defOption,
            itemGroupOID: newItemGroupDef?.oid,
            done: true
        })
    };


    const onCancel = () => {
        setDefItem(null);
        setDefVisible(false);
        setDefOption(null);
    }

    return (
        (selectedOID !== "" || create == true) && (
            <ItemGroupDef
                metaData={metaData}
                selectedOID={selectedOID}
                languages={languages}
                itemGroupDef={itemGroupDef}
                setItemGroupDef={setItemGroupDef}
                visible={defVisible}
                yesOrNo={yesOrNo}
                itemGroupRepeatType={itemGroupRepeatType}
                repeatTypeIcons={repeatTypeIcons}
                disabledRepeatingType={disabledRepeatingType}
                onFinish={onFinish}
                onCancel={onCancel}
            />
        )
    );
}

export default ItemGroupContainer;
