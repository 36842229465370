import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import cookie from 'react-cookies';

import translationEn from './message.en'
import translationKo from './message.ko'
import {isEmpty} from "../../lib/StringUtils";

const LANGUAGE_COOKIE_NAME = 'org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE';

const resource =  {
    en: {
        translation: translationEn
    },
    ko: {
        translation: translationKo
    }
};

// i18n
//     // load translation using http -> see /public/locales
//     // learn more: https://github.com/i18next/i18next-http-backend
//     .use(Backend)
//     // detect user language
//     // learn more: https://github.com/i18next/i18next-browser-languageDetector
//     .use(LanguageDetector)
//     // pass the i18n instance to the react-i18next components.
//     // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
//     .use(initReactI18next)
//     // init i18next
//     // for all options read: https://www.i18next.com/overview/configuration-options
//     .init({
//         fallbackLng: 'en',
//         debug: true,
//         resources: resource,
//         interpolation: {
//             escapeValue: false, // not needed for react as it escapes by default
//         },
//         // special options for react-i18next
//         // learn more: https://react.i18next.com/components/i18next-instance
//         react: {
//             wait: true,
//         },
//     });
//
// export default i18n;

i18n
    .use(initReactI18next)  // passes i18n down to react-i18next
    .init({
        resources: resource,
        lng: isEmpty(cookie.load(LANGUAGE_COOKIE_NAME))?"ko":cookie.load(LANGUAGE_COOKIE_NAME),
        fallbackLng: 'ko',
        // ns: ['translation'],
        // defaultNS: "translation",
        debug: true,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
