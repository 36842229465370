import React, {useEffect, useState} from 'react';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Col, Form, Input, InputNumber, List, Result, Row, Select, Space, Switch, Table, Tag} from "antd";
import {metadataActions} from "../../redux/module/metadata";
import _ from "lodash";
import {isEnvProduction, toMetaData} from "../../lib/ODMUtils";
import {isEmpty} from "../../lib/StringUtils";
import {useParams} from "react-router-dom";
import DefaultLoading from "../../components/common/DefaultLoading";
import {useTranslation} from "react-i18next";

// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 16 },
// };

const timingOptions = [
    {value:'D', label:'Day'},
    {value:'W', label:'Week'},
    {value:'M', label:'Month'},
];

const prefix = 'VW.';
const VisitWindowContainer = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector(({metaDataStore}) => (metaDataStore));
    const isProd = isEnvProduction(metaDataStore);
    const metaData = toMetaData(metaDataStore.study);
    const {t} = useTranslation();
    //공통
    const [studyTiming, setStudyTiming] = useState(metaDataStore.study.metaDataVersion[0]?.studyTiming);
    const [equal, setEqual] = useState(false);
    const [studyEvents, setStudyEvents] = useState([]);
    const [baselineVisitOID, setBaselineVisitOID] = useState(metaDataStore.study.metaDataVersion[0]?.studyTiming?.baselineVisitOID);
    const [baselineIdx, setBaselineIdx] = useState(metaDataStore.study.metaDataVersion[0]?.studyTiming?.durationTimingConstraint?.findIndex(v => v.structuralElementOID == baselineVisitOID));
    //컴포넌트에 따라 필요한 정보
    useEffect(() => {
        const studyEventOIDs = getStudyEventOIDs(false);
        //{console.log({value:oid, label:});}
        setStudyEvents(studyEventOIDs.map((oid, idx) => {
            return {
                value:oid,
                index:idx,
                label:metaData.studyEvent.get(oid).name
            }
        }));

        if(studyTiming == null) {
            const studyTimingDef = {oid:'VW001', name:'Visit Window'};
            studyTimingDef.systemQuery = false;
            studyTimingDef.entryLock = false;

            const durationTimingConstraint = [];
            const studyEvent = metaDataStore.study.metaDataVersion[0].studyEventDef.filter(v => v.type === 'SCHEDULED').sort((a,b) => a.visitNum - b.visitNum);
            studyEvent.forEach(v => {
                durationTimingConstraint.push({
                    oid:"VW." + v.oid,
                    name:v.name,
                    structuralElementOID:v.oid,
                });

                // console.log(durationTimingConstraint);
            });

            studyTimingDef.durationTimingConstraint = durationTimingConstraint;
            setStudyTiming(studyTimingDef);
        } else {
            const visitOIDs = studyTiming.durationTimingConstraint.map(s => `${s.oid}`);
            const studyEventOIDs = getStudyEventOIDs();
            // const studyEventOIDs = metaDataStore.study.metaDataVersion[0].studyEventDef.filter(v => v.type === 'SCHEDULED').map(v => `${prefix}${v.oid}`);
            //
            const eq = _.isEqualWith(visitOIDs, studyEventOIDs);
            // console.log('visitOIDs =>', visitOIDs);
            // console.log('studyEventOIDs =>', studyEventOIDs);
            // console.log('isEqual =>', eq);

            setEqual(eq);
        }
    }, []);

    useEffect(() => {
        // console.log('studyTiming => ', studyTiming);
        form.setFieldsValue(studyTiming);
    }, [studyTiming]);

    // console.log(studyTiming);

    const reloadEvents = () => {
        const studyEventOIDs = getStudyEventOIDs(false);
        const eventMap = new Map();
        studyTiming.durationTimingConstraint.forEach(t => {
           eventMap.set(t.oid, t);
        });

        const durationTimingConstraint = [];
        studyEventOIDs.forEach(oid => {
            const eventOID = `${prefix}${oid}`;

            if(eventMap.get(eventOID) != null) {
                durationTimingConstraint.push(eventMap.get(eventOID));
            } else {
                const studyEvent = metaData.studyEvent.get(oid);
                durationTimingConstraint.push({
                    oid: `${prefix}${oid}`,
                    name: studyEvent.name,
                    structuralElementOID:oid,
                })
            }
        });

        setStudyTiming({...studyTiming, durationTimingConstraint});
        setEqual(true);

        // console.log(eventMap);
    }

    const getStudyEventOIDs = (appendPrefix = true) => {
        let studyEventOIDs = [];

        metaDataStore.study.metaDataVersion[0].protocol.studyEventGroupRef.forEach(g => {
            if(metaData.studyEventGroup.get(g.studyEventGroupOID).repeating !== 'YES') {
                let oids = metaData.studyEventGroup.get(g.studyEventGroupOID).studyEventRef
                    .filter(s => metaData.studyEvent.get(s.studyEventOID)?.repeating !== 'YES')
                    .filter(s => metaData.studyEvent.get(s.studyEventOID)?.type === 'SCHEDULED')
                    .map(s => appendPrefix ? `${prefix}${s.studyEventOID}` : s.studyEventOID);
                if(oids.length > 0) {
                    studyEventOIDs = studyEventOIDs.concat(oids);
                }
            }
        });

        return studyEventOIDs;
    }

    const onChangeBaselineVisit = (oid, value) => {
        setBaselineVisitOID(oid);
        setBaselineIdx(value.index);
    }

    //데이터 등록/수정 함수
    const onSubmitData = (studyTiming) => {
        studyTiming.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
        dispatch(metadataActions.updateStudyTiming(studyTiming));
    };

    return (
        <>
            {
                (!isProd && equal == false && studyTiming != null) &&
                <Result
                    status="warning"
                    title={t('column.event.order.changed.pls.reset')}
                    extra={
                        <Button type="primary" key="console" onClick={reloadEvents}>
                            {t('btn.text.reset')}
                        </Button>
                    }
                />
            }
                <Form layout={"inline"} form={form} name="control-hooks" onFinish={onSubmitData} size={'small'} disabled={!equal}>
                    <Form.Item className={'mb-0'} name={"oid"} label="OID" rules={[{ required: true}]} hidden={true}>
                        <Input readOnly={true}/>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name={"name"} label="Name" rules={[{ required: true}]} hidden={true}>
                        <Input readOnly={true}/>
                    </Form.Item>
                    <div className={'row'}>
                        <div  className={'col-12 mb-1'}>
                            <Form.Item valuePropName={"checked"} className={'mb-0'} name={"systemQuery"}
                                       label="System Query" rules={[{required: true}]}>
                                <Switch disabled={isProd || !equal} />
                            </Form.Item>
                        </div>
                        <div  className={'col-12 mb-1'}>
                            <Form.Item valuePropName={"checked"} className={'mb-0'} name={"entryLock"}
                                       label="Entry Lock" rules={[{required: true}]}>
                                <Switch disabled={isProd || !equal} />
                            </Form.Item>
                        </div>
                        <div  className={'col-12 mb-1'}>
                            <Form.Item name={"baselineVisitOID"} rules={[{ required: true, message:t('message.pls.specify.baseline.visit')}]} label={'Baseline Visit'}>
                                {
                                    isProd?(
                                        <Tag>
                                            <span className={'font-size-12 font-weight-bold'}>
                                                {isEmpty(studyTiming?.baselineVisitOID)?'N/A':`[${studyTiming?.baselineVisitOID}] - ${studyEvents.find(se => se.value === studyTiming?.baselineVisitOID)?.label}`}
                                            </span>
                                        </Tag>
                                    ):(
                                        <Select options={studyEvents} style={{width:'90px'}} onChange={onChangeBaselineVisit} />
                                    )
                                }
                            </Form.Item>
                        </div>
                    </div>

                    <table className={'table table-bordered table-hover text-center'}>
                        <thead className={'table-light'}>
                            <tr>
                                <th>Visit</th>
                                <th>Pre-Window</th>
                                <th>Target</th>
                                <th>Post-Window</th>
                            </tr>
                        </thead>
                        <tbody>
                        {studyTiming != null && studyTiming?.durationTimingConstraint.map((duration, index) => (
                        <tr>
                            <td className={'font-weight-bolder'}>
                                <Form.Item className={'mb-0'} name={["durationTimingConstraint", index, "oid"]} label="OID" rules={[{ required: true}]} hidden={true}>
                                    <Input readOnly={true}/>
                                </Form.Item>
                                <Form.Item className={'mb-0'} name={["durationTimingConstraint", index, "name"]} label="Name" rules={[{ required: false}]} hidden={true}>
                                    <Input readOnly={true}/>
                                </Form.Item>
                                <Form.Item className={'mb-0'} name={["durationTimingConstraint", index, "structuralElementOID"]} label="structuralElementOID" rules={[{ required: false}]} hidden={true}>
                                    <Input readOnly={true}/>
                                </Form.Item>

                                {`${prefix}${baselineVisitOID}` === form.getFieldValue(["durationTimingConstraint", index, "oid"]) &&
                                    <Tag color={'blue'} className={'ml-1'}>Baseline</Tag>
                                }
                                {studyTiming.durationTimingConstraint[index].name}
                            </td>
                            <td>
                                <Form.Item>
                                    <Space.Compact>
                                        <Form.Item name={["durationTimingConstraint", index, "preWindow"]} rules={[{ required: true, message:''}]}>
                                            <InputNumber readOnly={isProd} min={0}/>
                                        </Form.Item>
                                        <Form.Item name={["durationTimingConstraint", index, "preWindowUnit"]} rules={[{ required: true, message:''}]}>
                                            {
                                                isProd?(
                                                    <Tag>
                                                        <span className={'font-size-12 font-weight-bold'}>
                                                            {timingOptions?.find(to => to.value === studyTiming?.durationTimingConstraint[index]?.preWindowUnit)?.label||'N/A'}
                                                        </span>
                                                    </Tag>
                                                ):(
                                                    <Select options={timingOptions} style={{width:'90px'}} />
                                                )
                                            }
                                        </Form.Item>
                                    </Space.Compact>
                                </Form.Item>
                            </td>
                            <td>
                                <Form.Item>
                                    <Space.Compact>
                                        <Form.Item name={["durationTimingConstraint", index, "target"]} rules={[{ required: true, message:''}]}>
                                            <InputNumber readOnly={isProd}
                                                         min={baselineIdx > index ? Number.MIN_SAFE_INTEGER : 0}
                                                         max={baselineIdx < index ? Number.MAX_SAFE_INTEGER : 0} />
                                        </Form.Item>
                                        <Form.Item name={["durationTimingConstraint", index, "targetUnit"]} rules={[{ required: true, message:''}]}>
                                            {
                                                isProd?(
                                                    <Tag>
                                                        <span className={'font-size-12 font-weight-bold'}>
                                                            {timingOptions?.find(to => to.value === studyTiming?.durationTimingConstraint[index]?.targetUnit)?.label||'N/A'}
                                                        </span>
                                                    </Tag>
                                                ):(
                                                    <Select options={timingOptions} style={{width:'90px'}} />
                                                )
                                            }
                                        </Form.Item>
                                    </Space.Compact>
                                </Form.Item>
                            </td>
                            <td>
                                <Form.Item>
                                    <Space.Compact>
                                        <Form.Item name={["durationTimingConstraint", index, "postWindow"]} rules={[{ required: true, message:''}]}>
                                            <InputNumber readOnly={isProd} min={0}/>
                                        </Form.Item>
                                        <Form.Item name={["durationTimingConstraint", index, "postWindowUnit"]} rules={[{ required: true, message:''}]}>
                                            {
                                                isProd?(
                                                    <Tag>
                                                        <span className={'font-size-12 font-weight-bold'}>
                                                            {timingOptions?.find(to => to.value === studyTiming?.durationTimingConstraint[index]?.postWindowUnit)?.label||'N/A'}
                                                        </span>
                                                    </Tag>
                                                ):(
                                                    <Select options={timingOptions} style={{width:'90px'}} />
                                                )
                                            }
                                        </Form.Item>
                                    </Space.Compact>
                                </Form.Item>
                            </td>
                        </tr>
                        ))}
                        </tbody>
                        {
                            !isProd &&
                            <tfoot style={{display: equal == false ? 'none' : ''}}>
                            <tr>
                                <td colSpan={4} className={'text-right'}>
                                    <Button type={"primary"} size={"middle"} htmlType={"submit"}>{t('btn.text.save')}</Button>
                                </td>
                            </tr>
                            </tfoot>
                        }
                    </table>
                </Form>
        </>
    );
}

export default VisitWindowContainer;
