import React, {useEffect} from "react";
import {Card, Col, Form, Modal, Row} from "antd";
import ModalFooter from "../ModalFooter";
import DescriptionOrQuestion from "../../../../components/common/odm/ant-form/DescriptionOrQuestion";
import FormInputOID from "../../../../components/common/odm/ant-form/FormInputOID";
import FormInputName from "../../../../components/common/odm/ant-form/FormInputName";
import FormSelectYesOrNo from "../../../../components/common/odm/ant-form/FormSelectYesOrNo";
import ModalTitle from "../component/ModalTitle";

const StudyEventGroupDef = ({ selectedOID, visible, studyEventGroupDef, setStudyEventGroupDef, languages, onFinish, onCancel, yesOrNo}) => {

    const [form] = Form.useForm();

    useEffect(() => {
        if(studyEventGroupDef !== null) {
            form.setFieldsValue(studyEventGroupDef);
        } else {
            form.resetFields();
        }
    }, [studyEventGroupDef])

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    return (
        <Modal
            forceRender={true}
            wrapClassName={'odm-modal'}
            title={<ModalTitle iconType={'studyEventGroupDef'}>Event Group</ModalTitle>}
            width={800}
            onCancel={onCancel}
            open={visible}
            destroyOnClose={true}
            styles={{body:{overflowY: 'auto', height: 'calc(100vh - 20rem)'}}}
            footer={<ModalFooter onCancel={onCancel} form={form}/>}
        >
            <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
                <Card title={'Attributes'} type={'inner'} className={'mb-2'}>
                    <Row gutter={[20, 0]}>
                        <Col span={12}>
                            <FormInputOID selectedOID={selectedOID} form={form}/>
                        </Col>
                        <Col span={12}>
                            <FormInputName name={studyEventGroupDef?.name} defType={'studyEventGroupDef'} form={form} />
                        </Col>
                        <Col span={12}>
                            <FormSelectYesOrNo label={'Repeating'} name={'repeating'} required={true} yesOrNo={yesOrNo} value={studyEventGroupDef?.repeating} />
                        </Col>
                        <Col span={12} hidden={true}>
                            <FormSelectYesOrNo label={'Epoch'} name={'epochOID'} required={false} yesOrNo={[]} />
                        </Col>
                    </Row>
                </Card>
                <Card title={'Description'} type={'inner'} className={'mb-2'}>
                    <DescriptionOrQuestion languages={languages} name={'description'} form={form}/>
                </Card>
            </Form>
        </Modal>
    )
}
export default StudyEventGroupDef;
