import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    descriptionCheck,
    isEnvProduction,
    mandatorySetting,
    orderNumberSetting,
    toMetaData
} from "../../../lib/ODMUtils";
import ValueListDef from "../modal/odm/ValueListDef";
import {metadataActions} from "../../../redux/module/metadata";
import _ from 'lodash';
import {isEmpty} from "../../../lib/StringUtils";
import {useParams} from "react-router-dom";

const ValueListContainer = ({defVisible, setDefVisible, defType, defItem, setDefItem}) => {
    //Redux
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const metaData = toMetaData(metaDataStore.study);

    //공통
    const propName = 'valueListDef';
    const [initialValue, setInitialValue] = useState({});
    const [selectedOID, setSelectedOID] = useState("");

    const [create, setCreate] = useState(false);
    const [languages, setLanguages] = useState({});

    //컴포넌트에 따라 필요한 정보
    const [valueListDef, setValueListDef] = useState(initialValue);
    const isSdtmct = isEmpty(metaDataStore.studyInfo.sdtmct) == false;

    useEffect(() => {
        if (metaDataStore.loaded === true) {
            setInitialValue(metaDataStore.initialValue.item);
            setLanguages(metaDataStore.languages);
        }

        if (defItem !== null) { //Update
            setSelectedOID(_.cloneDeep(defItem.oid));
            setValueListDef(_.cloneDeep(defItem));
            setCreate(false);
        } else if (defItem === null) { //New
            setValueListDef(initialValue);
            setSelectedOID("");
            setCreate(true);
        }

    }, [metaDataStore, defItem]);

    //데이터 등록/수정 함수
    const onFinish = (valueListDef) => {
        valueListDef.attr = propName;
        valueListDef.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929

        mandatorySetting(valueListDef.itemRef); //true or false를 Yes or No로 변경
        orderNumberSetting(valueListDef.itemRef); //OrderNumber 정렬순으로 부여 (index)
        descriptionCheck(valueListDef);

        if (create) {
            dispatch(metadataActions.create(valueListDef));
        } else {
            //const idx = metaDataVersions[selectedVal.verIndex][propName].findIndex(o => o.oid === itemGroupDef.oid);
            dispatch(metadataActions.update(valueListDef));
        }

        onCancel(); //Drawer 화면 사라짐.
    };

    const onCancel = () => {
        setDefItem(null);
        setDefVisible(false);
    }

    return (
        (selectedOID !== "" || create == true) && (
            <ValueListDef selectedOID={selectedOID} languages={languages} valueListDef={valueListDef}
                          setValueListDef={setValueListDef} visible={defVisible} metaData={metaData} isProd={isProd}
                          isSdtmct={isSdtmct} onFinish={onFinish} onCancel={onCancel} />
        )
    )
}

export default ValueListContainer;