import {Button, message, Popover, Select, Space, Tag} from "antd";
import _ from "lodash";
import {isEmpty} from "../../../../lib/StringUtils";
import {useRef} from "react";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../../lib/ODMUtils";

const MethodBtn = ({metaData, data, itemGroupOID, formDef, setFormDef, itemRefMap, setItemRefMap, index, defType, visible, setVisible}) => {
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const methodDefs = [...metaData.editCheck.values()].filter(ecs => ecs.action.type === 'METHOD');
    const displayDef = methodDefs.find(def => def?.oid === data?.methodOID);

    const onChangeMethod = (value) => {
        const methodOID = value;
        const cloneItemRefMap = _.cloneDeep(itemRefMap);
        if(isEmpty(methodOID)) {
            delete cloneItemRefMap.get(itemGroupOID)[index].methodOID;
        } else {
            cloneItemRefMap.get(itemGroupOID)[index].methodOID = methodOID;
        }
        setItemRefMap(cloneItemRefMap);
    }

    const methodOptions = [
        {value: '', label: '...'},
        ...methodDefs.map(ecs => ({
            key: `CON_${itemGroupOID}_${index}_${ecs.oid}`,
            value: ecs.oid,
            label: `${ecs.oid} - ${ecs.name}`
        }))
    ];

    return (
        <>
            <Popover placement={'top'}
                     title={'Method'}
                     trigger={'click'}
                     open={visible}
                     onOpenChange={() => setVisible(!visible)}
                     content={(
                         <Space>
                             {
                                 isProd?(
                                     <Tag>
                                         <span className={'font-weight-bold text-danger'}>
                                             {displayDef!=null?`${displayDef?.oid} - ${displayDef?.name}`:`NONE`}
                                         </span>
                                     </Tag>
                                 ) : (
                                     <Select showSearch={true} placeholder={'Method'} style={{width: 450}}
                                             defaultValue={data?.methodOID} onChange={onChangeMethod}
                                             onBlur={() => setVisible(false)} options={methodOptions} />
                                 )
                             }
                         </Space>
                     )}>
                <Button size={'small'} onClick={() => setVisible(true)}>
                    <i className='fa fa-calculator'></i>
                </Button>
            </Popover>
        </>
    )

}

export default MethodBtn;
