/**
 * 유저에게 권한이 있는지 확인
 * @param anyAuthority
 * @returns {*}
 */
import store from "../store/configureStore";

export const hasAnyAuthority = (authorities, ...anyAuthority) => {
    if(authorities instanceof Array) {
        return authorities.some(auth => anyAuthority.includes(auth));
    }
    return false;
}

/**
 *
 * @returns {*}
 */
const {user} = store.getState();
export const isBuilderAccessAuthorities = (authorities) => {
    if(authorities instanceof Array) {
        return authorities.some(auth => user?.builderAccessAuthorities.includes(auth));
    }
    return true;
}

export const isManagerAuthority = (authorities) => {
    if (authorities instanceof Array) {
        return authorities.some(auth => user?.managerAuthority.includes(auth));
    }
}