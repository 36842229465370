import React from "react";
import {Helmet} from "react-helmet-async";
import SponsorList from "../../../components/common/builder/sponsor/SponsorList";

const SponsorPage = () => {

    return (
        <>
            <Helmet title={"BUILDER :: Sponsor"} />
            <SponsorList />
        </>
    )
}

export default SponsorPage;