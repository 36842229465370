import {Helmet} from "react-helmet-async";
import ODMTabs from "../../../layouts/Tab/odm";
import React from "react";
import IndependentEvaluationContainer from "../../../container/odm/IndependentEvaluationContainer";

const IndependentEvaluationPage = () => {
    return (
        <ODMTabs menu={'independent-evaluation'}>
            <Helmet title={`ODM Designer v2.0.0 :: Independent Evaluation`}/>
            <IndependentEvaluationContainer />
        </ODMTabs>
    )
}

export default IndependentEvaluationPage;