import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ScheduleHeader from "./ScheduleHeader";
import ScheduleBody from "./ScheduleBody";
import {Droppable} from "react-beautiful-dnd";
import {Badge, Button, Input} from "antd";
import {
    descriptionCheck,
    getScheduleMapByProtocol,
    isEnvProduction,
    isExcludeOID,
    toMetaData
} from "../../../lib/ODMUtils";
import StudyEventDef from "../../../container/build-tool/modal/odm/StudyEventDef";
import {metadataActions} from "../../../redux/module/metadata";
import {DefContent, DefContentWrapper, DefControlBlock, DefHeadTitle} from "../../../container/build-tool/StyleBox";
import ScheduleDefDetail from "./ScheduleDefDetail";
import _ from 'lodash';
import Swal from "sweetalert2";
import {sweetAlert} from "../../../lib/BuilderUtils";
import ScheduleFooter from "./ScheduleFooter";
import style from "./style.module.scss";
import {WarningOutlined} from "@ant-design/icons";
import StudyEventGroupDef from "../../../container/build-tool/modal/odm/StudyEventGroupDef";
import NormalLoading from "../NormalLoading";
import {useParams} from "react-router-dom";
import {Table} from "reactstrap";
import {isEmpty} from "../../../lib/StringUtils";
import {useTranslation} from "react-i18next";

const ScheduleDefList = ({defType, defTitle, refType,
                             protocol, setProtocol,
                             defList, setDefList,
                             formList, setFormList,
                             toFormList, setShouldConfirm}) => {

    //Redux Data
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const [metaDataVersion, setMetaDataVersion] = useState(metaDataStore.study.metaDataVersion[0]);
    const [metaData, setMetaData] = useState(toMetaData(metaDataStore.study));

    //PreventLeave - 새로고침 시, 화면 페이지 벗어나기 여부 설정
    // const {enablePrevent, disablePrevent} = usePreventLeave();

    //Common / visible
    const [create, setCreate] = useState(true);
    const [defFilteredList, setDefFilteredList] = useState(null);
    const [studyEventVisible, setStudyEventVisible] = useState(false);
    const [studyEventGroupVisible, setStudyEventGroupVisible] = useState(false);

    //StudyEventDef
    const [selectedOID, setSelectedOID] = useState("");
    const [studyEventDef, setStudyEventDef] = useState(metaDataStore.initialValue.studyEvent);
    const [studyEventGroupDef, setStudyEventGroupDef] = useState(metaDataStore.initialValue.studyEventGroup);

    //FormDef
    const [formDefs, setFormDefs] = useState();

    const [curStudyEventCnt, setCurStudyEventCnt] = useState(0);

    const {t} = useTranslation();

    //Study Event - Study Event Group
    const languages = metaDataStore.languages;
    const yesOrNo = metaDataStore.yesOrNo;
    const eventTypes = metaDataStore.eventType;

    useEffect(() => {
        if (metaDataStore.loaded) {
            //When loaded, action
            setMetaDataVersion(metaDataStore.study.metaDataVersion[0]);
            setMetaData(toMetaData(metaDataStore.study));
        }

    }, [metaDataStore]);

    useEffect(() => {
        if (metaDataVersion) {
            if(defType === 'protocol') {
                const cloneProtocol = _.cloneDeep(metaDataVersion.protocol);
                setProtocol(cloneProtocol);
                toFormList(cloneProtocol);
            } else {
                //defList 초기 Setting 동작
                if (defList === null) {
                    switch (defType) {
                        case 'studyEventDef':
                            const studyEvent = _.cloneDeep(metaDataVersion.studyEventDef);
                            setDefList(studyEvent);
                            break;
                        case 'studyEventGroupDef':
                            const studyEventGroup = _.cloneDeep(metaDataVersion.studyEventGroupDef);
                            setDefList(studyEventGroup);
                            break;
                    }
                } else {
                    let cloneDefList;
                    let filteredDefList, updateDef;

                    switch (defType) {
                        case 'studyEventDef': //Study Event 설정
                            const studyEvent = _.cloneDeep(metaDataVersion.studyEventDef);

                            //New 동작 시, 생성 정보 주입.
                            cloneDefList = _.cloneDeep(Array.from([
                                ...defList,
                                ...metaDataVersion.studyEventDef.filter(se => !(defList.some(def => def.oid === se.oid))),
                            ]));

                            //Delete 동작 시, 삭제 대상 제외
                            filteredDefList = cloneDefList.filter(def => studyEvent.some(se => se.oid === def.oid))

                            //Update 동작 시, Update할 Def정보 설정.
                            updateDef = studyEvent.find(se => se.oid === selectedOID);
                            filteredDefList.map((def, index, array) => {
                                if (def.oid === selectedOID) {
                                    filteredDefList.splice(index, 1, {
                                        ...updateDef,
                                        formRef: def.formRef //현재 Setting중인 FormRef값은 그대로 둠.
                                    })
                                }
                            });

                            setDefList(filteredDefList);
                            break;
                        case 'studyEventGroupDef': //Study Event Group 설정
                            const studyEventGroup = _.cloneDeep(metaDataVersion.studyEventGroupDef);
                            //New 동작 시, 생성 정보 주입.
                            cloneDefList = _.cloneDeep(Array.from([
                                ...defList,
                                ...metaDataVersion.studyEventGroupDef.filter(seg => !(defList.some(def => def.oid === seg.oid)))
                            ]));

                            //Delete 동작 시, 삭제 대상 제외
                            filteredDefList = cloneDefList.filter(def => studyEventGroup.some(seg => seg.oid === def.oid))

                            //Update 동작 시, Update할 Def정보 설정.
                            updateDef = studyEventGroup.find(seg => seg.oid === selectedOID);
                            filteredDefList.map((def, index, array) => {
                                if (def.oid === selectedOID) {
                                    filteredDefList.splice(index, 1, {
                                        ...updateDef,
                                        studyEventRef: def.studyEventRef //현재 Setting중인 StudyEvent값은 그대로 둠.
                                    })
                                }
                            });

                            setDefList(filteredDefList);
                            break;
                    }

                    setSelectedOID("") // 작업 수행 후 SelectedOID 초기화.
                }
            }
        }
    }, [metaDataVersion]);

    useEffect(() => {
        if(protocol) {
            const studyEventCnt = (protocol?.studyEventGroupRef?.length === 0)
                ? 0
                : protocol?.studyEventGroupRef?.map((seg) => metaData?.studyEventGroup?.get(seg?.studyEventGroupOID)?.studyEventRef.length).reduce((a, b) => (a + b));
            setCurStudyEventCnt(studyEventCnt);
        }
    }, [protocol]);


    //Def CUD Method
    const newField = (itemType) => {
        setSelectedOID("");
        setCreate(true);

        switch (itemType) {
            case "studyEventDef":
                setStudyEventVisible(true);
                setStudyEventDef(null);
                break;
            case "studyEventGroupDef":
                setStudyEventGroupVisible(true);
                setStudyEventGroupDef(null);
                break;
        }
    }

    const updateField = (defType, selectedOID) => {
        setSelectedOID(selectedOID);
        setCreate(false);

        switch (defType) {
            case "studyEventDef":
                setStudyEventDef(metaData.studyEvent.get(selectedOID));
                setStudyEventVisible(true);
                break;
            case "studyEventGroupDef":
                setStudyEventGroupDef(metaData.studyEventGroup.get(selectedOID));
                setStudyEventGroupVisible(true);
                break;
        }
    }

    const deleteField = async (defType, selectedOID) => {
        try {
            const res = await Swal.fire({
                title: t('column.deletion.notification'),
                // html: `<div class="alert alert-warning">${defType}[${selectedOID}]</div><span>위 데이터를 정말로 삭제하시겠습니까?</span>`,
                html: `<div class="alert alert-warning">${defType}[${selectedOID}]</div><span>${t('odm.are.you.sure.want.delete.data')}</span>`,
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'OK'
            })

            if (res.isConfirmed) {
                //defType에 따라서 처리되어 공용으로 처리 가능.
                const removeTargetDef = defList.find(def => def.oid === selectedOID);
                removeTargetDef.attr = defType;
                removeTargetDef.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
                dispatch(metadataActions.delete(removeTargetDef));
            }
        } catch (e) {
            if (e.message != null) {
                console.log(e.message);
            }
        }
    }

    //Def Methods
    const onFinish = (data) => {
        switch (defType) {
            case "studyEventDef":
                const studyEventData = metaData.studyEvent.get(data.oid);
                data = {...studyEventData, ...data};
                setStudyEventDef(data);
                break;
            case "studyEventGroupDef":
                const studyEventGroupData = metaData.studyEventGroup.get(data.oid);
                data = {...studyEventGroupData, ...data};
                setStudyEventGroupDef(data);
                break;
        }

        descriptionCheck(data);

        data.attr = defType;
        data.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929

        if (create) {
            dispatch(metadataActions.create(data));
        } else {
            dispatch(metadataActions.update(data));
        }

        switch (defType) {
            case "studyEventDef":
                setStudyEventVisible(false);
                // setStudyEventDef(null);
                break;
            case "studyEventGroupDef":
                setStudyEventGroupVisible(false);
                // setStudyEventGroupDef(null);
                break;
        }
    }

    const onCancel = () => {
        switch (defType) {
            case "studyEventDef":
                setStudyEventVisible(false);
                break;
            case "studyEventGroupDef":
                setStudyEventGroupVisible(false);
                break;
        }
        setSelectedOID("");
    }

    const onSearch = (keyword) => {
        if (keyword === "") {
            setDefFilteredList(null);
        } else {
            setDefFilteredList(defList.filter(def => def.name.includes(keyword)));
        }
    }

    //Ref Methods
    const toggleMandatory = (refOID, defOID) => {

        if(!isProd) {
            if(defOID === 'protocol') {
                const cloneProtocol = _.clone(protocol);
                const studyEventGroupRef = cloneProtocol.studyEventGroupRef.find(seg => seg.studyEventGroupOID === refOID);
                studyEventGroupRef.mandatory = studyEventGroupRef.mandatory === 'NO' ? 'YES' : 'NO';
                if(studyEventGroupRef.mandatory === 'NO') {
                    delete studyEventGroupRef.collectionExceptionConditionOID;
                }
                setProtocol(cloneProtocol)

            } else {
                const cloneDefList = Array.from(defList);
                const defInfo = cloneDefList.find(def => def.oid === defOID);

                switch (defType) {
                    case "studyEventDef":
                        const formRef = defInfo.formRef.find(form => form.formOID === refOID);
                        formRef.mandatory = formRef.mandatory === 'NO' ? 'YES' : 'NO';
                        if(formRef.mandatory === 'NO') {
                            delete formRef.collectionExceptionConditionOID;
                        }
                        break;
                    case "studyEventGroupDef":
                        const studyEventRef = defInfo.studyEventRef.find(form => form.studyEventOID === refOID);
                        studyEventRef.mandatory = studyEventRef.mandatory === 'NO' ? 'YES' : 'NO';
                        if(studyEventRef.mandatory === 'NO') {
                            delete studyEventRef.collectionExceptionConditionOID;
                        }
                        break;
                }
                setDefList(cloneDefList);
            }
        }
    }

    const onChangeCondition = (e, refOID, defOID) => {
        const conditionOID = e.target.value;

        if(defOID === 'protocol') {
            const cloneProtocol = _.clone(protocol);
            const studyEventGroupRef = cloneProtocol.studyEventGroupRef.find(seg => seg.studyEventGroupOID === refOID);
            studyEventGroupRef.collectionExceptionConditionOID = conditionOID;
            setProtocol(cloneProtocol)

        } else {
            const cloneDefList = Array.from(defList);
            const defInfo = cloneDefList.find(def => def.oid === defOID);

            switch (defType) {
                case "studyEventDef":
                    const formRef = defInfo.formRef.find(form => form.formOID === refOID);
                    formRef.collectionExceptionConditionOID = conditionOID;
                    break;
                case "studyEventGroupDef":
                    const studyEventRef = defInfo.studyEventRef.find(form => form.studyEventOID === refOID);
                    studyEventRef.collectionExceptionConditionOID = conditionOID;
                    break;
            }
            setDefList(cloneDefList);
        }
    }

    const onDeleteRef = (refOID, defOID) => {

        if(defOID === 'protocol') {
            const cloneProtocol = _.clone(protocol);
            cloneProtocol.studyEventGroupRef.map((seg, index, array) => {
                if (seg.studyEventGroupOID === refOID) {
                    array.splice(index, 1);
                }
            });
            setProtocol(cloneProtocol);
            toFormList(cloneProtocol);
        } else {
            const cloneDefList = Array.from(defList);
            const defInfo = cloneDefList.find(def => def.oid === defOID);

            switch (defType) {
                case "studyEventDef":
                    defInfo.formRef.map((form, index, array) => {
                        if (form.formOID === refOID) {
                            array.splice(index, 1);
                        }
                    });
                    break;
                case "studyEventGroupDef":
                    defInfo.studyEventRef.filter((studyEvent, index, array) => {
                        if (studyEvent.studyEventOID === refOID) {
                            array.splice(index, 1);
                        }
                    });
                    break;
            }

            setDefList(cloneDefList);
            // enablePrevent();
            setShouldConfirm(true);
        }
    }

    //Submit Methods
    const onReset = () => {
        //현재 MetaDataVersion 정보를 다시 읽어들임.
        switch (defType) {
            case 'studyEventDef':
                const studyEvent = _.cloneDeep(metaDataVersion.studyEventDef);
                setDefList(studyEvent);
                break;
            case 'studyEventGroupDef':
                const studyEventGroup = _.cloneDeep(metaDataVersion.studyEventGroupDef);
                setDefList(studyEventGroup);
                break;
            case 'protocol':
                const protocol = _.cloneDeep(metaDataVersion.protocol);
                setProtocol(protocol);
                toFormList(protocol);
                break;
        }

        //페이지 전환 시 팝업 발생하지 않음.
        // disablePrevent();
        setShouldConfirm(false);
    }

    const onSave = () => {
        switch (defType) {
            case 'studyEventDef':
            case 'studyEventGroupDef':
                //변경된 데이터만 가지고와서 저장.
                if(defList.some(def => def[defType === 'studyEventDef'? 'formRef':'studyEventRef'].length === 0)) {
                    sweetAlert(t('odm.data.with.empty.reference.info'), 0, 'error');
                    return;
                }

                const changedList = defList.filter(def => {return !_.isEqual(def, metaData[defType === 'studyEventDef'? 'studyEvent':'studyEventGroup'].get(def.oid));});
                const data = {defList:changedList, attr: defType, params: params} //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
                dispatch(metadataActions.updates(data));
                break;
            case 'protocol':
                if(protocol.studyEventGroupRef != null && protocol.studyEventGroupRef.length > 0) {
                    //Protocol 내부의 StudyStructure 항목의 expressionComparisonType의 JAVA_OBJECT 항목을 key값으로 변경 - 20221019
                    const stratificationFactors = protocol?.studyStructure?.stratification?.stratificationFactors??[];
                    stratificationFactors.forEach(factor => {
                        (factor?.itemExpressions??[]).forEach(itemExp => {
                            itemExp.expressionComparisonType = itemExp.expressionComparisonType?.key;
                        });
                    });
                    const stratificationInfos = protocol?.studyStructure?.stratification?.stratificationInfos??[];
                    stratificationInfos.forEach(info => {
                        (info?.itemExpressions??[]).forEach(itemExp => {
                            itemExp.expressionComparisonType = itemExp.expressionComparisonType?.key;
                        });
                    });

                    //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
                    protocol.params = params;
                    dispatch(metadataActions.updateProtocol(protocol))
                } else {
                    sweetAlert(t('odm.reference.info.empty'), 0, 'error');
                    return;
                }
                break;
        }

        //페이지 전환 시 팝업 발생하지 않음.
        // disablePrevent();
        setShouldConfirm(false);
    }


    const getStudyEventGroupTitleWidth = (size) => {
        let totalSize = 0;
        const headerWidth = 700;
        //total Size 구하기.
        protocol?.studyEventGroupRef?.forEach(seg => {
            totalSize += metaData?.studyEventGroup.get(seg?.studyEventGroupOID)?.studyEventRef?.length;
        })

        return Math.round(headerWidth/totalSize)*size;
    }

    const SiblingFormList = () => {
        const {form, studyEvent, studyEventGroup} = metaData;
        const {formSchedule, studyEventSchedule, studyEventScheduleMap} = getScheduleMapByProtocol(protocol, studyEventGroup, studyEvent, form);

        return (
            <>
                {
                    formSchedule.map(form => (
                        <tr>
                            <td style={{position:'sticky', left:'0', textAlign:'center', verticalAlign:'middle', background:'white', opacity:1}}>{form?.name}</td>
                            {
                                studyEventSchedule.map(se => (
                                    <td style={{verticalAlign:'middle', textAlign:'center'}}>
                                        {
                                            studyEventScheduleMap.get(se.oid).has(form.oid) &&
                                            <i className='bi bi-file-earmark' style={{color: '#d4d4d4', fontSize: 27}}/>
                                        }
                                    </td>
                                ))
                            }
                        </tr>
                    ))
                }
            </>
        )
    }

    return (
        <>
            {/* Loading */}
            {
                (protocol === null && defList === null) &&
                    <NormalLoading></NormalLoading>
            }
            {/* Protocol */}
            {
                protocol !== null &&
                <div className={'card'}>
                    <ScheduleHeader selectedOID={selectedOID} defType={defType} metaData={metaData} protocol={protocol}
                                    refType={refType} onDeleteRef={onDeleteRef} toggleMandatory={toggleMandatory} onChangeCondition={onChangeCondition}>
                        <Badge count={protocol.length} offset={[10, 0]}>
                            <h3>{defTitle}</h3>
                        </Badge>
                    </ScheduleHeader>
                    <ScheduleBody>
                        {
                            protocol?.studyEventGroupRef != null && protocol.studyEventGroupRef.length <= 0 &&
                                <div className={style.noContent}>
                                <h3 className={'text-danger p-5'} style={{border: 'solid 1px rgba(0,0,0,0.5)'}}>
                                    No Content
                                </h3>
                                    <span className={'text-danger'}><WarningOutlined /> {t('odm.pls.add.event.group')}</span>
                                </div>
                        }
                        {
                            protocol?.studyEventGroupRef != null && protocol.studyEventGroupRef.length > 0 &&
                            <Table bordered={true} className={style.protocol}
                                   style={{borderSpacing: 0, borderCollapse: 'separate', overflowX:'auto', tableLayout: 'fixed',
                                           display: curStudyEventCnt > 20 ? 'block' : ''}}>
                                <colgroup span={curStudyEventCnt + 1} style={{minWidth:'3vw'}} />
                                <thead className={style.protocolHeader}>
                                <tr>
                                    <th className={style.backslash} rowSpan={2}>
                                        <div style={{position:'absolute', top:14, right:6}}>
                                            Visits
                                        </div>
                                        <div style={{position:'absolute', bottom:14, left:6}}>
                                            Forms
                                        </div>
                                    </th>
                                    {/* StudyEventGroup */}
                                    {
                                        protocol.studyEventGroupRef.map((seg, segIdx) => (
                                            <React.Fragment key={segIdx}>
                                                {
                                                    metaData.studyEventGroup.get(seg.studyEventGroupOID)?.studyEventRef?.length > 0 &&
                                                    <th className={style.studyEventTitle} colSpan={metaData.studyEventGroup.get(seg.studyEventGroupOID)?.studyEventRef?.length??'1'}>
                                                        {seg?.mandatory === 'YES' && <sup className='text-danger'>*</sup>}
                                                        {metaData.studyEventGroup.get(seg.studyEventGroupOID).name}
                                                    </th>
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    {/* StudyEvent */}
                                    {
                                        protocol.studyEventGroupRef.map((seg, segIdx) => {
                                            const studyEventGroupDef = metaData.studyEventGroup.get(seg.studyEventGroupOID);
                                            return (
                                                <React.Fragment key={segIdx}>
                                                    {
                                                        (studyEventGroupDef?.studyEventRef??[]).map((se, seIdx) => (
                                                            <th>
                                                                {metaData.studyEvent.get(se.studyEventOID).name}
                                                            </th>
                                                        ))
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                    <SiblingFormList />
                                </tbody>
                            </Table>
                        }
                    </ScheduleBody>
                    <ScheduleFooter isProtocol={true} onReset={onReset} onSave={onSave}/>
                </div>
            }

            {/* StudyEvent - StudyEventGroup */}
            {
                defList !== null &&
                <div className={'card'}>
                    <Input.Search placeholder={`input search text`}
                                  className={'mt-3 col-12'}
                                  allowClear size={'small'} onSearch={onSearch}/>
                    <ScheduleHeader selectedOID={selectedOID} defType={defType} addNew={newField}>
                        <Badge count={defList.length} offset={[10, 0]}>
                            <h3>{defTitle}</h3>
                        </Badge>
                    </ScheduleHeader>
                    <ScheduleBody>
                        {
                            (defFilteredList !== null ? defFilteredList : defList).sort(function(a, b) {
                                if(a?.visitNum < b?.visitNum) {
                                    return -1;
                                }

                                if(a?.visitNum < b?.visitNum) {
                                    return 1;
                                }

                                return 0;
                            }).map((def, index) => (
                                    <div key={index}>
                                        <DefContentWrapper className={'pl-2 mt-2 mb-2'}>
                                            <DefHeadTitle>
                                                {defType === 'studyEventDef' &&
                                                <h5 className='mr-2'>[<span className={'text-primary font-weight-bold'}>{`${def?.visitNum != undefined && def?.visitNum >= 0 ? def.visitNum : '-'}`}</span>] {def.name}</h5>
                                                }
                                                {defType !== 'studyEventDef' &&
                                                    <h5 className='mr-2'>{def.name}</h5>
                                                }
                                                <DefControlBlock>
                                                    <Button type={'ghost'} size={'small'} className={'mr-1'}
                                                            onClick={() => updateField(defType, def.oid)}>
                                                        {isProd?(<i className='fa fa-info'/>):(<i className='fe fe-edit-3'/>)}
                                                    </Button>
                                                    {
                                                        (!isProd && !isExcludeOID(def.oid)) &&
                                                        <Button type={'ghost'} size={'small'}
                                                                onClick={() => deleteField(defType, def.oid)}>
                                                            <i className='fe fe-trash-2'/>
                                                        </Button>
                                                    }
                                                </DefControlBlock>
                                            </DefHeadTitle>
                                                <Droppable key={def.oid} droppableId={def.oid} type={refType} scroll={'y'}
                                                           direction={'horizontal'}>
                                                    {
                                                        (provided, snapshot) => (
                                                            <DefContent
                                                                {...provided.droppableProps}
                                                                isDraggingOver={snapshot.isDraggingOver}
                                                                ref={provided.innerRef}
                                                            >
                                                                <ScheduleDefDetail defType={defType} metaData={metaData} def={def}
                                                                                   toggleMandatory={toggleMandatory}
                                                                                   onChangeCondition={onChangeCondition}
                                                                                   onDeleteRef={onDeleteRef} isProd={isProd}/>
                                                                {provided.placeholder}
                                                            </DefContent>
                                                        )
                                                    }
                                                </Droppable>
                                        </DefContentWrapper>
                                    </div>
                                )
                            )
                        }
                    </ScheduleBody>
                    <ScheduleFooter onReset={onReset} onSave={onSave}/>
                </div>
            }
            {
                <StudyEventDef visible={studyEventVisible} selectedOID={selectedOID}
                               studyEventDef={studyEventDef} setStudyEventDef={setStudyEventDef}
                               onFinish={onFinish} onCancel={onCancel} eventTypes={eventTypes}
                               languages={languages} yesOrNo={yesOrNo}
                />
            }
            {
                <StudyEventGroupDef visible={studyEventGroupVisible} selectedOID={selectedOID}
                                    studyEventGroupDef={studyEventGroupDef}
                                    setStudyEventGroupDef={setStudyEventGroupDef}
                                    onFinish={onFinish} onCancel={onCancel} languages={languages} yesOrNo={yesOrNo}/>
            }
        </>
    )
}

export default ScheduleDefList;
