import React, {useEffect, useState} from 'react';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Form} from "antd";
import {metadataActions} from "../../redux/module/metadata";
import {isEnvProduction, toMetaData} from "../../lib/ODMUtils";
import {useParams} from "react-router-dom";
import ProScheduleDef from "../../components/odm/ProScheduleDef";
import {mdiMagnifyPlusOutline} from "@mdi/js";
import Icon from "@mdi/react";
import {useTranslation} from "react-i18next";

// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 16 },
// };

const EproContainer = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector(({metaDataStore}) => (metaDataStore));
    const isProd = isEnvProduction(metaDataStore);
    const metaData = toMetaData(metaDataStore.study);
    const [visitFormOIDs, setVisitFormOIDs] = useState(null);
    const [oid, setOID] = useState(null);
    const [studyEventOID, setStudyEventOID] = useState(null);
    const [formOID, setFormOID] = useState(null);

    const {t} = useTranslation();
    const [isExcludeSchedule, setExcludeSchedule] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
        setOID(null);
        setShowModal(false);
    }

    //컴포넌트에 따라 필요한 정보
    useEffect(() => {
        let _visitFormOIDs = [];
        let oids = Array.from(metaData.form.values()).filter(f => f.formType === 'EPRO' || f.formType === 'CRF_EPRO').map(f => f.oid);

        //oid 반복 처리 후 확인
        oids.forEach(oid => {
            let proEvents = Array.from(metaData.studyEvent.values())
                .filter(se => se.formRef.some(({formOID}) => formOID === oid))
                .map(se => se.oid);

            if(proEvents) {
                proEvents.forEach(seOID => {
                    let scheduleDef = metaData.eproScheduleMap.has(`PRO.${seOID}.${oid}`) ?  metaData.eproScheduleMap.get(`PRO.${seOID}.${oid}`) : null;
                    _visitFormOIDs.push({oid:`${seOID}.${oid}`, studyEventOID:seOID, formOID:oid, scheduleDef:scheduleDef});
                });
            }
        });

        //현재 보이는 ePRO Schedule 정보와 실제 저장된 ePRO Schedule 데이터가 불일치 한 경우 체크
        const isExcludeData = Array.from(metaData.eproScheduleMap.keys())
            .map((eproKey) => eproKey.split("PRO.")[1])
            .some((key) => _visitFormOIDs.map(def => def.oid).includes(key) === false)

        setExcludeSchedule(isExcludeData);
        setVisitFormOIDs(_visitFormOIDs);

    }, [metaDataStore]);

    useEffect(() => {
        console.log('useEffect => ', oid, studyEventOID, formOID);
        setShowModal(oid !== null);
    }, [oid]);

    const openModal = (studyEventOID, formOID) => {
        console.log('click =>', studyEventOID, formOID);
        setStudyEventOID(studyEventOID);
        setFormOID(formOID);
        setOID(`PRO.${studyEventOID}.${formOID}`);
    }

    const onSaveFinish = (proScheduleDef) => {

        proScheduleDef.params = params;
        proScheduleDef.attr = 'proScheduleDef';
        console.log('onSaveFinish => ', proScheduleDef);
        dispatch(metadataActions.updateProSchedule(proScheduleDef));
    }

    const onSyncSchedule = () => {
        const data = {
            ...params,
            visitFormOIDs:visitFormOIDs.map(def => def.oid)
        };
        dispatch(metadataActions.syncProSchedule(data))
    }

    return (
        <>
            {
                isExcludeSchedule &&
                <>
                    <Alert className={'mb-3'} message={<>
                        <span className={'mr-2'}>{t('message.epro-schedule.mismatch')}</span>
                        <Button type={'primary'} onClick={onSyncSchedule}>Schedule Sync</Button>
                    </>} type={'warning'} />
                </>
            }
            <table className={'table table-bordered table-hover text-center'}>
                <thead className={'table-light'}>
                <tr>
                    <th>VISIT</th>
                    <th>CRF</th>
                    <th>Baseline Visit</th>
                    <th>Days</th>
                    <th>Interval</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {visitFormOIDs != null && visitFormOIDs.map((proSchedule) => {
                    return (
                        <tr>
                            <td>{metaData.studyEvent.has(proSchedule.studyEventOID) ? metaData.studyEvent.get(proSchedule.studyEventOID).name : proSchedule.studyEventOID}</td>
                            <td>{metaData.form.has(proSchedule.formOID) ? metaData.form.get(proSchedule.formOID).name : proSchedule.formOID}</td>
                            <td>{(proSchedule.scheduleDef?.sourceStudyEventOID != null && metaData.studyEvent.has(proSchedule.scheduleDef.sourceStudyEventOID)) ? metaData.studyEvent.get(proSchedule.scheduleDef.sourceStudyEventOID).name : proSchedule.scheduleDef?.sourceStudyEventOID}</td>
                            <td>{proSchedule.scheduleDef?.numberOfTimes}</td>
                            <td>{proSchedule.scheduleDef?.interval}</td>
                            <td><Button type={"primary"} onClick={() => openModal(proSchedule.studyEventOID, proSchedule.formOID)}><Icon path={mdiMagnifyPlusOutline} size={1} /></Button></td>
                        </tr>
                    )
                })}
                </tbody>
            </table>

            <ProScheduleDef metaData={metaData} oid={oid} studyEventOID={studyEventOID} formOID={formOID} onFinish={onSaveFinish} showModal={showModal} closeModal={closeModal} isProd={isProd}/>
        </>
    );
}

export default EproContainer;