import {Form, Input} from 'antd';
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../../lib/ODMUtils";
const {Item} = Form;

const FormInput = ({style, required, type=null, name, label, readOnly, rules, onKeyup}) => {

    const metaDataStore = useSelector((store) => store.metaDataStore);
    const isProd = isEnvProduction(metaDataStore);

    return (
        <Item required={required} name={name}
              label={<span className={'text-primary'}>{label}</span>} rules={rules}>
            <Input type={type} style={style} readOnly={isProd || readOnly} onKeyUp={onKeyup !== undefined ? onKeyup : null} />
        </Item>
    )
}

export default FormInput;