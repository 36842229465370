import {Helmet} from "react-helmet-async";
import SettingsTabs from "../../../../layouts/Tab/settings";
import EmailNoticeList from "../../../../components/common/builder/studies/EmailNoticeList";

const EmailNoticePage = () => {

    return (
        <SettingsTabs menu={'email-notice'}>
            <Helmet title={'BUILDER :: E-Mail Notice'} />
            <EmailNoticeList />
        </SettingsTabs>
    )
}

export default EmailNoticePage
