import React from "react";
import DefinitionBtn from "./DefinitionBtn";
import {CodeListRefArea, Notice} from "../StyleBox";
import {CodeList, descriptionText, QuestionText, translatedText} from "./FormComponents";
import {isEmpty} from "../../../lib/StringUtils";
import style from "./style.module.scss";
import classNames from "classnames";
import {getExtensionsLabel, getReferenceFileLabel, unknownDateFormat} from "../../../lib/ODMUtils";
import {useTranslation} from "react-i18next";


const ItemField = ({metaData, designMode, selectedLanguage, setDefVisible, setDefType, setDefItem,
                       item, repeating, valueListOID = null, unitsItemOID = null ,
                       onECSInfo, methodOID = null, collectionExceptionConditionOID = null}) => {
    const {t} = useTranslation();

    const isCodeList = item?.codeListRef != null && (!isEmpty(item.codeListRef?.codeListOID));
    const isValueList = !isCodeList && valueListOID != null;

    //ValueList일 때, RESULT 값인지 확인
    const isValueListResultItem = valueListOID != null && item.oid.endsWith("ORRES");

    const codeList = isCodeList ? metaData.codeList.get(item.codeListRef.codeListOID) : null;
    const valueList = isValueList ? metaData.valueList.get(valueListOID)||null : null;
    const controlType = item?.controlType;

    return (
        <>
            {
                ((isCodeList && (controlType === 'DROPDOWN' || controlType.startsWith("RADIO") || controlType.startsWith("CHECKBOXES") || controlType === "DICTIONARY")) &&
                    <>
                        {
                            designMode && (
                                <CodeListRefArea>
                                    <DefinitionBtn data={codeList}
                                                   defType={codeList.externalCodeList != null ? "externalCodeList" : "codeListItem"}
                                                   setDefVisible={setDefVisible} setDefType={setDefType}
                                                   setDefItem={setDefItem}/>
                                </CodeListRefArea>
                            )
                        }
                        {
                            <>
                                {/*CodeList Update 진행 후 decode의 translateText가 공백으로 받아져서 처리.*/}
                                <CodeList itemDef={item} codeList={codeList} selectedLanguage={selectedLanguage} designMode={designMode}/>
                                <div className={classNames('', {
                                    [style.descriptionRepeating]: repeating != 'NO'
                                })}>
                                    {descriptionText({description: item.description, selectedLanguage: selectedLanguage})}
                                </div>
                            </>
                        }
                    </>
                )
            }
            {
                //repeating STATIC인 itemGroup에서 ORRES형식의 값을 사용할 때,
                (repeating == 'STATIC' && isValueListResultItem) &&
                <>
                    {
                        valueList !== null? (
                            <>
                                {
                                    designMode && (
                                        <DefinitionBtn data={valueList} defType={"valueList"}
                                                       setDefVisible={setDefVisible} setDefType={setDefType}
                                                       setDefItem={setDefItem}/>
                                    )
                                }
                                {
                                    (valueList?.itemRef??[]).map((i, index) => {
                                        const valueListItem = metaData.item.get(i?.itemOID);
                                        const isVLItemCodeList = valueListItem.codeListRef != null && (!isEmpty(valueListItem.codeListRef.codeListOID));;
                                        const VLItemCodeList = isVLItemCodeList ? metaData.codeList.get(valueListItem.codeListRef.codeListOID) : [];
                                        return (
                                            <div key={i?.itemOID}
                                                 style={{
                                                     minHeight: '45px',
                                                     borderBottom: '1px solid #eeeeee',
                                                     padding: '9px 0px 5px 0',
                                                     width: '100%'
                                                 }}>
                                                {
                                                    ((isVLItemCodeList && (valueListItem.controlType === 'DROPDOWN' ||
                                                            valueListItem.controlType.startsWith("RADIO") ||
                                                            valueListItem.controlType.startsWith("CHECKBOXES") ||
                                                            valueListItem.controlType === "DICTIONARY")) &&
                                                        <>
                                                            {
                                                                designMode && (
                                                                    <CodeListRefArea>
                                                                        <DefinitionBtn data={VLItemCodeList}
                                                                                       defType={VLItemCodeList.externalCodeList != null ? "externalCodeList" : "codeListItem"}
                                                                                       setDefVisible={setDefVisible} setDefType={setDefType}
                                                                                       setDefItem={setDefItem}/>
                                                                    </CodeListRefArea>
                                                                )
                                                            }
                                                            {
                                                                <>
                                                                    {/*CodeList Update 진행 후 decode의 translateText가 공백으로 받아져서 처리.*/}
                                                                    <CodeList itemDef={valueListItem} codeList={VLItemCodeList} selectedLanguage={selectedLanguage} designMode={designMode}/>
                                                                    <div className={classNames('', {
                                                                        [style.descriptionRepeating]: repeating != 'NO'
                                                                    })}>
                                                                        {descriptionText({description: item.description, selectedLanguage: selectedLanguage})}
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    ((valueListItem.controlType === 'INPUT' || valueListItem.controlType === 'TEXT' ||
                                                        valueListItem.controlType === 'DATEPICKER' ||
                                                        valueListItem.controlType === 'TIMEPICKER' ||
                                                        valueListItem.controlType === 'DATETIME' || valueListItem.controlType === 'CHECKBOX' ||
                                                        valueListItem.controlType === 'NUMBER'))
                                                        && (
                                                            <>
                                                                <div className={'input-group'}>
                                                                    {(!isEmpty(valueListItem.prefix)) &&
                                                                    <span className={'text-muted mt-1 mr-2'}>{valueListItem.prefix}</span>
                                                                    }
                                                                    {(!isEmpty(i.methodOID) && designMode) &&
                                                                    <div className={'input-group-prepend'}>
                                                                        <span className={'input-group-text'}><i className={'fa fa-calculator'}></i></span>
                                                                    </div>
                                                                    }
                                                                    {metaData.item.get(i.itemOID).dataType !== 'BOOLEAN' &&
                                                                    <input type="text" readOnly={true} className="form-control" style={{fontSize: '0.8rem'}}
                                                                           placeholder={getDataType(valueListItem.dataType, isVLItemCodeList, valueListItem, metaData)}/>
                                                                    }
                                                                    {metaData.item.get(i.itemOID).dataType === 'BOOLEAN' &&
                                                                    <label className="vb__utils__control vb__utils__control__checkbox">
                                                                        <input type={'checkbox'} disabled={true}/>
                                                                        <span className="vb__utils__control__indicator"></span>
                                                                        {designMode === true ? <sup style={{color: 'red'}}>1</sup> : ''}
                                                                        {QuestionText({itemDef:valueListItem, selectedLanguage:selectedLanguage, designMode:true})}{/*Checkbox Label 출력을 위해서 designMode 항시 true 로 설정*/}
                                                                    </label>
                                                                    }
                                                                    {
                                                                        !isEmpty(i.unitsItemOID) &&
                                                                        <div className={'input-group-append'}>
                                                                            <select className={'form-control'} style={{fontSize: '0.8rem',width:'90px'}}>
                                                                                {designMode ?
                                                                                    <>
                                                                                        <option>{i.unitsItemOID}</option>
                                                                                        {metaData.codeList.get(metaData.item.get(i.unitsItemOID).codeListRef.codeListOID).codeListItem.map((code, index) => (
                                                                                            <option key={`${i.unitsItemOID}-${index}`}>{code.codedValue} : {translatedText(code.decode.translatedText, selectedLanguage)}</option>
                                                                                        ))}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {metaData.codeList.get(metaData.item.get(i.unitsItemOID).codeListRef.codeListOID).codeListItem.map((code, index) => (
                                                                                            <option key={`${i.unitsItemOID}-${index}`}>{translatedText(code.decode.translatedText, selectedLanguage)}</option>
                                                                                        ))}
                                                                                    </>
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    }
                                                                    {(!isEmpty(valueListItem.postfix) && isEmpty(i.unitsItemOID)) &&
                                                                    <span className={'text-muted mt-1 ml-2'}>{valueListItem.postfix}</span>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {descriptionText({description:valueListItem.description, selectedLanguage:selectedLanguage, isWrap: false})}
                                                                </div>
                                                            </>
                                                        )
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </>
                        ) : (
                            <Notice>
                                {
                                    (!isValueList && isValueListResultItem && isCodeList)?(
                                        <span>{t('container.result.item.has.codeList.referenced.pls.remove.reference.codeList')}</span>
                                    ):(
                                        <span>{t('container.currently.selected.value.list.has.been.deleted')}</span>
                                    )
                                }
                            </Notice>
                        )
                    }
                </>
            }
            {
                (((controlType === 'INPUT' || controlType === 'TEXT' ||
                    controlType === 'DATEPICKER' || controlType === 'TIMEPICKER' ||
                    controlType === "FILE_UPLOAD" || controlType === "FILE_DOWNLOAD" ||
                    controlType === 'DATETIME' || controlType === 'CHECKBOX' ||
                    controlType === 'NUMBER') && isValueList === false)
                    &&
                    <>
                        <div className={'input-group'}>
                            {(!isEmpty(item.prefix)) &&
                                <span className={'text-muted mt-1 mr-2'}>{item.prefix}</span>
                            }
                            {(controlType === "FILE_UPLOAD" || controlType === "FILE_DOWNLOAD") &&
                                <div className={'input-group-prepend'}>
                                    <span className={'input-group-text'}>
                                        <i className={classNames('fa', {
                                            ["fa-upload"]: controlType === 'FILE_UPLOAD',
                                            ["fa-download"]: controlType === 'FILE_DOWNLOAD'
                                        })}></i>
                                    </span>
                                </div>
                            }
                            {(!isEmpty(methodOID) && designMode) &&
                                <div className={'input-group-prepend'}>
                                    <span className={'input-group-text'}><i className={'fa fa-calculator'}></i></span>
                                </div>
                            }
                            {item.dataType !== 'BOOLEAN' &&
                                <input type="text" readOnly={true} className="form-control" style={{fontSize: '0.8rem'}}
                                       placeholder={getDataType(item.dataType, isCodeList, item, metaData)}/>
                            }
                            {item.dataType === 'BOOLEAN' &&
                                <label className="vb__utils__control vb__utils__control__checkbox">
                                    <input type={'checkbox'} disabled={true}/>
                                    <span className="vb__utils__control__indicator"></span>
                                    {designMode === true ?
                                        <sup style={{color: 'red'}}>1</sup> : ''}
                                    {QuestionText({itemDef:item, selectedLanguage:selectedLanguage, designMode:true})}{/*Checkbox Label 출력을 위해서 designMode 항시 true 로 설정*/}
                                </label>
                            }
                            {
                                !isEmpty(unitsItemOID) &&
                                <div className={'input-group-append'}>
                                    <select className={'form-control'} style={{fontSize: '0.8rem',width:'90px'}}>
                                        {designMode ?
                                            <>
                                                <option>{unitsItemOID}</option>
                                                {metaData.codeList.get(metaData.item.get(unitsItemOID).codeListRef.codeListOID).codeListItem.map((code, index) => (
                                                    <option key={`${unitsItemOID}-${index}`}>{code.codedValue} : {translatedText(code.decode.translatedText, selectedLanguage)}</option>
                                                ))}
                                            </>
                                            :
                                            <>
                                                {metaData.codeList.get(metaData.item.get(unitsItemOID).codeListRef.codeListOID).codeListItem.map((code, index) => (
                                                    <option key={`${unitsItemOID}-${index}`}>{translatedText(code.decode.translatedText, selectedLanguage)}</option>
                                                ))}
                                            </>

                                        }
                                    </select>
                                </div>
                            }
                            {(!isEmpty(item.postfix) && isEmpty(unitsItemOID)) &&
                            <span className={'text-muted mt-1 ml-2'}>{item.postfix}</span>
                            }
                        </div>
                        <div className={classNames('', {
                            [style.descriptionRepeating]: repeating != 'NO'
                        })}>
                            {descriptionText({description: item.description, selectedLanguage: selectedLanguage})}
                        </div>
                    </>
                )
            }
            {
                (controlType === "TEXTAREA" &&
                    <>
                        <textarea className={'form-control'} rows={3} readOnly={true} style={{fontSize: '0.8rem', resize:'none'}}
                                  placeholder={getDataType(item.dataType, isCodeList, item, metaData)}></textarea>
                        <div>
                            {descriptionText({description: item.description, selectedLanguage: selectedLanguage})}
                        </div>
                    </>
                )
            }
            {
                (controlType === "LABEL" &&
                    <>
                        <span style={{fontSize: '0.8rem', resize:'none'}}>{item.labelText}</span>
                    </>
                )
            }
            {
                (controlType === "DROPDOWN_DATALINK" &&
                    <>
                        <select className={'form-control'} style={{fontSize: '0.8rem',width:'90px'}}>
                            <optgroup label={metaData.itemGroup.get(item?.dataLink?.source?.itemGroupOID)?.name}></optgroup>
                            <option>{item?.dataLink?.delimiter}{metaData.item.get(item?.dataLink?.source?.itemOID)?.name}</option>
                        </select>
                    </>
                )
            }
            {
                ((item?.valueListRef != null && item.valueListRef?.valueListOID != null && controlType.startsWith("CHECKBOXES")) &&
                    <>
                        {
                            designMode && (
                                <div className={'mb-2'}>
                                    <DefinitionBtn data={metaData.valueList.get(item.valueListRef.valueListOID)} defType={"valueList"}
                                               setDefVisible={setDefVisible} setDefType={setDefType}
                                               setDefItem={setDefItem}/>
                                </div>
                            )
                        }
                        {metaData.valueList.get(item.valueListRef.valueListOID).itemRef.map((i, index) => {
                                const valueListItem = metaData.item.get(i?.itemOID);
                                return (
                                    <label key={index} className="vb__utils__control vb__utils__control__checkbox">
                                        <input type={'checkbox'} disabled={true}/>
                                        <span className="vb__utils__control__indicator"></span>
                                        {
                                            designMode === true &&
                                            <span>
                                                <DefinitionBtn data={valueListItem} index={index} defType={"item"}
                                                               setDefVisible={setDefVisible} setDefType={setDefType}
                                                               setDefItem={setDefItem}/>
                                            </span>
                                        }
                                        {QuestionText({itemDef:valueListItem, selectedLanguage:selectedLanguage, designMode:true})}{/*Checkbox Label 출력을 위해서 designMode 항시 true 로 설정*/}
                                    </label>
                                )
                            })}
                    </>
                )
            }
            {
                //실험항목을 참조한 ValueList Field를 그릴 때 사용
                (isValueListResultItem === false && isValueList) && (
                    <>
                        {
                            valueList !== null? (
                                <>
                                    {
                                        designMode && (
                                            <DefinitionBtn data={valueList} defType={"valueList"}
                                                           setDefVisible={setDefVisible} setDefType={setDefType}
                                                           setDefItem={setDefItem}/>
                                        )
                                    }
                                    {
                                        valueList.itemRef.map((i, index) => {
                                            const valueListItem = metaData.item.get(i?.itemOID);
                                            return (
                                                <div key={index}
                                                     style={{
                                                         minHeight: valueListItem.description != null?'66px':'45px',
                                                         borderBottom: '1px solid #eeeeee',
                                                         padding: '9px 0px 5px 0',
                                                         width: '100%'
                                                     }}>
                                                    {
                                                        designMode &&
                                                        <DefinitionBtn data={valueListItem} index={index} defType={"item"}
                                                                       setDefVisible={setDefVisible} setDefType={setDefType}
                                                                       setDefItem={setDefItem}/>
                                                    }
                                                    <div className={style.valueListTextBlock}>{valueListItem.name}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            ) : (
                                <Notice>
                                    {t('container.currently.selected.value.list.has.been.deleted')}
                                </Notice>
                            )
                        }
                    </>
                )
            }

            {(!isEmpty(methodOID) && designMode) &&
            <div className={classNames({
                'text-warning': metaData.editCheck.get(methodOID)?.activate
            })}>
                <span className={classNames(style.cursorPointer,'badge', {
                    'badge-primary': metaData.editCheck.get(methodOID)?.activate,
                    'badge-secondary': !metaData.editCheck.get(methodOID)?.activate
                })} onClick={() => onECSInfo(methodOID)}>
                    <i className='fa fa-search-plus mr-1'></i>
                    {methodOID}
                </span>&nbsp;
                <small>{metaData.editCheck.get(methodOID)?.name||'현재 삭제된 Method OID입니다.'}</small>
            </div>
            }
            {(!isEmpty(collectionExceptionConditionOID) && designMode) &&
            <div className={classNames({
                'text-warning': metaData.editCheck.get(collectionExceptionConditionOID)?.activate
            })}>
                <span className={classNames(style.cursorPointer, 'badge', {
                'badge-danger': metaData.editCheck.get(collectionExceptionConditionOID)?.activate,
                'badge-secondary': !metaData.editCheck.get(collectionExceptionConditionOID)?.activate
            })} onClick={() => onECSInfo(collectionExceptionConditionOID)}>
                    <i className='fa fa-search-plus mr-1'></i>
                    {collectionExceptionConditionOID}
                </span>&nbsp;
                <small>{metaData.editCheck.get(collectionExceptionConditionOID)?.name||'삭제된 Condition OID입니다.'}</small>
            </div>
            }
        </>
    )
}

const getDataType = (dataType, isCodeList, item, metaData) => {

    if (["TEXT", "INTEGER", "FLOAT"].includes(dataType)) {
        let str = isCodeList ? `Length : ${item.dataType}` : `${item.dataType}`;
        str += `(`;

        if(item?.allowOnlyAlphabet && item?.onlyUpperCase && item?.allowSpace) {
            str += 'ONLY ALPHABET (WITH SPACE)';
            str += ' / ';
        } else if(item?.allowOnlyAlphabet && item?.allowSpace) {
            str += 'Only Alphabet (With Space)';
            str += ' / ';
        } else if(item?.allowOnlyAlphabet && item?.onlyUpperCase) {
            str += 'ONLY ALPHABET';
            str += ' / ';
        } else if(item?.allowOnlyAlphabet) {
            str += 'Only Alphabet';
            str += ' / ';
        }

        if (item.length != null) { // null, undefined Check
            str += `${item.length}`;
        }
        if (item.fractionDigits != null) { // null, undefined Check
            str += `.${item.fractionDigits}`;
        }

        str += ')';

        if(item?.allowExtensions != null && item?.allowExtensions.length > 0) {
            str += " - " + getExtensionsLabel(item.allowExtensions);
        }

        if(item?.limitFileSize != null) {
            str += " [Limit : " + item.limitFileSize  + "MB]"
        }

        if(item?.referenceFileSource != null) {
            str += " - " + getReferenceFileLabel(item.referenceFileSource, metaData);
        }

        return str.endsWith("()") ? dataType : str;
    } else if(dataType === 'DATE') {
        return 'DATE (YYYY-MM-DD)';
    } else if(dataType === 'TIME') {
        return 'TIME (HH:MM)';
    } else if(dataType === 'PARTIAL_DATE') {
        return unknownDateFormat.find(df => df.value === item?.unknownDateFormat??'ALL_UK')?.format;
    } else if(dataType === 'PARTIAL_TIME') {
        return 'TIME (UK or HH:UK)';
    }

    return dataType;
}

export default ItemField;
