import React from "react";
import {Form, Select, Tag} from 'antd';
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../../lib/ODMUtils";
import {isEmpty} from "../../../../lib/StringUtils";
import {useTranslation} from "react-i18next";
import _ from 'lodash';

const {Item} = Form

const FormSelectYesOrNo = ({name, label, required, icons = null, yesOrNo, value, disabledOption}) => {

    const metaDataStore = useSelector((store) => store.metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const {t} = useTranslation();

    const checkDisabledOption = (key) => {
        if(isEmpty(disabledOption)) {
            return false;
        } else if(_.isArray(disabledOption)) {
            return disabledOption.includes(key);
        } else {
            return key === disabledOption
        }
    }

    return (
        <Item name={name} label={<span className={'text-primary'}>{label}</span>}
              required={false}
              rules={[
                  {required: required === undefined ? false : required, message: t('message.warning.required-entry')}
              ]}>
            {
                isProd?(
                    <Tag color={'geekblue'}>
                        <span className={'font-size-16 font-weight-bold'}>
                            {icons != null && (<>{icons[value]}&nbsp;</>)}
                            {yesOrNo[value]}
                        </span>
                    </Tag>
                ): (
                    <Select>
                        {Object.keys(yesOrNo).map(key => (
                            <Select.Option key={key} value={key} disabled={checkDisabledOption(key)}>
                                {icons != null && (<>{icons[key]}&nbsp;</>)}
                                {yesOrNo[key]}
                            </Select.Option>
                        ))}
                    </Select>
                )
            }
        </Item>
    )
}

export default FormSelectYesOrNo;