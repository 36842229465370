import {Button, Popover, Tag} from "antd";
import React from "react";

const ConditionBtn = ({defOID, metaData, refItem, refInfo, visible, setVisible, onChangeCondition, isProd}) => {
    const conditionDefs = [...metaData.editCheck.values()].filter(ecs => ecs.action.type === 'CONDITION');
    const displayDef = conditionDefs.find(def => def?.oid === refItem?.collectionExceptionConditionOID);

    return (
        <>
            <Popover placement={'top'}
                     title={'Condition'}
                     trigger={'click'}
                     open={visible}
                     onOpenChange={() => setVisible(!visible)}
                     content={(
                         <div className={'form-group'} >
                             {
                                 isProd?(
                                     <Tag>
                                         <span className={'font-weight-bold text-danger'}>
                                             {displayDef!=null?`${displayDef?.oid} - ${displayDef?.name}`:`NONE`}
                                         </span>
                                     </Tag>
                                 ):(
                                     <select type={'text'} placeholder={'Condition'} defaultValue={refItem?.collectionExceptionConditionOID}
                                             className={'form-control'} onChange={(e) => onChangeCondition(e, refInfo.oid, defOID)}
                                             onBlur={() => setVisible(false)}>
                                         <option value={""}>
                                             ...
                                         </option>
                                         {conditionDefs.map(ecs => (
                                             <option value={ecs.oid} key={`CON_${refInfo.oid}_${ecs.oid}`}>{ecs.oid} - {ecs.name}</option>
                                         ))}
                                     </select>
                                 )
                             }
                         </div>
                     )}>
                <Button size={'small'} onClick={() => setVisible(true)}>
                    <i className='fa fa-code'></i>
                </Button>
            </Popover>
        </>
    )
}

export default ConditionBtn;