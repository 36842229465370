import React, {useEffect, useState} from 'react';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import {useDispatch, useSelector} from "react-redux";
import {metadataActions} from "../../../redux/module/metadata";
import {descriptionCheck} from "../../../lib/ODMUtils";
import FormDef from "../modal/odm/FormDef";
import {useParams} from "react-router-dom";

/**
 * Form Build 화면 상에서 Form을 추가하려고 할 때 동작하는 Container
 * @param defVisible
 * @param setDefVisible
 * @param defItem
 * @param setDefItem
 * @returns {false|JSX.Element}
 * @constructor
 */
const FormContainer = ({defVisible, setDefVisible, defItem, setDefItem}) => {

    //Redux
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);

    //공통
    const propName = 'formDef';
    const [initialValue, setInitialValue] = useState({});
    const [selectedOID, setSelectedOID] = useState("");

    const [create, setCreate] = useState(true);
    const [languages, setLanguages] = useState({});

    //컴포넌트에 따라 필요한 정보
    const [formType, setFormType] = useState([]);
    const [reportDesignType, setReportDesignType] = useState([]);
    const [formDef, setFormDef] = useState(initialValue); //현재 Form에 대한 정보
    const [yesOrNo, setYesOrNo] = useState([]); //현재 Form에서 Yes/No 선택하는 정보

    useEffect(() => {
        if (metaDataStore.loaded === true) {
            setInitialValue(metaDataStore.initialValue.item);
            setFormType(metaDataStore.formType);
            setReportDesignType(metaDataStore.reportDesignType);
            setYesOrNo(metaDataStore.yesOrNo);
            setLanguages(metaDataStore.languages);
        }

        if (defItem !== null) { //Update
            setSelectedOID(defItem.oid);
            setFormDef(defItem);
            setCreate(false);
        } else if (defItem === null) { //New
            setFormDef(initialValue);
            setSelectedOID("");
            setCreate(true);
        }

    }, [metaDataStore, defItem]);

    //데이터 등록/수정 함수
    const onFinish = (newFormDef) => {
        descriptionCheck(newFormDef); //description nullCheck 후, 없으면 삭제
        newFormDef.repeating = 'NO'; // YSH :: Repeating NO로 고정. (2022-02-23)
        newFormDef.attr = propName;
        newFormDef.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929

        if (create) {
            dispatch(metadataActions.create(newFormDef));
        } else {
            //const idx = metaDataVersions[selectedVal.verIndex][propName].findIndex(o => o.oid === itemGroupDef.oid);
            dispatch(metadataActions.update(newFormDef));
        }

        onCancel(); //Drawer 화면 사라짐.
    };

    const onCancel = () => {
        setDefItem(null);
        setDefVisible(false);
    }

    return (
        (selectedOID !== "" || create == true) && (
            <FormDef formDef={formDef} setFormDef={setFormDef}
                     selectedOID={selectedOID} visible={defVisible}
                     languages={languages} formType={formType}
                     reportDesignType={reportDesignType} yesOrNo={yesOrNo}
                     onFinish={onFinish} onCancel={onCancel}/>
        )
    );
}

export default FormContainer;
