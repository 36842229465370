import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import axios from "axios";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {CardBody, CardFooter, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {Button, Col, Form, Input, Row, Spin, Typography} from "antd";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {CloseOutlined, LoadingOutlined, RightOutlined} from "@ant-design/icons";
import {Table} from "reactstrap";
import {Notice} from "../../../../container/build-tool/StyleBox";
import {useTranslation} from "react-i18next";

const {Text} = Typography;

const ManageUsersSearch = () => {

    const navigate = useNavigate();
    const params = useParams();
    const [form] = Form.useForm();
    const {t} = useTranslation()

    const isEmail = /^[a-zA-Z0-9]([\w\.\_\-])*@([a-zA-Z0-9_-]+\.)+[a-zA-Z0-9]{2,8}$/;

    //User state
    const [users, setUsers] = useState([]);
    const [studyUsers, setStudyUsers] = useState([]);
    const [keyword, setKeyword] = useState(null);

    //Button Loading
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);

        try {
            //REST API Request
            const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/users/search`, {params: values});
            //State Setting
            if (status === httpStatus.ok) {
                setStudyUsers(data.studyUsers);
                setUsers(data.users);
                setKeyword(values?.q);
            }
        } catch (error) {
            errorHandle(error, navigate, `/studies/${params.sid}/users`);
        }

        setLoading(false);
    }

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    return (
        <DefaultCard>
            <CardHeader>
                <h3><RightOutlined className={'mr-2'}/>User</h3>
            </CardHeader>
            <Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <CardBody>
                    <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                        <Col span={16}>
                            <Form.Item name={'q'} label={t('column.email.or.name')} rules={[
                                {
                                    required: true,
                                    message: t('message.pls.enter.keyword')
                                },
                                {
                                    min: 2,
                                    message: t('message.keyword.must.least.two.character')
                                }
                            ]}>
                                <Input.Search loading={loading} onSearch={() => form.submit()} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className='help-block'>
                                <Text strong className='text-info' >
                                    <i className='fa fa-exclamation-circle mr-2'/>
                                    {t('studies.pls.search.user.with.email.or.name')}
                                </Text>&nbsp;
                                <Text strong type={'danger'}  >
                                    {t('studies.register.new.user.search.email')}
                                </Text>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                            {
                                loading &&
                                <Col span={24}>
                                    <Notice>
                                        <Spin size="large" indicator={<LoadingOutlined style={{fontSize: 64}} spin/>} />
                                    </Notice>
                                </Col>
                            }
                            {
                                !loading && (studyUsers.length > 0) &&
                                <Col span={24}>
                                    <hr className='my-4'/>
                                    <h6 className="text-warning font-weight-bold mr-2"><i className="fa fa-exclamation-circle"></i> {t('studies.already.registered.user')}</h6>
                                    <Table striped hover>
                                        <thead>
                                        <tr>
                                            <th className="col-4">{t('input.placeholder.email')}</th>
                                            <th className='col-2'>{t('profile.last.name')}</th>
                                            <th className='col-2'>{t('profile.middle.name')}</th>
                                            <th className='col-2'>{t('profile.first.name')}</th>
                                            <th className="col-2">#</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            studyUsers.map((studyUser) =>
                                                <tr key={studyUser.id}>
                                                    <td>{studyUser?.user.username}</td>
                                                    <td>{studyUser?.user.lastName}</td>
                                                    <td>{studyUser?.user?.middleName??''}</td>
                                                    <td>{studyUser?.user.firstName}</td>
                                                    <td>
                                                        <Link to={`/studies/${params.sid}/users/edit?id=${studyUser?.id}`}>
                                                            <i className="fa fa-external-link"></i> {t('column.verify.info')}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                            }
                            {
                                !loading && (users.length > 0) &&
                                <Col span={'24'}>
                                    <hr className='my-4'/>
                                    <h6>{t('studies.user.info.found')}</h6>
                                    <Table striped hover>
                                        <thead>
                                        <tr>
                                            <th className="col-4">{t('input.placeholder.email')}</th>
                                            <th className='col-2'>{t('profile.last.name')}</th>
                                            <th className='col-2'>{t('profile.middle.name')}</th>
                                            <th className='col-2'>{t('profile.first.name')}</th>
                                            <th className="col-2">#</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            users.map((user) =>
                                                <tr key={user.id}>
                                                    <td>{user.username}</td>
                                                    <td>{user.lastName}</td>
                                                    <td>{user?.middleName??''}</td>
                                                    <td>{user.firstName}</td>
                                                    <td>
                                                        <Button
                                                            onClick={() => navigate(`/studies/${params.sid}/users/edit?userId=${user.id}`)}>
                                                            <i className="fa fa-check"></i> {t('btn.text.select')}
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                            }
                            {
                                !loading && studyUsers.length === 0 && users.length === 0 && keyword !== null &&
                                    <Col span={'24'}>
                                        <hr className='my-4'/>
                                        <span className="text-danger font-weight-bold mr-2">
                                            {t('studies.no.user.found')}
                                        </span>
                                        {
                                            form.getFieldValue('q').match(isEmail) &&
                                            <Link to={`/studies/${params.sid}/users/edit?username=${keyword}`}>
                                                <i className="fa fa-plus"></i> {t('studies.new.registration')}
                                            </Link>
                                        }
                                    </Col>
                            }
                    </Row>
                </CardBody>
                <CardFooter className='text-right'>
                    <Button type={'default'} onClick={() => {
                        navigate(`/studies/${params.sid}/users`);
                    }}><CloseOutlined />Cancel</Button>
                </CardFooter>
            </Form>
        </DefaultCard>
    )
}

export default ManageUsersSearch;