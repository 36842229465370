import React from 'react'
import {Helmet} from 'react-helmet-async'
import Error404 from '@vb/sscomponents/Errors/404'
import AuthLayout from "../../../layouts/Auth";

const System404 = () => {
    return (
        <AuthLayout authPagesColor={'image'}
                    descr={'BUILDER'}
                    isAuthTopbar={true}
                    isGrayTopbar={false}>
            <Helmet>
                <title>Page 404</title>
            </Helmet>
            <Error404/>
        </AuthLayout>
    )
}

export default System404
