import {Button} from "antd";
import React, {useState, useEffect} from "react";
import style from "./style.module.scss"
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../lib/ODMUtils";
import {SettingFilled} from "@ant-design/icons";

const ScheduleFooter = ({isProtocol = false, onReset, onSave}) => {

    const metaDataStore = useSelector(({metaDataStore}) => (metaDataStore));
    const [useRandomization, setUseRandomization] = useState(false);
    const isProd = isEnvProduction(metaDataStore);

    useEffect(() => {
        if(isProtocol) {
            const mdv = metaDataStore.study.metaDataVersion[0];
            setUseRandomization(metaDataStore.studyInfo?.useRandomization);
        }
    }, []);

    return (
        <div className={`card-footer text-right ${style.stickyBottom}`}>
            {
                useRandomization &&
                <Link to={'study-structure'}>
                    <Button className={'mr-3'}>
                        <i className='fa fa-info-circle mr-2' /> Study Structure
                    </Button>
                </Link>
            }
            {
                !isProd &&
                <>
                    <Button type={'default'} className={'mr-3'} onClick={onReset}>
                        <i className='fa fa-refresh mr-2' /> Reset
                    </Button>

                    <Button type={'primary'} onClick={onSave} >
                        <i className='fa fa-save mr-2' />Save
                    </Button>
                </>
            }
        </div>
    );
}

export default ScheduleFooter;