import ODMTabs from "../../../layouts/Tab/odm";
import {Helmet} from "react-helmet-async";
import React from "react";
import VisitWindowContainer from "../../../container/odm/VisitWindowContainer";

const VisitWindowPage = () => {
    return (
        <ODMTabs menu={'visit-window'}>
            <Helmet title={`ODM Designer v2.0.0 :: Visit Window`}/>
            <VisitWindowContainer />
        </ODMTabs>
    )
}
export default VisitWindowPage;