import {Helmet} from "react-helmet-async";
import React from "react";
import ManageUsers from "../../../../components/common/builder/studies/ManageUsers";
import ManagementTabs from "../../../../layouts/Tab/management";

const ManageUsersPage = () => {

    return (
        <ManagementTabs menu={'users'}>
                <Helmet title={'BUILDER :: Manage Users'}/>
                <ManageUsers/>
        </ManagementTabs>
    )
}

export default ManageUsersPage;