import {useNavigate, useParams} from "react-router-dom";
import {Nav, NavItem, NavLink, TabContent} from "reactstrap";
import classNames from "classnames";
import React from "react";
import '../style.scss';

const CRFVersionTabs = ({children, menu}) => {

    const params = useParams();
    const navigate = useNavigate();

    return (
        <>
            <Nav tabs className={`crf-version-tabs`}>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/versions`)}
                             className={classNames({active: menu === 'versions'})}>
                        <i className='fe fe-check-square mr-2'/>CRF Versions
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/basic-definition`)}
                             className={classNames({active: menu === 'basic-definition'})}>
                        <i className='fe fe-user mr-2'/>Basic Definition
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/default-ecs`)}
                             className={classNames({active: menu === 'default-ecs'})}>
                        <i className='fa fa-question-circle-o mr-2'/>Default ECS
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/page-setting`)}
                             className={classNames({active: menu === 'page-setting'})}>
                        <i className='fa fa-th mr-2'/>Page Setting
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className={`p-3 content`}>
                {children}
            </TabContent>
        </>
    )
}

export default CRFVersionTabs
