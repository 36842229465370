import {Helmet} from "react-helmet-async";
import React from "react";
import ManageSites from "../../../../components/common/builder/studies/ManageSites";
import ManagementTabs from "../../../../layouts/Tab/management";

const ManageSitesPage = () => {
    
    return (
        <ManagementTabs menu={'sites'}>
                <Helmet title={'BUILDER :: Manage Sites'}/>
                <ManageSites/>
        </ManagementTabs>
    )
}

export default ManageSitesPage;