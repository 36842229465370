import React from 'react'
import { Helmet } from 'react-helmet-async'
import ForgotPassword from '@vb/sscomponents/Auth/ForgotPassword'
import AuthLayout from "../../../layouts/Auth";

const SystemForgotPassword = () => {
  return (
    <AuthLayout authPagesColor={'image'}
                descr={'BUILDER'}
                isAuthTopbar={true}
                isGrayTopbar={false}>
      <Helmet title="Forgot Password" />
      <ForgotPassword />
    </AuthLayout>
  )
}

export default SystemForgotPassword
