import {useEffect, useState} from "react";
import {Button, Popconfirm} from "antd";
import {useSelector} from "react-redux";
import {useLocation, useMatch, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import Request from "../../../../lib/axiosConfig";
import axios from "axios";
import {withTranslation} from "react-i18next";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {SIGNOUT} from "../../../../components/constant/responseType";

const SystemTimer = ({user, t}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);

    const [minutes, setMinutes] = useState(user.user !== null?parseInt(user.user.sessionTimeout):-1);
    const [seconds, setSeconds] = useState(0);
    const [pause, setPause] = useState(false); //세션정보 만료 시, 타이머를 일시정지 시킬 상태값
    const [isLogin, isLogout] = [useMatch('/login'), useMatch('/logout')];

    const isTimeout = (m, s) => {
        //Timeout Seconds (5m)
        const timeout = 300;
        const currentTime = m * 60 + s;

        return currentTime < timeout;
    }

    const timeoutReset = () => {
        if(!(isLogin || isLogout)) {
            (async() => {
                try {
                    console.log(location.pathname);
                    const {data} = await axios({
                        url: "/api/session-refresh",
                        method: 'get',
                        params: {r: Math.random()}
                    });

                    //Session Check 결과가 false이면, 확인요청화면 출력 (단 로그아웃 할때는 요청하지 않음)
                    if (data?.authorized) {
                        // sessionCheckDialog();
                        setMinutes(parseInt(user.user.sessionTimeout?.minute || 0));
                        setSeconds(0);
                    }
                } catch(e) {
                    errorHandle(e);
                }
            })();
        }
    }

    //Session Check 함수 동작.
    const sessionCheck = () => {
        (async() => {
            //Session Logout처리.
            await axios.post("/api/logout");
        })();
        
        sessionCheckDialog();
    }

    const sessionCheckDialog = () => {
        let isForceLogin = 0;

        Swal.fire({
            title: t('profile.session.expired'),
            html: `<div class="alert alert-primary">` + t('profile.session.continue.password') + `</div>`,
            input: "password",
            inputPlaceholder: 'Input a Password...',
            inputAttributes: {
                autocomplete: 'off'
            },
            showCancelButton: true,
            cancelButtonText: 'Logout',
            confirmButtonText: 'Login',
            showLoaderOnConfirm: true,
            preConfirm: (password) => {
                if(password === '') {
                    Swal.showValidationMessage(t('profile.session.enter.password'));
                    return;
                }
                const username = sessionStorage.getItem("builder.username");

                return Request({
                    url: "/login",
                    params: { username, password, forceLogin:isForceLogin },
                    method: 'post'
                }).then( response => {
                    if(response.data.code === 300) {
                        isForceLogin = 1;
                        throw new Error(response.data.message);
                    }
                    else if(response.data.code === 500) {
                        throw new Error(response.data.message);
                    }
                    return response;
                }).catch(
                    error => {
                        Swal.showValidationMessage(error.message);
                    })
            },
            allowOutsideClick: false
        }).then((result) => {
            //time.over값 삭제.
            if(result.isConfirmed) {
                setPause(false);
                timeoutReset();
            } else {
                navigate('/logout', {replace:true});
            }
        });
    }

    useEffect(() => {
        if(!pause) {
            const countdown = setTimeout(() => {
                if(seconds > 0) {
                    setSeconds(seconds-1);
                }
                if(seconds == 0) {
                    if(minutes === 0) {
                        setPause(true);
                        sessionCheck();
                    } else {
                        setSeconds(59);
                        setMinutes(minutes-1);

                        //1분 마다 요청.
                        (async() => {
                            const {data} = await axios({
                                url: "/api/session-refresh",
                                method: 'get',
                                params: {r: Math.random()}
                            });

                            //Session Check 결과가 false이면, 확인요청화면 출력
                            if(data?.authorized === false) {
                                setPause(true);
                                sessionCheckDialog();
                            }
                        })();
                    }
                }
            }, 1000);

            return () => {
                clearTimeout(countdown);
            }
        }
    }, [minutes, seconds]);

    //Location 변경 시점 마다 timeoutReset시킴.
    useEffect(() => {
        //이동하는 경로가 로그인이거나 로그아웃이면 처리하지 않음
        timeoutReset();
    }, [location])

    //metaDataStore값이 변결될 때마다 timeoutReset시킴
    useEffect(() => {
        timeoutReset();
    }, [metaDataStore])


    return (
        <Popconfirm
            placement={'left'}
            title={<>{t('timer.timeout.before')}<strong className='text-primary'>{`[${minutes}:${seconds < 10?`0${seconds}`:seconds}]`}</strong>.</>}
            okText={'Reset'} cancelButtonProps={{style: {display: 'none'}}}
            open={isTimeout(minutes, seconds)}
            onConfirm={timeoutReset}
        >

            <Button type={"link"} onClick={timeoutReset}>
                <i className='fe fe-clock'></i>&nbsp;
                {minutes}:{seconds < 10?`0${seconds}`:seconds}
            </Button>
        </Popconfirm>
    )

}

export default withTranslation()(SystemTimer);

