import ODMTabs from "../../../layouts/Tab/odm";
import {Helmet} from "react-helmet-async";
import React from "react";
import SubjectFieldMapContainer from "../../../container/odm/SubjectFieldMapContainer";

const SubjectFieldMapPage = () => {
    return (
        <ODMTabs menu={'subject-field-map'}>
            <Helmet title={`ODM Designer v2.0.0 :: Subject Field Map`}/>
            <SubjectFieldMapContainer />
        </ODMTabs>
    )
}
export default SubjectFieldMapPage;
