import {Button} from "antd";
import style from "../../../../../container/build-tool/content/style.module.scss";
import React from "react";

const LibraryDefinitionSubmit = ({onReset, onCancel, onSubmit}) => {
    return (
        <div className="pad-all p-3">
            <Button className={`mr-2 ${style.btnShadow}`} onClick={() => onReset()} >
                <i className='fa fa-refresh mr-2'/>Reset
            </Button>
            <Button className={`mr-2 ${style.btnShadow}`} onClick={() => onCancel()}>
                <i className='fa fa-list-ul mr-2' />List
            </Button>
            <Button className={style.btnShadow} type={'primary'} onClick={() => onSubmit()}>
                <i className='fa fa-check mr-2' />Save
            </Button>
        </div>
    )
}

export default LibraryDefinitionSubmit;