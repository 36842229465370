import {Form, Select, Tag} from 'antd';
import React from "react";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../../lib/ODMUtils";
import {useTranslation} from "react-i18next";

const {Item} = Form;

const FormSelectDataTypes = ({required = true, name = "dataType", label = "Data Type", dataTypes, onChange, disabledOption = "", value}) => {

    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const {t} = useTranslation();

    return (
        <Item name={name} label={<span className='text-primary'>{label}</span>}
              rules={[{required: required, message: t('message.warning.required-entry')}]}
              required={required}>
            {
                isProd?(
                    <Tag className={'p-2'}>
                        <span className={"font-size-16 font-weight-bold"}>
                            {dataTypes.find(data => data.value === value)?.label||'N/A'}
                        </span>
                    </Tag>
                ):(
                    <Select onChange={onChange} options={dataTypes}></Select>
                )
            }
        </Item>
    )

}

export default FormSelectDataTypes;
