import {Form, Select, Tag} from "antd";
import {isEmpty} from "../../../../../lib/StringUtils";
import React from "react";
import {useTranslation} from "react-i18next";

const {Item} = Form;
const {Option} = Select;

const LibraryFormSelectDataType = ({required = true, name = "dataType", label = "Data Type", icons = null, dataTypes, onChange, disabledOptions = null, value}) => {

    const {t} = useTranslation();

    return (
        <Item name={name} label={<span className='text-primary'>{label}</span>}
              rules={[{required: required, message:t('message.this.is.required.selection')}]}
              required={false}>
            <Select onChange={e => onChange !== undefined ? onChange(e) : null}>
                <Select.Option value={""}>...</Select.Option>
                {
                    Object.keys(dataTypes).map(key => (
                        <Option key={key} value={key} disabled={(!isEmpty(disabledOptions) && disabledOptions.includes(key))}>
                            {icons != null && (<>{icons[key]}&nbsp;</>)}
                            {dataTypes[key]}
                        </Option>
                    ))
                }
            </Select>
        </Item>
    )
}

export default LibraryFormSelectDataType;