import {Form, InputNumber} from "antd";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../../lib/ODMUtils";

const {Item} = Form;

const FormInputNumber = ({style, required, name, label, readOnly, rules, onKeyup, min = null, max = null}) => {

    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);

    const onChange = (value) => {
        console.log(typeof value, " : ", value);
    }

    return (
        <Item required={required} name={name} label={<span className={'text-primary'}>{label}</span>} rules={rules} >
            <InputNumber style={style} readOnly={isProd || readOnly} onKeyUp={onKeyup !== undefined ? onKeyup : null} onChange={onChange} min={min} max={max} />
        </Item>
    )
}

export default FormInputNumber;