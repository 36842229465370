import React, {useEffect, useState} from "react";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle, onSearch} from "../../../../lib/BuilderUtils";
import {Link, useNavigate, useParams} from "react-router-dom";
import {CardBody, CardFooter, CardHeader, PrimaryCard} from "../../../../pages/PageStyleBox";
import {Button, Dropdown, Input, Menu, message, Table, Tooltip} from "antd";
import {
    CaretUpOutlined,
    DeleteOutlined,
    EditOutlined,
    InfoCircleOutlined,
    RightOutlined,
    SyncOutlined
} from "@ant-design/icons";
import {isEmpty} from "../../../../lib/StringUtils";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";

const RoleList = () => {

    const navigate = useNavigate();
    const params = useParams();
    const [roles, setRoles] = useState(null);
    const [baseData, setBaseData] = useState(null);
    const [filterData, setFilterData] = useState(null);
    const [studyRNPMap, setStudyRNPMap] = useState(null);
    const [studyUserRoleMap, setStudyUserRoleMap] = useState(null);
    const {t} = useTranslation();

    const columns = [
        {
            title: 'No',
            dataIndex: 'id',
            key: 'id',
            align: "center",
            sorter: {compare: (a, b) => a.id>b.id?1:-1},
            render: (row, record, index) => {
                return (filterData !== null?filterData:baseData).length-(filterData !== null?filterData:baseData).indexOf(record);
            }
        },
        {
            title: 'Abbreviation Name',
            dataIndex: 'shortName',
            key: 'shortName',
        },
        {
            title: 'Name',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'Order',
            dataIndex: 'displayOrder',
            key: 'displayOrder',
            align: "center",
            sorter: {compare: (a, b) => a.displayOrder>b.displayOrder?1:-1},
            render: (row, record, index) => (isEmpty(row)?'-':row)
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: {compare: (a, b) => new Date(a.createdDate)>new Date(b.createdDate)?1:-1},
        },
        {
            title: 'Actions',
            align: "center",
            dataIndex: 'id',
            key: 'actions',
            render: (row, record, index) => {
                return (
                    <>
                    <Link to={`/studies/${params.sid}/roles/edit?id=${row}`}>
                        <Button icon={<EditOutlined/>} className={'mr-2'}>Edit</Button>
                    </Link>
                        {
                            (studyRNPMap !== null && studyRNPMap[row] ) && (studyUserRoleMap !== null && studyUserRoleMap[row]) &&
                            <Button icon={<DeleteOutlined />} onClick={() => onRemove(row)}>
                                Remove
                            </Button>
                        }
                    </>
                )
            }
        }
    ]

    useEffect( () => {

        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/roles`);
                if (status === httpStatus.ok) {
                    setBaseData(data.roleList);
                    setRoles(data.roles);
                    setStudyRNPMap(data.studyRNPMap);
                    setStudyUserRoleMap(data.studyUserRoleMap);
                }
            } catch (e) {
                errorHandle(e, navigate);
            }
        })();

    }, []);


    //New Roles Context Menu
    const menu = {
        items: [
            ...(roles !== null)?roles.map((role, index) => ({
                key: role.id,
                label: <Link to={`/studies/${params.sid}/roles/edit?roleId=${role.id}`}>{role.displayName}</Link>
            })):[]
        ]
    }

    const onSync = async () => {
        setBaseData(null);

        try{
            setBaseData(null);
            const {status, data} = await axios.post(`/api/builder/studies/${params.sid}/roles/sync`);

            if (status === httpStatus.ok) {
                //Sponsor Data Sync
                setBaseData(data.roleList)
                message.success(data?.message);
            }
        } catch (e) {
            errorHandle(e);
            setBaseData(Array.of([]));
        }
    }

    const onRemove = async (id) => {

        try {
            const res = await Swal.fire({
                icon: 'question',
                iconColor: 'red',
                title: t('studies.role.delete'),
                confirmButtonColor: '#ec1818',
                confirmButtonText: t('btn.text.delete'),
                cancelButtonText: t('btn.text.cancel'),
                showCancelButton: true,
                html:t('studies.check.delete.role')
            });

            if(res.isConfirmed) {
                setBaseData(null);

                const {status, data} = await axios({
                    url:`/api/builder/studies/${params.sid}/roles/remove`,
                    method:'delete',
                    params:{id},
                })

                if(status === httpStatus.ok) {
                    setBaseData(data.roleList);
                    message.success(data.message);
                }
            }

        } catch (e) {
            errorHandle(e);
        }
    }

    return (
        <PrimaryCard>
            <CardHeader>
                <div className={'row'}>
                    <div className={'col-8'}>
                        <h3><RightOutlined className={'mr-2'} />Role</h3>
                    </div>
                    <div className={'col-4'}>
                        <Input.Search
                            placeholder="Search by..."
                            onSearch={(value) => onSearch(baseData, setFilterData, value)}
                            enterButton
                        />
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                <Table columns={columns} // Columns Info
                       rowKey={row => row.id} // Row Key Value
                       dataSource={filterData === null ? baseData : filterData} // Data Source
                       pagination={{ pageSize: 25 }} // Pagination
                       loading={!baseData}  // Data Loading 처리
                       bordered={true}
                />
            </CardBody>
            <CardFooter className='text-right'>
                {
                    (baseData instanceof Array && baseData.length <= 0) &&
                    <Button type={'default'} className={'mr-2'} loading={!baseData} icon={<SyncOutlined/>} onClick={onSync}>
                        Sync
                        <Tooltip title={
                            <>
                                <div>{t('studies.retrieve.role.info1')}</div>
                                <div>{t('studies.retrieve.role.info2')}</div>
                            </>
                        }>
                            <InfoCircleOutlined/>
                        </Tooltip>
                    </Button>
                }
                <Dropdown menu={menu} trigger={'click'}>
                    <Button> Add new Role <CaretUpOutlined /> </Button>
                </Dropdown>
            </CardFooter>
        </PrimaryCard>
    )
}

export default RoleList;
