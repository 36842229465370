import React from "react";
import {Helmet} from "react-helmet-async";
import StudiesList from "../../../components/common/builder/studies/StudiesList";


const StudiesPage = () => {

    return (
        <>
            <Helmet title={'BUILDER :: Studies'}/>
            <StudiesList />
        </>
    )
}

export default StudiesPage;