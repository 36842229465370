import React from "react";
import {RefItemCard} from "../../../container/build-tool/StyleBox";
import style from './style.module.scss';
import RefControlOption from "../../../container/build-tool/content/RefControlOption";
import {Typography} from "antd";

const {Text} = Typography;

/**
 * @author sh.yang@safesoft.co.kr
 * @since 2021-08-15
 * @description Schedule Ref를 표시하는 Card Component
 * @param refType - Reference Data Type (FormRef, StudyEventRef, StudyEventGroupRef)
 * @param metaData - Ref Data에서 필요한 Def정보를 불러올 때 사용되는 metaData 정보
 * @param refItem - reference Data 값
 * @param refInfo - reference Data의 실제 Def 정보 값
 * @param dragging - 현재 Dragging 된 상태인지 표시 (true / false)
 * @param isUse - 현재 사용중인 Reference 값인지 확인 (isUse에 따라서 reference option 활성화 내용이 달라짐)
 * @returns {JSX.Element}
 * @constructor
 */
const ScheduleRefCard = ({refType, metaData, refItem, refInfo, dragging, defOID = null,
                             toggleMandatory, onDeleteRef, onChangeCondition }) => {

    return (
        <RefItemCard title={
            <>
                <div className='text-left'><Text style={{fontSize:8, color:'#4b7cf3'}} strong>{refInfo?.oid??'Loading...'}</Text></div>
                <Text>
                    {refItem?.mandatory === 'YES' && <sup className='text-danger'>*</sup>}
                    {refInfo?.name}
                </Text>
            </>
                    }
                     dragging={dragging?1:0}
        >
            {
                (metaData !== null && refType === 'formRef') &&
                <>
                    {
                        refInfo?.itemGroupRef?.length > 0 ?
                            (
                                <>
                                    {
                                        refInfo?.itemGroupRef.map((itemGroup, index) => (
                                            <div key={index}>
                                                {
                                                    index < 2 && (
                                                        <>
                                            <span className={style.ellipsisSpan}>
                                                {metaData.itemGroup.get(itemGroup.itemGroupOID).name}
                                            </span>
                                                            <hr className='my-1'/>
                                                            <hr className='my-1'/>
                                                            <hr className='my-1'/>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                    {
                                        refInfo?.itemGroupRef?.length > 2 &&
                                        <span>...</span>
                                    }
                                </>
                            ) : (
                            <span className={'text-danger'}>
                                No content.
                            </span>
                        )
                    }
                </>
            }
            {
                (metaData !== null && refType === 'studyEventRef') &&
                <>
                    {
                        refInfo?.formRef?.length > 0 ? (
                            <>
                                {
                                    refInfo?.formRef.map((form, index) => (
                                        <div key={index}>
                                            {
                                                index < 2 && (
                                                    <>
                                            <span className={style.ellipsisSpan}>
                                                {metaData.form.get(form.formOID).name}
                                            </span>
                                                        <hr className='my-1'/>
                                                        <hr className='my-1'/>
                                                        <hr className='my-1'/>
                                                    </>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                                {
                                    refInfo?.formRef?.length > 2 &&
                                    <span>...</span>
                                }
                            </>
                            ) : (
                            <span className={'text-danger'}>
                                No content.
                            </span>
                        )
                    }
                </>
            }
            {
                defOID !== null &&
                <RefControlOption metaData={metaData} defOID={defOID} refInfo={refInfo} refItem={refItem} refType={refType}
                                  onDeleteRef={onDeleteRef} toggleMandatory={toggleMandatory} onChangeCondition={onChangeCondition} />
            }
        </RefItemCard>
    )
}

export default ScheduleRefCard;
