import {Form, Input} from "antd";
const {Item} = Form;

const LibraryFormInput = ({style, required, type=null, name, label, readOnly, rules, onKeyup}) => {

    return (
        <Item required={required} name={name}
              label={<span className={'text-primary'}>{label}</span>} rules={rules}>
            <Input type={type} style={style} readOnly={readOnly} onKeyUp={onKeyup !== undefined ? onKeyup : null} />
        </Item>
    )
}

export default LibraryFormInput;