import React, {useEffect, useState} from "react";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {Link, useNavigate, useParams} from "react-router-dom";
import {CardBody, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import DefaultLoading from "../../DefaultLoading";
import {Button, Col, Form, Image, Input, List, message, Popover, Row, Select, Table, Tag, Typography} from "antd";
import {RightOutlined} from "@ant-design/icons";
import {Notice} from "../../../../container/build-tool/StyleBox";
import _ from 'lodash';
import {useTranslation} from "react-i18next";


const {Text} = Typography;

const RolesLearningCourse = () => {

    const params = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [roles, setRoles] = useState(null);
    const [learningCourses, setLearningCourses] = useState(null);
    const [learningContentsMap, setLearningContentsMap] = useState(null);
    const [rolesLearningCourseMap, setRoleLearningCourseMap] = useState(null);
    const [rolesLearningCourseAbleMap, setRolesLearningCourseAbleMap] = useState(null);

    const [form] = Form.useForm();

    useEffect(() => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/roles-learning-course`);

                if (status === httpStatus.ok) {
                    //init data
                    const initRoles = data?.roles ?? [];
                    const initLearningCourses = data?.learningCourses??[];
                    const initRoleLearningCourseMap = data?.studyRoleLearningCourseMap ?? {};
                    const initRolesLearningCourseAbleMap = {};

                    //rolesLearningCourseAbleMap 초기데이터 세팅
                    Object.keys(initRoleLearningCourseMap).forEach((roleId) => {
                        const learningCourse = Array.from(_.cloneDeep(data?.learningCourses)); //전체 강의 코스
                        const setLearningCourse = initRoleLearningCourseMap[roleId]; //RoleId에 등록된 강의 코스
                        const ableLearningCourse = learningCourse.filter((lc) => setLearningCourse.every((setLc) => setLc.id !== lc.id));
                        initRolesLearningCourseAbleMap[roleId] = ableLearningCourse;
                    });

                    // Role 정보
                    setRoles(initRoles);
                    // Learning Course 정보
                    setLearningCourses(initLearningCourses);
                    // 저장된 role 별 Learning Course 정보
                    setRoleLearningCourseMap(initRoleLearningCourseMap);
                    // 저장될 수 있는 Role 별 Learning Course 정보
                    setRolesLearningCourseAbleMap(initRolesLearningCourseAbleMap);
                    // Learning Course 별 Contents 정보
                    setLearningContentsMap(data?.learningContentsMap);
                    console.log(data?.learningContentsMap);

                    //Form 정보
                    const formData = initRoles.map((role) => {
                        return {
                            roleId:role.id,
                            learningCourseIds:initRoleLearningCourseMap[role.id].map(lc => lc.id),
                        }
                    })
                    form.setFieldsValue({
                        rolesLearningCourses:[...formData]
                    });
                }
            } catch (e) {
                errorHandle(e, navigate, `/studies/${params.sid}/dashboard`);
            }
        })();
    }, [])

    const getLearningCourseOptions = (lcs) => {
        return lcs?.length??0 > 0?lcs.map((lc) => ({value: lc.id, label: lc.learningCourseName})):[];
    }

    const getLearningCourseIds = (lcs) => {
        return lcs?.length??0 > 0?lcs.map((lc) => (lc.id)):[];
    }

    const getLearningContentsTooltip = (learningCourseId) => {
        return (
            <div style={{width:'50rem'}}>
                <h3>Course Contents</h3>
                <Table size={'small'} dataSource={learningContentsMap[learningCourseId]} rowKey={'id'} pagination={false}>
                    <Table.Column key={'title'} dataIndex={'title'} title={t('column.title')} align={'center'} />
                    <Table.Column key={'purpose'} dataIndex={'purpose'} title={t('column.purpose')} align={'center'} />
                    <Table.Column key={'duration'} dataIndex={'duration'} title={t('column.duration')} align={'center'} />
                    <Table.Column key={'thumbNail'} dataIndex={'thumbNail'} title={t('column.thumbNail')} align={'center'}
                                  render={(value) => (<Image src={value} width={150} />)} />
                </Table>
            </div>
        )
    }

    const onSelectLearningCourse = (roleId, learningCourseId) => {
        //Setting된 데이터 - 현재 Data가 추가되어야 함
        const cloneRolesLearningCourseMap = {...rolesLearningCourseMap};
        cloneRolesLearningCourseMap[roleId] = [...rolesLearningCourseMap[roleId], ...learningCourses.filter(lc => lc.id === learningCourseId)];

        //Setting될 데이터 - 현재 Data가 삭제되어야 함.
        const cloneRolesLearningCourseAbleMap = {...rolesLearningCourseAbleMap};
        cloneRolesLearningCourseAbleMap[roleId] = [...rolesLearningCourseAbleMap[roleId].filter(lc => lc.id !== learningCourseId)];
        //삭제 할 때, 데이터가 1개밖에 없고 undefined이면 null로 처리
        if(cloneRolesLearningCourseAbleMap[roleId].length === 1 && cloneRolesLearningCourseAbleMap[roleId][0] == null) {
            cloneRolesLearningCourseAbleMap[roleId] = null;
        }

        setRoleLearningCourseMap(cloneRolesLearningCourseMap);
        setRolesLearningCourseAbleMap(cloneRolesLearningCourseAbleMap);

        form.setFieldsValue({
            rolesLearningCourses:Object.keys(cloneRolesLearningCourseMap).map(roleId => ({roleId, learningCourseIds:getLearningCourseIds(cloneRolesLearningCourseMap[roleId])}))
        });
    }

    const onLearningCourseRemove = (roleId, learningCourseId, lcIndex) => {
        //Setting된 데이터 - 현재 Data가 삭제되어야 함
        const cloneRolesLearningCourseMap = {...rolesLearningCourseMap};
        cloneRolesLearningCourseMap[roleId].splice(lcIndex, 1); //lcIndex 항목 삭제


        //Setting될 데이터 - 현재 Data가 추가되어야 함.
        const cloneRolesLearningCourseAbleMap = {...rolesLearningCourseAbleMap};
        cloneRolesLearningCourseAbleMap[roleId] = [...rolesLearningCourseAbleMap[roleId], ...learningCourses.filter(lc => lc.id === learningCourseId)];

        setRoleLearningCourseMap(cloneRolesLearningCourseMap);
        setRolesLearningCourseAbleMap(cloneRolesLearningCourseAbleMap);

        console.log("Remove : ", cloneRolesLearningCourseMap[roleId]);

        form.setFieldsValue({
            rolesLearningCourses:Object.keys(cloneRolesLearningCourseMap).map(roleId => ({roleId, learningCourseIds:getLearningCourseIds(cloneRolesLearningCourseMap[roleId])}))
        });
    }

    const onFinish = (res) => {
        res.rolesLearningCourses.forEach((rolesLearningCourse, index) => {
            rolesLearningCourse.learningCourseIds = [...rolesLearningCourse.learningCourseIds.filter(lc => lc != null)];
        });

        (async () => {
            try {
                const {status, data} = await axios.post(`/api/builder/studies/${params.sid}/roles-learning-course`, res.rolesLearningCourses);
                if(status === httpStatus.ok) {
                    message.success(data?.message);
                }
            } catch(e) {
                errorHandle(e);
            }
        })();
    }

    const onFinishFailed = (error) => {
        console.log(error);
    }

    return (
        <>
            {
                (roles === null && learningCourses === null && rolesLearningCourseMap === null && rolesLearningCourseAbleMap === null) &&
                <DefaultLoading/>
            }
            {
                !(roles === null && learningCourses === null && rolesLearningCourseMap === null && rolesLearningCourseAbleMap === null) &&
                <DefaultCard>
                    <CardHeader>
                        <h3><RightOutlined className={'mr-2'}/>Role & Learning Course</h3>
                    </CardHeader>
                    <CardBody>
                        <Form form={form} layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Row>
                                <Col span={24}>
                                    <Form.List name={'rolesLearningCourses'}
                                               rules={[
                                                   {
                                                       validator: async (_, rolesLearningCourses) => {
                                                           // console.log(rolesLearningCourses)
                                                           const isEmptyCourse = rolesLearningCourses.every((rlc) => (rlc.learningCourseIds?.length??0) <= 0);
                                                           if(isEmptyCourse) {
                                                               return Promise.reject(new Error(t('studies.no.role.registered.learning.course')));
                                                           }
                                                       }
                                                   }
                                               ]}>
                                        {(fields, {add, remove}, {errors}) => (
                                            <>
                                                {
                                                    roles?.length > 0 ? roles.map((role, rIndex) => (
                                                        <React.Fragment key={`${role.id}`}>
                                                            <Col span={24}>
                                                                <Tag color={'default'} className={'font-size-16 mb-2'}>{role.displayName}</Tag>
                                                                <Select className={'width-100p'}
                                                                        options={getLearningCourseOptions(rolesLearningCourseAbleMap[role.id])}
                                                                        onSelect={(lcId) => onSelectLearningCourse(role.id, lcId)} />
                                                                <Form.Item hidden={true} name={[rIndex, 'roleId']}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <List grid={{gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 6, xxl: 3,}}
                                                                    style={{minHeight:'12rem'}}
                                                                    bordered className={'mb-3'}>
                                                                    {
                                                                        rolesLearningCourseMap[role.id].length > 0 &&
                                                                        rolesLearningCourseMap[role.id].map((lc, lcIndex) => (
                                                                            <Popover key={`${role.id}.${lc.id}`} title={getLearningContentsTooltip(lc.id)} placement={'bottomLeft'}>
                                                                                <List.Item className={'p-2'} style={{background: '#f9f9f9', border:'solid 1px #000', display:'flex' }}>
                                                                                    <span className={'font-size-16'}>
                                                                                        <Button size={'small'} className={'font-size-14'} onClick={() => onLearningCourseRemove(role.id, lc.id, lcIndex)}><i className='fa fa-times-circle'></i></Button>&nbsp;
                                                                                        {lc.learningCourseName}
                                                                                    </span>
                                                                                    <Form.Item hidden={true} name={[rIndex, 'learningCourseIds', lcIndex]}><Input /></Form.Item>
                                                                                </List.Item>
                                                                            </Popover>
                                                                        ))
                                                                    }
                                                                </List>
                                                            </Col>
                                                        </React.Fragment>
                                                    )) : (
                                                        <Notice>
                                                            <Text className={'font-size-24'} type={'danger'}>
                                                                <i className='fa fa-exclamation-circle mr-2'></i>
                                                                {t('studies.no.registered.role.info')}
                                                                <Link to={`/studies/${params.sid}/roles`}>
                                                                    <span className='text-info'>[<i className='fa fa-external-link'/>{t('studies.move.to.role.menu')}]</span>
                                                                </Link>
                                                            </Text>
                                                        </Notice>
                                                    )
                                                }
                                                <Col span={24}>
                                                    <Form.ErrorList errors={errors} />
                                                </Col>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                                <Col span={24} className={'text-right'}>
                                    <Button htmlType={'submit'} type={'primary'}>Save</Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </DefaultCard>
            }
        </>
    )
}

export default RolesLearningCourse;