import React from 'react'
import {Button, Form, Input, InputNumber, Select} from 'antd'
import style from '../style.module.scss'
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import {userActions} from "../../../../redux/module/user";
import {withTranslation} from "react-i18next";

const SearchSelect = styled(Select)`
  .ant-select-selection-item {
    background-color: white;
  }
`

const Profile = ({t, user }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onFinish = (values) => {
        dispatch(userActions.profileUpdate(values));
    }

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo)
    }

    return (
        <div className="mt-5 pt-2">
            <div className={`card ${style.container} ${style.profile}`}>
                <div className="text-dark font-size-32 mb-3">{t('profile.header.title')}</div>
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="mb-4"
                >
                    <div className='row'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <Form.Item name="username" label={t('profile.username')} initialValue={user.username}>
                                    <Input placeholder="Username(Email)" readOnly />
                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-4'>
                            <div className='form-group text-left'>
                                <Form.Item name="firstName" label={t('profile.first.name')}
                                           rules={[{required: true, message: 'Please input your First Name'}]}
                                           initialValue={user?.firstName}
                                >
                                    <Input placeholder="First Name" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group text-left'>
                                <Form.Item name="middleName" label={t('profile.middle.name')} initialValue={user?.middleName}>
                                    <Input placeholder="Middle Name" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group text-left'>
                                <Form.Item name="lastName" label={t('profile.last.name')}
                                           rules={[{required: true, message: 'Please input your Last Name'}]}
                                           initialValue={user?.lastName}
                                >
                                    <Input placeholder="Last Name" />
                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className={'col-12'}>
                            <Form.Item name="timeZone" label={t('profile.timezone')} initialValue={user?.timeZone} >
                                <SearchSelect showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                >
                                    {
                                        Object.keys(user?.timeZoneMap).map((timeZone, index) => (
                                            <Select.Option key={index} value={timeZone}>
                                                { user?.timeZoneMap[timeZone] }
                                            </Select.Option>
                                        ))
                                    }
                                </SearchSelect>
                            </Form.Item>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-6'>
                            <Form.Item name="sessionTimeout" label={t('profile.session.timeout')}
                                       initialValue={user?.sessionTimeout.key}>
                                <Select>
                                    {
                                        user.sessionTimeoutList.map((sessionTimeout, index) => (
                                          <Select.Option value={sessionTimeout.key} key={sessionTimeout.key}>
                                              {sessionTimeout.minute}
                                          </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='col-6'>
                            <Form.Item name="lockScreenMin" label={t('profile.lock.screen')}
                                       rules={[{required: true, message: 'Please input your Lock Screen Minute'}]}
                                       initialValue={user.lockScreenMin}>
                                <InputNumber style={{width: '100%'}} min={1} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className='col-6'>
                            <Button
                                type="default"
                                onClick={() => navigate(-1)}
                                className="text-center w-100"
                            >
                                <strong>{t('btn.cancel')}</strong>
                            </Button>
                        </div>
                        <div className='col-6'>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="text-center w-100"
                            >
                                <strong>{t('btn.update')}</strong>
                            </Button>
                        </div>
                    </div>

                </Form>
            </div>
        </div>
    )
}

export default withTranslation()(Profile);
