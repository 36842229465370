import styled, {css} from "styled-components";
import {Button as antButton, Card, Layout} from "antd";
import * as React from "react";

const { Sider } = Layout;

//Form Layout 영역
// export const FormHeader = styled(Layout.Header)`
//     background: white;
// `;

export const FormContainer = styled(Layout.Content)`
  background: white;
  min-height: calc(100vh - 255px);
  width: 70%;
  margin: 0px;
  padding: 0px;
`;

export const FormDiv = styled.div`
  width:185px;
  //heihgt:100px;
  //margin-right:5px;
`;

export const CardFormWrapper = styled.div`
  padding-left:5px;
  margin-left:10px;
    
  &:hover .card {
    background: #eaeaea;
    cursor: zoom-in;
  };
  
  &:hover .form-menu {
    display:inline !important;
  };
`;
// export const FormFooter = styled(Layout.Footer)`
//     background: white;
//     border-top: solid 0.1px;
// `;

///////////////////////////////////////////////////////////////////
//Form Content 영역
///////////////////////////////////////////////////////////////////

export const ContentWrapper = styled.div`
  border: solid 1px;
  padding-left: 0px;
  padding-right: 0px;
`;

export const ItemAddButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ItemGroupAddButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ContentDiv = styled.div`
`;


//ItemGroup
export const ItemGroupOptionDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
  flex: 1;
  opacity: ${props => (props.visible? '1' : '0')};
  transition: opacity .3s;
  
`;

export const ItemGroupExtra = styled.div`
  opacity: ${props => (props.visible? '1' : '0')};
  transition: opacity .3s;
`;

export const ItemGroupDiv = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  padding: 0.5rem;
  margin: 0 0 0.5rem 0;
  border-radius: 3px;
  background: #fff;
  width:100%;
  
  &:hover {
    border:1px dashed #ddd;
    
    ${ItemGroupOptionDiv}, ${ItemGroupExtra} {
      opacity: 1;
    }
  }
  
  border: 1px ${props => (props.isDragging ? 'dashed #ec8989' : 'solid #ddd')};
`;

export const DescriptionWrapper = styled.div.attrs({
  className: 'ml-3 mb-1'
})``

export const ItemGroupTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding : 1rem;
`;

export const ItemGroupDivider = styled.div.attrs({
  className: 'my-0 mb-2'
})`
  width: 100%;
`

export const ItemGroupTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
`;

export const ItemGroupMoveHandle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  overflow: hidden;
`;

//Item
export const ItemOptionDiv = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  background: #fafafa;
  border: 1px solid #a5b3db;
  border-radius: 3px;
  transition: all .3s;
  left: 7.5px;
  top: ${props => (props.designMode)?'35px':'20px'}; 
  z-index: 100;
  opacity: ${props => (props.visible)?'1':'0'};
`;

export const ItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  margin: 0 0 0.3rem 0;
  border-radius: 3px;
  border: 1px solid #ffffff;
  transition: padding-bottom .1s ease-in-out;
  background: #fff;
  
  ${props => (props.enable === false) && css`
    &:after {
      content: "　";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: repeating-linear-gradient(-45deg, rgba(241, 112, 112, 0.3), rgba(241, 112, 112, 0.3) 5px, rgba(231, 231, 231, 0.3) 0, rgba(231, 231, 231, 0.3) 10px);
    }
  `}
  
  &:hover ${ItemOptionDiv} {
    opacity: 1;
  }
  
  &:hover {
    border:1px dashed #1988e3 !important;
    ${props => (props.isIgnoreMarginBottom === false && css`padding-bottom: 2rem;`)
  }
  
  ${props => (props.isDragging ? 'border: 1px dashed #ec8989 !important;' : '')};
`;


//ItemGroup - NO Repeating
//Item 외부 요소
export const ItemDivBlock = styled.div`
  background: #fff;

  padding: 0.2rem 0.2rem 0;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 0.5rem 0.5rem 0.5rem;

  &:hover {

    ${ItemDiv} {
      border: 1px solid #eeeeee;
    }
  }
  
  
  ${props => (props.longHeight && css`min-height: 500px;`)}
  ${props => (props.isDraggingOver && css`background: #f0f4fd;`)}
  ${props => (props.isDraggingOver ? 'border: 1px dashed #5858ff;' : 'border: 1px solid #fff')};
`;



export const ItemTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ItemTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const CodeListBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const CodeListRefArea = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
`;

export const ItemQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //vertical-align: top !important;
  //border:1px solid red !important;
`;

export const ItemFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-self: stretch;
  padding: 0px 5px 0px 5px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

// export const Question = styled.div``;

export const Handle = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
  border-right: 1px solid #ddd;
  color: #000;
`;

export const CodeListField = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const List = styled.div`
  border: 1px ${props => (props.isDraggingOver ? 'dashed #000' : 'solid #fff')};
  background: #fff;
  padding: 0.5rem 0.5rem 0;
  border-radius: 3px;
  font-family: sans-serif;
`;

export const Container = styled(List)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 0 1.5rem;
  // ${props => (props.longHeight && css`min-height: 500px;`)}
  ${props => (props.enable != null && css`background: ${props.enable}`)};
  ${props => (props.isDraggingOver && css`
    background: #f0f4fd;
    border: 2px dashed #5858ff!important;`
  )}
  
  ${props => 
      (
          props.designMode?
          css`
            border: 2px dashed #eee;
            background: #fafafa;
          `
          :css`
            border: 2px solid #fff;
            background: #fff;`
      )
  }  
`;


//ItemGroup - YES Repeating
//Repeating Header
export const RepeatingHeader = styled.div`
  display: flex;
  //max-width: 250px;
  flex-direction: column;
  align-self: stretch;
  border-top:1px solid #dee2e6 !important;
  border-bottom:1px solid #e4e9f0 !important;
  border-right: 1px solid #f2f4f8 !important;
  border-left: 1px solid #f2f4f8 !important;
  padding: 10px;
  font-weight: 400;
  background: #f2f4f8;
  margin-bottom: 5px;
`;

export const QuestionTextWrapper = styled.div`
  max-width:220px !important;
  overflow:hidden !important;
  text-overflow:ellipsis !important;
  white-space:nowrap !important;
  font-size:0.9rem;
`;

export const ToolbarItemTextWrapper = styled.div`
  max-width:260px !important;
  overflow:hidden !important;
  text-overflow:ellipsis !important;
  white-space:nowrap !important;
  font-size:0.9rem;
`;

export const CodeListTextWrapper = styled.div`
  display: inline-block;
  max-width:220px !important;
  word-break: break-all !important;
  word-wrap: break-word !important;
  font-size:0.9rem;
`;

export const ValueListTextWrapper = styled.div`
  display: inline-block;
  max-width:180px !important;
  overflow:hidden !important;
  text-overflow:ellipsis !important;
  white-space:nowrap !important;
  font-size:0.9rem;
`;

//Item 내부 요소
export const RepeatingItemMoveHandle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-self: stretch;
  //min-height: 3rem;
  //overflow: hidden;
  //background: #eee;
  //border-bottom: dashed 1px;
  //color: #000;
  display:none;
`;

//Item - 외부 요소
export const RepeatingItemDivBlock = styled.div`
  //border: 1px ${props => (props.isDraggingOver ? 'dashed #eee' : 'solid #ddd')};
  border:1px solid #eee;
  background: #fff;
  flex:1;
  align-self: stretch;
  border-radius: 3px;
  padding:3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  //align-content: center;
  align-content: flex-start;
  justify-content: flex-start;
  margin: 0 0 1.5rem;

  ${props => (props.isDraggingOver  ? 'border:1px dashed #5858ff !important;background:#f0f4fd;' : '')}
  
  width: 100%;
  overflow-x: hidden;
  &:hover {
    overflow-x: auto;
    &::-webkit-scrollbar {
      margin-top: -8px !important;
      width: 8px;
      height: 8px;
      background: #fafafa;
      border: 1px solid #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3.5px;
      background-color: #ced4da;

      &:hover {
        background-color: #adb5bd;
      }
    }

    &::-webkit-scrollbar-track {
      background: #fafafa;
    }
  }
`;

export const ValueListItemRow = styled.div`
  border:1px solid #eee;
  background: #fff;
  border-radius: 3px;
  padding:3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin: 0 0 1.5rem;
`;

export const RepeatingItemOptionDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex:1;
  align-items: flex-end;
  transition: all .3s;
  opacity: ${props => (props.visible)?'1':'0'};
`;

export const RepeatingItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  align-self: stretch;
  min-width: fit-content;
  flex: 1;
  border-radius: 0px;
  border: 1px transparent !important; 
  border-right: 1px solid #eee !important;

  &:hover ${RepeatingItemOptionDiv} {
    opacity: 1;
  }

  &:hover {
    border-radius: 3px !important;
    border: 1px dashed #1988e3 !important;
  }

  background: #fff;
  ${props => (props.isDragging ? 'border: 1px dashed #1988e3 !important;' : '')};

  ${props => (props.enable === false) && css`
    &:after {
      content: "　";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: repeating-linear-gradient(-45deg, rgba(241, 112, 112, 0.3), rgba(241, 112, 112, 0.3) 5px, rgba(231, 231, 231, 0.3) 0, rgba(231, 231, 231, 0.3) 10px);
    }
  `}
`;


export const RepeatingItemTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
`;

export const RepeatingItemTitleArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3px;
`;


export const RepeatingCodeListRefArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-content: center;
  align-items: flex-start;
  margin-bottom: 3px;
`;

export const RepeatingQuestion = styled.div`
  display: flex;
  align-content: flex-start;
  align-self: stretch;
`;

export const RepeatingItemFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding:5px;
  flex:1;
`;

export const RepeatingOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-end;
  background: ${props => (props.white ? 'white' : 'rgb(255, 0, 0, 0.3)')};
  align-self: stretch;
  padding: 0px 5px 0px 5px;
`;

///////////////////////////////////////////////////////////////////
//Item ToolBar 영역
///////////////////////////////////////////////////////////////////

export const StickyDiv = styled.div`
  position:sticky;
  position: -webkit-sticky;
  top:0px;
  margin:5px;
  z-index: 99;
  
  & .card-header {
    background-color: #fff !important;
  }
`;

export const FormBuilderSider = styled(Sider).attrs(Sider.defaultProps)`
  max-width: 400px !important;
  min-width: 400px !important;
  width: 400px !important;
  padding:0 10px 5px 10px !important;
  margin:0px !important;
  background-color: #ffffff;
  border:2px solid #eeeeee; 
  
  & .ant-tabs-tab {
    margin:0 5px 0 0 !important;
  }
`;

export const ToolbarItemList = styled(List)`
  height: calc(100vh - 350px);
  overflow-y: auto;
  padding:2px;
  border:1px solid #eeeeee;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background-color: #ced4da;

    &:hover {
      background-color: #adb5bd;
    }
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }
`;

export const RefItemList = styled(List)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;

  padding-left: ${props => (props.protocol?'1.5rem':'2.5rem')};
  height: calc(100vh - 300px);
`

export const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 15px;
  margin-bottom:10px;
  width: 100%;
  border: 2px dashed #eee;
  line-height: 1.5;
  color: ${props => (props.white ? '#fff' : '#aaa')};
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0.5rem;
  padding: 0.5rem;
  color: #000;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 3px;
  font-size: 1rem;
  cursor: pointer;
`;

export const ItemOptions = styled.div`
  opacity: 0;
  transition: opacity 0.3s;
  flex:1;
`;

export const ToolbarItemWrapper = styled.div`
  display: flex;
  user-select: none;
  padding: 0.4rem 0.2rem 0.2rem 0.2rem;
  margin: 0 0 0.1rem 0;
  align-items: flex-start;
  //align-content: flex-start;
  line-height: 1.5;
  border-radius: 3px;
  background: #fff;
  border: 2px ${props => (props.isDragging ? 'dashed #4099ff' : 'solid #ddd')};

  ${props => (props.enable === false && css`
    &:after {
      content: "　";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: repeating-linear-gradient(-45deg, rgba(241, 112, 112, 0.3), rgba(241, 112, 112, 0.3) 5px, rgba(231, 231, 231, 0.3) 0, rgba(231, 231, 231, 0.3) 10px);
      z-index: 100;
    }
    
    //&:before {
    //  content: "This is Duplicated !";
    //  color: red;
    //  font-weight: 800;
    //  font-size: 24px;
    //  display: block;
    //  text-align: center;
    //  vertical-align: middle;
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  top: -40px;
    //}
  `)};
  
  &:hover ${ItemOptions}{
    opacity: 1;
  }
`;

export const Clone = styled(ToolbarItemWrapper)`
  + div {
    display: none !important;
  }
`;

export const FormBtn = styled(antButton).attrs({
    ...antButton.defaultProps,
})`
  background: ${props => (props.color)};
  border-radius: 2.5rem;
`;

export const CodeListsWrapper = styled.div`
  ${props => (props.isDraggingOver && css`
    border: dashed 1px red;
    
  `)}
`;

export const CodeListsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem;
  
  background: ${props => (props.isDragging) ? 'aliceblue' : 'white'};
  border: ${props => (props.isDragging) ? 'dashed 0.1px' : 'solid 0.1px rgba(0,0,0,0.05)'};
`;


//Schedule
export const ScheduleSider = styled(Sider).attrs(Sider.defaultProps)`
  max-width: 400px !important;
  min-width: 400px !important;
  width: 400px !important;
  
  ${props => (props.protocol) && css`
    max-width: 350px !important;
    min-width: 350px !important;
    width: 350px !important;
  ` 
  }
  
  padding:0px  !important;
  margin:0px !important;
  background-color: #ffffff;
  border:2px solid #eeeeee;

  & .ant-tabs-tab {
    margin:0 5px 0 0 !important;
  }
`;

export const DefControlBlock = styled.div`
  transition: opacity 0.3s;
  opacity: 0;
`;

export const RefControlBlock = styled.div`
  position: absolute;
  float: top;
  bottom: 5%;
  left: 15%;
  
  transition: opacity 0.3s;
  opacity: ${props => (props.visible? '1' : '0')};
`;

export const StudyEventGroupBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: ${props => (`${props.size}`)};
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 100px;
  border: solid #e4e9f0;
  border-width: 1px 1px 1px 0px;
`

export const StudyEventGroupTitleBlock = styled.span`
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  text-align: center;
  ${ ({titleWidth}) => (`max-width: ${titleWidth}px;`)}
`


export const FormContentGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: ${props => (`${props.size}`)};
  flex-grow: 1;
`

export const ProtocolRefControlBlock = styled.div`
  position: absolute;
  float: top;
  top: 0%;
  background: white;
  transition: opacity 0.3s;
  opacity: ${props => (props.visible? '1' : '0')};
`;

export const DefHeadCard = styled(Card).attrs({
  ...Card.defaultProps,
  className:'col-1 p-0 mt-2 mb-2'
})`
  position: sticky;
  left: 0;

  & .ant-card-head {
    background: rgb(250, 250, 210);
    cursor: pointer;
    height: 100%;
    padding-top: 7rem;
    transition: padding-top 0.3s;
  }

  & .ant-card-body {
    position: absolute;
    top: 40%;
  }

  transition: all 0.3s;
  
  &:hover {
    & .ant-card-head {
      background: rgba(250, 250, 210, 0.7);
      padding-top: 5.75rem;
    }
    
    border: solid 1px rgb(100,100,60);
    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, .1);
  }
`

export const StudyGroupRefItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  
  min-height: 50px;
  width: 135px;
  border: solid 1px rgba(0,0,0,0.2);
  background: white;
  margin: 0.25rem;
  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    border: dashed 1px rgb(100,100,60);
    box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, .1);
  }

  &:hover ${ProtocolRefControlBlock} {
    opacity: 1;
  }

  ${props => props.isDragging && css`
    border: dashed 1px rgb(100,100,60);
    box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, .1);
  ` }
  
`

export const RefItemCard = styled(Card).attrs({
  ...Card.defaultProps,
})`

  user-select: none;
  width: 135px;
  margin: 0.25rem;
  
  & .ant-card-body {
    padding: 10px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    height: 120px;
    font-size:0.8rem;
  }
  
  & .ant-card-head {
    padding:0px 0 0px 10px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    font-size:1rem;
  }

  &:hover {
    border: dashed 1px rgb(100,100,60);
    box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, .1);    
  }

  &:hover ${RefControlBlock} {
    opacity: 1;
  }
  
  ${props => props.dragging && css`
    border: dashed 1px rgb(100,100,60);
    box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, .1);
  ` }
  
}>
  
`;

export const DefContentWrapper = styled.div`
  border: 1px solid rgba(0,0,0,0.2);
  background: rgba(50,50,50,0.075);
  margin: 2rem;
  height: 100%;
  width: 95%;
  overflow-y: auto;
  
  &:hover {
    border: solid 1px rgb(100,100,60);
    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, .1);
  }

  &:hover ${DefControlBlock} {
    opacity: 1;
  }
`;

export const ProtocolContentWrapper = styled.div`
  border: 1px solid rgba(0,0,0,0.2);
  background: rgba(50,50,50,0.075);
  margin: 1rem 1rem 1rem 2rem;

  width: 85%;
  height: 80px;
  border-radius: 0.5rem;

  &:hover {
    border: solid 1px rgb(100,100,60);
    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, .1);
  }

  &:hover ${DefControlBlock} {
    opacity: 1;
  }
`

export const DefHeadTitle = styled.div`
  padding-top: 1rem;
  padding-left: 1rem;
  display: flex;
  position: sticky;
  left:0;
  flex-direction: row;
`;

export const DefContent = styled.div`
  height: 200px;
  width: fit-content;
  min-width: 98.5%;
  margin-right: 0.75rem;
  margin-bottom: 0.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;

  ${props => (props.isDraggingOver && css`
    border: dashed 1px;
    background: repeating-linear-gradient(-45deg, rgba(230, 230, 230, 0.3), rgba(230, 230, 230, 0.3) 5px, rgba(136, 136, 136, 0.3) 0, rgba(85, 85, 85, 0.3) 10px);
  `)}
`;

export const ProtocolContent = styled.div`

  height: 70px;
  width: 99%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  margin: 0.25rem;
  border-radius: 0.5rem;
  
  ${props => (props.isDraggingOver && css`
    border: dashed 1px;
    background: repeating-linear-gradient(-45deg, rgba(230, 230, 230, 0.3), rgba(230, 230, 230, 0.3) 5px, rgba(136, 136, 136, 0.3) 0, rgba(85, 85, 85, 0.3) 10px);
  `)}

`;

export const LibraryCard = styled(Card).attrs({
  ...Card.defaultProps
})`
  user-select: none;
  
  &:hover {
    background: #eeeeee;
    cursor: zoom-in;
    border: none;
    box-shadow: 0 10px 35px -5px rgba(0, 0, 0, 0.15);
    
    & > .ant-card-body {
      border: 1px solid white;
    }

    & > .ant-card-head {
      border: 1px solid white;
      border-width: 0px 1px 1px 1px;
    }
  }
`

export const DescriptionDiv = styled.div`
  height: 5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
`