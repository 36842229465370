import React, {useState} from "react";
import {ItemOptionDiv, RepeatingItemOptionDiv} from "../../../../../container/build-tool/StyleBox";
import {isExcludeOID} from "../../../../../lib/ODMUtils";
import RemoveBtn from "../../../../../container/build-tool/content/build-form/RemoveBtn";
import LibraryMandatoryBtn from "./LibraryMandatoryBtn";
import LibraryValueListBtn from "./LibraryValueListBtn";
import LibraryUnitsBtn from "./LibraryUnitsBtn";

const OptionButtons = ({
                           library, itemRef, itemGroup, itemRefMap, setItemRefMap,
                           index, isValueList, setIsValueList, isEnableUnit,
                           unitsOpen, setUnitsOpen, valueListOpen, setValueListOpen,
                           removeOpen, setRemoveOpen
                       }) => {
    const [itemDef, setItemDef] = useState(library.item.get(itemRef.itemOID));
    return (
        <>
            {itemDef?.controlType !== 'LABEL' &&
            <LibraryMandatoryBtn data={itemRef}
                          itemGroupOID={itemGroup.oid}
                          itemRefMap={itemRefMap}
                          setItemRefMap={setItemRefMap}
                          index={index} defType={"item"}/>
            }
            {
                (!isValueList && isEnableUnit(library.item.get(itemRef.itemOID))) &&
                <LibraryUnitsBtn library={library} data={itemRef}
                          itemGroupOID={itemGroup.oid}
                          itemRefMap={itemRefMap}
                          setItemRefMap={setItemRefMap}
                          open={unitsOpen} setOpen={setUnitsOpen}
                          index={index} defType={"item"}/>
            }
            {itemRef.itemOID.endsWith('TESTCD') &&
                <LibraryValueListBtn library={library} data={itemRef}
                                     itemGroupOID={itemGroup.oid} setIsValueList={setIsValueList}
                                     itemRefMap={itemRefMap} setItemRefMap={setItemRefMap}
                                     open={valueListOpen} setOpen={setValueListOpen}
                                     index={index} defType={"item"}/>
            }
            {
                (!isExcludeOID(itemRef.itemOID)) &&
                <RemoveBtn itemGroupOID={itemGroup.oid}
                           itemRefMap={itemRefMap}
                           setItemRefMap={setItemRefMap}
                           visible={removeOpen} setVisible={setRemoveOpen}
                           index={index} defType={"item"}/>
            }
        </>
    )
}

const LibraryItemOption = ({isRepeating, designMode, library, itemGroup, itemRef, itemRefMap, setItemRefMap, index, isEnableUnit}) => {

    const [unitsOpen, setUnitsOpen] = useState(false);
    const [valueListOpen, setValueListOpen] = useState(false);
    const [removeOpen, setRemoveOpen] = useState(false);
    const [isValueList, setIsValueList] = useState(false);

    const getVisible = () => {
        return (unitsOpen || valueListOpen || removeOpen);
    }

    return (
      <>
          {
              //Repeating = NO
              (isRepeating === false)? (
                  <ItemOptionDiv designMode={designMode} visible={getVisible()}>
                      <OptionButtons library={library} itemRef={itemRef} itemGroup={itemGroup} itemRefMap={itemRefMap} setItemRefMap={setItemRefMap}
                                     index={index} isValueList={isValueList} setIsValueList={setIsValueList} isEnableUnit={isEnableUnit}
                                     unitsOpen={unitsOpen} setUnitsOpen={setUnitsOpen}
                                     valueListOpen={valueListOpen} setValueListOpen={setValueListOpen}
                                     removeOpen={removeOpen} setRemoveOpen={setRemoveOpen} />
                  </ItemOptionDiv>
              ) : (
                  <RepeatingItemOptionDiv visible={getVisible()}>
                      <OptionButtons library={library} itemRef={itemRef} itemGroup={itemGroup} itemRefMap={itemRefMap} setItemRefMap={setItemRefMap}
                                     index={index} isValueList={isValueList} setIsValueList={setIsValueList} isEnableUnit={isEnableUnit}
                                     unitsOpen={unitsOpen} setUnitsOpen={setUnitsOpen}
                                     valueListOpen={valueListOpen} setValueListOpen={setValueListOpen}
                                     removeOpen={removeOpen} setRemoveOpen={setRemoveOpen} />
                  </RepeatingItemOptionDiv>
              )
          }
      </>
    );

}

export default LibraryItemOption;
