import ODMTabs from "../../../layouts/Tab/odm";
import {Helmet} from "react-helmet-async";
import EditCheckContainer from "../../../container/odm/EditCheckContainer";
import React from "react";

const ECSPage = () => {
    return (
        <ODMTabs menu={'ecs'}>
            <Helmet title={`ODM Designer v2.0.0 :: Edit Checks`}/>
            <EditCheckContainer />
        </ODMTabs>
    )
}
export default ECSPage;