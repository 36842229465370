import {Layout} from "antd";
import {ScheduleSider, StickyDiv} from "../../../container/build-tool/StyleBox";
import ScheduleRefList from "./ScheduleRefList";
import ScheduleDefList from "./ScheduleDefList";
import {DragDropContext} from "react-beautiful-dnd";
import React, {useEffect, useState} from "react";
import {toMetaData} from "../../../lib/ODMUtils";
import {useSelector} from "react-redux";
import {sweetAlert} from "../../../lib/BuilderUtils";
import _ from 'lodash';
import {usePrompt} from "../../../lib/Blocker";
import NormalLoading from "../NormalLoading";
import {useTranslation} from "react-i18next";

const {Content} = Layout;


const Schedule = ({defType, defTitle, refType, refTitle}) => {

    //Redux Data
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const [metaData, setMetaData] = useState(toMetaData(metaDataStore.study));

    const {t} = useTranslation();

    //Initial State
    const [loading, setLoading] = useState(true);

    const [defList, setDefList] = useState(null);
    const [protocol, setProtocol] = useState(null);
    const [refList, setRefList] = useState(null);
    const [formList, setFormList] = useState(null);

    // const {enablePrevent, disablePrevent} = usePreventLeave();
    const [shouldConfirm, setShouldConfirm] = useState(false);

    //변경 감지 후 확인 창 띄우기
    usePrompt(shouldConfirm, t);

    useEffect(() => {
        if (metaDataStore.loaded) {
            setMetaData(toMetaData(metaDataStore.study));
        }
    }, [metaDataStore]);

    useEffect( () => {
        setLoading(false);
    }, [])


    const onDragUpdate = (update) => {
        // console.log("Drag Update : ", update);
        //TODO :: Duplicate Check 추가 필요.
    }

    const onDragEnd = (result) => {
        const {source, destination, draggableId, type} = result;
        let isWorking = false;

        // console.log("%cEnd : ", "color:red", source, destination, draggableId, type);

        if(!destination) {
            return;
        }

        //New Ref Item Copy
        if(source.droppableId.includes("new")) {
            switch(type) {
                case "formRef":
                    const formValue = metaData.form.get(draggableId); //해당 form이 실제로 존재하는 지 확인.
                    if(formValue !== undefined) { //Yes,
                        const formRef = {
                            formOID: draggableId,
                            orderNumber:null,
                            mandatory:"NO",
                            collectionExceptionConditionOID:null,
                        }

                        defList.map((def, index, array) => {
                            if(def.oid === destination.droppableId) {
                                const formRefs = array[index].formRef;

                                if(formRefs.find(form => form.formOID === draggableId)) {
                                    sweetAlert(t('message.duplicate.form.data'), 1000, 'error')
                                } else if(metaData.form.get(draggableId).itemGroupRef == null || metaData.form.get(draggableId).itemGroupRef?.length <= 0) {
                                    sweetAlert(t('message.without.form.item.cannot.reference'), 1000, 'error');
                                } else {
                                    formRefs.splice(destination.index, 0, formRef);
                                    isWorking = true;
                                }
                            }
                        });
                    } else {
                        console.log("ERROR !!");
                    }
                    break;
                case "studyEventRef":
                    const studyEventValue = metaData.studyEvent.get(draggableId);
                    if(studyEventValue !== undefined) { //Yes,
                        const initStudyEventRef = {
                            studyEventOID: draggableId,
                            mandatory: "NO",
                            orderNumber: null,
                            type: "StudyEventRef",
                            collectionExceptionConditionOID: null
                        }

                        defList.map((def, index, array) => {
                            if(def.oid === destination.droppableId) {
                                const studyEventRef = array[index]?.studyEventRef;
                                if(studyEventRef.find(studyEvent => studyEvent.studyEventOID === draggableId)) {
                                    sweetAlert(t('message.duplicate.study.event.data'), 1000, 'error');
                                } else {
                                    studyEventRef.splice(destination.index, 0, initStudyEventRef);
                                    isWorking = true;
                                }
                            }
                        });

                    } else {
                        console.log("ERROR !!");
                    }
                    break;
                case "studyEventGroupRef":
                    const studyEventGroupRef = {
                        studyEventGroupOID: draggableId,
                        mandatory: "NO",
                        orderNumber: null,
                        description: null,
                        collectionExceptionConditionOID:null
                    }
                    const cloneProtocol = _.clone(protocol);
                    if(cloneProtocol.studyEventGroupRef.find(seg => seg.studyEventGroupOID === draggableId)) {
                        sweetAlert(t('message.duplicate.study.event.group.data'), 1000, 'error');
                    } else {
                        cloneProtocol.studyEventGroupRef.splice(destination.index, 0, studyEventGroupRef);
                        setProtocol(cloneProtocol);
                    }
                    toFormList(cloneProtocol);
                    break;
            }
        } else { //reorder
            //동일한 def내에서 이동했을 때,
            if(destination.droppableId === source.droppableId) {
                let target, removed;

                switch(type) {
                    case "formRef":
                        target = defList.find(def => def.oid === destination.droppableId);
                        [removed] = target.formRef.splice(source.index, 1);
                        target.formRef.splice(destination.index, 0, removed);
                        break;
                    case "studyEventRef":
                        target = defList.find(def => def.oid === destination.droppableId);
                        [removed] = target.studyEventRef.splice(source.index, 1);
                        target.studyEventRef.splice(destination.index, 0, removed);
                        break;
                    case "studyEventGroupRef":
                        const cloneProtocol = _.clone(protocol);
                        [removed] = cloneProtocol.studyEventGroupRef.splice(source.index, 1);
                        cloneProtocol.studyEventGroupRef.splice(destination.index, 0, removed);
                        setProtocol(cloneProtocol);
                        toFormList(cloneProtocol);
                        break;
                }
                isWorking = true;
            } else { //다른 def로 이동했을 때,
                let startTarget, endTarget;
                let removed, isDuplicated = false;
                const refId = draggableId.split("::")[1];

                switch(type) {
                    case "formRef":
                        startTarget = defList.find(def => def.oid === source.droppableId);
                        endTarget = defList.find(def => def.oid === destination.droppableId);
                        isDuplicated = endTarget.formRef.some(ref => ref.formOID === refId);

                        if(isDuplicated) {
                            sweetAlert(t('message.duplicate.form.data'), 1000,'error')
                        } else {
                            [removed] = startTarget.formRef.splice(source.index, 1);
                            endTarget.formRef.splice(destination.index, 0, removed);
                        }
                        break;
                    case "studyEventRef":
                        startTarget = defList.find(def => def.oid === source.droppableId);
                        endTarget = defList.find(def => def.oid === destination.droppableId);
                        isDuplicated = endTarget.studyEventRef.some(ref => ref.studyEventOID === refId);

                        if(isDuplicated) {
                            sweetAlert(t('message.duplicate.study.event.data'), 1000,'error')
                        } else {
                            [removed] = startTarget.studyEventRef.splice(source.index, 1);
                            endTarget.studyEventRef.splice(destination.index, 0, removed);
                        }
                        break;
                    case "studyEventGroupRef":
                        //해당 없음.
                        break;
                }
                isWorking = true;
            }
        }
        //작업이 수행 여부에 따라서 페이지 전환 시 팝업창 발생.
        if(isWorking && shouldConfirm === false) {
            // enablePrevent();
            setShouldConfirm(true);
        }
    }

    const toFormList = (protocol) => {
        const cloneFormList = [];

        protocol?.studyEventGroupRef?.map((studyEventGroup) => {
            const studyEventGroupDef = metaData.studyEventGroup.get(studyEventGroup.studyEventGroupOID);
            studyEventGroupDef?.studyEventRef.map((studyEvent) => {
                const studyEventDef = metaData.studyEvent.get(studyEvent.studyEventOID);
                studyEventDef?.formRef.map((form) => {
                    const formDef = metaData.form.get(form.formOID);
                    //중복된 데이터가 없으면 넣음.
                    if(!cloneFormList.some(r => r.oid === formDef.oid)) {
                        cloneFormList.push(formDef);
                    }
                });
            })
        });
        setFormList(cloneFormList);
    }

    //Ref List에서 defList에 데이터를 가지고 있는 지 확인
    const isInclude = (refType, refOID) => {
        switch(refType) {
            case 'studyEventRef':
                return !defList?.some(def => def.studyEventRef.some(se => se.studyEventOID === refOID));
            case 'studyEventGroupRef':
                return !protocol?.studyEventGroupRef.some(seg => seg.studyEventGroupOID === refOID);
        }
    }

    return (
        <DragDropContext
            onDragUpdate={onDragUpdate}
            onDragEnd={onDragEnd}
        >
        <Layout>
            {
                (loading)? (
                    <NormalLoading></NormalLoading>
                ) : (
                    <>
                        <Content style={{minWidth: 1020, userSelect:'none'}}>
                            <div className={'card mr-2'}>
                                <ScheduleDefList defType={defType} defTitle={defTitle} refType={refType}
                                                 protocol={protocol} setProtocol={setProtocol}
                                                 defList={defList} setDefList={setDefList}
                                                 formList={formList} setFormList={setFormList} toFormList={toFormList}
                                                 setShouldConfirm={setShouldConfirm} />
                            </div>
                        </Content>
                        <ScheduleSider theme={'light'} protocol={defType==='protocol'?1:0}>
                            <StickyDiv>
                                <ScheduleRefList refType={refType} refTitle={refTitle} refList={refList}
                                                 setRefList={setRefList} isInclude={isInclude} />
                            </StickyDiv>
                        </ScheduleSider>
                    </>
                )
            }
        </Layout>
        </DragDropContext>
    )
}

export default Schedule;
