import {Form, Input, Select} from 'antd';
import React from "react";
const {Item} = Form;

const FormTextArea = ({name, required, label, readOnly = false, rules}) => {

    return (
      <Item required={required} name={name} label={<span className={'text-primary'}>{label}</span>} rules={rules}>
          <Input.TextArea readOnly={readOnly} />
      </Item>
    );
}

export default FormTextArea;