import _ from "lodash";
import {isEmpty} from "../../../../lib/StringUtils";
import {Button, Popover, Select, Space, Tag} from "antd";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../../lib/ODMUtils";

const ValueListBtn = ({metaData, data, itemGroupOID, setIsValueList, itemRefMap, setItemRefMap, index, defType, visible, setVisible}) => {
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const valueListDefs = Array.from(metaData.valueList.values());
    const displayDef = valueListDefs.find(def => def?.oid === data?.valueListOID);

    const onChangeValueList = (value) => {
        const valueListOID = value;
        const cloneItemRefMap = _.cloneDeep(itemRefMap);
        if(isEmpty(valueListOID)) {
            delete cloneItemRefMap.get(itemGroupOID)[index].valueListOID;
            setIsValueList(false);
        } else {
            cloneItemRefMap.get(itemGroupOID)[index].valueListOID = valueListOID;

            //valueListOID 항목이 선택되었을 경우, 아래 항목의 속성값을 전부 제거함.
            delete cloneItemRefMap.get(itemGroupOID)[index].methodOID;
            delete cloneItemRefMap.get(itemGroupOID)[index].collectionExceptionConditionOID;
            delete cloneItemRefMap.get(itemGroupOID)[index].unitsItemOID;
            setIsValueList(true);
        }
        setItemRefMap(cloneItemRefMap);
    }

    const valueListOptions = [
        {value: '', label: '...'},
        ...valueListDefs.map(valueList => ({
            key: `VL_${itemGroupOID}_${index}_${valueList.oid}`,
            value: valueList.oid,
            label: `${valueList.oid} - ${valueList.name}`
        }))
    ];

    return (
        <Popover placement={'top'}
                 title={'ValueList'}
                 trigger={'click'}
                 open={visible}
                 onOpenChange={() => setVisible(!visible)}
                 content={(
                     <Space>
                         {
                             isProd?(
                                 <Tag>
                                     <span className={'font-weight-bold text-danger'}>
                                         {displayDef!=null?`${displayDef?.oid} - ${displayDef?.name}`:`NONE`}
                                     </span>
                                 </Tag>
                             ) : (
                                 <Select placeholder={'ValueList'} defaultValue={data?.valueListOID}
                                         style={{width: 250}} showSearch={true} options={valueListOptions}
                                         onChange={onChangeValueList} onBlur={() => setVisible(false)} />
                             )
                         }
                     </Space>
                 )}>
            <Button size={'small'} onClick={() => setVisible(true)}>
                <i className='fa fa-th-list'></i>
            </Button>
        </Popover>
    )
};
export default ValueListBtn;