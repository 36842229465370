import React, {useEffect, useState} from "react";
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
import {convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {Button, Col, Input, Row, Space, message, Form, Select, Card, Mentions, Tabs, Table, Checkbox } from "antd";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {errorHandle} from "../../../../lib/BuilderUtils";
import DefaultLoading from "../../DefaultLoading";
import {DefaultCard} from "../../../../pages/PageStyleBox";
import queryString from "query-string";
import {isEmpty} from "../../../../lib/StringUtils";
import {useTranslation} from "react-i18next";

const { TextArea } = Input;

const EditorWrapper = styled.div`
    .wrapper-class{
        //width: 50%;
        margin: 0 auto;
        margin-bottom: 1rem;
    }
    .editor {
        height: 350px !important;
        border: 1px solid #f1f1f1 !important;
        padding: 5px !important;
        border-radius: 2px !important;
    }
  
  .rdw-suggestion-dropdown {
    min-width: 200px !important;
  }
  
  .rdw-suggestion-option-active{
    user-focus: '';
  }
`;

const IntroduceContent = styled.div`
  position: relative;
  //border: 0.0625rem solid #d7e2eb;
  //border-radius: 0.75rem;
  overflow: hidden;
  padding: 1.5rem;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 4rem;
`;

const EmailNotice = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [form] = Form.useForm();
    const query = queryString.parse(location.search);

    // useState로 상태관리하기 초기값은 EditorState.createEmpty()
    // EditorState의 비어있는 ContentState 기본 구성으로 새 개체를 반환 => 이렇게 안하면 상태 값을 나중에 변경할 수 없음.
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorToHtml, setEditorToHtml] = useState(null);
    const [suggestionList, setSuggestionList] = useState(null);
    const [saeSuggestionList, setSaeSuggestionList] = useState(null);

    const [emailNoticeTemplate, setEmailNoticeTemplate] = useState(null);
    const [studySiteList, setStudySiteList] = useState([]);
    const [filterSiteList, setFilterSiteList] = useState([]);
    const [studyRoleList, setStudyRoleList] = useState([]);
    const [receiveRoleVOList, setReceiveRoleVOList] = useState([]);

    const [selectType, setSelectType] = useState([]);
    const [selectEnv, setSelectEnv] = useState([]);
    const [selectSite, setSelectSite] = useState([]);
    const [selectRoles, setSelectRoles] = useState([]);

    const [reportSAE, setReportSAE] = useState(false);

    const [isLoading, setLoading] = useState(false);
    const {t} = useTranslation();

    const envData = [
        {
            key: '1',
            name: 'DEV',
        },
        {
            key: '2',
            name: 'UAT',
        },
        {
            key: '3',
            name: 'PROD',
        },
    ];

    const onEditorStateChange = (editorState) => {
        // editorState에 값 설정
        // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        const htmlText = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setEditorToHtml(htmlText);
        setEditorState(editorState);

        // console.log('htmlText =>', htmlText);
        form.setFieldsValue({content:htmlText});

    };

    useEffect( () => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/email-notice/edit`, {params: query});

                if (status === httpStatus.ok) {
                    setSuggestionList(data?.suggestionList);
                    setSaeSuggestionList(data?.saeSuggestionList);
                    setStudySiteList(data?.studySites);

                    const tmpStudyRoleList = [];
                    if(data?.studyRoles) {
                        data.studyRoles.filter((role) => {
                            const tmp = {
                                id : role.id,
                                name: role.shortName,
                            }
                            tmpStudyRoleList.push(tmp);
                        });
                    }
                    setStudyRoleList(tmpStudyRoleList);

                    form.setFieldsValue(data?.emailNoticeTemplate);
                    setEmailNoticeTemplate(data?.emailNoticeTemplate);
                    setReceiveRoleVOList(data?.emailNoticeTemplate?.receiveRoleVOList);

                    setEditorToHtml(data?.emailNoticeTemplate.content||"");
                    const blocksFromHtml = htmlToDraft(data?.emailNoticeTemplate.content||"");
                    if (blocksFromHtml) {
                        const { contentBlocks, entityMap } = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        const editorState = EditorState.createWithContent(contentState);
                        setEditorState(editorState);
                    }
                }
            } catch (e) {
                errorHandle(e, navigate);
            }
        })();

    }, []);

    const onFinish = async (values) => {
        //저장 중 로딩 설정
        setLoading(true);

        console.log('Received values of form:', values);
        const body = {
            ...emailNoticeTemplate,
            ...values
        }
        body.emailNoticeReceiveRoleList.splice(0); // role list 초기화

        try {
            const {status, data} = await axios({
                url: `/api/builder/studies/${params.sid}/email-notice/edit`,
                data: body,
                method: 'post'
            });

            if(status === httpStatus.ok) {
                message.success(data?.message);
                navigate(`/studies/${params.sid}/email-notice`);
            }
        } catch(e) {
            errorHandle(e);
        }

        setLoading(false);
    };

    const onEnvChange = (value) => {
        setSelectEnv(value);

        setFilterSiteList([
            {id: -1, name: "All"}, //ALL DATA
            ...studySiteList.filter((site) => {return site.envs.some((env) => env.key === value[0])})
        ]);
        setSelectSite([]);
    }

    const onSiteChange = (value) => {
        setSelectSite(value);
        setSelectRoles([]);
    }

    const onSelectRoles = (values) => {
        setSelectRoles(values);
    }

    const add = () => {
        let tmpReceiveRoleList = [...receiveRoleVOList];
        let newReceiveRoleList = [];

        if(selectRoles) {
            selectRoles.map((roleId) => {
                const tmp = {
                    // receiveType: selectType[0],
                    key: selectEnv[0]+'_'+selectSite[0]+'_'+roleId,
                    env: selectEnv[0],
                    studySiteId: selectSite[0],
                    studyRoleId: roleId,
                    studySiteName: filterSiteList.find(s => s.id === selectSite[0]).name,
                    studyRoleName: studyRoleList.find(r => r.id === roleId).name,
                }

                tmpReceiveRoleList.push(tmp);
            })

            newReceiveRoleList = tmpReceiveRoleList.reduce((prev, now) => {
                if(!prev.some(obj => obj.env === now.env && obj.studySiteId === now.studySiteId && obj.studyRoleId === now.studyRoleId)) {
                    prev.push(now);
                }
                return prev;
            }, []);
        }
        // console.log(newReceiveRoleList);
        setReceiveRoleVOList(newReceiveRoleList);
        form.setFieldsValue({receiveRoleVOList: newReceiveRoleList});
    }

    const handleDelete = (receiveRole) => {
        // console.log(receiveRole);
        const newData = receiveRoleVOList.filter((item) => item.key !== receiveRole.key);
        setReceiveRoleVOList(newData);
        form.setFieldsValue({receiveRoleVOList: newData});
    };

    const onReportSaeChange = (e) => {
        setReportSAE(e.target.checked);
        form.setFieldsValue({reportSAE:e.target.checked});
    }

    return (
        <>
            {
                (suggestionList === null && editorToHtml === null) ? (
                    <DefaultLoading />
                ) : (
                    <DefaultCard>
                        <Row style={{margin:'1rem'}}>
                            <Col span={12}>
                                <Space direction="vertical">
                                    <Form name="dynamic_form_nest_item"
                                          form={form}
                                          onFinish={onFinish} autoComplete="off"
                                    >
                                        <Card title={<h5>e-Mail Template</h5>}>
                                            <div style={{display:"flex"}}>
                                                <Form.Item name={'reportSAE'} valuePropName={"checked"}>
                                                    <Checkbox onChange={onReportSaeChange}>{t('studies.template.sae.report')}</Checkbox>
                                                </Form.Item>
                                                {
                                                    reportSAE &&
                                                    <div style={{display:"flex", marginLeft:"1rem"}}>
                                                        <Form.Item
                                                            name={'reportSAEType'}
                                                            hidden={true}
                                                        >
                                                            <Select
                                                                defaultValue="All"
                                                                placeholder={"SAE Report Type"}
                                                                style={{width: 200, marginRight:"10px"}}
                                                                options={[
                                                                    {value: 'All', label: 'All'},
                                                                    {value: 'Initial', label: 'Initial'},
                                                                    {value: 'Follow Up', label: 'Follow Up'}
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={'reportSAEFormOID'}
                                                            fieldKey={'reportSAEFormOID'}
                                                            hidden={true}
                                                        >
                                                            <Select
                                                                style={{width: "-webkit-fill-available"}}
                                                                options={[
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                }
                                            </div>
                                            <Form.Item
                                                name={'title'}
                                                rules={[{required: true, message: 'Missing title'},{ max: 255, message: 'Please enter Titles in 255 characters or less.' }]}
                                            >
                                                <Mentions
                                                    prefix={'$'}
                                                    placeholder="Title"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    {
                                                        suggestionList.map((suggestion, index) => (
                                                            <Mentions.Option key={index} value={`${suggestion?.value}`}>
                                                                {suggestion?.text}
                                                            </Mentions.Option>
                                                        ))
                                                    }
                                                    {
                                                        reportSAE &&
                                                        saeSuggestionList.map((saeSuggestion, index) => (
                                                            <Mentions.Option key={index+5} value={`${saeSuggestion?.value}`}>
                                                                {saeSuggestion?.text}
                                                            </Mentions.Option>
                                                        ))
                                                    }

                                                </Mentions>
                                            </Form.Item>
                                            <Form.Item
                                                name={'content'}
                                                fieldKey={'content'}
                                                rules={[{required: true, message: 'Missing content'}]}
                                                hidden={true}
                                            >
                                                <TextArea/>
                                            </Form.Item>
                                            <EditorWrapper>
                                                <Editor
                                                    // 에디터와 툴바 모두에 적용되는 클래스
                                                    wrapperClassName="wrapper-class"
                                                    // 에디터 주변에 적용된 클래스
                                                    editorClassName="editor"
                                                    // 툴바 주위에 적용된 클래스
                                                    toolbarClassName="toolbar-class"
                                                    // 툴바 설정
                                                    toolbar={{
                                                        // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
                                                        list: {inDropdown: true},
                                                        textAlign: {inDropdown: true},
                                                        link: {inDropdown: true},
                                                        history: {inDropdown: false},
                                                    }}
                                                    placeholder={t('studies.pls.write.content')}
                                                    // 한국어 설정
                                                    // localization={{
                                                    //     locale: 'ko',
                                                    // }}
                                                    // 초기값 설정
                                                    editorState={editorState}
                                                    // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
                                                    onEditorStateChange={onEditorStateChange}
                                                    mention={{
                                                        separator: ' ',
                                                        trigger: '$',
                                                        suggestions: reportSAE ? [...suggestionList, ...saeSuggestionList] : suggestionList,
                                                    }}
                                                />
                                            </EditorWrapper>
                                            <Row>
                                                <Col span={24}>
                                                    <Card title={<h5>{t('studies.email.reception.setting')}</h5>}>
                                                        <Tabs defaultActiveKey={'receiver'} tabPosition={'left'} type={'card'}>
                                                            <Tabs.TabPane tab={'Role'} key={'role'}>
                                                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                                                    <Table
                                                                        style={{marginRight: "10px", width: "50%"}}
                                                                        bordered={true}
                                                                        size={"small"}
                                                                        pagination={false}
                                                                        rowKey="name"
                                                                        dataSource={envData}
                                                                        columns={[
                                                                            {
                                                                                title: 'Env',
                                                                                dataIndex: 'name',
                                                                            }
                                                                        ]}
                                                                        rowSelection={
                                                                            {
                                                                                type: 'radio',
                                                                                columnWidth: 20,
                                                                                onChange: onEnvChange,
                                                                            }
                                                                        }
                                                                    />
                                                                    <Table
                                                                        style={{marginRight: "10px", width: "-webkit-fill-available"}}
                                                                        bordered={true}
                                                                        size={"small"}
                                                                        pagination={{
                                                                            pageSize: 5,
                                                                            defaultPageSize: 5,
                                                                        }}
                                                                        rowKey="id"
                                                                        dataSource={filterSiteList}
                                                                        columns={[
                                                                            {
                                                                                title: 'Site',
                                                                                dataIndex: 'name',
                                                                            }
                                                                        ]}
                                                                        rowSelection={
                                                                            {
                                                                                type: 'radio',
                                                                                columnWidth: 20,
                                                                                selectedRowKeys: selectSite,
                                                                                onChange: onSiteChange,
                                                                            }
                                                                        }
                                                                    />
                                                                    <Table
                                                                        style={{width: "-webkit-fill-available"}}
                                                                        bordered={true}
                                                                        size={"small"}
                                                                        pagination={{
                                                                            pageSize: 5,
                                                                            defaultPageSize: 5,
                                                                        }}
                                                                        rowKey="id"
                                                                        dataSource={selectSite.length > 0 ? studyRoleList : []}
                                                                        columns={[
                                                                            {
                                                                                title: 'Role',
                                                                                dataIndex: 'name',
                                                                            }
                                                                        ]}
                                                                        rowSelection={
                                                                            {
                                                                                type: 'checkbox',
                                                                                columnWidth: 20,
                                                                                selectedRowKeys: selectRoles,
                                                                                onChange: onSelectRoles,
                                                                            }
                                                                        }
                                                                    />
                                                                </div>
                                                                <div style={{textAlign: "center"}}>
                                                                    <Button style={{margin: "10px"}} type="primary" shape="round" size="large"
                                                                            disabled={selectRoles.length <= 0}
                                                                            onClick={add}>Add</Button>
                                                                </div>
                                                                <Form.Item name={'receiveRoleVOList'} rules={[
                                                                    {
                                                                        validator: async(_, roleList) => {

                                                                            //RoleList 정보 불러오기
                                                                            const userList = form.getFieldValue('emailNoticeReceiveUserList');
                                                                            //UserList 정보와 RoleList 정보 취합
                                                                            const listInfo = [...roleList, ...userList];

                                                                            if(listInfo.length > 0) {
                                                                                return Promise.resolve;
                                                                            } else {
                                                                                return Promise.reject(new Error(t("message.validator.no.receive.info")));
                                                                            }
                                                                        }
                                                                    }
                                                                ]}>
                                                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                                                        <Table
                                                                            style={{width: "-webkit-fill-available"}}
                                                                            bordered={true}
                                                                            size={"small"}
                                                                            pagination={{
                                                                                pageSize: 5,
                                                                                defaultPageSize: 5,
                                                                            }}
                                                                            rowKey="name"
                                                                            dataSource={receiveRoleVOList}
                                                                            columns={[
                                                                                {
                                                                                    title: 'Env',
                                                                                    dataIndex: 'env',
                                                                                    key: 'env',
                                                                                    width: '15%',
                                                                                    align: 'center',
                                                                                },
                                                                                {
                                                                                    title: 'Site',
                                                                                    dataIndex: 'studySiteName',
                                                                                    key: 'studySiteId',
                                                                                    width: '30%',
                                                                                    align: 'center',
                                                                                },
                                                                                {
                                                                                    title: 'Role',
                                                                                    dataIndex: 'studyRoleName',
                                                                                    key: 'studyRoleId',
                                                                                    width: '30%',
                                                                                    align: 'center',
                                                                                },
                                                                                {
                                                                                    title: 'action',
                                                                                    dataIndex: 'action',
                                                                                    width: '10%',
                                                                                    align: 'center',
                                                                                    render: (_, receiveRole) =>
                                                                                        receiveRoleVOList.length >= 1 ? (
                                                                                            <MinusCircleOutlined style={{color: "red"}}
                                                                                                                 onClick={() => handleDelete(receiveRole)}/>
                                                                                        ) : null,
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                </Form.Item>
                                                            </Tabs.TabPane>
                                                            <Tabs.TabPane tab={'User'} key={'user'}>
                                                                <Form.List name="emailNoticeReceiveUserList" rules={[
                                                                    {
                                                                        validator: async(_, userList) => {
                                                                            //RoleList 정보 불러오기
                                                                            const roleList = form.getFieldValue('receiveRoleVOList');
                                                                            //UserList 정보와 RoleList 정보 취합
                                                                            const listInfo = [...roleList, ...userList];

                                                                            if(listInfo.length > 0) {
                                                                                return Promise.resolve;
                                                                            } else {
                                                                                return Promise.reject(new Error(t("message.validator.no.receive.info")));
                                                                            }
                                                                        }
                                                                    }
                                                                ]}>
                                                                    {(fields, {add, remove}, {errors}) => (
                                                                        <>
                                                                            {fields.map(({
                                                                                             key,
                                                                                             name,
                                                                                             ...restField
                                                                                         }) => (
                                                                                <Space key={key} style={{
                                                                                    display: 'flex',
                                                                                    marginBottom: 8
                                                                                }} align="baseline">
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'receiveType']}
                                                                                        hidden={true}
                                                                                    >
                                                                                        <Select defaultValue="TO"
                                                                                                placeholder={"Type"}
                                                                                                options={[
                                                                                                    {value: 'TO', label: 'TO'},
                                                                                                    {value: 'CC', label: 'CC'},
                                                                                                    {value: 'BCC', label: 'BCC'}
                                                                                                ]}>
                                                                                        </Select>
                                                                                    </Form.Item>
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'userName']}
                                                                                        rules={[{
                                                                                            required: true,
                                                                                            message: 'Missing userName'
                                                                                        }]}
                                                                                    >
                                                                                        <Input placeholder="User Name"/>
                                                                                    </Form.Item>
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'email']}
                                                                                        rules={[{
                                                                                            required: true,
                                                                                            message: 'Missing email'
                                                                                        }]}
                                                                                    >
                                                                                        <Input placeholder="Email" style={{width:'300px'}}/>
                                                                                    </Form.Item>
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'selectedEnvs']}
                                                                                        rules={[{
                                                                                            required: true,
                                                                                            message: 'Missing env'
                                                                                        }]}
                                                                                    >
                                                                                        <Select mode={"multiple"}
                                                                                                placeholder={"Environment"}
                                                                                                style={{minWidth: 220}}
                                                                                                options={[
                                                                                                    {value: 'DEV', label: 'DEV'},
                                                                                                    {value: 'UAT', label: 'UAT'},
                                                                                                    {value: 'PROD', label: 'PROD'}
                                                                                                ]}
                                                                                        >
                                                                                        </Select>
                                                                                    </Form.Item>
                                                                                    <MinusCircleOutlined style={{color: "red"}}
                                                                                                         onClick={() => remove(name)}/>
                                                                                </Space>

                                                                            ))}
                                                                            <Form.Item>
                                                                                <Button type="dashed" onClick={() => add()}
                                                                                        block icon={<PlusOutlined/>}>
                                                                                    Add
                                                                                </Button>
                                                                            </Form.Item>
                                                                            <Form.ErrorList className={'text-danger'} errors={errors}/>
                                                                        </>
                                                                    )}
                                                                </Form.List>
                                                            </Tabs.TabPane>
                                                        </Tabs>
                                                    </Card>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={24} style={{
                                                    textAlign: 'right', marginTop: 30
                                                }}>
                                                    <Form.Item>
                                                        <Button loading={isLoading} type="primary" htmlType="submit">
                                                            Submit
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Form>
                                </Space>
                            </Col>
                            <Col span={11} offset={1}>
                                <Card title={<h5>Preview</h5>}>
                                    <IntroduceContent dangerouslySetInnerHTML={{__html: editorToHtml}}/>
                                </Card>
                            </Col>
                            {/*<Col flex="auto"></Col>*/}
                        </Row>
                    </DefaultCard>
                )
            }
        </>
    );

}

export default EmailNotice
