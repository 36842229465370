import React, {useState} from "react";
import {CardBody, CardFooter, CardHeader, PrimaryCard} from "../../../../pages/PageStyleBox";
import Icon from "@mdi/react";
import {mdiFileDocumentMultipleOutline} from "@mdi/js";
import {Button, Col, Row} from "antd";
import {isEmpty} from "../../../../lib/StringUtils";
import LibraryCardForm from "./build-form/LibraryCardForm";

const LibraryFormList = ({library, onFormAdd, onFormEdit, onFormDelete}) => {

    const [keyword, setKeyword] = useState('');

    return (
        <PrimaryCard>
            <CardHeader>
                <Row>
                    <Col span={16}>
                        <h4 className={'header-title'}><Icon path={mdiFileDocumentMultipleOutline} size={1} className={'mr-2'} />Forms({library?.formDef?.length??0})</h4>
                    </Col>
                    <Col span={8} className={'text-right'}>
                        <Button type={'primary'} onClick={() => onFormAdd()}><i className='fa fa-plus mr-2' /> Add</Button>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className={'definitions'}>
                <div className={'row mb-3'}>
                    <div className={'col-12'}>
                        <div className={'input-group'}>
                            <div className={'input-group-prepend'}>
                                <span className={'input-group-text input-lg'} ><i className={'fa fa-search icon-lg'}></i></span>
                            </div>
                            <input className={'form-control'} placeholder={'...'} onChange={e => setKeyword(e.target.value)}></input>
                        </div>
                    </div>
                </div>
                <div className={'row pl-0 ml-0'}>
                    {(library?.formDef??[]).filter(i => keyword === "" || (i.oid.toUpperCase().indexOf(keyword.toUpperCase()) !== -1 || i.name.toUpperCase().indexOf(keyword.toUpperCase()) !== -1))
                        .map((data, index) =>
                            <LibraryCardForm key={`form-thumb-${data.oid}`} formDef={data}
                                             disabled={isEmpty(data.oid)} onEdit={onFormEdit} onDelete={onFormDelete} />
                        )
                    }
                </div>
            </CardBody>
        </PrimaryCard>

    )
}

export default LibraryFormList;