import {Helmet} from "react-helmet-async";
import React from "react";
import ODMTabs from "../../../layouts/Tab/odm";
import DBSpecContainer from "../../../container/build-tool/container/DBSpecContainer";

const DBSpecPage = () => {

    return (
        <ODMTabs menu={'spec'}>
            <Helmet title={'ODM Designer v2.0.0 :: DB Spec'}/>
            <DBSpecContainer/>
        </ODMTabs>
    )
}

export default DBSpecPage;