import {Nav, NavItem, NavLink, TabContent} from "reactstrap";
import classNames from "classnames";
import {useNavigate, useParams} from "react-router-dom";
import React from "react";
import {
    mdiAccountDetails,
    mdiAlertCircle,
    mdiCalculatorVariantOutline,
    mdiCalendar,
    mdiCalendarExpandHorizontal,
    mdiCalendarMonth,
    mdiCalendarMultiple,
    mdiDatabaseArrowDownOutline,
    mdiFileDocumentMultipleOutline,
    mdiFileTable,
    mdiFormatListChecks,
    mdiMagnifyScan,
    mdiPublish,
    mdiTabletCellphone,
    mdiVectorSquare
} from "@mdi/js";
import '../style.scss';
import Icon from "@mdi/react";
import {useSelector} from "react-redux";

const ODMTabs = ({children, menu}) => {
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const params = useParams();
    const navigate = useNavigate();

    return (
        <>
            <Nav tabs className='odm-tab'>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/spec`)}
                             className={classNames({active: menu === 'spec'})}>
                           <Icon path={mdiFileTable} size={1} className={'pt-2'} />DB Spec
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/forms`)}
                             className={classNames({active: menu === 'forms'})}>
                           <Icon path={mdiFileDocumentMultipleOutline} size={1} className={'pt-2'} />Forms
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/study-events`)}
                             className={classNames({active: menu === 'study-events'})}>
                            <Icon path={mdiCalendar} size={1} className={'pt-2'} />Events
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/study-event-groups`)}
                             className={classNames({active: menu === 'study-event-groups'})}>
                            <Icon path={mdiCalendarMultiple} size={1} className={'pt-2'} />Event Groups
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/protocol`)}
                             className={classNames({active: menu === 'protocol'})}>
                            <Icon path={mdiCalendarMonth} size={1} className={'pt-2'} />Protocol(SoA)
                    </NavLink>
                </NavItem>
                {metaDataStore?.studyInfo?.useIndependentEvaluation &&
                    <NavItem>
                        <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/independent-evaluation`)}
                                 className={classNames({active: menu === 'independent-evaluation'})}>
                            <Icon path={mdiFormatListChecks} size={1} className={'pt-2'} /> IE
                        </NavLink>
                    </NavItem>
                }
                {metaDataStore?.studyInfo?.useEPRO &&
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/epro`)}
                             className={classNames({active: menu === 'e-pro'})}>
                            <Icon path={mdiTabletCellphone} size={1} className={'pt-2'} /> ePRO
                    </NavLink>
                </NavItem>
                }
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/visit-window`)}
                             className={classNames({active: menu === 'visit-window'})}>
                            <Icon path={mdiCalendarExpandHorizontal} size={1} className={'pt-2'} /> Visit Window
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/subject-status`)}
                             className={classNames({active: menu === 'subject-status'})} >
                        <Icon path={mdiAccountDetails} size={1} className={'pt-2'} /> Subject Status
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/subject-field-map`)}
                             className={classNames({active: menu === 'subject-field-map'})} >
                        <Icon path={mdiVectorSquare} size={1} className={'pt-2'} /> Field MAP
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/ecs`)}
                             className={classNames({active: menu === 'ecs'})} >
                        <Icon path={mdiCalculatorVariantOutline} size={1} className={'pt-2'} /> Edit Checks
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/sae`)}
                             className={classNames({active: menu === 'sae'})} >
                        <Icon path={mdiAlertCircle} size={1} className={'pt-2'} /> AE/SAE
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/dataset`)}
                             className={classNames({active: menu === 'dataset'})} >
                        <Icon path={mdiDatabaseArrowDownOutline} size={1} className={'pt-2'} /> DATA SET
                    </NavLink>
                </NavItem>
                {/*<NavItem>*/}
                {/*    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/lab`)}*/}
                {/*             className={classNames({active: menu === 'lab'})} >*/}
                {/*        <Icon path={mdiAlertCircle} size={1} className={'pt-2'} /> LAB*/}
                {/*    </NavLink>*/}
                {/*</NavItem>*/}
                {metaDataStore?.studyInfo?.useSAEReport &&
                    <NavItem>
                        <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/sae-map`)}
                                 className={classNames({active: menu === 'sae-map'})}>
                            <Icon path={mdiMagnifyScan} size={1} className={'pt-2'}/> SAE-MAP
                        </NavLink>
                    </NavItem>
                }
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/v/${params.id}/publish`)}
                             className={classNames({active: menu === 'publish'})}>
                            <Icon path={mdiPublish} size={1} /> Publish
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className={'p-4 content'}>
                {children}
            </TabContent>
        </>
    )

}

export default ODMTabs;
