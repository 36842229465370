import Swal from "sweetalert2";
import {message} from "antd";
import {httpStatus} from "./createRequestSaga";
import dayjs from 'dayjs';
import withReactContent from "sweetalert2-react-content";
import { Workbook } from "exceljs";
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import {isEmpty} from "./StringUtils";
import {withTranslation} from "react-i18next";

//ERROR NAME 상수
const RUNTIME_ERROR = 'RUNTIME_ERROR';

/**
 * @description Table 형태의 데이터에서 Search 할 때 사용하는 메서드
 * @param baseData 원본 데이터
 * @param setFilterData filterData에 설정할 때 사용할 setState 값
 * @param value 입력한 값.
 * @param searchKeywords 검색 키워드 지정
 * @author sh.yang
 * @since 2021-08-12
 */
export const onSearch = (baseData, setFilterData, value, searchKeywords = null) => {
    const result = baseData.filter(fieldData =>
        Object.keys(fieldData).some(keyword => {
            //예외 목록 처리
            if(searchKeywords !== null && !searchKeywords.includes(keyword)) {
                return false;
            }else if (fieldData[keyword] !== null) {
                if (typeof fieldData[keyword] === 'object') {
                    //JSON 타입인 경우, 하위 항목의 일치여부 확인.
                    return Object.keys(fieldData[keyword]).some(internalKeyword =>
                        String(fieldData[keyword][internalKeyword])
                            .toLowerCase()
                            .includes(value.toLowerCase())
                    )
                } else {
                    //JSON 타입이 아닌 경우,
                    return String(fieldData[keyword])
                        .toLowerCase()
                        .includes(value.toLowerCase())
                }
            }
        })
    )

    setFilterData(result);
}

/**
 *
 */
export const onExporting = (e, fileName, customizeCell) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(fileName);

    //Action Bar exclude
    e.component.beginUpdate();
    e.component.columnOption("action", "visible", false);

    exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        customizeCell: customizeCell
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }),
                `${fileName}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
        });
    });
    e.cancel = true;
}

export const headerHighlight = (gridCell, excelCell) => {
    //Header Background Color Setting
    if(gridCell.rowType === 'header') {
        excelCell.font = {
            bold: true,
            color: { argb: 'FFFFFF'}
        };

        excelCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb:'008A3E'}
        };
    }
}

export const onExported = (e) => {
    //Action Bar Include
    e.component.columnOption("action", "visible", true);
    e.component.endUpdate();
}

/**
 * Sweet Alert 화면을 띄워주는 간이 Method
 * @param title Sweet Alert에 입력할 Text
 * @param timer 나타나는 시간 설정 (0:계속 유지 / 1부터는 밀리초(ms) 적용
 * @param icon  Sweet Alert 화면에 나타나게 할 Icon화면 [success, warning, error, question]
 */
export const sweetAlert = (title, timer, icon, html = null) => {
    withReactContent(Swal).fire({icon, title, html, timer, timerProgressBar: timer>0,})
}

/**
 * sessionStorage에서 studyOID로 된 studyInfo 정보를 JSON화 시켜서 Setting한다.
 * @param studyOID
 * @returns {any|null}
 */
export const setStorageStudy = (studyOID, data, t) => {
    //예외 상황 처리
    if(isEmpty(studyOID)) {
        message.error(t('lib.study-oid.not-exist'))
    } else if(data == null || isEmptyObject(data)) {
        message.error(t('lib.data.not-exist'))
    }

    if(typeof data === 'object') {
        sessionStorage.setItem(studyOID, JSON.stringify(data));
    }
}

/**
 * sessionStorage에서 studyOID로 된 studyInfo 정보는 찾는다.
 * 찾아서 없는 경우 null을 리턴한다.
 * @param studyOID
 * @returns {any|null}
 */
export const getStorageStudy = (studyOID) => {
    return sessionStorage.getItem(studyOID)!=null?JSON.parse(sessionStorage.getItem(studyOID)):null;
}


/**
 * User가 RuntimeError를 발생 시킬 때 사용.
 * @param message
 * @returns {Error}
 */
export const runtimeError = (message, status = httpStatus.internalServerError) => {
    const error = new Error(message);
    error.status = status;
    error.msg = message;
    error.name = RUNTIME_ERROR;
    return error;
}

/**
 * @author sh.yang
 * @since 2021-08-20
 * @description Error 발생 시 일괄 처리하는 Handle Method
 * @param error Exception 발생 시, catch안에 입력되는 Error Parameter 값
 * @param navigate 경로 변경을 하기 위한 navigate 객체
 * @param redirectPath API에서 의도한 Error인 경우 Redirect 시킬 Path정보
 * @return void
 */
export const errorHandle = (error, navigate = null, redirectPath = null) => {

    if (error?.name === RUNTIME_ERROR) {
        //인위적으로 발생시킨 RUNTIME_ERROR인 경우, 아래 내용으로 처리
        const {msg} = error;
        message.error(msg + ` - [${dayjs().format('YYYY-MM-DD HH:mm:ss')}]`);
        if(navigate !== null) { //PATH가 정해져있지 않으면 error에 설정된 Status화면으로
            navigate(redirectPath??`/${error.status}`, {replace:true});
        }
    } else if (error?.response?.data?.message != null) {
        message.error(error?.response?.data?.message + ` - [${dayjs().format('YYYY-MM-DD HH:mm:ss')}]`);
        if(navigate !== null && error?.response.status === httpStatus.forbidden) {
            navigate("/403", {replace: true});
        } else if(navigate !== null && redirectPath !== null) {
            navigate(redirectPath, {replace: true});
        } else if(navigate !== null && redirectPath === null && error?.response?.status === httpStatus.internalServerError) {
            navigate("/500", {replace:true});
        } else if(navigate !== null && redirectPath === null && error?.response?.status === httpStatus.badRequest) {
            navigate("/400", {replace:true});
        }
    } else {
        message.error(error?.message + `- [${dayjs().format('YYYY-MM-DD HH:mm:ss')}]`);
        if(error?.response?.status === httpStatus.forbidden) {
            if(navigate !== null) {navigate("/403", {replace: true});}
        } else if(error?.response?.status === httpStatus.internalServerError) {
            if(navigate !== null) {navigate("/500", {replace: true});}
        }
    }
}

/**
 * Form Item의 rules 항목
 * @param rules
 * @returns {{rules: *[]}}
 */
export const itemRules = (...rules) => {
    const result = [];
    if(rules.length > 0) {
        rules.forEach((rule, index) => {
            if(Object?.keys(rule) > 0) {
                rule = {
                    message: rule?.message||null,
                    type: rule?.type||null,
                    min: rule?.min||null,
                    max: rule?.max||null,
                    len: rule?.len||null,
                    pattern: rule?.pattern||null,
                    required: rule?.required||null,
                    whitespace: rule?.whitespace||null,
                }
                result.push(rule);
            }
        })
    }

    return {rules: [...result]}
}

export const isEmptyObject = (param) => {
    return Object.keys(param).length === 0 && param.constructor === Object;
}


/**
 * 현재 Table의 td항목의 index를 찾아서 반환한다.
 * @param event
 * @returns {int} cell Index value
 */
export const getIndexOfTableCell = (event) => {
    //해당 Cell의 Index를 찾을 때까지 부모 요소로 이동.
    let target = event.target;
    while(target.cellIndex === undefined) {
        target = target.parentElement;
    }
    return target.cellIndex;
}

export default withTranslation()(setStorageStudy);