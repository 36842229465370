import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {CardBody, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {Avatar, Button, Card, Col, Form, Input, message, Row, Select} from "antd";
import {errorHandle} from "../../../../lib/BuilderUtils";
import Swal from "sweetalert2";
import classNames from "classnames";
import DefaultLoading from "../../DefaultLoading";
import {RightOutlined} from "@ant-design/icons";
import queryString from "query-string";
import {Notice} from "../../../../container/build-tool/StyleBox";
import NoDataBlock from "../../NoDataBlock";
import {useTranslation} from "react-i18next";

const ManageAccess = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const params = queryString.parse(location.search);

    const [sendLoading, setSendLoading] = useState(false);
    const [roles, setRoles] = useState(null);
    const [edcStudyInfo, setEdcStudyInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    useEffect( () => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/manage-access`, {params});
                if (status === httpStatus.ok) {
                    setRoles(data.roles);
                    setEdcStudyInfo(data.edcStudyInfo);
                }
            } catch (e) {
                errorHandle(e, navigate, "/studies");
            }
        })();

    }, []);


    const reload = async () => {
        try {
            const {status, data} = await axios.get(`/api/builder/studies/manage-access`, {params});
            if (status === httpStatus.ok) {
                setRoles(data.roles);
                setEdcStudyInfo(data.edcStudyInfo);
            }
        } catch(error) {
            errorHandle(error);
        }
    }

    /**
     * Email 초대 메일 발송
     * @param values
     */
    const onFinish = async (values) => {
        setSendLoading(true);

        try {
            const {data, status} = await axios({
                    url: `/api/builder/studies/manage-access`,
                    params: values,
                    method: 'post'
                });
            if(status === httpStatus.ok) {
                message.success(data.message);
                //Reload
                await reload();
            }
        } catch(e) {
            errorHandle(e);
        }

        setSendLoading(false);
    }

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    //Active, Inactive 상태 변경 동작
    const toggleActive = async (user, active) => {

        try{
            const res = await Swal.fire({
                title: t('studies.status.change'),
                html: t('studies.would.like.change.status',{key: active? 'INACTIVE':'ACTIVE'}),
                showCancelButton: true,
                confirmButtonColor: 'primary',
            })

            if(res.isConfirmed) {
                // Loading
                setLoading(true);

                const {status, data} = await axios({
                    url: '/api/builder/studies/manage-access',
                    params: {
                        id: params.id,
                        contractUserId: user.id
                    },
                    method: 'put'
                })

                if(status === httpStatus.ok) {
                    message.success(data.message);
                    // 변경 여부 확인 후 불러오기 동작 실행.
                    if(data.change) {
                        await reload();
                    }
                }
            }
        } catch(e) {
            errorHandle(e);
        }
        // Loading Close
        setLoading(false);
    }

    return (
        <>
            {
                (roles === null || edcStudyInfo === null) &&
                <DefaultLoading />
            }
            {
                (roles !== null && edcStudyInfo !== null) &&
                <DefaultCard>
                    <CardHeader>
                        <h3><RightOutlined className={'mr-2'} />Manage Access</h3>
                    </CardHeader>
                    <Form layout="vertical"
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}>
                        <Form.Item name={'id'} hidden initialValue={params.id}>
                            <Input />
                        </Form.Item>
                        <CardBody>
                            <Row gutter={{xs: 3, sm: 6, md: 9, lg: 12}}>
                                <Col span={'3'}>
                                    <Form.Item name={'roleId'} initialValue={roles[0]?.id}>
                                        <Select>
                                            {
                                                roles.map((role, index) => (
                                                    <Select.Option key={index} value={role.id}>
                                                        {role?.displayName}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={'3'}>
                                    <Form.Item name={'email'} rules={[{
                                        required: true, message: t('studies.enter.email')
                                    }]}>
                                        <Input placeholder={'Enter email'}/>
                                    </Form.Item>
                                </Col>
                                <Col span={'3'}>
                                    <Form.Item name={'domain'} initialValue={`@${edcStudyInfo.study.contract.domain}`}>
                                        <Input style={{background:'#eee'}} readOnly placeholder={'Enter email'}/>
                                    </Form.Item>
                                </Col>
                                <Col span={'3'}>
                                    <Button type={'primary'} htmlType={'submit'} loading={sendLoading}>
                                        <i className='fa fa-send-o mr-2'/>Send
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{userSelect: 'none'}}>
                                    <Card loading={loading} className={'mt-3'}>
                                        <Row gutter={{xs: 6, sm: 12, md: 18, lg: 24}}>
                                        {
                                            edcStudyInfo?.acl.length>0?(edcStudyInfo.acl).map((access, index, array) => (
                                                <Col key={index} span={6}>
                                                    <Card type={'inner'}
                                                          title={
                                                              <>
                                                                  {
                                                                      access.active?(
                                                                          <span className='badge badge-success p-2 font-size-16'>Active</span>
                                                                      ): (
                                                                          <span className='badge badge-warning p-2 font-size-16'>Inactive</span>
                                                                          )
                                                                  }
                                                              </>
                                                          }
                                                          extra={
                                                                  <Button size={'small'}
                                                                          onClick={() => toggleActive(access.contractUser, access.active)}>
                                                                          <i className={classNames('fe mr-2', {
                                                                              ['fe-lock']:access.active,
                                                                              ['fe-unlock']:access.active === false
                                                                          })} />
                                                                          <span>{access.active?'INACTIVE':'ACTIVE'}</span>
                                                                      </Button>
                                                          }
                                                          className={`mr-4 mb-4`}
                                                    >

                                                        <div>
                                                            <Avatar className={'mr-2'} size={"large"}>
                                                                {access.contractUser.user?.displayName[0]}
                                                            </Avatar>
                                                            <span className='font-weight-bold font-size-18'>{access.contractUser.user?.displayName}</span>
                                                        </div>
                                                        <div className={'mt-3 ml-5'}>
                                                            {access.contractUser.user?.username}
                                                        </div>
                                                    </Card>
                                                </Col>
                                            )) : (
                                                <Notice style={{borderColor: "transparent"}}>
                                                    <NoDataBlock />
                                                </Notice>
                                            )
                                        }
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </DefaultCard>
            }
        </>
    )

}

export default ManageAccess;