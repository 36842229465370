import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Button} from "antd";
import {withTranslation} from "react-i18next";

const Error500 = ({t}) => {
    const navigate = useNavigate();
    return (
        <div className="container pl-5 pr-5 pt-5 pb-5 mb-auto text-dark font-size-32">
            <div className="font-weight-bold mb-3">{t('error.title.500')}</div>
            <div className="text-gray-6 font-size-24">
                {t('error.message.500')}
            </div>
            <div className="font-weight-bold font-size-70 mb-1">{t('error.code.500')}</div>
            <Button onClick={() => {navigate(-2)}} className="btn btn-outline-primary width-100">
                {t('btn.go-back')}
            </Button>
        </div>
    )
}

export default withTranslation()(Error500);
