import {Link, useNavigate, useParams} from "react-router-dom";
import React, {createRef, useEffect, useRef, useState} from "react";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {Button, message} from "antd";
import DefaultLoading from "../../DefaultLoading";
import {CardBody, CardFooter, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {RightOutlined, SaveOutlined, WarningOutlined} from "@ant-design/icons";
import {Form, Table} from "reactstrap";
import style from "../studies/style.module.scss";
import {Notice} from "../../../../container/build-tool/StyleBox";
import _ from "lodash";
import {usePrompt} from "../../../../lib/Blocker";
import {useTranslation} from "react-i18next";

const SponsorRolesPrivileges = () => {

    const params = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [roles, setRoles] = useState(null);
    const [privileges, setPrivileges] = useState(null);
    const [savedRolePrivileges, setSavedRolePrivileges] = useState("init");
    const [loading, setLoading] = useState(false);
    const [change, setChange] = useState(false);
    const rolePrivilegesRef = useRef([]);

    //변경 감지 후 확인 창 띄우기
    usePrompt(change, t);


    useEffect( () => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/sponsors/${params.sponsorId}/sponsor-roles-privileges `);

                if (status === httpStatus.ok) {
                    //set Data
                    setRoles(data?.roles);
                    setPrivileges(data?.privileges);
                    setSavedRolePrivileges(data?.savedRolePrivileges || []);
                }
            } catch (e) {
                errorHandle(e, navigate, `/sponsors/${params.sponsorId}/sponsor-info`);
            }
        })()
    }, [])


    useEffect(() => {
        if (roles != null && privileges != null) {
            if (rolePrivilegesRef.current?.length !== roles.length * privileges?.length) {
                // Add or Remove refs
                rolePrivilegesRef.current = Array(roles.length * privileges.length).fill().map((_, i) => rolePrivilegesRef.current[i] || createRef());
            }
        }

    }, [roles, privileges]);

    const onChangedCheck = () => {
        //변경 여부 확인.
        const rolePrivileges = rolePrivilegesRef.current.filter(rp => rp.current?.checked === true).map(rp => rp.current?.defaultValue);
        setChange(!_.isEqual(savedRolePrivileges.sort(), rolePrivileges.sort()));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            //rolePrivileges의 ref값을 가져와서 checked된 값만 세팅함.
            const body = {
                oldRolePrivileges: savedRolePrivileges,
                rolePrivileges: rolePrivilegesRef.current.filter(rp => rp.current.checked === true).map(rp => rp.current.defaultValue),
            };

            const {status, data} = await axios({
                url: `/api/builder/sponsors/${params.sponsorId}/sponsor-roles-privileges`,
                data: body,
                method: 'post'
            })

            if (status === httpStatus.ok) {
                //message
                message.success(data?.message);
                setSavedRolePrivileges(data?.savedRolePrivileges);
                setChange(false);
            }
        } catch (e) {
            errorHandle(e);
        }
        setLoading(false);
    }

    return (
        <>
            {
                (roles === null || privileges === null || savedRolePrivileges === 'init') &&
                <DefaultLoading/>
            }
            {
                (roles !== null && privileges !== null && savedRolePrivileges !== "init") &&
                <DefaultCard>
                    <CardHeader>
                        <h3><RightOutlined className={'mr-2'}/>Role & Privilege</h3>
                    </CardHeader>
                    <Form onSubmit={onSubmit}>
                        <CardBody>
                            {
                                roles?.length > 0 ? (
                                    <Table bordered striped hover>
                                        <thead>
                                        <tr className={style.headerSticky}>
                                            <th className={`${style.backslash} stop-dragging`}>
                                                <div>Role</div>
                                                Privilege
                                            </th>
                                            {
                                                roles.map((role, roleIdx) => (
                                                    <th key={roleIdx} className='stop-dragging width-150'
                                                        style={{textAlign: 'center', verticalAlign: 'middle'}}
                                                        scope={'row'}>
                                                        <div>{role?.shortName}</div>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            privileges.map((privilege, privilegeIdx) => (
                                                <tr key={privilegeIdx} className={'stop-dragging'}>
                                                    <th style={{verticalAlign: 'middle'}}>
                                                        {privilege?.description}
                                                    </th>
                                                    {
                                                        roles.map((role, roleIdx) => (
                                                            <td key={roleIdx} className='text-center'>
                                                                <input type={'checkbox'} className={style.roleCheckbox}
                                                                       onClick={onChangedCheck}
                                                                       ref={rolePrivilegesRef.current[(privilegeIdx * roles.length) + roleIdx]}
                                                                       value={`${role.id},${privilege.id}`}
                                                                       defaultChecked={savedRolePrivileges != null && savedRolePrivileges.includes(`${role.id},${privilege.id}`)}
                                                                />
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                ) : (
                                    <Notice>
                                        <h2 className={'mt-2'}>
                                            <span className={'mr-4'}>{t('sponsor.no.registered.role.info')}</span>
                                            <Link to={`/sponsors/${params.sponsorId}/sponsor-roles`}>
                                                <span className='text-info'>[<i className='fa fa-external-link'/>{t('sponsor.move.to.role.menu')}]</span>
                                            </Link>
                                        </h2>
                                    </Notice>
                                )
                            }
                        </CardBody>
                        {
                            change &&
                            <CardFooter className='text-right'>
                                <Button loading={loading} icon={<SaveOutlined/>} type={'primary'} htmlType={'submit'}>Save</Button>
                            </CardFooter>
                        }
                    </Form>
                </DefaultCard>
            }
        </>
    )
}

export default SponsorRolesPrivileges;