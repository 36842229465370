import {Alert, Col, Input, Modal, Row, Table, Tag} from "antd";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import ModalFooter from "./ModalFooter";
import axios from "axios";
import {errorHandle, onSearch} from "../../../lib/BuilderUtils";
import {httpStatus} from "../../../lib/createRequestSaga";
import {useParams} from "react-router-dom";
import _ from 'lodash';
import {toMetaData} from "../../../lib/ODMUtils";
import style from "./style.module.scss";
import {metadataActions} from "../../../redux/module/metadata";
import {isEmpty} from "../../../lib/StringUtils";
import {useTranslation} from "react-i18next";

const ControlledTerminology = ({defVisible, setDefVisible, defType, setDefType, defOption, setDefOption}) => {

    //Redux
    const dispatch = useDispatch();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const metaData = toMetaData(metaDataStore.study);
    const params = useParams();
    const {t} = useTranslation();

    //속성 정보
    const propName = 'itemDefs';
    const [title, setTitle] = useState(null);
    const [ctData, setCtData] = useState(null);
    const [filteredCtData, setFilteredCtData] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [error, setError] = useState(null);
    const [itemDefList, setItemDefList] = useState(null);
    const [keyword, setKeyword] = useState(null);

    const columns = [
        {
            title: 'Concept id',
            dataIndex: 'conceptId',
            key: 'conceptId',
            width: 100,
        },
        {
            title: 'Submission Value',
            dataIndex: 'submissionValue',
            key: 'submissionValue',
            width: 150,
        },
        {
            title: 'Preferred Term',
            dataIndex: 'preferredTerm',
            key: 'preferredTerm',
        },
        {
            title: 'Definition',
            dataIndex: 'definition',
            key: 'definition'
        }
    ]


    useEffect( () => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/studies/${params.sid}/${defType}/controlled-terminology`);
                if(status === httpStatus.ok) {
                    setCtData(data?.terms);
                    setTitle(data?.title);
                }
            } catch (e) {
                errorHandle(e);
            }
        })();
    }, [defType]);


    //MetaDataStore 정보 변경 시, 나감
    useEffect(() => {
        if(metaDataStore.loaded === true && itemDefList !== null) {
            onCancel();
            setDefOption({
                ...defOption,
                done: true,
                option: 'controlledTerminology',
                itemOIDs: itemDefList.map(itemDef => itemDef?.oid)
            })
        }
    }, [metaDataStore]);

    const onCancel = () => {
        setDefVisible(false);
        setDefType(null);
    }

    //모달 저장 동작.
    const onSave = () => {
        if(selectedRows !== null && selectedRows?.length > 0) {
            const selectedInfoList = _.cloneDeep((filteredCtData === null?ctData:filteredCtData).filter(s => selectedRows.includes(s.conceptId)));
            const itemDefList = selectedInfoList.map((info) =>
                ({
                    oid: info?.submissionValue,
                    name: info?.preferredTerm,
                    dataType: 'TEXT',
                    length: null,
                    fractionDigits: null,
                })
            );

            const data = {attr: propName, defList: itemDefList, params:params};
            dispatch(metadataActions.creates(data));
            setItemDefList(itemDefList);
        } else {
            setError(t('message.no.row.items.have.been.selected'));
        }
    }

    return (
        <Modal
            title={title||'Data Loading...'}
            width={1200}
            onCancel={onCancel}
            destroyOnClose={true}
            open={defVisible}
            styles={{body:{overflowY: 'auto', height: 'calc(100vh - 25rem)'}}}
            footer={<ModalFooter onCancel={onCancel} onSave={onSave} />}
        >
            <Row gutter={[0, 10]}>
                <Col span={12}>
                    {
                        isEmpty(error) === false &&
                        <Alert showIcon type={'error'} message={error} />
                    }
                </Col>
                <Col offset={4} span={8}>
                    <Input.Search
                        placeholder="Search by..."
                        onSearch={(value) => {
                            onSearch(ctData, setFilteredCtData, value, ['conceptId', 'submissionValue', 'preferredTerm']);
                            setKeyword(value);
                        }}
                        enterButton
                    />
                </Col>
                <Col span={24}>
                    <Table size={'small'} columns={columns}
                           dataSource={filteredCtData === null ? ctData : filteredCtData} loading={ctData === null}
                           rowKey={'conceptId'} rowClassName={(record) => metaData.item.has(record?.submissionValue) && style.disabledRow}
                           key={'ControlledTerminology'}
                           rowSelection={{
                               selectedRowKeys:selectedRows,
                               onChange: (selectedRows) => {
                                   setSelectedRows(selectedRows);
                               },
                           }}
                           pagination={{defaultPageSize:5, total:(filteredCtData===null?ctData:filteredCtData)?.length||0}}/>
                </Col>
            </Row>
        </Modal>

    )
}

export default ControlledTerminology;