import {Card, List, Modal, Typography} from "antd";

const PublishRecordErrorModal = ({visible, setVisible, messages}) => {

    //,로 구분하여 메세지 출력
    const messageList = visible?messages.split('||')??[]:[];

    return (
        <Modal forceRender={true}
               open={visible}
               width={1000}
               onCancel={() => setVisible(false)}
               destroyOnClose={true}
               okButtonProps={{className:'d-none'}}
        >
            <Card title={<span className={'font-size-24'}><i className='fa fa-file-code-o'></i> Error Messages</span>}>
                <List size={'small'}>
                    {
                        messageList.map((message,idx) => (
                            <List.Item key={idx} style={{border:'solid #ddd', borderWidth:'0px 0px 0px 2px' }} >
                                <Typography.Text type={'danger'}>{message}</Typography.Text>
                            </List.Item>
                        ))
                    }
                </List>
            </Card>
        </Modal>
    )

}

export default PublishRecordErrorModal;