import {Helmet} from "react-helmet-async";
import CRFVersionInfo from "../../../../../components/common/builder/studies/CRFVersionInfo";

const CRFVersionInfoPage = () => {

    return (
        <>
            <Helmet title={'BUILDER :: CRF Versions Info'} />
            <CRFVersionInfo />
        </>
    )
}

export default CRFVersionInfoPage;