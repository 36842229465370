import {
    Button,
    Card,
    Col,
    Drawer,
    Form,
    Layout,
    Mentions,
    message,
    Modal,
    Popover,
    Radio,
    Row,
    Select,
    Space,
    Switch,
    Table, Tag,
    Upload
} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import styles from "./style.module.scss";
import {CardBody, CardHeader} from "../../pages/PageStyleBox";
import {toMetaData} from "../../lib/ODMUtils";
import {isEmpty} from "../../lib/StringUtils";
import {useTranslation} from "react-i18next";
import {
    DownloadOutlined,
    ExclamationCircleOutlined,
    HistoryOutlined,
    ReloadOutlined,
    UploadOutlined
} from "@ant-design/icons";
import axios from "axios";
import {errorHandle} from "../../lib/BuilderUtils";
import DataGrid, {Column, LoadPanel, Pager, Paging} from "devextreme-react/data-grid";
import {metadataActions} from "../../redux/module/metadata";
import {downloadFile} from "../../lib/FileUtils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export const httpStatus = {
    ok: 200,
    duplicateLogin: 300,
    badRequest: 400,
    unAuthorized: 401,
    paymentRequired: 402,
    forbidden: 403,
    notFound: 404,
    methodNotAllowed: 405,
    notAcceptable: 406,
    internalServerError: 500,
}

const { Option } = Mentions;

const IndependentEvaluationDef = () => {

    const metaDataStore = useSelector(({metaDataStore}) => (metaDataStore));
    const metaData = toMetaData(metaDataStore.study);
    const studyOID = metaData?.studyOID;

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [disabledView, setDisabledView] = useState(false);

    const [independentEvaluationDef, setIndependentEvaluationDef] = useState(null);

    const [pictureCodeType, setPictureCodeType] = useState([]); // All picture code type load
    const [selectPicCodeType, setSelectPicCodeType] = useState(null);

    const [pictureCodeList, setPictureCodeList] = useState(null);
    const [parsingPictureCodeList, setParsingPictureCodeList] = useState([]);
    const [parsingHistoryId, setParsingHistoryId] = useState(null);
    const [isUploadType, setIsUploadType] = useState(false);

    const [openParsingList, setOpenParsingList] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const [historyList, setHistoryList] = useState(null);
    const [downloadLog, setDownloadLog] = useState(null);

    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/independent-evaluation/picture-code`);

                if (status === httpStatus.ok) {
                    if(data?.historyList)
                        setHistoryList(data?.historyList);

                    if(data?.downloadLog)
                        setDownloadLog(data?.downloadLog);

                    if(data?.pictureCodeList.length > 0) {
                        setIsUploadType(selectPicCodeType === "Upload");
                        setPictureCodeList(data?.pictureCodeList);
                        form.setFieldsValue({ pictureCodeList: pictureCodeList});
                        // console.log(data?.pictureCodeList);
                    }
                }
            } catch (e) {
                errorHandle(e, navigate);
            }
        })();

    }, []);

    useEffect(() => {
        if(metaDataStore.loaded === true) {
            // load picture code
            setPictureCodeType(metaDataStore.pictureCodeType);

            // set data
            const mdv = metaDataStore.study.metaDataVersion[0];
            const independentEvaluationDef = mdv?.protocol?.independentEvaluationDef == null ? metaDataStore.initialValue.independentEvaluation : mdv?.protocol?.independentEvaluationDef;
            const selectPicCodeType = independentEvaluationDef?.pictureCodeType == null ? "UPLOAD" : independentEvaluationDef?.pictureCodeType;
            const pictureCodeDef = independentEvaluationDef?.pictureCodeDef;

            setOpenHistory(false);
            setIndependentEvaluationDef(independentEvaluationDef);
            setSelectPicCodeType(selectPicCodeType);
            form.setFieldsValue({ selectPicCodeType: selectPicCodeType, pictureCodeDef: pictureCodeDef});
        }
    }, [metaDataStore])

    const onFinish = async (values) => {
        console.log("*** Result of Values : ", values);

        setLoading(true);

        values.params = params;
        const dataIndependentEvaluationDef = {
            ...metaData?.independentEvaluationDef,
            ...{pictureCodeType: values.selectPicCodeType,
                pictureCodeDef: values.pictureCodeDef}
        }
        const data = {
            ...dataIndependentEvaluationDef,
            ...values
        }
        dispatch(metadataActions.updateIndependentEvaluation(data));

        setLoading(false);
    }

    const onFinishFailed = (e) => {
        console.error(e);
    }

    const checkMention = async (_, value) => {
        if(isEmpty(value) /*&& useRandomization*/){
            return Promise.reject(new Error(t('message.warning.required-entry')));
        } else {
            const siteCode = RegExp(/\${SiteCode}/g);
            const seq = RegExp(/\${Seq}/g);
            const test = /[${} ]/g;

            if(!seq.test(value))
                return Promise.reject(new Error(t('message.warning.required-entry')));

            const resultText = value.replaceAll(siteCode, '').replaceAll(seq, '');
            if(test.test(resultText))
                return Promise.reject(new Error("정해진 형식 외의 값은 입력할 수 없습니다. ex:SiteCode, Seq"));
        }
    };

    const onChangePicCode = (name) => {

        let value = form.getFieldValue(["pictureCodeDef", name]) || "";

        setIndependentEvaluationDef({
            ...independentEvaluationDef,
            pictureCodeDef:{
                ...independentEvaluationDef.pictureCodeDef,
                [name]: value
            }
        });
        form.setFieldsValue({pictureCodeDef:{[name]: value}});
    }

    const uploadProps = {
        name: "file",
        method: "POST",
        showUploadList : false,
        headers: {authorization: "authorization-text", contentType: 'application/json'},
        action:  `/api/builder/studies/${studyOID}/independent-evaluation/picture-code/upload-parsing`,
        beforeUpload : (file) => {
            let fileType = file.name.split('.').pop().toLowerCase();
            const isXlsx = fileType === 'xlsx'

            if(!isXlsx){
                message.error(`[ ${file.name} ] is not a xlsx file.`);
                return Upload.LIST_IGNORE;
            }
        },
        onSuccess(response, file) {
            if (response) {
                if (response?.status === httpStatus.ok) {
                    setParsingPictureCodeList(response?.parsingPictureCodeList);
                    setParsingHistoryId(response?.historyId);
                    setOpenParsingList(true);
                } else {
                    message.warning(response?.message);
                }
            }
        },
        onError(xhr, error) {
            errorHandle(error);
        }
    };

    return (
        selectPicCodeType != null &&
        <Layout className={classNames('card', {[`${styles.blockingArea}`]: disabledView})}>
            <CardHeader>
                <h3>Independent Evaluation Setting</h3>
            </CardHeader>
            <CardBody>
                <Form name={"independent-evaluation-form"}
                      layout="vertical"
                      form={form}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      style={{position:"relative"}}
                >
                    <Row gutter={[20, 0]}>
                        <Col span={8}>
                            <Card title={'Picture Code Setting'} style={{height:'100%'}}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item name={'selectPicCodeType'}>
                                            <Radio.Group defaultValue={selectPicCodeType}
                                                         disabled={isUploadType}
                                                         onChange={(e) => {
                                                             setSelectPicCodeType(e.target.value);
                                                             form.setFieldsValue({selectPicCodeType: e.target.value});
                                                         }}>
                                                <Space direction="horizontal">
                                                    {
                                                        Object.keys(pictureCodeType).map((type) => (
                                                            <Radio key={type} disabled={type === 'SEQUENTIAL_GENERATION'} value={type}>
                                                                {pictureCodeType[type]}
                                                            </Radio>
                                                        ))
                                                    }
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={16}>
                            {
                                (
                                    <>
                                        {
                                            selectPicCodeType === "SEQUENTIAL_GENERATION" ?
                                                <PictureCodeSequence loading={loading} t={t}
                                                                     independentEvaluationDef={independentEvaluationDef}
                                                                     checkMention={checkMention}
                                                                     onChangePicCode={onChangePicCode}/>
                                                :
                                                <PictureCodeUpload t={t} params={params} navigate={navigate}
                                                                   location={location}
                                                                   pictureCodeList={pictureCodeList}
                                                                   setPictureCodeList={setPictureCodeList}
                                                                   uploadProps={uploadProps}
                                                                   setIsUploadType={setIsUploadType}
                                                                   downloadLog={downloadLog}
                                                                   setDownloadLog={setDownloadLog}
                                                                   openHistory={openHistory}
                                                                   setOpenHistory={setOpenHistory}
                                                                   historyList={historyList}
                                                                   setHistoryList={setHistoryList}
                                                                   parsingPictureCodeList={parsingPictureCodeList}
                                                                   openParsingList={openParsingList}
                                                                   parsingHistoryId={parsingHistoryId}
                                                                   setOpenParsingList={setOpenParsingList}
                                                />
                                        }
                                    </>
                                )
                            }
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>
                        <Col span={24} style={{textAlign: "right"}}>
                            <Button loading={loading} type={'primary'} htmlType={'submit'}>Save</Button>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Layout>
    )
}

const PictureCodeSequence = ({loading, t, checkMention, onChangePicCode, independentEvaluationDef}) => {

    const generateNumber = ({length, leftPadWithZero, initialNumber, autoGenerated}) => {

        let strNumber = '';
        if(autoGenerated) {
            strNumber = `${initialNumber}`;
            if(leftPadWithZero && length > strNumber?.length) {
                const repeat = length - strNumber?.length;
                for(let i=0; i<repeat; i++) { strNumber = '0' + strNumber; }
            }
        } else {
            if (length === 2) {
                strNumber = (leftPadWithZero ? '0' : '') + '1';
            } else if (length === 3) {
                strNumber = (leftPadWithZero ? '00' : '') +'1';
            } else if (length === 4) {
                strNumber = (leftPadWithZero ? '000' : '') + '1';
            } else if (length === 5) {
                strNumber = (leftPadWithZero ? '0000' : '') + '1';
            }
        }
        return strNumber;
    };

    const PicCodePreview = () => {
        const pictureCodeDef = independentEvaluationDef?.pictureCodeDef;
        const seq = RegExp(/\${Seq}/g);
        let format = '';
        if(seq.test(pictureCodeDef?.format)){
            format = pictureCodeDef?.format.replace(seq, generateNumber(pictureCodeDef));
        }
        return(
            <>
                <h3>
                    <span className={'badge badge-info mr-1'}>{format}</span>
                </h3>
            </>
        )
    };

    return (
        <Card title={'Sequential Generation Setting'}>
            <Row gutter={[20, 0]}>
                <Col span={10}>
                    <Form.Item name={['pictureCodeDef', 'format']}
                               label={'Picture Code Format'}
                               rules={[{required: true, validator: checkMention,},]}>
                        <Mentions
                            placeholder="Picture Code Format"
                            prefix={'$'}
                            split={''}
                            onChange={() => onChangePicCode('format')}
                        >
                            <Option value={'{SiteCode}'}>SiteCode</Option>
                            <Option value={'{Seq}'}>Seq</Option>
                        </Mentions>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={['pictureCodeDef', 'length']} label={'Sequence Length'}
                               rules={[{required: true, message: t('message.warning.required-entry')}]}>
                        <Select onChange={() => onChangePicCode('length')}>
                            <Select.Option key={0} value={""}>...</Select.Option>
                            <Select.Option key={2} value={2}>2</Select.Option>
                            <Select.Option key={3} value={3}>3</Select.Option>
                            <Select.Option key={4} value={4}>4</Select.Option>
                            <Select.Option key={5} value={5}>5</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item name={["pictureCodeDef", "leftPadWithZero"]}
                               label={'Left Pad With Zero'} valuePropName={'checked'}>
                        <Switch onChange={() => onChangePicCode('leftPadWithZero')}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <PicCodePreview/>
                </Col>
            </Row>
            <Row>
                <Col span={24} className={'mt-2'} style={{textAlign:"right"}}>
                    <Button loading={loading} type={'primary'} htmlType={'submit'}>Save</Button>
                </Col>
            </Row>
        </Card>
    )
}
const PictureCodeUpload = ({   t, params, navigate, location,
                               pictureCodeList, setPictureCodeList,
                               uploadProps, setIsUploadType,
                               downloadLog, setDownloadLog,
                               openHistory, setOpenHistory,
                               historyList, setHistoryList,
                               parsingPictureCodeList, parsingHistoryId,
                               openParsingList, setOpenParsingList,
                           }) => {

    const {sid:studyOID, id} = params;

    const historyColumns = [
        {
            title: 'No',
            dataIndex: 'id',
            align: "center",
            width: '5%',
            render: (row, record) => {
                return (historyList.indexOf(record)) + 1;
            }
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            align: "center",
            width: '20%',
            render: (value) => {
                return value.username;
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            align: "center",
            width: '20%',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            align: "center",
            width: '10%'
        },
        {
            title: 'Status',
            dataIndex: 'resultType',
            align: "center",
            width: '10%',
            render: (row, record) => {
                let color;
                switch(row) {
                    case 'PASS': color = 'geekblue'; break;
                    case 'FAIL': color = 'red'; break;
                    case 'PARSED': color = 'default'; break;
                    case 'CANCEL': color = 'warning'; break;
                }

                return <Tag color={color} >{row}</Tag>
            }
        },
        {
            title: 'Item',
            dataIndex: 'uploadCount',
            align: "center",
            width: '10%'
        },
        {
            title: 'File',
            align: "center",
            dataIndex: 'id',
            width: '20%',
            render: (row, record) => {
                if(record.type === "RESET")
                    return "";
                return (
                    <>
                        {
                            record?.resultType === 'FAIL' &&
                            <Popover title={t('ie.error.message.title')}
                                     placement={'left'}
                                     trigger={'click'} id={record?.id}
                                     content={record?.errorMessages}>
                                <Button className={'mr-2'} icon={<ExclamationCircleOutlined />} type={'default'} />
                            </Popover>
                        }
                        <Button icon={<DownloadOutlined />} onClick={() => onHistoryFileDownload(row)} />
                    </>
                )
            }
        }
    ];

    const onHistoryFileDownload = (id) => {
        axios({
            url: `/api/builder/studies/${studyOID}/independent-evaluation/picture-code/download/file`,
            responseType: 'blob',
            method: 'get',
            params: {id}
        }).then(({headers, status, data}) => {
            if (status === httpStatus.ok) {
                downloadFile(headers, data);

                //Nested Promise Result.
                axios({
                    url: `/api/builder/studies/${studyOID}/independent-evaluation/picture-code/download/files/log`,
                    method: 'get',
                }).then(({status, data}) => {
                    if(status === httpStatus.ok) {
                        setDownloadLog(data?.downloadLog);
                    }
                })
            }
        }).catch((e) => {
            if (e?.response?.data?.message != null) {
                message.error(e?.response?.data.message);
            } else {
                message.error(e?.message);
            }
        })
    }

    const onOpenHistory = () => {
        setOpenHistory(true);
    }

    const onCloseHistory = () => {
        setOpenHistory(false);
    }

    const onReset = () => {
        const resetModal = withReactContent(Swal);
        resetModal.fire({
            title: <strong>{t('ie.picture.code.reset.modal.title')}</strong>,
            icon: 'info',
            iconColor: 'green',
            html: `<Space>
                        <h6 class="alert alert-info">
                            <span>${t('ie.modal.picture-code.reset-except-used.message')}</span><br>
                            <span>${t('ie.modal.picture-code.reset-enter-password.message')}</span>
                        </h6>
                   </Space>`,
            input: "password",
            inputPlaceholder: t('input.placeholder.password'),
            inputAttributes: {
                autocomplete: 'off',
            },
            showCancelButton: true,
            cancelButtonText: `<i class="fa fa-close" /> ${t('btn.cancel')}`,
            confirmButtonText: `<i class="fa fa-check" /> ${t('btn.submit')}`,
            showLoaderOnConfirm: true,
            preConfirm: (password) => {
                if (password === '') {
                    resetModal.showValidationMessage('password');
                    return;
                }
                return axios({
                    url: `/api/builder/studies/${studyOID}/independent-evaluation/picture-code/reset`,
                    data: {
                        oid: studyOID,
                        password: password
                    },
                    method: 'put'
                }).then(response => {
                    setHistoryList(response?.data?.historyList);
                    setPictureCodeList(response?.data?.pictureCodeList);
                    setIsUploadType(false);

                    message.success(response?.data?.message);

                }).catch(({response: {data}}) => {
                    resetModal.showValidationMessage(data.message);
                })
            },
        })
    }

    const onDownload = async() => {
        try{
            const {headers, status, data} = await axios({
                url: `/api/builder/studies/${studyOID}/independent-evaluation/picture-code/download/template`,
                responseType: 'blob',
                method:'get'
            });

            if(status === httpStatus.ok) {
                downloadFile(headers, data);
                navigate(location.pathname);
            }

        } catch(e) {
            if (e?.response?.data?.message != null) {
                message.error(e?.response?.data.message);
            } else {
                message.error(e?.message);
            }
        }
    }

    const expandedRowRender = (record) => {
        const data = downloadLog.filter(d => d.pictureCodeHistory.id === record.id);
        const columns = [
            {
                title: 'No',
                dataIndex: 'id',
                key: 'id',
                align: "center",
                width: '10%',
                render: (row, record, idx) => {
                    return idx + 1;
                }
            },
            {
                title: 'User',
                dataIndex: 'createdBy',
                align: "center",
                width: '30%',
                render: (value) => {
                    return value.username;
                }
            },
            {
                title: 'Download Date',
                dataIndex: 'createdDate',
                align: "center",
                width: '30%',
            },
            {
                title: 'Number of Downloads',
                dataIndex: 'downloadCnt',
                align: "center",
                width: '30%',
            }
        ];

        return <Table columns={columns}
                      dataSource={data}
                      pagination={false}
                      size={"small"}/>;
    };

    const rowExpandable = (record) => {
        return downloadLog.some(d => d.pictureCodeHistory.id === record.id);
    };

    const ParsingListModal = () => {
        return (
            <Modal title={<span className={'mr-4'}>{t('ie.modal.picture-code.upload.title')}</span>}
                   onOk={onUploadPictureCode}
                   okText={t('ie.modal.upload.btn')}
                   onCancel={onUploadCancelPictureCode}
                   cancelText={t('ie.modal.upload-cancel.btn')}
                   width={500}
                   maskClosable={false}
                   open={openParsingList}
                   destroyOnClose={false}
            >
                <div>
                    <span className={'mb-4'} style={{whiteSpace:'wrap-line'}}>{t('ie.modal.picture-code.upload.message')}</span>
                    <Table dataSource={parsingPictureCodeList} scroll={{y: 350, scrollToFirstRowOnChange:true}}
                           bordered pagination={false} size={"small"}>
                        <Table.Column title={'Subject'} align={'center'} dataIndex={'subjectKey'} />
                        <Table.Column title={'Env'} align={'center'} dataIndex={'env'} />
                        <Table.Column title={'Visit Num'} align={'center'} dataIndex={'visitNum'} />
                        <Table.Column title={'Picture Code'} align={'center'} dataIndex={'pictureCode'} />
                    </Table>
                </div>
            </Modal>
        )
    }

    const onUploadPictureCode = async (e) => {
        try {
            const {status, data} = await axios({
                url: `/api/builder/studies/${studyOID}/independent-evaluation/picture-code/upload`,
                method: 'post',
                data: {
                    historyId: parsingHistoryId,
                    pictureCodeList: parsingPictureCodeList,
                    resultType: 'PASS'
                },
            });

            if (status === httpStatus.ok) {
                setHistoryList(data?.historyList);
                setPictureCodeList(data?.pictureCodeList);
                setOpenParsingList(false);
                message.success(data?.message);
            }
        } catch(e) {
            const {response} = e;
            const {data} = response;
            setHistoryList(data?.historyList);
            setOpenParsingList(false);
            errorHandle(e);
        }
    }

    const onUploadCancelPictureCode = async (e) => {
        try {
            const {status, data} = await axios({
                url: `/api/builder/studies/${studyOID}/independent-evaluation/picture-code/upload`,
                method: 'post',
                data: {
                    historyId: parsingHistoryId,
                    pictureCodeList: parsingPictureCodeList,
                    resultType: 'CANCEL'
                },
            });

            if (status === httpStatus.ok) {
                setHistoryList(data?.historyList);
                setOpenParsingList(false);
                message.warning(data?.message);
            }
        } catch(e) {
            const {response} = e;
            const {data} = response;
            setHistoryList(data?.historyList);
            setOpenParsingList(false);
            errorHandle(e);
        }
    }

    return (
        <div>
            <Card title={'Upload Setting'}>
                <Row gutter={[20, 0]}>
                    <Col span={24}>
                        <ParsingListModal />
                        <Form.Item name={'pictureCodeList'}>
                            <DataGrid dataSource={pictureCodeList}
                                      id={'pictureCode'}
                                      allowedPageSizes={true}
                                      allowColumnResizing={false}
                                      columnMinWidth={50}
                                      columnAutoWidth={true}
                                      showColumnLines={true}
                                      showRowLines={true}
                                      showBorders={true}
                                      hoverStateEnabled={true}
                                      allowColumnReordering={true}
                            >
                                <Column caption={('No')} fixed={true} fixedPosition={'left'} width={'60px'} alignment={'center'}
                                        cellTemplate={(cellElement, cellInfo) => {
                                            const dataGrid = cellInfo.component;
                                            const num = dataGrid.pageIndex() * dataGrid.pageSize() + cellInfo.rowIndex;
                                            cellElement.innerText= num + 1;
                                        }}
                                />
                                <Column dataField={'env'} caption={'Env'} dataType={'string'} alignment={'center'} />
                                <Column dataField={'subjectKey'} caption={('Subject')} dataType={'string'} alignment={'center'} />
                                <Column dataField={'visitNum'} caption={('Visit Num')}  dataType={'number'} alignment={'center'} />
                                <Column dataField={'pictureCode'} caption={'Picture Code'} dataType={'string'} alignment={'center'} />
                                {/*<Toolbar>*/}
                                {/*<Item name={'columnChooserButton'}/>*/}
                                {/*<Item name={'exportButton'}/>*/}
                                {/*</Toolbar>*/}
                                {/*<ColumnChooser enabled={true}/>*/}
                                {/*<Export enabled={true} allowExportSelectedData={false}/>*/}
                                <LoadPanel enabled={true}/>
                                {/*<HeaderFilter visible={true} allowSearch={true}/>*/}
                                {/*<FilterRow visible={true} applyFilter={'auto'}/>*/}
                                <Paging defaultPageSize={10}/>
                                <Pager
                                    visible={true}
                                    displayMode={'compact'}
                                    // allowedPageSizes={[10, 20, 30, 50]}
                                    // showPageSizeSelector={true}
                                    // showInfo={true}
                                />
                            </DataGrid>
                        </Form.Item>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col span={12} style={{textAlign:"left", display:"flex"}}>
                        <Upload {...uploadProps}>
                            <Button className={'mr-2'} icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Col>
                    <Col span={12} style={{textAlign:"right"}}>
                        <Button className={'mr-2'} icon={<ReloadOutlined />} onClick={onReset}>Reset</Button>
                        <Button className={'mr-2'} icon={<DownloadOutlined />} onClick={onDownload}>Template</Button>
                        <Button icon={<HistoryOutlined />} onClick={onOpenHistory}> History </Button>
                    </Col>
                    <Drawer title={"Picture Code History"} placement="right" size={'large'}
                            open={openHistory} onClose={() => onCloseHistory()} >
                        <Table columns={historyColumns}
                               rowKey={row => row.id}
                               dataSource={historyList}
                               pagination={{ pageSize: 10 }}
                               bordered={true}
                               expandable={{
                                   expandedRowRender,
                                   rowExpandable,
                                   defaultExpandedRowKeys: ['0'],
                               }}
                               size={"middle"}
                        />
                    </Drawer>
                </Row>
            </Card>
        </div>
    )
}

export default IndependentEvaluationDef;