import React, {useEffect, useState} from 'react';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import {useDispatch, useSelector} from "react-redux";
import {metadataActions} from "../../../redux/module/metadata";
import {codeListRefCheck, descriptionCheck, questionCheck, toMetaData, valueListRefCheck} from "../../../lib/ODMUtils";
import ItemDef from "../modal/odm/ItemDef";
import {useParams} from "react-router-dom";

const ItemContainer = ({defVisible, setDefVisible, defType, defItem, setDefItem, defOption, setDefOption}) => {

    //Redux
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);

    //공통
    const propName = 'itemDef';
    const [initialValue, setInitialValue] = useState({});
    const [selectedOID, setSelectedOID] = useState("");

    const [create, setCreate] = useState(false);
    const [languages, setLanguages] = useState({});

    //컴포넌트에 따라 필요한 정보
    const [itemDef, setItemDef] = useState(initialValue);
    const [dataTypes, setDataTypes] = useState([]);
    const [units, setUnits] = useState([]);
    const [codeLists, setCodeLists] = useState([]);
    const [valueLists, setValueLists] = useState([]);
    const [metaData, setMetaData] = useState(null);

    useEffect(() => {

        if (metaDataStore.loaded === true) {
            setInitialValue(metaDataStore.initialValue.item);
            setDataTypes(metaDataStore.dataType);
            setUnits(metaDataStore.units);
            setLanguages(metaDataStore.languages);

            const meta = toMetaData(metaDataStore.study);
            setMetaData(meta);
            setValueLists(Array.from(meta.valueList.values()));
            setCodeLists(Array.from(meta.codeList.values()));
        }

        if (defItem !== null) { //Update
            setSelectedOID(defItem.oid);
            setItemDef(defItem);
            setCreate(false);
        } else if (defItem === null) { //New
            setItemDef(initialValue);
            setSelectedOID("");
            setCreate(true);
        }

    }, [metaDataStore, defItem]);

    //데이터 등록/수정 함수
    const onFinish = (newItemDef) => {
        descriptionCheck(newItemDef); // description undefined Check 후, 없으면 삭제
        questionCheck(newItemDef); // question undefined Check 후, 없으면 삭제
        codeListRefCheck(newItemDef); // codeList undefined Check 후, 없으면 삭제
        valueListRefCheck(newItemDef); // valueList undefined Check 후, 없으면 삭제

        newItemDef.attr = propName;
        newItemDef.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
        if (create) {
            dispatch(metadataActions.create(newItemDef));
        } else {
            //const idx = metaDataVersions[selectedVal.verIndex][propName].findIndex(o => o.oid === itemGroupDef.oid);
            dispatch(metadataActions.update(newItemDef));
            //현재 저장된 ItemDef의 oid값 가져오기.
        }

        onCancel(); //Modal 화면 사라짐.
        setDefOption({
            ...defOption,
            itemOID: newItemDef?.oid,
            done: true
        })
    };

    const onCancel = () => {
        setDefItem(null);
        setDefVisible(false);
        setDefOption(null);
    }

    return (
        (selectedOID !== "" || create == true) && (
            <ItemDef metaData={metaData} selectedOID={selectedOID}
                languages={languages} itemDef={itemDef}
                setItemDef={setItemDef} visible={defVisible}
                codeLists={codeLists} valueLists={valueLists}
                onFinish={onFinish} onCancel={onCancel}
            />
        )
    );
}

export default ItemContainer;
