import React from "react";
import SponsorDashboard from "../../../../components/common/builder/sponsor/SponsorDashboard";
import {Helmet} from "react-helmet-async";

const SponsorDashboardPage = () => {

    return (
        <>
            <Helmet title={"BUILDER :: Sponsor Dashboard"} />
            <SponsorDashboard />
        </>
    )
}

export default SponsorDashboardPage;