import React, {useEffect, useState} from "react";
import {Button, Card, Form, Input, Modal, Popconfirm, Space, Switch, Table, Tag} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {isEnvProduction, toMetaData} from "../../lib/ODMUtils";
import {metadataActions} from "../../redux/module/metadata";
import {isEmpty} from "../../lib/StringUtils";
import FormInputOID from "../../components/common/odm/ant-form/FormInputOID";
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const SubjectStatusContainer = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const metaData = toMetaData(metaDataStore.study);
    const {t} = useTranslation();
    // const [items, setItems] = useState([]);
    // const [showModal, setShowModal] = useState(false);
    const [subjectStatus, setSubjectStatus] = useState(metaDataStore.study.metaDataVersion[0]?.subjectStatusDef);
    // const [subjectStatusDef, setSubjectStatusDef] = useState({});
    const [loading, setLoading] = useState(true);
    const [oid, setOid] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState({
            r: '241', g: '112', b: '19', a: '1',
        });
    const [colorCode, setColorCode] = useState(null);
    const attr = 'subjectStatusDef';

    useEffect(() => {
        setSubjectStatus(metaDataStore.study.metaDataVersion[0]?.subjectStatusDef);
        setLoading(false);
    }, [metaDataStore]);
//

    const fnEdit = ({oid}) => {
        setOid(oid);
        setEditMode(isEmpty(oid));
        if(!isEmpty(oid)) {
            const subjectStatusObj = subjectStatus.find(s => s.oid === oid);
            form.setFieldsValue(subjectStatusObj);
            setColorCode(subjectStatusObj.color);
        }
        setShowModal(true);
    }

    const onFinish = (subjectStatusDef) => {
        setLoading(true);
        subjectStatusDef.attr = attr;
        subjectStatusDef.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
        // console.log(editCheckDef);
        if(isEmpty(oid)) {
            dispatch(metadataActions.create(subjectStatusDef));
        } else {
            dispatch(metadataActions.update(subjectStatusDef));
        }
        form.resetFields();
        setOid(null);
        setShowModal(false);
    };

    const deleteStatus = (oid) => {
        setLoading(true);
        const subjectStatusDef = metaData.subjectStatus.get(oid);
        subjectStatusDef.attr = attr;
        subjectStatusDef.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929

        dispatch(metadataActions.delete(subjectStatusDef));
    }

    const closeModal = () => {
        form.resetFields();
        setShowModal(false);
        setOid(null);
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values) => {
                console.log(values);
                // Submit values
                onFinish(values);
            })
            .catch((errorInfo) => {});
    };

    const onChangeColor = color => {
        setColor(color.rgb);
        setColorCode(color.hex);
        form.setFieldsValue({"color":color.hex});
    }

    const columns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            // render: text => <a>{text}</a>,
        },
        {
            title: 'Display Order',
            dataIndex: 'priority',
            key: 'priority',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <Tag color={record.color}>{text}</Tag>
        },
        // {
        //     title: 'Color',
        //     dataIndex: 'color',
        //     key: 'color',
        //     render:text => <span style={{backgroundColor:text,padding:3,fontSize:12}}>{text}</span>
        // },
        {
            title: 'Disable the next visit.',
            dataIndex: 'disabledNextVisit',
            key: 'disabledNextVisit',
            render:text => <Switch checked={text} disabled={true}></Switch>
        },
        {
            title: 'Exclude Subject Count',
            dataIndex: 'excludeSubjectCount',
            key: 'excludeSubjectCount',
            render:text => <Switch checked={text} disabled={true}></Switch>
        },
        {
            title: 'Action',
            key: 'action',
            render:(text, record) => (
                <Space size="middle">
                    {/*<a>Invite {record.lastName}</a>*/}
                    <a onClick={() => fnEdit({oid:record.oid})}><i className={'fa fa-search'}></i></a>
                    {/*<a onClick={() => fnEdit({oid:record.oid})}><i className={'fa fa-pencil'}></i></a>*/}
                    {
                        !isProd &&
                        <Popconfirm
                            title="Are you sure to delete this task?"
                            onConfirm={() => deleteStatus(record.oid)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#"><i className={'fa fa-trash-o'}></i></a>
                        </Popconfirm>
                    }
                </Space>
            )
        },
    ];


    const syncStatus = () => {
        dispatch(metadataActions.syncSubjectStatus(params));
    }

    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `${ colorCode }`,
                // background: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <Card title={'Subject Status'} extra={
            <>
                {
                    !isProd &&
                    <>
                        {
                            subjectStatus?.length <= 0 &&
                            <Button type={"ghost"} onClick={() => syncStatus()} className={'mr-4'}><i className={'fa fa-refresh mr-2'} />Sync</Button>
                        }
                        <Button type={"primary"} onClick={() => fnEdit({oid:""})}><i className={'fa fa-plus mr-2'} />New</Button>
                    </>
                }
            </>
        }>
            <Table dataSource={subjectStatus} bordered size={'small'} loading={loading} columns={columns} />
            {/*<EditCheckDef metaData={metaData} oid={oid} onFinish={onFinish} showModal={showModal} closeModal={closeModal}/>*/}
            <Modal
                title="Subject Status"
                centered
                maskClosable={false}
                keyboard={false}
                open={showModal}
                okText={'Save'}
                destroyOnClose={true}
                // okButtonProps={{disabled:annotations.length > 0 || !editMode}}
                onOk={() => handleFormSubmit()}
                onCancel={() => closeModal()}
                // width={1000}
                footer={[
                    (!isProd && !editMode) && <Button type={'link'} onClick={() => setEditMode(true)}><i className={'fa fa-pencil'}></i>&nbsp;&nbsp;Edit</Button>,
                    (!isProd && editMode) && <Button type={"primary"} disabled={!editMode} onClick={() => handleFormSubmit()}><i className={'fa fa-check'}></i>&nbsp;&nbsp;Save</Button>,
                    <Button type={'link'} onClick={() => closeModal()}><i className={'fa fa-times'}></i>&nbsp;&nbsp;Close</Button>,
                ]}
                // afterClose={() => {setEditMode(false);closeModal();}}
            >
                <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} size={'middle'}>
                    {/*<Form.Item className={'mb-0'} name={"oid"} label="OID" rules={[{ required: true}]}>*/}
                    <FormInputOID selectedOID={oid} form={form} requiredMark={true}/>
                    {/*</Form.Item>*/}
                    <Form.Item className={'mb-0'} name="status" label="Status" rules={[{ required: true, message:t('message.pls.enter.status')}]}>
                        <Input disabled={!editMode}/>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name="priority" label="Display Order" rules={[{ required: true, message:t('message.pls.enter.display.order')}]}>
                        <Input type={"number"} disabled={!editMode}/>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name="color" label="Color" rules={[{ required: true, message:t('message.pls.enter.color')}]}>
                        <Input type={"text"} hidden={true} disabled={!editMode}/>
                        <div>
                            <div style={ styles.swatch } onClick={ () => editMode ? setDisplayColorPicker(true) : null}>
                                <div style={ styles.color } />
                            </div>
                            { displayColorPicker ? <div style={ styles.popover }>
                                <div style={ styles.cover } onClick={ () => setDisplayColorPicker(false) }/>
                                <SketchPicker color={ color } onChange={ onChangeColor } presetColors={
                                    ['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3',
                                    '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000',
                                    '#EB144C', '#8BC34A', '#3F51B5', '#F47373']} />
                            </div> : null }

                        </div>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name="disabledNextVisit" label="Disable the next visit." valuePropName={'checked'} initialValue={false}>
                        <Switch disabled={!editMode}/>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name="excludeSubjectCount" label="Exclude Subject Count." valuePropName={"checked"} initialValue={false}>
                        <Switch disabled={!editMode}/>
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    )
}

export default SubjectStatusContainer;
