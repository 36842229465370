import {Helmet} from "react-helmet-async";
import StudyPrecondition from "../../../../components/common/builder/studies/StudyPrecondition";
import SettingsTabs from "../../../../layouts/Tab/settings";

const StudyPreconditionPage = () => {

    return (
        <SettingsTabs menu={'precondition'}>
            <Helmet title={'BUILDER :: Precondition'} />
            <StudyPrecondition />
        </SettingsTabs>
    )

}

export default StudyPreconditionPage