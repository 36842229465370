import React, {useEffect, useState} from "react";
import {Button, Card, Cascader, Col, Form, Row, Select, Tag} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getCascadeLabel, isEnvProduction, toMetaData} from "../../lib/ODMUtils";
import 'react-querybuilder/dist/query-builder.css';
import {metadataActions} from "../../redux/module/metadata";
import {useParams} from "react-router-dom";
import {isEmpty} from "../../lib/StringUtils";
import {useTranslation} from "react-i18next";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
};
const SubjectFieldMapContainer = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const metaData = toMetaData(metaDataStore.study);
    const {t} = useTranslation();
    const [subjectField, setSubjectField] = useState(null);

    //Options
    const [ageOptions, setAgeOptions] = useState(null);
    const [visitDateOptions, setVisitDateOptions] = useState(null);
    const [randomOptions, setRandomOptions] = useState(null);
    const [ipOptions, setIPOptions] = useState(null);
    const [evaluationOptions, setEvaluationOptions] = useState(null);
    const [sources, setSources] = useState([]);

    const useRandomization = metaDataStore.studyInfo?.useRandomization;
    const randomNumberFormat = metaDataStore?.study?.basicDefinitions?.randomNumberDef?.format;
    const useIPManagement = metaDataStore.studyInfo?.useIPManagement;

    const useVisitWindow = !!metaDataStore.study.metaDataVersion[0]?.studyTiming;
    const useIndependentEvaluation = metaDataStore.studyInfo?.useIndependentEvaluation;

    // const studyEventGroupOID = metaDataStore.study.metaDataVersion[0]?.protocol?.studyEventGroupRef[0]?.studyEventGroupOID;
    // console.log(studyEventGroupOID);
    // const firstStudyEventOID = metaData.studyEventGroup.get(studyEventGroupOID)?.studyEventDefGroup[0].studyEventOID;
    // console.log(firstStudyEventOID);
    useEffect(() => {
        try {
            const subjectField = metaData?.subjectField??{};
            // console.log('subjectField =', subjectField);
            let subjectId = [];
            let consentDate = [];
            // let age = [];
            let birthDate = [];
            let initial = [];
            let sex = [];
            let visitDateOID;
            let ageOID;
            let randomNoOID;
            let ipCodeOID;
            let ipCodeDoseOID;
            let evaluationOIDs;

            if (subjectField.subjectId != null) {
                subjectId = [subjectField.subjectId.studyEventOID, subjectField.subjectId.formOID, subjectField.subjectId.itemGroupOID, subjectField.subjectId.itemOID];
            }
            if (subjectField.consentDate != null) {
                consentDate = [subjectField.consentDate.studyEventOID, subjectField.consentDate.formOID, subjectField.consentDate.itemGroupOID, subjectField.consentDate.itemOID];
            }
            // if (subjectField.age != null) {
            //     age = [subjectField.age.studyEventOID, subjectField.age.formOID, subjectField.age.itemGroupOID, subjectField.age.itemOID];
            // }
            if (subjectField.birthDate != null) {
                birthDate = [subjectField.birthDate.studyEventOID, subjectField.birthDate.formOID, subjectField.birthDate.itemGroupOID, subjectField.birthDate.itemOID];
            }
            if (subjectField.initial != null) {
                initial = [subjectField.initial.studyEventOID, subjectField.initial.formOID, subjectField.initial.itemGroupOID, subjectField.initial.itemOID];
            }
            if (subjectField.sex != null) {
                sex = [subjectField.sex.studyEventOID, subjectField.sex.formOID, subjectField.sex.itemGroupOID, subjectField.sex.itemOID];
            }

            visitDateOID = subjectField.visitDateOID;
            ageOID = subjectField.ageOID;
            randomNoOID = subjectField.randomNoOID;
            ipCodeOID = subjectField.ipCodeOID;
            ipCodeDoseOID = subjectField.ipCodeDoseOID;
            evaluationOIDs = subjectField.evaluationOIDs;

            setAgeOptions([...metaData.item.values()].filter(i => i.dataType === 'INTEGER').map(i => ({value:i.oid, label:`${i.name} - ${i.oid}`})));
            setVisitDateOptions([...metaData.item.values()].filter(i => i.dataType === 'DATE').map(i => ({value:i.oid, label:`${i.name} - ${i.oid}`})));
            setRandomOptions([...metaData.item.values()].filter(i => i.controlType === 'INPUT' &&  i.dataType === 'TEXT' ).map(i => ({value:i.oid, label:`${i.name} - ${i.oid}`})));
            // setIPOptions([...metaData.item.values()].filter(i => i.controlType === 'INPUT' &&  i.dataType === 'INTEGER' ).map(i => ({value:i.oid, label:`${i.name} - ${i.oid}`})));
            setIPOptions([...metaData.item.values()].filter(i => i.controlType === 'INPUT' &&  i.dataType === 'TEXT' ).map(i => ({value:i.oid, label:`${i.name} - ${i.oid}`})));
            setEvaluationOptions([...metaData.ieItemDefs].map(i => ({value:i.oid, label:`${i.name} - ${i.oid}`})));

            setSubjectField({
                subjectId: subjectId,
                consentDate: consentDate,
                ageOID: ageOID,
                birthDate: birthDate,
                initial:initial,
                sex: sex,
                visitDateOID: visitDateOID,
                randomNoOID: randomNoOID,
                ipCodeOID : ipCodeOID,
                // ipCodeDoseOID : ipCodeDoseOID,
                evaluationOIDs : evaluationOIDs
            });

            // console.log(adverseEvent);
            form.setFieldsValue({
                subjectId: subjectId,
                consentDate: consentDate,
                ageOID: ageOID,
                birthDate: birthDate,
                initial:initial,
                sex: sex,
                visitDateOID: visitDateOID,
                randomNoOID: randomNoOID,
                ipCodeOID : ipCodeOID,
                // ipCodeDoseOID : ipCodeDoseOID,
                evaluationOIDs : evaluationOIDs
            });

            //Cascade Source 설정
            const dataSources = Array.from(metaData.studyEvent.values()).map(e => {
                const studyEvent = {value: e.oid, label: e.name};
                studyEvent.children = e.formRef.filter(f => metaData.form.get(f.formOID) != null && metaData.form.get(f.formOID).formType !== 'SAE_REPORT_FORM').map(f => {
                    const formDef = metaData.form.get(f.formOID);
                    const form = {value: formDef.oid, label: formDef.name};
                    form.children = formDef.itemGroupRef.map(ig => {
                        const itemGroupDef = metaData.itemGroup.get(ig.itemGroupOID);
                        const itemGroup = {value: itemGroupDef.oid, label: itemGroupDef.name};
                        itemGroup.children = itemGroupDef.itemRef.map(i => {
                            const itemDef = metaData.item.get(i.itemOID);
                            return {value: itemDef.oid, label:`${itemDef.name} [${itemDef.oid}]`};
                        })
                        return itemGroup;
                    })
                    return form;
                });
                return studyEvent;
            });
            setSources(dataSources);

        } catch (e) {
            console.log("ERR");
            console.error(e);
        }
    }, []);

    const [form] = Form.useForm();

    const onFinish = async values => {
        // console.log('Received values of form: ', values);
        values.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
        dispatch(metadataActions.updateSubjectField(values));
    };

    useEffect(() => {

        console.log("Age Option : ", ageOptions);

    }, [ageOptions])

    return (
        <Form name={"subject-field-map-form"} {...layout} form={form} onFinish={onFinish}>
            <Card title={t('column.field.mapping.configuration')} className={'mb-3'}>
                <Row>
                    <Col span={11} offset={1}>
                        <Form.Item name={["subjectId"]} label={"Subject Identifier"} rules={[{required:true}]}>
                            {
                                isProd?(
                                    <Tag className={'p-2'}>
                                        <span className={'font-size-16 font-weight-bold'}>
                                            {getCascadeLabel(sources, subjectField?.subjectId)}
                                        </span>
                                    </Tag>
                                ):(
                                    <Cascader style={{width: '100%'}} options={sources} placeholder="Please select"/>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={1}>
                        <Form.Item name={["consentDate"]} label={"Consent Date"} rules={[{required:metaDataStore?.studyInfo?.versionControlType == 'CONSENT_DATE'}]}>
                            {
                                isProd?(
                                    <Tag className={'p-2'}>
                                        <span className={'font-size-16 font-weight-bold'}>
                                            {getCascadeLabel(sources, subjectField?.consentDate)}
                                        </span>
                                    </Tag>
                                ):(
                                    <Cascader style={{width: '100%'}} options={sources} placeholder="Please select"/>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={1}>
                        <Form.Item name={["birthDate"]} label={"Birth Date"} rules={[{required:false}]}>
                            {
                                isProd?(
                                    <Tag className={'p-2'}>
                                        <span className={'font-size-16 font-weight-bold'}>
                                            {getCascadeLabel(sources, subjectField?.birthDate)}
                                        </span>
                                    </Tag>
                                ):(
                                    <Cascader style={{width: '100%'}} options={sources} placeholder="Please select"/>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={1}>
                        <Form.Item name={["initial"]} label={"Initial"} rules={[{required:false}]}>
                            {
                                isProd?(
                                    <Tag className={'p-2'}>
                                        <span className={'font-size-16 font-weight-bold'}>
                                            {getCascadeLabel(sources, subjectField?.initial)}
                                        </span>
                                    </Tag>
                                ):(
                                    <Cascader style={{width: '100%'}} options={sources} placeholder="Please select"/>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={1}>
                        <Form.Item name={["sex"]} label={"Sex"} rules={[{required:false}]}>
                            {
                                isProd?(
                                    <Tag className={'p-2'}>
                                        <span className={'font-size-16 font-weight-bold'}>
                                            {getCascadeLabel(sources, subjectField?.sex)}
                                        </span>
                                    </Tag>
                                ):(
                                    <Cascader style={{width: '100%'}} options={sources} placeholder="Please select"/>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={1}>
                        <Form.Item name={["ageOID"]} label={"Age"} rules={[{required:false}]}>
                            {
                                isProd?(
                                    <Tag className={'p-2'}>
                                        <span className={'font-size-16 font-weight-bold'}>
                                            {ageOptions?.find(vd => vd.value === subjectField?.ageOID)?.label||'N/A'}
                                        </span>
                                    </Tag>
                                ):(
                                    <Select showSearch={true} allowClear={true} placeholder="Please select"
                                            filterOption={(input, option) =>
                                        (option?.label??"").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } options={ageOptions}></Select>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={1}>
                        <Form.Item name={"visitDateOID"}
                                   label={"Visit Date"}
                                   rules={[{required: useVisitWindow?true:false,
                                            message: 'Visit Date is required'}]}>
                            {
                                isProd?(
                                    <Tag className={'p-2'}>
                                        <span className={'font-size-16 font-weight-bold'}>
                                            {visitDateOptions?.find(vd => vd.value === subjectField?.visitDateOID)?.label||'N/A'}
                                        </span>
                                    </Tag>
                                ):(
                                    <Select showSearch={true} allowClear={true} placeholder="Please select"
                                            filterOption={(input, option) =>
                                        (option?.label??"").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } options={visitDateOptions} />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={1}>
                        <Form.Item name={["randomNoOID"]}
                                   label={"Random No"}
                                   rules={[{required: useRandomization?true:false
                                            , message : 'Random No is required'}]}>
                            {
                                isProd?(
                                    <Tag className={'p-2'}>
                                        <span className={'font-size-16 font-weight-bold'}>
                                            {randomOptions?.find(vd => vd.value === subjectField?.randomNoOID)?.label||'N/A'}
                                        </span>
                                    </Tag>
                                ):(
                                    <Select showSearch={true} allowClear={true} placeholder="Please select"
                                            filterOption={(input, option) =>
                                        (option?.label??"").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } options={randomOptions} />
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                {/*    <Col span={11} offset={1}>*/}
                {/*        <Form.Item name={["ipCodeOID"]}*/}
                {/*                   label={"IP Code"}*/}
                {/*                   rules={[{required: useIPManagement*/}
                {/*                       , message : 'IP Code is required'}]}>*/}
                {/*            {*/}
                {/*                isProd?(*/}
                {/*                    <Tag className={'p-2'}>*/}
                {/*                            <span className={'font-size-16 font-weight-bold'}>*/}
                {/*                                {ipOptions?.find(vd => vd.value === subjectField?.ipCodeOID)?.label||'N/A'}*/}
                {/*                            </span>*/}
                {/*                    </Tag>*/}
                {/*                ):(*/}
                {/*                    <Select showSearch={true} allowClear={true} placeholder="Please select"*/}
                {/*                            filterOption={(input, option) =>*/}
                {/*                                (option?.label??"").toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
                {/*                            } options={ipOptions} />*/}
                {/*                )*/}
                {/*            }*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                    <Col span={11} offset={1}>
                        <Form.Item name={"evaluationOIDs"}
                                   label={"Evaluation Result"}
                                   rules={[{required: useIndependentEvaluation ? true : false,
                                       message: 'Evaluation Result is required'}]}>
                            {
                                isProd?(
                                    evaluationOptions?.map((evaluation) => (
                                        <Tag className={'p-2'}>
                                            <span className={'font-size-16 font-weight-bold'}>
                                                {subjectField?.evaluationOIDs.includes(evaluation.value) ? evaluation.label : 'N/A'}
                                            </span>
                                        </Tag>
                                    ))
                                ):(
                                    <Select showSearch={true} allowClear={true} placeholder="Please select"
                                            filterOption={(input, option) =>
                                                (option?.label??"").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            } options={evaluationOptions} mode={"multiple"} />
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={11} offset={13}>
                        <Form.Item {...tailLayout} className={"text-right mt-2"}>
                            <Button hidden={isProd} type={"primary"} htmlType={"submit"}>Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
    )
}

export default SubjectFieldMapContainer;
