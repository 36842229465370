import React, {useEffect, useState} from 'react';
import {Navigate, Outlet, Route, Routes, useLocation, useMatch, useNavigate, useParams} from 'react-router-dom';
import {useTranslation, withTranslation} from "react-i18next";
import ODMPage from "./pages/odm";
import System404 from "./pages/auth/404";
import SystemLogin from "./pages/auth/login";
import {useDispatch, useSelector} from "react-redux";
import System500 from "./pages/auth/500";
import {PATH_PUSHED, PATH_REPLACED, PROFILE_SUCCESS} from "./components/constant/responseType";
import SystemProfile from "./pages/auth/profile";
import PrivateRoute from "./components/route/PrivateRoute";
import PublicRoute from "./components/route/PublicRoute";
import Logout from "./@vb/sscomponents/Auth/Logout";
import System403 from "./pages/auth/403";
import SystemForgotPassword from "./pages/auth/forgot-password";
import {UpOutlined} from "@ant-design/icons";
import {Button, Tooltip} from "antd";
import useScroll from "./lib/useScroll";
import StudiesPage from "./pages/builder/studies";
import StudyEditPage from "./pages/builder/studies/edit";
import axios from "axios";
import {isManagerAuthority} from "./lib/AuthorityUtils";
import ManageAccessPage from "./pages/builder/studies/manage-access";
import DashboardPage from "./pages/builder/studies/dashboard";
import CRFVersionPage from "./pages/builder/studies/crf-version";
import CRFVersionEditPage from "./pages/builder/studies/crf-version/edit";
import CRFVersionInfoPage from "./pages/builder/studies/crf-version/info";
import CRFVersionControlPage from "./pages/builder/studies/crf-version/control";
import RolesPage from "./pages/builder/studies/roles";
import RoleEditPage from "./pages/builder/studies/roles/edit";
import RolesPrivilegesPage from "./pages/builder/studies/roles-privileges";
import StudyPreconditionPage from "./pages/builder/studies/precondition";
import ManageSitesPage from "./pages/builder/studies/manage-sites";
import ManageSiteEditPage from "./pages/builder/studies/manage-sites/edit";
import ManageSiteVersionPage from "./pages/builder/studies/manage-sites/version";
import ManageUsersPage from "./pages/builder/studies/manage-users";
import ManageUsersEditPage from "./pages/builder/studies/manage-users/edit";
import ManageUsersSearchPage from "./pages/builder/studies/manage-users/search";
import DefaultECSPage from "./pages/builder/studies/default-ecs";
import PageSettingPage from "./pages/builder/studies/page-setting";
import FormListPage from "./pages/odm/form-list";
import FormsPage from "./pages/odm/forms";
import StudyEventGroupsPage from "./pages/odm/study-event-groups";
import StudyEventsPage from "./pages/odm/study-events";
import ProtocolPage from "./pages/odm/protocol";
import DBSpecPage from "./pages/odm/spec";
import PublishPage from "./pages/odm/publish";
import ECSPage from "./pages/odm/ecs";
import VisitWindowPage from "./pages/odm/visit-window";
import SAEConfigPage from "./pages/odm/sae-map/SAEConfigPage";
import SAEMapPage from "./pages/odm/sae-map";
import DataSetPage from "./pages/odm/dataset";
import SubjectFieldMapPage from "./pages/odm/subject";
import SubjectStatusPage from "./pages/odm/subject-status";
import SponsorPage from "./pages/builder/sponsors";
import SponsorEditPage from "./pages/builder/sponsors/edit";
import SponsorDashboardPage from "./pages/builder/sponsors/dashboard";
import SponsorPreconditionPage from "./pages/builder/sponsors/precondition";
import SponsorSubjectStatusPage from "./pages/builder/sponsors/subject-status";
import SponsorRoleListPage from "./pages/builder/sponsors/roles";
import SponsorRoleEditPage from "./pages/builder/sponsors/roles/edit";
import SponsorRolesPrivilegesPage from "./pages/builder/sponsors/roles-privileges";
import StudyStructurePage from "./pages/odm/protocol/study-structure";
import {isEmpty} from "./lib/StringUtils";
import BasicDefinitionPage from "./pages/builder/studies/basic-definition";
import {userActions} from "./redux/module/user";
import RolesLearningCoursePage from "./pages/builder/sponsors/roles-learning-course";
import StudyPage from "./pages/builder/studies/StudyPage";
import EproPage from "./pages/odm/epro";
import RolesPrivilegesIWRSPage from "./pages/builder/studies/roles-privileges-iwrs";
import GlobalLibraryPage from "./pages/builder/global-libraries";
import GlobalLibraryEditPage from "./pages/builder/global-libraries/edit";
import LibraryPage from "./pages/builder/global-libraries/library";
import LibraryFormListPage from "./pages/builder/global-libraries/library/formList";
import LibraryFormPage from "./pages/builder/global-libraries/library/form";
import CrfCoverPage from "./pages/builder/studies/crf-setting";
import EmailNoticePage from  "./pages/builder/studies/email-notice";
import EmailNoticeEditPage from "./pages/builder/studies/email-notice/edit";

import 'css/definitions.css';
import IndependentEvaluationPage from "./pages/odm/independent-evaluation";

function App() {

    const user = useSelector(({user}) => (user));
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();
    const [uriPath, setUriPath] = useState("");
    const isLogout = useMatch('/logout');
    const isLogin = useMatch('/login');

    const {scrollY} = useScroll();

    const topScrollYSetting = () => {
      window.scrollTo({top: 0, left:0, behavior:'smooth'});
    };

    useEffect(() => {
        const baseUri = ['/', '/studies', '/login']
        // '/', '/studies', '/login' path가 아닌경우, uriPath에 상태값 저장
        if(!baseUri.includes(location.pathname)) {
            setUriPath(location.pathname+(location?.search??''));
        }
    }, [])

    /**
     * ResponseStatus 응답에 따라서 history push
     */
    useEffect(() => {
        switch (user.responseStatus) {
            case PROFILE_SUCCESS:
                user.responseStatus = '';
                navigate('/studies');
                break;
            case PATH_PUSHED:
                user.responseStatus = '';
                navigate(user.pathname);
                break;
            case PATH_REPLACED:
                user.responseStatus = '';
                navigate(user.pathname, {replace:true});
                break;
        }
    }, [user.responseStatus])

    /**
     * Session 응답이 string 형태의 태그 형식으로 반환될 경우, login 화면으로 이동시킨다.
     */
    useEffect( () => {
        //Login, Logout에 접속할 때는 Session Check 예외처리
        if(!(isLogout || isLogin)) {
            (async () => {
                try {
                    const {data} = await axios({
                        url: "/api/session-refresh",
                        method: 'get',
                        params: {r: Math.random()}
                    });

                    //Session Check
                    if((user?.authorized && user?.user != null) && data?.authorized === false) {
                        dispatch(userActions.duplicateLogout());
                        navigate('/login?duplicated', {replace:true});
                    }
                } catch(e) {
                    console.log(e);
                }
            })();
        }
    }, [location.pathname]);

    return (
        <>
            <Routes>
                { //최초 접속 시, User 정보가 없었으면 login 화면으로 이동
                    <Route path={'/'} element={
                        <>
                            {
                                user?.user != null ? (
                                    <Navigate to={isEmpty(uriPath) ? '/studies' : uriPath}/>
                                ) : (
                                    <Navigate to={'/login'} replace/>
                                )
                            }
                        </>
                    } />
                }
                {/* register */}
                {/*<Route path={'/users/register/:encUserId'} element={SystemRegister}/>*/}

                {/* Login */}
                <Route path={'/login'} element={<PublicRoute />}>
                    {/* 하위 Route에 존재하면 알아서 /로 구분자가 추가 됨 */}
                    <Route path={''} element={<SystemLogin />} />
                </Route>

                <Route path={'/forgot-password'} element={<PublicRoute />}>
                    {/* 하위 Route에 존재하면 알아서 /로 구분자가 추가 됨 */}
                    <Route path={''} element={<SystemForgotPassword />} />
                </Route>

                {/* Logout */}
                <Route path={'/logout'} element={<PrivateRoute />}>
                    <Route path={''} element={<Logout />} />
                </Route>

                {/* Profile */}
                <Route path={'/profile'} element={<PrivateRoute isLayout={false} />}>
                    <Route path={''} element={<SystemProfile user={user} />} />
                </Route>


                <Route path={'/studies'} element={<PrivateRoute />}>
                    {/* Studies Menu */}
                    {/* /studies */}
                    <Route path={''} element={<StudiesPage />} />
                    {/* /studies/edit */}
                    <Route path={'edit'} element={<StudyEditPage />} />
                    {/* /studies/manage-access */}
                    <Route path={"manage-access"} element={isManagerAuthority(user?.user?.authorities)?(<ManageAccessPage />):(<Navigate to={'/403'} state={{from: 403, explain: t('explain.user.permission.denied')}}/>)} />

                    {/* Study Internal Menu */}
                    <Route path={':sid'} element={<StudyPage />}>
                        {/* Base Path 설정 */}
                        <Route path={''} element={<Navigate to={`/studies/${params.sid}/versions`}/>} />

                        {/* /studies/:sid/dashboard */}
                        <Route path={'dashboard'} element={<DashboardPage />} />

                        {/* Study > CRF Design */}
                        <Route path={'versions'} element={<Outlet />}>
                            {/* Study > CRF Version */}
                            <Route path={''} element={<CRFVersionPage />} />
                            {/* Study > CRF Version > Edit */}
                            <Route path={'edit'} element={<CRFVersionEditPage />} />
                            {/* Study > CRF Version > Info */}
                            <Route path={'info'} element={<CRFVersionInfoPage />} />
                            {/* Study > CRF Version > Control */}
                            <Route exact path={':id/version-control'} element={<CRFVersionControlPage />} />
                        </Route>
                        {/* CRF Design > Basic Definition */}
                        <Route path={'basic-definition'} element={<BasicDefinitionPage />} />
                        {/* CRF Design > Default ECS */}
                        <Route path={'default-ecs'} element={<DefaultECSPage />} />
                        {/* CRF Design > Page Setting */}
                        <Route path={'page-setting'} element={<PageSettingPage />} />

                        {/* Study > Settings */}
                        {/* Settings > Manage Role */}
                        <Route path={'roles'} element={<Outlet />}>
                            <Route path={''} element={<RolesPage />} />
                            <Route path={'edit'} element={<RoleEditPage />} />
                        </Route>
                        {/* Settings > Manage Role&Privilege */}
                        <Route path={'roles-privileges'} element={<RolesPrivilegesPage />} />
                        {/* Settings > Manage Role&LearningCourse */}
                        <Route path={'roles-learning-course'} element={<RolesLearningCoursePage />} />
                        {/* Settings > Manage Role&Privilege - IWRS */}
                        {/*<Route path={'roles-privileges-iwrs'} element={<RolesPrivilegesIWRSPage />} />*/}

                        {/* Settings > Study Precondition */}
                        <Route path={'precondition'} element={<StudyPreconditionPage />} />
                        {/* Settings > CRF Cover */}
                        <Route path={'crf-setting'} element={<CrfCoverPage />} />
                        <Route path={'email-notice'} element={<Outlet />}>
                            {/* Settings > E-Mail Notice */}
                            <Route path={''} element={<EmailNoticePage />} />
                            {/* Settings > E-Mail Notice > Edit */}
                            <Route path={'edit'} element={<EmailNoticeEditPage />} />
                        </Route>

                        {/* Study > Management */}
                        <Route path={'sites'} element={<Outlet />}>
                            {/* Study > Manage Site */}
                            <Route path={''} element={<ManageSitesPage />} />
                            {/* Study > Manage Site > Edit */}
                            <Route path={'edit'} element={<ManageSiteEditPage />} />
                            {/* Study > Manage Site > CRF */}
                            <Route path={':siteId/versions'} element={<ManageSiteVersionPage />} />
                        </Route>
                        <Route path={'users'} element={<Outlet />}>
                            {/* Study > Manage User */}
                            <Route path={''} element={<ManageUsersPage />} />
                            {/* Study > Manage User > Edit */}
                            <Route path={'edit'} element={<ManageUsersEditPage />} />
                            {/* Study > Manage User > Create User[Search] */}
                            <Route path={'search'} element={<ManageUsersSearchPage />} />
                        </Route>


                        {/* ODM Desinger */}
                        <Route path={'v/:id'} element={<ODMPage />}>
                            {/* ODM > DB Spec */}
                            <Route path={'spec'} element={<DBSpecPage />} />
                            {/* ODM > Form */}
                            <Route path={'forms'} element={<Outlet />}>
                                <Route path={''} element={<FormListPage />} />
                                <Route path={':oid'} element={<FormsPage />} />
                            </Route>
                            {/* ODM > Event */}
                            <Route path={'study-events'} element={<StudyEventsPage />} />
                            {/* ODM > Event Group */}
                            <Route path={'study-event-groups'} element={<StudyEventGroupsPage />} />
                            {/* ODM > Schedule Of Activity */}
                            <Route path={'protocol'} element={<Outlet />}>
                                <Route path={''} element={<ProtocolPage />} />
                                <Route path={'study-structure'} element={<Outlet />}>
                                    <Route path={''} element={<StudyStructurePage />} />
                                </Route>
                            </Route>
                            {/* ODM > ePRO */}
                            <Route path={'epro'} element={<EproPage />} />
                            {/* ODM > Visit Window */}
                            <Route path={'visit-window'} element={<VisitWindowPage />} />
                            {/* ODM > Subject Status */}
                            <Route path={'subject-status'} element={<SubjectStatusPage />} />
                            {/* ODM > Field MAP */}
                            <Route path={'subject-field-map'} element={<SubjectFieldMapPage />} />
                            {/* ODM > Edit Checks */}
                            <Route path={'ecs'} element={<ECSPage />} />
                            {/* ODM > AE/SAE */}
                            <Route path={'sae'} element={<SAEConfigPage />} />
                            {/* ODM > SAE-MAP */}
                            <Route path={'sae-map'} element={<SAEMapPage />} />
                            <Route path={'independent-evaluation'} element={<IndependentEvaluationPage />} />
                            <Route path={'dataset'} element={<DataSetPage />} />
                            {/* ODM > Publish */}
                            <Route path={'publish'} element={<PublishPage />} />
                        </Route>
                    </Route>
                </Route>

                {/* Sponsor Menu */}
                <Route path={'/sponsors'} element={<PrivateRoute />}>
                    <Route path={''} element={<SponsorPage />} />
                    <Route path={'edit'} element={<SponsorEditPage />} />

                    {/* Sponsor Internal Menu */}
                    <Route path={':sponsorId'} element={<Outlet />}>
                        <Route path={''} element={<Navigate to={`sponsor-dashboard`} replace />} />
                        <Route path={'sponsor-dashboard'} element={<SponsorDashboardPage />} />
                        <Route path={'sponsor-precondition'} element={<SponsorPreconditionPage />} />
                        <Route path={'sponsor-subject-status'} element={<SponsorSubjectStatusPage />} />
                        <Route path={'sponsor-roles'} element={<Outlet />} >
                            <Route path={''} element={<SponsorRoleListPage />} />
                            <Route path={'edit'} element={<SponsorRoleEditPage />} />
                        </Route>
                        <Route path={'sponsor-roles-privileges'} element={<SponsorRolesPrivilegesPage />} />
                    </Route>
                </Route>

                {/* Global Library Menu */}
                <Route path={'/global-library'} element={<PrivateRoute />}>
                    <Route path={''} element={<GlobalLibraryPage />} />
                    <Route path={'edit'} element={<GlobalLibraryEditPage />} />

                    {/* Library Store */}
                    <Route path={'v/:libraryId'} element={<LibraryPage />}>
                        <Route path={'forms'} element={<Outlet />}>
                            <Route path={''} element={<LibraryFormListPage />} />
                            <Route path={':oid'} element={<LibraryFormPage />} />
                        </Route>
                    </Route>

                </Route>

                {/*<Route path={'/test'} element={<Outlet />}>*/}
                {/*    <Route path={''} element={<TestPage />} />*/}
                {/*</Route>*/}

                {/* Error Page */}
                <Route path={'/403'} element={<System403 />} />
                <Route path={'/500'} element={<PrivateRoute isLayout={false} />}>
                    <Route path={''} element={<System500 />} />
                </Route>
                <Route path={'/404'} element={<System404/>}/>
                {/* Not Found Page */}
                <Route path={'/*'} element={<Navigate to={'/404'}/>}/>
            </Routes>
            <Tooltip title={t('apps.move.top.title')}>
                <Button hidden={scrollY < 100} type={'default'}
                        style={{position: 'fixed', left: '2.5%', bottom: '7.5rem'}}
                        onClick={() => topScrollYSetting()}
                        size={'large'} shape={'circle'}><UpOutlined/></Button>
            </Tooltip>
        </>
);
}

export default withTranslation()(App);
