import _ from "lodash";
import {isEmpty} from "../../../../../lib/StringUtils";
import {Button, Popover, Select, Space, Tag} from "antd";

const LibraryValueListBtn = ({library, data, itemGroupOID, setIsValueList, itemRefMap, setItemRefMap, index, open, setOpen}) => {

    const valueListDefs = Array.from(library.valueList.values());
    const displayDef = valueListDefs.find(def => def?.oid === data?.valueListOID);

    const onChangeValueList = (value) => {
        const valueListOID = value;
        const cloneItemRefMap = _.cloneDeep(itemRefMap);
        if(isEmpty(valueListOID)) {
            delete cloneItemRefMap.get(itemGroupOID)[index].valueListOID;
            setIsValueList(false);
        } else {
            cloneItemRefMap.get(itemGroupOID)[index].valueListOID = valueListOID;

            //valueListOID 항목이 선택되었을 경우, 아래 항목의 속성값을 전부 제거함.
            delete cloneItemRefMap.get(itemGroupOID)[index].methodOID;
            delete cloneItemRefMap.get(itemGroupOID)[index].collectionExceptionConditionOID;
            delete cloneItemRefMap.get(itemGroupOID)[index].unitsItemOID;
            setIsValueList(true);
        }
        setItemRefMap(cloneItemRefMap);
    }

    const valueListOptions = [
        {value: '', label: '...'},
        ...valueListDefs.map(valueList => ({
            key: `VL_${itemGroupOID}_${index}_${valueList.oid}`,
            value: valueList.oid,
            label: `${valueList.oid} - ${valueList.name}`
        }))
    ];

    return (
        <Popover placement={'top'}
                 title={'ValueList'}
                 trigger={'click'}
                 open={open}
                 onOpenChange={() => setOpen(!open)}
                 content={(
                     <Space>
                         <Select placeholder={'ValueList'} defaultValue={data?.valueListOID}
                                 style={{width: 250}} showSearch={true} options={valueListOptions}
                                 onChange={onChangeValueList} onBlur={() => setOpen(false)}/>
                     </Space>
                 )}>
            <Button size={'small'} onClick={() => setOpen(true)}>
                <i className='fa fa-th-list'></i>
            </Button>
        </Popover>
    )
}

export default LibraryValueListBtn;