import React, {Fragment, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Button, Col, Row, Tag} from "antd";
import {EditOutlined, RightOutlined} from "@ant-design/icons";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle, onExporting} from "../../../../lib/BuilderUtils";
import {CardBody, CardFooter, CardHeader, PrimaryCard} from "../../../../pages/PageStyleBox";
import {withTranslation} from "react-i18next";
import DataGrid, {
    Column,
    ColumnChooser,
    Export,
    FilterRow,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    Scrolling,
    StateStoring,
    Toolbar
} from "devextreme-react/data-grid";
import NormalLoading from "../../NormalLoading";

const ManageUsers = ({t}) => {

    const params = useParams();
    const navigate = useNavigate();
    const [dataGrid, setDataGrid] = useState(null);
    const [userList, setUserList] = useState(null);
    const [studyInfo, setStudyInfo] = useState(null);

    useEffect( () => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/users`);
                if(status === httpStatus.ok) {
                    //set Data
                    setUserList(data?.studyUsers);
                    setStudyInfo(data?.studyInfo);
                }
            } catch (e) {
                errorHandle(e, navigate, `/studies/${params.sid}/dashboard`);
            }
        })();
    }, [])

    return (
        <PrimaryCard>
            <CardHeader>
                <div className={'row'}>
                    <div className={'col-8'}>
                        <h3><RightOutlined className={'mr-2'} />User</h3>
                    </div>
                    {/*<div className={'col-4'}>*/}
                    {/*    <Input.Search*/}
                    {/*        placeholder="Search by..."*/}
                    {/*        onSearch={(value) => onSearch(baseData, setFilterData, value)}*/}
                    {/*        enterButton*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            </CardHeader>
            <CardBody>
                {
                    userList == null?(
                        <NormalLoading />
                    ) : (
                        <DataGrid dataSource={userList}
                                  height={'600'}
                                  id={'gridStudyUsers'}
                                  ref={(ref) => {setDataGrid(ref);}}
                                  allowedPageSizes={true}
                                  allowColumnResizing={false}
                                  columnMinWidth={50}
                                  columnAutoWidth={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  showBorders={true}
                                  hoverStateEnabled={true}
                                  allowColumnReordering={true}
                                  keyExpr={'studyUserId'}
                                  onExporting={(e) => onExporting(e, 'StudyUsers',
                                      ({gridCell, excelCell}) => {
                                          //displayName
                                          if (gridCell.rowType === 'data' && gridCell.column.dataField === 'displayName') {
                                              excelCell.value = [gridCell.data?.displayName, `(${gridCell.data?.username})`].join('\n');
                                          }
                                          //Evaluator Info
                                          else if (gridCell.rowType === 'data' && gridCell.column.dataField === 'evaluatorInfo') {
                                              excelCell.value = gridCell?.data?.studyUserRoleList.map((studyUserRole) => {
                                                      let res = "";
                                                      res += `${studyUserRole?.independentEvaluation?'Evaluator':''}`;
                                                      res += `${studyUserRole?.evaluatorNo}`;
                                                      return res;
                                                  }
                                              ).join('\n');
                                          }
                                          //Role Info
                                          else if (gridCell.rowType === 'data' && gridCell.column.dataField === 'roleInfo') {
                                              excelCell.value = gridCell?.data?.studyUserRoleList.map((studyUserRole) => {
                                                      let res = "";
                                                      res += `${studyUserRole?.roleName} - [ `;
                                                      res += studyUserRole?.siteAll === false ? studyUserRole?.siteNames : 'ALL';
                                                      res += ` / ${studyUserRole?.envs} ]`;
                                                      return res;
                                                  }
                                              ).join('\n');
                                          }
                                          //Action
                                          else if (gridCell.column.dataField === 'action') {
                                              excelCell.value = '';
                                          }
                                      })}>
                            {/*<GroupPanel visible={true} />*/}
                            {/*<Grouping autoExpandAll={true} />*/}
                            <Column dataField={'studyUserId'} caption={t('column.no')} fixed={true} fixedPosition={'left'} width={'60px'}
                                    cellRender={({row}) => (
                                        <span key={row?.loadIndex}>{userList?.length - row?.loadIndex}</span>
                                    )}
                            />
                            <Column dataField={'displayNameAndUsername'} caption={t('column.user')} fixed={true} fixedPosition={'left'} width={'240px'}
                                    cellRender={({data, row}) => (
                                        <>
                                            <span>{data?.displayName}</span>
                                            <br />
                                            {`(${data?.username})`}
                                        </>
                                    )}/>
                            {
                                studyInfo?.useIndependentEvaluation &&
                                <Column dataField={'evaluatorInfo'} caption={t('column.evaluator-info')} fixed={true}
                                        fixedPosition={'left'}
                                        cellRender={({data, row}) => (
                                            <>
                                                {
                                                    data?.independentEvaluator &&
                                                    <Tag>Evaluator - No.{data?.evaluatorNo}</Tag>
                                                }
                                            </>
                                        )}/>
                            }
                            <Column dataField={'roleInfo'} caption={t('column.role-info')} width={'700px'}
                                    cellRender={({data, row}) => (
                                        <Row gutter={[0,10]} key={row?.loadIndex}>
                                            {
                                                data?.studyUserRoleList.map((studyUserRole) => (
                                                        <Col span={'24'} key={studyUserRole?.studyUserRoleId}>
                                                            <Tag color={'geekblue'} className={'mr-2'}>
                                                                {studyUserRole?.roleName}
                                                            </Tag>
                                                            <span className={'mr-1'}>- [ </span>
                                                            {
                                                                studyUserRole?.siteAll === false && (studyUserRole?.siteNameList??[]).length > 0 ? (
                                                                    studyUserRole?.siteNameList.map((siteName) => (
                                                                        <Tag color={'cyan'} className={'mr-2'}>
                                                                            {siteName}
                                                                        </Tag>
                                                                    ))
                                                                ) : (
                                                                    <Tag color={'volcano'}>
                                                                        ALL
                                                                    </Tag>
                                                                )
                                                            }
                                                            <span className={'mr-2'}>/ </span>
                                                            {
                                                                (studyUserRole?.envList??[]).map((env) => (
                                                                        <Tag color={'default'} key={env?.key} className={'mr-2'}>
                                                                            {env?.key}
                                                                        </Tag>
                                                                    )
                                                                )
                                                            }
                                                            <span>]</span>
                                                        </Col>
                                                    )
                                                )
                                            }
                                        </Row>
                                    )}
                            />
                            <Column dataField={'createdDate'} caption={t('column.created-date')} dataType={'date'} format={'yyyy-MM-dd HH:mm:ss'} />
                            <Column dataField={'expiredDate'} caption={t('column.expired-date')} dataType={'date'} format={'yyyy-MM-dd'} />
                            <Column dataField={'action'} caption={t('column.action')} dataType={'datetime'} fixed={true} fixedPosition={'right'}
                                    cellRender={({data}) => (
                                        <>
                                            <Button className={'mr-3'}>
                                                <Link to={`/studies/${params.sid}/users/edit?id=${data?.studyUserId}`}>
                                                    <EditOutlined className={'mr-2'} />Edit
                                                </Link>
                                            </Button>
                                        </>
                                    )} />
                            <Toolbar>
                                <Item name={'columnChooserButton'} />
                                <Item name={'exportButton'} />
                            </Toolbar>
                            <ColumnChooser enabled={true} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <LoadPanel enabled={true} />
                            <HeaderFilter visible={true} allowSearch={true} />
                            <FilterRow visible={true} applyFilter={'auto'} />
                            <StateStoring enabled={true} type={'sessionStorage'} storageKey={`storage-studyUsers`}/>
                            <Scrolling rowRenderingMode={'virtual'} />
                            <Paging defaultPageSize={10}/>
                            <Pager
                                visible={true}
                                displayMode={'compact'}
                                allowedPageSizes={[10, 20, 30, 50]}
                                showPageSizeSelector={true}
                                showInfo={true}
                            />
                        </DataGrid>
                    )
                }
            </CardBody>
            <CardFooter className='text-right'>
                <Button>
                    <Link to={`/studies/${params.sid}/users/search`} ><i className='fa fa-plus mr-2' /> Add a new user</Link>
                </Button>
            </CardFooter>
        </PrimaryCard>
    )
}

export default withTranslation()(ManageUsers);