import {CardBody, CardFooter, CardHeader, PrimaryCard} from "../../../../pages/PageStyleBox";
import {Button, Col, Dropdown, message, Popover, Row, Space, Tag, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {errorHandle, getIndexOfTableCell, onExporting} from "../../../../lib/BuilderUtils";
import {RightOutlined, UpOutlined} from "@ant-design/icons";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {useSelector} from "react-redux";
import {isManagerAuthority} from "../../../../lib/AuthorityUtils";
import _ from "lodash";
import {isEmpty} from "../../../../lib/StringUtils";
import {withTranslation} from "react-i18next";
import DataGrid, {
    Column,
    ColumnChooser,
    Export,
    FilterRow,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    Scrolling,
    StateStoring,
    Toolbar
} from "devextreme-react/data-grid";
import NormalLoading from "../../NormalLoading";

const StudiesList = ({t}) => {

    const authorities = useSelector(({user}) => (user?.user.authorities));

    const navigate = useNavigate();
    const [studyList, setStudyList] = useState(null);
    const [dataGrid, setDataGrid] = useState(null);
    const [edcStudyUndefinedSiteMap, setEdcStudyUndefinedSiteMap] = useState(null);
    const [multiContract, setMultiContract] = useState(false);
    const [contracts, setContracts] = useState([]);

    //Init
    useEffect( () => {
        (async () => {
            //GET Request
            try {
                //REST API Request
                const {status, data} = await axios.get(`/api/builder/studies`);
                if(status === httpStatus.ok) {
                    //State Setting
                    setStudyList(data?.studyList);
                    setEdcStudyUndefinedSiteMap(data?.edcStudyUndefinedSiteMap);
                    setMultiContract(data?.multiContract);

                    console.log(data?.contracts);

                    setContracts(data?.contracts);
                }
            } catch (e) {
                //Error Exception
                errorHandle(e, navigate);
            }
        })()
    }, []);

    //Study Edit
    const onEdit = (studyID) => {
        navigate(`/studies/edit?id=${studyID}`)
    }

    //Study Lock
    const onLock = (studyOID, lockStatus) => {

        //StudyLock에 대한 요청
        const lockModal = withReactContent(Swal);
        lockModal.fire({
            title: t('studies.modal.header.title'),
            icon: `${lockStatus? 'error':'info'}`,
            iconColor: `${lockStatus ? 'red' : 'skyblue'}`,
            html: `<Space>
                        <h5 class="alert ${lockStatus ? 'alert-danger' : 'alert-info'}">
                            ${lockStatus ? t('studies.modal.terminate') : t('studies.modal.terminate-cancel')}
                        </h5>
                   </Space>`,
            input: "password",
            inputPlaceholder: t('input.placeholder.password'),
            inputAttributes: {
                autocomplete: 'off',
            },
            showCancelButton: true,
            cancelButtonText: `<i class="fa fa-close" /> ${t('btn.cancel')}`,
            confirmButtonText: `<i class="fa fa-check" /> ${t('btn.submit')}`,
            showLoaderOnConfirm: true,
            preConfirm: (password) => {
                if (password === '') {
                    lockModal.showValidationMessage(t('input.required.message.password'));
                    return;
                }

                return axios({
                    url: '/api/builder/studies',
                    data: {
                        password: password,
                        oid: studyOID,
                        lockStatus: lockStatus,
                    },
                    method: 'put'
                }).then(response => {
                    message.success(response.data.message);

                    //DataGrid Instance 설정
                    const dataGridInstance = dataGrid.instance;
                    const currentIndex = dataGridInstance.pageIndex();

                    const changeData = _.cloneDeep(studyList);
                    changeData.map((data, index) => {
                        if (data.studyOID === studyOID) {
                            data.studyTerminate = lockStatus;
                            changeData.splice(index, 1, data);
                        }
                    });
                    setStudyList(changeData);

                    setTimeout(() => {
                        dataGridInstance.pageIndex(currentIndex);
                    }, 100)

                }).catch(({response: {data}}) => {
                    lockModal.showValidationMessage(data.message);
                })
            },
        })
    }

    const onCreateStudy = ({key}) => {
        navigate(`/studies/edit?contractId=${key}`);
    }

    const contractMenus = {
        items: (contracts??[]).map(ct => ({
            label: ct?.companyName,
            key: ct?.id,
            icon: <i className={'fa fa-building-o'}></i>
        })),
        onClick: onCreateStudy,
    }

    return (

        <PrimaryCard>
            <CardHeader>
                <Row>
                    <Col span={16}>
                        <h3><RightOutlined className={'mr-2'} />Studies</h3>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                {
                    studyList == null?(
                        <NormalLoading />
                    ):(
                        <DataGrid dataSource={studyList}
                                  height={'600'}
                                  id={'gridStudies'}
                                  ref={(ref) => {setDataGrid(ref);}}
                                  allowedPageSizes={true}
                                  allowColumnResizing={false}
                                  columnMinWidth={50}
                                  columnAutoWidth={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  showBorders={true}
                                  hoverStateEnabled={true}
                                  allowColumnReordering={true}
                                  keyExpr={'num'}
                                  onCellClick={({values, columnIndex, rowType, data}) => {

                                      //PENDING 상태인 경우, 접속 불가
                                      if(data?.status?.toUpperCase() === 'PENDING') {

                                      }
                                      //Action 항목이 아니면
                                      else if(rowType === 'data' && columnIndex !== (values?.length??0)-1) {
                                          navigate(`/studies/${data?.studyOID}/dashboard`);
                                      }
                                  }}
                                  onExporting={(e) => onExporting(e, 'Studies',
                                      ({gridCell, excelCell}) => {
                                          //Phase - Other 항목인 경우, excell Value를 phaseOther로 설정
                                          if(gridCell.rowType === 'data' && gridCell.column.dataField === 'phase' && gridCell.value === 'Other' ) {
                                              excelCell.value = gridCell?.data?.phaseOther||'';
                                          }
                                          //Action
                                          else if(gridCell.column.dataField === 'action') {
                                              excelCell.value = '';
                                          }
                                      }
                                  )}>
                            {/*<GroupPanel visible={true} />*/}
                            {/*<Grouping autoExpandAll={true} />*/}
                            <Column dataField={'num'} caption={t('column.no')} fixed={true} fixedPosition={'left'} width={'60px'} />
                            {
                                multiContract &&
                                <Column dataField={'contractName'} caption={t('column.contract')} dataType={'string'} width={'100px'} />
                            }
                            <Column dataField={'sponsorName'} caption={t('column.sponsor')} dataType={'string'} width={'150px'} />
                            <Column dataField={'phase'} caption={t('column.phase')} dataType={'string'} width={'100px'}
                                    cellRender={({data}) => (<>{data?.phase==='Other'?data?.phaseOther:data?.phase}</>)} />
                            <Column dataField={'studyName'} caption={t('column.study.name')} dataType={'string'}
                                    cellRender={({data}) => (
                                        <span>
                                            {data.studyName}&nbsp;&nbsp;
                                            {
                                                (edcStudyUndefinedSiteMap !== null && !isEmpty(edcStudyUndefinedSiteMap[data.id])) &&
                                                <Popover title={t('studies.site.restriction-notice')} content={
                                                    <span>
                                                        <strong>
                                                            [{edcStudyUndefinedSiteMap[data.id]}]
                                                        </strong>
                                                        {t('studies.limit-count-restrictions')}
                                                    </span>
                                                }>
                                                    <i className='fa fa-exclamation-circle' style={{color:'red'}} />
                                                </Popover>
                                            }
                                        </span>
                                    )}/>
                            <Column dataField={'createdDate'} caption={t('column.created-date')} dataType={'datetime'} format={'yyyy-MM-dd HH:mm:ss'} width={'150'} />
                            <Column dataField={'status'} caption={t('column.status')} width={'100'} dataType={'string'}
                                    cellRender={({data}) => (
                                        <>
                                            {
                                                data?.status === 'PENDING' ? (
                                                    <Tooltip title={t('studies.approval.administrator')}
                                                             color={'#b70000'} overlayInnerStyle={{width:'14rem'}}
                                                    >
                                                        <Tag color={'red'}>{data?.status}</Tag>
                                                    </Tooltip>
                                                ) : (
                                                    <Tag>{data?.status}</Tag>
                                                )
                                            }
                                        </>
                                    )} />
                            <Column dataField={'studyTerminate'} caption={t('column.study-terminated')} width={'100'}
                                    cellRender={({data}) => (
                                        <>
                                            {
                                                data?.studyTerminate ? (
                                                    <i className='fa fa-times-circle-o'></i>
                                                ) : (
                                                    <i className='fa fa-circle-o'></i>
                                                )
                                            }
                                        </>
                                    )} />
                            <Column dataField={'action'} caption={t('column.action')} fixed={true} fixedPosition={'right'} width={'360'}
                                    cellRender={({data}) => (
                                        <Space size={'middle'} id='actions'>
                                            <Button type={'default'} onClick={() => {
                                                onEdit(data.studyId)
                                            }} icon={<i className='fe fe-edit mr-2'/>}>
                                                {t('btn.edit')}
                                            </Button>

                                            {
                                                data.status !== 'PENDING' &&
                                                <Button type={'default'} onClick={() => {
                                                    onLock(data.studyOID, (!data.studyTerminate))
                                                }} icon={data.studyTerminate ?(<i className='fa fa-circle-o mr-2'/>):(<i className='fa fa-times-circle-o mr-2' />)}>
                                                    {data.studyTerminate ? t('btn.terminate-cancel') : t('btn.terminate')}
                                                </Button>
                                            }
                                            {
                                                (isManagerAuthority(authorities) && (data?.manager??false)) &&
                                                <Button icon={<i className={'fe fe-share-2 mr-2'} />}
                                                        onClick={() => navigate(`/studies/manage-access?id=${data.studyId}`)}>
                                                    {t('btn.manage.access')}
                                                </Button>
                                            }
                                        </Space>
                                    )} />
                            <Toolbar>
                                <Item name={'columnChooserButton'} />
                                <Item name={'exportButton'} />
                            </Toolbar>
                            <ColumnChooser enabled={true} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <LoadPanel enabled={true} />
                            <HeaderFilter visible={true} allowSearch={true} />
                            <FilterRow visible={true} applyFilter={'auto'} />
                            <StateStoring enabled={true} type={'sessionStorage'} storageKey={`storage-studies`}/>
                            <Scrolling rowRenderingMode={'virtual'} />
                            <Paging defaultPageSize={10}/>
                            <Pager
                                visible={true}
                                displayMode={'compact'}
                                allowedPageSizes={[10, 20, 30, 50]}
                                showPageSizeSelector={true}
                                showInfo={true}
                            />
                        </DataGrid>
                    )
                }
            </CardBody>
            <CardFooter>
                {
                    multiContract?(
                        <Dropdown menu={contractMenus} placement={'topRight'}>
                            <Button>
                                <Space>
                                    {t('studies.btn.add')}
                                    <UpOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    ):(
                        <Button onClick={() => { navigate('/studies/edit'); }}> {t('studies.btn.add')} </Button>
                    )
                }
            </CardFooter>
        </PrimaryCard>
    )
}

export default withTranslation()(StudiesList);
