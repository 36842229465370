import axios from "axios";
import {trackPromise} from "react-promise-tracker";

export const method = {
    get: 'get',
    post: 'post',
    put: 'put',
    delete: 'delete',
}

export const getGlobalLibraryWithAxios = async (id) => {
    const { data } = await axios.get(`/api/builder/global-library/v/${id}`);
    return data;
}

export const saveDefWithAxios = async (params) => {
    const {data} = await trackPromise(axios({
        url: `/api/builder/global-library/v/${params?.id}/${params?.attr}/${params?.oid}`,
        data: params,
        method: params?.method
    }));
    return data;
}

export const saveFormRefWithAxios = async (params) => {
    const {data} = await trackPromise(axios({
        url: `/api/builder/global-library/v/${params?.id}/${params?.attr}/updates`,
        data: params.defList,
        method: 'post'
    }));
    return data;
}

export const downloadLibraryWithAxios = async (params) => {
    const {sid, id} = params?.params;
    const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
    const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
    const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

    const {data} = await trackPromise(axios({
        url: `/api/builder/library-interface/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/library/download`,
        data: params?.data,
        method: 'post'
    }));
    return data;
}

export const uploadLibraryWithAxios = async (params) => {
    const {data} = await trackPromise(axios({
        url: `/api/builder/library-interface/global-library/v/${params.libraryId}/library/upload`,
        data: params?.data,
        method: 'post'
    }));
    return data;
}