import {Form, Select, Tag} from 'antd';
import React from "react";
import {isEmpty} from "../../../../lib/StringUtils";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../../lib/ODMUtils";
import {useTranslation} from "react-i18next";

const {Item} = Form;
const {Option} = Select;

const FormSelectDataType = ({required = true, name = "dataType", label = "Data Type",
                                icons = null, dataTypes, onChange, disabledOptions = null, value}) => {

    const metaDataStore = useSelector((store) => store.metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const {t} = useTranslation();

    return (
        <Item name={name} label={<span className='text-primary'>{label}</span>}
              rules={[{required: required, message: t('message.warning.required-entry')}]}
              required={false}>
            {
                isProd?(
                    <Tag color={'geekblue'}>
                        <span className={'font-size-16 font-weight-bold'}>
                            {icons != null && icons[value]}
                            {dataTypes[value]}
                        </span>
                    </Tag>
                ):(
                    <Select onChange={e => onChange !== undefined ? onChange(e) : null}>
                        <Select.Option value={""}>...</Select.Option>
                        {
                            Object.keys(dataTypes)
                                .map(key => (
                                <Option key={key} value={key} disabled={(!isEmpty(disabledOptions) && disabledOptions.includes(key))}>
                                    {icons != null && (<>{icons[key]}&nbsp;</>)}
                                    {dataTypes[key]}
                                </Option>
                            ))
                        }
                    </Select>
                )
            }
        </Item>
    )

}

export default FormSelectDataType;
