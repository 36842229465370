import {isEmpty} from "../../../../../lib/StringUtils";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Modal, Row, Select, Tag} from "antd";
import ModalTitle from "../../../../../container/build-tool/modal/component/ModalTitle";
import ModalFooter from "../../../../../container/build-tool/modal/ModalFooter";
import LibraryFormInputOID from "../ant-form/LibraryFormInputOID";
import LibraryFormInputName from "../ant-form/LibraryFormInputName";
import LibraryItemContainer from "../container/LibraryItemContainer";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {MenuOutlined} from "@ant-design/icons";
import style from "../../../../../container/build-tool/modal/style.module.scss";
import NoDataBlock from "../../../NoDataBlock";
import {Table} from "reactstrap";
import {useTranslation} from "react-i18next";

const {Item, List} = Form;

const LibraryValueListDef = ({library, open, valueListDef, languages, onFinish, onCancel}) => {

    const {t} = useTranslation();

    const [form] = Form.useForm();
    const [filteredItemDefs, setFilteredItemDefs] = useState(null);
    const [itemDefs, setItemDefs] = useState(Array.from(library.item.values()));

    const [defOpen, setDefOpen] = useState(false);
    const [defItem, setDefItem] = useState(null);
    const [defOption, setDefOption] = useState({}); //DefOption 사용

    useEffect(() => {
        const newItemDefs = Array.from(library.item.values());
        setItemDefs(newItemDefs);
        if(valueListDef != null) {
            setFilteredItemDefs(newItemDefs.filter(i => !(valueListDef?.itemRef??[]).some(ir => ir.itemOID === i.oid) && i.controlType !== 'LABEL'));
        }
    }, [library]);

    const itemAppend = () => {
        const itemOID = form.getFieldValue('itemOID');
        const itemRef = form.getFieldValue('itemRef');

        if(isEmpty(itemOID)) {
            // message.warning("추가 할 Item 항목이 존재하지 않습니다.");
            return;
        }

        if (itemRef !== undefined) {
            const result = [...itemRef, {itemOID: itemOID, mandatory: false, orderNumber: itemRef?.length}];
            form.setFieldsValue({itemRef: result})
        } else {
            const result = [{itemOID: itemOID, mandatory: false, orderNumber: itemRef?.length??0}];
            form.setFieldsValue({itemRef: result});
        }

        if (filteredItemDefs !== null) {
            setFilteredItemDefs(filteredItemDefs.filter(i => i.oid !== itemOID && i.controlType !== 'LABEL'));
        } else {
            setFilteredItemDefs(itemDefs.filter(i => i.oid !== itemOID && i.controlType !== 'LABEL'));
        }

        //Select 값 초기화
        form.setFieldsValue({itemOID: null});
    }

    const itemRemove = (index) => {
        const itemRef = form.getFieldValue('itemRef');
        const [removed] = itemRef.splice(index, 1); //ItemRef에서 값 삭제
        setFilteredItemDefs(itemDefs.filter(i => !(itemRef.some(ir => ir.itemOID === i.oid)) && i.controlType !== 'LABEL'));
        form.setFieldsValue(itemDefs.filter(i => !(itemRef.some(ir => ir.itemOID === i.oid)) && i.controlType !== 'LABEL')); //field상에서 index에 해당하는 값 삭제.

        // message.success(`[${removed.itemOID}] Item을 참조항목에서 삭제하였습니다.`);
    }

    const onDragEnd = (result) => {
        const {source, destination} = result;
        // Drop한 영역이 없는 경우 아무 처리가 없이 종료
        if (!destination) {
            return;
        }
        // Drag를 시작한 영역과 Drop한 영역의 위치가 똑같으면 아무 처리 없이 종료.
        if (destination.index === source.index) { return; }

        const itemRef = form.getFieldValue('itemRef');
        const [item] = itemRef.splice(source.index, 1);
        itemRef.splice(destination.index, 0, item);

        //orderNumber 자동 설정.
        itemRef.map((item, index) => {
            item.orderNumber = index+1;
        })
        form.setFieldsValue({itemRef});
    }

    useEffect(() => {
        //YES or No로 되어있는 값을 true, false로 변경
        if (valueListDef?.itemRef != null && valueListDef.itemRef.length > 0) {
            valueListDef.itemRef.map((itemRef, index) => {
                valueListDef.itemRef[index].mandatory = valueListDef.itemRef[index].mandatory === 'YES' ? true : false;
            });
            //itemRef값이 존재하면 존재한 itemRef값을 filter처리한 값을 itemDefs로 설정.
            setFilteredItemDefs(itemDefs.filter(i => !valueListDef.itemRef.some(ir => ir.itemOID === i.oid) && i.controlType !== 'LABEL'));
        }

        form.setFieldsValue(valueListDef);

    }, [valueListDef]);

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    const getDataType = (index) => {
        const itemData = library.item.get(form.getFieldValue(['itemRef', index]).itemOID);
        if(itemData !== undefined) {
            const {dataType, length, fractionDigits} = itemData;
            let res = dataType||'-';
            if (['TEXT', 'INTEGER', 'FLOAT'].includes(dataType)) {
                res += "(";
                if(length != null) {res +=`${length}`;}
                if(fractionDigits != null) {res +=`.${fractionDigits}`;}
                if(length == null && fractionDigits == null) {res +='-';}
                res += ")";
            }
            return res;
        }
        return '-';
    }

    const onCreate = (defType) => {
        setDefOpen(true);
        setDefItem(null);
        setDefOption({
            option: 'create'
        })
    }

    const onEdit = (defType, defItem) => {
        setDefOpen(true);
        setDefItem(defItem);
        setDefOption({
            option: 'modify'
        })
    }

    return (
        <Modal
            forceRender={true}
            wrapClassName={'odm-modal'}
            title={<ModalTitle iconType={'valueListDef'}>ValueList</ModalTitle>}
            width={800}
            onCancel={onCancel}
            open={open}
            destroyOnClose={true}
            styles={{body:{overflowY: 'auto', height: 'calc(100vh - 20rem)'}}}
            footer={<ModalFooter onCancel={onCancel} form={form} />}
        >
            <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
                <Card title={'Attributes'} type={'inner'} className={'mb-2'}>
                    {
                        defOpen &&
                        <LibraryItemContainer defOpen={defOpen} setDefOpen={setDefOpen}
                                              defItem={defItem} setDefItem={setDefItem}
                                              defOption={defOption} setDefOption={setDefOption} />
                    }
                    <Row gutter={[20,0]}>
                        <Col span={12}>
                            <LibraryFormInputOID selectedOID={valueListDef?.oid??""} form={form} />
                        </Col>
                        <Col span={12}>
                            <LibraryFormInputName name={valueListDef?.name} defType={'valueListDef'} form={form} />
                        </Col>
                        <Col span={24} className={'mb-2'}>
                            <span className={'text-primary mr-3'}>Item Refs.</span>
                            <Button size={'small'} className={'mr-3'} icon={<i className={'fa fa-plus mr-2'} />} onClick={() => onCreate("itemDef")}>ItemDef</Button>
                        </Col>
                        <Col span={24}>
                            <Item name={'itemOID'}>
                                <Select showSearch onSelect={itemAppend}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                    {
                                        (filteredItemDefs !== null ? filteredItemDefs : itemDefs).map((item, index) => (
                                            <Select.Option key={index} value={item.oid}>
                                                {`[${item.oid}] ${item.name}`}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Item>
                        </Col>
                        <Col span={24} className={'mb-3'}>
                            <Tag className={'font-size-14'} icon={<><i className='fa fa-exclamation-circle font-size-14'/> Total Count : </>}>{form.getFieldValue("itemRef")?.length||'0'}</Tag>
                        </Col>
                        <Col span={24}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th className={'text-center'} style={{width:'50px'}}>Sort</th>
                                        <th className={'text-center'} style={{width: '50px'}}>No.</th>
                                        <th style={{width:'100px'}}>Item</th>
                                        <th className={'text-center'} style={{width:'150px'}}>Data Type</th>
                                        <th className={'text-center'} style={{width:'50px'}}>#</th>
                                    </tr>
                                    </thead>
                                    <Droppable droppableId={'itemRef'}>
                                        {
                                            (provided, snapshot) => (
                                                <tbody key={'tbody'}
                                                       ref={provided.innerRef}
                                                       {...provided.droppableProps}
                                                       isDraggingOver={snapshot.isDraggingOver}>
                                                <List name={'itemRef'}
                                                      rules={[
                                                          {
                                                              validator: async (_, names) => {
                                                                  if(names == null) {
                                                                      return Promise.reject(new Error(t('message.there.is.no.item.reference.entry')));
                                                                  }
                                                                  else if(names.length <= 0) {
                                                                      return Promise.reject(new Error(t('message.warning.required-entry')));
                                                                  } else {
                                                                      return Promise.resolve();
                                                                  }
                                                              }
                                                          }
                                                      ]}>
                                                    {
                                                        (fields, { add, remove }, {errors}) => (
                                                            fields.length > 0 ? (
                                                                fields.map((field, index) => (
                                                                    <Draggable key={index} className={'code-list-table'}
                                                                               draggableId={`${index}`}
                                                                               index={index}>
                                                                        {
                                                                            (dragProvided, dragSnapshot) => (
                                                                                <>
                                                                                    {
                                                                                        library.item.get(form.getFieldValue(['itemRef', index])?.itemOID) != null &&
                                                                                        <tr key={index}
                                                                                            ref={dragProvided.innerRef}
                                                                                            {...dragProvided.draggableProps}
                                                                                            isDragging={dragSnapshot.isDragging}>
                                                                                            <td className='text-center bg-white' style={{width:'50px', verticalAlign:'middle'}}
                                                                                                {...dragProvided.dragHandleProps}>
                                                                                                <MenuOutlined/>
                                                                                            </td>
                                                                                            <td className={'text-center bg-white'} style={{width:'50px', verticalAlign:'middle'}}>
                                                                                                <Tag color={'success'} className={'mr-0'}>{index+1}</Tag>
                                                                                            </td>
                                                                                            <td className={'bg-white'} style={{width:'310px', verticalAlign:'middle'}}>
                                                                                                <div>
                                                                                                    <Tag color={'geekblue'}
                                                                                                         className={`mr-0 ${style.tagBtn}`}
                                                                                                         style={{cursor: 'pointer'}}
                                                                                                         icon={<i className='fa fa-search-plus mr-1'/>}
                                                                                                         onClick={() => onEdit("itemDef", library.item.get(form.getFieldValue(['itemRef', index])?.itemOID))}>
                                                                                                        {form.getFieldValue(['itemRef', index])?.itemOID}
                                                                                                    </Tag>
                                                                                                </div>
                                                                                                {library.item.get(form.getFieldValue(['itemRef', index]).itemOID)?.name}
                                                                                            </td>
                                                                                            <td className={'text-center bg-white'} style={{width:'300px', verticalAlign:'middle'}}>
                                                                                                <Tag color={'processing'} className={'mr-0'}>{getDataType(index)}</Tag>
                                                                                            </td>

                                                                                            <td className={'text-center bg-white'} style={{width:'50px', verticalAlign:'middle'}}>
                                                                                                <Button size={'small'} onClick={() => itemRemove(index)}>
                                                                                                    <i className={'fa fa-trash-o'} />
                                                                                                </Button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                    </Draggable>
                                                                ))
                                                            ) : (
                                                                <>
                                                                    {console.log("Fields : ", fields)}
                                                                    <tr>
                                                                        <td className='text-center' colSpan={6}>
                                                                            <NoDataBlock />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-center' colSpan={6}>
                                                                            <Form.ErrorList errors={errors} />
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        )
                                                    }
                                                </List>
                                                {provided.placeholder}
                                                </tbody>
                                            )
                                        }
                                    </Droppable>
                                </Table>
                            </DragDropContext>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Modal>
    )

}

export default LibraryValueListDef;
