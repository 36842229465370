import Schedule from "../../../components/common/odm/Schedule";
import {Helmet} from "react-helmet-async";
import React from "react";
import ODMTabs from "../../../layouts/Tab/odm";
import {mdiCalendar} from "@mdi/js";
import Icon from "@mdi/react";

const StudyEventsPage = () => {

    return (
        <ODMTabs menu={'study-events'}>
            <Helmet title={'ODM Designer v2.0.0 :: Study Events'}/>
            <Schedule defType={'studyEventDef'} refType={'formRef'} refTitle={'Form'}
                      defTitle={<span><Icon path={mdiCalendar} size={1} className={'mr-2'} />Event</span>}
            />
        </ODMTabs>
    )

}

export default StudyEventsPage;
