import {Helmet} from "react-helmet-async";
import React from "react";
import RolesPrivileges from "../../../../components/common/builder/studies/RolesPrivileges";
import SettingsTabs from "../../../../layouts/Tab/settings";

const RolesPrivilegesPage = () => {

    return (
        <SettingsTabs menu={'roles-privileges'}>
            <Helmet title={'BUILDER :: Role & Privilege'}/>
            <RolesPrivileges/>
        </SettingsTabs>
    )
}

export default RolesPrivilegesPage;