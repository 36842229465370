import {Helmet} from "react-helmet-async";
import CRFVersionEdit from "../../../../../components/common/builder/studies/CRFVersionEdit";

const CRFVersionEditPage = () => {

    return (
        <>
            <Helmet title={'BUILDER :: CRF Versions Edit'} />
            <CRFVersionEdit />
        </>
    )
}

export default CRFVersionEditPage;