import {Form, Select} from "antd";
import React from "react";
import {isEmpty} from "../../../../../lib/StringUtils";
import _ from "lodash";
import {useTranslation} from "react-i18next";

const {Item} = Form

const LibraryFormSelectYesOrNo = ({name, label, required, icons = null, yesOrNo, value, disabledOption}) => {

    const checkDisabledOption = (key) => {
        if(isEmpty(disabledOption)) {
            return false;
        } else if(_.isArray(disabledOption)) {
            return disabledOption.includes(key);
        } else {
            return key === disabledOption
        }
    }

    const {t} = useTranslation();

    return (
        <Item name={name} label={<span className={'text-primary'}>{label}</span>}
              required={false}
              rules={[
                  {required: required === undefined ? false : required, message: t('message.this.is.required.selection')}
              ]}>
            <Select>
                {Object.keys(yesOrNo).map(key => (
                    <Select.Option key={key} value={key} disabled={checkDisabledOption(key)}>
                        {icons != null && (<>{icons[key]}&nbsp;</>)}
                        {yesOrNo[key]}
                    </Select.Option>
                ))}
            </Select>
        </Item>
    )
}

export default LibraryFormSelectYesOrNo;