import {Button, Col, Form, Input, Modal, Row, Select, Spin, Switch, Typography} from 'antd';
import AceEditor from "react-ace";

import React, {useEffect, useState} from "react";
import {isEmpty} from "../../lib/StringUtils";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-sqlserver";
import "ace-builds/src-noconflict/snippets/sqlserver";
import langTools from "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/ext-searchbox";
import axios from 'axios';
import {useSelector} from "react-redux";
import {LoadingOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";

const tables = [
    {caption:'caption.system.info.table', value:'_SYSTEM', meta:'System Table'},
]

const columns = [
    {caption:'caption.site.code', value: '_SITE_CODE', meta:'System Column'},
    {caption:'caption.site.name', value: '_SITE_NAME', meta:'System Column'},
    {caption:'caption.site.phone.num', value: '_SITE_PHONE', meta:'System Column'},
    {caption:'caption.site.fax.num', value: '_SITE_FAX', meta:'System Column'},
    {caption:'caption.site.pi.name', value: '_SITE_PI_NAME', meta:'System Column'},
    {caption:'caption.site.department', value: '_SITE_DEPARTMENT', meta:'System Column'},
    {caption:'caption.site.address', value: '_SITE_ADDRESS', meta:'System Column'},
    {caption:'caption.site.email', value: '_SITE_EMAIL', meta:'System Column'},

    {caption:'caption.reporter.email', value: '_REPORTER_EMAIL', meta:'System Column'},
    {caption:'caption.reporter.reported.date', value: '_REPORTER_REPORTED_DATE', meta:'System Column'},
    {caption:'caption.reporter.name', value: '_REPORTER_NAME', meta:'System Column'},
    {caption:'caption.report.type.sae.report,initial.follow.up', value: '_REPORT_TYPE', meta:'System Column'}
]

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const SAEReportItemGroupDef = ({metaData, formOID, saeItemGroupDef = null, itemGroupOID, itemGroupName, onFinish, showModal, closeModal, isProd}) => {
    const [form] = Form.useForm();
    const initialValue = {
        itemGroupOID:itemGroupOID,
        dataSourceDefs:[]
    };
    const {t} = useTranslation();

    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validate, setValidate] = useState(false);
    const metaDataStore = useSelector((store) => store.metaDataStore);
    const [showParseBtn, setShowParseBtn] = useState(false);
    const [saeReportFormDef, setSaeReportFormDef] = useState(initialValue);
    const [sql, setSql] = useState("");
    const [errors, setErrors] = useState([]);
    // setEditMode(false);

    useEffect(() => {
        /*setSaeReportFormDef(saeReportFormDef);*/

        if(saeReportFormDef?.['sql'] != null) { //값이 이미 입력되어있는 경우,
            setEditMode(false);
        } else {
            setEditMode(true);
        }

        form.setFieldsValue(saeReportFormDef);
    }, [saeReportFormDef]);

    useEffect(() => {
        form.resetFields();
        setLoading(true);
        // setEditMode(true);

        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    useEffect(() => {
        if((saeItemGroupDef?.['sql']) != null) { //undefined, null, ''
            setSql(saeItemGroupDef.sql);
            setSaeReportFormDef(saeItemGroupDef);
        } else {
            setSaeReportFormDef(initialValue);
        }
    }, [saeItemGroupDef])


    const parse = () => {
        setShowParseBtn(false);
        setValidate(true);
        (async () => {

            const res = await axios.post(`/api/studies/${metaDataStore.versionInfo.studyOID}/mdv/${metaDataStore.versionInfo.oid}/sql/parse`, {itemGroupOID, sql});
            setValidate(false);

            const data = res.data;
            if(data.errors.length === 0) {

                const itemGroupDef = {...saeReportFormDef};

                itemGroupDef.itemGroupOID = data.itemGroupOID;
                itemGroupDef.dataSourceDefs = data.dataSourceDefs;
                itemGroupDef.sql = sql;
                form.setFieldsValue(itemGroupDef);

                // setSaeReportFormDef(itemGroupDef);
                setErrors([]);
            } else {
                setErrors(data.errors);
            }
        })();
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values) => {
                // Submit values



                values.sql = sql;
                onFinish(values);
                closeModal();
            })
            .catch((errorInfo) => {});
    };

    const onChange = (newValue, action) => {
        // console.log('change expr =', newValue, 'action=', action);
        if(editMode) {
            setSql(newValue);
            if (isEmpty(newValue)) {
                setShowParseBtn(false);
            } else {
                setShowParseBtn(true);
            }
        }
    }

    const reset = () => {
        setSql("");
        form.resetFields();
        setShowParseBtn(false);
        setErrors([]);
        setValidate(false);
        closeModal();
    }

    const staticWordCompleter = {
        getCompletions: function(editor, session, pos, prefix, callback) {

            callback(null, tables.map(function({caption, value, meta}) {
                return {caption: t(caption), value, meta};
            }));

            callback(null, columns.map(function({caption, value, meta}) {
                return {caption: t(caption), value, meta};
            }));

            //선택한 SAE ItemGroup의 Item 정보 리스트 조회
            const itemGroup = metaData.itemGroup.get(itemGroupOID);
            const itemInfos = [...itemGroup.itemRef].map(({itemOID}) => {
                const {name} = metaData.item.get(itemOID);

                return {
                    caption: `${itemOID} - ${name}`,
                    value: `${itemOID}`,
                    meta: `SAE Item Column`,
                }
            });

            callback(null, itemInfos.map(function({caption, value, meta}) {
                return {caption, value, meta};
            }));
        }
    }

    langTools.setCompleters([staticWordCompleter]);

    return (

        <Modal
            title="SAE-MAP Editor"
            centered
            maskClosable={false}
            keyboard={false}
            open={showModal}
            okText={'Save'}
            destroyOnClose={true}
            onCancel={() => reset()}
            width={1000}
            footer={[
                (!isProd && !editMode) && <Button type={'link'} onClick={() => setEditMode(true)}><i className={'fa fa-pencil'}></i>&nbsp;&nbsp;Edit</Button>,
                (!isProd && editMode) && <Button type={"primary"} disabled={!editMode || showParseBtn} onClick={() => handleFormSubmit()}><i className={'fa fa-check'}></i>&nbsp;&nbsp;Save</Button>,
                <Button type={'link'} onClick={() => reset()}><i className={'fa fa-times'}></i>&nbsp;&nbsp;Close</Button>,
            ]}
            afterClose={() => {setValidate(false);}}
        >
            <Spin spinning={loading} tip={'Loading...'} size={'large'}>
                <Form layout={"vertical"} form={form} name="control-hooks" onFinish={onFinish} size={'middle'}>

                    <Spin indicator={antIcon} spinning={validate} tip={'Parsing expression...'} size={'large'}>
                        <div>{itemGroupName + "/" + itemGroupOID}</div>
                        <hr/>
                        <Form.Item className={'mb-0'} name={"sql"} label="SQL QUERY"
                                   rules={[
                                       { validator: (_, value) => {
                                           if(value == null) {
                                               return Promise.reject(new Error(t('message.pls.input.data.query.select')));
                                           } else if(errors.length <= 0) { //Validator로 error가 잡힌게 없는 경우 Validate 처리
                                               return Promise.resolve();
                                           } else {
                                               return Promise.reject(new Error(t('message.error.occurred.during.sql.parsing')));
                                           }
                                       }}
                                   ]}>
                            <AceEditor
                                readOnly={!editMode}
                                focus={false}
                                width={'100%'}
                                mode={"sqlserver"}
                                theme={"xcode"}
                                minLines={10}
                                maxLines={Infinity}
                                onChange={onChange}
                                showPrintMargin={false}
                                showGutter={true}
                                wrapEnabled={editMode}
                                highlightActiveLine={editMode}
                                fontSize={15}
                                style={{fontFamily:'consolas,monaco'}}
                                defaultValue={sql}
                                // borderRadius:3
                                name="SAE_MAP_EDITOR"
                                setOptions={{
                                    enableBasicAutocompletion: false,
                                    enableLiveAutocompletion: editMode,
                                    // enableSnippets: true,
                                    showLineNumbers: true,
                                    useWorker:false,
                                    tabSize: 4,
                                    dragEnabled: false,
                                    placeholder:`/**\n* ${t('caption.placeholder.pls.input.data.query.select')}\n*/`,
                                    // autoScrollEditorIntoView: true,
                                }}
                            />
                            <div>
                                {errors.map((message) => {
                                    return (
                                        <div className={'text-danger'}>{message}</div>
                                    )
                                })}
                            </div>

                        </Form.Item>
                    </Spin>

                    <Form.Item className={'mb-0'} name="itemGroupOID" label="ItemGroupOID" hidden={true}>
                        <Input readOnly={true}/>
                    </Form.Item>
                    <hr/>
                    <Form.Item valuePropName={"checked"} className={'mb-0'}
                               name={"editable"} label="Editable" rules={[{ required: false}]}
                               initialValue={true} >
                        <Switch disabled={!editMode} />
                    </Form.Item>

                    {/*{saeReportFormDef.dataSourceDefs.length > 0 &&*/}
                    {form.getFieldValue("dataSourceDefs")?.length > 0 &&
                        <>
                            <hr/>
                        <Form.List name={"dataSourceDefs"}>
                            {(fields) => {
                                return (
                                    <>
                                        {fields.map((field, index) => (
                                        <Row key={field.key + index} gutter={[16, 16]}>
                                            <Col span={6}>
                                                <Form.Item  name={[index, "tableName"]} label={'DataSource'}>
                                                    <Input readOnly={true} size={"small"}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={18}>
                                                <Form.List name={[index, "columnDefs"]}>
                                                    {fields => (
                                                        <>
                                                        {fields.map((field, itemIndex) => {
                                                            return (
                                                            <Row key={field.key + itemIndex} gutter={[16, 16]}>
                                                                <Col span={8}>
                                                                    <Form.Item  name={[itemIndex, "type"]} label={'Type'} hidden={true}>
                                                                        <Input readOnly={true} size={"small"}/>
                                                                    </Form.Item>
                                                                    <Form.Item  name={[itemIndex, "crfItemOID"]} label={form.getFieldValue(["dataSourceDefs", index, "columnDefs", itemIndex, "type"]) === 'CRF_ITEM' ? 'CRF Item' : 'Constant'}>
                                                                        <Input readOnly={true} size={"small"}/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <Form.Item  name={[itemIndex, "saeItemOID"]} label={'SAE Item'}>
                                                                        <Input readOnly={true} size={"small"}/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <Form.Item valuePropName={"checked"} className={'mb-0'}
                                                                               name={[itemIndex, "editable"]} label="Editable" rules={[{ required: false}]}
                                                                               initialValue={true} >
                                                                        <Switch disabled={!editMode} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            )
                                                        })}
                                                        </>
                                                    )}
                                                </Form.List>

                                            </Col>
                                        </Row>
                                        ))}
                                    </>
                                )
                            }}
                        </Form.List>
                        </>
                    }



                    <div style={{textAlign:'right',marginTop:'5px'}}>
                        <Button type={"primary"} danger={true} onClick={parse} hidden={!showParseBtn} size={"small"}>Parsing</Button>
                    </div>
                </Form>
            </Spin>
        </Modal>

    )
}

export default SAEReportItemGroupDef;
