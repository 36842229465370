import React from "react";
import {Droppable} from "react-beautiful-dnd";
import ScheduleDefDetail from "./ScheduleDefDetail";
import {ProtocolContent, ProtocolContentWrapper} from "../../../container/build-tool/StyleBox";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../lib/ODMUtils";

const ScheduleHeader = ({children, selectedOID, defType, addNew, protocol, refType, metaData, toggleMandatory, onDeleteRef, onChangeCondition}) => {

    const metaDataStore = useSelector((store) => store.metaDataStore);
    const isProd = isEnvProduction(metaDataStore);

    return (
        <div className={'card-header card-header-flex mt-3'}>
            <div className="d-flex flex-column justify-content-center">
                {children}
            </div>
            {
                //Protocol에서만 사용.
                addNew === undefined &&
                <ProtocolContentWrapper>
                    <PerfectScrollbar>
                    <Droppable key={defType}
                               droppableId={defType}
                               type={refType} direction={'horizontal'}>
                        {
                            (provided, snapshot) => (
                                <ProtocolContent
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                    ref={provided.innerRef}
                                >
                                    <ScheduleDefDetail defType={defType} metaData={metaData} def={protocol} isProd={isProd}
                                                       toggleMandatory={toggleMandatory} onDeleteRef={onDeleteRef} onChangeCondition={onChangeCondition} />
                                    {provided.placeholder}
                                </ProtocolContent>
                            )
                        }
                    </Droppable>
                    </PerfectScrollbar>
                </ProtocolContentWrapper>
            }
            {
                (!isProd && addNew !== undefined) &&
                <div className="ml-auto d-flex flex-column justify-content-center">
                    <div>
                        <button disabled={selectedOID !== ""} className={'btn btn-primary btn-squared'} onClick={() => addNew(defType)}>
                            <i className={'fa fa-plus'}></i> New
                        </button>
                    </div>
                </div>
            }
        </div>
    )

}

export default ScheduleHeader;