import React from "react";

const ModalIcons = ({type}) => {
    return (
        <>
            { type === 'formDef'             && <img src={"/static/icon/modal/ic-form.svg"} />                  }
            { type === 'itemGroupDef'        && <img src={"/static/icon/modal/ic-itemgroup.svg"} />             }
            { type === 'itemDef'             && <img src={"/static/icon/modal/ic-item.svg"} />                  }
            { type === 'codeListItem'        && <img src={"/static/icon/modal/ic-codelist-properties.svg"} />   }
            { type === 'externalCodeList'    && <img src={"/static/icon/modal/ic-codelist-external.svg"} />     }
            { type === 'valueListDef'        && <img src={"/static/icon/modal/ic-value.svg"} />                 }
            { type === 'studyEventDef'       && <img src={"/static/icon/modal/ic-event.svg"} />                 }
            { type === 'studyEventGroupDef'  && <img src={"/static/icon/modal/ic-eventgroup.svg"} />            }
        </>
    )
}

export default ModalIcons;