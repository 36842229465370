import ODMTabs from "../../../layouts/Tab/odm";
import {Helmet} from "react-helmet-async";
import React from "react";
import SAEConfigContainer from "../../../container/odm/SAEConfigContainer";
import {useTranslation} from "react-i18next";

const SAEConfigPage = () => {
    const {t} = useTranslation();

    return (
        <ODMTabs menu={'sae'}>
            <Helmet title={t('saemap.odm.designer.v2.sae.config')}/>
            <SAEConfigContainer />
        </ODMTabs>
    )
}
export default SAEConfigPage;
