import React, {useEffect, useState} from "react";
import {Button, Card, Collapse, Form, Select, Space, Tag} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {isEnvProduction, toMetaData} from "../../lib/ODMUtils";
import "ace-builds/src-noconflict/mode-sqlserver";
import "ace-builds/src-noconflict/theme-sqlserver";
import "ace-builds/src-noconflict/snippets/sqlserver";
import "ace-builds/src-min-noconflict/ext-searchbox";
import {metadataActions} from "../../redux/module/metadata";
import {Notice} from "../build-tool/StyleBox";
import {useParams} from "react-router-dom";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {vs} from 'react-syntax-highlighter/dist/esm/styles/prism';
import SAEReportItemGroupDef from "../../components/odm/SAEReportItemGroupDef";
import {mdiArrowRight, mdiCheckboxBlankOutline, mdiCheckboxMarkedOutline, mdiSquareEditOutline} from '@mdi/js';
import Icon from "@mdi/react";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

const SAEDataMapContainer = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);
    const metaData = toMetaData(metaDataStore.study);
    const {t} = useTranslation();

    const saeForms = [...metaData.form.values()].filter(t => t.formType === 'SAE_REPORT_FORM');
    const [treeDataMap, setTreeDataMap] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [formOID, setFormOID] = useState(null);
    const [itemGroupOID, setItemGroupOID] = useState(null);
    const [saeItemGroupDef, setSaeItemGroupDef] = useState(null);
    const [itemGroupName, setItemGroupName] = useState("");

    const initialValue = {
        itemGroupOID:itemGroupOID,
        dataSourceDefs:[]
    };

    const closeModal = () => {
        setSaeItemGroupDef(null);
        setShowModal(false);
    }
    const onSaveFinish = (saeReportFormDef) => {
        const saeReportItemGroupDef = {
            formOID,
            itemGroupDef:saeReportFormDef
        };


        saeReportItemGroupDef.params = params;
        dispatch(metadataActions.updateSAEFormMap(saeReportItemGroupDef));
    }

    useEffect(() => {
        if(saeForms != null && saeForms.length > 0) {
            const _treeDataMap = new Map();
            const _existsIgMap = new Map();
            saeForms.map((form, index) => {

                let groups = [];

                metaData.saeReportFormMap.get(form.oid)?.map((g, index) => {
                    _existsIgMap.set(g.itemGroupOID, "");
                });

                form.itemGroupRef.map((itemGroupRef, gIdx) => {
                    if(!_existsIgMap.has(itemGroupRef.itemGroupOID)) {
                        groups.push({
                            label: metaData.itemGroup.get(itemGroupRef.itemGroupOID).name+"/"+itemGroupRef.itemGroupOID,
                            value: `${form.oid}.${itemGroupRef.itemGroupOID}.${metaData.itemGroup.get(itemGroupRef.itemGroupOID).name}`
                        });
                    }
                });


                _treeDataMap.set(form.oid, groups);


            });

            setTreeDataMap(_treeDataMap);
        }
    }, []);


    const sources = Array.from(metaData.studyEvent.values()).map(e => {
        const studyEvent = {value: e.oid, label: e.name};
        studyEvent.children = e.formRef.filter(f => metaData.form.get(f.formOID).formType !== 'SAE_REPORT_FORM').map(f => {
            const formDef = metaData.form.get(f.formOID);
            const form = {value: formDef.oid, label: formDef.name}
            form.children = formDef.itemGroupRef.map(g => {
                const itemGroupDef = metaData.itemGroup.get(g.itemGroupOID);
                const itemGroup = {value: itemGroupDef.oid, label: itemGroupDef.name}
                itemGroup.children = itemGroupDef.itemRef.map(i => {
                    const itemDef = metaData.item.get(i.itemOID);
                    return {value: itemDef.oid, label: `${itemDef.name} [${itemDef.oid}]`, refCodeList:itemDef?.codeListRef?.codeListOID};
                });
                return itemGroup;
            });
            return form;
        });
        return studyEvent;
    });

    const [form] = Form.useForm();


    useEffect(() => {
        if(metaData?.saeReportMapDef != null) {
            // form.setFieldsValue(JSON.parse(metaData.saeReportFormMapDef.mapJson));
            // setSaeFormMap(JSON.parse(metaData.saeReportFormMapDef.mapJson));
            console.log(metaData.saeReportMapDef);
        }
    }, [])


    const onSelectItemGroup = (oid) => {
        setShowModal(true);
        const arr = oid.split(".");
        setFormOID(arr[0]);
        setItemGroupOID(arr[1]);
        setItemGroupName(arr[2]);
        setSaeItemGroupDef(initialValue);
    }

    const onEditItemGroupDef = (formOID, itemGroupOID, itemGroupName, itemGroupDef) => {
        setShowModal(true);
        setFormOID(formOID);
        setItemGroupOID(itemGroupOID);
        setItemGroupName(itemGroupName);
        setSaeItemGroupDef(itemGroupDef);
    }

    return (
        <>

            {(saeForms != null && saeForms.length > 0) &&
                <>

                    <div>
                        {saeForms.map((f, idx) => (
                            <Card key={idx} title={<h5><i className={'fa fa-file-o'}></i> {f.name}</h5>} className={'mb-3'} extra={isProd ? <></> :
                                <Select
                                    style={{
                                        width: 270,
                                    }}
                                    placeholder="Please select"
                                    onSelect={onSelectItemGroup}
                                    options={treeDataMap?.get(f.oid)}
                                />
                            }>
                                <Space direction={'vertical'} style={{width:'100%'}}>
                                    {metaData.saeReportFormMap.get(f.oid)?.map((g, index) => {
                                        const itemGroupDef = metaData.itemGroup.get(g.itemGroupOID);

                                        return (
                                            <Collapse collapsible={'header'} defaultActiveKey={`sae-${f.oid}-${g.itemGroupOID}-${index}`}>
                                                <Collapse.Panel header={`${itemGroupDef.name}/${itemGroupDef.oid} - ${g.editable ? 'Editable' : 'Uneditable'}`}
                                                                extra={isProd ? <></> : <Button type={"link"} size={"small"} onClick={() => onEditItemGroupDef(f.oid, g.itemGroupOID, itemGroupDef.name, g)}><Icon path={mdiSquareEditOutline} size={1} /></Button>}
                                                                key={`sae-${f.oid}-${g.itemGroupOID}-${index}`}>
                                                    {
                                                        // itemGroupDef.repeating === 'NO' ?
                                                        // <SAEMapNonRepeatGroup formOID={f.oid} metaData={metaData} onFieldChange={onFieldChange} isProd={isProd}
                                                        //                       itemGroupDef={itemGroupDef} sources={sources} saeFormMap={saeFormMap}/> :
                                                        // <SAEMapSimpleRepeatGroup formOID={f.oid} metaData={metaData} onFieldChange={onFieldChange} isProd={isProd}
                                                        //                          itemGroupDef={itemGroupDef} sources={sources} saeFormMap={saeFormMap}/>
                                                        <>
                                                            <table className={'table table-hover table-condensed table-striped table-sm font-size-14'}>
                                                                <thead>
                                                                <tr>
                                                                    <th className={'col-4 text-right pr-5'}>CRF / Constant</th>
                                                                    <th className={'col-4'}>SAE</th>
                                                                    <th className={'col-4'}>Editable</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {g.dataSourceDefs.map((ds) => {
                                                                    return (
                                                                        <>
                                                                            {ds.columnDefs.map((col) => {
                                                                                // const itemDef = metaData.item.get(i.itemOID);
                                                                                return (
                                                                                    <tr>
                                                                                        <td className={'text-right'} style={{fontWeight:500}}>
                                                                                            {
                                                                                                ds?.tableName === '_SYSTEM' ? (
                                                                                                    <Tag color={'geekblue'}>SYSTEM</Tag>
                                                                                                ) : (
                                                                                                    <span className={col.type === 'CRF_ITEM' ? 'text-primary' : 'text-warning'}>{ds.tableName}</span>
                                                                                                )
                                                                                            }
                                                                                            <span className={classNames('ml-2 mr-2', {
                                                                                                ['text-primary']: col.type === 'CRF_ITEM',
                                                                                                ['text-warning']: col.type !== 'CRF_ITEM',
                                                                                            })}>/</span>
                                                                                            <span className={col.type === 'CRF_ITEM' ? 'text-primary' : 'text-warning'}>{col.crfItemOID}</span>
                                                                                            <span className={'ml-3 mr-3'}><Icon path={mdiArrowRight} size={0.5} /></span>
                                                                                        </td>
                                                                                        <td style={{color:'purple',fontWeight:500}}>{col.saeItemOID}</td>
                                                                                        <td><Icon path={col.editable ? mdiCheckboxMarkedOutline : mdiCheckboxBlankOutline} size={1} /></td>
                                                                                    </tr>
                                                                                )})}
                                                                        </>
                                                                    )}
                                                                )}
                                                                </tbody>
                                                            </table>

                                                            <SyntaxHighlighter language="sql" style={vs} customStyle={{fontSize:"2.0"}} codeTagProps={{style:{fontSize:"inherit"}}}>
                                                                {g.sql}
                                                            </SyntaxHighlighter>
                                                        </>
                                                    }
                                                </Collapse.Panel>
                                            </Collapse>
                                        )
                                    })}
                                </Space>
                            </Card>
                        ))}
                    </div>
                    <SAEReportItemGroupDef metaData={metaData} formOID={formOID} saeItemGroupDef={saeItemGroupDef} itemGroupOID={itemGroupOID} itemGroupName={itemGroupName} onFinish={onSaveFinish} showModal={showModal} closeModal={closeModal} isProd={isProd}/>
                </>
            }


            {(saeForms == null || saeForms.length == 0) &&
                <Notice>
                    <h6 className={'text-center text-warning m-3'}>
                        <i className={'fa fa-exclamation-circle'}></i> {t('message.sae.report.form.is.not.configure')}
                    </h6>
                </Notice>
            }
        </>
    )
}

export default SAEDataMapContainer;