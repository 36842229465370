import {createActionTypes, createUserSaga} from "../../lib/createRequestSaga";
import {createAction, handleActions} from "redux-actions";
import userAPI from "../../lib/api/user";
import {takeLatest} from "redux-saga/effects";
import {
    LOAD_FAILED,
    LOAD_SUCCESS,
    PATH_PUSHED,
    PATH_REPLACED,
    PROFILE_FAILED,
    PROFILE_SUCCESS,
    SIGNOUT, DUPLICATED_SIGNOUT
} from "../../components/constant/responseType";

//Action Types
// export const LOGIN = 'user/LOGIN';
export const LOGOUT = 'user/LOGOUT';
export const DUPLICATE_LOGOUT = 'user/DUPLICATE_LOGOUT';

//Pathname 이동을 위한 설정
export const GO_PATH = 'user/GO_PATH';
export const REPLACE_PATH = 'user/REPLACE_PATH';

//회원가입 동작
export const [REGISTER, REGISTER_SUCCESS, REGISTER_FAILURE] = createActionTypes('user/REGISTER');
//현재 사용자 정보 불러오기 동작.
export const [LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT_SUCCESS, LOAD_CURRENT_ACCOUNT_FAILURE] = createActionTypes('user/LOAD_CURRENT_ACCOUNT');

//사용자 정보 수정 동작
export const [PROFILE_UPDATE, PROFILE_UPDATE_SUCCESS, PROFILE_UPDATE_FAILURE] = createActionTypes('user/PROFILE_UPDATE');


export const userActions = {
    // login: createAction(LOGIN),
    // register: createAction(REGISTER),
    loadCurrentAccount: createAction(LOAD_CURRENT_ACCOUNT),
    duplicateLogout: createAction(DUPLICATE_LOGOUT),
    logout: createAction(LOGOUT),
    profileUpdate: createAction(PROFILE_UPDATE),
}

// const loginSaga = createUserSaga(LOGIN, userAPI.login);
const loadCurrentAccountSaga = createUserSaga(LOAD_CURRENT_ACCOUNT, userAPI.loadCurrentAccount);
// const logoutSaga = createUserSaga(LOGOUT, userAPI.logout);
const profileUpdate = createUserSaga(PROFILE_UPDATE, userAPI.profileUpdate);


export function* userSaga() {
    // yield takeLatest(LOGIN, loginSaga);
    // yield takeLatest(LOGOUT, logoutSaga);
    yield takeLatest(LOAD_CURRENT_ACCOUNT, loadCurrentAccountSaga);
    yield takeLatest(PROFILE_UPDATE, profileUpdate);
}

const initialState = {
    user: null,
    register: null,
    responseStatus: '',
    authorized: false,
    error: false,
    loading: false,
    loaded: false,
    pathname: null,
    errorMessage: '',
    builderAccessAuthorities:['BUILDER_STUDY_BUILD_PRIVILEGE', 'BUILDER_MANAGER_PRIVILEGE'], //빌더 접속 가능한 기본 권한.
    managerAuthority:['BUILDER_MANAGER_PRIVILEGE'], //빌더 매니저 권한
    // iwrsAccessAuthorities:['IWRS_ACCESS_PRIVILEGE']
}

export default handleActions({
        [LOGOUT]: (state, a = {}) => ({
            ...state,
            user: null,
            loaded: false,
            authorized: false,
            responseStatus: SIGNOUT,
        }),

        [DUPLICATE_LOGOUT] : (state, {}) => ({
            ...state,
            user: null,
            loaded: false,
            authorized: false,
            responseStatus: DUPLICATED_SIGNOUT
        }),

        [GO_PATH]: (state, { payload: pathname }) => ({
            ...state,
            pathname,
            responseStatus: PATH_PUSHED
        }),

        [REPLACE_PATH]: (state, { payload: pathname }) => ({
            ...state,
            pathname,
            responseStatus: PATH_REPLACED
        }),

        //현재 Current Account 불러오기.
        [LOAD_CURRENT_ACCOUNT]: (state, {}) => ({
            ...state,
            loading: true,
            loaded: false,
            error: false,
            responseStatus: ''
        }),

        [LOAD_CURRENT_ACCOUNT_SUCCESS]: (state, {payload: user}) => ({
            ...state,
            user,
            loading: false,
            loaded: true,
            authorized: true,
            error: false,
            responseStatus: LOAD_SUCCESS
        }),
        [LOAD_CURRENT_ACCOUNT_FAILURE]: (state, {payload: error}) => ({
            ...state,
            loading: false,
            loaded: true,
            authorized: false,
            error: true,
            errorMessage: error,
            responseStatus: LOAD_FAILED
        }),

        //Profile 수정
        [PROFILE_UPDATE]: (state) => ({
            ...state,
            loading: true,
            error: false,
            responseStatus: ''
        }),
        [PROFILE_UPDATE_SUCCESS]: (state, {payload: user}) => ({
            ...state,
            user,
            loading: false,
            error: false,
            responseStatus: PROFILE_SUCCESS

        }),
        [PROFILE_UPDATE_FAILURE]: (state, {payload: error}) => ({
            ...state,
            error: true,
            errorMessage: error,
            responseStatus: PROFILE_FAILED
        }),

    },
    initialState
);