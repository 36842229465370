import React, {useState} from 'react'
import {Dropdown} from 'antd'
import styles from './style.module.scss'
import i18n from '../../../../config/lang/i18n';
import {isEmpty} from "../../../../lib/StringUtils";
import {withTranslation} from "react-i18next";
import cookie from 'react-cookies';

const LANGUAGE_COOKIE_NAME = 'org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE'

const LanguageSwitcher = ({t}) => {

    const lang = isEmpty(cookie.load(LANGUAGE_COOKIE_NAME)) ? 'ko' : cookie.load(LANGUAGE_COOKIE_NAME);
    const [language, setLanguage] = useState(lang);

    const changeLanguage = ({key}) => {
        setLanguage(key);
        i18n.changeLanguage(key);
        cookie.save(LANGUAGE_COOKIE_NAME, key, {
            path:'/'
        });
    }

    const menu = {
        items: [
            {
                key:'en',
                icon:'EN',
                label: <span className={'ml-2'}>{t('lang.english')}</span>
            },
            {
                key:'ko',
                icon:'KO',
                label: <span className={'ml-2'}>{t('lang.korean')}</span>
            }
        ],
        onClick: (data) => {changeLanguage(data)}
    }

    return (
        <Dropdown menu={menu} trigger={['click']} placement="bottomRight">
            <div className={styles.dropdown}>
                <div className={styles.flag}>
                    {language?.toUpperCase()}
                </div>
            </div>
        </Dropdown>
    )
}

export default withTranslation()(LanguageSwitcher);
