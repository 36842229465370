import {createActionTypes, createMetadataSaga} from "../../lib/createRequestSaga";
import { createAction, handleActions } from 'redux-actions';
import {META_LOADING_SUCCESS, META_LOADING_FAILED, SAVE_FAILED, SAVE_SUCCESS, DELETE_FAILED, SYNC_SUCCESS, SYNC_FAILED} from "../../components/constant/responseType";
import {takeLatest} from "redux-saga/effects";
import api from "../../lib/api/metadata";


//Action Types
export const [FETCH_METADATA, FETCH_METADATA_SUCCESS, FETCH_METADATA_FAILURE] = createActionTypes('builder/metadata/FETCH_METADATA');
export const [UPDATE_PROTOCOL, UPDATE_PROTOCOL_SUCCESS, UPDATE_PROTOCOL_FAILURE] = createActionTypes('metadata/protocol/UPDATE');
export const [UPDATE_STUDY_TIMING, UPDATE_STUDY_TIMING_SUCCESS, UPDATE_STUDY_TIMING_FAILURE] = createActionTypes('metadata/studyTiming/UPDATE');
export const [UPDATE_SUBJECT_FIELD, UPDATE_SUBJECT_FIELD_SUCCESS, UPDATE_SUBJECT_FIELD_FAILURE] = createActionTypes('metadata/subjectField/UPDATE');
export const [UPDATE_ADVERSE_EVENT, UPDATE_ADVERSE_EVENT_SUCCESS, UPDATE_ADVERSE_EVENT_FAILURE] = createActionTypes('metadata/adverseEvent/UPDATE');
export const [UPDATE_SAE_FORM_MAP, UPDATE_SAE_FORM_MAP_SUCCESS, UPDATE_SAE_FORM_MAP_FAILURE] = createActionTypes('metadata/saeFormMap/UPDATE');
export const [UPDATE_METADATA, UPDATE_METADATA_SUCCESS, UPDATE_METADATA_FAILURE] = createActionTypes('builder/metadata/UPDATE');
export const [UPDATE_STUDY_STRUCTURE, UPDATE_STUDY_STRUCTURE_SUCCESS, UPDATE_STUDY_STRUCTURE_FAILURE] = createActionTypes('metadata/studyStructure/UPDATE');
export const [UPDATE_PRO_SCHEDULE, UPDATE_PRO_SCHEDULE_SUCCESS, UPDATE_PRO_SCHEDULE_FAILURE] = createActionTypes('metadata/proSchedule/UPDATE');
export const [UPDATE_INDEPENDENT_EVALUATION, UPDATE_INDEPENDENT_EVALUATION_SUCCESS, UPDATE_INDEPENDENT_EVALUATION_FAILURE] = createActionTypes('metadata/independentEvaluation/UPDATE');

export const [CREATE, CREATE_SUCCESS, CREATE_FAILURE] = createActionTypes('CREATE');
export const [UPDATE, UPDATE_SUCCESS, UPDATE_FAILURE] = createActionTypes('UPDATE');
export const [DELETE, DELETE_SUCCESS, DELETE_FAILURE] = createActionTypes('DELETE');
export const [CREATES, CREATES_SUCCESS, CREATES_FAILURE] = createActionTypes('CREATES')
export const [UPDATES, UPDATES_SUCCESS, UPDATES_FAILURE] = createActionTypes('UPDATES');

export const [SYNC_SUBJECT_STATUS, SYNC_SUBJECT_STATUS_SUCCESS, SYNC_SUBJECT_STATUS_FAILURE] = createActionTypes('builder/metadata/syncSubjectStatus');
export const [SYNC_PRO_SCHEDULE, SYNC_PRO_SCHEDULE_SUCCESS, SYNC_PRO_SCHEDULE_FAILURE] = createActionTypes('builder/metadata/syncProSchedule');

//Action Creators
export const metadataActions = {
    //Foundation
    fetchMetaData: createAction(FETCH_METADATA),
    updateMetaData: createAction(UPDATE_METADATA),
    updateProtocol: createAction(UPDATE_PROTOCOL),
    updateAdverseEvent: createAction(UPDATE_ADVERSE_EVENT),
    updateSubjectField: createAction(UPDATE_SUBJECT_FIELD),
    updateSAEFormMap: createAction(UPDATE_SAE_FORM_MAP),
    syncSubjectStatus: createAction(SYNC_SUBJECT_STATUS),
    updateStudyTiming: createAction(UPDATE_STUDY_TIMING),
    updateStudyStructure: createAction(UPDATE_STUDY_STRUCTURE),
    updateProSchedule: createAction(UPDATE_PRO_SCHEDULE),
    syncProSchedule: createAction(SYNC_PRO_SCHEDULE),
    updateIndependentEvaluation: createAction(UPDATE_INDEPENDENT_EVALUATION),

    //CodeList
    create: createAction(CREATE),
    update: createAction(UPDATE),
    delete: createAction(DELETE),
    creates: createAction(CREATES),
    updates: createAction(UPDATES)
}

//actions Saga
const fetchMetadataSaga = createMetadataSaga(FETCH_METADATA, api.getMetaData);
const updateProtocolSaga = createMetadataSaga(UPDATE_PROTOCOL, api.updateProtocol);
const updateAdverseEventSaga = createMetadataSaga(UPDATE_ADVERSE_EVENT, api.updateAdverseEvent);
const updateSubjectFieldSaga = createMetadataSaga(UPDATE_SUBJECT_FIELD, api.updateSubjectField);
const updateSAEFormMapSaga = createMetadataSaga(UPDATE_SAE_FORM_MAP, api.updateSAEFormMap);
const updateMetaDataSaga = createMetadataSaga(UPDATE_METADATA, api.updateMetaData);
const syncSubjectStatusSaga = createMetadataSaga(SYNC_SUBJECT_STATUS, api.syncSubjectStatus);
const updateStudyTimingSaga = createMetadataSaga(UPDATE_STUDY_TIMING, api.updateStudyTiming);
const updateStudyStructure = createMetadataSaga(UPDATE_STUDY_STRUCTURE, api.updateStudyStructure);
const updateProSchedule = createMetadataSaga(UPDATE_PRO_SCHEDULE, api.updateProSchedule);
const syncProSchedule = createMetadataSaga(SYNC_PRO_SCHEDULE, api.syncProSchedule);
const updateIndependentEvaluation = createMetadataSaga(UPDATE_INDEPENDENT_EVALUATION, api.updateIndependentEvaluation);

const createSaga = createMetadataSaga(CREATE, api.save);
const updateSaga = createMetadataSaga(UPDATE, api.save);
const deleteSaga = createMetadataSaga(DELETE, api.save);
const createsSaga = createMetadataSaga(CREATES, api.creates);
const updatesSaga = createMetadataSaga(UPDATES, api.updates);


//Metadata Saga
export function* metadataSaga() {
    console.log('*metaDataSaga');

    //Metadata Fetch & Protocol Update
    yield takeLatest(FETCH_METADATA, fetchMetadataSaga);
    yield takeLatest(UPDATE_PROTOCOL, updateProtocolSaga);
    yield takeLatest(UPDATE_ADVERSE_EVENT, updateAdverseEventSaga);
    yield takeLatest(UPDATE_SUBJECT_FIELD, updateSubjectFieldSaga);
    yield takeLatest(UPDATE_SAE_FORM_MAP, updateSAEFormMapSaga);
    yield takeLatest(UPDATE_METADATA, updateMetaDataSaga);
    yield takeLatest(SYNC_SUBJECT_STATUS, syncSubjectStatusSaga);
    yield takeLatest(UPDATE_STUDY_TIMING, updateStudyTimingSaga);
    yield takeLatest(UPDATE_STUDY_STRUCTURE, updateStudyStructure);
    yield takeLatest(UPDATE_PRO_SCHEDULE, updateProSchedule);
    yield takeLatest(SYNC_PRO_SCHEDULE, syncProSchedule);
    yield takeLatest(UPDATE_INDEPENDENT_EVALUATION, updateIndependentEvaluation);

    //CodeList
    yield takeLatest(CREATE, createSaga);
    yield takeLatest(UPDATE, updateSaga);
    yield takeLatest(DELETE, deleteSaga);
    yield takeLatest(CREATES, createsSaga);
    yield takeLatest(UPDATES, updatesSaga);
}

//initial state
const initialState = {
    error:false,
    loaded:false,
    loading:false,
    responseStatus:'',
    dataType:[],
    clDataType:[],
    units:[],
};


//Reducer
export default handleActions({

    //FETCH METADATA ========================================================================
    [FETCH_METADATA]: (state) => ({
        ...state,
        error:false,
        responseStatus:'',
        loading:true,
        loaded:false,
    }),
    [FETCH_METADATA_SUCCESS]: (state, {payload:allData}) => ({
        ...state,
        ...allData,
        error:false,
        responseStatus:META_LOADING_SUCCESS,
        loading:false,
        loaded:true,
    }),
    [FETCH_METADATA_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        loaded:false,
        loading:true,
        responseStatus:META_LOADING_FAILED,
    }),
    //FETCH METADATA ========================================================================

    //PROTOCOL ==============================================================================
    [UPDATE_PROTOCOL]: (state) => ({
        ...state,
        error:false,
        loading:true,
        responseStatus:'',
    }),
    [UPDATE_PROTOCOL_SUCCESS]: (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SAVE_SUCCESS,
        loading:false
    }),
    [UPDATE_PROTOCOL_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        // loaded:true,
        message:error.message,
        responseStatus:SAVE_FAILED,
        loading:false
    }),
    //PROTOCOL ==============================================================================
    //STUDY_TIMING ==============================================================================
    [UPDATE_STUDY_TIMING]: (state) => ({
        ...state,
        error:false,
        loading:true,
        responseStatus:'',
    }),
    [UPDATE_STUDY_TIMING_SUCCESS]: (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SAVE_SUCCESS,
        loading:false
    }),
    [UPDATE_STUDY_TIMING_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        // loaded:true,
        message:error.message,
        responseStatus:SAVE_FAILED,
        loading:false
    }),
    //STUDY_TIMING ==============================================================================

    //ADVERSE_EVENT ==============================================================================
    [UPDATE_ADVERSE_EVENT]: (state) => ({
        ...state,
        error:false,
        loading:true,
        responseStatus:'',
    }),
    [UPDATE_ADVERSE_EVENT_SUCCESS]: (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SAVE_SUCCESS,
        loading:false
    }),
    [UPDATE_ADVERSE_EVENT_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        // loaded:true,
        message:error.message,
        responseStatus:SAVE_FAILED,
        loading:false
    }),
    //ADVERSE_EVENT ==============================================================================
    //SUBJECT_FIELD_MAP ==============================================================================
    [UPDATE_SUBJECT_FIELD]: (state) => ({
        ...state,
        error:false,
        loading:true,
        responseStatus:'',
    }),
    [UPDATE_SUBJECT_FIELD_SUCCESS]: (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SAVE_SUCCESS,
        loading:false
    }),
    [UPDATE_SUBJECT_FIELD_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        // loaded:true,
        message:error.message,
        responseStatus:SAVE_FAILED,
        loading:false
    }),
    //SUBJECT_FIELD_MAP ==============================================================================
    //SAE_FORM_MAP ==============================================================================
    [UPDATE_SAE_FORM_MAP]: (state) => ({
        ...state,
        error:false,
        loading:true,
        responseStatus:'',
    }),
    [UPDATE_SAE_FORM_MAP_SUCCESS]: (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SAVE_SUCCESS,
        loading:false
    }),
    [UPDATE_SAE_FORM_MAP_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        // loaded:true,
        message:error.message,
        responseStatus:SAVE_FAILED,
        loading:false
    }),
    //SAE_FORM_MAP ==============================================================================
    //STUDY_STRUCTURE ==============================================================================
    [UPDATE_STUDY_STRUCTURE]  : (state) => ({
        ...state,
        error: false,
        loading : true,
        responseStatus: '',
    }),
     [UPDATE_STUDY_STRUCTURE_SUCCESS]  : (state, {payload:study}) => ({
         ...state,
         study,
         responseStatus:SAVE_SUCCESS,
         loading:false
    }),
     [UPDATE_STUDY_STRUCTURE_FAILURE]  : (state, {payload:error}) => ({
         ...state,
         error:true,
         message:error.message,
         responseStatus:SAVE_FAILED,
         loading:false
    }),
    //STUDY_STRUCTURE ==============================================================================
    //PRO_SCHEDULE ==============================================================================
    [UPDATE_PRO_SCHEDULE]  : (state) => ({
        ...state,
        error: false,
        loading : true,
        responseStatus: '',
    }),
     [UPDATE_PRO_SCHEDULE_SUCCESS]  : (state, {payload:study}) => ({
         ...state,
         study,
         responseStatus:SAVE_SUCCESS,
         loading:false
    }),
     [UPDATE_PRO_SCHEDULE_FAILURE]  : (state, {payload:error}) => ({
         ...state,
         error:true,
         message:error.message,
         responseStatus:SAVE_FAILED,
         loading:false
    }),
    //PRO_SCHEDULE ==============================================================================
    //PRO_SCHEDULE ==============================================================================
    [SYNC_PRO_SCHEDULE]  : (state) => ({
        ...state,
        error: false,
        loading : true,
        responseStatus: '',
    }),
    [SYNC_PRO_SCHEDULE_SUCCESS]  : (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SYNC_SUCCESS,
        loading:false
    }),
    [SYNC_PRO_SCHEDULE_FAILURE]  : (state, {payload:error}) => ({
        ...state,
        error:true,
        message:error.message,
        responseStatus:SYNC_FAILED,
        loading:false
    }),
    //PRO_SCHEDULE ==============================================================================
    //INDEPENDENT_EVALUATION ==============================================================================
    [UPDATE_INDEPENDENT_EVALUATION]  : (state) => ({
        ...state,
        error: false,
        loading : true,
        responseStatus: '',
    }),
    [UPDATE_INDEPENDENT_EVALUATION_SUCCESS]  : (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SAVE_SUCCESS,
        loading:false
    }),
    [UPDATE_INDEPENDENT_EVALUATION_FAILURE]  : (state, {payload:error}) => ({
        ...state,
        error:true,
        message:error.message,
        responseStatus:SAVE_FAILED,
        loading:false
    }),
    //INDEPENDENT_EVALUATION ==============================================================================

    //SYNC_SUBJECT_STATUS =======================================================================
    [SYNC_SUBJECT_STATUS]: (state) => ({
        ...state,
        error: false,
        loading: true,
        responseStatus:'',
    }),
    [SYNC_SUBJECT_STATUS_SUCCESS]: (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SYNC_SUCCESS,
        loading:false,
    }),
    [SYNC_SUBJECT_STATUS_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        // loaded:true,
        message:error.message,
        responseStatus:SYNC_FAILED,
        loading:false
    }),

    //SYNC_SUBJECT_STATUS =======================================================================

    //METADATA ==============================================================================
    [UPDATE_METADATA]: (state) => ({
        ...state,
        error:false,
        loading:true,
        responseStatus:'',
    }),
    [UPDATE_METADATA_SUCCESS]: (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SAVE_SUCCESS,
        loading:false
    }),
    [UPDATE_METADATA_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        // loaded:true,
        message:error.message,
        responseStatus:SAVE_FAILED,
        loading:false
    }),
    //METADATA ==============================================================================

    //METADATA VERSION DEF ==================================================================
    //CREATES
    //UPDATES
    [CREATES]: (state) => ({
        ...state,
        error:false,
        loading:true,
        responseStatus:'',
    }),
    [CREATES_SUCCESS]: (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SAVE_SUCCESS,
        loading:false
    }),
    [CREATES_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        // loaded:true,
        message:error.message,
        responseStatus:SAVE_FAILED,
        loading:false
    }),

    //CREATE
    [CREATE]: (state) => ({
        ...state,
        error:false,
        loading:true,
        responseStatus:'',
    }),
    [CREATE_SUCCESS]: (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SAVE_SUCCESS,
        loading:false
    }),
    [CREATE_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        // loaded:true,
        message:error.message,
        responseStatus:SAVE_FAILED,
        loading:false
    }),

    //UPDATE
    [UPDATE]: (state) => ({
        ...state,
        error:false,
        loading:true,
        responseStatus:'',
    }),
    [UPDATE_SUCCESS]: (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SAVE_SUCCESS,
        loading:false
    }),
    [UPDATE_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        // loaded:true,
        message:error.message,
        responseStatus:SAVE_FAILED,
        loading:false
    }),

    //UPDATES
    [UPDATES]: (state) => ({
        ...state,
        error:false,
        loading:true,
        responseStatus:'',
    }),
    [UPDATES_SUCCESS]: (state, {payload:study}) => ({
        ...state,
        study,
        responseStatus:SAVE_SUCCESS,
        loading:false
    }),
    [UPDATES_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        // loaded:true,
        message:error.message,
        responseStatus:SAVE_FAILED,
        loading:false
    }),

    //DELETE
    [DELETE]: (state) => ({
        ...state,
        error:false,
        loading:true,
        responseStatus:'',
    }),
    [DELETE_SUCCESS]: (state, {payload:study}) => ({
        ...state,
        study:study,
        responseStatus:DELETE_SUCCESS,
        loading:false
    }),
    [DELETE_FAILURE]: (state, {payload:error}) => ({
        ...state,
        error:true,
        // loaded:true,
        message:error.message,
        responseStatus:DELETE_FAILED,
        loading:false
    }),
    //METADATA VERSION DEF ==================================================================

}, initialState);