import React, {useEffect, useState} from "react";
import DescriptionOrQuestion from "../../../../components/common/odm/ant-form/DescriptionOrQuestion";
import FormInputOID from "../../../../components/common/odm/ant-form/FormInputOID";
import FormInputName from "../../../../components/common/odm/ant-form/FormInputName";
import {Card, Col, Form, Input, Modal, Row, Select} from "antd";
import FormSelectDataType from "../../../../components/common/odm/ant-form/FormSelectDataType";
import ModalFooter from "../ModalFooter";
import {useSelector} from "react-redux";
import './modalStyle.scss';
import ModalTitle from "../component/ModalTitle";
import FormSelectYesOrNo from "../../../../components/common/odm/ant-form/FormSelectYesOrNo";
import {toMetaData} from "../../../../lib/ODMUtils";
import {isEmpty} from "../../../../lib/StringUtils";
import {Option} from "antd/es/mentions";

const FormDef = ({selectedOID, languages, formDef, setFormDef, visible, yesOrNo, formType, reportDesignType, onFinish, onCancel}) => {
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const [form] = Form.useForm();
    const [type, setType] = useState();
    const [disableFormTypes, setDisableFormTypes] = useState([]);
    const [formDefOptions, setFormDefOptions] = useState([]);
    const [custom, setCustom] = useState(false);

    useEffect(() => {
        setFormDef(formDef);
        setType(formDef.formType);
        form.setFieldsValue({
            ...formDef,
            reportDesignType: formDef?.reportDesignType??'DEFAULT'
        });

        //disableType 설정
        let _disableTypes = [];
        if(!metaDataStore.studyInfo?.useSAEReport??false) {
            _disableTypes.push('SAE_REPORT_FORM');

        }
        if(!metaDataStore.studyInfo?.useEPRO??false) {
            _disableTypes.push('CRF_EPRO');
            _disableTypes.push('EPRO');
        }

        if(!metaDataStore.studyInfo?.useIndependentEvaluation??false) {
            _disableTypes.push('INDEPENDENT_EVALUATION_FORM');
            _disableTypes.push('INDEPENDENT_EVALUATION_UPLOAD_FORM');
        }

        setDisableFormTypes(_disableTypes);

        //Form Sibling Option 설정
        const metaData = toMetaData(metaDataStore.study);
        const _formDefs = Array.from(metaData.form.values());
        const options = (selectedOID !== ""?_formDefs.filter(f => f.oid !== selectedOID):_formDefs).map(f => ({
            value: f.oid,
            label: `[${f.oid}] - ${f.name}`
        }));
        setFormDefOptions([{value: null, label:'...'}, ...options]);

    }, [formDef]);

    //visible이 false처리되어 닫힐 때, form 항목 리셋
    useEffect(() => {
        if(!visible) { form.resetFields(); }
    }, [visible])

    const onFinishFailed = (errors) => {
        console.log(errors);
    }
    const onChangeFormType = (_type) => {
        setType(_type);

        if(_type === 'EPRO' || _type === 'CRF_EPRO' || _type === 'INDEPENDENT_EVALUATION_FORM') {
            form.setFieldValue('repeating', 'YES');
        }
        else if(_type === 'INDEPENDENT_EVALUATION_UPLOAD_FORM') {
            form.setFieldValue('repeating', 'NO');
        }
    }

    const onChangeDesignType = (_type) => {
        setCustom(_type === 'CUSTOM');
    }

    const getFormRepeatingDisabledOption = (_type) => {
        switch (_type) {
            case 'EPRO':
            case 'CRF_EPRO':
            case 'INDEPENDENT_EVALUATION_FORM':
                return 'NO';
            case 'INDEPENDENT_EVALUATION_UPLOAD_FORM':
                return 'YES';
            default:
                return '';
        }
    }

    return (
        <Modal
            forceRender={true}
            wrapClassName={'odm-modal'}
            title={<ModalTitle iconType={'formDef'}>Form</ModalTitle>}
            width={800}
            onCancel={onCancel}
            open={visible}
            styles={{body:{overflowY: 'auto', height: 'calc(100vh - 20rem)'}}}
            footer={<ModalFooter onCancel={onCancel} form={form} />}
        >
            <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
                <Card title={'Attributes'} type={'inner'} className={'mb-2'}>
                    <Row gutter={[20, 0]}>
                        <Col span={12}>
                            <FormInputOID selectedOID={selectedOID} form={form} allowDotChar={false}/>
                        </Col>
                        <Col span={12}>
                            <FormInputName name={formDef?.name} defType={'formDef'} form={form}/>
                        </Col>
                        <Col span={12}>
                            <FormSelectYesOrNo label={'Repeating'} name={'repeating'} required={true} yesOrNo={yesOrNo} value={formDef?.repeating}
                                disabledOption={getFormRepeatingDisabledOption(type)}
                            />
                        </Col>
                        <Col span={12}>
                            <FormSelectDataType label={'Form Type'} name={'formType'} required={true} dataTypes={formType}
                                                disabledOptions={disableFormTypes} onChange={onChangeFormType} value={formDef?.formType}
                            />
                        </Col>
                        {
                            type === 'SAE_REPORT_FORM' &&
                            <Col span={custom?12:24}>
                                <Form.Item label={'Report Design Type'} name={'reportDesignType'}>
                                    <Select onChange={onChangeDesignType}>
                                        {
                                            Object.keys(reportDesignType).map(key => (
                                                <Option key={key} value={key}>
                                                    {reportDesignType[key]}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        {
                            type === 'SAE_REPORT_FORM' && custom &&
                            <Col span={12}>
                                <Form.Item label={'Report API Key'} name={'reportApiKey'}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        }
                        <Col span={24}>
                            <Form.Item name={'siblingFormOID'} label={<span className={'text-primary'}>Sibling Form</span>}>
                                <Select showSearch={true} options={formDefOptions} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card title={'Description'} type={'inner'} className={'mb-2'}>
                    <DescriptionOrQuestion languages={languages} name={'description'} form={form}/>
                </Card>
            </Form>
        </Modal>
    )
}

export default FormDef;
