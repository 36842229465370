import {Form, InputNumber} from "antd";

const {Item} = Form;


const LibraryFormInputNumber = ({style, required, name, label, readOnly, rules, onKeyup, min = null, max = null}) => {

    return (
        <Item required={required} name={name} label={<span className={'text-primary'}>{label}</span>} rules={rules} >
            <InputNumber style={style} readOnly={readOnly} onKeyUp={onKeyup !== undefined ? onKeyup : null} min={min} max={max} />
        </Item>
    )

}

export default LibraryFormInputNumber;