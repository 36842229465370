import Request from '../axiosConfig';
import { trackPromise} from 'react-promise-tracker';

export default {

    async getMetaData(params) {
        // axios 요청을 생성합니다.
        /*
        axios 요청을 생성합니다.
        https://github.com/axios/axios axios API 챕터
        인터셉터를 안썼으면 다음과 같습니다.
        axios({
            baseURL: 'https://api.hnpwa.com',
            url: '/news/1.json',
            method: 'get'
        })
        */

        return await trackPromise(
            Request({
                url: '/api/metaDataVersion',
                params:params,
                method: 'get'
            }))
    },
    async updateProtocol(params) {
        const {sid, id} = params?.params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/protocol`,
                data:params,
                method: 'post'
            }))
    },
    async updateStudyTiming(params) {
        const {sid, id} = params?.params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/studyTiming`,
                data:params,
                method: 'post'
            }))
    },
    async updateAdverseEvent(params) {
        const {sid, id} = params?.params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/adverseEvent`,
                data:params,
                method: 'post'
            }))
    },
    async updateSubjectField(params) {
        const {sid, id} = params?.params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/subjectField`,
                data:params,
                method: 'post'
            }))
    },
    async updateSAEFormMap(params) {
        const {sid, id} = params?.params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/saeFormMap`,
                data:params,
                method: 'post'
            }))
    },
    async updateStudyStructure(params) {
        const {sid, id} = params?.params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/studyStructure`,
                data:params,
                method: 'post'
            }))
    },
    async updateProSchedule(params) {
        const {sid, id} = params?.params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/proSchedule`,
                data:params,
                method: 'post'
            }))
    },

    async syncProSchedule(params) {
        const {sid, id, visitFormOIDs} = params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/syncProSchedule`,
                data: visitFormOIDs,
                method: 'post'
            }))
    },
    async updateIndependentEvaluation(params) {
        const {sid, id} = params?.params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/independentEvaluation`,
                data:params,
                method: 'post'
            }))
    },
    async syncSubjectStatus(params) {
        const {sid, id} = params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/syncSubjectStatus`,
                method: 'post'
            }))
    },
    async creates(data) {
        const {sid, id} = data?.params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/${data.attr}/creates`,
                data: data.defList,
                method: 'post'
            })
        )
    },
    async updates(data) {
        const {sid, id} = data?.params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/${data.attr}/updates`,
                data: data.defList,
                method: 'post'
            }))
    },
    async save(data) {

        const {sid, id} = data?.params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return await trackPromise(
            Request({
                url: `/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/${data.attr}/${data.oid}`,
                data: data,
                method: data.requestMethod,
            }))
    },
    async findCDASH(params) {
        return await trackPromise(
            Request({
                url: '/api/cdisc/cdashig/2-1/search',
                params:params,
                method: 'get'
            }))
    },
    findCDASHDomains() {
        return trackPromise(
            Request({
                url: '/api/cdisc/cdashig/2-1/domains',
                params:{r:Math.random()},
                method: 'get'
            }))
    },
    async getShareAPI(url) {
        return await
            Request.get(
                url
                // method: 'get'
            )
    },
    async findCDASHCT(params) {
        return await trackPromise(
            Request({
                url: '/api/cdisc/cdashct/cdashct-2019-12-20',
                method: 'get',
                params:params
            }))
    },
    // saveMetaDataVersionLog(params) {
    //     return trackPromise(Request.post('/api/metaDataVersion/logging', params));
    // },
    async publishMetaData(params, data) {
        const {sid, id} = params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);
        const patch = sessionStorage.getItem(`${sid}_${id}_patch`);

        return trackPromise(
            Request({
                url:`/api/studies/${studyOID}/mdv/${mdvOID}/patch/${patch}/publish`,
                method:'post',
                data:data
            }))
    },
    async updateMetaData(metaData) {
        const {sid, id} = metaData?.params;
        const studyOID = sessionStorage.getItem(`${sid}_${id}_studyOID`);
        const mdvOID = sessionStorage.getItem(`${sid}_${id}_mdvOID`);

        return trackPromise(
            Request({
                url:`/api/${studyOID}/${mdvOID}/wizard/save`,
                method:'post',
                data:metaData
            }))
    },
    getItems() {
        // axios 요청을 생성합니다.
        /*
        axios 요청을 생성합니다.
        https://github.com/axios/axios axios API 챕터
        인터셉터를 안썼으면 다음과 같습니다.-
        axios({
            baseURL: 'https://api.hnpwa.com',
            url: '/news/1.json',
            method: 'get'
        })
        */

        return trackPromise(Request({
            // baseURL설정되어 있기 때문에 그 뒤의 URL만 작성합니다.
            url: '/api/itemDefs',
            method: 'get'
        }));
    },
    getItemDef(oid) {
        return trackPromise(Request({
            url: '/api/itemDef',
            params:{oid:oid},
            method: 'get'
        }));
    },

    saveItemDef(params) {
        return trackPromise(Request({
            url: '/api/itemDef',
            data:params,
            method: 'post'
        }));
    },
    getCodelists() {
        // axios 요청을 생성합니다.
        /*
        axios 요청을 생성합니다.
        https://github.com/axios/axios axios API 챕터
        인터셉터를 안썼으면 다음과 같습니다.
        axios({
            baseURL: 'https://api.hnpwa.com',
            url: '/news/1.json',
            method: 'get'
        })
        */
        return trackPromise(Request({
            // baseURL설정되어 있기 때문에 그 뒤의 URL만 작성합니다.
            url: '/api/codeLists',
            method: 'get'
        }))
    },
    getCodeList(oid) {
        return trackPromise(Request({
            url: '/api/codeList',
            params:{oid:oid},
            method: 'get'
        }));
    }
}
