import {Button, Col, Dropdown, Row, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {RightOutlined, UpOutlined} from "@ant-design/icons";
import {errorHandle, onExporting} from "../../../../lib/BuilderUtils";
import {CardBody, CardFooter, CardHeader, PrimaryCard} from "../../../../pages/PageStyleBox";
import {httpStatus} from "../../../../lib/createRequestSaga";
import DataGrid, {
    Column,
    ColumnChooser,
    Export,
    FilterRow,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    Scrolling,
    StateStoring,
    Toolbar
} from "devextreme-react/data-grid";
import {withTranslation} from "react-i18next";
import NormalLoading from "../../NormalLoading";

const SponsorList = ({t}) => {

    const navigate = useNavigate();
    const [sponsorList, setSponsorList] = useState(null);
    const [dataGrid, setDataGrid] = useState(null);
    const [multiContract, setMultiContract] = useState(false);
    const [contracts, setContracts] = useState([]);

    useEffect(() => {
        //GET Request
        (async () => { //비동기를 동기식으로 응답 받을 때 까지 대기.
            try {
                //REST API Request
                const {status, data} = await axios.get(`/api/builder/sponsors`);

                if(status === httpStatus.ok) {
                    //State Setting
                    setSponsorList(data?.sponsorList);
                    setMultiContract(data?.multiContract);
                    setContracts(data?.contracts);
                    console.log(data?.contracts);
                }
            } catch (e) {
                errorHandle(e);
            }
        })()
    }, []);


    //Study Edit
    const onEdit = (sponsorID) => {
        navigate(`/sponsors/edit?id=${sponsorID}`)
    }

    const onCreateSponsor = ({key}) => {
        navigate(`/sponsors/edit?contractId=${key}`);
    }

    const contractMenus = {
        items: (contracts??[]).map(ct => ({
            label: ct?.companyName,
            key: ct?.id,
            icon: <i className={'fa fa-building-o'}></i>
        })),
        onClick: onCreateSponsor,
    }

    return (
        <PrimaryCard style={{minHeight: '35rem'}}>
            <CardHeader>
                <Row>
                    <Col>
                        <h3><RightOutlined className={'mr-2'} />Sponsors</h3>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                {
                    sponsorList == null? (
                        <NormalLoading />
                    ) : (
                        <DataGrid dataSource={sponsorList}
                                  height={'600'}
                                  id={'gridSponsors'}
                                  ref={(ref) => {setDataGrid(ref);}}
                                  allowedPageSizes={true}
                                  allowColumnResizing={false}
                                  columnMinWidth={50}
                                  columnAutoWidth={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  showBorders={true}
                                  hoverStateEnabled={true}
                                  allowColumnReordering={true}
                                  keyExpr={'id'}
                                  onCellClick={({values, columnIndex, rowType, data}) => {
                                      if(rowType === 'data' && columnIndex !== (values?.length??0)-1) {
                                          navigate(`/sponsors/${data?.id}/sponsor-dashboard`);
                                      }
                                  }}
                                  onExporting={(e) => onExporting(e, 'Sponsors',
                                      ({gridCell, excelCell}) => {
                                          if(gridCell.column.dataField === 'action') {
                                              excelCell.value = '';
                                          }
                                      }
                                  )}>
                            {/*<GroupPanel visible={true} />*/}
                            {/*<Grouping autoExpandAll={true} />*/}
                            <Column dataField={'id'} caption={t('column.no')} fixed={true} fixedPosition={'left'} width={'60px'}
                                    cellRender={({row}) => (
                                        <span key={row?.loadIndex}>{sponsorList?.length - row?.loadIndex}</span>
                                    )} />
                            {
                                multiContract &&
                                <Column dataField={'contract.companyName'} caption={t('column.contract')} dataType={'string'} width={'150px'} />
                            }
                            <Column dataField={'name'} caption={t('column.sponsor')} dataType={'string'} />
                            <Column dataField={'logo'} caption={t('column.logo')} dataType={'string'} width={'250px'}
                                    cellRender={({data}) => (<Space><img src={data?.logo} width={'100%'} height={'auto'} /></Space>)} />
                            <Column dataField={'createdDate'} caption={t('column.created-date')} dataType={'date'} format={'yyyy-MM-dd HH:mm:ss'} width={'150px'} />
                            <Column dataField={'action'} caption={t('column.action')} fixed={true} fixedPosition={'right'}
                                    cellRender={({data}) => (
                                        <Space size={'middle'} id='actions'>
                                            <Button onClick={() => {onEdit(data?.id) }} icon={<i className='fe fe-edit'/>}> Edit</Button>
                                        </Space>
                                    )} />
                            <Toolbar>
                                <Item name={'columnChooserButton'} />
                                <Item name={'exportButton'} />
                            </Toolbar>
                            <ColumnChooser enabled={true} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <LoadPanel enabled={true} />
                            <HeaderFilter visible={true} allowSearch={true} />
                            <FilterRow visible={true} applyFilter={'auto'} />
                            <StateStoring enabled={true} type={'sessionStorage'} storageKey={`storage-sponsors`}/>
                            <Scrolling rowRenderingMode={'virtual'} />
                            <Paging defaultPageSize={10}/>
                            <Pager
                                visible={true}
                                displayMode={'compact'}
                                allowedPageSizes={[10, 20, 30, 50]}
                                showPageSizeSelector={true}
                                showInfo={true}
                            />
                        </DataGrid>
                    )
                }

            </CardBody>
            <CardFooter>
                {
                    multiContract?(
                        <Dropdown menu={contractMenus} placement={'topRight'}>
                            <Button>
                                <Space>
                                    {t('sponsors.btn.add')}
                                    <UpOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    ):(
                        <Button onClick={() => {navigate('/sponsors/edit')}}> {t('sponsors.btn.add')} </Button>
                    )
                }
            </CardFooter>
        </PrimaryCard>
    )
}

export default withTranslation()(SponsorList);