import {call, put} from "redux-saga/effects";

export const httpStatus = {
    ok: 200,
    duplicateLogin: 300,
    badRequest: 400,
    unAuthorized: 401,
    paymentRequired: 402,
    forbidden: 403,
    notFound: 404,
    methodNotAllowed: 405,
    notAcceptable: 406,
    internalServerError: 500,
}

export const createActionTypes = (type) => {
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;

    return [type, SUCCESS, FAILURE]
}

const requestMethod = {'CREATE': 'post', 'DELETE': 'delete', 'UPDATE': 'put'};

/**
 * This function is use for only MetaData Module.
 * @param type
 * @param request
 * @returns {(function(*): Generator<SimpleEffect<"CALL", CallEffectDescriptor<* extends ((...args: any[]) => SagaIterator<infer RT>) ? RT : (* extends ((...args: any[]) => Promise<infer RT>) ? RT : (* extends ((...args: any[]) => infer RT) ? RT : never))>>|SimpleEffect<"CALL", CallEffectDescriptor<{[P in string]: (this:Ctx, ...args: any[]) => any}[string] extends ((...args: any[]) => SagaIterator<infer RT>) ? RT : ({[P in string]: (this:Ctx, ...args: any[]) => any}[string] extends ((...args: any[]) => Promise<infer RT>) ? RT : ({[P in string]: (this:Ctx, ...args: any[]) => any}[string] extends ((...args: any[]) => infer RT) ? RT : never))>>|SimpleEffect<"CALL", CallEffectDescriptor<(this:unknown, ...args: any[]) => any extends ((...args: any[]) => SagaIterator<infer RT>) ? RT : ((this:unknown, ...args: any[]) => any extends ((...args: any[]) => Promise<infer RT>) ? RT : ((this:unknown, ...args: any[]) => any extends ((...args: any[]) => infer RT) ? RT : never))>>|SimpleEffect<"PUT", PutEffectDescriptor<{payload: *, type: string}>>|SimpleEffect<"PUT", ChannelPutEffectDescriptor<unknown>>|SimpleEffect<"PUT", PutEffectDescriptor<{payload: *, type: string, error: boolean}>>, void, *>)|*}
 */
export const createMetadataSaga = (type, request) => {
    const [ACTION, SUCCESS, FAILURE] = createActionTypes(type);
    return function* (action) {
        try {
            if (type === 'CREATE' || type === 'UPDATE' || type === 'DELETE') {//메타데이터 추가/수정/삭제 공통 처리(api/metadata.js)
                action.payload.requestMethod = requestMethod[type];
            }
            const response = yield call(request, action.payload);

            yield put({
                type: SUCCESS,
                payload: response.data,
            });

            // message.success('성공!');
        } catch (e) {
            const responseData = e.response;
            if(e.response?.data?.message != null) {
                responseData.message = e.response.data.message;
            } else {
                responseData.message = e.response.statusText;
            }

            // if(e.response) {
            //     responseData = e.response;
            //     responseData.message = e.response.data;
            // } else if(e.request) {
            //     responseData = e.request;
            //     responseData.message = '요청에 실패 했습니다.';
            // } else {
            //     responseData.message = e.message;
            // }

            yield put({
                type: FAILURE,
                payload: responseData,
                message: responseData.message,
                error: true,
            });
            // message.error('오류가 발생 했습니다.');
        }
        // yield put(actions.finishLoading()); //로딩 끝
    }
}


export const createUserSaga = (type, request) => {
    const [ACTION, SUCCESS, FAILURE] = createActionTypes(type);
    return function* (action) {
        try {
            const {status, data} = yield call(request, action.payload);

            //Login 페이지 관련 html이 출력되는 경우,
            if (typeof data === "string" && data.includes("chunk")) {
                if(action.payload === true) {
                    yield put({
                        type: 'user/REPLACE_PATH',
                        payload: `/login`,
                    })
                } else {
                    yield put({
                        type: 'user/REPLACE_PATH',
                        payload: `/login?invalidSession`,
                    })
                }
                return;
            }

            if (status === httpStatus.ok) {
                //LOAD CURRENT ACCOUNT 일 때, 현재 username을 localStorage에 저장.
                if (action.type === 'user/LOAD_CURRENT_ACCOUNT') {
                    sessionStorage.setItem("builder.username", data?.username);
                }

                yield put({
                    type: SUCCESS,
                    payload: data,
                });
            }

            // message.success('성공!');
        } catch (e) {
            yield put({
                type: FAILURE,
                payload: e,
                error: true,
            });
            // message.error('오류가 발생 했습니다.');
        }
    }
}

/**
 * Common redux-saga function
 * @param type
 * @param request
 * @returns {(function(*): Generator<SimpleEffect<"CALL", CallEffectDescriptor<* extends ((...args: any[]) => SagaIterator<infer RT>) ? RT : (* extends ((...args: any[]) => Promise<infer RT>) ? RT : (* extends ((...args: any[]) => infer RT) ? RT : never))>>|SimpleEffect<"CALL", CallEffectDescriptor<{[P in string]: (this:Ctx, ...args: any[]) => any}[string] extends ((...args: any[]) => SagaIterator<infer RT>) ? RT : ({[P in string]: (this:Ctx, ...args: any[]) => any}[string] extends ((...args: any[]) => Promise<infer RT>) ? RT : ({[P in string]: (this:Ctx, ...args: any[]) => any}[string] extends ((...args: any[]) => infer RT) ? RT : never))>>|SimpleEffect<"CALL", CallEffectDescriptor<(this:unknown, ...args: any[]) => any extends ((...args: any[]) => SagaIterator<infer RT>) ? RT : ((this:unknown, ...args: any[]) => any extends ((...args: any[]) => Promise<infer RT>) ? RT : ((this:unknown, ...args: any[]) => any extends ((...args: any[]) => infer RT) ? RT : never))>>|SimpleEffect<"PUT", PutEffectDescriptor<{payload: *, type: string}>>|SimpleEffect<"PUT", ChannelPutEffectDescriptor<unknown>>|SimpleEffect<"PUT", PutEffectDescriptor<{payload: *, type: string, error: boolean}>>, void, *>)|*}
 */
const createRequestSaga = (type, request) => {
    const [ACTION, SUCCESS, FAILURE] = createActionTypes(type);
    return function* (action) {
        // yield put(actions.startLoading()); //로딩 시작

        try {
            const {status, data} = yield call(request, action.payload);

            //Login 페이지 관련 html이 출력되는 경우,
            if(typeof data === "string" && data.includes("chunk")) {
                yield put({
                    type: 'user/REPLACE_PATH',
                    payload: `/login?invalidSession`,
                })
                // message.error("세션이 종료되어 로그인 화면으로 이동합니다.");
                return;
            }

            if(status === httpStatus.ok) {
                yield put({
                    type: SUCCESS,
                    payload: data,
                });
            }
            // message.success('성공!');
        } catch (e) {
            yield put({
                type: FAILURE,
                payload: e,
                error: true,
            });
            // message.error('오류가 발생 했습니다.');
        }
        // yield put(actions.finishLoading()); //로딩 끝
    }
}

export default createRequestSaga;
