import {Helmet} from "react-helmet-async";
import React from "react";
import CRFVersionList from "../../../../components/common/builder/studies/CRFVersionList";
import CRFVersionTabs from "../../../../layouts/Tab/crf-version";

const CRFVersionPage = () => {

    return (
        <CRFVersionTabs menu={'versions'}>
            <Helmet title={'BUILDER :: CRF Versions'}/>
            <CRFVersionList />
        </CRFVersionTabs>
    )

}

export default CRFVersionPage;