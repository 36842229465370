import Request from '../axiosConfig';
import { trackPromise} from 'react-promise-tracker';

export default {
    async login(params) {
        return await trackPromise(
            Request({
                url:'/login',
                params:params,
                method:'post',
            })
        )
    },
    async logout(params) {
        return await trackPromise(
            Request({
                url:'/logout',
                data:params,
                method:'get',
            })
        )
    },

    async loadCurrentAccount(params) {
        return await trackPromise(
            Request({
                url:'/api/users/load-current-account',
                data:params,
                method:'get',
            })
        )
    },

    async profileUpdate(params) {
        return await trackPromise(
            Request({
                url:'/api/profile-update',
                data:params,
                method:'put',
            })
        )
    }

}