import ModalTitle from "../../../../../container/build-tool/modal/component/ModalTitle";
import ModalFooter from "../../../../../container/build-tool/modal/ModalFooter";
import {Card, Col, Dropdown, Form, InputNumber, Modal, Row, Select, Switch, Tag} from "antd";
import React, {useEffect, useState} from "react";
import LibraryFormInputOID from "../ant-form/LibraryFormInputOID";
import LibraryFormInputName from "../ant-form/LibraryFormInputName";
import {allowExtensionOptions, controlTypes, dataTypes, unknownDateFormat} from "../../../../../lib/ODMUtils";
import {isEmpty} from "../../../../../lib/StringUtils";
import LibraryFormSelectDataTypes from "../ant-form/LibraryFormSelectDataTypes";
import LibraryFormInputNumber from "../ant-form/LibraryFormInputNumber";
import FormInputNumber from "../../../odm/ant-form/FormInputNumber";
import FormInput from "../../../odm/ant-form/FormInput";
import {WithContext as ReactTags} from "react-tag-input";
import {DownOutlined} from "@ant-design/icons";
import LibraryCodeListContainer from "../container/LibraryCodeListContainer";
import LibraryFormInput from "../ant-form/LibraryFormInput";
import LibraryDescriptionOrQuestion from "../ant-form/LibraryDescriptionOrQuestion";
import _ from 'lodash';
import {useTranslation} from "react-i18next";

const KeyCodes = {
    comma: 188,
    enter: 13
};

const libraryControlTypes = controlTypes.filter(({value}) => ["DROPDOWN_DATALINK", "FILE_DOWNLOAD"].includes(value) === false);

const LibraryItemDef = ({library, open, itemDef, languages, codeLists, onFinish, onCancel}) => {

    const {t} = useTranslation();

    const [form] = Form.useForm();

    const [mandatoryDigits, setMandatoryDigits] = useState(false);
    const [mandatoryLength, setMandatoryLength] = useState(false);
    const [mandatoryPrefix, setMandatoryPrefix] = useState(false);
    const [mandatoryPostfix, setMandatoryPostfix] = useState(false);
    const [showAllowFutureDate, setShowAllowFutureDate] = useState(false);
    const [showAllowOnlyAlphabet, setShowAllowOnlyAlphabet] = useState(false);
    const [showOnlyUpperCase, setShowOnlyUpperCase] = useState(false);
    const [showAllowSpace, setShowAllowSpace] = useState(false);
    const [showUnknownDateFormat, setShowUnknownDateFormat] = useState(false);
    const [showAllowWords, setShowAllowWords] = useState(false);
    const [showAllowExtensions, setShowAllowExtensions] = useState(false);
    const [showTextAlignJustify, setShowTextAlignJustify] = useState(false);
    const [showLabel, setShowLabel] = useState(false);

    const [showCodeList, setShowCodeList] = useState(false);
    const [filteredCodelist, setFilteredCodelist] = useState([]);
    const [enabledDataTypes, setEnabledDataTypes] = useState([]);

    const [controlType, setControlType] = useState(null);
    const [allowWords, setAllowWords] = useState([]);

    const [ukFormat, setUkFormat] = useState("ALL_UK");
    const [defaultMonth, setDefaultMonth] = useState(1);
    const [defaultDay, setDefaultDay] = useState(1);

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    //CodeList Item Setting
    const [defOpen, setDefOpen] = useState(null);
    const [defType, setDefType] = useState(null);
    const [defItem, setDefItem] = useState(null);

    const handleAllowWordDelete = i => {
        setAllowWords(allowWords.filter((tag, index) => index !== i));
    };

    const handleAllowWordAddition = tag => {
        tag.text = tag.text.toUpperCase();
        setAllowWords([...allowWords, tag]);
    };

    useEffect(() => {
        if(itemDef?.controlType != null && itemDef?.dataType != null) {
            loadPropertiesInfo(itemDef);
        }
        form.setFieldsValue(itemDef);

        if(itemDef?.allowWords ?? false) {
            const words = [];
            itemDef.allowWords.forEach(w => words.push({id:w, text:w}));
            setAllowWords(words);
        } else {
            setAllowWords([]);
        }
    }, [itemDef]);


    //CodeLists 변수 감지 확인
    useEffect(() => {
        if(codeLists) {
            const type = form.getFieldValue('dataType');
            //type이 null, undefined, ""이 아닌 경우 필터동작을 활성화 함.
            if(!isEmpty(type)) {
                const filtered = codeLists.filter(c => c.dataType === type);
                setFilteredCodelist(filtered);
            }
        }
    }, [codeLists]);

    const loadPropertiesInfo = (itemDef) => {
        const controlType = libraryControlTypes.find(t => t.value === itemDef?.controlType);
        setControlType(controlType);
        const dataType = itemDef?.dataType;
        const dataTypeArr = controlType.dataTypes;
        setEnabledDataTypes(dataTypes.filter(t => dataTypeArr.findIndex(type => type === t.value) !== -1));

        setShowCodeList(controlType.codeListRef);
        setShowAllowFutureDate(controlType.value === 'DATEPICKER');
        setFilteredCodelist([]);

        setMandatoryPrefix(controlType.value === "INPUT");
        setMandatoryPostfix(controlType.value === "INPUT");

        setMandatoryDigits((dataType === 'FLOAT' || dataType === 'DOUBLE'));
        setMandatoryLength((dataType === 'DATE' || dataType === 'TIME' || dataType === 'PARTIAL_DATE' || dataType === 'PARTIAL_TIME' || dataType === 'INTEGER' || dataType === 'FLOAT' || dataType === 'DOUBLE' || dataType === 'TEXT' || dataType === 'STRING'));
        setShowUnknownDateFormat(controlType.value === 'DATEPICKER' && (dataType === 'PARTIAL_DATE'));
        setShowAllowOnlyAlphabet(controlType.value === 'INPUT' && dataType === 'TEXT');
        setShowOnlyUpperCase(itemDef?.allowOnlyAlphabet && (controlType.value === 'INPUT' && dataType === 'TEXT'));
        setShowAllowSpace(itemDef?.allowOnlyAlphabet && (controlType.value === 'INPUT' && dataType === 'TEXT'));

        setShowAllowWords(controlType.value === "INPUT" && (dataType === 'INTEGER' || dataType === 'FLOAT'));
        setShowAllowExtensions(controlType.value === 'FILE_UPLOAD' && dataType === 'TEXT');
        setShowTextAlignJustify(controlType.value === "RADIOBUTTON_HORIZONTAL" && (dataType === 'INTEGER' || dataType === 'TEXT'));
        setShowLabel(controlType.value === 'LABEL');

        setUkFormat(itemDef?.unknownDateFormat);
        setDefaultMonth((itemDef?.defaultMonth) !== null ? (itemDef?.defaultMonth) : 1);
        setDefaultDay((itemDef?.defaultDay) !== null ? (itemDef?.defaultDay) : 1);

        if (codeLists.length > 0) {
            const filtered = controlType.value === 'DICTIONARY' ? codeLists.filter(c => c.dataType === dataType && c.externalCodeList != null) : codeLists.filter(c => c.dataType === dataType && c.codeListItem != null);
            setFilteredCodelist(filtered);
        }
    }

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    const onChangeDataType = (type, option) => {
        checkDataType(type);
    }

    const onChangeCodeList = (_, value) => {
        let codeListRef = form.getFieldValue(['codeListRef', 'codeListOID']);
        let isCodeListRef = isEmpty(codeListRef) == false;

        if(showCodeList && !isCodeListRef) {
            return Promise.reject(new Error(t('message.this.is.required.selection')));
        }

        return Promise.resolve();
    }

    const onSubmit = (itemDef) => {
        if(showAllowWords && allowWords.length > 0) {
            const words = allowWords.map(w => w.text.toUpperCase());
            itemDef.allowWords = words;
        }

        onFinish(itemDef);
    }

    const checkDataType = (type) => {
        setMandatoryDigits((type === 'FLOAT' || type === 'DOUBLE'));
        setMandatoryLength((type === 'DATE' || type === 'TIME' || type === 'PARTIAL_DATE' || type === 'PARTIAL_TIME' || type === 'INTEGER' || type === 'FLOAT' || type === 'DOUBLE' || type === 'TEXT' || type === 'STRING'));
        setShowUnknownDateFormat((type === 'PARTIAL_DATE'));

        if(controlType != null) {
            setShowAllowFutureDate(controlType.value === 'DATEPICKER');//date, partialDate 인 경우 보이도록 처리
            setShowAllowOnlyAlphabet(controlType.value === 'INPUT' && type === "TEXT");//input이면서 text일 때 보이도록 처리
            setShowAllowWords(controlType.value === 'INPUT' && (type === "INTEGER" || type === "FLOAT"));//input이면서 text일 때 보이도록 처리
            setShowAllowExtensions(controlType.value === 'FILE_UPLOAD' && type === 'TEXT');//File Upload이면서 Text일 때 보이도록 처리
        }

        let length = null;
        if(type === 'DATE' || type === 'PARTIAL_DATE') {
            length = 10;
        } else if(type === 'TIME' || type === 'PARTIAL_TIME') {
            length = 5;
        }

        form.setFieldsValue({
            length,
            fractionDigits:null
        });
        if(controlType != null) {
            if (codeLists.length > 0) {
                const filtered = controlType.value === 'DICTIONARY' ? codeLists.filter(c => c.dataType === type && c.externalCodeList != null) : codeLists.filter(c => c.dataType === type && c.codeListItem != null);
                setFilteredCodelist(filtered);
            }
        }
    }

    const onOnlyAlphabetChange = (value) => {
        setShowOnlyUpperCase(value);
        setShowAllowSpace(value);
    }

    const onChangeControlType = (value) => {
        if(value != null) {
            const controlType = libraryControlTypes.find(t => t.value === value);
            // console.log(controlType);
            setControlType(controlType);
            const dataTypeArr = controlType.dataTypes;
            setEnabledDataTypes(dataTypes.filter(t => dataTypeArr.findIndex(type => type === t.value) !== -1));
            setShowCodeList(controlType.codeListRef);
            setShowAllowFutureDate(controlType.value === 'DATEPICKER');
            setShowAllowOnlyAlphabet(false);
            setShowOnlyUpperCase(false);
            setShowAllowSpace(false);
            setShowUnknownDateFormat(false);
            setFilteredCodelist([]);

            setMandatoryPrefix(value === "INPUT");
            setMandatoryPostfix(value === "INPUT");
            const type = form.getFieldValue('dataType');
            setShowAllowWords(value === "INPUT" && (type === 'INTEGER' || type === 'FLOAT'));
            setShowAllowExtensions(value === 'FILE_UPLOAD' && type === 'TEXT');
            setShowLabel(value === 'LABEL');

        }

        form.setFieldsValue({
            dataType:null,
            codeListRef:null,
            allowFutureDate: null,
            allowOnlyAlphabet: null,
            onlyUpperCase: null,
            allowSpace: null,
        });
        // checkDataType(dataType);
    }

    //CodeList Menu
    const menu = {
        items : [
            {
                key: 'externalCodeList',
                label: <><i className={'fa fa-plus mr-2'} />ExternalCodeList</>
            }
        ],
        onClick: ({key}) => {
            onCreate(key);
        }
    }

    /**
     * CodeList Create Method
     * @param type
     */
    const onCreate = (type) => {
        setDefOpen(true);
        setDefType(type);
        setDefItem(null);
    }

    const onCodeListSelect = (value) => {
        const codeList = library.codeList.get(value);
        if(codeList.codeListItem != null) {
            let max = -1;
            codeList.codeListItem.forEach(c => {
                if(c.codedValue.toString().length > max) {
                    max = c.codedValue.toString().length;
                }
            })
            if(max > -1) {form.setFieldsValue({length: max});}
        }
    }

    const ukChange = (selectedUk) => {
        setUkFormat(selectedUk);

        let length = 10;
        if(selectedUk === 'YEAR_UK') {
            length = 2;
        } else if(selectedUk === 'YEAR_SHORT') {
            length = 4;
        } else if(selectedUk === 'MONTH_SHORT' || selectedUk === 'MONTH_UK_SHORT') {
            length = 7;
        }

        form.setFieldsValue({
            length,
            fractionDigits:null
        });
    }

    return (

        <Modal
            forceRender={true}
            wrapClassName={'odm-modal'}
            title={<ModalTitle iconType={'itemDef'}>Item</ModalTitle>}
            width={800}
            onCancel={onCancel}
            open={open}
            destroyOnClose={true}
            styles={{body:{overflowY: 'auto', height: 'calc(100vh - 20rem)'}}}
            footer={<ModalFooter onCancel={onCancel} form={form} />}
        >
            <Form layout={'vertical'} onFinish={onSubmit} onFinishFailed={onFinishFailed} form={form}>
                <Card title={'Attributes'} type={'inner'} className={'mb-2'}>
                    <Row gutter={[20,0]}>
                        <Col span={6}>
                            <LibraryFormInputOID selectedOID={itemDef?.oid??""} allowDotChar={false} form={form} />
                        </Col>
                        <Col span={18}>
                            <LibraryFormInputName name={itemDef?.name} defType={'itemDef'} form={form} />
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'controlType'} label={<span className='text-primary'>{'Control Type'}</span>}
                                       rules={[{required: true, message:t('message.this.is.required.selection')}]}
                                       required={true}>
                                <Select onChange={onChangeControlType} options={libraryControlTypes} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <LibraryFormSelectDataTypes dataTypes={enabledDataTypes} onChange={onChangeDataType} value={itemDef?.dataType} />
                        </Col>
                        {
                            (mandatoryLength || mandatoryDigits) &&
                            <>
                                {
                                    mandatoryLength &&
                                    <Col span={mandatoryDigits?5:10}>
                                        <LibraryFormInputNumber required={mandatoryLength} name={'length'} label={'Length'} style={{width: '100%'}}
                                                                min={1}
                                                                rules={[
                                                                    {
                                                                        required: mandatoryLength,
                                                                        message: t('message.warning.required-entry')
                                                                    },
                                                                    {
                                                                        type: 'number',
                                                                        max: 4000,
                                                                        message: t('message.length.value.can.be.up.to.4000')
                                                                    },
                                                                    {
                                                                        validator: async (_, length) => {
                                                                            const fractionDigits = form.getFieldValue('fractionDigits');
                                                                            const isError = (length - fractionDigits) < 2;

                                                                            if(fractionDigits == null) {
                                                                                return Promise.resolve();
                                                                            } else if(isError) {
                                                                                return Promise.reject(new Error(t('message.length.value.must.be.least.2.greater.than.value.of.fraction.digits')));
                                                                            } else {
                                                                                return Promise.resolve();
                                                                            }
                                                                        }
                                                                    }
                                                                ]}/>
                                    </Col>
                                }
                                {
                                    showUnknownDateFormat &&
                                    <Col span={10}>
                                        <Form.Item label={'Unknown Date Format'} name={'unknownDateFormat'} initialValue={'ALL_UK'}
                                                   rules={[{required: true, message: t('message.pls.specify.uk.date.format')}]}>
                                            <Select options={unknownDateFormat} onChange={ukChange} />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    (showUnknownDateFormat && ukFormat === "YEAR_SHORT") &&
                                    <Col span={4}>
                                        <Form.Item label={'Default Month'} name={'defaultMonth'} initialValue={1}
                                                   rules={[{required: true, message: '기준 월을 지정해주세요.'}]}>
                                            <Select options={[
                                                { value: 1, label: '1'}, { value: 2, label: '2'}, { value: 3, label: '3'},
                                                { value: 4, label: '4'}, { value: 5, label: '5'}, { value: 6, label: '6'},
                                                { value: 7, label: '7'}, { value: 8, label: '8'}, { value: 9, label: '9'},
                                                { value: 10, label: '10'}, { value: 11, label: '11'}, { value: 12, label: '12'},
                                            ]} value={defaultMonth} />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    (showUnknownDateFormat && (ukFormat === "YEAR_SHORT" || ukFormat === "MONTH_SHORT")) &&
                                    <Col span={4}>
                                        <Form.Item label={'Default Day'} name={'defaultDay'} initialValue={1}
                                                   rules={[{required: true, message: '기준 일자를 지정해주세요.'}]}>
                                            <InputNumber style={{width: '100%'}} min={1} max={31} precision={0} stringMode={false} value={defaultDay} />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    showAllowFutureDate &&
                                    <Col span={5}>
                                        <Form.Item label={<span className='text-primary'>Allow Future Date</span>} valuePropName="checked" name={'allowFutureDate'}>
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    mandatoryDigits &&
                                    <Col span={5}>
                                        <FormInputNumber required={mandatoryDigits} name={'fractionDigits'} label={'Fraction Digits'}
                                                         style={{width: '100%'}} min={1}
                                                         rules={[
                                                             {
                                                                 required: mandatoryDigits,
                                                                 message: t('message.warning.required-entry')
                                                             },
                                                             {
                                                                 type: 'number',
                                                                 max: 50,
                                                                 message: t('message.value.for.fraction.digits.can.be.input.up.to.50')
                                                             }
                                                         ]}/>
                                    </Col>
                                }
                                {
                                    showAllowOnlyAlphabet &&
                                    <Col span={(showOnlyUpperCase&&showAllowSpace)?6:24}>
                                        <Form.Item label={'Allow Only Alphabet'} valuePropName="checked" className={'text-primary'} name={'allowOnlyAlphabet'}>
                                            <Switch onChange={onOnlyAlphabetChange} />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    showOnlyUpperCase &&
                                    <Col span={6}>
                                        <Form.Item label={'Only UpperCase'} valuePropName="checked" className={'text-primary'} name={'onlyUpperCase'}>
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    showAllowSpace &&
                                    <Col span={12}>
                                        <Form.Item label={'Allow Space'} valuePropName="checked" className={'text-primary'} name={'allowSpace'}>
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    mandatoryPrefix &&
                                    <Col span={12}>
                                        <FormInput required={false} name={'prefix'} label={'Left Text'}
                                                   rules={[
                                                       {
                                                           max: 20,
                                                           message: t('message.prefix.value.can.be.input.up.to.20.characters')
                                                       }
                                                   ]} />
                                    </Col>
                                }
                                {
                                    mandatoryPostfix &&
                                    <Col span={12}>
                                        <FormInput required={false} name={'postfix'} label={'Right Text'}
                                                   rules={[
                                                       {
                                                           max: 20,
                                                           message: t('message.postfix.value.can.be.input.up.to.20.characters')
                                                       }
                                                   ]} />
                                    </Col>
                                }
                                {
                                    showLabel &&
                                    <Col span={12}>
                                        <FormInput required={showLabel} name={'labelText'} label={'Label Text'}
                                                   rules={[
                                                       {
                                                           required: showLabel,
                                                           message: t('message.warning.required-entry')
                                                       },
                                                       {
                                                           min: 1,
                                                           message: t('message.pls.input.at.least.1.character.for.Label.Text')
                                                       },
                                                       {
                                                           validator: async (_, labelText) => {
                                                               const lengthValue = form.getFieldValue('length');

                                                               if(lengthValue == null) {
                                                                   return Promise.reject(new Error(t('message.pls.enter.length.value')));
                                                               } else if(labelText?.length > lengthValue) {
                                                                   return Promise.reject(new Error(t('message.enter.up.to.length.value.characters',{lengthValue: lengthValue})));
                                                               } else {
                                                                   return Promise.resolve();
                                                               }
                                                           }
                                                       }
                                                   ]} />
                                    </Col>
                                }
                                {
                                    showTextAlignJustify &&
                                    <Col span={6}>
                                        <Form.Item label={<span className='text-primary'>Align Text(Justify)</span>} valuePropName="checked" name={'alignJustify'}>
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    showAllowWords &&
                                    <Col span={24}>
                                        <div style={{marginBottom:15}}>
                                            <div className={'text-primary'}>Allow Words</div>
                                            <ReactTags
                                                tags={allowWords}
                                                // suggestions={suggestions}
                                                delimiters={delimiters}
                                                handleDelete={handleAllowWordDelete}
                                                handleAddition={handleAllowWordAddition}
                                                // handleDrag={handleAllowWordDrag}
                                                // handleTagClick={handleTagClick}
                                                autofocus={false}
                                                focus={false}
                                                inputFieldPosition="inline"
                                                placeholder={'e.g. ND, UK,..'}
                                                allowDragDrop={false}
                                            />
                                        </div>
                                    </Col>
                                }
                                {
                                    showAllowExtensions &&
                                    <>
                                        <Col span={12}>
                                            <Form.Item label={<span className='text-primary'>Allow Extensions</span>}
                                                       name={'allowExtensions'} rules={[{required: true, message:'필수 선택 항목입니다.'}]}>
                                                <Select mode={'multiple'} options={allowExtensionOptions} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label={<span className='text-primary'>Limit File Size (MB)</span>}
                                                       name={'limitFileSize'} rules={[{required: true, message:'필수 입력 항목입니다.'}]}>
                                                <InputNumber min={0} max={1024} placeholder={'MB 단위로 입력'} style={{width:'100%'}} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                            </>
                        }
                        <Col span={24} hidden={!showCodeList}>
                            <Form.Item label={
                                <>
                                    <span style={{flex:'1 0 fit-content'}} className={'mr-2'}>
                                        CodeList Reference
                                    </span>
                                    <Dropdown.Button size={'small'} menu={menu}
                                                     icon={<DownOutlined/>}
                                                     onClick={() => onCreate('codeListItem')}>
                                        <i className={'fa fa-plus mr-2'}/>CodeListItem
                                    </Dropdown.Button>
                                </>
                            } name={['codeListRef', 'codeListOID']} rules={[{validator: onChangeCodeList}]}>
                                {
                                    <Select showSearch allowClear={true} onSelect={onCodeListSelect} placeholder={filteredCodelist?.length <= 0 ?t('message.pls.select.data.type'):t('message.select.code.list')}
                                            filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                                        {/*<Select.Option>...</Select.Option>*/}
                                        {filteredCodelist.map(option => (
                                            <Select.Option key={option.oid} value={option.oid} >
                                                <Tag style={{minWidth:100, lineHeight:'20px', verticalAlign: 'text-top'}} color={"red"}>{option.oid}</Tag>
                                                &nbsp;{option.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                }
                            </Form.Item>
                            { //CodeListContainer
                                (defOpen && (defType === 'codeListItem' || defType === 'externalCodeList')) &&
                                <LibraryCodeListContainer defOpen={defOpen} setDefOpen={setDefOpen}
                                                          defType={defType} defItem={defItem} setDefItem={setDefItem}/>
                            }
                        </Col>
                        <Col span={24}>
                            <LibraryFormInput nname={'sdsVarName'} label={'SDS Variable Name'}
                                              rules={[
                                                  {
                                                      pattern: /^([a-zA-Z_][a-zA-Z0-9_]+){1,8}$/i,
                                                      message: t('message.format.is.not.valid')
                                                  },
                                                  {max: 8, message: t('message.you.can.enter.up.to.8.characters')}
                                              ]}/>
                        </Col>
                    </Row>
                    <Card title={'Question'} type={'inner'} className={'mb-2'}>
                        <LibraryDescriptionOrQuestion languages={languages} name={'question'} form={form}/>
                    </Card>
                    <Card title={'Description'} type={'inner'} className={'mb-2'}>
                        <LibraryDescriptionOrQuestion languages={languages} name={'description'} form={form}/>
                    </Card>
                </Card>
            </Form>
        </Modal>
    )

}

export default LibraryItemDef;
