import React, {useEffect, useState} from "react";
import {Drawer} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {httpStatus} from "../../../lib/createRequestSaga";
import {errorHandle, onExporting} from "../../../lib/BuilderUtils";
import './style.scss';
import NormalLoading from "../NormalLoading";
import DataGrid, {
    Column,
    ColumnChooser,
    Export,
    FilterRow,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    Toolbar
} from "devextreme-react/data-grid";

const StudyStructureCurrentRandomNo = ({showCurrentRandomNo, onHistoryCloseDrawer, useStratification, range, reload}) => {

    const [randomList, setRandomList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataGrid, setDataGrid] = useState(null);

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params?.sid}/stratification/currentRandomNo`);
                if(status === httpStatus.ok) {
                    setRandomList(data?.randomizationList);
                }
            } catch (e) {
                errorHandle(e, navigate, '');
            }
            setLoading(false);
        })()

    }, [reload])

    return (
        <>
            <Drawer
                title={"Current Randomization Info"}
                placement="right"
                size={'large'}
                open={showCurrentRandomNo}
                onClose={() => onHistoryCloseDrawer('current')}
            >
                {
                    loading?(
                        <NormalLoading />
                    ):(
                        <>
                            <DataGrid dataSource={randomList}
                                      height={'600'}
                                      id={'currentRandomNoList'}
                                      ref={(ref) => {
                                          setDataGrid(ref);
                                      }}
                                      allowedPageSizes={true}
                                      allowColumnResizing={false}
                                      columnMinWidth={50}
                                      columnAutoWidth={true}
                                      showColumnLines={true}
                                      showRowLines={true}
                                      showBorders={true}
                                      hoverStateEnabled={true}
                                      allowColumnReordering={true}
                                      keyExpr={'id'}
                                      onExporting={(e) => onExporting(e, 'Studies',
                                          ({gridCell, excelCell}) => {
                                              if (gridCell.column.dataField === 'action') {
                                                  excelCell.value = '';
                                              }
                                          }
                                      )}>
                                <Column dataField={'num'} caption={('No')} fixed={true} fixedPosition={'left'}
                                        width={'60px'}
                                        cellTemplate={(cellElement, cellInfo) => {
                                            const dataGrid = cellInfo.component;
                                            const num = dataGrid.pageIndex() * dataGrid.pageSize() + cellInfo.rowIndex;
                                            cellElement.innerText= num + 1;
                                        }}
                                />
                                <Column dataField={'environment.key'} caption={'Env'} dataType={'string'}/>
                                {
                                    'SITE' === range &&
                                    <Column dataField={'siteOid'} dataType={'string'}/>
                                }
                                <Column dataField={'sequenceNo'} dataType={'string'}/>
                                <Column dataField={'randomNo'} dataType={'string'}/>
                                <Column dataField={'isUse'}
                                        caption={('Used')}
                                        cellRender={({data}) => {
                                            const use = data?.use === true? '○' : '-';
                                            return (
                                                <>
                                                    <span>{use}</span>
                                                </>
                                            )
                                        }}
                                />
                                <Column dataField={'isCodeBreaking'}
                                        caption={('Code Breaking')}
                                        cellRender={({data}) => {
                                            const codeBreaking = data?.codeBreaking === true? '○' : '-';
                                            return (
                                                <>
                                                    <span>{codeBreaking}</span>
                                                </>
                                            )
                                        }}
                                />
                                <Toolbar>
                                    <Item name={'columnChooserButton'}/>
                                    <Item name={'exportButton'}/>
                                </Toolbar>
                                <ColumnChooser enabled={true}/>
                                <Export enabled={true} allowExportSelectedData={false}/>
                                <LoadPanel enabled={true}/>
                                <HeaderFilter visible={true} allowSearch={true}/>
                                <FilterRow visible={true} applyFilter={'auto'}/>
                                <Paging defaultPageSize={10}/>
                                <Pager
                                    visible={true}
                                    displayMode={'compact'}
                                    allowedPageSizes={[10, 20, 30, 50]}
                                    showPageSizeSelector={true}
                                    showInfo={true}
                                />
                            </DataGrid>
                        </>
                    )
                }
            </Drawer>
        </>
    )
}

export default StudyStructureCurrentRandomNo;