import {Button, Tooltip} from "antd";
import classNames from "classnames";
import {getKeySeqItemMap} from "../../Methods";
import {useSelector} from "react-redux";
import {isEnvProduction} from "../../../../lib/ODMUtils";

const KeySequenceBtn = ({metadata, data, itemRefMap, setItemRefMap, index, defType, itemGroupOID}) => {

    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);

    const onKeySequence = () => {
        if(isProd === false) {
            const keySeqItemMap = getKeySeqItemMap(metadata, itemRefMap, [itemGroupOID], data?.itemOID);
            // console.log(keySeqItemMap);
            setItemRefMap(keySeqItemMap);
        }
    }

    return (
        <>
            <Tooltip placement="top" title={`Key Sequence : ${data?.keySequence??'NO'}`}>
                <Button size={'small'} className={classNames({
                    'text-info': data?.keySequence > 0
                })} onClick={() => onKeySequence()}>
                    <i className='fa fa-key'></i>
                </Button>
            </Tooltip>
        </>
    )

}

export default KeySequenceBtn;