import {Outlet, useParams} from "react-router-dom";
import {useEffect} from "react";
import axios from "axios";
import {httpStatus} from "../../../lib/createRequestSaga";
import {errorHandle, setStorageStudy} from "../../../lib/BuilderUtils";

const StudyPage = () => {
    const {sid} = useParams();

    //sid 값이 변경될 때마다, sessionStorage 정보에 studyInfo에 대한 json 정보를 저장한다.
    useEffect(() => {
        if(sid != null) {
            //sid가 초기값이거나 현재 sid가 변경되었으면,
            (async () => {
                try {
                    const {status, data} = await axios({
                        url: '/api/builder/edcStudyInfo',
                        params: {sid},
                        method: 'get'
                    });

                    if (status === httpStatus.ok) {
                        setStorageStudy(sid, data);
                    }
                } catch (e) {
                    errorHandle(e);
                }
            })();
        }
    }, [sid])

    return (
        <Outlet />
    )
}

export default StudyPage;