import {isEmpty} from "./StringUtils";

export const onSearch = (value, type, values, setFilteredList, setKeyword) => {
    if(isEmpty(value)) {
        setFilteredList(null);
    } else {
        switch(type) {
            case "itemGroup":
                setFilteredList(values.filter(ig => {
                    let result = false;
                    if(!isEmpty(ig?.domain)){result |= ig?.domain.toUpperCase().includes(value.toUpperCase()||'');}
                    if(!isEmpty(ig?.oid))   {result |= ig?.oid.toUpperCase().includes(value.toUpperCase()||'');}
                    if(!isEmpty(ig?.name))  {result |= ig?.name.toUpperCase().includes(value.toUpperCase()||'');}
                    return result;
                }));
                break;
            case "item":
            case "codeList":
            case "valueList":
                setFilteredList(values.filter(v => {
                    let result = false;
                    if(!isEmpty(v?.oid))   {result |= v?.oid.toUpperCase().includes(value.toUpperCase());}
                    if(!isEmpty(v?.name))   {result |= v?.name.toUpperCase().includes(value.toUpperCase());}
                    return result;
                }));
                break;
        }

        //keyword Setting이 있으면 설정
        if(setKeyword != null) {
            setKeyword(value);
        }
    }
}

export const getStyle = (style, snapshot) => {
    if (!snapshot.isDropAnimating) {
        return style;
    }
    return {
        ...style,
        // cannot be 0, but make it super tiny
        transitionDuration: `0.0001s`,
    };
}