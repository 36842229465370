import {Helmet} from "react-helmet-async";
import React from "react";
import SettingsTabs from "../../../../layouts/Tab/settings";
import RolesLearningCourse from "../../../../components/common/builder/studies/RolesLearningCourse";

const RolesLearningCoursePage = () => {

    return (
        <SettingsTabs menu={'roles-learning-course'}>
            <Helmet title={'BUILDER :: Role & Learning Course'}/>
            <RolesLearningCourse/>
        </SettingsTabs>
    )
}

export default RolesLearningCoursePage;