import React, {useEffect, useState} from 'react';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import {useDispatch, useSelector} from "react-redux";
import api from "../../lib/api/metadata";
import _ from "lodash";
import {Button, Card, Checkbox, Col, message, Radio, Row, Space, Steps, Skeleton, Modal} from 'antd';
import Domain from "./Domain";
import FieldList from "./FieldList";
import CodeList from "./CodeList";
import axios from 'axios';
import {metadataActions} from "../../redux/module/metadata";
import {useParams} from "react-router-dom";

const { Step } = Steps;

const steps = [
    {
        title: 'Domain',
        content: 'Domain을 선택해 주세요.',
    },
    {
        title: 'Item',
        content: 'Item을 선택해 주세요.',
    },
    {
        title: 'Codelists',
        content: 'Controlled Terminology를 선택해 주세요.',
    },
    {
        title: 'Generate eCRF',
        content: 'eCRF 미리보기가 가능합니다.',
    },
];

const stickyAttr = {position:'sticky', top:'-24px', zIndex: 1000, background:'white'};

const delimiter = ".";
const excludeFields = ['STUDYID', 'SITEID', 'SUBJID', 'VISIT', 'VISDAT'];//공통 변수는 share api 아이템 항목에서 제외하도록 한다.
const excludeSuffixFields = ['DD', 'MO', 'YY', 'U'];


const CDISCWizard = ({visibleWizard, setVisibleWizard}) => {
    const dispatch = useDispatch();
    const params = useParams();
    const metaDataStore = useSelector((store) => store.metaDataStore);
    const [span, setSpan] = useState(4);
    const cdashURI = metaDataStore.studyInfo.cdash;
    const [current, setCurrent] = useState(-1);
    const [cdashName, setCdashName] = useState("-");
    // const scenarioDomains = cdashURI.indexOf('2-2') !== -1 ? ['LB', 'EG', 'MI', 'DM', 'DS', 'VS', 'SC'] : ['LB', 'EG', 'MI', 'VS'];
    const [scenarioDomains, setScenarioDomains] = useState([]);

    const [taList, setTaList] = useState([]);

    const [classDomains, setClassDomains] = useState([]);
    const [domainFields, setDomainFields] = useState(new Map());
    const [ctItems, setCtItems] = useState(new Map());

    const [selectedDomains, setSelectedDomains] = useState(new Map());//선택된 도메인
    const [selectedDomainFields, setSelectedDomainFields] = useState(new Map());//선택된 도메인 Fields
    const [selectedCodeItems, setSelectedCodeItems] = useState(new Map());//선택된 CodeItem..
    const labType = metaDataStore.labTypeMap[metaDataStore.studyInfo.labType.key];
    // console.log(metaDataStore.studyInfo.labType);

    useEffect(() => {
        console.log('effect => ', scenarioDomains);
    }, [scenarioDomains]);

    const [isNext, setIsNext] = useState(false);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    useEffect(() => {
        if(visibleWizard) { setCurrent(-1); }
    }, [visibleWizard])

    useEffect(() => {
        if(current === 0) {
            // setSelectedDomainFields(new Map());
        } else if(current === 1) {
            // setSelectedCodeItems(new Map());
        } else if(current === 2) {//Item에서 codelist를 참조하는 데이터 확인
            const newCtItems = new Map();
            selectedDomainFields.forEach(async (value, domain, map) => {
                const domainKey = selectedDomains.get(domain);
                // console.log('domainKey=', domainKey);
                // console.log(metaDataStore.studyInfo.cdashct);
                // console.log(metaDataStore.studyInfo.sdtmct);
                const codeListFields = domainFields.get(domainKey).fields.filter(field => value.includes(field.name));
                codeListFields.map((codeListField) => {
                    // console.log(codeListField)
                    if(codeListField['_links']['codelist'] !== undefined) {
                        const href = codeListField['_links']['codelist'][0].href;
                        if(newCtItems.has(href)) {
                            newCtItems.get(href).push(codeListField.name);
                        } else {
                            newCtItems.set(href, [codeListField.name]);
                        }
                    }
                });
            });
            setCtItems(newCtItems);
        }
    }, [current]);

    useEffect(() => {
        // console.log('ctItems.size => ', ctItems.size);
        if(current === 0) {
            setIsNext(selectedDomains.size > 0);
        } else if(current === 1) {
            setIsNext(selectedDomains.size > 0 && selectedDomains.size == selectedDomainFields.size);
        } else if(current === 2) {
            setIsNext(/*ctItems.size > 0 && */ctItems.size == selectedCodeItems.size);
        }


        // setCtItems(new Map());
        // console.log('isNext =>', isNext);
    }, [current, selectedDomains, selectedDomainFields, ctItems, selectedCodeItems]);


    const getTa = async (taName) => {
        setSelectedDomains(new Map());
        setSelectedDomainFields(new Map());
        setSelectedCodeItems(new Map());
        if(taName) {
            const taResponse = await getData('/api/wizard/ta/list/' + taName);
            const data = taResponse.data;
            const taDomainMap = new Map();
            const taDomainFieldMap = new Map();
            Object.keys(data).map((domain) => {
                if(domain === 'DM' && cdashURI.indexOf('2-2') != -1) {
                    taDomainMap.set(domain, domain+".BirthDateCollectionSingleDate");
                    taDomainFieldMap.set(domain, data[domain]);
                } else if (domain.indexOf('.') === -1) {
                    taDomainMap.set(domain, domain);
                    taDomainFieldMap.set(domain, data[domain]);
                } else {
                    let s = domain.split('.');
                    if (s[0] === 'LB') {
                        if (domain === labType) {
                            taDomainMap.set(s[0], domain);
                            taDomainFieldMap.set(s[0], data[domain]);
                        }
                    } else {
                        taDomainMap.set(s[0], domain);
                        taDomainFieldMap.set(s[0], data[domain]);
                    }
                }
            });
            setSelectedDomains(taDomainMap);
            setSelectedDomainFields(taDomainFieldMap);
        }
        setCurrent(0);
    }

    const excludeSuffixFilter = (fieldName) => {
        for(let index in excludeSuffixFields) {
            // console.log(fieldName + ', suffix =', excludeSuffixFields[index]);
            if(fieldName.lastIndexOf(excludeSuffixFields[index]) !== -1) {
                return true
            }
        }

        return false;
    }

    const excludeFieldFilter = (fields) => {
        // console.log(fields);
        if(fields == undefined) return [];
        return fields
            .filter(field => !excludeFields.includes(field.name))
            // .filter(field => excludeSuffixFilter(field.name))
            .sort((a, b) => {
                const v1 = a.core === 'HR' ? 3 : a.core === 'R/C' ? 2 : 1;
                const v2 = b.core === 'HR' ? 3 : b.core === 'R/C' ? 2 : 1;
            return v2 - v1;
        });
    }

    useEffect(() => {
        if(visibleWizard) {
            (async () => {
                const cdash = await api.getShareAPI(cdashURI);
                const classes = cdash.data.classes;
                // console.log(cdash.data);

                if (classes?.length > 0) {
                    setCdashName(cdash.data.name);
                    setClassDomains(classes);
                    const _domainFieldMap = new Map();
                    setSpan(classes.length < 5 ? 6 : 4);
                    const _scenarioDomains = new Array();
                    classes.map((clazz) => {
                        clazz.domains.map((domain) => {
                            if (domain['_links']['scenarios'] != null) {
                                // if(scenarioDomains.includes(domain.name) || (cdashURI.indexOf('2-2') !== -1 && domain.name == 'DM')) {
                                console.log('Scenario Domain : ', domain.name);
                                _scenarioDomains.push(domain.name);
                                domain['_links']['scenarios'].map((s) => {
                                    (async () => {
                                        const scenario = await api.getShareAPI(s.href);//시나리오에 따라 필드 정보가 다르기 때문에 새로 조회해서 가져옴.
                                        console.log('scenario : ', scenario);
                                        const scenarioKey = s.href.substring(s.href.lastIndexOf("/") + 1);
                                        console.log(scenarioKey);
                                        const _domain = {...domain, ...{fields: scenario.data.fields}};
                                        // domain.fields = scenario.data.fields;
                                        console.log('scenarioKey : ', scenarioKey);
                                        console.log('_domain : ', _domain);
                                        _domainFieldMap.set(scenarioKey, _domain);

                                    })();
                                });
                            } else {
                                _domainFieldMap.set(domain.name, domain);
                                // _scenarioDomains.push(domain.name);
                            }
                        });
                    });
                    setDomainFields(_domainFieldMap);
                    console.log('_scenarioDomains =>', _scenarioDomains);
                    setScenarioDomains(_scenarioDomains);
                }
            })();

            const taResponse = getData("/api/wizard/ta/list");
            taResponse.then(({data}) => {
                setTaList(data);
            });
        }
        // console.log(taResponse);
    }, [visibleWizard]);

    const onChangeDomain = (e) => {
        const val = e.target.value;
        const newDomains = _.cloneDeep(selectedDomains);
        if(val.indexOf(delimiter) != -1) {
            const s = val.split(delimiter);
            const domain = s[0];
            // const process = s[1];
            if(newDomains.has(domain)) {
                if(newDomains.get(domain) === val) {
                    newDomains.delete(domain);//이미 선택된 값인 경우 삭제 처리

                    checkDeleteDomainFields(domain);

                } else {
                    newDomains.set(domain, val);
                }
            } else {
                newDomains.set(domain, val);
            }
        } else {
            if (newDomains.has(val)) {
                newDomains.delete(val);
                checkDeleteDomainFields(val);
            } else {
                newDomains.set(val, val);
            }
        }

        // console.log(newDomains);
        setSelectedDomains(newDomains);
    }

    const checkDeleteDomainFields = (domain) => {
        console.log(domain, 'domain field 삭제');
        if(selectedDomainFields.has(domain)) {
            const _selectedDomainFields = _.cloneDeep(selectedDomainFields);
            _selectedDomainFields.delete(domain);
            setSelectedDomainFields(_selectedDomainFields);
        }
    }

    const getData = async (url) => {
        return await axios.get(url);
    }

    const saveMetaData = async () => {
        message.info('save metadata...');
        // const param = new Map();
        // param.set("domains", selectedDomains);
        // param.set("items", selectedDomainFields);
        // param.set("codelists", selectedCodeItems);

        // console.log(param);
        const obj = {};
        obj.domains = mapToObj(selectedDomains);
        obj.items = mapToObj(selectedDomainFields);
        obj.codelists = mapToObj(selectedCodeItems);
        obj.params = params; //LocalStorage의 key값을 확인하기 위한 parameter 추가 - 20220929

        dispatch(metadataActions.updateMetaData(obj));

        if(setVisibleWizard != null) {
            setVisibleWizard(false);
        }
    }

    return (
        <Modal
            title="Wizard"
            centered
            open={visibleWizard}
            cancelText={'Close'}
            onOk={() => setVisibleWizard(false)}
            onCancel={() => setVisibleWizard(false)}
            width={1300}
            destroyOnClose={true}
            footer={
                current > -1 &&
                <div className="steps-action">
                    {current > -1 && (
                        <Button onClick={() => prev()}>
                            <i className={'fa fa-chevron-left'}></i>&nbsp;&nbsp;Previous
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button style={{margin: '0 8px'}} disabled={!isNext} type="primary" onClick={() => next()}>
                            Next&nbsp;&nbsp;<i className={'fa fa-chevron-right'}></i>
                            {/*/ domains : {selectedDomains.size} /*/}
                            {/*domain Fields : {selectedDomainFields.size} /*/}
                            {/*codeItems : {selectedCodeItems.size}*/}
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={saveMetaData}>
                            Done
                        </Button>
                    )}
                </div>

            }
        >
            {/*title={cdashName}*/}
            <Card styles={{body:{height:'35rem', overflow:'auto'}}} title={
                current > -1 &&
                <Steps progressDot current={current}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title}/>
                    ))}
                </Steps>
            }>
                <div className="steps-content">
                    {current === -1 &&
                        <>
                            <h4>Therapeutic Areas</h4>
                            <hr/>
                            <h6>TA Standards</h6>
                            <Row>
                                {taList.map((taName) => (
                                    <Col span={4} style={{padding:'3px'}}>
                                        <Card size={'small'} title={taName}>
                                            <Button type={"primary"} htmlType={"button"} value={taName} name={taName}
                                                    onClick={(e) => getTa(taName)}><i className={'fa fa-search-plus'}></i>&nbsp;{taName}</Button>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                <Col span={4} style={{padding:'3px'}}>
                                    <Card size={'small'} title={'Direct Selection'}>
                                        <Button type={"default"} htmlType={"button"} value={'Direct Selection'} name={'Direct Selection'}
                                                onClick={(e) => getTa('')}><i className={'fa fa-search-plus'}></i>&nbsp;Direct Selection</Button>
                                    </Card>
                                </Col>
                            </Row>
                        </>

                    }
                    {current === 0 &&
                    <Row>
                        {classDomains.length !== 0 && classDomains.map((clazz) =>
                            <Col span={span} style={{padding:'3px'}}>
                                <Card size={'small'} title={clazz.name} styles={{header:stickyAttr}}>
                                    {clazz.domains.map((domain) => (
                                        <Domain labType={labType} scenarioDomains={scenarioDomains} domain={domain} selectedDomains={selectedDomains} delimiter={delimiter} domainChangeListener={onChangeDomain}></Domain>
                                    ))}
                                </Card>
                            </Col>
                        )}
                    </Row>
                    }
                    {current === 1 &&
                    <Row>
                        {[...selectedDomains.values()].map((val) => {
                            const s = val.split(delimiter);
                            console.log('domain : ', val);
                            return (
                                // <Skeleton loading={domainFields.has(val)}>
                                <Col span={6} style={{padding:'3px'}}>
                                    <Card size={'small'} title={domainFields.get(val)?.label + (s.length == 2 ? ' - ' + s[1] : '')} styles={{header:stickyAttr}}>
                                        <FieldList domain={s[0]} selectedDomainFields={selectedDomainFields} setSelectedDomainFields={setSelectedDomainFields} fields={excludeFieldFilter(domainFields.get(val)?.fields)}/>
                                    </Card>
                                </Col>
                                // </Skeleton>
                            )}
                        )}
                    </Row>
                    }
                    {current === 2 && ctItems.size > 0 &&
                    <Row>
                        {[...ctItems.keys()].map((href) => {
                            return (
                                <Col span={8} style={{padding:'3px'}}>
                                    <CodeList href={href} selectedCodeItems={selectedCodeItems} setSelectedCodeItems={setSelectedCodeItems} items={ctItems.get(href)} stickyAttr={stickyAttr}/>
                                </Col>
                            )}
                        )}
                    </Row>
                    }
                    {
                        current === 3 &&
                        <Card title={'아래의 정보가 생성됩니다.'}>
                            <Row>
                                {[...selectedDomains.values()].map((domain) => (
                                    <Col span={6}>
                                        <h6>{domain}</h6>
                                    </Col>
                                ))}
                            </Row>
                        </Card>
                    }
                </div>
            </Card>
        </Modal>
    )
}

function mapToObj(inputMap) {
    let obj = {};

    inputMap.forEach(function(value, key){
        obj[key] = value
    });

    return obj;
}

export default CDISCWizard;
