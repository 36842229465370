import React, {useEffect, useState} from "react";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {Notice, ToolbarItemList} from "../StyleBox";
import {Button, Collapse, Dropdown, Input, message, Tabs, Tag} from "antd";
import ToolBtn from "./ToolBtn";
import ToolbarItem from "./ToolbarItem";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useDispatch} from "react-redux";
import {metadataActions} from "../../../redux/module/metadata";
import style from './style.module.scss';
import {isEmpty} from "../../../lib/StringUtils";
import {DownOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {onSearch} from "../../../lib/ToolbarUtils";
import ReferenceSearchModal from "./modal/ReferenceSearchModal";
import _ from 'lodash';
import {checkDelete, mandatorySetting} from "../../../lib/ODMUtils";
import {useTranslation} from "react-i18next";

const {Panel} = Collapse;
const {TabPane} = Tabs;

const Toolbar = ({ metaData, Form, itemRefMap, isProd, dropEnable, setDefVisible, setDefType, setDefItem}) => {

    const dispatch = useDispatch();
    const params = useParams();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [searchType, setSearchType] = useState('');
    const [searchOID, setSearchOID] = useState('');
    const [searchResult, setSearchResult] = useState({forms:[],itemGroups:[],items:[],valueList:[],studyEvents:[],studyEventGroups:[]});
    const [isSearchOID, setIsSearchOID] = useState(false);
    const {t} = useTranslation();

    const onSearchOID = (type, oid) => {
        setIsSearchOID(true);
        setSearchType(type);
        setSearchOID(oid);
        setIsModalVisible(true);

    }

    useEffect(() => {
        if(isModalVisible) {
            if (searchType === 'item') {
                const searchItemGroups = [...metaData.itemGroup.values()].filter(itemGroup => itemGroup.itemRef.findIndex(itemRef => itemRef.itemOID === searchOID) !== -1);
                const searchValueLists = [...metaData.valueList.values()].filter(v => v.itemRef.findIndex(v => v.itemOID === searchOID) !== -1);

                const result = {
                    items: [],
                    forms: [],
                    studyEvents: [],
                    studyEventGroups:[],
                    valueList: searchValueLists,
                    itemGroups: searchItemGroups
                };

                setSearchResult(result);
            } else if (searchType === 'valueList') {
                const searchItemRef = [...metaData.itemGroup.values()].filter(itemGroup => itemGroup.itemRef.findIndex(itemRef => itemRef.valueListOID === searchOID) !== -1).map(itemGroup => metaData.item.get(itemGroup.itemRef.find(itemRef => itemRef.valueListOID).itemOID));
                const searchItems = [...metaData.item.values()].filter(v => v.valueListRef !== null && v.valueListRef.valueListOID === searchOID);
                const allSearchList = [...searchItems, ...searchItemRef];
                const result = {
                    items: allSearchList,
                    forms: [],
                    studyEvents: [],
                    studyEventGroups:[],
                    valueList: [],
                    itemGroups: []
                };

                setSearchResult(result);
            } else if (searchType === 'codeListItem' || searchType == 'externalCodeList') {
                const searchItems = [...metaData.item.values()].filter(item => item.codeListRef != null && item.codeListRef.codeListOID === searchOID);
                // const searchValueLists = [...metaData.valueList.values()].filter(v => v.itemRef.findIndex(v => v.itemOID === searchOID) !== -1);

                const result = {
                    items: searchItems,
                    forms: [],
                    studyEvents:[],
                    studyEventGroups:[],
                    valueList: [],
                    itemGroups: []
                };

                setSearchResult(result);
            } else if (searchType === 'itemGroup') {
                const searchForms = [...metaData.form.values()].filter(form => form.itemGroupRef.findIndex(itemGroupRef => itemGroupRef.itemGroupOID === searchOID) !== -1);

                const result = {
                    items: [],
                    forms: searchForms,
                    studyEvents:[],
                    studyEventGroups:[],
                    valueList: [],
                    itemGroups: []
                };

                setSearchResult(result);
            } else if (searchType === 'form') {
                const searchStudyEvents = [...metaData.studyEvent.values()].filter(studyEvent => studyEvent.formRef.findIndex(formRef => formRef.formOID === searchOID) !== -1);

                const result = {
                    items: [],
                    forms: [],
                    studyEvents:searchStudyEvents,
                    studyEventGroups:[],
                    valueList: [],
                    itemGroups: []
                };

                setSearchResult(result);
            } else if (searchType === 'studyEvent') {
                const searchStudyEventGroups = [...metaData.studyEventGroup.values()].filter(studyEventGroup => studyEventGroup.studyEventRef.findIndex(studyEventRef => studyEventRef.studyEventOID === searchOID) !== -1);

                const result = {
                    items: [],
                    forms: [],
                    studyEvents:[],
                    studyEventGroups:searchStudyEventGroups,
                    valueList: [],
                    itemGroups: []
                };

                setSearchResult(result);
            }
        }

        setTimeout(function() {
            setIsSearchOID(false);
        }, 750);

    }, [isModalVisible, searchType, searchOID]);

    //Toolbar 정보 Setting
    const {itemGroup, item, codeList, valueList} = metaData;

    const [itemGroupKeyword, setItemGroupKeyword] = useState(null);
    const [filteredItemGroups, setFilteredItemGroups] = useState(null);
    const [itemKeyword, setItemKeyword] = useState(null);
    const [filteredItems, setFilteredItems] = useState(null);
    const [codeListKeyword, setCodeListKeyword] = useState(null);
    const [filteredCodeLists, setFilteredCodeLists] = useState(null);
    const [valueListKeyword, setValueListKeyword] = useState(null);
    const [filteredValueLists, setFilteredValueLists] = useState(null);

    const [uploadVisible, setUploadVisible] = useState(false);

    const isEnable = (enable, draggableId) => {
        //move Type에, draggableId가 일치한 경우만
        if (enable !== null && enable.destination !== null) {
            if (enable.type === 'copy' && enable.draggableId === draggableId) {
                return enable.checked;
            }
        }
        return null;
    }

    const onCreate = (type) => {
        setDefVisible(true);
        setDefType(type);
        setDefItem(null);
    }

    const onUpload = (type) => {
        setUploadVisible(true);
    }

    const onEdit = (itemType, item) => {
        setDefVisible(true);
        setDefType(itemType);
        setDefItem(item);
    }

    //onSearch Debounce 적용
    const onDebounceSearch = _.debounce(onSearch, 200);

    //Def정보 변경 시, Filter적용 변경.
    useEffect(() => {
        //Keyword값이 empty가 아닌 경우, filter 검색항목 재적용.
        if(!isEmpty(itemGroupKeyword)) {
            onDebounceSearch(itemGroupKeyword, "itemGroup", Array.from(metaData.itemGroup.values()), setFilteredItemGroups);
        }
        if(!isEmpty(itemKeyword)) {
            onDebounceSearch(itemKeyword, "item", Array.from(metaData.item.values()), setFilteredItems);
        }
        if(!isEmpty(codeListKeyword)) {
            onDebounceSearch(codeListKeyword, "codeList", Array.from(metaData.codeList.values()), setFilteredCodeLists);
        }
        if(!isEmpty(valueListKeyword)) {
            onDebounceSearch(valueListKeyword, "valueList", Array.from(metaData.valueList.values()), setFilteredValueLists);
        }
    }, [metaData]);

    const onRemove = async (itemType, propName, item) => {
        try {
            const res = await withReactContent(Swal).fire({
                title: `[${item.oid}] ${item.name}`,
                icon: 'question',
                iconColor: 'red',
                html: `
                    <div class="alert alert-danger">
                        <span>정말로 삭제하시겠습니까?</span>
                    </div>
                `,
                showCancelButton: true,
                cancelButtonText: '아니오',
                showConfirmButton: '예'
            });

            if(itemType === 'valueList') {
                mandatorySetting(item.itemRef); //true or false를 Yes or No로 변경
            }

            //예 눌렀을 때,
            if(res.isConfirmed) {
                //현재 페이지에 참조되어있는 ItemGroup이 존재하는 경우
                const existItemGroupOIDs = checkDelete(itemRefMap, propName, item);

                //현재 itemRefList에 지우려는 item의 Reference 정보가 있으면,
                if(existItemGroupOIDs != null) {
                    message.warning(t('message.error.exist.item-ref', {itemGroupOIDs:existItemGroupOIDs}))
                } else {
                    item.attr = propName;
                    item.params = params; //sessionStorage의 key값을 확인하기 위한 parameter 추가 - 20220929
                    dispatch(metadataActions.delete(item));
                }
            }
        } catch(e) {
            Swal.showValidationMessage(e.message);
        }
    }

    /**
     * 드래그 비활성화 처리할 아이템
     * @param oid
     * @returns {boolean|*}
     */
    const isDragDisabledItem = oid => {
        if(oid == null) return false;
        // return oid === "ICDAT" || oid === "SUBJID" || (oid.endsWith("U"))
        return (oid.endsWith("U"))
    }

    /**
     * 드래그 비활성화 아이템 그룹
     * @param oid
     * @returns {boolean}
     */
    const isDragDisabledItemGroup = oid => {
        if(oid == null) return false;
        return oid === "EN";
    }

    const menu = {
        items: [{label: <ToolBtn type={'externalCodeList'} onCreate={onCreate} btnType={'text'} />}]
    }

    const tabItems = [
        {
            key: 'tab-1',
            label: <><span className='width-100p mr-2'><i className={'fe fe-folder'}></i>&nbsp;Study Library</span></>,
            children: (
                <Collapse accordion bordered={false} className={style.menu}  defaultActiveKey={["slib-2"]}  expandIconPosition={'start'}>
                    <Panel key="slib-1" header={<>
                        Item Groups
                        {
                            filteredItemGroups !== null &&
                            <Tag className={'ml-2'}><i className='fa fa-filter'/></Tag>
                        }
                    </>} bordered={true} extra={ <ToolBtn hidden={isProd} type="itemGroup" onCreate={onCreate}/> }>
                        <Input.Search allowClear className={style.search} onChange={(e) => onDebounceSearch(e?.target?.value, 'itemGroup', Array.from(itemGroup.values()), setFilteredItemGroups, setItemGroupKeyword)} />
                        <Droppable droppableId="newItemGroup" direction="vertical" isDropDisabled={true} type="itemGroup">
                            {(provided, snapshot) => (
                                <ToolbarItemList ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver}>
                                    {
                                        Form.itemGroupRef.filter(fig => (filteredItemGroups!==null?filteredItemGroups:Array.from(itemGroup.values())).some(ig => ig.oid === fig.itemGroupOID)).length < (filteredItemGroups!==null?filteredItemGroups:Array.from(itemGroup.values())).length ?
                                            (filteredItemGroups!==null?filteredItemGroups:Array.from(itemGroup.values())).map((ig, index) => (
                                                Form.itemGroupRef.every(fig => fig.itemGroupOID !== ig.oid) &&
                                                <Draggable key={ig.oid} draggableId={ig.oid} index={index} isDragDisabled={isProd} disableInteractiveElementBlocking={true}>
                                                    {(provided, snapshot) => (
                                                        <ToolbarItem key={`toolbar-item-group-${ig.oid}`} itemType="itemGroup"
                                                                     provided={provided} snapshot={snapshot} isDragging={snapshot.isDragging}
                                                                     enable={isEnable(dropEnable, ig.oid)}
                                                                     item={ig} onEdit={onEdit} onRemove={onRemove} onSearchOID={onSearchOID} />
                                                    )}
                                                </Draggable>
                                            ))
                                            : (
                                                <Notice key={'notice'}>
                                                    No ItemGroups.
                                                </Notice>
                                            )
                                    }
                                    {/* placeholder가 없으면 warning 발생으로 인해 강제로 집어넣음. */}
                                    <div style={{display: "none"}}>{provided.placeholder}</div>
                                </ToolbarItemList>
                            )}
                        </Droppable>
                    </Panel>
                    <Panel key={"slib-2"} header={<>
                        Items
                        {
                            filteredItems !== null &&
                            <Tag className={'ml-2'}><i className='fa fa-filter'/></Tag>
                        }
                    </>} extra={<ToolBtn hidden={isProd} type="item" onCreate={onCreate} />}>
                        <Input.Search allowClear className={style.search} onChange={(e) => onDebounceSearch(e?.target?.value, 'item', Array.from(item.values()), setFilteredItems, setItemKeyword)} />
                        <Droppable droppableId="newItem"
                                   direction="vertical"
                                   isDropDisabled={true}
                                   type="item"
                        >
                            {(provided, snapshot) => (
                                <ToolbarItemList
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                >
                                    {
                                        (filteredItems!==null?filteredItems:Array.from(item.values())).length > 0 ?
                                            (filteredItems!==null?filteredItems:Array.from(item.values())).map((i, index) => (
                                                <Draggable key={i?.oid} draggableId={i?.oid} index={index} isDragDisabled={isProd || isDragDisabledItem(i?.oid)} disableInteractiveElementBlocking={true}>
                                                    {(provided, snapshot) => (
                                                        <ToolbarItem key={`toolbar-item-${i?.oid}`} itemType="item"
                                                                     provided={provided} snapshot={snapshot} isDragging={snapshot.isDragging}
                                                                     enable={isEnable(dropEnable, i?.oid)}
                                                                     item={i} onEdit={onEdit} onRemove={onRemove} onSearchOID={onSearchOID}/>
                                                    )}
                                                </Draggable>
                                            )) : (
                                                <Notice key={'notice'}>
                                                    No Items.
                                                </Notice>
                                            )
                                    }
                                    {/* placeholder가 없으면 warning 발생으로 인해 강제로 집어넣음. */}
                                    <div style={{display: "none"}}>{provided.placeholder}</div>
                                </ToolbarItemList>
                            )
                            }
                        </Droppable>
                    </Panel>
                    <Panel key={"slib-3"}
                           header={
                               <>
                                   CodeLists
                                   {
                                       filteredCodeLists !== null &&
                                       <Tag className={'ml-2'}><i className='fa fa-filter'/></Tag>
                                   }
                               </>
                           }
                           extra={<>
                               <Button size={'small'} hidden={isProd}
                                       onClick={
                                           (e) => {
                                               e.stopPropagation(); // Panel Toggle 동작으로 Event가 확장되지 않게 제어.
                                               onCreate("codeListItem");
                                           }
                                       }>
                                   <i className='fa fa-plus mr-2'/>codeListItem
                               </Button>
                               <Dropdown menu={menu} trigger={"click"} onClick={(e) => {
                                   e.stopPropagation();
                               }}>
                                   <Button hidden={isProd} size={'small'}><DownOutlined /></Button>
                               </Dropdown>
                           </>}>
                        <Input.Search allowClear className={style.search} onChange={(e) => onDebounceSearch(e?.target?.value, 'codeList', Array.from(codeList.values()), setFilteredCodeLists, setCodeListKeyword)} />
                        <ToolbarItemList>
                            {
                                (filteredCodeLists!==null?filteredCodeLists:Array.from(codeList.values())).length > 0 ?
                                    (filteredCodeLists!==null?filteredCodeLists:Array.from(codeList.values())).map((cl, index) => (
                                        // CodeListItem Field가 있으면, codeList Item 아니면, externalCodeList
                                        <ToolbarItem key={`toolbar-codelist-${cl?.oid}`} enable={true}
                                                     itemType={cl?.externalCodeList===null?'codeListItem':'externalCodeList'}
                                                     item={cl} onEdit={onEdit} onRemove={onRemove} onSearchOID={onSearchOID} />
                                    )) : (
                                        <Notice key={'notice'}>
                                            No CodeLists.
                                        </Notice>
                                    )
                            }
                        </ToolbarItemList>
                    </Panel>
                    <Panel key={"slib-4"} header={
                        <>
                            ValueLists
                            {
                                filteredValueLists !== null &&
                                <Tag className={'ml-2'}><i className='fa fa-filter'/></Tag>
                            }
                        </>}
                           extra={<ToolBtn hidden={isProd} type="valueList" onCreate={onCreate} />}>
                        <Input.Search allowClear className={style.search} onChange={(e) => onDebounceSearch(e?.target?.value, 'valueList', Array.from(valueList.values()), setFilteredValueLists, setValueListKeyword)} />
                        <ToolbarItemList>
                            {
                                (filteredValueLists !== null ? filteredValueLists : Array.from(valueList.values())).length > 0 ?
                                    (filteredValueLists !== null ? filteredValueLists : Array.from(valueList.values())).map((vl, index) => (
                                            <ToolbarItem key={`toolbar-valuelist-${vl?.oid}`} enable={true} itemType="valueList" item={vl} onEdit={onEdit} onRemove={onRemove} onSearchOID={onSearchOID}/>
                                        )
                                    ) : (
                                        <Notice key={'notice'}>
                                            No ValueList.
                                        </Notice>
                                    )
                            }
                        </ToolbarItemList>
                    </Panel>
                </Collapse>
            )
        }
    ]

    return (
        <>
            <ReferenceSearchModal searchOID={searchOID} onSearchOID={onSearchOID}
                                  isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}
                                  isSearchOID={isSearchOID} searchType={searchType} searchResult={searchResult} />
            {
                Form !== null &&
                <Tabs defaultActiveKey="tab-1" size={'small'} type={'card'} key={'tabs'} items={tabItems} />
            }
        </>
    )
}

export default Toolbar;
