import React, {useEffect, useState} from "react";
import {CardBody, CardFooter, CardHeader, PrimaryCard} from "../../../../pages/PageStyleBox";
import {Button, Col, Dropdown, message, Row, Space} from "antd";
import {RightOutlined, UpOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle, onExported, onExporting} from "../../../../lib/BuilderUtils";
import DataGrid, {
    Column,
    ColumnChooser,
    Export,
    FilterRow,
    HeaderFilter,
    Item,
    LoadPanel,
    Pager,
    Paging,
    Scrolling,
    StateStoring,
    Toolbar
} from "devextreme-react/data-grid";
import NormalLoading from "../../NormalLoading";
import {useTranslation} from "react-i18next";
import LibraryActiveModal from "./LibraryActiveModal";
import {trackPromise} from "react-promise-tracker";
import classNames from "classnames";


const GlobalLibraryList = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [libraryList, setLibraryList] = useState(null);
    const [dataGrid, setDataGrid] = useState(null);
    const [multiContract, setMultiContract] = useState(false);
    const [contracts, setContracts] = useState([]);

    const [library, setLibrary] = useState({});
    const [isNameMatch, setNameMatch] = useState(false);
    const [activeOpen, setActiveOpen]= useState(false);
    const [active, setActive] = useState(false);


    useEffect(() => {
        (async () => {
            try {
                //REST API Request
                const {status, data} = await axios.get(`/api/builder/global-library`);

                if(status === httpStatus.ok) {
                    const {libraryList, multiContract, contracts} = data;

                    //State Setting
                    setLibraryList(libraryList);
                    setMultiContract(multiContract);
                    setContracts(contracts);
                }
            } catch (e) {
                errorHandle(e);
            }
        })();
    }, []);

    const onEdit = (id) => {
        navigate(`/global-library/edit?id=${id}`)
    }

    const onActiveToggle = (id, active) => {
        const activeLib = libraryList.find(lib => lib?.id === id)
        console.log("Active/Inactive Library : ", activeLib);
        setLibrary(activeLib);
        setNameMatch(false);
        setActiveOpen(true);
        setActive(!active);
    }

    const onCreateLibrary = ({key}) => {
        navigate(`/global-library/edit?contractId=${key}`);
    }

    const contractMenus = {
        items: (contracts??[]).map(ct => ({
            label: ct?.companyName,
            key: ct?.id,
            icon: <i className={'fa fa-building-o'}></i>
        })),
        onClick: onCreateLibrary,
    }

    const onActiveLibrary = async (id) => {
        try {
            const {status, data} = await trackPromise(axios.put(`/api/builder/global-library/${id}/toggle-active`, {active}))

            if(status === httpStatus.ok) {
                message.success(data?.message);
                setLibraryList(data?.libraryList);
            }
        } catch (e) {
            errorHandle(e);
        }

        setActiveOpen(false);
    }

    const onActiveChange = (e) => {
        const value = e.target.value;
        const name = library?.name;
        setNameMatch(value === name);
    }

    return (
        <PrimaryCard>
            <CardHeader>
                <Row>
                    <Col>
                        <h3><RightOutlined className={'mr-2'} />Global Libraries</h3>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                {
                    libraryList == null?(
                        <NormalLoading />
                    ):(
                        <DataGrid dataSource={libraryList}
                                  height={'600'}
                                  id={'gridGlobalLibrary'}
                                  ref={(ref) => {setDataGrid(ref);}}
                                  allowedPageSizes={true}
                                  allowColumnResizing={false}
                                  columnMinWidth={50}
                                  columnAutoWidth={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  showBorders={true}
                                  hoverStateEnabled={true}
                                  allowColumnReordering={true}
                                  keyExpr={'id'}
                                  onCellClick={({values, columnIndex, rowType, data}) => {
                                      if(rowType === 'data' && columnIndex !== (values?.length??0)-1) {
                                          navigate(`/global-library/v/${data?.id}/forms`);
                                      }
                                  }}
                                  onExporting={(e) => onExporting(e, 'GlobalLibraries',
                                      ({gridCell, excelCell}) => {
                                      }
                                  )}
                                  onExported={(e) => onExported(e)}>
                            {/*<GroupPanel visible={true} />*/}
                            {/*<Grouping autoExpandAll={true} />*/}
                            <Column dataField={'id'} caption={t('column.no')} fixed={true} fixedPosition={'left'} width={'60px'}
                                    cellRender={({row}) => (
                                        <span key={row?.loadIndex}>{libraryList?.length - row?.loadIndex}</span>
                                    )} />
                            {
                                multiContract &&
                                <Column dataField={'contractName'} caption={t('column.contract')} dataType={'string'} />
                            }
                            <Column dataField={'name'} caption={t('column.name')} dataType={'string'} />
                            <Column dataField={'description'} caption={t('column.description')} dataType={'string'} width={500} />
                            <Column dataField={'createdDate'} caption={t('column.created-date')} dataType={'date'} format={'yyyy-MM-dd HH:mm:ss'}  />
                            <Column dataField={'createdBy.username'} caption={t('column.created-by')} dataType={'date'} format={'yyyy-MM-dd HH:mm:ss'} />
                            <Column dataField={'active'} caption={t('column.active')} dataType={'boolean'} />
                            <Column dataField={'action'} caption={t('column.action')} fixed={true} fixedPosition={'right'} width={225}
                                    cellRender={({data}) => (
                                        <Space size={'middle'} id='actions'>
                                            <Button onClick={() => onEdit(data?.id)} icon={<i className='fe fe-edit fa fa-check-square-o'/>}> Edit</Button>
                                            <Button onClick={() => onActiveToggle(data?.id, data?.active)} icon={<i className={classNames('mr-2', {['fa fa-check-square-o']:!data?.active, ['fa fa-square-o']:data?.active})} />}>{data?.active?'Inactive':'Active'}</Button>
                                        </Space>
                                    )} />
                            <Toolbar>
                                <Item name={'columnChooserButton'} />
                                <Item name={'exportButton'} />
                            </Toolbar>
                            <ColumnChooser enabled={true} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <LoadPanel enabled={true} />
                            <HeaderFilter visible={true} allowSearch={true} />
                            <FilterRow visible={true} applyFilter={'auto'} />
                            <StateStoring enabled={true} type={'sessionStorage'} storageKey={`storage-global-libraries`}/>
                            <Scrolling rowRenderingMode={'virtual'} />
                            <Paging defaultPageSize={10}/>
                            <Pager
                                visible={true}
                                displayMode={'compact'}
                                allowedPageSizes={[10, 20, 30, 50]}
                                showPageSizeSelector={true}
                                showInfo={true}
                            />
                        </DataGrid>
                    )
                }
                <LibraryActiveModal activeOpen={activeOpen} setActiveOpen={setActiveOpen} active={active}
                                    onActiveChange={onActiveChange} isNameMatch={isNameMatch}
                                    onActiveLibrary={onActiveLibrary} library={library} />
            </CardBody>
            <CardFooter>
                {
                    multiContract?(
                        <Dropdown menu={contractMenus} placement={'topRight'}>
                            <Button>
                                <Space>
                                    {t('library.btn.add')}
                                    <UpOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    ):(
                        <Button onClick={() => {navigate('/global-library/edit')}}> {t('library.btn.add')} </Button>
                    )
                }
            </CardFooter>
        </PrimaryCard>
    )
}

export default GlobalLibraryList;