import React from "react";
import {Helmet} from "react-helmet-async";
import StudyEdit from "../../../../components/common/builder/studies/StudyEdit";

const StudyEditPage = ({user}) => {

    return (
        <>
            <Helmet title={'BUILDER :: Study Edit'} />
            <StudyEdit />
        </>
    )
}

export default StudyEditPage;