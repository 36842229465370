import PerfectScrollbar from "react-perfect-scrollbar";
import React from "react";

const ScheduleBody = ({children}) => {


    return (
        <div className={'card-body definitions'} style={{height: '100%', width: '100%'}} >
                {children}
        </div>
    )

}

export default ScheduleBody;