import React from 'react'
import {Input, Button, Form, message} from 'antd'
import {Link, useNavigate} from 'react-router-dom'
import style from '../style.module.scss'
import {errorHandle} from "../../../../lib/BuilderUtils";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {withTranslation} from "react-i18next";

const ForgotPassword = ({t}) => {

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const {status, data} = await axios({
        url:'/api/users/forgot-password',
        method:'post',
        data: values
      });

      if(status === httpStatus.ok) {
        message.success(data?.message);
        navigate('/login', {replace:true});
      }
    } catch(e) {
      errorHandle(e);
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }


  return (
    <div className="mt-5 pt-2">
      <div className={`card ${style.container}`}>
        <div className="text-dark mb-3 text-center">
          <h3>{t('forgot-password.header.title')}</h3>
          <p>{t('forgot-password.sub.title')}</p>
        </div>
        <Form
          layout="vertical"
          requiredMark={false}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="mb-4"
        >
          <Form.Item
            name="email"
            rules={[{
                required: true,
                message: t('input.required.message.email')
              },
              {
                pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                message: t('input.pattern.message.email')
              }
              ]}
          >
            <Input placeholder={t('input.placeholder.email')} />
          </Form.Item>
          <Button type="primary" htmlType={'submit'} className="text-center w-100">
            <strong>{t('btn.reset.password')}</strong>
          </Button>
        </Form>
        <Link to="/login" className="vb__utils__link">
          <i className="fe fe-arrow-left mr-1 align-middle" />
          {t('link.back-to-login')}
        </Link>
      </div>
    </div>
  )
}

export default withTranslation()(ForgotPassword);
