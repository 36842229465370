import React, {useEffect, useState} from "react";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Form, Input, message, Switch} from "antd";
import NormalLoading from "../../NormalLoading";
import {withTranslation} from "react-i18next";

const DefaultECS = ({t}) => {
    const params = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        (async () => {
            try {
                const response = await axios.get(`/api/builder/studies/${params.sid}/basic-definition/default-ecs`);
                if (response.status === httpStatus.ok) {
                    const {data} = response;

                    // console.log(data);
                    form.setFieldsValue(data);
                    setLoading(false);
                }
            } catch(e) {
                errorHandle(e, navigate);
            }
        })();
    }, []);

    const onFinish = async (values) => {

        try {
            const response = await axios.post(`/api/builder/studies/${params.sid}/basic-definition/default-ecs`, values);

            if(response.status === httpStatus.ok) {
                const {data} = response;
                message.success(data.message);
            }

        } catch (e) {
            errorHandle(e);
        }
    };

    return (
        <>
            {
                loading?(
                    <NormalLoading />
                ):(
                    <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" form={form}>
                        <table className={'table table-bordered'}>
                            <thead>
                            <tr>
                                <th>OID</th>
                                <th>Message</th>
                                <th>Force Save</th>
                            </tr>
                            </thead>
                            <tbody>
                            <Form.List name="defaultEditChecks">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <tr>
                                                <td>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'oid']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Missing oid',
                                                            },
                                                        ]}
                                                    >
                                                        <Input bordered={false} placeholder="OID" readOnly={true} />
                                                    </Form.Item>
                                                </td>
                                                <td style={{width:'50%'}}>
                                                    {t(form.getFieldValue('defaultEditChecks')[name].message)}
                                                </td>
                                                <td>
                                                    <Form.Item valuePropName={"checked"}
                                                               name={[name, 'forceSave']} rules={[{ required: false}]}
                                                               initialValue={true} >
                                                        <Switch />
                                                    </Form.Item>
                                                </td>
                                            </tr>
                                        ))}
                                        {/*<Form.Item>*/}
                                        {/*    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>*/}
                                        {/*        Add field*/}
                                        {/*    </Button>*/}
                                        {/*</Form.Item>*/}
                                    </>
                                )}
                            </Form.List>
                            </tbody>
                        </table>
                        <Form.Item className={'text-right'}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                )
            }
        </>
    )
}

export default withTranslation()(DefaultECS);
