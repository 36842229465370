import ODMTabs from "../../../layouts/Tab/odm";
import {Helmet} from "react-helmet-async";
import React from "react";
import SAEDataMapContainer from "../../../container/odm/SAEDataMapContainer";

const SAEMapPage = () => {
    return (
        <ODMTabs menu={'sae-map'}>
            <Helmet title={`ODM Designer v2.0.0 :: SAE Map`}/>
            <SAEDataMapContainer />
        </ODMTabs>
    )
}
export default SAEMapPage;
